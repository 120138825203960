import React, { ReactElement, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import {
  faAlarmExclamation,
  faArrowLeft,
  faCube,
  faGaugeMax,
  faLightbulbOn,
  faPause,
  faPlay,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import IWTab from 'shared/components/thunderbolt/IWTab'
import IWTabs from 'shared/components/thunderbolt/IWTabs'
import { useQuery } from 'react-query'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  deleteAlert,
  getPodDatacube,
  getUserAlert,
  toggleAlertIsPaused,
} from 'shared/podServiceClient'
import NotFound from 'root/pages/NotFound'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import { goToURL, isPermissionAvailable } from 'shared/helpers'
import userContext from 'shared/contexts/UserContext'
import AlertTriggerSummary from '../components/AlertTriggerSummary'
import AlertConfigSummary from '../components/AlertConfigSummary'

import ConfirmChangesModal from '../../insightsManager/components/ConfirmChangesModal'

const LeftHand = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1;
`

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
`

/**
 * Component to display the details of an Alert
 */
const AlertDetailsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { alertId = '' } = useParams()
  const { availableToolPolicies } = useContext(userContext)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false)
  const toast = useToast()

  const isOwner = true
  const canEditAlert = isPermissionAvailable(
    availableToolPolicies,
    'alertsManager:editor',
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    data: alert,
    error,
    refetch,
    isLoading: alertIsInitializing,
  } = useQuery(
    `alert:${alertId}`,
    () => {
      return getUserAlert(alertId)
    },
    { refetchOnWindowFocus: false },
  )

  const [activeTab, setActiveTab] = useState<number>(0)

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      setShowDeleteConfirmModal(false)
      await deleteAlert(alert?.userAlertId)
      toast.secondary(
        t('insightManager.pods.deleteSuccess', { name: alert?.userAlertName }),
        { canDismiss: true },
      )
      navigate(-1)
    } catch (e) {
      toast.alert(
        t('insightManager.pods.deleteError', { name: alert?.userAlertName }),
      )
    } finally {
      setIsLoading(false)
      await refetch()
    }
  }

  const renderButtons = () => {
    const buttons: ReactElement[] = []
    if (canEditAlert) {
      buttons.push(
        <IWButton
          iconPosition="leading"
          variant="outline"
          data-testid="alert-actions-pause"
          icon={alert?.isPaused ? faPlay : faPause}
          onClick={async () => {
            const newIsPaused = await toggleAlertIsPaused(alert?.userAlertId)
            toast.secondary(
              newIsPaused
                ? t('alertsManager.toasts.paused', {
                    name: alert?.userAlertName,
                  })
                : t('alertsManager.toasts.resumed', {
                    name: alert?.userAlertName,
                  }),
            )
            await refetch()
          }}
        >
          {alert?.isPaused ? t('actions.resume') : t('actions.pause')}
        </IWButton>,
      )
    }
    if (isOwner) {
      buttons.push(
        <IWButton
          icon={faTrash}
          iconPosition="leading"
          variant="outline"
          data-testid="alert-actions-delete"
          onClick={() => setShowDeleteConfirmModal(true)}
        >
          {t('actions.delete')}
        </IWButton>,
      )
    }

    return buttons
  }

  const handleGetPodDataCube = async () => {
    try {
      setIsLoading(true)
      const url = await getPodDatacube(alert?.userPodId || '')
      goToURL(url)
    } catch (e) {
      toast.alert(
        t('insightManager.pods.dataCubeError', { name: alert?.userPodName }),
      )
    } finally {
      setIsLoading(false)
    }
  }

  if (alertIsInitializing || isLoading) {
    return <IWLoading />
  }

  if (error) {
    return <IWError />
  }

  if (!(alertIsInitializing || isLoading) && !alert) {
    return <NotFound />
  }

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <LeftHand>
          <StyledButton
            onClick={() => navigate('/tools/alerts-manager')}
            data-testid="page-header-back-button"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </StyledButton>
          <StyledTitle>{alert?.userAlertName}</StyledTitle>
        </LeftHand>
        <IWButtonGroup color="primary">{renderButtons()}</IWButtonGroup>
      </IWPageHeader>
      <main>
        <ConfirmChangesModal
          title={t(`alertsManager.confirmDeleteLabel`)}
          body={t(`alertsManager.confirmDeleteDescription`)}
          isOpen={showDeleteConfirmModal}
          handleClose={() => setShowDeleteConfirmModal(false)}
          handleConfirm={() => handleDelete()}
        />
        <IWBadgeGroup>
          <IWCardBadge
            label={t('alertsManager.triggerCount', {
              count: alert?.triggerCount,
            })}
            icon={faAlarmExclamation}
          />
          <IWCardBadge
            label={t(`insights.${alert?.podType.insightId}.label`)}
            icon={faLightbulbOn}
          />
          <IWCardBadge
            label={`${t(
              `insights.${alert?.podType.insightId}.metrics.${alert?.podType.metricId}.label`,
            )}`}
            icon={faGaugeMax}
          />
          <IWCardBadge label={alert?.userPodName || ''} icon={faCube} />
          {alert?.isPaused ? (
            <IWGeneralBadge
              data-testid="alert-paused-badge"
              label={t(`alertsManager.paused`)}
              fontHue={{ color: 'grey', value: 0 }}
              hue={{ color: 'grey', value: 500 }}
            />
          ) : undefined}
        </IWBadgeGroup>

        <IWTabs activeTabIndex={activeTab} onTabChange={setActiveTab}>
          <IWTab label={t('alertsManager.triggerHistory')} />
          <IWTab label={t('alertsManager.alertConfig')} />
        </IWTabs>
        {activeTab === 0 && (
          <AlertTriggerSummary
            onGetPodDataCube={handleGetPodDataCube}
            alertId={alertId}
          />
        )}
        {activeTab === 1 &&
          (alert ? <AlertConfigSummary alertId={alertId} /> : <></>)}
      </main>
    </>
  )
}

export default AlertDetailsPage
