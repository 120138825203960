import React from 'react'
import styled from 'styled-components'
import { darken, lighten, transitions } from 'polished'
import IWTypography from './IWTypography'

const StyledLabel = styled(IWTypography)``

// The odd styling here is intended to hide but not remove from SRs
const StyledHiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledDaySelector = styled.div`
  display: inline-block;
  border-radius: 50%;
  background: ${(props) => props.theme.palette.grey[100]};
  color: ${(props) => props.theme.palette.grey[700]};
  text-transform: uppercase;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  ${StyledHiddenInput}:checked + & {
    background: ${(props) => props.theme.palette.primary[700]};
    color: ${(props) => props.theme.palette.grey[0]};
  }
  ${StyledHiddenInput}:focus + & {
    outline-offset: 0.125rem;
    outline: ${(props) => `${props.theme.palette.primary[800]} solid 0.125rem`};
  }
  ${StyledHiddenInput}:hover:checked + & {
    background: ${(props) => darken(0.1, props.theme.palette.primary[700])};
  }
  ${StyledHiddenInput}:hover:not(:checked) + & {
    background: ${(props) => darken(0.1, props.theme.palette.grey[100])};
  }
  ${StyledHiddenInput}:active:checked + & {
    background: ${(props) => lighten(0.1, props.theme.palette.primary[700])};
  }
  ${StyledHiddenInput}:active:not(:checked) + & {
    background: ${(props) => lighten(0.1, props.theme.palette.grey[100])};
  }
  ${StyledHiddenInput} + label {
    color: ${(props) => props.theme.palette.grey[0]};
  }
  ${transitions(['color', 'background-color'], '0.3s ease 0s')};
`

interface IWRoundInputProps {
  /** The form unique id of the input element */
  id: string
  /** label to display and describe the toggle */
  label: string
  type: 'checkbox' | 'radio'
  labelLength?: number
}

type Props = IWRoundInputProps & React.ComponentPropsWithoutRef<'input'>

/**
 * Round checkbox or radio button. This is a pure radio or checkbox that can be used in an uncontrolled or controlled manner. Labels provided will use only the first character of the string.
 */
const IWRoundInput = React.forwardRef(
  ({ id, label, type, ...props }: Props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <StyledLabel htmlFor={id} weight="medium" size="sm" as="label">
        <StyledHiddenInput
          ref={ref}
          id={id}
          type={type}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
        />
        <StyledDaySelector>{label}</StyledDaySelector>
      </StyledLabel>
    )
  },
)

export default IWRoundInput
