import PapaParse from 'papaparse'
import Zip from 'jszip'
import { PapaparseReturn, ValidPolicies } from './types'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { z, ZodType } from 'zod'

export const goToURL = (url: string) => {
  window.open(url, '_blank')
}

export const downloadContent = ({
  filename,
  ext,
  content,
  mediaType = 'text/plain',
  charset = 'utf-8',
  encodeURI = true,
}: {
  filename: string
  ext: string
  content: string
  mediaType?: string
  charset?: string
  encodeURI?: boolean
}): void => {
  const dataMediaType = charset
    ? `data:${mediaType};charset=${charset}`
    : `data:${mediaType}`
  const encoding = encodeURI ? encodeURIComponent(content) : content
  const element = document.createElement('a')
  element.download = `${filename}.${ext}`
  element.href = `${dataMediaType},${encoding}`
  element.click()
}

/**
 * Convert Flat Json to CVS
 * @param items Array on T
 * @param headers
 * @returns CSV string
 */
export const convertToCVS = (
  items: Array<object> = [],
  headers: string[] = [],
) => {
  if (items.length === 0) {
    return items.join('\r\n')
  }

  const [first] = items
  const columnNames = headers.length > 0 ? headers : Object.keys(first)
  const values = items.map((row) => {
    return columnNames
      .map((columnName) => {
        const value = row[columnName]
        return value === null ? '' : value
      })
      .join(',')
  })
  return [columnNames.join(','), ...values].join('\r\n')
}

/**
 *
 * This function extract permission available for a specific
 * tool, and it's associated policy
 * this return if a user is authorised to have sometool : some role
 * avialble to them
 *
 */
export const isPermissionAvailable = (
  availableToolPolicies: ValidPolicies[],
  requiredPolicy: ValidPolicies,
) => {
  return availableToolPolicies && availableToolPolicies.includes(requiredPolicy)
}

export const formatFormErrors = (
  errors: {
    values: { path: string; min?: number; max?: number }
    key: string
  }[],
  fieldPrefix: string,
) => {
  return errors.reduce((acc, fieldErr) => {
    const { values, key } = fieldErr
    const { path, min, max } = values
    const translatedPath = t(`${fieldPrefix}.${path}`)
    acc[path] = t(key, { path: translatedPath, min, max })
    return acc
  }, {})
}

export const downloadZip = async function (
  data: {
    fileName: string
    fileExtension: 'xml' | 'txt'
    fileContent: string
    size: number
  }[],
  directoryName: string,
  includeTimestamp = true,
) {
  const zip = new Zip()
  if (includeTimestamp) {
    const longDate = DateTime.now().toFormat('yyyy-MM-dd-HH:mm:ss')
    directoryName += `-${longDate}`
  }

  data.forEach((val) =>
    zip.file(
      `${directoryName}/${val.fileName}.${val.fileExtension}`,
      val.fileContent,
    ),
  )

  const content = await zip.generateAsync({ type: 'blob' })

  const link = document.createElement('a')
  const fileUrl = URL.createObjectURL(content)

  link.setAttribute('href', fileUrl)
  link.setAttribute('download', `${directoryName}.zip`)

  document.body.appendChild(link) // Required for FF

  link.click()
}

export function csvToJson<T>(
  file: File,
  previewLimit: number = 10000,
  dynamicTyping: boolean = false,
  hasHeaders: boolean = true,
  skipEmptyLines: boolean = true,
): Promise<PapaparseReturn<T>> {
  return new Promise((res) => {
    PapaParse.parse(file, {
      header: hasHeaders,
      dynamicTyping,
      skipEmptyLines: skipEmptyLines,
      preview: previewLimit + 1,
      complete: (result) =>
        res({
          data: result.data,
          headers: result.meta.fields,
        }),
    })
  })
}

export function validateArrayWithZod<T>(items: T[], validationSchema: ZodType) {
  return z.array(validationSchema).parse(items)
}
