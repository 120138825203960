import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import { getAlertConfig } from 'shared/podServiceClient'
import useGetOrgUsers from 'shared/hooks/useGetOrgUsers'
import NotFound from '../../../root/pages/NotFound'
import { AggregationFn, AlertCreateSchema, TimeGroup } from '../types'
import AlertDetailsSummary from './AlertDetailsSummary'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`

type Props = {
  alertId: string
}
/**
 *
 * This is a summary component to show a summary of an alert
 *
 */
const AlertConfigSummary = ({ alertId }: Props) => {
  const {
    data: alert,
    error,
    isLoading,
  } = useQuery(['alertId', alertId], () => getAlertConfig(alertId))

  const { data: orgUsers, isLoading: isLoadingOrgUsers } = useGetOrgUsers()
  if (isLoading || isLoadingOrgUsers) {
    return <IWLoading />
  }

  if (error) {
    return <IWError />
  }
  if (!alert) {
    return <NotFound />
  }

  const recipients =
    orgUsers?.list
      .filter((user) => alert.recipients?.includes(user.id))
      .map((u) => u.email) ?? []

  const alertSchema: AlertCreateSchema = {
    timezone: alert.timezone,
    attributeFilters: alert.attributeFilters,
    userAlertName: alert.userAlertName,
    userPodId: alert.userPodId,
    aggregationFnId: alert.aggregationFnId as AggregationFn,
    timeGroupId: alert.timeGroupId as TimeGroup,
    alertConditions: alert.alertConditions || [],
    splits: alert.splits,
    timeOffset: alert.timeOffset,
    shouldMatchAllConditions: alert.shouldMatchAllConditions,
    isPaused: alert.isPaused,
    recipients,
  }

  return (
    <StyledDiv>
      {alertSchema && (
        <AlertDetailsSummary
          aggregationFnId={alertSchema.aggregationFnId}
          timeGroupId={alertSchema.timeGroupId}
          splits={alertSchema.splits}
          attributeFilters={alertSchema.attributeFilters}
          timeOffset={alertSchema.timeOffset}
          alertConditions={alertSchema.alertConditions}
          shouldMatchAllConditions={alertSchema.shouldMatchAllConditions}
          recipients={alertSchema.recipients}
          userAlertName={alertSchema.userAlertName}
          isPaused={alertSchema.isPaused}
        />
      )}
    </StyledDiv>
  )
}

export default AlertConfigSummary
