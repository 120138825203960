import React, { ChangeEvent, SyntheticEvent } from 'react'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import { useTranslation } from 'react-i18next'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import {
  MAX_NAME_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from './DataAccessCreateModal'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import { useNavigate } from 'react-router'
import useCopyText from 'shared/hooks/useCopyText'

const StyledModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
interface Props {
  isModalOpen: boolean
  credentialId: string
  clientId: string
  name: string
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void
  description: string
  onDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void
  hasErrorName: boolean
  onCancel: (e: SyntheticEvent) => void
  isCancelButtonDisabled: boolean
  isSaveButtonDisabled: boolean
  onSave: (
    e: SyntheticEvent,
    credentialId: string,
    name: string,
    description: string,
  ) => void
}
const DataAccessEditModal = ({
  isModalOpen,
  clientId,
  credentialId,
  name,
  onNameChange,
  description,
  onDescriptionChange,
  hasErrorName,
  onCancel,
  isCancelButtonDisabled,
  isSaveButtonDisabled,
  onSave,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { copyText } = useCopyText()
  return (
    <IWModal open={isModalOpen} onClose={onCancel}>
      <IWModalHeader>
        <IWTypography as="h1" size="lg" weight="medium">
          {t('systemPreferences.integrations.dataAccess.editCredentialHeader')}
        </IWTypography>
      </IWModalHeader>
      <StyledModalContent>
        <IWTypography
          as="p"
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t(
            'systemPreferences.integrations.dataAccess.newCredentialDescription',
          )}
        </IWTypography>
        <StyledSection>
          <IWTypography
            as="p"
            size="sm"
            weight="medium"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {t('systemPreferences.integrations.dataAccess.hostUrl')}
          </IWTypography>
          <IWTypography
            as="p"
            size="sm"
            weight="regular"
            fontHue={{ color: 'grey', value: 600 }}
          >
            https://competitive.edge.innowatts.net/api/v1/integration/data/
            <strong>
              ${'{'}reportId{'}'}
            </strong>
          </IWTypography>
          <IWAlert
            label={t(
              'systemPreferences.integrations.dataAccess.hostUrlExplanation',
            )}
            color="primary"
            variant="alternative"
            actionButtons={[
              {
                text: t(
                  'systemPreferences.integrations.dataAccess.viewAllInnoPodsButton',
                ),
                action: () => navigate('/tools/insights-manager'),
              },
            ]}
            shouldButtonsBeInNewLine
          />
        </StyledSection>
        <StyledSection>
          <IWTypography
            as="p"
            size="sm"
            weight="medium"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {t('systemPreferences.integrations.dataAccess.clientId')}
          </IWTypography>
          <IWTypography
            as="p"
            size="sm"
            weight="regular"
            fontHue={{ color: 'grey', value: 600 }}
          >
            {clientId}
          </IWTypography>
          <div>
            <IWButton
              variant="outline"
              color="primary"
              icon={faCopy}
              iconPosition="leading"
              onClick={() => copyText(clientId)}
            >
              {t(
                'systemPreferences.integrations.dataAccess.copyClientIdButton',
              )}
            </IWButton>
          </div>
        </StyledSection>
        <IWTextInput
          required
          maxLength={MAX_NAME_LENGTH}
          hasError={hasErrorName}
          value={name}
          onChange={onNameChange}
          label={t(
            'systemPreferences.integrations.dataAccess.newCredentialNameInput',
          )}
        />
        <IWTextInput
          maxLength={MAX_DESCRIPTION_LENGTH}
          value={description}
          onChange={onDescriptionChange}
          label={t(
            'systemPreferences.integrations.dataAccess.newCredentialDescriptionInput',
          )}
        />
      </StyledModalContent>
      <StyledModalFooter>
        <IWButton
          disabled={isCancelButtonDisabled}
          color="grey"
          variant="outline"
          onClick={onCancel}
        >
          {t('button.cancel')}
        </IWButton>
        <IWButton
          disabled={isSaveButtonDisabled}
          onClick={(e) => {
            onSave(e, credentialId, name, description)
          }}
        >
          {t('button.save')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default DataAccessEditModal
