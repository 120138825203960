import { useState } from 'react'
import type { FilterBadgesOptionsState } from 'shared/components/thunderbolt/IWFilterBadgeOptions'
import type { FilterBadgesRangeState } from 'shared/components/thunderbolt/IWFilterBadgeRange'
import { ReturnProps as UseRelativeDatePickerProps } from 'shared/hooks/useIWRelativeDatePicker'

type FilterBadge<T, V> = {
  type: T
  value?: V
  isInView: boolean
  isOpen: boolean
  defaultOptions?: string[]
  currentOptions?: string[]
}

type DatetimeDataType = FilterBadge<'datetime', UseRelativeDatePickerProps>
type NumberDataType = FilterBadge<'number', FilterBadgesRangeState>
type StringDataType = FilterBadge<'string', FilterBadgesOptionsState>

// TODO: Extract type and value to get better typing
export type UseFilterBadgesHookProps = {
  [filterName: string]: StringDataType | NumberDataType | DatetimeDataType
}

const useFilterBadges = (defaultFilters?: UseFilterBadgesHookProps) => {
  const [filtersState, setFiltersState] = useState<UseFilterBadgesHookProps>(
    defaultFilters || {},
  )

  const handleFilterAdd = (id: string) => {
    setFiltersState((prev) => {
      const filter = { ...prev }
      filter[id].isInView = true
      filter[id].isOpen = true
      return filter
    })
  }

  const handleFilterClose = (id: string) => {
    setFiltersState((prev) => {
      const filter = { ...prev }
      filter[id].isOpen = false
      return filter
    })
  }

  const setDefaultOptions = (id: string, options: string[]) => {
    setFiltersState((prev) => {
      const filter = { ...prev }
      if (filter[id].type === 'string') {
        filter[id].defaultOptions = options
      }
      return filter
    })
  }

  const handleFilterOpen = (id: string) => {
    setFiltersState((prev) => {
      const filter = { ...prev }
      filter[id].isOpen = true
      return filter
    })
  }

  const handleFilterConfirm = (id, state) => {
    setFiltersState((prev) => {
      const filter = { ...prev }
      filter[id].value = state
      filter[id].isOpen = false
      return filter
    })
  }

  const handleFilterRemove = (id) => {
    setFiltersState((prev) => {
      const filter = { ...prev }
      filter[id].isInView = false
      filter[id].value = undefined
      return filter
    })
  }

  return {
    filtersState,
    filtersInView: Object.entries(filtersState)
      .filter(([, value]) => value.isInView)
      .map(([key, value]) => ({
        name: key,
        type: value.type,
        isInView: value.isInView,
        isOpen: value.isOpen,
        defaultOptions: value.defaultOptions,
      })),
    handleFilterAdd,
    handleFilterConfirm,
    handleFilterRemove,
    setDefaultFilters: setFiltersState,
    handleFilterClose,
    handleFilterOpen,
    setDefaultOptions,
  }
}

export default useFilterBadges
