import React, { useEffect, useState } from 'react'
import useGetOrgUsers from 'shared/hooks/useGetOrgUsers'
import { OrgUser } from 'shared/userServiceClient'
import AlertDetailsSummary from './AlertDetailsSummary'
import { AlertCreateSchema } from '../types'
import { WizardStepProps } from '../../../shared/types'

export type Props = WizardStepProps<AlertCreateSchema>

export default function SummaryAlertStep({ state }: Props) {
  const [selectedOrgUsers, setSelectedOrgUsers] = useState<OrgUser[]>([])
  const { data: orgUsers, isLoading: initializing } = useGetOrgUsers()

  useEffect(() => {
    if (initializing) {
      return
    }

    if (state?.recipients?.length && orgUsers?.list.length) {
      const filteredUsers = orgUsers.list.filter((user) =>
        state.recipients.includes(user.id),
      )
      setSelectedOrgUsers(filteredUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing, orgUsers])

  return (
    <>
      {state && (
        <AlertDetailsSummary
          aggregationFnId={state.aggregationFnId}
          timeGroupId={state.timeGroupId}
          splits={state.splits}
          attributeFilters={state.attributeFilters}
          timeOffset={state.timeOffset}
          alertConditions={state.alertConditions}
          shouldMatchAllConditions={state.shouldMatchAllConditions}
          recipients={selectedOrgUsers.map((u) => u.email)}
          userAlertName={state.userAlertName}
          isPaused={state.isPaused}
        />
      )}
    </>
  )
}
