import React, { ReactEventHandler, SyntheticEvent } from 'react'
import styled from 'styled-components'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import IWTypography from './IWTypography'
import IWBadgeGroup from './IWBadgeGroup'
import IWButton from './IWButton'
import IWCardBadge, { Props as CardBadgeProps } from './IWCardBadge'
import { AvailableColors, DefaultVariantHues } from '../../../@types/styled'
import { getBackgroundColor, getFontColor } from './utils/utils'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const defaultVariantHues: DefaultVariantHues = {
  primary: {
    background: {
      color: 'primary',
      value: 900,
    },
    font: {
      color: 'grey',
      value: 0,
    },
  },
  secondary: {
    background: {
      color: 'secondary',
      value: 500,
    },
    font: {
      color: 'grey',
      value: 0,
    },
  },
  alert: {
    background: {
      color: 'alert',
      value: 50,
    },
    font: {
      color: 'alert',
      value: 800,
    },
  },
  warning: {
    background: {
      color: 'warning',
      value: 50,
    },
    font: {
      color: 'warning',
      value: 900,
    },
  },
  grey: {
    background: {
      color: 'grey',
      value: 200,
    },
    font: {
      color: 'grey',
      value: 600,
    },
  },
}

interface GeneralProps {
  /** string will appear as the item header */
  header: string
  /** FontAwesome icon to display on the lefthand side */
  icon?: FontAwesomeIconProps['icon'] | IconDefinition
  /** Icon box background color */
  iconColor?: AvailableColors
  /** Optional description that appears under header */
  description?: string
  /** Different descriptive `<CardBadge />` */
  badges?: Array<CardBadgeProps>
  /** tells if product cardis selected in case of a list */
  isSelected?: boolean
}

interface WithActionButton extends GeneralProps {
  /** AnchorMain variant button text and click action */
  actionButton: {
    text: string
    action: (e: SyntheticEvent) => void
  }
  onClick?: never
  isDisabled?: never
}

export interface WithClick extends GeneralProps {
  /** Prop to make entire div  */
  onClick: ReactEventHandler
  actionButton?: never
  /** shoudl the "button" be disabled */
  isDisabled?: boolean
}

interface WithNothing extends GeneralProps {
  onClick?: never
  actionButton?: never
  isDisabled?: never
}

export type Props = WithActionButton | WithClick | WithNothing

const StyledCardWrapper = styled.li<{
  isClickable: boolean
  isSelected?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: ${(props) => (props.isSelected ? '0.4rem' : '0.375rem')};
  border: ${(props) =>
    props.isSelected
      ? `1px solid ${props.theme.palette.primary[700]}`
      : `1px solid ${props.theme.palette.grey[0]}`};
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.palette.primary[50]
      : props.theme.palette.grey[0]};
  cursor: ${(props) => {
    if (props.isClickable) {
      return 'pointer'
    }
    if (props.isDisabled) {
      return 'not-allowed'
    }
    return 'default'
  }};

  &:hover {
    background-color: ${(props) =>
      !props.isSelected && !props.isDisabled && props.isClickable
        ? props.theme.palette.grey[100]
        : ''};
  }
`

const StyledIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    getFontColor(props, { ...props.theme, defaultVariantHues })};
  font-size: 1.5rem;
  background-color: ${(props) =>
    getBackgroundColor(props, { ...props.theme, defaultVariantHues })};
  border-radius: 0.375rem;
  width: 2.875rem;
  height: 2.875rem;
  margin-right: 0.5rem;
`

const StyledTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
`

const StyledTypography = styled(IWTypography)`
  display: flex;
`

const StyledHeader = styled.div`
  margin-bottom: 0.25rem;
`

const StyledDescription = styled.div`
  margin-bottom: 0.25rem;
  text-align: left;
`

const StyledLink = styled.div`
  text-align: left;
  margin-top: 0.5rem;
`

/**
 * Product Card component would be used to detail every product in the company.
 * This component would be wrapped inside another component 'ProductList' which would show us all the products in a list form.
 */
const IWProductCard = ({
  header,
  icon,
  iconColor,
  description,
  actionButton,
  badges,
  isSelected,
  onClick,
  isDisabled,
}: Props) => {
  return (
    <StyledCardWrapper
      role="button"
      data-testid="product-card"
      isSelected={isSelected}
      isClickable={Boolean(onClick)}
      isDisabled={isDisabled}
      onClick={(e) => {
        e.preventDefault()
        if (onClick && !isDisabled) onClick(e)
      }}
    >
      {icon && (
        <StyledIconWrapper color={iconColor}>
          <FontAwesomeIcon icon={icon} />
        </StyledIconWrapper>
      )}

      <StyledTextWrapper>
        <StyledHeader>
          <StyledTypography
            size="base"
            weight="medium"
            fontHue={{ color: 'grey', value: 900 }}
          >
            {header}
          </StyledTypography>
        </StyledHeader>
        {description && (
          <StyledDescription>
            <StyledTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 500 }}
            >
              {description}
            </StyledTypography>
          </StyledDescription>
        )}
        {badges && (
          <IWBadgeGroup>
            {badges.map((badge) => (
              <IWCardBadge
                key={badge.label}
                label={badge.label}
                icon={badge.icon}
                iconColor={badge.iconColor}
                colorValue={badge.colorValue}
              />
            ))}
          </IWBadgeGroup>
        )}
        {actionButton && (
          <StyledLink>
            <IWButton onClick={actionButton.action} variant="anchorMain">
              {actionButton.text}
            </IWButton>
          </StyledLink>
        )}
      </StyledTextWrapper>
    </StyledCardWrapper>
  )
}

export default IWProductCard
