import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import IWToastList, { IWToastProps } from './IWToastList'

type ToastContextType = {
  primary: (label, options?) => string
  secondary: (label, options?) => string
  warning: (label, options?) => string
  alert: (label, options?) => string
  grey: (label, options?) => string
  removeToast: (id: string) => void
  clearAll: () => void
}

const ToastContext = React.createContext<ToastContextType>({
  primary: () => '',
  secondary: () => '',
  warning: () => '',
  alert: () => '',
  grey: () => '',
  removeToast: () => {},
  clearAll: () => {},
})

type ToastProviderProps = {
  children: ReactNode
}

const TIMEOUT = 1000 * 3 // 3s

const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<IWToastProps[]>([])
  const [timers, setTimers] = useState<any>([])
  const { t } = useTranslation()

  const removeToast = (id: string) => {
    setToasts((prevToasts) => [
      ...prevToasts.filter((toast) => toast.id !== id),
    ])
  }

  const clearAll = () => {
    setToasts(() => [])
  }

  const addToast = (options) => {
    const id = uuidv4()
    const { canDismiss, duration, ...rest } = options
    // Default to alternative for toasts
    rest.variant = rest.variant || 'alternative'
    /*
      If the alert needs to be user dismissible then we need to inject a dismiss button
      so that we can bind its handler to the removeToast function here.
     */
    if (canDismiss) {
      rest.actionButtons = [
        {
          text: t('button.dismiss'),
          action: () => removeToast(id),
        },
        ...(rest.actionButtons ? rest.actionButtons : []),
      ]
    }
    setToasts((prevToasts) => [...prevToasts, { id, ...rest }])
    const newTimer = setTimeout(() => {
      removeToast(id)
    }, duration || TIMEOUT)
    setTimers((prevTimers) => [...prevTimers, newTimer])

    return id
  }

  useEffect(() => {
    return () => {
      // Clean up timers
      timers.forEach(clearTimeout)
    }
  }, [timers])

  const primary = (label, options) =>
    addToast({ color: 'primary', label, ...options })
  const secondary = (label, options) =>
    addToast({ color: 'secondary', label, ...options })
  const alert = (label, options) =>
    addToast({ color: 'alert', label, ...options })
  const warning = (label, options) =>
    addToast({ color: 'warning', label, ...options })
  const grey = (label, options) =>
    addToast({ color: 'grey', label, ...options })

  return (
    <ToastContext.Provider
      value={{
        primary,
        secondary,
        alert,
        warning,
        grey,
        removeToast,
        clearAll,
      }}
    >
      <IWToastList toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
}

/**
 * Provides a hook for easy use of this predefined context within components
 */
const useToast = () => {
  return useContext(ToastContext)
}

export { useToast, ToastProvider }

export default ToastContext
