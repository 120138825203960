import styled from 'styled-components'

/**
 * Generic Card Component that can be used to implement other component.
 * Such as Notification Item, and Application List Item.
 *
 * The `IWGenericCard` is the outer element and
 * should only host any of the following components: `
 * IWGenericCardHeader, IWGenericCardBody, IWGenericCardFooter`
 *
 *
 * NOTE:
 *
 * - While extending `IWGenericCardHeader`, `IWGenericCardBody` into a styled-component, please avoid overriding the `margin` property.
 * As the provided `margin` is to specify `margin` by the UX Documentation.
 * - While extending `IWGenericCardFooter` into a styled-component, please avoid overriding the `border-top` property.
 * As the provided `border-top` is to specify `border-top` by the UX Documentation.
 */
export const IWGenericCard = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.375rem;
`

/**
 * NOTE: While extending into a style-component, please avoid overriding the `margin` property.
 * As the provided `margin` is the specify `margin` by the UX Documentation.
 */
export const IWGenericCardHeader = styled.div`
  margin: 1rem 1rem 0.5rem 1rem;
`

/**
 * NOTE: While extending into a styled-component, please avoid overriding the `margin` property.
 * As the provided `margin` is the specify `margin` by the UX Documentation.
 */
export const IWGenericCardBody = styled.div`
  margin: 0 1rem 1rem 1rem;
`

/**
 * NOTE: While extending into a styled-component, please avoid overriding the `border-top` property.
 * As the provided `border-top` is to specify `border-top` by the UX Documentation.
 */
export const IWGenericCardFooter = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0rem 0rem 0.375rem 0.375rem;
`

export default IWGenericCard
