import React from 'react'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import IWStaticDatePicker, {
  Props as IWStaticDatePickerProps,
} from './IWStaticDatePicker'
import IWTextInput from './IWTextInput'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface Props extends IWStaticDatePickerProps {
  label?: string
  hasError?: boolean
  errorText?: string
}

/** A simple wrapper around the `<IWStaticDatePicker />` that includes a text input for date selection */
const IWDatePicker = ({
  initialView = DateTime.local(),
  weekStartsOn = 7,
  selectedDate,
  onChange,
  id,
  label,
  hasError,
  errorText,
}: Props) => {
  return (
    <StyledDiv data-testid="date-picker">
      <IWTextInput
        hasError={hasError}
        errorText={errorText}
        label={label}
        type="date"
        value={selectedDate?.toISODate()}
        onChange={(d) => {
          const date = DateTime.fromISO(d.target.value)
          if (date.isValid) {
            onChange(date)
          }
        }}
      />
      <IWStaticDatePicker
        id={id}
        initialView={initialView}
        weekStartsOn={weekStartsOn}
        selectedDate={selectedDate}
        onChange={onChange}
      />
    </StyledDiv>
  )
}

export default IWDatePicker
