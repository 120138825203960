import { RetentionPeriod } from 'tools/insightsManager/types'

const generateRententionDuration = (
  retentionPeriod: RetentionPeriod,
): string | undefined => {
  let finalRetentionString: string | undefined

  switch (retentionPeriod) {
    case '1day':
      finalRetentionString = 'P1D'
      break
    case '1week':
      finalRetentionString = 'P1W'
      break
    case '2week':
      finalRetentionString = 'P2W'
      break
    case '3week':
      finalRetentionString = 'P3W'
      break
    case '1month':
      finalRetentionString = 'P1M'
      break
    default:
      finalRetentionString = undefined
      break
  }
  return finalRetentionString
}

export default generateRententionDuration
