import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  getHourOptions,
  getMonthOptions,
  getWeekdayOptions,
} from 'shared/utils/dateUtils'
import IWInputLabel, { IWInputInfo } from './IWCommonLabels'
import IWDropdown from './IWDropdown'
import IWRoundInput from './IWRoundInput'
import { HourNumbers, MonthNumbers, WeekdayNumbers } from 'luxon'

interface DropdownOption<T> {
  value: T
}

export type ScheduleType =
  | 'once'
  | 'hourly'
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'yearly'

type ScheduleDropdownOption = DropdownOption<ScheduleType>

type HourDropdownOption = DropdownOption<HourNumbers>

export type WeekdayDropdownOption = DropdownOption<WeekdayNumbers>

type TimeOfMonth = 'firstOfMonth' | '15thOfMonth' | 'lastOfMonth'

type TimeOfMonthDropdownOption = DropdownOption<TimeOfMonth>

type MonthDropdownOption = DropdownOption<MonthNumbers>

interface ScheduleTypeProps {
  /**
   * schedule type defines which kind of schedule does the user want
   * allowed values are 'once' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'
   * structure of selected value passed back or to the component is in
   * { value : ScheduleType} form
   */
  selectedScheduleTypeOption?: ScheduleDropdownOption
  /**
   * this defines weather the schedule type has error
   * currently the error has shows as red line on dropdown
   */
  isScheduleTypeError?: boolean
  /**
   * this is a event handler for schedule option change
   */
  onScheduleTypeChange: (value: ScheduleDropdownOption) => void
}

interface HourTypeProps {
  /**
   * hourOptions are 0 to 23 hr and a user can select from one of these
   * values to determine the cron schedule hours
   * the selected value supplied or retrieved from input is in form
   * of {value : number}
   */
  selectedHourOption?: HourDropdownOption
  /**
   * defines if the hour dropdown have error or not
   */
  isHourError?: boolean
  /**
   * this is a event handler for hour option change
   */
  onHourChange: (value: HourDropdownOption) => void
}

interface WeekdayTypeProps {
  /**
   * weekDayoptions determine the day of week when the cron is scheduled
   * to run , values supplied or retrieved from input is in the form of
   * { value : number } where number is from 1 to 7 where 1 is monday and
   * 7 is sunday so if monday and saturday are selected in the input the
   * output would be [{value: 1}, {value: 6}]
   */
  selectedWeekdayOption?: WeekdayDropdownOption[]
  /**
   * used to show if there is a error in weekday input
   */
  isWeekdayError?: boolean
  /**
   * event handler for weekdayOtion input change
   */
  onWeekdayChange: (value: WeekdayDropdownOption) => void
}

interface TimeOfMonthProps {
  /**
   * timeOfMonthOptions are to select or retrieve time of month
   * which is either firstOfMonth | 15thOfMonth | lastOfMonth
   * data retrieved is in form of { value : TimeOfMonth}
   */
  selectedTimeOfMonthOption?: TimeOfMonthDropdownOption
  /**
   * show error in time of month dropdown
   */
  isTimeOfMonthError?: boolean
  /**
   * event handler for time of month options
   */
  onTimeOfMonthChange: (value: TimeOfMonthDropdownOption) => void
}

interface MonthProps {
  /**
   * this have selected month info
   * and values are in form of {value : number } where number is month number
   * which starts from 1 and goes till 12 where 1 in jan and 12 is dec
   */
  selectedMonthOption?: MonthDropdownOption
  /**
   * to show error in month dropdown
   */
  isMonthError?: boolean
  /**
   * event handler for month dropdown change
   */
  onMonthChange: (value: MonthDropdownOption) => void
}

const StyledDaySelector = styled.div`
  display: flex;
  gap: 0.5rem;
`

const SchedulePopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledSelect = styled.div`
  flex-grow: 1;
`

const StyledSingleSection = styled.div`
  display: flex;
  gap: 1rem;
`

const IWScheduleTypeSelector = ({
  value,
  onChange,
  error,
  disabledOptions,
}: {
  value: ScheduleDropdownOption | undefined
  onChange: (newVal) => void
  error?: boolean
  disabledOptions?: ScheduleType[]
}) => {
  const { t } = useTranslation()

  const scheduleType: ScheduleDropdownOption[] = useMemo(() => {
    const vals: ScheduleDropdownOption[] = [
      { value: 'once' },
      { value: 'hourly' },
      { value: 'daily' },
      { value: 'weekly' },
      { value: 'monthly' },
      { value: 'yearly' },
    ]
    return vals.map((v) => {
      return {
        ...v,
        isDisabled: disabledOptions?.includes(v.value),
      }
    })
  }, [disabledOptions])

  return (
    <IWDropdown
      options={scheduleType || ''}
      getOptionLabel={(option) => {
        const o = option as ScheduleDropdownOption
        return t(`cronJobScheduleInput.scheduleType.${o.value}`)
      }}
      onChange={onChange}
      value={value}
      hasError={error}
    />
  )
}

const IWWeekdaySelector = ({
  values,
  onChange,
  error,
}: {
  values?: WeekdayDropdownOption[] | undefined
  onChange: (val: WeekdayDropdownOption) => void
  error?: boolean
}) => {
  const { t } = useTranslation()
  const weekDayoptionsRaw = new Map(
    getWeekdayOptions().map(({ label: l, value, labelLong }) => [
      value,
      { label: l, value, labelLong },
    ]),
  )
  const weekDaysOptions: WeekdayDropdownOption[] = useMemo(
    () =>
      Array.from(weekDayoptionsRaw.keys()).map((value) => ({
        value,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <>
      <IWInputLabel label="Repeat Every" />
      <StyledDaySelector>
        {weekDaysOptions.map((d) => {
          const { label, labelLong } = weekDayoptionsRaw.get(d.value) || {
            label: '',
            labelLong: '',
          }
          return (
            <IWRoundInput
              key={d.value}
              aria-label={labelLong}
              title={labelLong}
              type="checkbox"
              id={d.value.toString()}
              name={d.value.toString()}
              label={label}
              value={d.value}
              checked={Boolean(values?.find((w) => w.value === d.value))}
              onChange={(newValue) =>
                onChange({
                  value: Number(newValue.target.value) as WeekdayNumbers,
                })
              }
            />
          )
        })}
      </StyledDaySelector>
      {error && (
        <IWInputInfo errorText={t(`cronJobScheduleInput.inputError`)} />
      )}
    </>
  )
}

const IWHourSelector = ({
  value: selectedHourValue,
  label,
  onChange,
  error,
}: {
  value: HourDropdownOption | undefined
  label: string
  onChange: (val) => void
  error?: boolean
}) => {
  const hourOptionsRaw = new Map(
    getHourOptions().map(({ label: l, value }) => [value, { label: l, value }]),
  )

  const hourOptions: Array<HourDropdownOption> = useMemo(() => {
    return Array.from(hourOptionsRaw.keys()).map((value) => ({
      value,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IWDropdown
      options={hourOptions || ''}
      label={label}
      onChange={onChange}
      getOptionLabel={(option) => {
        const o = option as HourDropdownOption
        return hourOptionsRaw.get(o.value)?.label || ''
      }}
      value={selectedHourValue}
      hasError={error}
    />
  )
}

const IWMonthSelector = ({
  value: selectedMonthValue,
  onChange,
  error,
}: {
  value: MonthDropdownOption | undefined
  onChange: (val) => void
  error?: boolean
}) => {
  const monthOptionsRaw = new Map(
    getMonthOptions().map(({ label: l, value }) => [
      value,
      { label: l, value },
    ]),
  )

  const monthList: MonthDropdownOption[] = useMemo(() => {
    return Array.from(monthOptionsRaw.keys()).map((value) => ({
      value,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IWDropdown
      options={monthList || ''}
      label="Repeat Every"
      onChange={onChange}
      getOptionLabel={(option) => {
        const o = option as MonthDropdownOption
        return monthOptionsRaw.get(o.value)?.label || ''
      }}
      value={selectedMonthValue}
      hasError={error}
    />
  )
}

const IWTimeOfMonthSelector = ({
  value,
  label,
  onChange,
  error,
}: {
  value: TimeOfMonthDropdownOption | undefined
  label: string
  onChange: (val) => void
  error?: boolean
}) => {
  const { t } = useTranslation()

  const timeOfMonthOptions: TimeOfMonthDropdownOption[] = useMemo(() => {
    return [
      {
        value: 'firstOfMonth',
      },
      {
        value: '15thOfMonth',
      },
      {
        value: 'lastOfMonth',
      },
    ]
  }, [])

  return (
    <IWDropdown
      value={value}
      options={timeOfMonthOptions || ''}
      getOptionLabel={(option) => {
        const o = option as TimeOfMonthDropdownOption
        return t(`cronJobScheduleInput.timeOfMonth.${o.value}`)
      }}
      label={label}
      onChange={onChange}
      hasError={error}
    />
  )
}

export interface CronScheduleSelectorErrorState {
  isScheduleTypeError: ScheduleTypeProps['isScheduleTypeError']
  isHourError: HourTypeProps['isHourError']
  isWeekdayError: WeekdayTypeProps['isWeekdayError']
  isTimeOfMonthError: TimeOfMonthProps['isTimeOfMonthError']
  isMonthError: MonthProps['isMonthError']
  hasErrors: boolean
}

export type SharedCronJobScheduleProps = ScheduleTypeProps &
  HourTypeProps &
  WeekdayTypeProps &
  TimeOfMonthProps &
  MonthProps

export type Props = SharedCronJobScheduleProps & {
  shouldDisplayErrors?: boolean
  disabledOptions?: ScheduleType[]
}

/**
 * The Contextual Menu to be used throughout the app to display a list of IWContextMenuItems
 * This wraps the PopOver component and merely handles the open state,
 * the accepted input is in form of <CronScheduleSelectorInputState> which would prepopulate
 * the input which could be used to display things coming from db
 */
const IWCronJobSelector = ({
  selectedScheduleTypeOption,
  isScheduleTypeError,
  onScheduleTypeChange,
  selectedHourOption,
  isHourError,
  onHourChange,
  selectedWeekdayOption,
  isWeekdayError,
  onWeekdayChange,
  selectedTimeOfMonthOption,
  isTimeOfMonthError,
  onTimeOfMonthChange,
  selectedMonthOption,
  isMonthError,
  onMonthChange,
  shouldDisplayErrors,
  disabledOptions,
}: Props) => {
  const { t } = useTranslation()

  const translatedText = {
    selectHours: t('cronJobScheduleInput.selectHours'),
    repeatEvery: t('cronJobScheduleInput.repeatEvery'),
    on: t('prepositions.on'),
    at: t('prepositions.at'),
  }

  return (
    <SchedulePopupDiv data-testid="cron-selector">
      <IWScheduleTypeSelector
        onChange={(newValue) => {
          onScheduleTypeChange(newValue)
        }}
        disabledOptions={disabledOptions}
        value={selectedScheduleTypeOption}
        error={shouldDisplayErrors && isScheduleTypeError}
      />
      {selectedScheduleTypeOption?.value === 'daily' && (
        <IWHourSelector
          value={selectedHourOption}
          label={translatedText.selectHours}
          onChange={(newValue) => onHourChange(newValue)}
          error={shouldDisplayErrors && isHourError}
        />
      )}
      {selectedScheduleTypeOption?.value === 'weekly' && (
        <IWWeekdaySelector
          onChange={(newValue) => onWeekdayChange(newValue)}
          values={selectedWeekdayOption}
          error={shouldDisplayErrors && isWeekdayError}
        />
      )}
      {selectedScheduleTypeOption?.value === 'yearly' && (
        <IWMonthSelector
          onChange={(newValue) => onMonthChange(newValue)}
          value={selectedMonthOption}
          error={shouldDisplayErrors && isMonthError}
        />
      )}
      <StyledSingleSection>
        {selectedScheduleTypeOption?.value === 'monthly' && (
          <>
            <StyledSelect>
              <IWTimeOfMonthSelector
                label={`${translatedText.repeatEvery}`}
                onChange={(newValue) => onTimeOfMonthChange(newValue)}
                value={selectedTimeOfMonthOption}
                error={shouldDisplayErrors && isTimeOfMonthError}
              />
            </StyledSelect>
            <StyledSelect>
              <IWHourSelector
                value={selectedHourOption}
                label={translatedText.at}
                onChange={(newValue) => onHourChange(newValue)}
                error={shouldDisplayErrors && isHourError}
              />
            </StyledSelect>
          </>
        )}
        {selectedScheduleTypeOption?.value === 'yearly' && (
          <>
            <StyledSelect>
              <IWTimeOfMonthSelector
                label={`${translatedText.on}`}
                onChange={(newValue) => onTimeOfMonthChange(newValue)}
                value={selectedTimeOfMonthOption}
                error={shouldDisplayErrors && isTimeOfMonthError}
              />
            </StyledSelect>
            <StyledSelect>
              <IWHourSelector
                value={selectedHourOption}
                label={translatedText.at}
                onChange={(newValue) => onHourChange(newValue)}
                error={shouldDisplayErrors && isHourError}
              />
            </StyledSelect>
          </>
        )}
        {selectedScheduleTypeOption?.value === 'weekly' && (
          <StyledSelect>
            <IWHourSelector
              value={selectedHourOption}
              label={translatedText.at}
              onChange={(newValue) => onHourChange(newValue)}
              error={shouldDisplayErrors && isHourError}
            />
          </StyledSelect>
        )}
      </StyledSingleSection>
    </SchedulePopupDiv>
  )
}

export default IWCronJobSelector
