import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWRadioButton from 'shared/components/thunderbolt/IWRadioButton'
import IWRadioButtonGroup from 'shared/components/thunderbolt/IWRadioButtonGroup'
import type {
  PodType,
  ValidActualUsageMetricIds,
  ValidLongTermForecastMetricIds,
  ValidRiskMetricIds,
  ValidShortTermForecastMetricIds,
  ValidShortTermForecastPerformanceMetricIds,
  ValidShortTermForecastPerformanceSystemMetricIds,
  ValidShortTermForecastPerformanceTopDownMetricIds,
  ValidShortTermForecastSystemMetricIds,
} from 'tools/insightsManager/types'
import { ValidWholesaleSettlementMetricIds } from 'tools/insightsManager/types'
import { MetricObject } from 'tools/insightsManager/utils/getMetrics'
import { WizardStepProps } from '../../../shared/types'

export type ValidMetricIds =
  | ValidWholesaleSettlementMetricIds
  | ValidShortTermForecastMetricIds
  | ValidRiskMetricIds
  | ValidLongTermForecastMetricIds
  | ValidShortTermForecastPerformanceMetricIds
  | ValidShortTermForecastPerformanceTopDownMetricIds
  | ValidShortTermForecastSystemMetricIds
  | ValidActualUsageMetricIds
  | ValidShortTermForecastPerformanceSystemMetricIds

export type Props = WizardStepProps<PodType['metricId']> & {
  metrics: MetricObject<ValidMetricIds>[]
}

export default function SelectPodMetricStep({
  state,
  onChange,
  showErrors,
  metrics,
}: Props) {
  const { t } = useTranslation()
  const [selectedMetric, setSelectedMetric] = useState<
    PodType['metricId'] | undefined
  >(state)
  const [hasSelectedMetricError, setSelectedMetricError] = useState<
    boolean | undefined
  >(false)

  useEffect(() => {
    if (!selectedMetric) {
      setSelectedMetricError(true)
    } else {
      setSelectedMetricError(false)
      onChange(selectedMetric, !hasSelectedMetricError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetric, hasSelectedMetricError])

  return (
    <IWFormSection
      sectionTitle={t(`insightManager.createPod.metricSelector.selectMetric`)}
    >
      <IWRadioButtonGroup
        errorText={
          showErrors ? t(`insightManager.createPod.errors.selectAnOption`) : ''
        }
      >
        {metrics.map(({ label, description, value, isDisabled }) => (
          <IWRadioButton
            id={value}
            disabled={isDisabled}
            key={value}
            checked={selectedMetric === value}
            label={label}
            description={description}
            value={value}
            onChange={() => setSelectedMetric(value)}
          />
        ))}
      </IWRadioButtonGroup>
    </IWFormSection>
  )
}
