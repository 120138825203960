/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { darken, rgba } from 'polished'
import IWInputLabel, { IWInputInfo } from './IWCommonLabels'

interface ExtraProps {
  /** Optional Label to display for this input  */
  label?: string
  /** Boolean to indicate if the input has an error  */
  hasError?: boolean
  /** Optional leading icon and action  */
  leadingIcon?: {
    icon: IconDefinition
    onIconClick?: () => void
  }
  /** Optional trailing icon and action  */
  trailingIcon?: {
    icon: IconDefinition
    onIconClick?: () => void
  }
  /** helper text for additional infomation */
  helperText?: string
  /** error message on input which will display error message */
  errorText?: string
}

type Props = ExtraProps & React.ComponentPropsWithoutRef<'input'>

const getIconColor = (props) => {
  if (props.disabled) {
    return props.theme.palette.grey[400]
  }
  if (!props.disabled && props.hasError) {
    return props.theme.palette.alert[800]
  }
  return props.theme.palette.grey[400]
}

const getIconCursor = (props) => {
  if (props.onClick && !props.disabled) {
    return 'pointer'
  }
  if (props.disabled) {
    return 'not-allowed'
  }
  return 'default'
}

const getFocusBoxShadow = (props) => {
  const color = rgba(props.theme.palette.primary[800], 0.2)
  return `0 0 0.5rem ${color}`
}

const IconWrapper = styled.span<{ onClick; disabled; hasError; iconPosition }>`
  display: flex;
  margin-right: ${(props) =>
    props.iconPosition === 'leading' ? '0.25rem' : undefined};
  margin-left: ${(props) =>
    props.iconPosition === 'trailing' ? '0.25rem' : undefined};
  color: ${getIconColor};
  font-size: 1.125rem;

  &:hover {
    cursor: ${getIconCursor};
    color: ${(props) =>
      props.onClick && !props.disabled && darken(0.2, getIconColor(props))};
  }
`

const StyledInput = styled('input')<{
  disabled?: boolean
}>`
  background-color: transparent;
  color: ${(props) => props.theme.palette.grey[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.sm};
  border: 0;
  outline: 0;
  width: 100%;
  min-width: 1rem;
  padding: 0.5rem 0;
  margin: 0;

  &:disabled {
    color: ${(props) => props.theme.palette.grey[400]};
    cursor: not-allowed;
  }

  &:focus {
    outline: none !important;
  }

  ::placeholder {
    color: ${(props) => props.theme.palette.grey[400]};
    text-transform: capitalize;
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

const StyledPlaceholder = styled('div')<{
  disabled?: boolean
  isPlaceholder: boolean
}>`
  background-color: transparent;
  color: ${(props) =>
    props.isPlaceholder
      ? props.theme.palette.grey[400]
      : props.theme.palette.grey[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.sm};
  border: 0;
  outline: 0;
  width: 100%;
  min-width: 1rem;
  padding: 0.5rem 0;
  margin: 0;
  cursor: pointer;

  &:disabled {
    color: ${(props) => props.theme.palette.grey[400]};
  }

  &:focus {
    outline: none !important;
  }
`

const StyledInputWrapper = styled.div<{
  disabled?: boolean
  hasError?: boolean
}>`
  display: flex;
  gap: 0.25rem;
  align-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.palette.grey[100]
      : props.theme.palette.grey[0]};
  padding: 0 1rem;
  border-radius: 0.375rem;
  border: 1px solid;
  border-color: ${(props) =>
    props.hasError && !props.disabled
      ? props.theme.palette.alert[800]
      : props.theme.palette.grey[300]};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};

  &:hover {
    border-color: ${(props) => {
      if (!props.disabled) {
        return props.hasError
          ? props.theme.palette.alert[800]
          : props.theme.palette.grey[400]
      }
      return props.theme.palette.grey[300]
    }};
  }

  ${StyledInput}:disabled + & {
    pointer-events: none;
  }

  &:focus-within {
    box-shadow: ${getFocusBoxShadow};
    outline: none !important;
  }

  &:focus-within {
    border-color: ${(props) =>
      props.hasError
        ? props.theme.palette.alert[800]
        : props.theme.palette.primary[700]};
    box-shadow: ${(props) => (props.hasError ? 'none' : getFocusBoxShadow)};
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

/** This is a similar component just like input , but this doesn't uses input
 *  this uses a div to emulate input which is required in some of the dropdowns
 */
export const IWInputPlaceholder = React.forwardRef(
  (
    {
      leadingIcon,
      trailingIcon,
      hasError,
      disabled,
      label,
      id,
      helperText,
      errorText,
      placeholder,
      children,
      required,
      ...props
    }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <StyledDiv>
        <IWInputLabel label={label} id={id} required={required} />
        <StyledInputWrapper
          tabIndex={0}
          ref={ref}
          disabled={disabled}
          hasError={hasError}
        >
          {leadingIcon && (
            <IconWrapper
              disabled={disabled}
              hasError={hasError}
              iconPosition="leading"
              role={leadingIcon.onIconClick ? 'button' : 'span'}
              onClick={leadingIcon.onIconClick}
            >
              <FontAwesomeIcon icon={leadingIcon.icon} />
            </IconWrapper>
          )}
          <StyledPlaceholder
            isPlaceholder={!children}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            {children || placeholder}
          </StyledPlaceholder>
          {trailingIcon && (
            <IconWrapper
              disabled={disabled}
              hasError={hasError}
              role={trailingIcon.onIconClick ? 'button' : 'span'}
              iconPosition="trailing"
              onClick={trailingIcon.onIconClick}
            >
              <FontAwesomeIcon icon={trailingIcon.icon} />
            </IconWrapper>
          )}
        </StyledInputWrapper>
        <IWInputInfo helperText={helperText} errorText={errorText} />
      </StyledDiv>
    )
  },
)

export const IWFileInput = React.forwardRef(
  (
    {
      leadingIcon,
      trailingIcon,
      hasError,
      disabled,
      label,
      id,
      helperText,
      errorText,
      required,
      ...props
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <StyledDiv>
        <IWInputLabel label={label} id={id} required={required} />
        <StyledInputWrapper disabled={disabled} hasError={hasError}>
          {leadingIcon && (
            <IconWrapper
              disabled={disabled}
              hasError={hasError}
              iconPosition="leading"
              role={leadingIcon.onIconClick ? 'button' : 'span'}
              onClick={leadingIcon.onIconClick}
            >
              <FontAwesomeIcon icon={leadingIcon.icon} />
            </IconWrapper>
          )}
          <StyledInput
            ref={ref}
            id={id}
            placeholder={props.placeholder}
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
          {trailingIcon && (
            <IconWrapper
              disabled={disabled}
              hasError={hasError}
              role={trailingIcon.onIconClick ? 'button' : 'span'}
              iconPosition="trailing"
              onClick={trailingIcon.onIconClick}
            >
              <FontAwesomeIcon icon={trailingIcon.icon} />
            </IconWrapper>
          )}
        </StyledInputWrapper>
        <IWInputInfo helperText={helperText} errorText={errorText} />
      </StyledDiv>
    )
  },
)

/**
 * Text input component use throughout the app, can have leading and trailing icons
 * and actions
 */
const IWTextInput = React.forwardRef(
  (
    {
      leadingIcon,
      trailingIcon,
      hasError,
      disabled,
      label,
      id,
      helperText,
      errorText,
      required,
      ...props
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <StyledDiv>
        <IWInputLabel label={label} id={id} required={required} />
        <StyledInputWrapper disabled={disabled} hasError={hasError}>
          {leadingIcon && (
            <IconWrapper
              disabled={disabled}
              hasError={hasError}
              iconPosition="leading"
              role={leadingIcon.onIconClick ? 'button' : 'span'}
              onClick={leadingIcon.onIconClick}
            >
              <FontAwesomeIcon icon={leadingIcon.icon} />
            </IconWrapper>
          )}
          <StyledInput
            ref={ref}
            id={id}
            placeholder={props.placeholder}
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
          {trailingIcon && (
            <IconWrapper
              disabled={disabled}
              hasError={hasError}
              role={trailingIcon.onIconClick ? 'button' : 'span'}
              iconPosition="trailing"
              onClick={trailingIcon.onIconClick}
            >
              <FontAwesomeIcon icon={trailingIcon.icon} />
            </IconWrapper>
          )}
        </StyledInputWrapper>
        <IWInputInfo helperText={helperText} errorText={errorText} />
      </StyledDiv>
    )
  },
)

export default IWTextInput
