import { DateTime, Interval } from 'luxon'
import { FilterBadgesOptionsState } from 'shared/components/thunderbolt/IWFilterBadgeOptions'
import { FilterBadgesRangeState } from 'shared/components/thunderbolt/IWFilterBadgeRange'
import { UseFilterBadgesHookProps } from 'shared/hooks/useFilterBadges'
import { ReturnProps as DateFilterState } from 'shared/hooks/useIWRelativeDatePicker'
import {
  DruidBoundFilter,
  DruidFilter,
  DruidInFilter,
  DruidOptionFilter,
  DruidVirtualColumn,
} from 'shared/podServiceClient'
import { formatRelativeDate } from '../helpers'
import { RelativeDateFilter } from '../types'

function buildOptionFilter(
  dimension: string,
  state: FilterBadgesOptionsState,
): DruidOptionFilter {
  const obj: DruidInFilter = {
    type: 'in',
    dimension,
    values: state.values,
  }
  if (state.optionType === 'include') {
    return obj
  }
  // this is exclude
  return {
    type: 'not',
    field: obj,
  }
}

export function buildRangeFilter(
  dimension: string,
  state: FilterBadgesRangeState,
) {
  const boundValues = (key, val) => {
    const map = {
      '>': {
        lower: val,
        lowerStrict: true,
      },
      '>=': {
        lower: val,
        lowerStrict: false,
      },
      '<': {
        upper: val,
        upperStrict: true,
      },
      '<=': {
        upper: val,
        upperStrict: false,
      },
    }
    return map[key]
  }

  let obj: DruidBoundFilter = {
    type: 'bound',
    dimension,
    lower: undefined,
    lowerStrict: undefined,
    upper: undefined,
    upperStrict: undefined,
    ordering: { type: 'numeric' },
  }

  if (
    state.selectedFirstConditionOption !== undefined &&
    state.firstConditionValue !== undefined
  ) {
    const key = state.selectedFirstConditionOption.value
    const value = state.firstConditionValue
    obj = { ...obj, ...boundValues(key, value) }
  }
  if (
    state.selectedSecondConditionOption !== undefined &&
    state.secondConditionValue !== undefined
  ) {
    const key = state.selectedSecondConditionOption.value
    const value = state.secondConditionValue
    obj = { ...obj, ...boundValues(key, value) }
  }
  return obj
}

export function getSpecialDate({
  relativeDate,
  minTime,
  maxTime,
}: {
  relativeDate: RelativeDateFilter
  minTime: DateTime
  maxTime: DateTime
}): DateTime | undefined {
  if (relativeDate.main === 'previous' || relativeDate.main === 'next') {
    let specialDate
    if (relativeDate.relative === 'today') {
      specialDate = DateTime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    }
    if (
      relativeDate.relative === 'specificDate' &&
      relativeDate.specificDate !== undefined
    ) {
      specialDate = DateTime.fromISO(relativeDate.specificDate)
    }
    if (relativeDate.relative === 'lastDateAvailable') {
      specialDate = maxTime
    }
    if (relativeDate.relative === 'firstDateAvailable') {
      specialDate = minTime
    }
    return specialDate
  }
  return undefined
}

export function buildIntervalFilter(
  state: DateFilterState,
  { minTime, maxTime }: { minTime: DateTime; maxTime: DateTime },
  timezone: string = 'UTC',
) {
  const relativeDate = formatRelativeDate(state)
  let interval: Interval | undefined
  const timezoneOption = { zone: timezone }
  if (relativeDate.main === 'on') {
    const date = DateTime.fromISO(relativeDate.specificDate, timezoneOption)
    interval = Interval.fromDateTimes(date, date.plus({ day: 1 }))
  }
  if (relativeDate.main === 'after') {
    const date = DateTime.fromISO(relativeDate.specificDate, timezoneOption)
    interval = Interval.after(date, { years: 1000 })
  }
  if (relativeDate.main === 'before') {
    const date = DateTime.fromISO(relativeDate.specificDate, timezoneOption)
    interval = Interval.before(date, { years: 1000 })
  }
  if (relativeDate.main === 'between') {
    const minDate = DateTime.fromISO(relativeDate.datePickerMin, timezoneOption)
    const maxDate = DateTime.fromISO(relativeDate.datePickerMax, timezoneOption)
    interval = Interval.fromDateTimes(minDate, maxDate)
  }
  if (relativeDate.main === 'previous') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })
    if (specialDate) {
      interval = Interval.fromDateTimes(
        specialDate.setZone(timezone).minus({
          [relativeDate.timeLength]: relativeDate.timeLengthValue,
        }),
        specialDate.setZone(timezone),
      )
    }
  }
  if (relativeDate.main === 'next') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })
    if (specialDate) {
      interval = Interval.fromDateTimes(
        specialDate.setZone(timezone),
        specialDate.setZone(timezone).plus({
          [relativeDate.timeLength]: relativeDate.timeLengthValue,
        }),
      )
    }
  }
  return interval ? interval.toISO({ includeOffset: false }) : undefined
}

export function buildDateFilter(
  dimension: string,
  state: DateFilterState,
  { minTime, maxTime }: { minTime: DateTime; maxTime: DateTime },
  timezone: string = 'UTC',
) {
  const relativeDate = formatRelativeDate(state)
  const timezoneOption = { zone: timezone }
  const virtualColumnDimension = `VIRTUAL_${dimension}`
  const virtualColumn: DruidVirtualColumn = {
    type: 'expression',
    name: virtualColumnDimension,
    expression: `timestamp_parse("${dimension}",null,'${timezone}')`,
    outputType: 'LONG',
  }
  if (relativeDate.main === 'on') {
    const filter = buildRangeFilter(virtualColumnDimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
      selectedSecondConditionOption: { value: '<', label: '' },
      secondConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      )
        .plus({ day: 1 })
        .toMillis(),
    })
    return { filter, virtualColumn }
  }
  if (relativeDate.main === 'after') {
    const filter = buildRangeFilter(virtualColumnDimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
    })
    return { filter, virtualColumn }
  }
  if (relativeDate.main === 'before') {
    const filter = buildRangeFilter(virtualColumnDimension, {
      selectedFirstConditionOption: { value: '<=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
    })
    return { filter, virtualColumn }
  }
  if (relativeDate.main === 'between') {
    const filter = buildRangeFilter(virtualColumnDimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.datePickerMin,
        timezoneOption,
      ).toMillis(),
      selectedSecondConditionOption: { value: '<=', label: '' },
      secondConditionValue: DateTime.fromISO(
        relativeDate.datePickerMax,
        timezoneOption,
      ).toMillis(),
    })
    return { filter, virtualColumn }
  }
  if (relativeDate.main === 'previous') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })

    if (!specialDate) {
      return undefined
    }

    const filter = buildRangeFilter(virtualColumnDimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: specialDate
        .setZone(timezone)
        .minus({ [relativeDate.timeLength]: relativeDate.timeLengthValue })
        .toMillis(),
      selectedSecondConditionOption: { value: '<=', label: '' },
      secondConditionValue: specialDate.setZone(timezone).toMillis(),
    })
    return { filter, virtualColumn }
  }
  if (relativeDate.main === 'next') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })

    if (!specialDate) {
      return undefined
    }

    const filter = buildRangeFilter(virtualColumnDimension, {
      selectedFirstConditionOption: { value: '<=', label: '' },
      firstConditionValue: specialDate
        .setZone(timezone)
        .plus({ [relativeDate.timeLength]: relativeDate.timeLengthValue })
        .toMillis(),
      selectedSecondConditionOption: { value: '>=', label: '' },
      secondConditionValue: specialDate.setZone(timezone).toMillis(),
    })
    return { filter, virtualColumn }
  }
  return undefined
}

export function filtersToDruid(
  filters: UseFilterBadgesHookProps,
  { minTime, maxTime }: { minTime: DateTime; maxTime: DateTime },
  timezone: string = 'UTC',
) {
  const druidFilters: DruidFilter[] = []
  const virtualColumns: DruidVirtualColumn[] = []
  const intervals: string[] = []
  Object.entries(filters).forEach(([dimension, state]) => {
    if (state.value === undefined) {
      return
    }
    if (state.type === 'string') {
      druidFilters.push(buildOptionFilter(dimension, state.value))
    }
    if (state.type === 'number') {
      druidFilters.push(buildRangeFilter(dimension, state.value))
    }
    if (state.type === 'datetime') {
      if (dimension === '__time') {
        const interval = buildIntervalFilter(
          state.value,
          { minTime, maxTime },
          timezone,
        )
        if (interval === undefined) {
          return
        }
        intervals.push(interval)
      } else {
        const dateFilter = buildDateFilter(
          dimension,
          state.value,
          {
            minTime,
            maxTime,
          },
          timezone,
        )
        if (dateFilter === undefined) {
          return
        }
        druidFilters.push(dateFilter.filter)
        virtualColumns.push(dateFilter.virtualColumn)
      }
    }
  })
  return {
    virtualColumns,
    filters: druidFilters,
    intervals:
      intervals.length === 0
        ? [
            Interval.fromDateTimes(minTime, maxTime).toISO({
              includeOffset: false,
            }),
          ]
        : intervals,
  }
}
