/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWCronJobSelectorWrapper from 'shared/components/thunderbolt/IWCronJobSelectorWrapper'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import { DefaultCronSelectorState } from 'shared/hooks/useIWCronJobScheduleSelector'
import { PodType, RetentionPeriodOptions } from 'tools/insightsManager/types'
import usePodBuilderGeneralSetting from 'tools/insightsManager/hooks/usePodBuilderGeneralSetting'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import { DefaultState as DateRange } from 'shared/hooks/useIWRelativeDatePicker'
import { ScheduleType } from 'shared/components/thunderbolt/IWCronJobSelector'
import PodIntegrationsSection from './PodIntegrationsSection'
import usePodIntegrations from '../hooks/usePodIntegrations'
import { useQuery } from 'react-query'
import {
  getSFTPCredentials,
  getThirdPartyIntegrations,
} from 'systemPreferences/integrationsServiceClient'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWRadioButton from 'shared/components/thunderbolt/IWRadioButton'
import IWRadioButtonGroup from 'shared/components/thunderbolt/IWRadioButtonGroup'
import IWCheckbox from 'shared/components/thunderbolt/IWCheckbox'
import { WizardStepProps } from '../../../shared/types'

const MAX_LENGTH = 240
const MIN_LENGTH = 3

const WrapperDivExample = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    width: 100%;
  }

  gap: 1rem;
`

const StyledDiv = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

const FlexDiv = styled.div`
  display: flex;
  & > * {
    margin-right: 1rem;
  }
`

interface StepState {
  userPodName?: string
  cronSelectorInputState?: DefaultCronSelectorState
  retentionPeriod?: RetentionPeriodOptions
  eventBased: boolean
  sftpCredentialIds: string[]
  thirdPartyDriveCredentialIds: string[]
  hasIntegrations: boolean
}

const retentionOptions: RetentionPeriodOptions[] = [
  { value: '1day' },
  { value: '1week' },
  { value: '2week' },
  { value: '3week' },
  { value: '1month' },
  { value: 'indefinitely' },
]

export type Props = WizardStepProps<StepState> & {
  dateRange?: DateRange
  insightId?: PodType['insightId']
  metricId?: PodType['metricId']
}

const PodGeneralSettingStep = ({
  state,
  onChange,
  showErrors,
  dateRange,
  insightId,
  metricId,
}: Props) => {
  const { t } = useTranslation()
  const [hasIntegrations, setHasIntegrations] = useState(false)
  const defaultPodName = `${t(`insights.${insightId}.label`)} - ${t(
    `insights.${insightId}.metrics.${metricId}.label`,
  )}`
  const {
    userPodName,
    isPodNameError,
    onPodNameChange,
    retentionPeriod,
    isRetentionError,
    onRetentionPeriodChange,
    cronSelectorInputState,
    eventBased,
    onEventBasedChange,
    hasError,
  } = usePodBuilderGeneralSetting({
    selectedPodName: state?.userPodName || defaultPodName,
    cronJobSchedule: state?.cronSelectorInputState,
    selectedRetentionPeriod: state?.retentionPeriod,
    selectedEventBased: state?.eventBased || false,
    minLength: MIN_LENGTH,
  })

  const {
    localCredentials: localSftpCredentials,
    handleLocalCredentials: handleLocalSftpCredentials,
    toggleCredentials: toggleSftpCredentials,
  } = usePodIntegrations({
    getName: (r) => r.name,
    getDescription: (r) => `${r.host}:${r.port}/${r.path ?? ''}`,
  })
  const {
    localCredentials: localThirdPartyCredentials,
    handleLocalCredentials: handleLocalThirdPartyCredentials,
    toggleCredentials: toggleLocalThirdPartyCredentials,
  } = usePodIntegrations({
    getName: (r) =>
      t(
        `systemPreferences.integrations.thirdPartyIntegrations.availableIntegrations.${r.thirdPartyDriveId}`,
      ),
    getDescription: (r) => r.email,
  })

  const { data: availableSftpCredentials } = useQuery(
    ['sftpCredentials'],
    () => {
      return getSFTPCredentials(0, 100, {
        direction: 'descending',
        column: 'created_at',
      }).then((r) => r.rows)
    },
    {
      keepPreviousData: true,
    },
  )

  const { data: availableThirdPartyCredentials } = useQuery(
    ['thirdPartyCredentials'],
    () => {
      return getThirdPartyIntegrations()
    },
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    handleLocalSftpCredentials(availableSftpCredentials, [])
    handleLocalThirdPartyCredentials(availableThirdPartyCredentials, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSftpCredentials, availableThirdPartyCredentials])

  const [isCronOpen, setIsCronOpen] = useState(false)
  const [shouldDisplayCronErrors, setShouldDisplayCronErrors] = useState(false)

  const handleCronConfirm = () => {
    if (cronSelectorInputState.hasErrors) {
      setShouldDisplayCronErrors(true)
    } else {
      setIsCronOpen(false)
      setShouldDisplayCronErrors(false)
    }
  }

  const handleCronCancel = () => {
    cronSelectorInputState.modifyFullState({})
    setIsCronOpen(false)
  }

  useEffect(() => {
    if (isCronOpen) {
      return
    }

    onChange(
      {
        userPodName,
        retentionPeriod,
        cronSelectorInputState,
        eventBased,
        sftpCredentialIds: hasIntegrations
          ? localSftpCredentials.filter((s) => s.isSelected).map((s) => s.id)
          : [],
        hasIntegrations,
        thirdPartyDriveCredentialIds: hasIntegrations
          ? localThirdPartyCredentials
              .filter((s) => s.isSelected)
              .map((s) => s.id)
          : [],
      },
      !hasError,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userPodName,
    retentionPeriod,
    cronSelectorInputState,
    eventBased,
    hasError,
    isCronOpen,
    localSftpCredentials,
    localThirdPartyCredentials,
    hasIntegrations,
  ])

  const cronDisabledOptions: ScheduleType[] =
    dateRange?.selectedMainOption?.value === 'on' ||
    dateRange?.selectedMainOption?.value === 'between' ||
    eventBased
      ? ['daily', 'hourly', 'monthly', 'weekly', 'yearly']
      : []

  return (
    <>
      <IWFormSection sectionTitle={t(`insightManager.createPod.general`)}>
        <IWTextInput
          required
          data-testid="pod-general-setting-name"
          label={t(`insightManager.createPod.generalSetting.podNameLabel`)}
          value={userPodName}
          placeholder={defaultPodName}
          maxLength={MAX_LENGTH}
          onChange={(e) => onPodNameChange(e.target.value)}
          hasError={showErrors && isPodNameError}
          errorText={
            showErrors && isPodNameError
              ? t(`insightManager.createPod.errors.characterLengthRequired`, {
                  minLength: MIN_LENGTH,
                  maxLength: MAX_LENGTH,
                })
              : undefined
          }
        />
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(
          `insightManager.createPod.generalSetting.scheduleSelectSection`,
        )}
      >
        <FlexDiv>
          <IWCheckbox
            id="eventBased"
            name="eventBased"
            checked={eventBased}
            label={t(
              `insightManager.createPod.generalSetting.eventBasedInputLabel`,
            )}
            description={t(
              `insightManager.createPod.generalSetting.eventBasedInputDesc`,
            )}
            onChange={(e) => onEventBasedChange(e.target.checked)}
          />
        </FlexDiv>

        <WrapperDivExample>
          <IWCronJobSelectorWrapper
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cronSelectorInputState}
            shouldShowErrorsOutsideInput={
              showErrors && cronSelectorInputState.hasErrors
            }
            disabledOptions={cronDisabledOptions}
            shouldDisplayErrors={shouldDisplayCronErrors}
            isOpen={isCronOpen}
            onConfirm={handleCronConfirm}
            onPlaceholderClick={() => setIsCronOpen(true)}
            onCancel={handleCronCancel}
          />
          <IWDropdown
            options={retentionOptions.map((retentionOption) => {
              const option = { ...retentionOption }
              const cronSelection =
                cronSelectorInputState.selectedScheduleTypeOption?.value
              option.isDisabled =
                (cronSelection === 'once' && option.value !== 'indefinitely') ||
                (cronSelection !== 'once' && option.value === 'indefinitely')
              return option
            })}
            required
            getOptionLabel={(option) => {
              const o = option as RetentionPeriodOptions
              return t(
                `insightManager.createPod.generalSetting.retentionOptions.${o.value}`,
              )
            }}
            value={retentionPeriod}
            label={t(
              `insightManager.createPod.generalSetting.retentionPeriodLabel`,
            )}
            helperText={t(
              `insightManager.createPod.generalSetting.retentionPeriodDesc`,
            )}
            onChange={(val) =>
              onRetentionPeriodChange(val as RetentionPeriodOptions)
            }
            hasError={showErrors && isRetentionError}
            errorText={
              showErrors && isRetentionError
                ? t(`insightManager.createPod.errors.enterAllRequired`)
                : undefined
            }
          />
        </WrapperDivExample>
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(
          `insightManager.createPod.generalSetting.additionalOutput`,
        )}
      >
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 400 }}>
          {t(`insightManager.createPod.generalSetting.additionalOutputDesc`)}
        </IWTypography>
        <IWRadioButtonGroup direction="row">
          <IWRadioButton
            id="hide"
            name="hide"
            checked={!hasIntegrations}
            label={t(
              `insightManager.createPod.generalSetting.notNotStoreExternal`,
            )}
            onChange={(e) => setHasIntegrations(!e.target.checked)}
          />
          <IWRadioButton
            id="show"
            name="show"
            checked={hasIntegrations}
            label={t(`insightManager.createPod.generalSetting.storeExternal`)}
            onChange={(e) => setHasIntegrations(e.target.checked)}
          />
        </IWRadioButtonGroup>
        {hasIntegrations && (
          <StyledDiv>
            <PodIntegrationsSection
              isEditable
              availableCredentials={localSftpCredentials || []}
              onCredentialIdToggle={toggleSftpCredentials}
              sectionTitle={t(
                `insightManager.createPod.generalSetting.sftpOptions`,
              )}
            />
            <PodIntegrationsSection
              isEditable
              availableCredentials={localThirdPartyCredentials || []}
              onCredentialIdToggle={toggleLocalThirdPartyCredentials}
              sectionTitle={t(
                `insightManager.createPod.generalSetting.thirdPartyIntegrations`,
              )}
            />
          </StyledDiv>
        )}
      </IWFormSection>
    </>
  )
}

export default PodGeneralSettingStep
