import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { LayoutProvider } from 'shared/contexts/LayoutContext'
import i18n from 'i18n'
import App from 'root/Main'

function ThunderBoltApp() {
  return (
    <I18nextProvider i18n={i18n}>
      <LayoutProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LayoutProvider>
    </I18nextProvider>
  )
}

export default ThunderBoltApp
