import React, { ChangeEvent, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { useNavigate } from 'react-router'
import useCopyText from 'shared/hooks/useCopyText'

export const MAX_NAME_LENGTH = 50
export const MAX_DESCRIPTION_LENGTH = 240

const StyledModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CreateDataAccessModalContent = ({
  name,
  hasErrorName,
  onNameChange,
  description,
  onDescriptionChange,
  isCanceledButtonDisabled,
  isCreateButtonDisabled,
  onCancel,
  onCreate,
}: {
  name: string
  hasErrorName: boolean
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void
  description: string
  onDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void
  isCanceledButtonDisabled: boolean
  isCreateButtonDisabled: boolean
  onCancel: (e: SyntheticEvent) => void
  onCreate: (e: SyntheticEvent) => void
}) => {
  const { t } = useTranslation()
  return (
    <>
      <StyledModalContent>
        <IWTypography
          as="p"
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t(
            'systemPreferences.integrations.dataAccess.newCredentialDescription',
          )}
        </IWTypography>
        <IWTextInput
          required
          maxLength={MAX_NAME_LENGTH}
          hasError={hasErrorName}
          value={name}
          onChange={onNameChange}
          label={t(
            'systemPreferences.integrations.dataAccess.newCredentialNameInput',
          )}
        />
        <IWTextInput
          maxLength={MAX_DESCRIPTION_LENGTH}
          value={description}
          onChange={onDescriptionChange}
          label={t(
            'systemPreferences.integrations.dataAccess.newCredentialDescriptionInput',
          )}
        />
        <IWAlert
          variant="alternative"
          color="primary"
          label={t(
            'systemPreferences.integrations.dataAccess.newCredentialAlert',
          )}
        />
      </StyledModalContent>
      <StyledModalFooter>
        <IWButton
          disabled={isCanceledButtonDisabled}
          color="grey"
          variant="outline"
          onClick={onCancel}
        >
          {t('button.cancel')}
        </IWButton>
        <IWButton disabled={isCreateButtonDisabled} onClick={onCreate}>
          {t('button.create')}
        </IWButton>
      </StyledModalFooter>
    </>
  )
}

const DataAccessConfirmationModalContent = ({
  clientId,
  clientSecret,
  onConfirmation,
}: {
  clientId: string
  clientSecret: string
  onConfirmation: (e: SyntheticEvent) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { copyText } = useCopyText()

  return (
    <>
      <StyledModalContent>
        <IWTypography
          as="p"
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t(
            'systemPreferences.integrations.dataAccess.newCredentialConfirmationDescription',
          )}
        </IWTypography>
        <StyledSection>
          <IWTypography
            as="p"
            size="sm"
            weight="medium"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {t('systemPreferences.integrations.dataAccess.hostUrl')}
          </IWTypography>
          <IWTypography
            as="p"
            size="sm"
            weight="regular"
            fontHue={{ color: 'grey', value: 600 }}
          >
            https://competitive.edge.innowatts.net/api/v1/integration/data/
            <strong>
              ${'{'}podId{'}'}
            </strong>
          </IWTypography>
          <IWAlert
            label={t(
              'systemPreferences.integrations.dataAccess.hostUrlExplanation',
            )}
            color="primary"
            variant="alternative"
            actionButtons={[
              {
                text: t(
                  'systemPreferences.integrations.dataAccess.viewAllInnoPodsButton',
                ),
                action: () => navigate('/tools/insights-manager'),
              },
            ]}
            shouldButtonsBeInNewLine
          />
        </StyledSection>
        <StyledSection>
          <IWTypography
            as="p"
            size="sm"
            weight="medium"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {t('systemPreferences.integrations.dataAccess.clientId')}
          </IWTypography>
          <IWTypography
            as="p"
            size="sm"
            weight="regular"
            fontHue={{ color: 'grey', value: 600 }}
          >
            {clientId}
          </IWTypography>
          <div>
            <IWButton
              variant="outline"
              color="primary"
              icon={faCopy}
              iconPosition="leading"
              onClick={() => copyText(clientId)}
            >
              {t(
                'systemPreferences.integrations.dataAccess.copyClientIdButton',
              )}
            </IWButton>
          </div>
        </StyledSection>
        <StyledSection>
          <IWTypography
            as="p"
            size="sm"
            weight="medium"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {t('systemPreferences.integrations.dataAccess.clientSecret')}
          </IWTypography>
          <IWTypography
            as="p"
            size="sm"
            weight="regular"
            fontHue={{ color: 'grey', value: 600 }}
          >
            {clientSecret}
          </IWTypography>
          <div>
            <IWButton
              variant="outline"
              color="primary"
              icon={faCopy}
              iconPosition="leading"
              onClick={() => copyText(clientSecret)}
            >
              {t(
                'systemPreferences.integrations.dataAccess.copyClientSecretButton',
              )}
            </IWButton>
          </div>
        </StyledSection>
        <IWAlert
          variant="alternative"
          color="warning"
          label={t(
            'systemPreferences.integrations.dataAccess.newCredentialConfirmationWarning',
          )}
        />
      </StyledModalContent>
      <StyledModalFooter>
        <IWButton onClick={onConfirmation}>
          {t('button.copyConfirmation')}
        </IWButton>
      </StyledModalFooter>
    </>
  )
}

interface Props {
  submitStatus: 'waiting' | 'submitting' | 'complete'
  newDataAccessName: string
  hasErrorNewDataAccessName: boolean
  onNewDataAccessNameChange: (e: ChangeEvent<HTMLInputElement>) => void
  newDataAccessDescription: string
  onNewDataAccesDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void
  onModalCancel: (e: SyntheticEvent) => void
  onModalCreate: (e: SyntheticEvent) => void
  newClientId: string | undefined
  newClientSecret: string | undefined
  onModalConfirmCopy: (e: SyntheticEvent) => void
  isModalOpen: boolean
}
const DataAccessCreateModal = ({
  submitStatus,
  newDataAccessName,
  hasErrorNewDataAccessName,
  onNewDataAccessNameChange,
  newDataAccessDescription,
  onNewDataAccesDescriptionChange,
  onModalCancel,
  onModalCreate,
  newClientId,
  newClientSecret,
  onModalConfirmCopy,
  isModalOpen,
}: Props) => {
  const { t } = useTranslation()
  const modalContent = () => {
    if (submitStatus === 'waiting' || submitStatus === 'submitting') {
      return (
        <CreateDataAccessModalContent
          name={newDataAccessName}
          hasErrorName={hasErrorNewDataAccessName}
          onNameChange={onNewDataAccessNameChange}
          description={newDataAccessDescription}
          onDescriptionChange={onNewDataAccesDescriptionChange}
          isCanceledButtonDisabled={submitStatus === 'submitting'}
          isCreateButtonDisabled={
            hasErrorNewDataAccessName || submitStatus === 'submitting'
          }
          onCancel={onModalCancel}
          onCreate={onModalCreate}
        />
      )
    }
    if (
      submitStatus === 'complete' &&
      newClientId !== undefined &&
      newClientSecret !== undefined
    ) {
      return (
        <DataAccessConfirmationModalContent
          clientId={newClientId}
          clientSecret={newClientSecret}
          onConfirmation={onModalConfirmCopy}
        />
      )
    }
  }
  return (
    <IWModal open={isModalOpen}>
      <IWModalHeader>
        <IWTypography as="h1" size="lg" weight="medium">
          {t('systemPreferences.integrations.dataAccess.newCredentialHeader')}
        </IWTypography>
      </IWModalHeader>
      {modalContent()}
    </IWModal>
  )
}

export default DataAccessCreateModal
