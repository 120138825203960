import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const { PUBLIC_URL } = process.env

const loadingUrl = `${PUBLIC_URL}/images/loading.gif`

const StyledLoading = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.grey[0]};
`

const StyledLoadingIconWrapper = styled.div`
  margin: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.125rem;
  height: 8.125rem;
  border-radius: 100%;
  border: ${(props) => `3px solid ${props.theme.palette.grey[100]}`};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);

  & > img {
    border-radius: 8.125rem;
  }
`

const StyledSmallLoading = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: 1rem;
`
export const IWSmallLoading = () => {
  return <StyledSmallLoading icon={faSyncAlt} spin />
}

/**
 * Simple loading component, displays the loading icon in the center of a white div.
 *
 */
const IWLoading = () => {
  return (
    <StyledLoading data-testid="loading">
      <StyledLoadingIconWrapper>
        <img src={loadingUrl} alt="Loading" />
      </StyledLoadingIconWrapper>
    </StyledLoading>
  )
}

export default IWLoading
