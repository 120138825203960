import { DefaultState as RelativeDatePickerDefaultState } from 'shared/hooks/useIWRelativeDatePicker'
import {
  AttributeFilter,
  CreatePodSchema,
  RangeCondition,
} from 'tools/insightsManager/types'
import { MeasureRangeFilter } from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import { LimitFilter } from 'tools/insightsManager/hooks/useLimitFilters'
import { MeasureCondition } from 'shared/hooks/useMeasureRangeConditions'
import {
  AlertCondition,
  AlertCreateSchema,
  MeasureConditionValueTypes,
} from 'tools/alertsManager/types'

export function formatRelativeDate({
  selectedMainOption,
  selectedSpecificDatePickerDate,
  selectedDatePickerMin,
  selectedDatePickerMax,
  selectedRelativeOption,
  timeLengthValue,
  selectedTimeLengthOption,
}: RelativeDatePickerDefaultState): CreatePodSchema['relativeDateFilter'] {
  if (
    selectedMainOption?.value === 'on' &&
    selectedSpecificDatePickerDate !== undefined
  ) {
    return {
      main: 'on',
      specificDate: selectedSpecificDatePickerDate.toISODate(),
    }
  }
  if (
    selectedMainOption?.value === 'after' &&
    selectedSpecificDatePickerDate !== undefined
  ) {
    return {
      main: 'after',
      specificDate: selectedSpecificDatePickerDate.toISODate(),
    }
  }
  if (
    selectedMainOption?.value === 'before' &&
    selectedSpecificDatePickerDate !== undefined
  ) {
    return {
      main: 'before',
      specificDate: selectedSpecificDatePickerDate.toISODate(),
    }
  }
  if (
    selectedMainOption?.value === 'between' &&
    selectedDatePickerMin !== undefined &&
    selectedDatePickerMax !== undefined
  ) {
    return {
      main: 'between',
      datePickerMin: selectedDatePickerMin.toISODate(),
      datePickerMax: selectedDatePickerMax.toISODate(),
    }
  }
  if (
    selectedMainOption?.value === 'previous' &&
    selectedRelativeOption?.value !== undefined &&
    timeLengthValue !== undefined &&
    selectedTimeLengthOption !== undefined &&
    selectedRelativeOption.value !== 'firstDateAvailable'
  ) {
    if (selectedRelativeOption.value === 'specificDate') {
      return {
        main: 'previous',
        relative: 'specificDate',
        timeLength: selectedTimeLengthOption.value,
        timeLengthValue,
        specificDate: selectedSpecificDatePickerDate?.toISODate(),
      }
    }
    return {
      main: 'previous',
      relative: selectedRelativeOption.value,
      timeLength: selectedTimeLengthOption.value,
      timeLengthValue,
    }
  }
  if (
    selectedMainOption?.value === 'next' &&
    selectedRelativeOption?.value !== undefined &&
    timeLengthValue !== undefined &&
    selectedTimeLengthOption !== undefined &&
    selectedRelativeOption.value !== 'lastDateAvailable'
  ) {
    if (selectedRelativeOption.value === 'specificDate') {
      return {
        main: 'next',
        relative: 'specificDate',
        timeLength: selectedTimeLengthOption.value,
        timeLengthValue,
        specificDate: selectedSpecificDatePickerDate?.toISODate(),
      }
    }
    return {
      main: 'next',
      relative: selectedRelativeOption.value,
      timeLength: selectedTimeLengthOption.value,
      timeLengthValue,
    }
  }
  throw new Error('Bad date picker')
}

export function formatRangeFilters(
  filters: MeasureRangeFilter[],
): CreatePodSchema['rangeFilters'] {
  const rangeFilters: CreatePodSchema['rangeFilters'] = []
  filters.forEach((elem) => {
    if (!elem.selectedMeasureRangeFilterOption) {
      return
    }
    const firstValue = elem.firstConditionValue
    const secondValue = elem.secondConditionValue
    const firstCondition = elem.selectedFirstConditionOption?.value
    const secondCondition = elem.selectedSecondConditionOption?.value
    const conditions: RangeCondition[] = []

    if (
      firstCondition !== undefined &&
      !elem.isFirstConditionDisabled &&
      firstValue !== undefined
    ) {
      conditions.push({ operator: firstCondition, value: firstValue })
    }
    if (
      secondCondition !== undefined &&
      !elem.isSecondConditionDisabled &&
      secondValue !== undefined
    ) {
      conditions.push({ operator: secondCondition, value: secondValue })
    }
    rangeFilters.push({
      attribute: elem.selectedMeasureRangeFilterOption.value,
      conditions,
    })
  })
  return rangeFilters.length > 0 ? rangeFilters : undefined
}

export function formatMeasureConditionsFilters(
  filters: MeasureCondition[],
): AlertCreateSchema['alertConditions'] {
  const rangeFilters: AlertCreateSchema['alertConditions'] = []
  filters.forEach((elem) => {
    if (!elem.selectedMeasureOption || !elem.selectedValueTypeOption) {
      return
    }
    const firstValue = elem.firstConditionValue
    const secondValue = elem.secondConditionValue
    const firstCondition = elem.selectedFirstConditionOption?.value
    const secondCondition = elem.selectedSecondConditionOption?.value
    const conditions: AlertCondition['conditions'] = []

    if (firstCondition !== undefined && firstValue !== undefined) {
      conditions.push({ operator: firstCondition, value: firstValue })
    }
    if (secondCondition !== undefined && secondValue !== undefined) {
      conditions.push({ operator: secondCondition, value: secondValue })
    }

    rangeFilters.push({
      attribute: elem.selectedMeasureOption.value,
      valueType: elem.selectedValueTypeOption
        .value as MeasureConditionValueTypes,
      conditions,
    })
  })
  return rangeFilters
}

export function formatLimitFilter(
  filter: LimitFilter,
): CreatePodSchema['podLimit'] {
  const {
    selectedEdgeOption,
    selectedMeasureOption,
    limitValue,
    selectedTypeOption,
  } = filter
  if (
    selectedEdgeOption &&
    selectedMeasureOption &&
    limitValue !== undefined &&
    selectedTypeOption &&
    selectedMeasureOption
  ) {
    return {
      edge: selectedEdgeOption.value,
      type: selectedTypeOption.value,
      value: limitValue,
      measure: selectedMeasureOption.value,
    }
  }
  return undefined
}

export const formatAttributeFilters = (
  filters: AttributeFilter[] | undefined,
): CreatePodSchema['attributeFilters'] => {
  if (!filters) {
    return undefined
  }
  const podSchemaFilters: CreatePodSchema['attributeFilters'] = []
  filters.forEach((filter) => {
    if (!filter.attribute || !filter.condition) {
      return
    }
    podSchemaFilters.push({
      attribute: filter.attribute.value,
      includes: filter.condition.value === 'equal',
      values: filter.values.map((e) => e.value),
    })
  })
  return podSchemaFilters
}
