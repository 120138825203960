import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Props as IWCardBadgeProps } from './IWCardBadge'
import { Props as BadgeProps } from './IWGeneralBadge'
import IWTypography from './IWTypography'

type AllBadgeProps = BadgeProps | IWCardBadgeProps
export type IWBadgeType = ReactElement<AllBadgeProps> | undefined

interface Props {
  children: IWBadgeType | IWBadgeType[]
  testId?: string
  /** label displayed in badge group */
  label?: string
}

const StyleDiv = styled.div`
  width: 100%;
  flex-flow: row wrap;
  display: flex;
  gap: 0.5rem;

  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

/**
 * Pass this component one or multiple `<IWGeneralBadge />` and you will have a
 * nice badge group that follows the rules set by the UI Team.
 *
 * __NOTE:__ This component does not contain any spacing around the parent container.
 * Which should allow you to add any spacing you would like between components.
 */
const IWBadgeGroup = ({ children, label, testId }: Props) => {
  return (
    <StyleDiv data-testid={testId}>
      {label && (
        <IWTypography
          size="sm"
          weight="medium"
          fontHue={{
            color: 'grey',
            value: 500,
          }}
        >
          {label}:
        </IWTypography>
      )}
      {children}
    </StyleDiv>
  )
}

export default IWBadgeGroup
