import React from 'react'
import IWBadgeGroup, { IWBadgeType } from './IWBadgeGroup'

interface IWFilterBadgesGroupProps {
  children: IWBadgeType | IWBadgeType[]
  /** Label passed down to `IWBadgeGroup` */
  label?: string
}

const IWFilterBadgesGroup = ({ children, label }: IWFilterBadgesGroupProps) => {
  return (
    <IWBadgeGroup label={label} testId="iw-filter-badges-group-test-id">
      {children}
    </IWBadgeGroup>
  )
}

export default IWFilterBadgesGroup
