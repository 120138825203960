import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  faUser,
  faShareNodes,
  faCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import IWDivider from 'shared/components/thunderbolt/IWDivider'
import IWVerticalNavItem from 'shared/components/thunderbolt/IWVerticalNavItem'
import LayoutContext from 'shared/contexts/LayoutContext'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import IWVerticalNavSection, {
  StyledNavigation,
} from 'shared/components/thunderbolt/IWVerticalNavSection'
import UserContext from 'shared/contexts/UserContext'
import { isPermissionAvailable } from 'shared/helpers'

export const IWNavSectionDivider = styled(IWDivider)`
  margin: 1rem 0;
`

const StyledNav = styled('aside')`
  height: 100%;
  width: 16rem;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.grey[0]};
`

const StyledCollapsedNav = styled('div')`
  padding: 1.5rem 1.5rem 0 1.5rem;
`

/**
 * This is Innowatts' Vertical Navigation Menu when used in full page layouts,
 * it takes VerticalNavSection components as its children
 */
const IWFullPageSidebar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isSmallScreen } = useContext(LayoutContext)
  const { availableToolPolicies } = useContext(UserContext)

  const canViewLoadScheduling = isPermissionAvailable(
    availableToolPolicies,
    'loadScheduling:editor',
  )

  const options = [
    {
      label: t('systemPreferences.title.userProfile'),
      value: '/system-preferences/user',
    },
    {
      label: t('systemPreferences.title.generalSettings'),
      value: '/system-preferences/general',
    },
    {
      label: t('systemPreferences.title.integrations'),
      value: '/system-preferences/integrations',
    },
    {
      label: t('systemPreferences.title.loadScheduling'),
      value: '/system-preferences/load-scheduling',
    },
  ]

  return (
    <>
      {isSmallScreen ? (
        <StyledCollapsedNav>
          <IWDropdown
            name="sidebar"
            required
            fullWidth
            value={options.find(({ value }) => value === pathname)}
            onChange={(e) => navigate(e.value, { replace: true })}
            options={options}
            placeholder={t('placeholders.select')}
          />
        </StyledCollapsedNav>
      ) : (
        <StyledNav>
          <StyledNavigation>
            <IWVerticalNavSection>
              <IWVerticalNavItem
                as="routerNavLink"
                label={t('systemPreferences.title.userProfile')}
                icon={faUser}
                active={pathname === '/system-preferences/user'}
                to="/system-preferences/user"
              />
              <IWVerticalNavItem
                as="routerNavLink"
                label={t('systemPreferences.title.integrations')}
                icon={faShareNodes}
                active={pathname === '/system-preferences/integrations'}
                to="/system-preferences/integrations"
              />
              {canViewLoadScheduling && (
                <IWVerticalNavItem
                  as="routerNavLink"
                  label={t('systemPreferences.title.loadScheduling')}
                  icon={faCalendarAlt}
                  active={pathname === '/system-preferences/load-scheduling'}
                  to="/system-preferences/load-scheduling"
                />
              )}
            </IWVerticalNavSection>
          </StyledNavigation>
        </StyledNav>
      )}
    </>
  )
}

export default IWFullPageSidebar
