import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router'
import UserContext from 'shared/contexts/UserContext'
import DashboardManager from './pages/DashboardManager'

const DashboardsManagerWrapper = () => {
  const { availableTools } = useContext(UserContext)
  const isAuthorised = availableTools.includes('dashboardsManager')

  return isAuthorised ? <Outlet /> : <Navigate to="/not-authorised" replace />
}

export const routes = [
  {
    title: 'Dashboards Manager',
    key: 'dashboardsManager',
    path: '/dashboards-manager',
    main: <DashboardsManagerWrapper />,
    childRoutes: [
      {
        title: 'Dashboards Manager',
        key: 'main',
        main: <DashboardManager />,
        entryPoint: true,
      },
    ],
  },
]
