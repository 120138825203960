import React, { useContext } from 'react'
import { DefaultState as IWRelativeDatePickerProps } from 'shared/hooks/useIWRelativeDatePicker'
import { DefaultCronSelectorState } from 'shared/hooks/useIWCronJobScheduleSelector'
import styled from 'styled-components'
import { LimitFilter } from 'tools/insightsManager/hooks/useLimitFilters'
import { MeasureRangeFilter } from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWInputLabel from 'shared/components/thunderbolt/IWCommonLabels'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { useTranslation } from 'react-i18next'
import {
  AttributeFilter,
  PodType,
  RetentionPeriodOptions,
  WeatherAttributes,
} from 'tools/insightsManager/types'

import getInsights from 'tools/insightsManager/utils/getInsights'
import {
  getActualUsageMetrics,
  getLongTermForecastMetrics,
  getRiskManagementMetrics,
  getShortTermForecastMetrics,
  getShortTermForecastPerformanceMetrics,
  getShortTermForecastPerformanceSystemMetrics,
  getShortTermForecastPerformanceTopDownMetrics,
  getShortTermForecastSystemMetrics,
  getWholesaleSettlementMetrics,
} from 'tools/insightsManager/utils/getMetrics'
import getCronDisplayString from 'shared/utils/getCronDisplayString'
import getDatePickerDisplayString from 'shared/utils/getDatePickerDisplayString'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import UserContext from 'shared/contexts/UserContext'
import PodIntegrationsSection from './PodIntegrationsSection'
import { useQuery } from 'react-query'
import {
  getSFTPCredentials,
  getThirdPartyIntegrations,
} from 'systemPreferences/integrationsServiceClient'

interface PodBuilderSummaryProps {
  /**
   * values coming from first and second step , tells the type of insight and metric
   * selected
   */
  podType: PodType
  /**
   * Weather attributes if a weather pod
   */
  weatherAttributes?: WeatherAttributes
  /**
   * Market(s) to filter by
   */
  markets?: DropdownValueProps[]
  /**
   * relative date picker values
   */
  dateRangeConfig: IWRelativeDatePickerProps
  /**
   * attribute values
   */
  attributes?: AttributeFilter[]
  /**
   * range filter values
   */
  rangeFilters?: MeasureRangeFilter[]
  /**
   * limit values
   */
  limits?: LimitFilter
  /**
   * general values like pod name and cron schedule
   */
  general: {
    userPodName: string
    retentionPeriod: RetentionPeriodOptions
    cronSelectorInputState: DefaultCronSelectorState
    eventBased: boolean
    useForLoadScheduling: boolean
    hasIntegrations?: boolean
    sftpCredentialIds?: string[]
    thirdPartyDriveCredentialIds?: string[]
  }
}

const MultipleAttributeSection = styled.div`
  width: 100%;
  border-radius: 0.375rem;
  padding: 1rem;
  background: ${(props) => props.theme.palette.grey[50]};
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
`

const SingleRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const SingleRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SingleValueDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  gap: 0.5rem;
  text-transform: capitalize;
`

const StyledGeneralSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const StyledDiv = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

/**
 *
 * This is a summary component to show the final summary of
 * all selected form steps for POD creation , this component accepts
 * values coming from previous form steps and displays a final info version
 * of selected options in each step
 *
 */
const PodBuilderSummary = ({
  podType,
  weatherAttributes,
  markets,
  dateRangeConfig,
  attributes,
  rangeFilters,
  limits,
  general,
}: PodBuilderSummaryProps) => {
  const { t } = useTranslation()
  const { availablePodTypesMap } = useContext(UserContext)

  const cronFinalGeneratedString = getCronDisplayString(
    general.cronSelectorInputState,
    t,
  )

  const dateConfigString = getDatePickerDisplayString(dateRangeConfig, t)

  const metricString = [
    ...getShortTermForecastMetrics(t, availablePodTypesMap.shortTermForecast),
    ...getRiskManagementMetrics(t, availablePodTypesMap.riskManagement),
    ...getActualUsageMetrics(t, availablePodTypesMap.actualUsage),
    ...getWholesaleSettlementMetrics(
      t,
      availablePodTypesMap.wholesaleSettlement,
    ),
    ...getLongTermForecastMetrics(t, availablePodTypesMap.longTermForecast),
    ...getShortTermForecastPerformanceMetrics(
      t,
      availablePodTypesMap.shortTermForecastPerformance,
    ),
    ...getShortTermForecastPerformanceTopDownMetrics(
      t,
      availablePodTypesMap.shortTermForecastPerformanceTopDown,
    ),
    ...getShortTermForecastSystemMetrics(
      t,
      availablePodTypesMap.shortTermForecastSystem,
    ),
    ...getShortTermForecastPerformanceSystemMetrics(
      t,
      availablePodTypesMap.shortTermForecastPerformanceSystem,
    ),
  ].find((m) => m.value === podType.metricId)?.label

  const defaultInsights = getInsights(t)

  const insightString = defaultInsights.find(
    (insight) => insight.type === podType.insightId,
  )?.label

  const retentionPeriodString = t(
    `insightManager.createPod.generalSetting.retentionOptions.${general.retentionPeriod.value}`,
  )

  const eventBasedString = general.eventBased
    ? t(`insightManager.podDetails.summary.eventBased.yesEventBased`)
    : t(`insightManager.podDetails.summary.eventBased.notEventBased`)

  const useForLoadSchedulingString = general.useForLoadScheduling
    ? t(
        `insightManager.podDetails.summary.useForLoadScheduling.yesUseForLoadScheduling`,
      )
    : t(
        `insightManager.podDetails.summary.useForLoadScheduling.notUseForLoadScheduling`,
      )

  const { data: availableSftpCredentials } = useQuery(
    ['sftpCredentials'],
    () => {
      return getSFTPCredentials(0, 100, {
        direction: 'descending',
        column: 'created_at',
      }).then((r) => r.rows)
    },
    {
      keepPreviousData: true,
    },
  )

  const { data: availableThirdPartyCredentials } = useQuery(
    ['thirdPartyCredentials'],
    () => {
      return getThirdPartyIntegrations()
    },
    {
      keepPreviousData: true,
    },
  )

  const selectedSftp = availableSftpCredentials
    ?.filter(({ credentialId }) => {
      return (
        general.sftpCredentialIds &&
        general.sftpCredentialIds.includes(credentialId)
      )
    })
    .map((sftp) => ({
      id: sftp.credentialId,
      name: sftp.name,
      description: sftp.description || '',
      isSelected: true,
    }))

  const selectedThirdPartDrive = availableThirdPartyCredentials
    ?.filter(({ credentialId }) => {
      return (
        general.thirdPartyDriveCredentialIds &&
        general.thirdPartyDriveCredentialIds.includes(credentialId)
      )
    })
    .map((thirdPartyId) => ({
      id: thirdPartyId.credentialId,
      name: thirdPartyId.thirdPartyDriveId,
      description: '',
      isSelected: true,
    }))

  return (
    <>
      <IWFormSection
        sectionTitle={t(`insightManager.createPod.insightSelector.formHeader`)}
      >
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 600 }}
        >
          {insightString}
        </IWTypography>
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(`insightManager.createPod.podCalculation`)}
      >
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 600 }}
        >
          {metricString}
        </IWTypography>
      </IWFormSection>
      <IWFormSection sectionTitle={t(`insightManager.createPod.dateRange`)}>
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 600 }}
        >
          {dateConfigString}
        </IWTypography>
      </IWFormSection>
      <>
        {Boolean(weatherAttributes) && (
          <IWFormSection sectionTitle={t('weatherAttribute.formHeader')}>
            <SingleValueDisplay>
              <IWInputLabel
                required
                label={t('weatherAttribute.weatherStationCount.label')}
              />
              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 600 }}
              >
                {weatherAttributes?.weatherStationCount}
              </IWTypography>
            </SingleValueDisplay>
            <SingleValueDisplay>
              <IWInputLabel
                required
                label={t('weatherAttribute.aggregationFn.label')}
              />
              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 600 }}
              >
                {weatherAttributes?.aggregationFnId}
              </IWTypography>
            </SingleValueDisplay>
          </IWFormSection>
        )}
        {Boolean(markets?.length) && (
          <IWFormSection sectionTitle={t('insightManager.createPod.markets')}>
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {markets?.map((m) => m.label).join(', ')}
            </IWTypography>
          </IWFormSection>
        )}
        {Boolean(attributes?.length) && (
          <IWFormSection
            sectionTitle={t(`attributeFilterSection.attributeFilters`)}
          >
            {attributes?.map(({ attribute, condition, values }) => (
              <MultipleAttributeSection key={`${attribute?.value}`}>
                <SingleRow>
                  <SingleValueDisplay>
                    <IWInputLabel
                      required
                      label={t(`attributeFilterSection.attribute`)}
                    />
                    <IWTypography
                      size="sm"
                      weight="regular"
                      fontHue={{ color: 'grey', value: 600 }}
                    >
                      {attribute?.label}
                    </IWTypography>
                  </SingleValueDisplay>
                  <SingleValueDisplay>
                    <IWInputLabel
                      required
                      label={t(`attributeFilterSection.condition`)}
                    />
                    <IWTypography
                      size="sm"
                      weight="regular"
                      fontHue={{ color: 'grey', value: 600 }}
                    >
                      {condition?.label}
                    </IWTypography>
                  </SingleValueDisplay>

                  <SingleValueDisplay>
                    <IWInputLabel required label={t(`common.value`)} />
                    <IWBadgeGroup>
                      {values.map((val) => (
                        <IWGeneralBadge
                          key={val.value}
                          label={val.label}
                          color="grey"
                        />
                      ))}
                    </IWBadgeGroup>
                  </SingleValueDisplay>
                </SingleRow>
              </MultipleAttributeSection>
            ))}
          </IWFormSection>
        )}
      </>
      <>
        {Boolean(rangeFilters?.length) && (
          <>
            <IWFormSection sectionTitle={t(`rangeFilterSection.rangeFilters`)}>
              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 500 }}
              >
                {t(`rangeFilterSection.rangeFiltersDescription`)}
              </IWTypography>
              {rangeFilters?.map((filter) => (
                <MultipleAttributeSection
                  key={filter.selectedSecondConditionOption?.value}
                >
                  <SingleRowContainer>
                    <SingleRow>
                      <SingleValueDisplay>
                        <IWInputLabel
                          required
                          label={t(`rangeFilterSection.measureToFilter`)}
                        />
                        <IWTypography
                          size="sm"
                          weight="regular"
                          fontHue={{ color: 'grey', value: 600 }}
                        >
                          {filter.selectedMeasureRangeFilterOption?.label}
                        </IWTypography>
                      </SingleValueDisplay>
                    </SingleRow>
                    {!filter.isFirstConditionDisabled && (
                      <SingleRow>
                        <SingleValueDisplay>
                          <IWInputLabel
                            required
                            label={t(`rangeFilterSection.condition`)}
                          />
                          <IWTypography
                            size="sm"
                            weight="regular"
                            fontHue={{ color: 'grey', value: 600 }}
                          >
                            {filter.selectedFirstConditionOption?.label}
                          </IWTypography>
                        </SingleValueDisplay>

                        <SingleValueDisplay>
                          <IWInputLabel
                            required
                            label={t(`rangeFilterSection.minValue`)}
                          />
                          <IWTypography
                            size="sm"
                            weight="regular"
                            fontHue={{ color: 'grey', value: 600 }}
                          >
                            {filter.firstConditionValue}
                          </IWTypography>
                        </SingleValueDisplay>
                      </SingleRow>
                    )}
                    {!filter.isSecondConditionDisabled && (
                      <SingleRow>
                        <SingleValueDisplay>
                          <IWInputLabel
                            required
                            label={t(`rangeFilterSection.condition`)}
                          />
                          <IWTypography
                            size="sm"
                            weight="regular"
                            fontHue={{ color: 'grey', value: 600 }}
                          >
                            {filter.selectedSecondConditionOption?.label}
                          </IWTypography>
                        </SingleValueDisplay>

                        <SingleValueDisplay>
                          <IWInputLabel
                            required
                            label={t(`rangeFilterSection.maxValue`)}
                          />
                          <IWTypography
                            size="sm"
                            weight="regular"
                            fontHue={{ color: 'grey', value: 600 }}
                          >
                            {filter.secondConditionValue}
                          </IWTypography>
                        </SingleValueDisplay>
                      </SingleRow>
                    )}
                  </SingleRowContainer>
                </MultipleAttributeSection>
              ))}
            </IWFormSection>
          </>
        )}
      </>
      <>
        {Boolean(limits) && limits?.limitValue && (
          <IWFormSection sectionTitle={t('limitFilterSection.limitFilter')}>
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 500 }}
            >
              {t('limitFilterSection.limitFiltersDescription')}
            </IWTypography>
            <MultipleAttributeSection>
              <SingleRow>
                <SingleValueDisplay>
                  <IWInputLabel
                    required
                    label={t('limitFilterSection.measureToLimit')}
                  />
                  <IWTypography
                    size="sm"
                    weight="regular"
                    fontHue={{ color: 'grey', value: 600 }}
                  >
                    {limits.selectedMeasureOption?.label}
                  </IWTypography>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel required label={t('limitFilterSection.edge')} />
                  <IWTypography
                    size="sm"
                    weight="regular"
                    fontHue={{ color: 'grey', value: 600 }}
                  >
                    {limits.selectedEdgeOption?.label}
                  </IWTypography>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel required label={t('limitFilterSection.type')} />
                  <IWTypography
                    size="sm"
                    weight="regular"
                    fontHue={{ color: 'grey', value: 600 }}
                  >
                    {limits.selectedTypeOption?.label}
                  </IWTypography>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel
                    required
                    label={t('limitFilterSection.value')}
                  />

                  <IWTypography
                    size="sm"
                    weight="regular"
                    fontHue={{ color: 'grey', value: 600 }}
                  >
                    {limits.limitValue}
                  </IWTypography>
                </SingleValueDisplay>
              </SingleRow>
            </MultipleAttributeSection>
          </IWFormSection>
        )}
      </>
      <IWFormSection sectionTitle={t('insightManager.createPod.general')}>
        <StyledGeneralSection>
          <SingleValueDisplay>
            <IWInputLabel
              required
              label={t('insightManager.createPod.generalSetting.podNameLabel')}
            />
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {general.userPodName}
            </IWTypography>
          </SingleValueDisplay>

          <SingleValueDisplay>
            <IWInputLabel
              label={t('insightManager.podDetails.summary.eventBased.label')}
            />
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {eventBasedString}
            </IWTypography>
          </SingleValueDisplay>

          <SingleValueDisplay>
            <IWInputLabel
              label={t(
                'insightManager.podDetails.summary.useForLoadScheduling.label',
              )}
            />
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {useForLoadSchedulingString}
            </IWTypography>
          </SingleValueDisplay>

          <SingleValueDisplay>
            <IWInputLabel required label={t('cronJobScheduleInput.label')} />
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {cronFinalGeneratedString}
            </IWTypography>
          </SingleValueDisplay>
          <SingleValueDisplay>
            <IWInputLabel
              required
              label={t(
                'insightManager.createPod.generalSetting.retentionPeriodLabel',
              )}
            />
            <IWTypography
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {retentionPeriodString}
            </IWTypography>
          </SingleValueDisplay>

          <IWFormSection
            sectionTitle={t(
              `insightManager.createPod.generalSetting.additionalOutput`,
            )}
          >
            {general.hasIntegrations ? (
              <StyledDiv>
                <PodIntegrationsSection
                  availableCredentials={selectedSftp || []}
                  isEditable={false}
                  onCredentialIdToggle={() => {}}
                  sectionTitle={t(
                    `insightManager.createPod.generalSetting.sftpOptions`,
                  )}
                />
                <PodIntegrationsSection
                  availableCredentials={selectedThirdPartDrive || []}
                  isEditable={false}
                  onCredentialIdToggle={() => {}}
                  sectionTitle={t(
                    `insightManager.createPod.generalSetting.thirdPartyIntegrations`,
                  )}
                />
              </StyledDiv>
            ) : (
              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 600 }}
              >
                {t(
                  `insightManager.createPod.generalSetting.notNotStoreExternal`,
                )}
              </IWTypography>
            )}
          </IWFormSection>
        </StyledGeneralSection>
      </IWFormSection>
    </>
  )
}

export default PodBuilderSummary
