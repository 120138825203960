import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import { number, object, string } from 'yup'
import { formatFormErrors } from '../../../shared/helpers'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

const createSchema = object({
  name: string().required().min(3).max(50),
  description: string().nullable().min(3).max(100),
  host: string().required().min(3).max(100),
  port: number().required(),
  path: string().nullable().max(100),
  username: string().required().min(3).max(50),
  password: string().required().min(3).max(50),
})

const StyledModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

const StyledInputRow = styled.div`
  display: flex;
  gap: 1rem;
  > * {
    flex: 1;
  }
`

interface Props {
  submitStatus: 'waiting' | 'submitting' | 'complete'
  credential:
    | {
        name?: string
        description?: string
        host?: string
        port?: string
        path?: string
        username?: string
        password?: string
      }
    | undefined
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onModalCancel: (e: SyntheticEvent) => void
  onModalCreate: (e: SyntheticEvent) => void
  onTest: (e: SyntheticEvent) => void
}

const SFTPCreateModal = ({
  submitStatus,
  credential,
  onChange,
  onModalCancel,
  onModalCreate,
  onTest,
}: Props) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<{
    name?: string
    description?: string
    host?: string
    port?: string
    username?: string
    password?: string
  }>({})
  const checkCredentialIsValid = async (): Promise<boolean> => {
    try {
      await createSchema.validate(credential, {
        abortEarly: false,
      })
      return true
    } catch (err: any) {
      setErrors(
        formatFormErrors(
          err.errors,
          'systemPreferences.integrations.sftp.fields',
        ),
      )
      return false
    }
  }
  return (
    <IWModal open={true}>
      <IWModalHeader>
        <IWTypography as="h1" size="lg" weight="medium">
          {t('systemPreferences.integrations.sftp.newCredentialHeader')}
        </IWTypography>
      </IWModalHeader>

      <StyledModalContent>
        <IWTextInput
          required
          errorText={errors.name}
          hasError={Boolean(errors.name)}
          value={credential?.name}
          name="name"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.name')}
        />
        <IWTextInput
          errorText={errors.description}
          hasError={Boolean(errors.description)}
          value={credential?.description}
          name="description"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.description')}
        />
        <StyledInputRow>
          <IWTextInput
            errorText={errors.host}
            hasError={Boolean(errors.host)}
            required
            value={credential?.host}
            name="host"
            onChange={onChange}
            label={t('systemPreferences.integrations.sftp.fields.host')}
          />
          <IWTextInput
            required
            errorText={errors.port}
            hasError={Boolean(errors.port)}
            value={credential?.port}
            type="number"
            name="port"
            onChange={onChange}
            label={t('systemPreferences.integrations.sftp.fields.port')}
          />
        </StyledInputRow>
        <IWTextInput
          required
          errorText={errors.username}
          hasError={Boolean(errors.username)}
          value={credential?.username}
          onChange={onChange}
          name="username"
          label={t('systemPreferences.integrations.sftp.fields.username')}
        />
        <IWTextInput
          required
          errorText={errors.password}
          hasError={Boolean(errors.password)}
          value={credential?.password}
          onChange={onChange}
          name="password"
          type="password"
          label={t('systemPreferences.integrations.sftp.fields.password')}
        />
        <IWTextInput
          value={credential?.path}
          name="path"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.path')}
        />
        <div>
          <IWButton
            variant="outline"
            disabled={submitStatus === 'submitting'}
            onClick={async (e) => {
              if (await checkCredentialIsValid()) {
                onTest(e)
              }
            }}
          >
            {t('systemPreferences.integrations.sftp.button.test')}
          </IWButton>
        </div>
      </StyledModalContent>
      <StyledModalFooter>
        <IWButton
          disabled={submitStatus === 'submitting'}
          color="grey"
          variant="outline"
          onClick={onModalCancel}
        >
          {t('button.cancel')}
        </IWButton>
        <IWButton
          disabled={submitStatus === 'submitting'}
          onClick={async (e) => {
            if (await checkCredentialIsValid()) {
              onModalCreate(e)
            }
          }}
        >
          {t('button.create')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default SFTPCreateModal
