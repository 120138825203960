import { HourNumbers, MonthNumbers, WeekdayNumbers } from 'luxon'
import { DefaultCronSelectorState } from 'shared/hooks/useIWCronJobScheduleSelector'
import {
  getHourOptions,
  getMonthOptions,
  getWeekdayOptions,
} from 'shared/utils/dateUtils'

const getCronDisplayString = (state: DefaultCronSelectorState, t) => {
  let string

  const hourOptions = new Map(
    getHourOptions().map(({ label: l, value }) => [value, { label: l, value }]),
  )
  const monthOptions = new Map(
    getMonthOptions().map(({ label: l, value }) => [
      value,
      { label: l, value },
    ]),
  )
  const weekDayOptions = new Map(
    getWeekdayOptions().map(({ label: l, value, labelLong }) => [
      value,
      { label: l, value, labelLong },
    ]),
  )

  const selHourOptions = (state.selectedHourOption?.value ?? -1) as HourNumbers
  const selMonthOptions = (state.selectedMonthOption?.value ??
    -1) as MonthNumbers

  switch (state.selectedScheduleTypeOption?.value) {
    case 'once':
      string = t('cronJobScheduleInput.finalStrings.once', {
        type: t(
          `cronJobScheduleInput.scheduleType.${state.selectedScheduleTypeOption.value}`,
        ),
      })
      break
    case 'daily':
      string = t('cronJobScheduleInput.finalStrings.daily', {
        type: t(
          `cronJobScheduleInput.scheduleType.${state.selectedScheduleTypeOption.value}`,
        ),
        hour: hourOptions.get(selHourOptions)?.label || '',
      })
      break
    case 'hourly':
      string = t('cronJobScheduleInput.finalStrings.hourly', {
        type: t(
          `cronJobScheduleInput.scheduleType.${state.selectedScheduleTypeOption.value}`,
        ),
      })
      break
    case 'weekly':
      string = t('cronJobScheduleInput.finalStrings.weekly', {
        type: t(
          `cronJobScheduleInput.scheduleType.${state.selectedScheduleTypeOption.value}`,
        ),
        hour: hourOptions.get(selHourOptions)?.label || '',
        weekday: state.selectedWeekdayOption
          ?.map((w) => weekDayOptions.get(w.value as WeekdayNumbers)?.labelLong)
          .sort()
          .join(', '),
      })

      break
    case 'monthly':
      string = t('cronJobScheduleInput.finalStrings.monthly', {
        type: t(
          `cronJobScheduleInput.scheduleType.${state.selectedScheduleTypeOption.value}`,
        ),
        hour: hourOptions.get(selHourOptions)?.label || '',
        timeOfMonth: t(
          `cronJobScheduleInput.timeOfMonth.${state.selectedTimeOfMonthOption?.value}`,
        ),
      })
      break
    case 'yearly':
      string = t('cronJobScheduleInput.finalStrings.yearly', {
        type: t(
          `cronJobScheduleInput.scheduleType.${state.selectedScheduleTypeOption.value}`,
        ),
        hour: hourOptions.get(selHourOptions)?.label || '',
        month: monthOptions.get(selMonthOptions)?.label,
        timeOfMonth: t(
          `cronJobScheduleInput.timeOfMonth.${state.selectedTimeOfMonthOption?.value}`,
        ),
      })
      break
    default:
      string = ''
      break
  }
  return string
}

export default getCronDisplayString
