import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWToggleGroup from 'shared/components/thunderbolt/IWToggleGroup'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import { ReactComponent as NoPodsImg } from '../../../images/no-pods.svg'

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export type Props = {
  onCredentialIdToggle: (credentialId: string) => void
  isEditable?: boolean
  availableCredentials: {
    id: string
    name: string
    description: string
    isSelected: boolean
  }[]
  sectionTitle: string
}

const PodIntegrationsSection = ({
  availableCredentials,
  onCredentialIdToggle,
  isEditable,
  sectionTitle,
}: Props) => {
  const { t } = useTranslation()
  const isLoading = false

  return (
    <StyledSection>
      <>
        {isLoading ? (
          <IWLoading />
        ) : (
          <IWFormSection sectionTitle={sectionTitle}>
            <IWToggleGroup>
              {availableCredentials.length > 0
                ? availableCredentials?.map((credential) => (
                    <IWToggle
                      id={credential.id}
                      label={credential.name}
                      disabled={!isEditable}
                      value={credential.id}
                      checked={credential.isSelected}
                      description={credential.description}
                      onChange={() => {
                        onCredentialIdToggle(credential.id)
                      }}
                    />
                  ))
                : [
                    <EmptyDiv data-testid="empty-message">
                      <NoPodsImg />
                      <IWTypography weight="bold">
                        {t(
                          'insightManager.createPod.generalSetting.noCredentials',
                        )}
                      </IWTypography>
                      <IWTypography
                        size="sm"
                        fontHue={{ color: 'grey', value: 500 }}
                      >
                        {t(
                          'insightManager.createPod.generalSetting.noCredentialsCopy',
                        )}
                      </IWTypography>
                    </EmptyDiv>,
                  ]}
            </IWToggleGroup>
          </IWFormSection>
        )}
      </>
    </StyledSection>
  )
}

export default PodIntegrationsSection
