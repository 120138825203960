import { z, ZodType } from 'zod'

export const STFPodCsvRowValidation = z.object({
  brand: z.string(),
  forecast_gross: z.coerce.number(),
  forecast_net: z.coerce.number(),
  market: z.string(),
  start_time: z.string(),
  utility: z.string(),
  zone: z.string(),
  __time: z.string(),
})

export const NOPPodCsvRowValidation = z.object({
  start_time: z.string(),
  market: z.string(),
  brand: z.string(),
  zone: z.string(),
  supply: z.coerce.number(),
  demand: z.coerce.number(),
  net_open_position: z.coerce.number(),
  // price: z.coerce.number(),
  __time: z.string(),
})

export const LsCsvRowValidation = z.object({
  brand: z.string(),
  market: z.string(),
  date: z.string().refine((arg) => arg.match(/^\d{4}-\d{2}-\d{2}$/), {
    message: 'date must be formatted as an ISO Date (YYYY-MM-DD)',
  }),
  zone: z.string(),
  location: z.string(),
  location_name: z.string(),
  1: z.coerce.number(),
  2: z.coerce.number(),
  3: z.coerce.number(),
  4: z.coerce.number(),
  5: z.coerce.number(),
  6: z.coerce.number(),
  7: z.coerce.number(),
  8: z.coerce.number(),
  9: z.coerce.number(),
  10: z.coerce.number(),
  11: z.coerce.number(),
  12: z.coerce.number(),
  13: z.coerce.number(),
  14: z.coerce.number(),
  15: z.coerce.number(),
  16: z.coerce.number(),
  17: z.coerce.number(),
  18: z.coerce.number(),
  19: z.coerce.number(),
  20: z.coerce.number(),
  21: z.coerce.number(),
  22: z.coerce.number(),
  23: z.coerce.number(),
  24: z.coerce.number(),
})

export const LsErcotCsvRowValidation = z.object({
  brand: z.string(),
  market: z.string(),
  date: z.string().refine((arg) => arg.match(/^\d{4}-\d{2}-\d{2}$/), {
    message: 'date must be formatted as an ISO Date (YYYY-MM-DD)',
  }),
  zone: z.string(),
  location: z.string(),
  location_name: z.string(),
  supply: z.coerce.number(),
  load: z.coerce.number(),
  demand: z.coerce.number(),
  open_position: z.coerce.number(),
  net_open_position: z.coerce.number(),
  price: z.coerce.number(),
  curve: z.string(),
  bid_or_offer: z.string(),
  interval: z.coerce.number(),
})

export const ExpandedTradesPodCsvRowValidation = z.object({
  __time: z.string(),
  deal_id: z.string(),
  market: z.string(),
  brand: z.string(),
  zone: z.string(),
  start_time: z.string(),
  volume: z.coerce.number(),
  price: z.coerce.number(),
  buy_sell: z.literal('BUY').or(z.literal('SELL')),
  dt: z.string(),
  peak: z.string(),
  da_rt: z.literal('DA').or(z.literal('RT')),
  counterparty: z.string(),
})

export const IbtCsvRowValidation = z.object({
  contract_id: z.string(),
  contract_name: z.string(),
  market: z.string(),
  brand: z.string(),
  zone: z.string(),
  buyer: z.string(),
  buyer_qse_code: z.string(),
  seller: z.string(),
  seller_qse_code: z.string(),
  date: z.string().refine((arg) => arg.match(/^\d{4}-\d{2}-\d{2}$/), {
    message: 'date must be formatted as an ISO Date (YYYY-MM-DD)',
  }),
  1: z.coerce.number(),
  2: z.coerce.number(),
  3: z.coerce.number(),
  4: z.coerce.number(),
  5: z.coerce.number(),
  6: z.coerce.number(),
  7: z.coerce.number(),
  8: z.coerce.number(),
  9: z.coerce.number(),
  10: z.coerce.number(),
  11: z.coerce.number(),
  12: z.coerce.number(),
  13: z.coerce.number(),
  14: z.coerce.number(),
  15: z.coerce.number(),
  16: z.coerce.number(),
  17: z.coerce.number(),
  18: z.coerce.number(),
  19: z.coerce.number(),
  20: z.coerce.number(),
  21: z.coerce.number(),
  22: z.coerce.number(),
  23: z.coerce.number(),
  24: z.coerce.number(),
})

export function validateArrayWithZod<T>(items: T[], validationSchema: ZodType) {
  return z.array(validationSchema).parse(items)
}
