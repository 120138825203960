import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { ReactComponent as NoResultsImg } from 'images/no-results.svg'

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

const IWNoResultsMessage = () => {
  const { t } = useTranslation()
  return (
    <EmptyDiv data-testid="no-results-message">
      <NoResultsImg />
      <IWTypography weight="bold">{t('noResultsMessage.title')}</IWTypography>
      <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
        {t('noResultsMessage.body')}
      </IWTypography>
    </EmptyDiv>
  )
}

export default IWNoResultsMessage
