// this is ignored here as it is used extensively while implementing react-tables
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useRowSelect, useTable } from 'react-table'
import styled from 'styled-components'
import { transitions } from 'polished'
import {
  faEllipsisV,
  faSort,
  faSortDown,
  faSortUp,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSticky } from 'react-table-sticky'
import IWTypography from './IWTypography'
import IWCheckbox from './IWCheckbox'
import IWContextMenu from './IWContextMenu'
import IWContextMenuItem, {
  Props as IWContextMenuItemProps,
} from './IWContextMenuItem'

export type SortDirection = 'ascending' | 'descending'

export type PinDirection = 'right' | 'left'

export type Column = {
  /** Title of the column (must be translated) */
  title: string
  /** Key that the react-table hoo uses to map column to data */
  accessor: string
  /** mapping of the column to the db column, for sorting */
  dbColumn?: string
  /** Determines how the content of a columns is aligned. Center for special fields and right for numbers */
  align?: string
  /** The trailing icon to use for the column */
  icon?: IconDefinition

  /** Flag to determine if the header should wrap whitespace or not */
  wrapHeader?: boolean
  /** Flag to determine if the data is sortable by this column  */
  sortable?: boolean
  /** Flag to determine if the column is pinnable */
  pinnable?: boolean
  /** This is not a hard width as the cells can still grow based on content per the
   *  built-in html table layout algorithm. If we wanted exact widths we would need
   *  to use the table-fixed css property but we lose auto columns widths and,
   *  given that we wish to render dynamic data this is preferable
   */
  targetPercentageWidth?: number
  /**
   * `sticky` is an optional property that determines the sticky behavior of the table column.
   * It can be either 'left' or 'right'.
   * If 'left' is specified, the column will stick to the left side of the table when scrolling horizontally.
   * If 'right' is specified, the column will stick to the right side of the table when scrolling horizontally.
   */
  sticky?: 'left' | 'right'
  minWidth?: number
}

type PinnedColumns = { [accessor: string]: PinDirection | undefined }
type SortedBy = { [accessor: string]: SortDirection }

export interface IWTableProps {
  /** Array of columns per the column definition */
  columns: Column[]
  /** Data to display in the table */
  data: any[]
  /** Function that, when set, adds a selectable row checkbox column to the start of the table */
  onSelectRows?: (rows) => void
  /** is set, shows the provided string on the row selection col */
  rowSelectTitle?: string
  /** Function to trigger on sort fo a column */
  onSort?: (accessor: string, direction: SortDirection) => void
  onPin?: (accessor: string, pinDirection?: PinDirection) => void
  /** Key value pairs to determine what the current data is sorted by to indicate in the table header */
  sortedBy?: SortedBy
  pinnedColumns?: PinnedColumns
  /** Flag to show a border around the table wrapper  */
  border?: boolean
  isLoading?: boolean
}

export const TableWrap = styled.div<{ border?: boolean; isLoading?: boolean }>`
  display: block;
  position: relative;
  height: min-content;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border: ${(props) =>
    props.border && `1px solid ${props.theme.palette.grey[300]}`};
`

const StyledLoadingOverlay = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.layers.overlayout};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.grey[50]};
  opacity: 0.3;
`

export const StyledTable = styled.table`
  width: 100%;
  background: ${(props) => props.theme.palette.grey[0]};
  border-spacing: 0;
`

export const StyledTBody = styled.tbody`
  position: relative;
  z-index: 0;
`

export const StyledThead = styled.thead`
  background: ${(props) => props.theme.palette.primary[700]};
`

/**
 * Sending 'sticky' here will override the variant and force it to be dark grey per the spec
 */
export const StyledTH = styled.th<{
  sticky?: boolean
  wrapHeader: boolean
}>`
  position: sticky;
  top: 0;
  z-index: ${(props) => props.theme.layers.generallayout};
  white-space: ${(props) => (props.wrapHeader ? 'wrap' : 'nowrap')};
  background: ${(props) =>
    props.sticky
      ? props.theme.palette.grey[700]
      : props.theme.palette.primary[700]};
  color: ${(props) =>
    props.sticky
      ? props.theme.palette.grey[50]
      : props.theme.palette.primary[50]};
`

/**
 * Sending 'sticky' here will override the variant and force it to be dark grey per the spec
 */
export const StyledHeaderTitle = styled(IWTypography)<{
  sticky?: boolean
  align?: string
}>`
  text-transform: uppercase;
  color: ${(props) =>
    props.sticky ? props.theme.palette.grey[50] : 'inherit'};
  flex: 1;
  text-align: ${(props) => props.align || 'left'};
`

export const StyledBodyTR = styled.tr<{ sticky?: boolean }>`
  :nth-child(odd) {
    background-color: ${(props) => props.theme.palette.grey[0]};
  }

  :nth-child(even) {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`

export const StyledTD = styled.td<{
  sticky?: boolean
  align?: string
  rowIndex: number
}>`
  padding: 0.75rem;
  text-align: ${(props) => props.align || 'left'};

  background: ${(props) => {
    if (props.sticky) {
      /* If the row index is even/odd AND it is sticky we need to change the bg of the cell
       *  Since the rest of the row is colored normally (transparent with the row coloring)  */
      return props.rowIndex % 2 === 0
        ? props.theme.palette.grey[500]
        : props.theme.palette.grey[400]
    }
    return 'transparent'
  }};

  color: ${(props) =>
    props.sticky ? props.theme.palette.grey[0] : props.theme.palette.grey[800]};
`

export const ThCellContents = styled.div`
  line-height: 1rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`

export const SelectCol = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ColumnOptionsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const SortIcon = styled(FontAwesomeIcon)<{ active?: boolean }>`
  opacity: ${(props) => (props.active ? '100%' : 0)};
  ${transitions(['opacity'], '0.2s ease 0s')};
`

const StyledIconBox = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const HeaderCell = ({
  id,
  title,
  isSortable,
  icon,
  isPinnable,
  pinDirection,
  align,
  onSort,
  onPin,
  sortDirection,
}: {
  id: string
  title: string
  isSortable: boolean
  isPinnable: boolean
  sortDirection?: SortDirection
  icon: Column['icon']
  align: Column['align']
  onSort: IWTableProps['onSort']
  onPin: IWTableProps['onPin']
  pinDirection?: 'left' | 'right'
}) => {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)
  const menuItems = useMemo(() => {
    const items: ReactElement<IWContextMenuItemProps>[] = []
    if (isSortable && onSort) {
      const sortableItems = ['ascending', 'descending'].map((d) => {
        const direction = d as SortDirection
        return (
          <IWContextMenuItem
            key={`sort-${direction}`}
            label={t(`tables.sort.${d}`)}
            disabled={sortDirection === direction}
            icon={d === 'ascending' ? faSortUp : faSortDown}
            onClick={() => {
              onSort(id, direction)
            }}
          />
        )
      })
      items.push(...sortableItems)
    }
    if (isPinnable && onPin) {
      const pinDirections: PinDirection[] = ['left', 'right']
      const pinnableItems = pinDirections.map((d) => {
        const translationKey = pinDirection === d ? 'unpin' : d
        return (
          <IWContextMenuItem
            key={`pin-${d}`}
            label={t(`tables.pin.${translationKey}`)}
            onClick={() => {
              if (pinDirection === d) {
                onPin(id)
              } else {
                onPin(id, d)
              }
            }}
          />
        )
      })
      items.push(...pinnableItems)
    }
    return items
  }, [
    t,
    isSortable,
    onSort,
    isPinnable,
    onPin,
    sortDirection,
    id,
    pinDirection,
  ])

  let sortIcon = faSort
  if (sortDirection === 'ascending') {
    sortIcon = faSortUp
  } else if (sortDirection === 'descending') {
    sortIcon = faSortDown
  }
  return (
    <ThCellContents
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <StyledHeaderTitle
        weight="medium"
        sticky={Boolean(pinDirection)}
        size="sm"
        as="span"
        align={align}
      >
        {title}
      </StyledHeaderTitle>
      {menuItems.length > 0 && (
        <ColumnOptionsWrapper>
          {icon && <FontAwesomeIcon icon={icon} />}
          {isSortable && onSort && (
            <StyledIconBox
              role="button"
              onClick={() => {
                if (!sortDirection || sortDirection === 'descending') {
                  onSort(id, 'ascending')
                } else {
                  onSort(id, 'descending')
                }
              }}
            >
              <SortIcon role="button" icon={sortIcon} active={isHovered} />
            </StyledIconBox>
          )}
          <IWContextMenu positions={['right', 'bottom']} menu={menuItems}>
            <StyledIconBox>
              <FontAwesomeIcon icon={faEllipsisV} />
            </StyledIconBox>
          </IWContextMenu>
        </ColumnOptionsWrapper>
      )}
    </ThCellContents>
  )
}

// Per react-tales docs https://react-table.tanstack.com/docs/api/useRowSelect#userowselect
export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <IWCheckbox id={ref} ref={resolvedRef} {...rest} />
  },
)

export const usePinnedColumns = () => {
  const [pinnedColumns, setPinnedColumns] = useState<PinnedColumns>({})
  const handlePinColumns = (
    accessor: string,
    direction?: PinDirection,
  ): void => {
    setPinnedColumns((prev) => {
      const obj = Object.entries(prev).find(
        (e) => e[1] === direction && e[0] !== accessor,
      )
      const update = {
        ...prev,
        [accessor]: direction,
      }
      if (obj) {
        return {
          ...update,
          [obj[0]]: undefined,
        }
      }
      return update
    })
  }
  return {
    pinnedColumns,
    handlePinColumns,
  }
}

/**
 * There is a weird quirk with our styling where in order to pin nicely to the left
 * the first column must be pinned to the left. Same happens with the right. In order
 * to pin to the right the last column must be pinned to the right.
 * This function simply ensures the columns are ordered correctly to pin the first
 * and last columns (when necessary)
 */
const orderColumns = (cols: Column[], pinnedColumns: PinnedColumns) => {
  let shallowCopy: Column[] = [...cols]
  const leftPinIndex = shallowCopy.findIndex(
    (x) => pinnedColumns[x.accessor] === 'left',
  )
  if (leftPinIndex > -1) {
    const cut = shallowCopy.splice(leftPinIndex, 1)[0]
    shallowCopy = [{ ...cut }, ...shallowCopy]
  }
  const rightPinIndex = shallowCopy.findIndex(
    (x) => pinnedColumns[x.accessor] === 'right',
  )

  if (rightPinIndex > -1) {
    const cut = shallowCopy.splice(rightPinIndex, 1)[0]
    shallowCopy = [...shallowCopy, { ...cut }]
  }
  return shallowCopy
}

/**
 * Thunderbolt table. Wraps the react-table package and styles it.
 * It can be configured to be sortable, row selectable and with the various column options.
 */
const IWTable = ({
  columns,
  data,
  onSelectRows,
  rowSelectTitle,
  onSort,
  onPin,
  sortedBy = {},
  pinnedColumns = {},
  border,
  isLoading = false,
}: IWTableProps) => {
  const [newColumns, setNewColumns] = useState(() =>
    orderColumns(columns, pinnedColumns),
  )
  useEffect(() => {
    const newCols =
      Object.keys(pinnedColumns).length > 0
        ? orderColumns(columns, pinnedColumns)
        : columns
    setNewColumns(newCols)
  }, [columns, pinnedColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: newColumns,
      data,
    },
    // Row selection per -> https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/full-width-resizable-table?file=/src/App.js:3812-3817
    useRowSelect,
    useSticky,
    (hooks) => {
      if (onSelectRows) {
        hooks.allColumns.push((allColumns) => [
          // This adds a selection column to the start of the table if selectableRows is true
          {
            id: 'selection',
            disableResizing: true,
            width: 48, // this doesn't seem to take rems
            sticky: allColumns.some((c) => c.sticky) ? 'left' : undefined,
            isSelection: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({
              getToggleAllRowsSelectedProps,
            }: {
              getToggleAllRowsSelectedProps: any
            }) => (
              <SelectCol>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                <StyledHeaderTitle weight="medium" size="sm">
                  {rowSelectTitle}
                </StyledHeaderTitle>
              </SelectCol>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: { row: any }) => {
              return (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )
            },
          },
          ...allColumns,
        ])
      }
    },
  )

  useEffect(() => {
    // when we select a row or all rows, we emit the row indices to the parent component
    if (onSelectRows) {
      onSelectRows(selectedRowIds)
    }
  }, [onSelectRows, selectedRowIds])

  return (
    <>
      <TableWrap border={border} isLoading={isLoading}>
        {isLoading && <StyledLoadingOverlay />}
        <StyledTable {...getTableProps()}>
          <StyledThead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <StyledTH
                      {...column.getHeaderProps({
                        wrapHeader: !!column.wrapHeader,
                        sticky: Boolean(pinnedColumns[column.id]),
                        width: `${column.targetPercentageWidth}%`,
                      })}
                    >
                      {column.isSelection ? (
                        <ThCellContents>
                          {column.render('Header')}
                        </ThCellContents>
                      ) : (
                        <HeaderCell
                          id={column.id}
                          title={column.title}
                          isSortable={column.sortable}
                          isPinnable={column.pinnable}
                          icon={column.icon}
                          pinDirection={pinnedColumns[column.id]}
                          sortDirection={sortedBy[column.id] || undefined}
                          onSort={onSort}
                          onPin={onPin}
                          align={column.align}
                        />
                      )}
                    </StyledTH>
                  )
                })}
              </tr>
            ))}
          </StyledThead>

          <StyledTBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <StyledBodyTR {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <StyledTD
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                          sticky:
                            cell.getCellProps().style.position === 'sticky',
                          align: cell.column.align,
                          rowIndex: row.id,
                        })}
                      >
                        {cell.value ||
                        cell.value === 0 ||
                        cell.column.isSelection
                          ? cell.render('Cell')
                          : '-'}
                      </StyledTD>
                    )
                  })}
                </StyledBodyTR>
              )
            })}
          </StyledTBody>
        </StyledTable>
      </TableWrap>
    </>
  )
}

export default IWTable
