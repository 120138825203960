import React from 'react'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWInputLabel, {
  IWError,
} from 'shared/components/thunderbolt/IWCommonLabels'
import IWDropdown, {
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFilterSection from 'shared/components/thunderbolt/IWFilterSection'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import type { MeasureConditionOperators } from 'tools/alertsManager/types'
import { MeasureCondition } from '../../hooks/useMeasureRangeConditions'
import { mapToDropdownOptions } from '../../../tools/alertsManager/utils/utils'

export interface Props {
  measure: MeasureCondition
  showErrors?: boolean
  onFirstConditionChange: (
    value: GenericDropdownValue<MeasureConditionOperators>,
    id: string,
  ) => void
  onSecondConditionChange: (
    value: GenericDropdownValue<MeasureConditionOperators>,
    id: string,
  ) => void
  onFirstConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onSecondConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onDelete: (id: string) => void
}

const StyledHeaderSection = styled.div`
  display: flex;
  width: 100%;
`

const StyledHeaderLeft = styled.div`
  width: inherit;

  & > div {
    margin-bottom: 1rem;
  }
`

const StyledFilterSection = styled(IWFilterSection)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`

const StyledIWButton = styled(IWButton)`
  padding: 0.375rem 1rem;
  align-self: flex-end;
`

const StyledIWDropdown = styled(IWDropdown)`
  margin-right: 1rem;
`

const StyledFlexRow = styled.div`
  display: flex;
`

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const StyleInputWrapper = styled.div`
  width: 100%;
`

const IWMeasureRangeFilter = ({
  measure,
  showErrors,
  onFirstConditionChange,
  onSecondConditionChange,
  onFirstConditionValueChange,
  onSecondConditionValueChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()

  const firstConditions: GenericDropdownValue<MeasureConditionOperators>[] =
    mapToDropdownOptions<MeasureConditionOperators>(['>', '>='])
  const secondConditions: GenericDropdownValue<MeasureConditionOperators>[] =
    mapToDropdownOptions<MeasureConditionOperators>(['<', '<='])

  return (
    <StyledFilterSection data-testid="measure-condition-component">
      <StyledHeaderSection>
        <StyledHeaderLeft>
          <StyledFlexColumn>
            <IWInputLabel
              label={t('rangeFilterSection.measureToFilter')}
              id={`measure-${measure.id}`}
            />
            <IWTypography
              size="sm"
              id={`measure-${measure.id}`}
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {measure?.selectedMeasureOption?.label}
            </IWTypography>
          </StyledFlexColumn>
          <StyledFlexColumn>
            <IWInputLabel
              label={t('rangeFilterSection.valueType')}
              id={`value-type-${measure.id}`}
            />
            <IWTypography
              size="sm"
              id={`value-type-${measure.id}`}
              weight="regular"
              fontHue={{ color: 'grey', value: 600 }}
            >
              {measure?.selectedValueTypeOption?.label}
            </IWTypography>
          </StyledFlexColumn>
        </StyledHeaderLeft>
        <div>
          <StyledIWButton
            data-testid="measure-condition-delete-button"
            color="grey"
            variant="outline"
            type="button"
            onClick={() => onDelete(measure.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </StyledIWButton>
        </div>
      </StyledHeaderSection>

      <StyledFlexRow>
        <StyledIWDropdown
          required
          fullWidth
          name="first-condition"
          label={t('rangeFilterSection.condition')}
          value={
            measure.selectedFirstConditionOption ||
            onFirstConditionChange(firstConditions[0], measure.id)
          }
          onChange={(e) => onFirstConditionChange(e, measure.id)}
          options={firstConditions}
          getOptionLabel={(option) => {
            const { value } =
              option as GenericDropdownValue<MeasureConditionOperators>
            return t(`rangeFilterSection.options.${value}`)
          }}
          placeholder={t('placeholders.select')}
          hasError={showErrors && !measure.selectedFirstConditionOption}
        />

        <StyleInputWrapper>
          <IWTextInput
            data-testid="measure-condition-first-condition-input"
            name="minvalue"
            type="number"
            required
            label={t('rangeFilterSection.minValue')}
            value={measure.firstConditionValue ?? ''}
            onChange={(e) => onFirstConditionValueChange(e, measure.id)}
            placeholder={t('placeholders.enterValue')}
            hasError={
              showErrors &&
              measure.hasFirstConditionValueError &&
              measure.hasSecondConditionValueError
            }
          />
        </StyleInputWrapper>
      </StyledFlexRow>

      <StyledFlexRow>
        <StyledIWDropdown
          required
          fullWidth
          name="second-condition"
          label={t('rangeFilterSection.condition')}
          value={
            measure.selectedSecondConditionOption ||
            onSecondConditionChange(secondConditions[0], measure.id)
          }
          onChange={(e) => onSecondConditionChange(e, measure.id)}
          options={secondConditions}
          getOptionLabel={(option) => {
            const { value } =
              option as GenericDropdownValue<MeasureConditionOperators>
            return t(`rangeFilterSection.options.${value}`)
          }}
          placeholder={t('placeholders.select')}
          hasError={showErrors && !measure?.selectedSecondConditionOption}
        />

        <StyleInputWrapper>
          <IWTextInput
            data-testid="measure-condition-second-condition-input"
            name="maxvalue"
            type="number"
            required
            label={t('rangeFilterSection.maxValue')}
            value={measure.secondConditionValue ?? ''}
            onChange={(e) => onSecondConditionValueChange(e, measure.id)}
            placeholder={t('placeholders.enterValue')}
            hasError={
              showErrors &&
              measure.hasFirstConditionValueError &&
              measure.hasSecondConditionValueError
            }
          />
        </StyleInputWrapper>
      </StyledFlexRow>
      {showErrors &&
        measure.hasFirstConditionValueError &&
        measure.hasSecondConditionValueError && (
          <IWError
            label={t('insightManager.createPod.errors.enterAllRequired')}
          />
        )}
    </StyledFilterSection>
  )
}

export default IWMeasureRangeFilter
