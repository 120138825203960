import { useEffect, useState } from 'react'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { useTranslation } from 'react-i18next'
import { getAllFileSchemas } from 'shared/podServiceClient'
import { useQuery } from 'react-query'
import { ImportManagerSchema } from '../types'

const useInnowattsSchemaSelector = ({
  selectedSchemaArg,
}: {
  selectedSchemaArg?: ImportManagerSchema
}) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [selectedSchema, setSelectedSchemas] = useState<
    DropdownValueProps | undefined
  >(
    selectedSchemaArg
      ? {
          label: selectedSchemaArg.name,
          value: selectedSchemaArg.id,
        }
      : undefined,
  )

  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const handleSchemaSelection = (updatedFileSchema: DropdownValueProps) => {
    setSelectedSchemas(updatedFileSchema)
  }

  const { data: availableSchemas } = useQuery(
    'fileSchemas',
    async () => {
      try {
        const res = await getAllFileSchemas()
        if (res.length === 0) {
          toast.alert(t('useImportFileSchemas.errors.noSchemas'), {
            canDismiss: true,
          })
        }
        return res
      } catch (error) {
        toast.alert(t('useImportFileSchemas.errors.loadSchemas'), {
          status: error.response?.status,
          canDismiss: true,
        })
        throw error
      }
    },
    {
      retry: 3,
    },
  )

  useEffect(() => {
    const dataPresent = selectedSchema
    setHasErrors(!dataPresent)
  }, [selectedSchema])

  return {
    selectedSchema,
    onSchemaSelectionChange: handleSchemaSelection,
    availableSchemas,
    hasErrors,
  }
}

export default useInnowattsSchemaSelector
