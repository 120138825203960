import styled, { DefaultTheme } from 'styled-components'
import {
  AvailableFontSizes,
  AvailableFontWeights,
  Hue,
} from '../../../@types/styled'
import { getFontColor } from './utils/utils'

const getFontSize = (size: string, theme: DefaultTheme) => {
  return size
    ? theme.typography.fontSizes[size]
    : theme.typography.fontSizes.base
}

const getLineHeight = (size: AvailableFontSizes, theme: DefaultTheme) => {
  return theme.typography.lineHeights[size]
}

const getFontWeight = (weight: AvailableFontWeights, theme: DefaultTheme) => {
  return theme.typography.fontWeights[weight]
}

/** This is the core typography element for Thunderbolt
 * It accepts 'size', 'fontHue' (color of the font in themeable color values), and weight.
 * These size and weight values are constrained by set values in the theme, FontHue uses any position color and value combination of the colors in the theme.
 * Optionally you can set the 'inheritFontColor' to have the font inherit from the parent
 */
const IWTypography = styled.p<{
  size?: AvailableFontSizes
  fontHue?: Hue
  inheritFontColor?: boolean
  weight?: AvailableFontWeights
}>`
  color: ${(props) => getFontColor(props, props.theme)};
  font-size: ${(props) =>
    props.size
      ? getFontSize(props.size, props.theme)
      : props.theme.typography.fontSizes.base};
  line-height: ${(props) =>
    props.size
      ? getLineHeight(props.size, props.theme)
      : props.theme.typography.lineHeights.base};
  font-weight: ${(props) =>
    props.weight
      ? getFontWeight(props.weight, props.theme)
      : props.theme.typography.fontWeights.regular};
`

export default IWTypography
