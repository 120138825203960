import React from 'react'
import styled from 'styled-components'
import IWTypography from './IWTypography'

export type Props = {
  /** The label to display on the tab to indicate its function */
  label: string
  /** Pass a positive integer here to display a value as a badge on the right */
  badgeValue?: number
  /** Passes the tabs index to the parent */
  onClick?: () => void
  /** Flag to set the flags state to active (blue bottom border) */
  active?: boolean
  /** Flag to set the flags state to disabled */
  disabled?: boolean
}

const StyledTabContent = styled.div<{
  active?: boolean
}>`
  display: flex;
  margin-bottom: 1rem;
  padding: 0 0.25rem;
`

const StyledIWTypography = styled(IWTypography)<{
  active?: boolean
  disabled?: boolean
}>`
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.palette.grey[400]
    }
    return props.active
      ? props.theme.palette.grey[700]
      : props.theme.palette.grey[500]
  }};
`

const BadgeIconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  color: ${(props) => props.theme.palette.primary[700]};
  background-color: ${(props) => props.theme.palette.primary[50]};
  border-radius: 2rem;
`

const StyledActiveIndicator = styled.div<{ active?: boolean }>`
  height: 0.125rem;
  width: 100%;
  transition: all 0.2s ease-in-out;
  background: ${(props) =>
    props.active ? props.theme.palette.primary[700] : 'transparent'};
`

const StyledTab = styled.button<{ active?: boolean; disabled?: boolean }>`
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  color: ${(props) =>
    props.disabled
      ? props.theme.palette.grey[400]
      : props.theme.palette.grey[500]};
  pointer-events: ${(props) => props.disabled && 'none'};

  &:hover {
    ${StyledActiveIndicator} {
      background: ${(props) =>
        props.active
          ? props.theme.palette.primary[700]
          : props.theme.palette.grey[300]};
    }

    ${StyledIWTypography} {
      color: ${(props) => props.theme.palette.grey[700]}
    }
`

/**
 * The tab component for the app which displays a label and optional badge value
 * It can be set to active to indicate the selected tab
 * The onClick handler returns the tab index to the parent to indicate this tab was clicked
 */
const IWTab = ({ label, badgeValue, active, disabled, onClick }: Props) => {
  return (
    <StyledTab
      role="tab"
      active={active}
      disabled={disabled}
      onClick={() => {
        return !active && onClick && onClick()
      }}
    >
      <StyledTabContent>
        <StyledIWTypography
          disabled={disabled}
          active={active}
          as="span"
          size="sm"
          weight="medium"
        >
          {label}
        </StyledIWTypography>
        {badgeValue ? (
          <BadgeIconWrapper>
            <IWTypography as="span" size="xs" weight="medium" inheritFontColor>
              {badgeValue}
            </IWTypography>
          </BadgeIconWrapper>
        ) : (
          ''
        )}
      </StyledTabContent>
      <StyledActiveIndicator active={active} />
    </StyledTab>
  )
}

export default IWTab
