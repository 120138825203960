import React from 'react'
import { UserType } from 'shared/types'

/**
 * UserContext contains the current user info so any
 * component on the app can make use of it.
 *
 * Ideally it will have been set up by an authentication
 * layer before any component using it is rendered.
 */
const UserContext = React.createContext<UserType>({
  userId: 'someuserid',
  email: 'anonymous@email.com',
  name: 'some name',
  organization: 'some organization',
  availableTools: [],
  availableToolPolicies: [],
  availablePodTypesMap: {},
  availableDashboardType: [],
})

export default UserContext
