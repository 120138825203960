import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
  /** Props are key values paris which define the defaults and values being used in the hook */
  [key: string]: string | number | boolean
}

/**
 * This is a hook to simulate 2 way binding for a component and the query params.
 * It returns the state as defined by the query and a function to set a query param
 * When a query param is set, it is reflected in the hook state AND in the url query
 */
const useBoundQueryParams = (props: Props): { state; setParam } => {
  const location = useLocation()
  const { pathname, search } = location
  const navigate = useNavigate()
  const [state, setState] = useState<Props>(props)

  const [debouncer] = useState<any>({ timeout: null })

  const setParam = (key, value) => {
    // Ensure a valid type is being set here, so we can't set "1" where we expect 1
    if (typeof value === typeof state[key]) {
      // Push the new path with updated search (query)
      setState((prevState) => {
        const newSearch = new URLSearchParams({
          ...prevState,
          [key]: value,
        }).toString()
        // Search has the ? but new search doesn't
        if (search !== `?${newSearch}`) {
          if (debouncer.timeout) {
            clearTimeout(debouncer.timeout)
          }
          // We debounce here so that multiple changes pushed from the parent component
          // at the same time don't create multiple histories
          // For example, When changing the items per page on a table it also changes the page number
          debouncer.timeout = setTimeout(() => {
            debouncer.timeout = null
            navigate({
              pathname,
              search: newSearch,
            })
          }, 200)
        }
        return { ...prevState, [key]: value }
      })
    }
  }

  /**
   * Parses the query string into an object of the expected type per the params
   * @param string - query string
   */
  const parseQueryString = (string) => {
    // ?activeTab=0&page=1&perPage=10
    const split = string.split('&')
    // Remove the ? from the first one
    split[0] = split[0].substr(1, split[0].length - 1)
    // Split up into key value pairs
    return split.reduce((acc, val) => {
      const [key, value] = val.split('=')
      const expectedType = typeof state[key]
      if (expectedType === 'boolean') {
        acc[key] = value === 'true'
      } else if (expectedType === 'number') {
        acc[key] = Number.parseFloat(value)
      } else {
        acc[key] = value
      }
      return acc
    }, {})
  }

  /* On search change, override any defaults provided to the hook with query
     values of the correct type */
  useEffect(() => {
    let typedQueryParams = {}
    if (search) {
      typedQueryParams = parseQueryString(search)
    }
    setState((prevState) => ({ ...prevState, ...typedQueryParams }))
    // Skip here since adding parseQueryString in deps causes infinite render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return {
    state,
    setParam,
  }
}

export default useBoundQueryParams
