import { DefaultTheme } from 'styled-components'
import { DefaultVariantHues, Hue } from '../../../../@types/styled'

interface ExtendedDefaultTheme extends DefaultTheme {
  defaultVariantHues: DefaultVariantHues
}

export const getBackgroundHue = (
  props,
  theme: DefaultTheme | ExtendedDefaultTheme,
): Hue => {
  if (props.color && 'defaultVariantHues' in theme) {
    return theme.defaultVariantHues[props.color].background
  }
  return props.hue
}

export const getBackgroundColor = (
  props,
  theme: DefaultTheme | ExtendedDefaultTheme,
): string => {
  const hue = getBackgroundHue(props, theme)
  return theme.palette[hue.color][hue.value]
}

export const getFontHue = (
  props,
  theme: DefaultTheme | ExtendedDefaultTheme,
): Hue => {
  if (props.color && 'defaultVariantHues' in theme) {
    return theme.defaultVariantHues[props.color].font
  }
  if (props.fontHue) {
    return props.fontHue
  }
  return { color: 'grey', value: 900 }
}

export const getFontColor = (
  props,
  theme: DefaultTheme | ExtendedDefaultTheme,
): string => {
  if (props.inheritFontColor) {
    return 'inherit'
  }
  const hue = getFontHue(props, theme)
  return theme.palette[hue.color][hue.value]
}
