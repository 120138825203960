import { useQuery } from 'react-query'
import { getOrgUsers, getAllOrgs } from 'shared/userServiceClient'

const useGetOrgUsers = () => {
  return useQuery('availableOrgUsers', getOrgUsers, {
    staleTime: 1 * 60 * 60 * 1000,
  })
}

export const useGetAllOrgs = (isAdmin: boolean) => {
  return useQuery('allOrgs', getAllOrgs, {
    enabled: isAdmin || false,
    staleTime: 1 * 60 * 60 * 1000,
    select: (data) => (isAdmin ? data : undefined),
  })
}

export default useGetOrgUsers
