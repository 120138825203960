import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import { Column } from 'shared/components/thunderbolt/IWTable'
import { getSubmissionHistoryBySubmissionGroupId } from 'shared/loadSchedulingClient'
import styled from 'styled-components'
import LoadSchedulingTable, { EmptyMessage } from '../components/LSTable'
import { getSubmissionHistoryTableSchemaByMarket } from '../schemas'
import { LsSchedulingType, SubmissionHistory } from '../types'

const StyledWrapper = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`

const LeftHand = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1;
`

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin-right: 0.5rem;
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
`

const SubmissionHistoryDetailsPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { submissionGroupId = '', market: currentMarket = '' } = useParams()

  const [searchParams] = useSearchParams()
  const [columns, setColumns] = useState<Column[]>([])
  const [tableItems, setTableItems] = useState<unknown[]>([])

  const { data: submissionHistory, isLoading: isLoadingSubmissions } = useQuery(
    ['submission-history-by-submission-meta-id'],
    () => {
      return getSubmissionHistoryBySubmissionGroupId(submissionGroupId, {
        market: currentMarket,
        brand: searchParams.get('brand'),
        zone: searchParams.get('zone'),
        location: searchParams.get('location'),
      })
    },
  )

  const transformErcotSubmissionHistoryData = (data: SubmissionHistory[]) => {
    return data.reduce((memo, submission) => {
      const currentSchedulingTypeTranslation =
        submission.schedulingType === 'PHYSICAL_SCHEDULING'
          ? t('loadScheduling.physicalType')
          : t('loadScheduling.demandType')

      const base = {
        ...submission,
        schedulingType: currentSchedulingTypeTranslation,
        createdAt: `${DateTime.fromISO(
          submission.createdAt as string,
        ).toLocaleString(DateTime.DATETIME_SHORT)} ${DateTime.fromISO(
          submission.createdAt as string,
        ).toFormat('ZZZZ')}`,
        updatedAt: `${DateTime.fromISO(
          submission.updatedAt as string,
        ).toLocaleString(DateTime.DATETIME_SHORT)} ${DateTime.fromISO(
          submission.updatedAt as string,
        ).toFormat('ZZZZ')}`,
      }

      if (submission.schedulingType === 'PHYSICAL_SCHEDULING') {
        if (typeof base.intervals === 'string') {
          base.intervals = JSON.parse(base.intervals)
        }

        base.intervals.forEach(({ interval, load }) => {
          base[interval] = load
        })
        memo.push(base)
      } else {
        submission.intervals.forEach(
          ({ interval, load, price, submitType, curveType }) => {
            const flattened = {
              ...base,
              interval,
              load,
              price,
              submitType,
              curveType,
            }
            memo.push(flattened)
          },
        )
      }

      return memo
    }, [] as unknown[])
  }

  const tableMapper = (market: string, data: SubmissionHistory[]) => {
    if (market === 'ERCOT') {
      return transformErcotSubmissionHistoryData(data)
    }

    return data.map((item) => {
      const submission = { ...item }
      submission.market = market

      submission.createdAt = `${DateTime.fromISO(
        submission.createdAt as string,
      ).toLocaleString(DateTime.DATETIME_SHORT)} ${DateTime.fromISO(
        submission.createdAt as string,
      ).toFormat('ZZZZ')}`

      submission.schedulingType =
        submission.schedulingType === 'PHYSICAL_SCHEDULING'
          ? t('loadScheduling.physicalType')
          : submission.schedulingType === 'DEMAND_SCHEDULING'
          ? t('loadScheduling.demandType')
          : 'N/A'

      submission.message = submission.wasSuccessful
        ? submission.submissionId
        : !submission.wasSuccessful && submission.submissionLog
        ? submission.submissionLog
        : t('loadScheduling.failed')

      submission.submitStatus = submission.wasSuccessful
        ? t('loadScheduling.success')
        : t('loadScheduling.failed')

      if (typeof submission.intervals === 'string') {
        submission.intervals = JSON.parse(submission.intervals)
      }

      submission.intervals.forEach(({ interval, load }) => {
        submission[interval] = load
      })

      return submission
    })
  }

  useEffect(() => {
    if (submissionHistory && submissionHistory.length !== 0) {
      let currentSchedulingType: LsSchedulingType | undefined = undefined

      if (
        submissionHistory.every(
          (v) => v.schedulingType === 'PHYSICAL_SCHEDULING',
        )
      ) {
        currentSchedulingType = 'PHYSICAL_SCHEDULING'
      } else if (
        submissionHistory.every((v) => v.schedulingType === 'DEMAND_SCHEDULING')
      ) {
        currentSchedulingType = 'DEMAND_SCHEDULING'
      }

      const transformedSubmissions = tableMapper(
        currentMarket,
        submissionHistory,
      )
      const cols = getSubmissionHistoryTableSchemaByMarket(
        currentMarket,
        currentSchedulingType,
      )

      setTableItems([...transformedSubmissions])
      setColumns([...cols])
    }
  }, [submissionHistory])

  if (isLoadingSubmissions && columns.length === 0) {
    return <IWLoading />
  }

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <StyledWrapper>
          <LeftHand>
            <StyledButton
              onClick={() => navigate(-1)}
              data-testid="page-header-back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </StyledButton>
            <StyledTitle>
              {t('loadScheduling.submissionHistoryDetails')}
            </StyledTitle>
          </LeftHand>
        </StyledWrapper>
      </IWPageHeader>

      <main>
        {!isLoadingSubmissions &&
          !Boolean(tableItems.length) &&
          !Boolean(columns.length) && <EmptyMessage />}

        {!isLoadingSubmissions &&
          Boolean(tableItems?.length) &&
          Boolean(columns.length) && (
            <LoadSchedulingTable
              columns={columns}
              items={tableItems}
              isLoading={isLoadingSubmissions}
            />
          )}
      </main>
    </>
  )
}

export default SubmissionHistoryDetailsPage
