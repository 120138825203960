import { Mapping } from 'tools/importManager/types'
import axiosApiClient from './apiClient'

export const uploadImportedFile = async (
  file: File,
  options: {
    mappings: Mapping[]
  },
  schema: string,
) => {
  const url = `/credentials/v1/import-manager/upload-file`
  const formData = new FormData()
  formData.append('file', file)
  formData.append('options', JSON.stringify(options))
  formData.append('schemaType', schema)

  return axiosApiClient.post(url, formData)
}
