import { Reducer, useReducer } from 'react'
import { Props } from 'shared/components/thunderbolt/IWRelativeDatePicker'

export const defaultHasErrorsCalculation = (state: DefaultState) => {
  if (!state.selectedMainOption) {
    return true
  }
  if (
    state.selectedMainOption.value === 'after' ||
    state.selectedMainOption.value === 'before' ||
    state.selectedMainOption.value === 'on'
  ) {
    if (!state.selectedSpecificDatePickerDate) {
      return true
    }
  }
  if (
    state.selectedMainOption.value === 'previous' ||
    state.selectedMainOption.value === 'next'
  ) {
    if (
      !state.timeLengthValue ||
      !state.selectedTimeLengthOption ||
      !state.selectedRelativeOption
    ) {
      return true
    }
    if (
      state.selectedRelativeOption.value === 'specificDate' &&
      !state.selectedSpecificDatePickerDate
    ) {
      return true
    }
  }
  if (state.selectedMainOption.value === 'between') {
    if (!state.selectedDatePickerMin || !state.selectedDatePickerMax) {
      return true
    }
    if (
      state.selectedDatePickerMin &&
      state.selectedDatePickerMax &&
      state.selectedDatePickerMin > state.selectedDatePickerMax
    ) {
      return true
    }
  }
  return false
}

export const defaultIndividualErrorsCalculation = (
  state: DefaultState,
): IndividualErrors => {
  return {
    isMainOptionError: !state.selectedMainOption,
    isSpecificDatePickerError: !state.selectedSpecificDatePickerDate,
    isDatePickerMinError: !state.selectedDatePickerMin,
    isDatePickerMaxError: !state.selectedDatePickerMax,
    hasDualDatePickerError: Boolean(
      state.selectedDatePickerMin &&
        state.selectedDatePickerMax &&
        state.selectedDatePickerMin > state.selectedDatePickerMax,
    ),
    isRelativeOptionError: !state.selectedRelativeOption,
    isTimeLengthOptionError: !state.selectedTimeLengthOption,
    isTimeLengthValueError: !state.timeLengthValue,
  }
}

export type DefaultState = Pick<
  Props,
  | 'selectedMainOption'
  | 'selectedSpecificDatePickerDate'
  | 'selectedDatePickerMin'
  | 'selectedDatePickerMax'
  | 'selectedRelativeOption'
  | 'selectedTimeLengthOption'
  | 'timeLengthValue'
  | 'disabledMainOptions'
  | 'disabledRelativeOptions'
>

type ReducerState = DefaultState

interface ModifyState extends ReducerState {
  type: 'MODIFY_STATE'
}

const modifyState = ({ ...val }: ReducerState): ModifyState => ({
  type: 'MODIFY_STATE',
  ...val,
})

type ReducerAction = ReturnType<typeof modifyState>

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...rest } = action
  switch (action.type) {
    case 'MODIFY_STATE':
      return {
        ...state,
        ...rest,
      }

    default:
      return state
  }
}

type IndividualErrors = Pick<
  Props,
  | 'isMainOptionError'
  | 'isSpecificDatePickerError'
  | 'isDatePickerMinError'
  | 'isDatePickerMaxError'
  | 'hasDualDatePickerError'
  | 'isRelativeOptionError'
  | 'isTimeLengthOptionError'
  | 'isTimeLengthValueError'
>

interface UseRelativeDatePickerProps extends DefaultState {
  hasErrorsCalculation?: (state: DefaultState) => boolean
  individualErrorCalculation?: (state: DefaultState) => IndividualErrors
}

export type ReturnProps = Omit<Props, 'shouldDisplayErrors'> & {
  hasErrors: boolean
  modifyFullState: (DefaultState) => void
}

const useRelativeDatePicker = ({
  selectedMainOption: defaultMainOption,
  selectedSpecificDatePickerDate: defaultSpecificDatePickerDate,
  timeLengthValue: defaultTimeLengthValue,
  selectedTimeLengthOption: defaultTimeLengthOption,
  selectedRelativeOption: defaultRelativeOption,
  selectedDatePickerMin: defaultDatePickerMin,
  selectedDatePickerMax: defaultDatePickerMax,
  hasErrorsCalculation,
  individualErrorCalculation,
  disabledMainOptions,
  disabledRelativeOptions,
}: UseRelativeDatePickerProps): ReturnProps => {
  const [state, dispatch] = useReducer(reducer, {
    selectedMainOption: defaultMainOption,
    selectedSpecificDatePickerDate: defaultSpecificDatePickerDate,
    timeLengthValue: defaultTimeLengthValue,
    selectedTimeLengthOption: defaultTimeLengthOption,
    selectedRelativeOption: defaultRelativeOption,
    selectedDatePickerMin: defaultDatePickerMin,
    selectedDatePickerMax: defaultDatePickerMax,
  })

  const handleMainOptionChange = (val: Props['selectedMainOption']) => {
    let newSelectedRelativeOption = state.selectedRelativeOption
    if (
      (val?.value === 'previous' &&
        state.selectedRelativeOption?.value === 'firstDateAvailable') ||
      (val?.value === 'next' &&
        state.selectedRelativeOption?.value === 'lastDateAvailable')
    ) {
      newSelectedRelativeOption = undefined
    }
    dispatch(
      modifyState({
        selectedMainOption: val,
        selectedRelativeOption: newSelectedRelativeOption,
      }),
    )
  }

  const individualErrors = individualErrorCalculation
    ? individualErrorCalculation(state)
    : undefined

  const hasErrors = hasErrorsCalculation ? hasErrorsCalculation(state) : false

  return {
    selectedMainOption: state.selectedMainOption,
    isMainOptionError: Boolean(individualErrors?.isMainOptionError),
    onMainOptionChange: handleMainOptionChange,
    selectedSpecificDatePickerDate: state.selectedSpecificDatePickerDate,
    isSpecificDatePickerError: Boolean(
      individualErrors?.isSpecificDatePickerError,
    ),
    onSpecificDatePickerChange: (
      date: Props['selectedSpecificDatePickerDate'],
    ) => dispatch(modifyState({ selectedSpecificDatePickerDate: date })),
    selectedDatePickerMin: state.selectedDatePickerMin,
    isDatePickerMinError: Boolean(individualErrors?.isDatePickerMinError),
    onDatePickerMinChange: (date: Props['selectedDatePickerMin']) =>
      dispatch(modifyState({ selectedDatePickerMin: date })),
    selectedDatePickerMax: state.selectedDatePickerMax,
    isDatePickerMaxError: Boolean(individualErrors?.isDatePickerMaxError),
    onDatePickerMaxChange: (date: Props['selectedDatePickerMax']) =>
      dispatch(modifyState({ selectedDatePickerMax: date })),
    hasDualDatePickerError: Boolean(individualErrors?.hasDualDatePickerError),
    selectedRelativeOption: state.selectedRelativeOption,
    isRelativeOptionError: Boolean(individualErrors?.isRelativeOptionError),
    onRelativeOptionChange: (val: Props['selectedRelativeOption']) =>
      dispatch(modifyState({ selectedRelativeOption: val })),
    selectedTimeLengthOption: state.selectedTimeLengthOption,
    isTimeLengthOptionError: Boolean(individualErrors?.isTimeLengthOptionError),
    onTimeLengthOptionChange: (val: Props['selectedTimeLengthOption']) =>
      dispatch(modifyState({ selectedTimeLengthOption: val })),
    timeLengthValue: state.timeLengthValue,
    isTimeLengthValueError: Boolean(individualErrors?.isTimeLengthValueError),
    onTimeLengthValueChange: (val: Props['timeLengthValue']) =>
      dispatch(modifyState({ timeLengthValue: val })),
    hasErrors,
    modifyFullState: (val: DefaultState) => dispatch(modifyState(val)),
    disabledMainOptions,
    disabledRelativeOptions,
  }
}

export default useRelativeDatePicker
