import { DateTime } from 'luxon'
import { Pod } from '../../insightsManager/types'
import { AggregationFn, TimeGroup } from '../types'

export const buildIntervalLabel = (pod: Pod, t) => {
  if (!pod.interval) {
    return t('insightManager.pods.interval.never')
  }
  if (!pod.nextRun) {
    return t('insightManager.pods.interval.nextRun')
  }
  return t('insightManager.pods.interval.nextRun', {
    value: DateTime.fromISO(pod.nextRun).toLocaleString(DateTime.DATE_SHORT),
  })
}

export const mapToDropdownOptions = <T>(
  options: T[],
  labelFn?: (key: T) => string,
) => {
  return options.map((o) => ({ label: labelFn ? labelFn(o) : o, value: o }))
}

export const getAlertTimeSeriesLabel = (
  timeGroupId?: TimeGroup,
  aggregationFnId?: AggregationFn,
  t?,
) => {
  if (!timeGroupId && !aggregationFnId && !t) {
    return ''
  }
  const groupIdLabel = t(`alertsManager.createAlert.triggerStep.${timeGroupId}`)
  const aggregationFnLabel = t(
    `alertsManager.createAlert.triggerStep.${aggregationFnId}`,
  )
  return `${groupIdLabel} (${aggregationFnLabel})`
}
