export const DOWNLOAD_ROW_LIMIT = 10000

export const TIMEZONES = {
  ERCOT: 'America/Chicago',
  PJM: 'America/New_York',
  'ISO-NE': 'America/New_York',
  ISONE: 'America/New_York',
  NEISO: 'America/New_York',
  NEPOOL: 'America/New_York',
  NYISO: 'America/New_York',
  MISO: 'America/New_York',
}

export {}
