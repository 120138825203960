import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { ButtonVariants, Props as IWButtonProps } from './IWButton'

type IWButtonType = ReactElement<IWButtonProps>

type Props = IWButtonProps & { children: IWButtonType | IWButtonType[] }

function getBorder(props) {
  return props.variant === 'outline'
    ? `1px solid ${props.theme.palette.grey[300]}`
    : `1px solid ${props.theme.palette.grey[0]}`
}

const StyledDiv = styled.div<{
  variant: ButtonVariants
}>`
  display: flex;

  & > * {
    border-radius: 0;
    border: none;
    border-top: ${getBorder};
    border-bottom: ${getBorder};
  }

  & > :first-child {
    border-radius: 0.375rem 0 0 0.375rem;
    border-left: ${getBorder};
  }

  & > :last-child {
    border-radius: 0 0.375rem 0.375rem 0;
    border-right: ${getBorder};
  }

  & > *:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
  }

  & > *:not(:last-child):not(:first-child) {
    border-left: none;
  }

  & > :only-child {
    border-radius: 0.375rem;
  }
`

/**
 * Pass this component one or multiple `<IWButton />` and you will have a
 * nice button group
 */
const IWButtonGroup = ({
  children,
  variant = 'outline',
  color = 'grey',
  hasSolidBackground,
}: Props) => {
  return (
    <StyledDiv variant={variant}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { variant, color, hasSolidBackground })
      })}
    </StyledDiv>
  )
}

export default IWButtonGroup
