import type {
  ValidActualUsageMetricIds,
  ValidAttributeInfoMetricIds,
  ValidRiskMetricIds,
  ValidShortTermForecastMetricIds,
  ValidShortTermForecastPerformanceMetricIds,
  ValidShortTermForecastPerformanceSystemMetricIds,
  ValidShortTermForecastPerformanceTopDownMetricIds,
  ValidShortTermForecastSystemMetricIds,
  ValidWholesaleSettlementMetricIds,
} from 'tools/insightsManager/types'
import { ValidLongTermForecastMetricIds } from 'tools/insightsManager/types'

export interface MetricObject<T> {
  label: string
  description: string
  value: T
  isDisabled: boolean
}

// TODO: This seems too convoluted just to get a label or value... there has to be a better way...
export const getShortTermForecastMetrics = (
  t,
  availableMetrics: ValidShortTermForecastMetricIds[] | undefined,
): MetricObject<ValidShortTermForecastMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidShortTermForecastMetricIds>[] = [
    {
      label: t(`insights.shortTermForecast.metrics.settlement.label`),
      description: t(
        `insights.shortTermForecast.metrics.settlement.description`,
      ),
      value: 'settlement',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.meter.label`),
      description: t(`insights.shortTermForecast.metrics.meter.description`),
      value: 'meter',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.customerType.label`),
      description: t(
        `insights.shortTermForecast.metrics.customerType.description`,
      ),
      value: 'customerType',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.productType.label`),
      description: t(
        `insights.shortTermForecast.metrics.productType.description`,
      ),
      value: 'productType',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.profile.label`),
      description: t(`insights.shortTermForecast.metrics.profile.description`),
      value: 'profile',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.topDown.label`),
      description: t(`insights.shortTermForecast.metrics.topDown.description`),
      value: 'topDown',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.settlementWeather.label`),
      description: t(
        `insights.shortTermForecast.metrics.settlementWeather.description`,
      ),
      value: 'settlementWeather',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.customerTypeWeather.label`),
      description: t(
        `insights.shortTermForecast.metrics.customerTypeWeather.description`,
      ),
      value: 'customerTypeWeather',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.profileWeather.label`),
      description: t(
        `insights.shortTermForecast.metrics.profileWeather.description`,
      ),
      value: 'profileWeather',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecast.metrics.topDownWeather.label`),
      description: t(
        `insights.shortTermForecast.metrics.topDownWeather.description`,
      ),
      value: 'topDownWeather',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getShortTermForecastPerformanceMetrics = (
  t,
  availableMetrics: ValidShortTermForecastPerformanceMetricIds[] | undefined,
): MetricObject<ValidShortTermForecastPerformanceMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidShortTermForecastPerformanceMetricIds>[] = [
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.allPerformance.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.allPerformance.description`,
      ),
      value: 'allPerformance',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecastPerformance.metrics.dailyMape.label`),
      description: t(
        `insights.shortTermForecastPerformance.metrics.dailyMape.description`,
      ),
      value: 'dailyMape',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.monthlyMape.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.monthlyMape.description`,
      ),
      value: 'monthlyMape',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.allBrandPerformance.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.allBrandPerformance.description`,
      ),
      value: 'allBrandPerformance',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.dailyBrandMape.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.dailyBrandMape.description`,
      ),
      value: 'dailyBrandMape',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.monthlyBrandMape.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.monthlyBrandMape.description`,
      ),
      value: 'monthlyBrandMape',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.allPortfolioPerformance.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.allPortfolioPerformance.description`,
      ),
      value: 'allPortfolioPerformance',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.dailyPortfolioMape.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.dailyPortfolioMape.description`,
      ),
      value: 'dailyPortfolioMape',
      isDisabled: false,
    },
    {
      label: t(
        `insights.shortTermForecastPerformance.metrics.monthlyPortfolioMape.label`,
      ),
      description: t(
        `insights.shortTermForecastPerformance.metrics.monthlyPortfolioMape.description`,
      ),
      value: 'monthlyPortfolioMape',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getShortTermForecastPerformanceTopDownMetrics = (
  t,
  availableMetrics:
    | ValidShortTermForecastPerformanceTopDownMetricIds[]
    | undefined,
): MetricObject<ValidShortTermForecastPerformanceTopDownMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidShortTermForecastPerformanceTopDownMetricIds>[] =
    [
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.allPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.allPerformance.description`,
        ),
        value: 'allPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.dailyMape.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.dailyMape.description`,
        ),
        value: 'dailyMape',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.monthlyMape.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.monthlyMape.description`,
        ),
        value: 'monthlyMape',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.allBrandPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.allBrandPerformance.description`,
        ),
        value: 'allBrandPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.dailyBrandMape.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.dailyBrandMape.description`,
        ),
        value: 'dailyBrandMape',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.monthlyBrandMape.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.monthlyBrandMape.description`,
        ),
        value: 'monthlyBrandMape',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.allPortfolioPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.allPortfolioPerformance.description`,
        ),
        value: 'allPortfolioPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.dailyPortfolioMape.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.dailyPortfolioMape.description`,
        ),
        value: 'dailyPortfolioMape',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.monthlyPortfolioMape.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceTopDown.metrics.monthlyPortfolioMape.description`,
        ),
        value: 'monthlyPortfolioMape',
        isDisabled: false,
      },
    ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getRiskManagementMetrics = (
  t,
  availableMetrics: ValidRiskMetricIds[] | undefined,
): MetricObject<ValidRiskMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }
  const metrics: MetricObject<ValidRiskMetricIds>[] = [
    {
      label: t(`insights.riskManagement.metrics.netOpenPosition.label`),
      description: t(
        `insights.riskManagement.metrics.netOpenPosition.description`,
      ),
      value: 'netOpenPosition',
      isDisabled: false,
    },
    {
      label: t(`insights.riskManagement.metrics.netOpenPositionLtf.label`),
      description: t(
        `insights.riskManagement.metrics.netOpenPositionLtf.description`,
      ),
      value: 'netOpenPositionLtf',
      isDisabled: false,
    },
    {
      label: t(`insights.riskManagement.metrics.supplyTrades.label`),
      description: t(
        `insights.riskManagement.metrics.supplyTrades.description`,
      ),
      value: 'supplyTrades',
      isDisabled: false,
    },
    {
      label: t(`insights.riskManagement.metrics.explodedTrades.label`),
      description: t(
        `insights.riskManagement.metrics.explodedTrades.description`,
      ),
      value: 'explodedTrades',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getLongTermForecastMetrics = (
  t,
  availableMetrics: ValidLongTermForecastMetricIds[] | undefined,
): MetricObject<ValidLongTermForecastMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidLongTermForecastMetricIds>[] = [
    {
      label: t(`insights.longTermForecast.metrics.meter.label`),
      description: t(`insights.longTermForecast.metrics.meter.description`),
      value: 'meter',
      isDisabled: false,
    },
    {
      label: t(`insights.longTermForecast.metrics.customerType.label`),
      description: t(
        `insights.longTermForecast.metrics.customerType.description`,
      ),
      value: 'customerType',
      isDisabled: false,
    },
    {
      label: t(`insights.longTermForecast.metrics.blockType.label`),
      description: t(`insights.longTermForecast.metrics.blockType.description`),
      value: 'blockType',
      isDisabled: false,
    },
    {
      label: t(`insights.longTermForecast.metrics.productType.label`),
      description: t(
        `insights.longTermForecast.metrics.productType.description`,
      ),
      value: 'productType',
      isDisabled: false,
    },
    {
      label: t(`insights.longTermForecast.metrics.profile.label`),
      description: t(`insights.longTermForecast.metrics.profile.description`),
      value: 'profile',
      isDisabled: false,
    },
    {
      label: t(`insights.longTermForecast.metrics.settlement.label`),
      description: t(
        `insights.longTermForecast.metrics.settlement.description`,
      ),
      value: 'settlement',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getShortTermForecastSystemMetrics = (
  t,
  availableMetrics: ValidShortTermForecastSystemMetricIds[] | undefined,
): MetricObject<ValidShortTermForecastSystemMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidShortTermForecastSystemMetricIds>[] = [
    {
      label: t(`insights.shortTermForecastSystem.metrics.systemERCOT.label`),
      description: t(
        `insights.shortTermForecastSystem.metrics.systemERCOT.description`,
      ),
      value: 'systemERCOT',
      isDisabled: false,
    },
    {
      label: t(`insights.shortTermForecastSystem.metrics.systemPJM.label`),
      description: t(
        `insights.shortTermForecastSystem.metrics.systemPJM.description`,
      ),
      value: 'systemPJM',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getActualUsageMetrics = (
  t,
  availableMetrics: ValidActualUsageMetricIds[] | undefined,
): MetricObject<ValidActualUsageMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidActualUsageMetricIds>[] = [
    {
      label: t(`insights.actualUsage.metrics.settlement.label`),
      description: t(`insights.actualUsage.metrics.settlement.description`),
      value: 'settlement',
      isDisabled: false,
    },
    {
      label: t(`insights.actualUsage.metrics.meterHourly.label`),
      description: t(`insights.actualUsage.metrics.meterHourly.description`),
      value: 'meterHourly',
      isDisabled: false,
    },
    {
      label: t(`insights.actualUsage.metrics.meterDaily.label`),
      description: t(`insights.actualUsage.metrics.meterDaily.description`),
      value: 'meterDaily',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getWholesaleSettlementMetrics = (
  t,
  availableMetrics: ValidWholesaleSettlementMetricIds[] | undefined,
): MetricObject<ValidWholesaleSettlementMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidWholesaleSettlementMetricIds>[] = [
    {
      label: t(`insights.wholesaleSettlement.metrics.summaryERCOT.label`),
      description: t(
        `insights.wholesaleSettlement.metrics.summaryERCOT.description`,
      ),
      value: 'summaryERCOT',
      isDisabled: false,
    },
    {
      label: t(`insights.wholesaleSettlement.metrics.allocationERCOT.label`),
      description: t(
        `insights.wholesaleSettlement.metrics.allocationERCOT.description`,
      ),
      value: 'allocationERCOT',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getShortTermForecastPerformanceSystemMetrics = (
  t,
  availableMetrics:
    | ValidShortTermForecastPerformanceSystemMetricIds[]
    | undefined,
): MetricObject<ValidShortTermForecastPerformanceSystemMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidShortTermForecastPerformanceSystemMetricIds>[] =
    [
      {
        label: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemERCOTAllPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemERCOTAllPerformance.description`,
        ),
        value: 'systemERCOTAllPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemERCOTDailyPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemERCOTDailyPerformance.description`,
        ),
        value: 'systemERCOTDailyPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemERCOTMonthlyPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemERCOTMonthlyPerformance.description`,
        ),
        value: 'systemERCOTMonthlyPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemPJMAllPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemPJMAllPerformance.description`,
        ),
        value: 'systemPJMAllPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemPJMDailyPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemPJMDailyPerformance.description`,
        ),
        value: 'systemPJMDailyPerformance',
        isDisabled: false,
      },
      {
        label: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemPJMMonthlyPerformance.label`,
        ),
        description: t(
          `insights.shortTermForecastPerformanceSystem.metrics.systemPJMMonthlyPerformance.description`,
        ),
        value: 'systemPJMMonthlyPerformance',
        isDisabled: false,
      },
    ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}

export const getAttributeInfoMetrics = (
  t,
  availableMetrics: ValidAttributeInfoMetricIds[] | undefined,
): MetricObject<ValidAttributeInfoMetricIds>[] => {
  if (!availableMetrics || availableMetrics.length <= 0) {
    return []
  }

  const metrics: MetricObject<ValidAttributeInfoMetricIds>[] = [
    {
      label: t(`insights.attributeInfo.metrics.meter.label`),
      description: t(`insights.attributeInfo.metrics.meter.description`),
      value: 'meter',
      isDisabled: false,
    },
  ]
  return metrics.filter((m) => availableMetrics.includes(m.value))
}
