import { useState } from 'react'

type LocalCredential = {
  isSelected: boolean
  name: string
  description: string
  id: string
}
// TODO: TYPE THIS CORRECTLY

function usePodIntegrations({
  getName,
  getDescription,
}: {
  getName: (t) => string
  getDescription: (t) => string
}) {
  const [localCredentials, setLocalCredentials] = useState<LocalCredential[]>(
    [],
  )

  const handleLocalCredentials = (availableCredentials, subscriptions) => {
    if (availableCredentials && subscriptions) {
      setLocalCredentials(() => {
        return availableCredentials?.map((r) => {
          return {
            id: r.credentialId,
            name: getName(r),
            description: getDescription(r),
            isSelected: Boolean(
              subscriptions.find(
                (subscription) => subscription.credentialId === r.credentialId,
              ),
            ),
          }
        })
      })
    }
  }

  const toggleCredentials = (toggledCredentialId) => {
    setLocalCredentials((prev) => {
      return prev?.map((r) => {
        if (r.id === toggledCredentialId) {
          return { ...r, isSelected: !r.isSelected }
        }
        return r
      })
    })
  }

  const handleHasIntegrations = () => {
    return Boolean(localCredentials?.some((r) => r.isSelected))
  }

  return {
    handleLocalCredentials,
    localCredentials,
    toggleCredentials,
    hasIntegrations: handleHasIntegrations,
  }
}

export default usePodIntegrations
