import React, { ReactNode, SyntheticEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  deepCopy,
  getFilterBadgeRangeDisplayString,
} from 'shared/utils/filterBadgesUtils'
import { useTranslation } from 'react-i18next'
import { Popover } from 'react-tiny-popover'
import { MeasureRangeFilter } from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import IWButton from './IWButton'
import IWDropdown, { DropdownValueProps } from './IWDropdown'
import IWTextInput from './IWTextInput'
import IWGeneralBadge from './IWGeneralBadge'

export type FilterBadgesRangeState = Pick<
  MeasureRangeFilter,
  | 'selectedFirstConditionOption'
  | 'selectedSecondConditionOption'
  | 'firstConditionValue'
  | 'secondConditionValue'
>

interface Props {
  children: JSX.Element & ReactNode
  isOpen: boolean
  rangeFilter: FilterBadgesRangeState
  onConfirm: () => void
  onCancel: () => void
  onFirstConditionChange: (value: DropdownValueProps) => void
  onSecondConditionChange: (value: DropdownValueProps) => void
  onFirstConditionValueChange: (
    value: React.ChangeEvent<HTMLInputElement>,
  ) => void
  onSecondConditionValueChange: (
    value: React.ChangeEvent<HTMLInputElement>,
  ) => void
}

interface IWFilterBadgesRangeProps {
  id: string
  onConfirm: (id: string, state: FilterBadgesRangeState) => void
  onRemove: (id: string) => void
  onCancel: (id: string) => void
  isOpen: boolean
  onClick: (e: SyntheticEvent) => void
}

const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
  min-width: 400px;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 100%;
`

const StyledFlexRow = styled.div`
  display: flex;
  gap: 1rem;
`

const StyleInputWrapper = styled.div`
  margin-right: 1rem;
  width: 100%;
`

const IWFilterBadgeRangeWrapper = ({
  children,
  isOpen,
  rangeFilter,
  onCancel,
  onConfirm,
  onFirstConditionChange,
  onSecondConditionChange,
  onFirstConditionValueChange,
  onSecondConditionValueChange,
}: Props) => {
  const { t } = useTranslation()
  const firstConditions = [
    {
      value: '>',
      label: t(`rangeFilterSection.options.greaterThan`),
    },
    {
      value: '>=',
      label: t(`rangeFilterSection.options.greaterThanOrEqualTo`),
    },
  ]

  const secondConditions = [
    {
      value: '<',
      label: t(`rangeFilterSection.options.lessThan`),
    },
    {
      value: '<=',
      label: t(`rangeFilterSection.options.lessThanOrEqualTo`),
    },
  ]

  return (
    <Popover
      padding={4}
      reposition
      containerStyle={{ zIndex: '3' }}
      positions={['bottom']}
      align="start"
      isOpen={isOpen}
      onClickOutside={onCancel}
      content={() => {
        return (
          <StyledPopupContent onClick={(e) => e.stopPropagation()}>
            <StyledFlexRow>
              <StyledIWDropdown
                name="first-condition"
                fullWidth
                label={t('rangeFilterSection.condition')}
                value={
                  rangeFilter.selectedFirstConditionOption ||
                  onFirstConditionChange(firstConditions[0])
                }
                onChange={onFirstConditionChange}
                options={firstConditions}
                placeholder={t('placeholders.select')}
              />

              <StyleInputWrapper>
                <IWTextInput
                  data-testid="measure-range-first-condition-input"
                  name="minvalue"
                  type="number"
                  label={t('rangeFilterSection.minValue')}
                  value={rangeFilter.firstConditionValue ?? ''}
                  onChange={onFirstConditionValueChange}
                  placeholder={t('placeholders.enterValue')}
                />
              </StyleInputWrapper>
            </StyledFlexRow>

            <StyledFlexRow>
              <StyledIWDropdown
                name="second-condition"
                fullWidth
                label={t('rangeFilterSection.condition')}
                value={
                  rangeFilter.selectedSecondConditionOption ||
                  onSecondConditionChange(secondConditions[0])
                }
                onChange={onSecondConditionChange}
                options={secondConditions}
                placeholder={t('placeholders.select')}
              />

              <StyleInputWrapper>
                <IWTextInput
                  data-testid="measure-range-second-condition-input"
                  name="maxvalue"
                  type="number"
                  label={t('rangeFilterSection.maxValue')}
                  value={rangeFilter.secondConditionValue ?? ''}
                  onChange={onSecondConditionValueChange}
                  placeholder={t('placeholders.enterValue')}
                />
              </StyleInputWrapper>
            </StyledFlexRow>
            <StyledButtonDiv>
              <IWButton
                data-testid="string-selector-cancel"
                variant="alternative"
                onClick={onCancel}
              >
                {t(`button.cancel`)}
              </IWButton>
              <IWButton
                disabled={
                  rangeFilter.firstConditionValue === undefined &&
                  rangeFilter.secondConditionValue === undefined
                }
                data-testid="string-selector-confirm"
                onClick={onConfirm}
              >
                {t(`button.confirm`)}
              </IWButton>
            </StyledButtonDiv>
          </StyledPopupContent>
        )
      }}
    >
      {children}
    </Popover>
  )
}

const IWFilterBadgesRange = ({
  id,
  onConfirm,
  onRemove,
  isOpen,
  onClick,
  onCancel,
}: IWFilterBadgesRangeProps) => {
  const defaults = {
    firstConditionValue: undefined,
    secondConditionValue: undefined,
    selectedFirstConditionOption: undefined,
    selectedSecondConditionOption: undefined,
  }
  const [summary, setSummary] = useState<string>(id)
  const [state, setState] = useState<FilterBadgesRangeState>(defaults)
  const ref = useRef<FilterBadgesRangeState>(deepCopy(state))

  const onFirstConditionChange = (selection: DropdownValueProps) => {
    setState({ ...state, selectedFirstConditionOption: selection })
  }

  const onSecondConditionChange = (selection: DropdownValueProps) => {
    setState({ ...state, selectedSecondConditionOption: selection })
  }

  const onFirstConditionValueChange = (e) => {
    const containErrors = e.target.value === ''
    const value = !containErrors ? parseFloat(e.target.value) : undefined
    setState({ ...state, firstConditionValue: value })
  }

  const onSecondConditionValueChange = (e) => {
    const containErrors = e.target.value === ''
    const value = !containErrors ? parseFloat(e.target.value) : undefined
    setState({ ...state, secondConditionValue: value })
  }

  const handleConfirm = () => {
    ref.current = deepCopy(state)
    const labelText = getFilterBadgeRangeDisplayString(id, state)
    onConfirm(id, state)
    setSummary(labelText)
  }

  const handleCancel = () => {
    const labelText = getFilterBadgeRangeDisplayString(id, ref.current)
    setState(() => ({ ...ref.current }))
    setSummary(labelText)
    onCancel(id)
  }

  return (
    <IWFilterBadgeRangeWrapper
      rangeFilter={state}
      isOpen={isOpen}
      onFirstConditionChange={onFirstConditionChange}
      onSecondConditionChange={onSecondConditionChange}
      onFirstConditionValueChange={onFirstConditionValueChange}
      onSecondConditionValueChange={onSecondConditionValueChange}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      <IWGeneralBadge
        label={summary}
        color="grey"
        icon={faTimesCircle}
        iconPosition="trailing"
        onClick={onClick}
        onIconClick={() => onRemove(id)}
      />
    </IWFilterBadgeRangeWrapper>
  )
}

export default IWFilterBadgesRange
