import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  faCircleExclamation,
  faGaugeMax,
  faLightbulbOn,
  faRetweet,
  faSearch,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { IWError } from 'shared/components/thunderbolt/IWCommonLabels'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import IWProductList from 'shared/components/thunderbolt/IWProductList'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import { useDebounceInput } from 'shared/hooks/useDebouncer'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import { Pod, PodType, SharingRole } from '../../insightsManager/types'
import { buildIntervalLabel } from '../utils/utils'
import { getAlertCompatiblePods } from '../../../shared/podServiceClient'
import IWNoResultsMessage from '../../../shared/components/thunderbolt/IWNoResultsMessage'
import { WizardStepProps } from '../../../shared/types'

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledPaginationControls = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

type InnoPodAlertState = {
  datasource: string
  userPodId: string
  userPodName: string
  interval?: string
  nextRun?: string
  podType: PodType
  userPodRole?: SharingRole
}

export type Props = WizardStepProps<InnoPodAlertState>

export default function InnoPodAlertStep({
  state,
  onChange,
  showErrors,
}: Props) {
  const { t } = useTranslation()
  const [currentSelection, setCurrentSelection] = useState<
    InnoPodAlertState | undefined
  >(state)
  // pagination & search server side
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearch = useDebounceInput(searchValue, 500)

  const handleClearInput = () => {
    setSearchValue('')
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const {
    data: pods,
    error,
    isLoading,
  } = useQuery(
    [
      'pods',
      'pageNumber',
      currentPage,
      'pageSize',
      itemsPerPage,
      'podName',
      debouncedSearch,
    ],
    () => {
      return getAlertCompatiblePods(
        currentPage - 1,
        itemsPerPage,
        debouncedSearch,
      )
    },
  )

  const handleSelection = (pod: InnoPodAlertState) => {
    setCurrentSelection(pod)
    onChange(pod, (error as boolean) || pod !== undefined)
  }

  const renderBadges = (pod: Pod) => {
    return [
      {
        label: t(`insights.${pod.podType.insightId}.label`),
        icon: faLightbulbOn,
      },
      {
        label: t(
          `insights.${pod.podType.insightId}.metrics.${pod.podType.metricId}.label`,
        ),
        icon: faGaugeMax,
      },
      {
        label: buildIntervalLabel(pod, t),
        icon: faRetweet,
      },
    ]
  }

  return (
    <>
      {isLoading && <IWLoading />}
      {!isLoading && (
        <IWFormSection
          sectionTitle={t(
            `alertsManager.createAlert.innoPodSelector.sectionHeader`,
          )}
        >
          <IWAlert
            icon={faCircleExclamation}
            label={t('alertsManager.createAlert.innoPodSelector.message')}
            color="primary"
            variant="alternative"
          />
          <StyledFilterSection>
            <IWTextInput
              data-testid="search-input"
              leadingIcon={{
                icon: faSearch,
              }}
              trailingIcon={{
                icon: faTimes,
                onIconClick: handleClearInput,
              }}
              autoFocus
              placeholder={t('placeholders.search')}
              onChange={handleInputChange}
              value={searchValue}
            />
          </StyledFilterSection>

          {pods && pods.total > 0 ? (
            <>
              <IWProductList>
                {pods.rows.map((pod) => (
                  <IWProductCard
                    key={pod.userPodId}
                    isSelected={currentSelection?.userPodId === pod.userPodId}
                    header={pod.userPodName}
                    onClick={() => {
                      handleSelection({
                        datasource: pod.datasource,
                        userPodId: pod.userPodId,
                        userPodName: pod.userPodName,
                        podType: pod.podType,
                        interval: pod.interval,
                        nextRun: pod.nextRun,
                        userPodRole: pod.myRole,
                      })
                    }}
                    badges={renderBadges(pod)}
                  />
                ))}
              </IWProductList>
              <StyledPaginationControls>
                <IWPaginationControls
                  perPageLabel={t('tables.rowsPerPage')}
                  itemsPerPage={itemsPerPage}
                  perPageOptions={[
                    { value: 10, label: t('numbers.number', { val: 10 }) },
                    { value: 20, label: t('numbers.number', { val: 20 }) },
                    { value: 40, label: t('numbers.number', { val: 40 }) },
                    { value: 50, label: t('numbers.number', { val: 50 }) },
                  ]}
                  totalItems={pods.total}
                  currentPage={currentPage}
                  onChangePage={(newPage) => setCurrentPage(newPage)}
                  onChangeItemsPerPage={(newPerPage) => {
                    setItemsPerPage(newPerPage)
                    setCurrentPage(1)
                  }}
                />
              </StyledPaginationControls>
            </>
          ) : (
            <IWNoResultsMessage />
          )}
          {showErrors && (
            <IWError label={t(`alertsManager.errors.enterAllRequired`)} />
          )}
        </IWFormSection>
      )}
    </>
  )
}
