import { useEffect, useState } from 'react'
import { PodType } from 'tools/insightsManager/types'

interface UseInsightsProps {
  selectedInsight?: PodType['insightId']
}

const useInsights = ({ selectedInsight }: UseInsightsProps) => {
  const [insight, setInsight] = useState<PodType['insightId'] | undefined>(
    selectedInsight,
  )

  const [hasInsightError, setHasInsightError] = useState<boolean | undefined>(
    false,
  )

  useEffect(() => {
    if (insight === undefined) {
      setHasInsightError(true)
    } else {
      setHasInsightError(false)
    }
  }, [insight])

  return {
    selectedInsight: insight,
    hasError: hasInsightError,
    onInsightChange: (val: PodType['insightId']) => setInsight(val),
  }
}

export default useInsights
