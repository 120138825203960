import { useMemo, useState, useRef } from 'react'
import { Duration, DateTime, Interval } from 'luxon'
import { getDaysOfWeek } from 'shared/utils/dateUtils'

interface Options {
  initialView?: DateTime
  weekStartsOn?: DateTime['weekday']
}

/**
 * Simple hook that provides a monthly view split by week. The calendar property
 * is a array of weekly arrays, where the week starts on weekStartsOn option and nonexisting dates are filled with undefined
 */
const useCalendar = ({
  initialView = DateTime.local(),
  weekStartsOn = 7,
}: Options) => {
  const [currentView, setCurrentView] = useState(initialView)
  const ref = useRef({ year: 0, month: '', weekdays: [] })

  const daysOfWeek = useMemo(() => getDaysOfWeek(weekStartsOn), [weekStartsOn])

  const calendar: Array<DateTime[]> = useMemo(() => {
    const interval = Interval.fromDateTimes(
      currentView.startOf('month'),
      currentView.endOf('month'),
    ).splitBy(Duration.fromObject({ day: 1 }))
    ref.current.year = interval[0].start.year
    ref.current.month = interval[0].start.monthLong

    // Split individual days into weeks
    let counter = 0
    const weekArr: Array<DateTime[]> = [[]]
    interval.forEach((int, index) => {
      if (int.start.weekday === weekStartsOn) {
        /**
         * If it happens the first day of month === weeksStartsOn
         * we need to keep counter on 0 until next pass
         */
        counter = index === 0 ? 0 : counter + 1
        weekArr[counter] = []
      }
      weekArr[counter].push(int.start)
    })
    return weekArr.map((w) => {
      if (weekArr.length < 7) {
        const undefinedArray = new Array(7 - w.length).fill(undefined)
        if (w[0].weekday === weekStartsOn) {
          return [...w, ...undefinedArray]
        }
        return [...undefinedArray, ...w]
      }
      return w
    })
  }, [currentView, weekStartsOn])

  const nextMonth = () => {
    setCurrentView((prev) => {
      return prev.plus({ month: 1 })
    })
  }

  const prevMonth = () => {
    setCurrentView((prev) => {
      return prev.minus({ month: 1 })
    })
  }

  return {
    calendar,
    nextMonth,
    prevMonth,
    setCurrentView,
    year: ref.current.year,
    month: ref.current.month,
    daysOfWeek,
  }
}

export default useCalendar
