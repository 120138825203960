import { Column } from 'shared/components/thunderbolt/IWTable'
import { LsSchedulingType } from './types'

const defaultTableSchema: Column[] = [
  {
    title: 'Market',
    accessor: 'market',
    align: 'left',
  },
  {
    title: 'Brand',
    accessor: 'brand',
    align: 'left',
  },
  {
    title: 'Zone',
    accessor: 'zone',
    align: 'left',
  },
  {
    title: 'Location',
    accessor: 'location',
    align: 'left',
  },
  {
    title: 'Location Name',
    accessor: 'locationName',
    align: 'left',
  },
  {
    title: 'Submitted Date',
    accessor: 'createdAt',
    align: 'left',
    minWidth: 225,
  },
  {
    title: 'Date',
    accessor: 'date',
    align: 'left',
    minWidth: 125,
  },
  {
    title: 'Schedule Type',
    accessor: 'schedulingType',
    align: 'left',
    minWidth: 30,
  },
  {
    title: 'Contract Name',
    accessor: 'contractName',
    align: 'left',
  },
  {
    title: 'Submitted By',
    accessor: 'submittedBy',
    align: 'left',
    minWidth: 225,
  },
  {
    title: 'Status',
    accessor: 'submitStatus',
    align: 'left',
  },
  {
    title: 'Message',
    accessor: 'message',
    align: 'left',
    minWidth: 200,
  },
  {
    title: 'Submission id',
    accessor: 'submissionId',
    align: 'left',
    minWidth: 200,
  },
  ...Array.from({ length: 24 }, (_, i) => ({
    title: `${i + 1}`,
    accessor: `${i + 1}`,
    align: 'right',
  })),
]

export function getDayAheadBidsConvertSchema(): Column[] {
  return [
    {
      title: 'brand',
      accessor: 'brand',
      align: 'left',
      pinnable: true,
      sticky: 'left',
    },
    {
      title: 'market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'zone',
      accessor: 'zone',
      align: 'left',
    },
    {
      title: 'location',
      accessor: 'location',
      align: 'left',
    },
    {
      title: 'location name',
      accessor: 'location_name',
      align: 'left',
    },
    {
      title: 'date',
      accessor: 'date',
      align: 'right',
    },
    ...Array.from({ length: 24 }, (_, i) => ({
      title: `${i + 1}`,
      accessor: `${i + 1}`,
      align: 'right',
    })),
  ]
}

export function getSubmitSummaryErrorTableSchema(): Column[] {
  return [
    {
      title: 'Status',
      accessor: 'name',
      align: 'left',
    },
    {
      title: 'Code',
      accessor: 'statusCode',
      align: 'left',
    },
    {
      title: 'Message',
      accessor: 'defaultMessage',
      align: 'left',
    },
  ]
}

export function getSubmitSummaryTableSchema(): Column[] {
  return [
    {
      title: 'Status',
      accessor: 'success',
      align: 'left',
    },
    {
      title: 'Market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'Brand',
      accessor: 'brand',
      align: 'left',
    },
    {
      title: 'Location',
      accessor: 'location',
      align: 'left',
    },
    {
      title: 'Submitted time',
      accessor: 'submittedTime',
      align: 'left',
    },
    {
      title: 'message',
      accessor: 'message',
      align: 'left',
    },
  ]
}

export function getSubmitSummaryERCOTTableSchema(): Column[] {
  return [
    {
      title: 'Market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'Brand',
      accessor: 'brand',
      align: 'left',
    },
    {
      title: 'Location',
      accessor: 'location',
      align: 'left',
    },
    {
      title: 'Date',
      accessor: 'date',
      align: 'left',
    },
    {
      title: 'MRID',
      accessor: 'mrid',
      align: 'left',
    },
    {
      title: 'Bid ID',
      accessor: 'bidId',
      align: 'left',
    },
    {
      title: 'message',
      accessor: 'message',
      align: 'left',
    },
    {
      title: 'Submit Type',
      accessor: 'submitType',
      align: 'left',
    },
    {
      title: 'Submit Status',
      accessor: 'submitStatus',
      align: 'left',
    },
  ]
}

export function getInternalBilateralTableSchema(): Column[] {
  return [
    {
      title: 'contract id',
      accessor: 'contract_id',
      align: 'left',
    },
    {
      title: 'Contract Name',
      accessor: 'contract_name',
      align: 'left',
      minWidth: 200,
    },
    {
      title: 'market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'brand',
      accessor: 'brand',
      align: 'left',
      pinnable: true,
      sticky: 'left',
    },
    {
      title: 'zone',
      accessor: 'zone',
      align: 'left',
    },
    {
      title: 'date',
      accessor: 'date',
      align: 'left',
      minWidth: 125,
    },
    {
      title: 'buyer',
      accessor: 'buyer',
      align: 'left',
      minWidth: 200,
    },
    {
      title: 'Buyer QSE Code',
      accessor: 'buyer_qse_code',
      align: 'left',
    },
    {
      title: 'seller',
      accessor: 'seller',
      align: 'left',
      minWidth: 200,
    },
    {
      title: 'Seller QSE Code',
      accessor: 'seller_qse_code',
      align: 'left',
    },
    ...Array.from({ length: 24 }, (_, i) => ({
      title: `${i + 1}`,
      accessor: `${i + 1}`,
      align: 'right',
    })),
  ]
}

const ercotTableSchema: Column[] = [
  {
    title: 'market',
    accessor: 'market',
    align: 'left',
  },
  {
    title: 'brand',
    accessor: 'brand',
    align: 'left',
  },
  {
    title: 'zone',
    accessor: 'zone',
    align: 'left',
  },
  {
    title: 'location',
    accessor: 'location',
    align: 'left',
  },
  {
    title: 'location name',
    accessor: 'locationName',
    align: 'left',
  },
  {
    title: 'submitted date',
    accessor: 'createdAt',
    align: 'left',
    minWidth: 225,
  },
  {
    title: 'date',
    accessor: 'date',
    align: 'left',
    minWidth: 125,
  },
  {
    title: 'Schedule Type',
    accessor: 'schedulingType',
    align: 'left',
    minWidth: 30,
  },
  {
    title: 'submitted by',
    accessor: 'submittedBy',
    align: 'left',
    minWidth: 225,
  },
  {
    title: 'submit status',
    accessor: 'submitStatus',
    align: 'left',
  },
  {
    title: 'bid id',
    accessor: 'bidId',
    align: 'left',
    minWidth: 150,
  },
  {
    title: 'MRID',
    accessor: 'mrid',
    align: 'left',
    minWidth: 200,
  },
  {
    title: 'message',
    accessor: 'message',
    align: 'left',
    minWidth: 200,
  },
  {
    title: 'hour end',
    accessor: 'interval',
    align: 'right',
  },
  {
    title: 'load',
    accessor: 'load',
    align: 'right',
  },
  {
    title: 'price',
    accessor: 'price',
    align: 'right',
  },
  {
    title: 'submit type',
    accessor: 'submitType',
    align: 'left',
  },
  {
    title: 'curve type',
    accessor: 'curveType',
    align: 'left',
  },
  {
    title: 'submission id',
    accessor: 'submissionId',
    align: 'left',
    minWidth: 200,
  },
]

export function getSubmissionHistoryTableSchemaByMarket(
  market: string,
  schedulingType: LsSchedulingType | undefined,
): Column[] {
  if (market === 'ERCOT' && schedulingType === 'DEMAND_SCHEDULING') {
    return ercotTableSchema
  }

  return defaultTableSchema
}

export function getLoadSchedulingSubmissionsSchema({
  sparkLineWidth,
}: {
  sparkLineWidth: number
}): Column[] {
  return [
    {
      title: 'Spark Line',
      accessor: 'spark',
      align: 'left',
      minWidth: sparkLineWidth,
    },
    {
      title: 'Market',
      accessor: 'market',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Brand',
      accessor: 'brand',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Zone',
      accessor: 'zone',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Location',
      accessor: 'location',
      align: 'left',
      sortable: true,
    },
    {
      title: 'Submitted Date',
      accessor: 'submittedDate',
      align: 'left',
      minWidth: 225,
      sortable: true,
    },
    {
      title: 'Start Flow Dates',
      accessor: 'startFlowDate',
      align: 'left',
    },
    {
      title: 'End Flow Dates',
      accessor: 'endFlowDate',
      align: 'left',
    },
    {
      title: 'Schedule Type',
      accessor: 'schedulingType',
      align: 'left',
      minWidth: 30,
      sortable: true,
    },
    {
      title: 'Status',
      accessor: 'latestSubmissionStatus',
      align: 'center',
    },
    {
      title: 'Group Id',
      accessor: 'submissionGroupId',
      align: 'left',
      minWidth: 200,
    },
    {
      title: 'Download',
      accessor: 'download',
      align: 'center',
    },
    {
      title: 'View More',
      accessor: 'viewMore',
      align: 'center',
    },
  ]
}

export function getLSSubmitSummaryTableSchema(): Column[] {
  return [
    {
      title: 'Market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'Brand',
      accessor: 'brand',
      align: 'left',
    },
    {
      title: 'Location',
      accessor: 'location',
      align: 'left',
    },
    {
      title: 'Flow Dates',
      accessor: 'dateRange',
      align: 'left',
    },
    {
      title: 'Status',
      accessor: 'status',
      align: 'left',
    },
  ]
}

export function getLSIbtSubmitSummaryTableSchema(): Column[] {
  return [
    {
      title: 'Market',
      accessor: 'market',
      align: 'left',
    },
    {
      title: 'Brand',
      accessor: 'brand',
      align: 'left',
    },
    {
      title: 'Zone',
      accessor: 'zone',
      align: 'left',
    },
    {
      title: 'Buyer',
      accessor: 'buyer',
      align: 'left',
    },
    {
      title: 'Seller',
      accessor: 'seller',
      align: 'left',
    },
    {
      title: 'Flow Dates',
      accessor: 'dateRange',
      align: 'left',
    },
    {
      title: 'Status',
      accessor: 'status',
      align: 'left',
    },
  ]
}
