import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWDropdown, {
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import IWTable, { Column } from 'shared/components/thunderbolt/IWTable'
import styled from 'styled-components'
import { ImportManagerSourceSchema, Mapping } from '../types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledRow = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;
  align-items: flex-end;
  margin-bottom: 1rem;
`
const StyledButton = styled(IWButton)`
  min-width: 6rem;
`

type Props = {
  mappings: Mapping[]
  source: ImportManagerSourceSchema[]
  options: GenericDropdownValue<number>[]
  handleOnMappingChange: (
    id: string,
    targetColumnName: string,
    sourceColumnValue: number,
  ) => void
  showError: boolean
}

const Row = ({
  id,
  column,
  source,
  options,
  onChange,
  hasError,
  errorText,
}: {
  id: string
  source: ImportManagerSourceSchema[]
  column: Mapping
  options: GenericDropdownValue<number>[]
  onChange: (
    id: string,
    targetColumnName: string,
    sourceColumnValue: number,
  ) => void
  hasError: boolean
  errorText: string | undefined
}) => {
  const { t } = useTranslation()
  const [previewColumns, setPreviewColumns] = useState<Column[]>([])
  const [showPreviewData, setShowPreviewData] = useState(false)
  const [previewData, setPreviewData] = useState<Record<string, string>[]>([])

  const handleShowPreview = (selectedColumn: Mapping) => {
    const data = source.find(
      (item) => item.columnIndex === selectedColumn.sourceColumn,
    )

    setPreviewData(
      data?.sampleData.map((v) => ({ value: v })).splice(0, 10) || [],
    )

    setPreviewColumns([
      { title: selectedColumn.targetColumnName, accessor: 'value' },
    ])

    setShowPreviewData((prev) => !prev)
  }
  return (
    <div key={id}>
      <StyledRow>
        <IWDropdown
          flexGrow
          placeholder={
            column.sourceColumn < 0
              ? 'Select a column'
              : source.find((s) => s.columnIndex === column.sourceColumn)
                  ?.columnName
          }
          label={column.targetColumnName}
          name={column.targetColumnName}
          options={options}
          onChange={(option: GenericDropdownValue<number>) => {
            if (showPreviewData) {
              handleShowPreview(column)
            }
            onChange('id', column.targetColumnName, option.value)
          }}
          hasError={hasError}
          errorText={errorText}
        />

        <StyledButton onClick={() => handleShowPreview(column)}>
          {showPreviewData ? t('button.close') : t('button.preview')}
        </StyledButton>
      </StyledRow>
      {showPreviewData && (
        <div style={{ marginBottom: '1rem' }}>
          <IWTable border data={previewData} columns={previewColumns} />
        </div>
      )}
    </div>
  )
}

const ImportDataMapper = ({
  mappings,
  source,
  options,
  handleOnMappingChange,
  showError,
}: Props) => {
  return (
    <Wrapper>
      <div style={{ flex: 1 }}>
        {mappings.map((column, index) => (
          <Row
            id={index.toString()}
            source={source}
            column={column}
            options={options}
            onChange={handleOnMappingChange}
            hasError={Boolean(column.errors) && showError}
            errorText={showError ? column?.errors?.message : undefined}
          />
        ))}
      </div>
    </Wrapper>
  )
}

export default ImportDataMapper
