import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFormContainer from 'shared/components/thunderbolt/IWFormContainer'
import IWProgressTracker from 'shared/components/thunderbolt/IWProgressTracker'
import styled from 'styled-components'
import ImportFileStep, { Props as ImportFileStepProps } from './ImportFileStep'
import DataMappingStep, {
  Props as DataMappingStepProps,
} from './DataMappingStep'
import SummaryStep from './SummaryStep'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-regular-svg-icons'
import { uploadImportedFile } from 'shared/integrationServiceClient'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { useNavigate } from 'react-router'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 8rem;
`

const FixedNavBar = styled.div`
  position: fixed;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette.primary[50]};
`

type ValueOf<T> = T[keyof T]
type WizardStep<T> = { isStepValid: boolean; value: ValueOf<T>; name: keyof T }
type WizardStepBuilder<T> = { [P in keyof T]: WizardStep<T[P]> }

enum Steps {
  ImportFile,
  DataMapping,
  Summary,
}

type UnknownSteps = [
  { importFile: undefined },
  { dataMapping: undefined },
  { summary: undefined },
]

type ImportManagerSteps = [
  { importFile: ImportFileStepProps['state'] },
  { dataMapping: DataMappingStepProps['state'] },
  { summary: undefined },
]

type AllSteps = ImportManagerSteps | UnknownSteps

const wizardSteps = (): WizardStepBuilder<AllSteps> => [
  {
    name: 'importFile',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'dataMapping',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'summary',
    isStepValid: false,
    value: undefined,
  },
]

const ImportManagerWizard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const wizardStateRef = useRef<WizardStepBuilder<AllSteps>>(wizardSteps())
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const getActiveStep = () => {
    switch (activeStepIndex) {
      case Steps.ImportFile:
        return (
          <ImportFileStep
            state={wizardStateRef.current[Steps.ImportFile].value}
            onChange={async (value, isStepValid) => {
              wizardStateRef.current[Steps.ImportFile] = {
                ...wizardStateRef.current[Steps.ImportFile],
                isStepValid,
                value,
              }
            }}
            showErrors={showErrors}
          />
        )
      case Steps.DataMapping:
        return (
          <DataMappingStep
            innowattsSchema={
              wizardStateRef.current[Steps.ImportFile].value
                ?.selectedInnowattsSchemaForComparison
            }
            sourceSchema={
              wizardStateRef.current[Steps.ImportFile].value
                ?.selectedFileSchemaForComparison
            }
            fileData={
              wizardStateRef.current[Steps.ImportFile].value?.selectedFileData
            }
            state={wizardStateRef.current[Steps.DataMapping].value}
            onChange={async (value, isStepValid) => {
              wizardStateRef.current[Steps.DataMapping] = {
                ...wizardStateRef.current[Steps.DataMapping],
                isStepValid: !isStepValid,
                value,
              }
            }}
            showErrors={showErrors}
          />
        )
      case Steps.Summary:
        return (
          <SummaryStep
            inputFileSchema={
              wizardStateRef.current[Steps.ImportFile].value
                ?.selectedFileSchemaForComparison || []
            }
            mapping={
              wizardStateRef.current[Steps.DataMapping].value?.mapping || []
            }
          />
        )
      default:
        return <></>
    }
  }

  const onBack = () => {
    if (activeStepIndex !== 0) {
      setActiveStepIndex((prev) => prev - 1)
    }
  }

  const onNext = async (currentStepIndex: number) => {
    if (
      !wizardStateRef.current[activeStepIndex].isStepValid &&
      activeStepIndex < currentStepIndex
    ) {
      setShowErrors(true)
      return
    }

    setShowErrors(false)
    setActiveStepIndex(currentStepIndex)
  }

  const handleFileSubmit = async () => {
    const [importFile, dataMapping] = wizardStateRef.current

    if (importFile.value?.selectedFile && dataMapping.value?.mapping) {
      try {
        setIsLoading(true)
        await uploadImportedFile(
          importFile.value?.selectedFile,
          {
            mappings: dataMapping.value?.mapping,
          },
          importFile.value.selectedInnowattsSchema.id,
        )
        toast.secondary(t('importManager.toasts.importFileSuccess'), {
          canDismiss: true,
        })
        navigate('/tools/insights-manager')
      } catch (e) {
        toast.alert(t('importManager.toasts.importFileFailure'), {
          canDismiss: true,
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      <StyledDiv id="form-import-manager-id">
        <IWProgressTracker
          steps={wizardStateRef.current.map((s) =>
            t(`importManager.${s.name}.labels.stepTitle`),
          )}
          currentActiveIndex={activeStepIndex}
          // onStepClick={(e, id) => handleStepChange(id)}
        />
        <IWFormContainer
          style={{
            marginTop: '1rem',
          }}
        >
          {getActiveStep()}
        </IWFormContainer>
      </StyledDiv>
      <FixedNavBar>
        <IWButton
          variant="outline"
          onClick={onBack}
          data-testid="import-manager-back"
          disabled={activeStepIndex === 0}
        >
          {t('button.back')}
        </IWButton>
        {activeStepIndex === Steps.Summary ? (
          <IWButton
            data-testid="import-manager-next"
            disabled={isLoading}
            onClick={() => handleFileSubmit()}
          >
            {isLoading ? <FontAwesomeIcon icon={faSync} /> : t('button.upload')}
          </IWButton>
        ) : (
          <IWButton
            data-testid="import-manager-next"
            disabled={activeStepIndex >= 3 && isLoading}
            onClick={() => onNext(activeStepIndex + 1)}
          >
            {t('button.next')}
          </IWButton>
        )}
      </FixedNavBar>
    </>
  )
}

export default ImportManagerWizard
