import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import useDataMapping from '../hooks/useImportManagerDataMapping'
import ImportDataMapper from './ImportDataMapper'
import {
  ImportManagerSchema,
  ImportManagerSourceSchema,
  Mapping,
} from '../types'
import { WizardStepProps } from 'shared/types'

type StepState = {
  mapping: Mapping[] | []
}

export type Props = WizardStepProps<StepState> & {
  innowattsSchema?: ImportManagerSchema
  sourceSchema?: ImportManagerSourceSchema[]
  fileData?: Record<string, string>[]
}
const DataMappingStep = ({
  state,
  onChange,
  showErrors,
  innowattsSchema,
  sourceSchema,
  fileData,
}: Props) => {
  const { t } = useTranslation()

  const { options, dataMappings, hasError, handleOnMappingChange } =
    useDataMapping({
      targetSchema: innowattsSchema,
      sourceSchema: sourceSchema,
      importedData: fileData,
    })

  useEffect(() => {
    onChange(
      {
        ...state,
        mapping: dataMappings,
      },
      hasError,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMappings, hasError, options])

  return (
    <IWFormSection
      sectionTitle={t('importManager.dataMapping.labels.sectionTitle')}
    >
      <ImportDataMapper
        mappings={dataMappings}
        source={sourceSchema || []}
        options={options}
        handleOnMappingChange={handleOnMappingChange}
        showError={showErrors}
      />
    </IWFormSection>
  )
}

export default DataMappingStep
