import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import {
  CreateSFTPCredential,
  createSFTPCredential,
  deleteSFTPCredential,
  editSFTPCredential,
  getSFTPCredentials,
  SFTPCredential,
  SFTPCredentialTestResult,
  testSFTPCredential,
  testStoredSFTPCredential,
} from 'systemPreferences/integrationsServiceClient'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { Column, SortDirection } from 'shared/components/thunderbolt/IWTable'
import { paginationOptions } from 'shared/types'
import SFTPTable from './SFTPTable'
import SFTPCreateModal from './SFTPCreateModal'
import SFTPEditModal from './SFTPEditModal'
import SFTPDeleteModal from './SFTPDeleteModal'
import IntegrationsSection from '../../pages/IntegrationsSection'
import styled from 'styled-components'

type CreateCredential =
  | {
      name?: string
      description?: string
      host?: string
      port?: string
      path?: string
      username?: string
      password?: string
    }
  | undefined

function isCreateSFTPCredential(
  obj: CreateCredential,
): obj is CreateSFTPCredential {
  if (!obj) {
    return false
  }
  if (obj.name && obj.host && obj.port && obj.username && obj.password) {
    return true
  }
  return false
}

const TableWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.375rem;
  padding: 1rem;
`

const SFTPSection = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newCredential, setNewCredential] =
    useState<CreateCredential>(undefined)
  const [submitStatus, setSubmitStatus] = useState<
    'waiting' | 'submitting' | 'complete'
  >('waiting')
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirection
  }>({
    column: 'created_at',
    direction: 'descending',
  })
  const [page, setPage] = useState({
    current: 1,
    size: paginationOptions[0].value,
  })
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedCredential, setSelectedCredential] = useState<
    SFTPCredential | undefined
  >()

  const handleModalCloseWithCleanUp = () => {
    setNewCredential(undefined)
    setSelectedCredential(undefined)
    setIsModalOpen(false)
    setIsEditModalOpen(false)
    setIsDeleteModalOpen(false)
    setSubmitStatus('waiting')
  }

  const handleAddClick = () => {
    setIsModalOpen(true)
  }

  const handleEdit = (e: SyntheticEvent, credential: SFTPCredential) => {
    setIsEditModalOpen(true)
    setSelectedCredential(JSON.parse(JSON.stringify(credential)))
  }

  const handleNewCredentialChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue, name: property } = e.target
    setNewCredential({
      ...newCredential,
      [property]: newValue || null,
    })
  }

  const handleSelectedCredentialChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue, name: property } = e.target
    setSelectedCredential((prev) => {
      if (prev) {
        return {
          ...prev,
          [property]: newValue || null,
        }
      }
      return undefined
    })
  }

  const handleDeleteTableButton = (
    e: SyntheticEvent,
    credential: SFTPCredential,
  ) => {
    setIsDeleteModalOpen(true)
    setSelectedCredential(JSON.parse(JSON.stringify(credential)))
  }

  const columns: Column[] = [
    {
      title: t('systemPreferences.integrations.sftp.fields.actions'),
      accessor: 'actions',
      targetPercentageWidth: 10,
    },
    {
      title: t('systemPreferences.integrations.sftp.fields.name'),
      accessor: 'name',
      dbColumn: 'name',
      sortable: true,
    },
    {
      title: t('systemPreferences.integrations.sftp.fields.issueDate'),
      accessor: 'createdAt',
      dbColumn: 'created_at',
      sortable: true,
    },
    {
      title: t('systemPreferences.integrations.sftp.fields.host'),
      accessor: 'host',
      dbColumn: 'host',
      sortable: true,
    },
    {
      title: t('systemPreferences.integrations.sftp.fields.path'),
      accessor: 'path',
      dbColumn: 'path',
    },
    {
      title: t('systemPreferences.integrations.sftp.fields.username'),
      accessor: 'username',
      dbColumn: 'username',
    },
  ]

  const {
    data: credentials,
    refetch,
    isLoading: isLoadingCredentials,
  } = useQuery(
    ['credentials', 'pageNumber', page, 'pageSize', page.size, 'sort', sort],
    () => {
      return getSFTPCredentials(page.current - 1, page.size, {
        direction: sort.direction,
        column:
          columns.find((c) => c.accessor === sort.column)?.dbColumn ||
          'created_at',
      })
    },
    {
      keepPreviousData: true,
    },
  )

  const handleTestCredentialsResult = async (
    cb: () => Promise<SFTPCredentialTestResult>,
  ) => {
    try {
      setSubmitStatus('submitting')
      const testResult = await cb()
      if (!testResult.isAuthenticated) {
        toast.alert(t('systemPreferences.integrations.sftp.connectionFailed'), {
          canDismiss: true,
        })
        return false
      }
      if (testResult.isAuthenticated && !testResult.pathExists) {
        toast.alert(t('systemPreferences.integrations.sftp.pathNotFound'), {
          canDismiss: true,
        })
        return false
      }
      if (testResult.isAuthenticated && testResult.pathExists) {
        toast.secondary(
          t('systemPreferences.integrations.sftp.connectionSuccessful'),
          { canDismiss: true },
        )
        return true
      }
    } catch (error) {
      toast.alert(t('systemPreferences.integrations.sftp.connectionError'), {
        canDismiss: true,
      })
    } finally {
      setSubmitStatus('waiting')
    }
  }

  const handleTestStoredCredential = (e: SyntheticEvent, credentialId) => {
    handleTestCredentialsResult(() => {
      return testStoredSFTPCredential(credentialId)
    })
  }

  const handleTestNewCredential = (
    e: SyntheticEvent,
    credential: SFTPCredential | CreateCredential,
  ) => {
    if (!credential) {
      return
    }
    if (isCreateSFTPCredential(credential)) {
      return handleTestCredentialsResult(() => {
        return testSFTPCredential({
          host: credential.host,
          port: credential.port,
          path: credential.path,
          username: credential.username,
          password: credential.password,
        })
      })
    }
  }

  const handleCreateCredential = async (e: SyntheticEvent) => {
    if (newCredential) {
      const success = await handleTestNewCredential(e, newCredential)
      if (!success) {
        return
      }
      setSubmitStatus('submitting')
      try {
        if (isCreateSFTPCredential(newCredential)) {
          await createSFTPCredential(newCredential)
          setSubmitStatus('complete')
          handleModalCloseWithCleanUp()
          await refetch()
        }
      } catch (error) {
        toast.alert(
          t('systemPreferences.integrations.sftp.newCredentialsFailure'),
          { canDismiss: true },
        )
      } finally {
        setSubmitStatus('waiting')
      }
    }
  }

  const handleEditCredential = async (
    e: SyntheticEvent,
    credential: SFTPCredential,
  ) => {
    try {
      const success = await handleTestNewCredential(e, credential)
      if (!success) {
        return
      }
      setSubmitStatus('submitting')
      await editSFTPCredential(credential)
      await refetch()
      toast.secondary(
        t('systemPreferences.integrations.sftp.credentialHasBeenSaved'),
        { canDismiss: true },
      )
      handleModalCloseWithCleanUp()
    } catch (error) {
      toast.alert(
        t('systemPreferences.integrations.sftp.editCredentialsFailure'),
        { canDismiss: true },
      )
    } finally {
      setSubmitStatus('waiting')
    }
  }

  const handleCredentialDelete = async (
    e: SyntheticEvent,
    credentialId: string,
  ) => {
    if (selectedCredential === undefined) {
      return
    }
    try {
      setSubmitStatus('submitting')
      await deleteSFTPCredential({ credentialId })
      toast.secondary(
        t('systemPreferences.integrations.sftp.credentialHasBeenDeleted', {
          name: selectedCredential.name,
        }),
        { canDismiss: true },
      )
      await refetch()
      handleModalCloseWithCleanUp()
    } catch (error) {
      toast.alert(
        t('systemPreferences.integrations.sftp.deleteCredentialsFailure'),
        { canDismiss: true },
      )
    } finally {
      setSubmitStatus('waiting')
    }
  }

  const handleSort = (column: string, direction: SortDirection) => {
    setSort({
      column: column,
      direction: direction || 'descending',
    })
  }

  return (
    <>
      <IntegrationsSection
        header={t('systemPreferences.integrations.sftp.header')}
        buttonLabel={t(
          'systemPreferences.integrations.sftp.addNewCredentialButton',
        )}
        onButtonClick={handleAddClick}
      >
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t('systemPreferences.integrations.sftp.description')}
        </IWTypography>
        <TableWrapper>
          <SFTPTable
            onSort={handleSort}
            sort={sort ? { [sort.column]: sort.direction } : undefined}
            columns={columns}
            data={credentials}
            page={page}
            onChangePage={(newPage) =>
              setPage((prev) => ({ ...prev, current: newPage }))
            }
            onChangeItemsPerPage={(newSize) =>
              setPage({ current: 1, size: newSize })
            }
            isLoading={isLoadingCredentials}
            onEdit={handleEdit}
            onDelete={handleDeleteTableButton}
            onTest={handleTestStoredCredential}
          />
        </TableWrapper>
      </IntegrationsSection>
      {isModalOpen && (
        <SFTPCreateModal
          submitStatus={submitStatus}
          credential={newCredential}
          onChange={handleNewCredentialChange}
          onModalCancel={handleModalCloseWithCleanUp}
          onModalCreate={handleCreateCredential}
          onTest={(e) => handleTestNewCredential(e, newCredential)}
        />
      )}
      {selectedCredential && (
        <>
          {isEditModalOpen && (
            <SFTPEditModal
              submitStatus={submitStatus}
              credential={selectedCredential}
              onChange={handleSelectedCredentialChange}
              onCancel={handleModalCloseWithCleanUp}
              onSave={handleEditCredential}
              onTest={(e) => handleTestNewCredential(e, selectedCredential)}
            />
          )}
          {isDeleteModalOpen && (
            <SFTPDeleteModal
              credentialId={selectedCredential.credentialId}
              name={selectedCredential.name}
              isCancelButtonDisabled={submitStatus === 'submitting'}
              isDeleteButtonDisabled={submitStatus === 'submitting'}
              onCancel={handleModalCloseWithCleanUp}
              onDelete={handleCredentialDelete}
            />
          )}
        </>
      )}
    </>
  )
}

export default SFTPSection
