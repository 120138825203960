import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import NotificationList from 'shared/components/thunderbolt/IWNotificationList'
import NotificationListItem from 'shared/components/thunderbolt/IWNotificationListItem'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { Notification } from 'tools/insightsManager/types'
import {
  getNotifications,
  getUnreadNotifications,
  markAllAsRead,
  toggleNotificationIsRead,
} from 'shared/userServiceClient'
import { queryClient } from 'shared/apiClient'
import useDebouncer from 'shared/hooks/useDebouncer'
import IWBackdrop from 'shared/components/thunderbolt/IWBackdrop'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import IWLoading, {
  IWSmallLoading,
} from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import LayoutContext from 'shared/contexts/LayoutContext'

const StyleMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const StyledNotificationList = styled(NotificationList)`
  height: calc(100vh - 6.75rem); // compensate for header height plus paddings
  overflow-y: auto;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const StyledDivBtns = styled.div`
  display: flex;
  gap: 1rem;
`

const RightBar = styled.aside`
  position: fixed;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.grey[0]};
  padding: 1.5rem;
  top: 0;
  right: 0;
  z-index: ${(props) => props.theme.layers.overlayout};
  transition: width 250ms ease-in-out;
  width: 550px;
`

const StyledLoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`

/**
 * This wrapper gives us the specific notification item based on notification type and source
 */
function NotificationListItemWrapper({
  notification,
  onToggle,
  onClose,
}: {
  notification: Notification
  onToggle: (id: string) => void
  onClose: Props['onClose']
}) {
  const { notificationId, isRead, title, subtitle, icon, link, timestamp } =
    notification
  const { timezone } = useContext(LayoutContext)
  return (
    <NotificationListItem
      as="routerLink"
      to={link}
      onClick={onClose}
      title={title}
      subtitle={subtitle}
      leadingIcon={icon}
      age={DateTime.fromMillis(timestamp, {
        zone: timezone,
      }).toISO()}
      isRead={isRead}
      onToggle={() => onToggle(notificationId)}
    />
  )
}

interface Props {
  onClose: (e: SyntheticEvent) => void
}

const Notifications = ({ onClose }: Props) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [shouldDisplayPageLoad, setShouldDisplayPageLoad] = useState(false)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const { debounce } = useDebouncer()

  useEffect(() => {
    return () => {
      queryClient.removeQueries('notifications')
    }
  }, [])

  const { data, refetch, fetchNextPage, isFetchingNextPage, isLoading, error } =
    useInfiniteQuery('notifications', getNotifications, {
      getNextPageParam: (lastPage, pages) => {
        // Since we don't have a cursor we use the number of pages to get the next one
        return pages.length
      },
    })

  useEffect(() => {
    // Move scroll to the bottom to ensure loading comes into view
    if (shouldDisplayPageLoad && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [shouldDisplayPageLoad])

  // Scroll to bottom to show loading
  useEffect(() => {
    if (!isFetchingNextPage) {
      setShouldDisplayPageLoad(false)
    }
  }, [isFetchingNextPage])

  useEffect(() => {
    const newNotifications = data?.pages.flat()
    if (newNotifications) {
      setNotifications(newNotifications)
    }
  }, [data])

  const { refetch: refetchCount } = useQuery(
    'unreadNotificationCount',
    getUnreadNotifications,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const updateFromServer = (id?: string) => {
    debounce(() => {
      /**
       * we debounce the recount to ensure we don't have a race condition and
       * we always have the correct and latest unread count
       */
      refetchCount()
      if (id) {
        /**
         * We update the specific page to ensure
         * if user scrolls up we don't set the old cache
         */
        refetch({
          refetchPage: (page: Notification[]) =>
            Boolean(page.find((elem) => elem.notificationId === id)),
        })
      } else {
        /**
         * We update the entire cache to ensure if
         * if user scrolls up we don't set the old cache
         */
        refetch()
      }
    }, 1000)
  }

  const handleNotificationToggle = (id: string) => {
    toggleNotificationIsRead(id)

    updateFromServer(id)
    setNotifications((prev) => {
      return prev.map((oldNotification) => {
        if (oldNotification.notificationId === id) {
          return { ...oldNotification, isRead: !oldNotification.isRead }
        }
        return oldNotification
      })
    })
  }

  const handleMarkAllAsRead = () => {
    markAllAsRead()
    updateFromServer()
    setNotifications((prev) => {
      return prev.map((oldNotification) => {
        return { ...oldNotification, isRead: true }
      })
    })
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
    const isAtBottomScroll = scrollHeight - scrollTop === clientHeight
    const lastPageLength = data?.pages[data.pages.length - 1].length
    if (lastPageLength === 0) {
      return
    }
    if (isAtBottomScroll) {
      setShouldDisplayPageLoad(true)
      debounce(() => {
        fetchNextPage()
      }, 500)
    }
  }

  const notificationContent = () => {
    if (error) {
      return <IWError />
    }
    if (!isLoading && notifications.length === 0) {
      return <IWNoResultsMessage />
    }
    if (isLoading) {
      return <IWLoading />
    }
    return notifications.map((notification) => (
      <NotificationListItemWrapper
        key={notification.notificationId}
        notification={notification}
        onToggle={handleNotificationToggle}
        onClose={(e) => {
          // When clicking on the notification, we want to mark as read before closing
          if (!notification.isRead) {
            handleNotificationToggle(notification.notificationId)
          }
          onClose(e)
        }}
      />
    ))
  }

  return (
    <IWBackdrop onClick={onClose}>
      <RightBar onClick={(e) => e.stopPropagation()}>
        <StyleMainDiv data-testid="notifications-panel">
          <StyledDiv>
            <IWTypography weight="bold">
              {t('notifications.notifications')}
            </IWTypography>
            <StyledDivBtns>
              <IWButton
                data-testid="mark-as-read-button"
                variant="anchorMain"
                onClick={handleMarkAllAsRead}
              >
                {t('notifications.markAllAsRead')}
              </IWButton>
              <IWButton
                data-testid="close-notifications-panel"
                variant="alternative"
                onClick={onClose}
              >
                {t('button.close')}
              </IWButton>
            </StyledDivBtns>
          </StyledDiv>
          <StyledNotificationList onScroll={handleScroll} ref={scrollRef}>
            {notificationContent()}
            {shouldDisplayPageLoad && (
              <StyledLoadingDiv>
                <IWSmallLoading />
              </StyledLoadingDiv>
            )}
          </StyledNotificationList>
        </StyleMainDiv>
      </RightBar>
    </IWBackdrop>
  )
}

export default Notifications
