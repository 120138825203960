import React from 'react'
import styled from 'styled-components'
import IWTypography from './IWTypography'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

interface IWHelperProps {
  label: string
}

/** Helper text below form elements */
export const IWHelper = ({ label }: IWHelperProps) => {
  return (
    <IWTypography
      weight="medium"
      size="sm"
      fontHue={{ color: 'grey', value: 400 }}
    >
      {label}
    </IWTypography>
  )
}

/** Error text for form elements */
export const IWError = ({ label }: IWHelperProps) => {
  return (
    <IWTypography
      weight="medium"
      size="sm"
      fontHue={{ color: 'alert', value: 600 }}
    >
      {label}
    </IWTypography>
  )
}

interface IWLabelProps {
  label?: string
  id?: string
  required?: boolean
}

interface IWInputInfoProps {
  helperText?: string
  errorText?: string
}

/**
 * Simple label which can be used to add general label to inputs and
 * other similar components
 */
const IWInputLabel = ({ required, label, id }: IWLabelProps) => {
  return label ? (
    <IWTypography
      as="label"
      weight="medium"
      size="sm"
      fontHue={{ color: 'grey', value: 700 }}
      htmlFor={id}
    >
      {label} {required && '*'}
    </IWTypography>
  ) : null
}

/**
 * Simple Info section which can be used to add general helper info to inputs and
 * other similar components
 */
export const IWInputInfo = ({ helperText, errorText }: IWInputInfoProps) => {
  return helperText || errorText ? (
    <StyledDiv>
      {helperText && <IWHelper label={helperText} />}
      {errorText && <IWError label={errorText} />}
    </StyledDiv>
  ) : null
}

export default IWInputLabel
