import React, { useEffect, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWDropdown, {
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { IWInputPlaceholder } from 'shared/components/thunderbolt/IWTextInput'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { AggregationFn, TimeGroup } from '../types'
import { getAlertTimeSeriesLabel, mapToDropdownOptions } from '../utils/utils'

const StyledSection = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    flex: 1;
  }
`

const StyledPopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

interface Props {
  selectedTimeSeriesGroupBy?: GenericDropdownValue<TimeGroup>
  selectedTimeSeriesAggregation?: GenericDropdownValue<AggregationFn>
  onConfirm: (
    selectedTimeSeriesGroupBy: GenericDropdownValue<TimeGroup>,
    selectedTimeSeriesAggregation: GenericDropdownValue<AggregationFn>,
  ) => void
  showErrors?: boolean
}

export default function TimeSeriesSummary({
  selectedTimeSeriesGroupBy,
  selectedTimeSeriesAggregation,
  onConfirm,
  showErrors,
}: Props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const groupByOptions = mapToDropdownOptions<TimeGroup>(['hourAll', 'dayAll'])
  const aggregationOptions = mapToDropdownOptions<AggregationFn>([
    'sum',
    'min',
    'max',
  ])
  const [selectedGroupByOption, setSelectedGroupByOption] = useState<
    GenericDropdownValue<TimeGroup> | undefined
  >(selectedTimeSeriesGroupBy)
  const [selectedAggregationOption, setSelectedAggregationOption] = useState<
    GenericDropdownValue<AggregationFn> | undefined
  >(selectedTimeSeriesAggregation)
  const [isTimeSeriesPopupOpen, setIsTimeSeriesPopupOpen] = useState(false)
  const [timeSeriesSummary, setTimeSeriesSummary] = useState(
    getAlertTimeSeriesLabel(
      selectedGroupByOption?.value,
      selectedAggregationOption?.value,
      t,
    ),
  )

  const handleTimeSeriesClose = () => {
    setIsTimeSeriesPopupOpen(false)
    setSelectedGroupByOption(selectedTimeSeriesGroupBy)
    setSelectedAggregationOption(selectedTimeSeriesAggregation)
  }

  const handleTimeSeriesConfirm = () => {
    if (selectedGroupByOption && selectedAggregationOption) {
      setIsTimeSeriesPopupOpen(false)
      onConfirm(selectedGroupByOption, selectedAggregationOption)
    }
  }

  useEffect(() => {
    if (selectedTimeSeriesGroupBy && selectedTimeSeriesAggregation) {
      const summary = getAlertTimeSeriesLabel(
        selectedGroupByOption?.value,
        selectedAggregationOption?.value,
        t,
      )
      setTimeSeriesSummary(summary)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTimeSeriesGroupBy,
    selectedTimeSeriesAggregation,
    selectedGroupByOption?.label,
    selectedAggregationOption?.label,
  ])

  return (
    <Popover
      padding={4}
      reposition
      containerStyle={{ zIndex: theme.layers.popover }}
      positions={['bottom']}
      isOpen={isTimeSeriesPopupOpen}
      onClickOutside={() => setIsTimeSeriesPopupOpen(false)}
      content={({ childRect }) => (
        <StyledPopupDiv
          style={{
            width: `${childRect.width}px`,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <StyledSection>
            <IWDropdown
              required
              options={groupByOptions}
              getOptionLabel={(option) => {
                const { value } = option as GenericDropdownValue<TimeGroup>
                return t(`alertsManager.createAlert.triggerStep.${value}`)
              }}
              value={selectedGroupByOption}
              label={t(`alertsManager.createAlert.triggerStep.groupBy`)}
              onChange={(val) => setSelectedGroupByOption(val)}
              hasError={showErrors && !selectedGroupByOption}
              errorText={
                showErrors && !selectedGroupByOption
                  ? t(`alertsManager.errors.enterAllRequired`)
                  : undefined
              }
            />
            <IWDropdown
              required
              options={aggregationOptions}
              getOptionLabel={(option) => {
                const { value } = option as GenericDropdownValue<AggregationFn>
                return t(`alertsManager.createAlert.triggerStep.${value}`)
              }}
              value={selectedAggregationOption}
              label={t(`alertsManager.createAlert.triggerStep.aggregation`)}
              onChange={(val) => setSelectedAggregationOption(val)}
              hasError={showErrors && !selectedAggregationOption}
              errorText={
                showErrors && !selectedAggregationOption
                  ? t(`alertsManager.errors.enterAllRequired`)
                  : undefined
              }
            />
          </StyledSection>
          <StyledButtonDiv>
            <IWButton
              data-testid="create-alert-time-series-cancel"
              variant="alternative"
              onClick={handleTimeSeriesClose}
            >
              {t(`button.cancel`)}
            </IWButton>
            <IWButton
              data-testid="create-alert-time-series-confirm"
              onClick={handleTimeSeriesConfirm}
            >
              {t(`button.confirm`)}
            </IWButton>
          </StyledButtonDiv>
        </StyledPopupDiv>
      )}
    >
      <IWInputPlaceholder
        required
        data-testid="create-alert-time-series-selector-placeholder"
        placeholder={t('placeholders.select')}
        label={t(`alertsManager.createAlert.triggerStep.timeSeriesTitle`)}
        onClick={() => setIsTimeSeriesPopupOpen(true)}
        helperText={t(
          `alertsManager.createAlert.triggerStep.timeSeriesHelpDesc`,
        )}
        hasError={showErrors && !timeSeriesSummary}
        errorText={
          showErrors && !timeSeriesSummary
            ? t('alertsManager.errors.enterAllRequired')
            : undefined
        }
      >
        {timeSeriesSummary && (
          <IWTypography size="sm" weight="regular">
            {timeSeriesSummary}
          </IWTypography>
        )}
      </IWInputPlaceholder>
    </Popover>
  )
}
