import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import { DateTime, Interval } from 'luxon'
import {
  faArrowLeft,
  faArrowsRotate,
  faCalendar,
  faCalendarDays,
  faChevronDown,
  faFlask,
  faGaugeMax,
  faLightbulbOn,
  faProjectDiagram,
  faShareNodes,
  faShop,
  faTrash,
  faUserFriends,
} from '@fortawesome/pro-regular-svg-icons'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import useDurationHumanizer from 'shared/hooks/useDurationHumanizer'
import IWTab from 'shared/components/thunderbolt/IWTab'
import IWTabs from 'shared/components/thunderbolt/IWTabs'
import { useQuery } from 'react-query'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deleteInnoPod, getPod, getPodDatacube } from 'shared/podServiceClient'
import NotFound from 'root/pages/NotFound'
import { useNavigate } from 'react-router-dom'
import { convertToCVS, downloadContent, goToURL } from 'shared/helpers'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import UserContext from 'shared/contexts/UserContext'
import useGetOrgUsers from 'shared/hooks/useGetOrgUsers'
import { Hue } from '../../../@types/styled'
import ConfirmChangesModal from '../components/ConfirmChangesModal'
import PodDetailsSummary from '../components/PodDetailsSummary'
import { SharingRole } from '../types'
import RawDataTable from '../components/RawDataTable'
import ShareWithModal from '../components/ShareWithModal'
import PodDetailsIntegrations from '../components/PodDetailsIntegrations'
import LayoutContext from 'shared/contexts/LayoutContext'
import PodVisualisations from '../components/PodVisualisation'

const StyledWrapper = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`

const StyledIWButton = styled(IWButton)`
  border: none;
`

const StatusBadgeWrapper = styled.div`
  align-self: center;
`

const LeftHand = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1;
`

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin-right: 0.5rem;
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
`

const ContextDivider = styled('hr')`
  background-color: ${(props) => props.theme.palette.grey[300]};
  margin: 0 0 0.5rem 0;
  height: 1px;
  border: none;
`

/**
 * Component to display the details of a Pod
 */
const PodDetailsPage = () => {
  const { t, i18n } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const { userId, organization } = useContext(UserContext)
  const navigate = useNavigate()
  const { podId = '' } = useParams()
  const toast = useToast()

  const {
    data: pod,
    refetch,
    error,
    isLoading: initializing,
  } = useQuery(`pod:${podId}`, () => getPod(podId))

  const isPodOwner = pod?.userId === userId
  const canSharePod =
    isPodOwner ||
    (pod?.myRole && (pod.myRole as SharingRole) >= SharingRole.EDIT)
  const [showShareModal, setShowShareModal] = useState<boolean>(false)
  const modalPromiseRef = useRef<{ resolve: () => void; reject: () => void }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false)

  const { data: availableUsers, isLoading: usersInitializing } =
    useGetOrgUsers()

  const [sinceLastRun, setSinceLastRun] = useState<string>()
  const activeLanguage = i18n.language
  const { humanizer } = useDurationHumanizer({ language: activeLanguage })

  const [activeTab, setActiveTab] = useState<number>(0)

  useEffect(() => {
    if (pod?.lastRun) {
      const duration = Interval.fromDateTimes(
        DateTime.fromISO(pod.lastRun, {
          zone: timezone,
        }),
        DateTime.now(),
      )
        .toDuration()
        .toMillis()
      // Calculate the localised age of the pod
      setSinceLastRun(humanizer(duration))
    }
  }, [activeLanguage, humanizer, pod?.lastRun, timezone])

  function buildIntervalLabel({
    interval,
    nextRun,
  }: {
    interval?: string
    nextRun?: string
  }) {
    if (!interval) {
      return t('insightManager.pods.interval.never')
    }
    if (!nextRun) {
      return t('insightManager.pods.interval.default')
    }
    return t('insightManager.pods.interval.nextRun', {
      value: DateTime.fromISO(nextRun, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT),
    })
  }

  const renderStatusBadge = (status): ReactElement | undefined => {
    let hue: Hue = { color: 'primary', value: 700 }
    switch (status) {
      case 'initializing':
        hue = { color: 'grey', value: 500 }
        break
      case 'ready':
        hue = { color: 'secondary', value: 900 }
        break
      case 'running':
        hue = { color: 'warning', value: 900 }
        break
      case 'failed':
        hue = { color: 'alert', value: 800 }
        break
      default:
        return undefined
    }
    return (
      <IWGeneralBadge
        data-testid="pod-status-badge"
        label={t(`insightManager.pods.status.badge.${status}`)}
        fontHue={{ color: 'grey', value: 0 }}
        hue={hue}
      />
    )
  }

  const handleGetPodDataCube = async (userPodId, userPodName) => {
    try {
      setIsLoading(true)
      const url = await getPodDatacube(userPodId)
      goToURL(url)
    } catch (e) {
      toast.alert(t('insightManager.pods.dataCubeError', { name: userPodName }))
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeletePod = async (userPodId, userPodName) => {
    try {
      setIsLoading(true)
      setShowDeleteConfirmModal(false)
      await deleteInnoPod(userPodId)
      toast.secondary(
        t('insightManager.pods.deleteSuccess', { name: userPodName }),
      )
      navigate(-1)
    } catch (e) {
      toast.alert(t('insightManager.pods.deleteError', { name: userPodName }))
    } finally {
      setIsLoading(false)
    }
  }

  const handleConfirmDeletePod = (userPodId, userPodName) => {
    setShowDeleteConfirmModal(true)
    modalPromiseRef.current = {
      resolve: async () => {
        await handleDeletePod(userPodId, userPodName)
        setShowDeleteConfirmModal(false)
      },
      reject: () => {
        setShowDeleteConfirmModal(false)
      },
    }
  }

  const renderContextMenu = ({
    userPodId,
    userPodName,
    hasBeenSuccessful,
    isLoadSchedulingPod,
  }: {
    userPodId?: string
    userPodName?: string
    hasBeenSuccessful?: boolean
    isLoadSchedulingPod?: boolean
  }) => {
    const contextMenuHeader = [
      <IWContextMenuItem
        key="explore"
        data-testid="pod-actions-explore"
        disabled={!hasBeenSuccessful}
        label={t('insightManager.pods.actions.explore')}
        icon={faFlask}
        onClick={() => handleGetPodDataCube(userPodId, userPodName)}
      />,
    ]

    if (canSharePod) {
      contextMenuHeader.push(
        <IWContextMenuItem
          key="share"
          data-testid="pod-actions-share"
          icon={faShareNodes}
          onClick={() => setShowShareModal(true)}
          label={t('insightManager.pods.actions.share')}
        />,
      )
    }

    if (isLoadSchedulingPod) {
      contextMenuHeader.push(
        <ContextDivider />,
        <IWContextMenuItem
          as="routerLink"
          key="load-scheduling-link"
          icon={faCalendarDays}
          data-testid="pod-actions-load-scheduling-link"
          label={t('insightManager.pods.actions.loadScheduling')}
          to={`/tools/load-scheduling/submit?podId=${userPodId}`}
        />,
      )
    }

    return contextMenuHeader
  }

  const renderSharedWith = () => {
    const sharedOrgsOtherThanUserOrg = pod?.sharedWithOrgRole?.filter(
      ({ orgId }) => orgId !== organization,
    )
    if (pod?.sharedWithOrgRole) {
      return (
        <IWBadgeGroup>
          <IWCardBadge
            label={`${t('insightManager.pods.sharing.sharedWithMyOrganization')}
            ${
              sharedOrgsOtherThanUserOrg &&
              sharedOrgsOtherThanUserOrg.length > 0
                ? ` +${sharedOrgsOtherThanUserOrg.length} Org`
                : ''
            }
            ${
              pod.sharedWithUsers && pod.sharedWithUsers.length > 0
                ? ` +${pod.sharedWithUsers.length}`
                : ''
            }`}
            icon={faUserFriends}
          />
        </IWBadgeGroup>
      )
    }
    if (
      !pod?.sharedWithOrgRole &&
      pod?.sharedWithUsers &&
      pod?.sharedWithUsers.length > 0
    ) {
      return (
        <IWBadgeGroup>
          <IWCardBadge
            label={`${t('insightManager.pods.sharing.sharedWith', {
              user:
                availableUsers?.obj[pod.sharedWithUsers[0].userId]?.name ||
                t('insightManager.pods.sharing.unknownUser'),
            })}${
              pod.sharedWithUsers.length > 2
                ? ` +${pod.sharedWithUsers.length - 1}`
                : ''
            }`}
            icon={faUserFriends}
          />
        </IWBadgeGroup>
      )
    }

    return ''
  }

  const renderButtons = () => {
    const buttons: ReactElement[] = []
    if (isPodOwner) {
      buttons.push(
        <IWButton
          icon={faTrash}
          iconPosition="leading"
          variant="outline"
          data-testid="pod-actions-delete"
          onClick={() =>
            handleConfirmDeletePod(pod?.userPodId, pod?.userPodName)
          }
        >
          {t('insightManager.pods.actions.delete')}
        </IWButton>,
      )
    }

    buttons.push(
      <IWContextMenu
        data-testid="pod-actions-tools"
        menu={renderContextMenu({
          userPodId: pod?.userPodId,
          userPodName: pod?.userPodName,
          hasBeenSuccessful: pod?.hasBeenSuccessful,
          isLoadSchedulingPod: pod?.useForLoadScheduling,
        })}
        positions={['bottom']}
      >
        <StyledIWButton
          icon={faChevronDown}
          iconPosition="trailing"
          variant="outline"
        >
          {t('insightManager.pods.actions.tools')}
        </StyledIWButton>
      </IWContextMenu>,
    )
    return buttons
  }

  function renderOrgLabel(
    sharedOrgList: Array<{ orgId: string; role: number }>,
  ) {
    const isCurrentUserOrgPresentInSharedList = sharedOrgList?.find(
      ({ orgId }) => orgId === organization,
    )

    const isSharedByOrgShared = sharedOrgList?.find(
      ({ orgId }) => orgId === 'org_shared' || orgId === 'org_nonprod',
    )

    return isCurrentUserOrgPresentInSharedList && isSharedByOrgShared
      ? t('insightManager.pods.sharing.innowatts')
      : t('insightManager.pods.sharing.myOrganization')
  }

  return (
    <>
      {(initializing || usersInitializing || isLoading) && <IWLoading />}
      {error && <IWError />}
      {!(initializing || usersInitializing || isLoading) && pod && (
        <>
          <IWPageHeader data-testid="page-header">
            <StyledWrapper>
              <LeftHand>
                <StyledButton
                  onClick={() => navigate('/tools/insights-manager')}
                  data-testid="page-header-back-button"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </StyledButton>
                <StyledTitle>{pod.userPodName}</StyledTitle>
                <StatusBadgeWrapper>
                  {pod.currentStatus && renderStatusBadge(pod.currentStatus)}
                </StatusBadgeWrapper>
              </LeftHand>
              <IWButtonGroup color="primary">{renderButtons()}</IWButtonGroup>
            </StyledWrapper>
          </IWPageHeader>
          <main>
            <ConfirmChangesModal
              title={t(`insightManager.pods.confirmDeleteLabel`)}
              body={t(`insightManager.pods.confirmDeleteDescription`)}
              isOpen={showDeleteConfirmModal}
              handleClose={modalPromiseRef.current?.reject}
              handleConfirm={modalPromiseRef.current?.resolve}
            />
            <IWBadgeGroup>
              {Boolean(pod.markets.length > 0) ? (
                <IWCardBadge
                  label={pod.markets.join(', ').toLocaleUpperCase()}
                  icon={faShop}
                />
              ) : (
                <></>
              )}
              <IWCardBadge
                label={t(`insights.${pod.podType.insightId}.label`)}
                icon={faLightbulbOn}
              />
              <IWCardBadge
                label={t(
                  `insights.${pod.podType.insightId}.metrics.${pod.podType.metricId}.label`,
                )}
                icon={faGaugeMax}
              />
              {Boolean(pod.useForLoadScheduling) ? (
                <IWCardBadge
                  label={t(`insights.loadScheduling.label`)}
                  icon={faCalendarDays}
                />
              ) : (
                <></>
              )}
              <IWCardBadge
                label={buildIntervalLabel({
                  interval: pod.interval,
                  nextRun: pod.nextRun,
                })}
                icon={faArrowsRotate}
              />
            </IWBadgeGroup>

            <IWBadgeGroup testId="pod-date-strings">
              <IWCardBadge
                label={t('insightManager.pods.createdOn', {
                  value: DateTime.fromISO(pod.createdAt, {
                    zone: timezone,
                  }).toLocaleString(DateTime.DATE_SHORT),
                })}
                icon={faCalendar}
              />
              <IWCardBadge
                label={
                  pod.lastRun && sinceLastRun
                    ? t('insightManager.pods.lastRanRelative', {
                        value: sinceLastRun?.split(' ')[0],
                      })
                    : t('insightManager.pods.hasNeverRun')
                }
                icon={faCalendar}
              />
            </IWBadgeGroup>

            {pod?.sharedByUserId && (
              <IWBadgeGroup>
                <IWCardBadge
                  label={t('insightManager.pods.sharing.sharedWithMeBy', {
                    user:
                      pod.sharedWithOrgRole && pod.sharedWithOrgRole.length > 0
                        ? renderOrgLabel(pod.sharedWithOrgRole)
                        : availableUsers?.obj[pod.sharedByUserId]?.name ||
                          t('insightManager.pods.sharing.unknownUser'),
                  })}
                  icon={faProjectDiagram}
                />
              </IWBadgeGroup>
            )}

            {canSharePod && renderSharedWith()}

            <IWTabs activeTabIndex={activeTab} onTabChange={setActiveTab}>
              <IWTab label={t('insightManager.podDetails.rawDataOutput')} />
              <IWTab label={t('insightManager.podDetails.podVisuals')} />
              <IWTab label={t('insightManager.podDetails.configAndFilters')} />
              <IWTab label={t('insightManager.podDetails.externalOutput')} />
            </IWTabs>
            {activeTab === 0 && (
              <RawDataTable
                datasource={pod.datasource}
                timezone={timezone}
                onDownload={(data) => {
                  const csv = convertToCVS(data)
                  downloadContent({
                    filename: `${pod.userPodName}-${pod.userPodId}`,
                    ext: 'csv',
                    content: csv,
                    mediaType: 'text/csv',
                  })
                }}
              />
            )}
            {activeTab === 1 && (
              <PodVisualisations
                userPodId={pod.userPodId}
                userPodName={pod.userPodName}
              />
            )}
            {activeTab === 2 && <PodDetailsSummary pod={pod} />}
            {activeTab === 3 && (
              <PodDetailsIntegrations
                userPodId={pod.userPodId}
                userPodName={pod.userPodName}
              />
            )}
          </main>
        </>
      )}
      {!(initializing || isLoading) && !pod && <NotFound />}
      {pod && (
        <ShareWithModal
          isOpen={showShareModal}
          handleClose={async () => {
            setShowShareModal(false)
            await refetch()
          }}
          pod={pod}
        />
      )}
    </>
  )
}

export default PodDetailsPage
