/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import {
  faPlusCircle,
  faSearch,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router'
import UserContext from 'shared/contexts/UserContext'
import { isPermissionAvailable } from 'shared/helpers'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import useBoundQueryParams from 'shared/hooks/useBoundQueryParams'
import {
  deleteAlert,
  getUserAlerts,
  toggleAlertIsPaused,
} from 'shared/podServiceClient'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import { ReactComponent as NoPodsImg } from '../../../images/no-pods.svg'
import AlertCardList from '../components/AlertCardList'
import ConfirmChangesModal from '../../insightsManager/components/ConfirmChangesModal'
import { useDebounceInput } from '../../../shared/hooks/useDebouncer'
import IWTextInput from '../../../shared/components/thunderbolt/IWTextInput'
import useGetOrgUsers from '../../../shared/hooks/useGetOrgUsers'

const ListControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const StyledWrapper = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  justify-content: space-between;
`

const Spacer = styled.div`
  flex-grow: 1;
`

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

const StyledPaginationControls = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const AlertManagerPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()
  const { availableToolPolicies } = useContext(UserContext)
  const canCreateAlert = isPermissionAvailable(
    availableToolPolicies,
    'alertsManager:editor',
  )
  const modalPromiseRef = useRef<{ resolve: () => void; reject: () => void }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false)

  const { data: orgUsersMap, isLoading: usersInitializing } = useGetOrgUsers()

  // Searching
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearch = useDebounceInput(searchValue, 500)

  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { setParam, state: queryState } = useBoundQueryParams({
    activeTab: 0,
    page: 1,
    perPage: 10,
  })

  const { perPage, page } = queryState

  useEffect(() => {
    if (itemsPerPage) {
      setParam('perPage', itemsPerPage)
    }
    if (currentPage) {
      setParam('page', currentPage)
    }
  }, [itemsPerPage, currentPage])

  // Watch for changes to the query to update the local component state on "back"
  useEffect(() => {
    setItemsPerPage(perPage)
    setCurrentPage(page)
  }, [perPage, page])

  const handleClearInput = () => {
    setSearchValue('')
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const {
    data: alerts,
    error,
    refetch,
    isLoading: isLoadingAlerts,
  } = useQuery(
    [
      'alerts',
      'pageNumber',
      currentPage,
      'pageSize',
      itemsPerPage,
      'podName',
      debouncedSearch,
    ],
    () => {
      return getUserAlerts(currentPage - 1, itemsPerPage, debouncedSearch)
    },
  )

  const handleDelete = async (userAlertId, userAlertName) => {
    try {
      setIsLoading(true)
      setShowDeleteConfirmModal(false)
      await deleteAlert(userAlertId)
      toast.secondary(
        t('alertsManager.toasts.deleteSuccess', { name: userAlertName }),
        { canDismiss: true },
      )
    } catch (e) {
      toast.alert(
        t('alertsManager.toasts.deleteError', { name: userAlertName }),
      )
    } finally {
      setIsLoading(false)
      await refetch()
    }
  }

  const handleConfirmDelete = (userAlertId, userAlertName) => {
    setShowDeleteConfirmModal(true)
    modalPromiseRef.current = {
      resolve: async () => {
        await handleDelete(userAlertId, userAlertName)
        setShowDeleteConfirmModal(false)
      },
      reject: () => {
        setShowDeleteConfirmModal(false)
      },
    }
  }

  const handleToggleAlertIsPaused = async (id: string, name: string) => {
    const newIsPaused = await toggleAlertIsPaused(id)
    refetch()
    toast.secondary(
      newIsPaused
        ? t('alertsManager.toasts.paused', { name })
        : t('alertsManager.toasts.resumed', { name }),
    )
  }

  const EmptyMessage = () => {
    return (
      <EmptyDiv data-testid="empty-message">
        <NoPodsImg />
        <IWTypography weight="bold">{t('alertsManager.noAlerts')}</IWTypography>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t('alertsManager.noAlertsDesc')}
        </IWTypography>
      </EmptyDiv>
    )
  }

  if (error) {
    return <IWError />
  }

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <StyledWrapper>
          <StyledTitle>{t('alertsManager.alertsManager')}</StyledTitle>
        </StyledWrapper>
      </IWPageHeader>
      <main>
        <ListControls>
          <Spacer />
          <IWTextInput
            data-testid="search-input"
            disabled={!alerts}
            leadingIcon={{
              icon: faSearch,
            }}
            trailingIcon={{
              icon: faTimes,
              onIconClick: handleClearInput,
            }}
            placeholder={t('placeholders.search')}
            onChange={handleInputChange}
            value={searchValue}
          />
          {canCreateAlert && (
            <IWButton
              data-testid="create-alert-button"
              icon={faPlusCircle}
              iconPosition="leading"
              onClick={() => navigate('/tools/alerts-manager/create-alert')}
            >
              {t('alertsManager.alert')}
            </IWButton>
          )}
        </ListControls>
        {(isLoadingAlerts || usersInitializing) && <IWLoading />}
        {!(isLoadingAlerts || usersInitializing) && alerts?.total === 0 && (
          <EmptyMessage />
        )}
        {!(isLoadingAlerts || usersInitializing) &&
          alerts &&
          alerts.total > 0 && (
            <>
              {alerts.rows.length > 0 && orgUsersMap?.obj ? (
                <>
                  <AlertCardList
                    orgUsersMap={orgUsersMap.obj}
                    onToggleAlertIsPaused={handleToggleAlertIsPaused}
                    alerts={alerts.rows}
                    isLoading={isLoading}
                    onDelete={handleConfirmDelete}
                  />
                  <StyledPaginationControls>
                    <IWPaginationControls
                      perPageLabel={t('tables.rowsPerPage')}
                      itemsPerPage={itemsPerPage}
                      perPageOptions={[
                        { value: 10, label: t('numbers.number', { val: 10 }) },
                        { value: 20, label: t('numbers.number', { val: 20 }) },
                        { value: 40, label: t('numbers.number', { val: 40 }) },
                        { value: 50, label: t('numbers.number', { val: 50 }) },
                      ]}
                      totalItems={alerts.total}
                      currentPage={currentPage}
                      onChangePage={setCurrentPage}
                      onChangeItemsPerPage={(newPerPage) => {
                        setItemsPerPage(newPerPage)
                        setCurrentPage(1)
                      }}
                    />
                  </StyledPaginationControls>
                  <ConfirmChangesModal
                    title={t(`alertsManager.confirmDeleteLabel`)}
                    body={t(`alertsManager.confirmDeleteDescription`)}
                    isOpen={showDeleteConfirmModal}
                    handleClose={modalPromiseRef.current?.reject}
                    handleConfirm={modalPromiseRef.current?.resolve}
                  />
                </>
              ) : (
                <IWNoResultsMessage />
              )}
            </>
          )}
      </main>
    </>
  )
}

export default AlertManagerPage
