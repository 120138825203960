import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { MainOptions } from 'shared/components/thunderbolt/IWRelativeDatePicker'
import IWRelativeDatePickerWrapper from 'shared/components/thunderbolt/IWRelativeDatePickerWrapper'
import { DateTime } from 'luxon'
import useIWRelativeDatePicker, {
  defaultHasErrorsCalculation,
  defaultIndividualErrorsCalculation,
  DefaultState,
} from 'shared/hooks/useIWRelativeDatePicker'
import MeasureRangeFilter from 'tools/insightsManager/components/MeasureRangeFilter'
import useMeasureRangeFilters, {
  MeasureRangeFilter as MeasureRangeFilterType,
} from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import LimitFilter from 'tools/insightsManager/components/LimitFilter'
import useLimitFilters, {
  LimitFilter as LimitFilterType,
} from 'tools/insightsManager/hooks/useLimitFilters'
import { AttributeFilter, WeatherAttributes } from 'tools/insightsManager/types'
import IWDropdown, {
  DropdownValueProps,
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import MarketFilter from '../../../components/MarketFilter'
import useMarketFilter from '../../../hooks/useMarketFilter'
import useWeatherAttributes from 'tools/insightsManager/hooks/useWeatherAttributes'
import { AggregationFn } from 'tools/alertsManager/types'
import { WizardStepProps } from '../../../../../shared/types'

type SettlementConfigurationState = {
  dateRange?: DefaultState
  attributeFilters?: AttributeFilter[]
  marketFilter: DropdownValueProps[]
  rangeFilters?: MeasureRangeFilterType[]
  limit?: LimitFilterType
  weatherAttributes: WeatherAttributes
  useForLoadScheduling?: boolean
}

const WrapperDivExample = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    width: 100%;
  }

  gap: 1rem;
`

const StyledRangeFilterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const datePickerMapper = (
  datePickerState: ReturnType<typeof useIWRelativeDatePicker>,
): DefaultState => {
  return {
    selectedSpecificDatePickerDate:
      datePickerState.selectedSpecificDatePickerDate,
    selectedDatePickerMin: datePickerState.selectedDatePickerMin,
    selectedDatePickerMax: datePickerState.selectedDatePickerMax,
    selectedRelativeOption: datePickerState.selectedRelativeOption,
    selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
    timeLengthValue: datePickerState.timeLengthValue,
    selectedMainOption: datePickerState.selectedMainOption,
    disabledMainOptions: datePickerState.disabledMainOptions,
  }
}

export type Props = WizardStepProps<SettlementConfigurationState>

const IWCreateConfigurationStep = ({ state, onChange, showErrors }: Props) => {
  const datePickerDisabledMainOptions: MainOptions[] = ['after', 'before']
  const { t } = useTranslation()
  const [isDatePickerOpen, setDatePickerIsOpen] = useState<boolean>(false)
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState<boolean>(false)
  const [datePickerDefaults, setDatePickerDefaults] = useState<DefaultState>(
    state?.dateRange || {
      selectedMainOption: undefined,
      selectedTimeLengthOption: { value: 'days' },
      selectedRelativeOption: { value: 'today' },
      selectedSpecificDatePickerDate: DateTime.local(),
      selectedDatePickerMin: DateTime.local(),
      selectedDatePickerMax: DateTime.local(),
      timeLengthValue: 30,
      disabledMainOptions: datePickerDisabledMainOptions,
    },
  )

  const {
    weatherStationCountOptions,
    aggregationOptions,
    weatherStationCount,
    aggregation,
    onWeatherStationCountChange,
    onAggregationChange,
    hasErrors: hasWeatherAttributesErrors,
  } = useWeatherAttributes(
    state?.weatherAttributes?.aggregationFnId,
    state?.weatherAttributes?.weatherStationCount,
  )

  const {
    selectedMarkets,
    availableMarkets,
    updateMarkets,
    hasErrors: hasMarketFiltersErrors,
  } = useMarketFilter(state?.marketFilter || [])

  const RANGE_FILTERS = [
    {
      value: 'forecast_net',
      label: t('insightManager.createPod.forecast_net'),
    },
    {
      value: 'forecast_gross',
      label: t('insightManager.createPod.forecast_gross'),
    },
  ]
  const LIMIT_FILTERS = [
    {
      value: 'forecast_net',
      label: t('insightManager.createPod.forecast_net'),
    },
    {
      value: 'forecast_gross',
      label: t('insightManager.createPod.forecast_gross'),
    },
  ]

  const {
    filters: rangeFilters,
    ranges,
    hasErrors: hasRangeFiltersErrors,
    addRange,
    deleteRange,
    onMeasureFilterChange,
    onFirstConditionChange,
    onFirstConditionValueChange,
    onFirstConditionToggleChange,
    onSecondConditionChange,
    onSecondConditionValueChange,
    onSecondConditionToggleChange,
  } = useMeasureRangeFilters(RANGE_FILTERS, state?.rangeFilters)

  const {
    limit,
    limitFilters,
    hasErrors: hasLimitErrors,
    onMeasureOptionChange,
    onEdgeOptionChange,
    onTypeOptionChange,
    onValueChange,
    addLimit,
    deleteLimit,
  } = useLimitFilters(LIMIT_FILTERS, state?.limit)

  const updateRangeFilters = (range) => {
    return RANGE_FILTERS.filter((rf) => {
      return (
        range.selectedMeasureRangeFilterOption?.value === rf.value ||
        !ranges.find(
          (r) => r.selectedMeasureRangeFilterOption?.value === rf.value,
        )
      )
    })
  }

  const datePickerState = useIWRelativeDatePicker({
    ...datePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const handleDatePickerConfirm = () => {
    if (datePickerState.hasErrors) {
      setShouldDisplayErrors(true)
    } else {
      setDatePickerDefaults({
        selectedMainOption: datePickerState.selectedMainOption,
        selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
        selectedRelativeOption: datePickerState.selectedRelativeOption,
        selectedSpecificDatePickerDate:
          datePickerState.selectedSpecificDatePickerDate,
        selectedDatePickerMin: datePickerState.selectedDatePickerMin,
        selectedDatePickerMax: datePickerState.selectedDatePickerMax,
        timeLengthValue: datePickerState.timeLengthValue,
        disabledMainOptions: datePickerDisabledMainOptions,
      })
      setDatePickerIsOpen(false)
      setShouldDisplayErrors(false)
    }
  }

  const handleDatePickerCancel = () => {
    datePickerState.modifyFullState(datePickerDefaults)
    setDatePickerIsOpen(false)
  }

  const stepValidation = () => {
    // valid if date picker has no errors and not open
    const isDateRangeValid = !datePickerState.hasErrors && !isDatePickerOpen
    // valid if range section is (empty or not empty and has no errors)
    const isRangeFilterSectionValid =
      ranges.length === 0 || (ranges.length > 0 && !hasRangeFiltersErrors)
    // valid if limit section is (empty or not empty and has no errors)
    const isLimitSectionValid = !limit || (limit && !hasLimitErrors)
    // step is valid if all section validation === true
    return (
      isDateRangeValid &&
      !hasMarketFiltersErrors &&
      isRangeFilterSectionValid &&
      isLimitSectionValid
    )
  }

  useEffect(() => {
    if (isDatePickerOpen) {
      return
    }
    const stepIsValid = stepValidation()
    const mappedDatePicker = datePickerMapper(datePickerState)
    onChange(
      {
        dateRange: mappedDatePicker,
        marketFilter: selectedMarkets,
        rangeFilters: ranges,
        limit,
        weatherAttributes: {
          weatherStationCount: weatherStationCount?.value,
          aggregationFnId: aggregation?.value,
        },
      },
      stepIsValid,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDatePickerOpen,
    datePickerState,
    selectedMarkets,
    hasMarketFiltersErrors,
    ranges,
    hasRangeFiltersErrors,
    limit,
    hasLimitErrors,
    weatherStationCount,
    aggregation,
    hasWeatherAttributesErrors,
  ])

  return (
    <>
      <IWFormSection sectionTitle={t(`insightManager.createPod.dateRange`)}>
        <IWRelativeDatePickerWrapper
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datePickerState}
          label={t(
            'insights.shortTermForecast.metrics.settlement.relativeDatePickerLabel',
          )}
          maxTimeLengthValue={100}
          shouldDisplayErrors={shouldDisplayErrors}
          isOpen={isDatePickerOpen}
          onConfirm={handleDatePickerConfirm}
          onPlaceholderClick={() => setDatePickerIsOpen(true)}
          onCancel={handleDatePickerCancel}
          hasError={showErrors && datePickerState.hasErrors}
        />
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`weatherAttribute.formHeader`)}
        sectionDescription={t(`weatherAttribute.formDesc`)}
      >
        <WrapperDivExample>
          <IWDropdown
            required
            options={aggregationOptions}
            getOptionLabel={(option) => {
              const { value } = option as GenericDropdownValue<AggregationFn>
              return t(`alertsManager.createAlert.triggerStep.${value}`)
            }}
            value={aggregation}
            label={t(`weatherAttribute.aggregationFn.label`)}
            onChange={(val) => onAggregationChange(val)}
            hasError={showErrors && !aggregation}
            errorText={
              showErrors && !aggregation
                ? t(`alertsManager.errors.enterAllRequired`)
                : undefined
            }
          />
          <IWDropdown
            required
            options={weatherStationCountOptions}
            value={weatherStationCount}
            label={t(`weatherAttribute.weatherStationCount.label`)}
            onChange={(val) => onWeatherStationCountChange(val)}
            hasError={showErrors && !weatherStationCount}
            errorText={
              showErrors && !weatherStationCount
                ? t(`alertsManager.errors.enterAllRequired`)
                : undefined
            }
          />
        </WrapperDivExample>
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(`attributeFilterSection.attributeFilters`)}
        sectionDescription={t(
          `attributeFilterSection.attributeFiltersDescription`,
        )}
      >
        <MarketFilter
          showErrors={showErrors}
          availableMarkets={availableMarkets}
          selectedMarkets={selectedMarkets}
          onSetState={updateMarkets}
          isMultiSelect
          hasErrors={hasMarketFiltersErrors}
        />
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`rangeFilterSection.rangeFilters`)}
        sectionDescription={t(`rangeFilterSection.rangeFiltersDescription`)}
      >
        <StyledRangeFilterSection>
          {ranges.map((range) => (
            <MeasureRangeFilter
              key={range.id}
              filters={updateRangeFilters(range)}
              range={range}
              showErrors={showErrors}
              hasErrors={hasRangeFiltersErrors}
              onMeasureFilterChange={onMeasureFilterChange}
              onFirstConditionChange={onFirstConditionChange}
              onFirstConditionValueChange={onFirstConditionValueChange}
              onFirstConditionToggleChange={onFirstConditionToggleChange}
              onSecondConditionChange={onSecondConditionChange}
              onSecondConditionValueChange={onSecondConditionValueChange}
              onSecondConditionToggleChange={onSecondConditionToggleChange}
              onDelete={deleteRange}
            />
          ))}

          <IWButton
            data-testid="measure-range-filter-add"
            type="button"
            color="primary"
            icon={faPlusCircle}
            iconPosition="leading"
            onClick={addRange}
            disabled={
              hasRangeFiltersErrors || rangeFilters.length === ranges.length
            }
          >
            {t('rangeFilterSection.rangeFilters')}
          </IWButton>
        </StyledRangeFilterSection>
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`limitFilterSection.limitFilters`)}
        sectionDescription={t(`limitFilterSection.limitFiltersDescription`)}
      >
        <StyledRangeFilterSection>
          <LimitFilter
            filters={limitFilters}
            limit={limit}
            onMeasureFilterChange={onMeasureOptionChange}
            onEdgeOptionChange={onEdgeOptionChange}
            onTypeOptionChange={onTypeOptionChange}
            onValueChange={onValueChange}
            onDelete={deleteLimit}
            showErrors={showErrors}
            hasErrors={hasLimitErrors}
          />

          <IWButton
            data-testid="measure-range-filter-add"
            type="button"
            color="primary"
            icon={faPlusCircle}
            iconPosition="leading"
            onClick={addLimit}
            disabled={Boolean(limit)}
          >
            {t('limitFilterSection.limitFilter')}
          </IWButton>
        </StyledRangeFilterSection>
      </IWFormSection>
    </>
  )
}
export default IWCreateConfigurationStep
