import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Popover } from 'react-tiny-popover'
import { faClock } from '@fortawesome/pro-regular-svg-icons'
import getCronDisplayString from 'shared/utils/getCronDisplayString'
import IWCronJobSelector, {
  Props as IWCronJobSelectorProps,
} from './IWCronJobSelector'
import { IWInputPlaceholder } from './IWTextInput'
import IWButton from './IWButton'
import IWTypography from './IWTypography'

const StyledPopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

interface Props extends IWCronJobSelectorProps {
  /**
   * Event handler to be used when the user click
   * confirm button on the cronJobScheduleSelectorInput
   */
  onConfirm: () => void
  /**
   * Event handler to be used when the user click
   * cancel button on the cronJobScheduleSelectorInput
   */
  onCancel: () => void
  /**
   * Event handler to be used when the user click
   * cronJobScheduleSelectorInput
   */
  onPlaceholderClick: () => void
  /**
   * flag to show error in main input
   */
  shouldShowErrorsOutsideInput?: boolean
  /**
   * to detemine when to open and when to close the popup
   * in which the cronjobselectorinput can be found
   */
  isOpen: boolean
}

/**
 * Simple wrapper to enclose the cronJobScheduleSelectorInput
 */
const IWCronJobSelectorWrapper = ({
  onConfirm,
  onCancel,
  onPlaceholderClick,
  isOpen,
  shouldShowErrorsOutsideInput,
  ...state
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { selectedScheduleTypeOption } = state

  const [summary, setSummary] = useState<string | undefined>()

  useEffect(() => {
    if (isOpen || !selectedScheduleTypeOption) {
      return
    }
    setSummary(getCronDisplayString(state, t))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, state])

  return (
    <Popover
      cron-selector-placeholder
      padding={4}
      reposition={false}
      containerStyle={{ zIndex: theme.layers.popover }}
      positions={['bottom']}
      isOpen={isOpen}
      onClickOutside={onCancel}
      content={({ childRect }) => {
        return (
          <StyledPopupDiv
            style={{
              width: `${childRect.width}px`,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <IWCronJobSelector
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...state}
            />
            <StyledButtonDiv>
              <IWButton
                data-testid="cron-selector-cancel"
                variant="alternative"
                onClick={onCancel}
              >
                {t(`button.cancel`)}
              </IWButton>
              <IWButton data-testid="cron-selector-confirm" onClick={onConfirm}>
                {t(`button.confirm`)}
              </IWButton>
            </StyledButtonDiv>
          </StyledPopupDiv>
        )
      }}
    >
      <IWInputPlaceholder
        data-testid="cron-selector-placeholder"
        placeholder={t('cronJobScheduleInput.placeholder')}
        leadingIcon={{ icon: faClock }}
        label={t('cronJobScheduleInput.label')}
        onClick={onPlaceholderClick}
        helperText={t('cronJobScheduleInput.helperText')}
        hasError={shouldShowErrorsOutsideInput && !selectedScheduleTypeOption}
        errorText={
          shouldShowErrorsOutsideInput && !selectedScheduleTypeOption
            ? t('insightManager.createPod.errors.enterAllRequired')
            : undefined
        }
        required
      >
        {summary && (
          <IWTypography size="sm" weight="regular">
            {summary}
          </IWTypography>
        )}
      </IWInputPlaceholder>
    </Popover>
  )
}

export default IWCronJobSelectorWrapper
