import React, { ReactNode } from 'react'
import styled from 'styled-components'
import IWTypography from './IWTypography'

export interface SectionProps {
  /** Required. string format. Specifies title to the section */
  sectionTitle: string | ReactNode
  /** Optional. string format. Describes the section's purpose */
  sectionDescription?: string
  /** Required. ReactNode. Can contain any of the Thunderbolt Form elements. Likely: Input, Select, etc. */
  children: ReactNode
}

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SectionTitle = styled.div`
  width: 100%;
  flex-direction: row;
`

const SectionDescription = styled.div`
  width: 100%;
  flex-direction: row;
  color: ${(props) => props.theme.palette.grey[700]};
`

/**
 * This particular component is used to create a customizable section inside a form in the UI.
 * This component can be used to create complex section in a form namely with multiple select elements or multiple input elements under one section
 * Required params: 'sectionTitle', 'children'
 * Optional params: 'sectionDescription'
 */
const IWFormSection = ({
  sectionTitle,
  sectionDescription,
  children,
}: SectionProps) => {
  return (
    <Section data-testid="form-section">
      <SectionTitle data-testid="form-section-title">
        <IWTypography
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {sectionTitle}
        </IWTypography>
      </SectionTitle>

      {sectionDescription && (
        <SectionDescription data-testid="form-section-description">
          <IWTypography fontHue={{ color: 'grey', value: 500 }} size="sm">
            {sectionDescription}
          </IWTypography>
        </SectionDescription>
      )}
      {children}
    </Section>
  )
}

export default IWFormSection
