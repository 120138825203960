import { DefaultCronSelectorState } from 'shared/hooks/useIWCronJobScheduleSelector'
import { DateTime } from 'luxon'
import { shiftCronHourFromLocalToUTC } from './dateUtils'

const generateCronExpression = (
  cronSelectorInputValues: DefaultCronSelectorState,
): string | undefined => {
  let string: string | undefined

  let runningDay = ''
  if (
    cronSelectorInputValues.selectedTimeOfMonthOption?.value === 'firstOfMonth'
  ) {
    runningDay = '1'
  } else if (
    cronSelectorInputValues.selectedTimeOfMonthOption?.value === '15thOfMonth'
  ) {
    runningDay = '15'
  } else if (
    cronSelectorInputValues.selectedTimeOfMonthOption?.value === 'lastOfMonth'
  ) {
    // NOTE: Edge case. This won't shift from last day to the next day.
    // Example: Hour 23 CDT last day of the month, will become hour 4 last day of the month
    runningDay = 'L'
  }

  const cronDate = DateTime.local()
    .set({
      month: cronSelectorInputValues.selectedMonthOption?.value,
      day: runningDay !== 'L' ? +runningDay : undefined,
    })
    .toISO()

  const {
    day,
    hour: selectedHour,
    month: selectedMonth,
  } = shiftCronHourFromLocalToUTC(
    cronSelectorInputValues.selectedHourOption?.value,
    cronDate,
  )
  const selectedDay = runningDay === 'L' ? runningDay : day
  const weeklySelectedDays = cronSelectorInputValues.selectedWeekdayOption
    ?.map(({ value }) => {
      const isoDate = DateTime.local().set({
        day: value,
      })
      const { day: currentDay } = shiftCronHourFromLocalToUTC(
        cronSelectorInputValues.selectedHourOption?.value,
        isoDate.toISO(),
      )
      if (currentDay > 7) {
        return 1
      }
      if (currentDay < 1) {
        return 7
      }
      return currentDay
    })
    .join(',')

  switch (cronSelectorInputValues.selectedScheduleTypeOption?.value) {
    case 'once':
      string = undefined
      break
    case 'daily':
      string = `0 ${selectedHour} * * *`
      break
    case 'hourly':
      string = `0 * * * *`
      break
    case 'weekly':
      string = `0 ${selectedHour} * * ${weeklySelectedDays}`
      break
    case 'monthly':
      string = `0 ${selectedHour} ${selectedDay} * *`
      break
    case 'yearly':
      string = `0 ${selectedHour} ${selectedDay} ${selectedMonth} *`
      break
    default:
      string = undefined
      break
  }
  return string
}

export default generateCronExpression
