import { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

const thirdPartyIntegrations = ['oneDrive', 'googleDrive'] as const
export type AvailableIntegrations = (typeof thirdPartyIntegrations)[number]

/**
 * This hook is general to any third party sign in popup..
 * Its intent is to open the
 */
const useThirdPartySignUpPopup = (
  availableIntegrations: AvailableIntegrations[],
) => {
  const [storedIntegration, setStoredIntegration] = useState<
    { integration: AvailableIntegrations; code: string } | undefined
  >()
  const [url, setUrl] = useState<string | undefined>()
  const [searchParams] = useSearchParams()

  const windowRef = useRef<Window | null>(null)

  useEffect(() => {
    const searchParamsCode = searchParams.get('code')
    const searchParamsState = searchParams.get('state')
    const integration = availableIntegrations.find(
      (i) => i === searchParamsState,
    )
    if (searchParamsCode && integration) {
      localStorage.setItem(
        'thirdPartyIntegration',
        JSON.stringify({ integration, code: searchParamsCode }),
      )
    }
  }, [availableIntegrations, searchParams])

  useEffect(() => {
    if (!url) {
      return
    }
    const width = 600
    const height = 600
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const options = {
      toolbar: 0,
      scrollbars: 1,
      status: 1,
      resizable: 0,
      location: 1,
      menubar: 0,
      width,
      height,
      top,
      left,
    }
    const windowStrOptions = Object.entries(options)
      .map(([k, v]) => `${k}=${v}`)
      .join(',')
    windowRef.current = window.open(url, '', windowStrOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const storageListener = () => {
    try {
      const thirdPartyIntegration = localStorage.getItem(
        'thirdPartyIntegration',
      )
      if (thirdPartyIntegration) {
        windowRef.current?.close()
        setStoredIntegration(JSON.parse(thirdPartyIntegration))
        window.removeEventListener('storage', storageListener)
      }
    } catch (e) {
      window.removeEventListener('storage', storageListener)
    }
  }

  window.addEventListener('storage', storageListener)

  const cleanUp = () => {
    localStorage.removeItem('thirdPartyIntegration')
  }

  return {
    storedIntegration,
    cleanUp,
    setUrl,
  }
}

export default useThirdPartySignUpPopup
