import React from 'react'
import styled from 'styled-components'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink } from 'react-router-dom'
import IWTypography from './IWTypography'
import IWCheckbox from './IWCheckbox'
import IWRadioButton from './IWRadioButton'

type ElementProps =
  | ({ as?: 'button' } & React.ComponentPropsWithoutRef<'button'>)
  | ({ as: 'a' } & React.ComponentPropsWithoutRef<'a'>)
  | ({ as: 'checkbox' } & React.ComponentPropsWithoutRef<'input'>)
  | ({ as: 'radio' } & React.ComponentPropsWithoutRef<'input'>)
  | ({ as: 'routerLink' } & React.ComponentPropsWithoutRef<typeof Link>)
  | ({ as: 'routerNavLink' } & React.ComponentPropsWithoutRef<typeof NavLink>)

interface ContextMenuItemProps {
  /**
   * The label of the menu item to indicate its function
   */
  label: string
  /**
   * Optional Id to be provided if the element is an input part of a form
   */
  id?: string
  /**
   * Icon to be placed in the leading position of the menu item
   */
  icon?: IconDefinition
  disabled?: boolean
}

export type Props = ContextMenuItemProps & ElementProps

const LeadingIconWrapper = styled.div<{}>`
  margin: 0 0.25rem 0 0.25rem;
  display: flex;
  font-size: 1rem;
  align-items: center;
`
const StyledIWTypography = styled(IWTypography)`
  flex-grow: 1;
  margin: 0 0.25rem 0 0.25rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledInputDiv = styled.input``

const StyledMenuItem = styled.button<{
  isInput?: boolean
}>`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  text-decoration: none;
  background-color: ${(props) => props.theme.palette.grey[0]};
  color: ${(props) => props.theme.palette.grey[700]};
  border: none;
  border-radius: inherit;
  text-transform: capitalize;
  padding: ${(props) =>
    props.isInput
      ? '0.75rem 1.5rem'
      : '0.75rem 1rem'}; // inputs need more padding to line up
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
  &:disabled {
    pointer-events: none;
    background-color: ${(props) => props.theme.palette.grey[50]};
    color: ${(props) => props.theme.palette.grey[400]};
  }
`
/**
 * The Contextual Menu item to be used throughout the app
 * The element can be cast to a 'button' 'checkbox' 'radio' or 'routerLink' or 'routerNavLink'
 *
 * In the case of button/nav links it functions as these elements would, triggering a link or action.
 * In the case of checkbox/radio it simply wraps the 'IWCheckbox' 'IWRadioButton' components and styles to look like a menu item
 */
const IWContextMenuItem = React.forwardRef(
  (
    { id, label, icon, disabled, as, ...props }: Props,
    ref:
      | React.Ref<HTMLButtonElement>
      | React.Ref<HTMLAnchorElement>
      | React.Ref<HTMLInputElement>,
  ) => {
    let tag
    switch (as) {
      case 'routerLink':
        tag = Link
        break
      case 'routerNavLink':
        tag = NavLink
        break
      case 'radio':
        tag = IWRadioButton
        break
      case 'checkbox':
        tag = IWCheckbox
        break
      default:
        tag = as
    }

    if (as === 'radio' || as === 'checkbox') {
      return (
        <StyledMenuItem isInput disabled={disabled}>
          <StyledInputDiv
            disabled={disabled}
            as={tag}
            id={id || Math.random().toString()}
            ref={ref as React.Ref<HTMLInputElement>}
            label={label}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(props as React.ComponentPropsWithoutRef<'input'>)}
          />
        </StyledMenuItem>
      )
    }

    return (
      <StyledMenuItem
        as={tag}
        ref={ref}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {icon && (
          <LeadingIconWrapper>
            <FontAwesomeIcon icon={icon} />
          </LeadingIconWrapper>
        )}
        <StyledIWTypography
          inheritFontColor
          as="span"
          size="sm"
          weight="regular"
        >
          {label}
        </StyledIWTypography>
      </StyledMenuItem>
    )
  },
)

export default IWContextMenuItem
