import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { useTranslation } from 'react-i18next'
import {
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

export type Props = {
  /** Icon to display */
  icon?: IconDefinition
  /** Main title to display */
  title: string
  /** Body/extra content to display */
  body: string
  /** Flag to determine visibility */
  isOpen: boolean
  /** Function to call when the close is pressed */
  handleClose?: () => void
  /** Function to call when the confirm is pressed */
  handleConfirm?: () => void
}

const StyledIWModalHeader = styled(IWModalHeader)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
`

/**
 * A warning modal which prompts the user to confirm or cancel
 */
const ConfirmChangesModal = ({
  icon = faExclamationTriangle,
  title,
  body,
  isOpen,
  handleClose,
  handleConfirm,
}: Props) => {
  const { t } = useTranslation()
  return (
    <IWModal open={isOpen} onClose={handleClose}>
      <StyledIWModalHeader>
        <IWTypography size="lg" fontHue={{ color: 'alert', value: 800 }}>
          <FontAwesomeIcon icon={icon} />
        </IWTypography>
        <IWTypography
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {title}
        </IWTypography>
      </StyledIWModalHeader>
      <IWModalContent data-testid="confirm-changes-modal">
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {body}
        </IWTypography>
      </IWModalContent>
      <IWModalFooter>
        <StyledActions>
          <IWButton variant="outline" color="grey" onClick={handleClose}>
            {t(`button.cancel`)}
          </IWButton>
          <IWButton
            data-testid="confirm-changes-modal-confirm"
            variant="main"
            color="alert"
            onClick={handleConfirm}
          >
            {t(`button.confirm`)}
          </IWButton>
        </StyledActions>
      </IWModalFooter>
    </IWModal>
  )
}

export default ConfirmChangesModal
