import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router'
import UserContext from 'shared/contexts/UserContext'
import AlertManagerPage from './pages/AlertManagerPage'
import AlertDetailsPage from './pages/AlertDetailsPage'
import AlertTriggerDetailsPage from './pages/AlertTriggerDetailsPage'
import CreateAlertPage from './pages/CreateAlertPage'

const AlertsManagerWrapper = () => {
  const { availableTools } = useContext(UserContext)
  const isAuthorised = availableTools.includes('alertsManager')

  return isAuthorised ? <Outlet /> : <Navigate to="/not-authorised" replace />
}

// eslint-disable-next-line import/prefer-default-export
export const routes = [
  {
    title: 'Alerts Manager',
    key: 'alertsManager',
    path: '/alerts-manager',
    main: <AlertsManagerWrapper />,
    childRoutes: [
      {
        title: 'Alerts Manager',
        key: 'main',
        main: <AlertManagerPage />,
        entryPoint: true,
      },
      {
        title: 'Alert Details',
        key: 'alert-details',
        path: '/alert-details/:alertId',
        main: <AlertDetailsPage />,
      },
      {
        title: 'Alert Trigger Details',
        key: 'alert-trigger-details',
        path: '/alert-details/:alertId/triggers/:triggerId',
        main: <AlertTriggerDetailsPage />,
      },
      {
        title: 'Create Alert',
        key: 'create-alert',
        path: '/create-alert',
        main: <CreateAlertPage />,
      },
    ],
  },
]
