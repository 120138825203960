import React from 'react'
import styled from 'styled-components'
import IWInputLabel, { IWInputInfo } from './IWCommonLabels'
import { IWRadioButtonProps } from './IWRadioButton'

export interface Props {
  /** Optional Label to display for this input  */
  label?: string
  children: React.ReactElement<IWRadioButtonProps>[]
  /** helper text for additional infomation */
  helperText?: string
  /** error message on input which will display error message */
  errorText?: string
  /** Determines if the buttons should be in a row or column */
  direction?: 'row' | 'column'
}

const StyledChildContainer = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => (props.direction === 'row' ? '1rem' : 'auto')};
  & > :not(:last-child) {
    margin-bottom: ${(props) => (props.direction === 'row' ? '0.5rem' : '0')};
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

/**
 * This represents a list of radio input items
 */
const IWRadioButtonGroup = ({
  label,
  children,
  helperText,
  errorText,
  direction = 'column',
}: Props) => {
  return (
    <StyledDiv>
      <IWInputLabel label={label} />
      <StyledChildContainer direction={direction}>
        {children}
      </StyledChildContainer>
      <IWInputInfo helperText={helperText} errorText={errorText} />
    </StyledDiv>
  )
}

export default IWRadioButtonGroup
