import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import type { FilterBadgesRangeState } from 'shared/components/thunderbolt/IWFilterBadgeRange'
// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse from 'fuse.js'

export const getFilterBadgeOptionsDisplayString = (
  filterName: string,
  optionType: string,
  values: DropdownValueProps[],
  maxOptionsDisplay: number = 2,
): string => {
  const labels = values.map((v) => v.label)
  const totalLabels = labels.length
  const prefix = filterName
  const suffix = optionType === 'include' ? 'included' : 'excluded'
  const filterlabel = totalLabels ? `${prefix} ${suffix}` : prefix

  if (totalLabels === 0) {
    return filterlabel
  }

  const displayPlusLabel = totalLabels > maxOptionsDisplay
  if (displayPlusLabel) {
    const [first, second] = labels
    const labelNames = [
      first,
      second,
      `+${totalLabels - maxOptionsDisplay}`,
    ].join(', ')
    return `${filterlabel}: ${labelNames}`
  }

  const valuesNames = labels.join(', ')
  return `${filterlabel}: ${valuesNames}`
}

export const getFilterBadgeRangeDisplayString = (
  filterName: string,
  state: FilterBadgesRangeState,
): string => {
  const label = filterName.replaceAll(/_/g, ' ')
  const firstConditionText =
    state.firstConditionValue && state.selectedFirstConditionOption?.value
      ? `${state.selectedFirstConditionOption?.value} ${state.firstConditionValue}`
      : ''
  const secondConditionText =
    state.secondConditionValue && state.selectedSecondConditionOption?.value
      ? `${state.selectedSecondConditionOption?.value} ${state.secondConditionValue}`
      : ''

  const preposition = firstConditionText && secondConditionText ? ` & ` : ''
  const suffix = `${firstConditionText}${preposition}${secondConditionText}`
  return suffix ? `${label}: ${suffix}` : label
}

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const getUniqueValuesByKey = (arr, key, searchKey = '') => {
  // Create a Set to store unique values
  const uniqueValues = new Set()

  // Iterate over the array of objects
  arr.forEach((obj) => {
    // Check if the object has the specified key
    if (obj.hasOwnProperty(key)) {
      // Add the value of the key to the Set
      uniqueValues.add(obj[key])
    }
  })

  if (searchKey !== '') {
    const fuse = new Fuse(Array.from(uniqueValues), {
      keys: [key],
    })
    return fuse.search(searchKey).map((item) => item.item)
  }

  // Convert the Set back to an array and return it
  return Array.from(uniqueValues)
}

export default {}
