import React, { useContext, useEffect } from 'react'
import { Routes, useLocation, useNavigate } from 'react-router-dom'
import UserContext from 'shared/contexts/UserContext'
import { QueryClientProvider } from 'react-query'
import ThemeContextProvider from 'ThemeContextProvider'
import LayoutContext from 'shared/contexts/LayoutContext'
import root from 'root'
import useAuthenticationFlow from 'root/hooks/useAuthenticationFlow'
import Login from 'root/pages/Login'
import { ToastProvider } from 'shared/components/thunderbolt/IWToastContext'
import { queryClient } from 'shared/apiClient'
import { DashboardCategoryProvider } from 'shared/contexts/DashboardCategoryContext'
import { InsightCategoryProvider } from 'shared/contexts/InsightManagerCategoryContext'

function App() {
  const { theme, setMenuOpen, isMediumScreen } = useContext(LayoutContext)
  const location = useLocation()
  const navigate = useNavigate()
  const { userState, login: onLogin } = useAuthenticationFlow(navigate)

  useEffect(() => {
    // If the route changes while we are on a medium screen (nav is overlay) we close it
    if (isMediumScreen) {
      setMenuOpen(false)
    }
    // We don't want to check on screen-size change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (!userState) {
    return <Login onLogin={onLogin} />
  }

  /* This component just sets the initial wrappers for theming, routing, i18, use query */
  return (
    <ThemeContextProvider theme={theme}>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider value={userState}>
            <DashboardCategoryProvider>
              <InsightCategoryProvider>
                <Routes>{root}</Routes>
              </InsightCategoryProvider>
            </DashboardCategoryProvider>
          </UserContext.Provider>
        </QueryClientProvider>
      </ToastProvider>
    </ThemeContextProvider>
  )
}

export default App
