import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWProductList from 'shared/components/thunderbolt/IWProductList'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import LayoutContext, {
  availableThemes,
  availableTimezones,
} from 'shared/contexts/LayoutContext'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { IWGenericCard } from 'shared/components/thunderbolt/IWGenericCard'
import NotificationSelectorModal from 'systemPreferences/components/NotificationSelectorModal'
import useNotificationSelector, {
  Notifications,
} from 'systemPreferences/hooks/useNotificationSelector'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import { availableLanguages } from 'i18n'

const PreferencesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CardContent = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
`

const StyledInfoBlock = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

/**
 * Page to display and edit general settings
 */
const GeneralSettingPage = () => {
  const { t } = useTranslation()

  const { theme, setTheme, language, setLanguage, timezone, setTimezone } =
    useContext(LayoutContext)

  const languageOptions = availableLanguages.map((l) => ({ value: l }))

  const timezoneOptions = availableTimezones.map((tz) => ({
    value: tz,
    label: tz,
  }))

  const themeOptions = availableThemes.map((thm) => ({ value: thm }))

  const {
    isNotificationModalOpen,
    setIsNotificationSelectorModalOpen,
    notificationOptions,
    selectedNotificationType,
    notifications,
    updateNotifications,
  } = useNotificationSelector()

  return (
    <>
      {isNotificationModalOpen && (
        <NotificationSelectorModal
          notificationType={selectedNotificationType}
          icon={
            notificationOptions.find((n) => n.id === selectedNotificationType)
              ?.icon || faBell
          }
          notifications={notifications() || ([] as Notifications)}
          isOpen={isNotificationModalOpen}
          handleCancel={() => setIsNotificationSelectorModalOpen(false)}
          handleConfirm={(changed) => {
            updateNotifications(changed)
            setIsNotificationSelectorModalOpen(false)
          }}
        />
      )}
      <PreferencesSection>
        <IWTypography size="lg" weight="medium">
          {t('systemPreferences.generalSetting.header')}
        </IWTypography>
        <IWGenericCard>
          <CardContent>
            <StyledInfoSection>
              <StyledInfoBlock>
                <IWDropdown
                  label={t('systemPreferences.generalSetting.language')}
                  value={{ value: language }}
                  onChange={(a) => {
                    setLanguage(a.value)
                  }}
                  getOptionLabel={(option) => {
                    return t(`language.${option.value}`)
                  }}
                  options={languageOptions}
                />
              </StyledInfoBlock>
              <StyledInfoBlock>
                <IWDropdown
                  label={t('systemPreferences.generalSetting.timezone')}
                  options={timezoneOptions}
                  onChange={(a) => {
                    setTimezone(a.value)
                  }}
                  value={{ value: timezone, label: timezone }}
                />
              </StyledInfoBlock>
            </StyledInfoSection>
          </CardContent>
        </IWGenericCard>
      </PreferencesSection>

      <PreferencesSection>
        <IWTypography size="lg" weight="medium">
          {t('systemPreferences.energySaving.header')}
        </IWTypography>
        <IWGenericCard>
          <CardContent>
            <StyledInfoSection>
              <IWDropdown
                options={themeOptions}
                value={{ value: theme }}
                onChange={(a) => {
                  setTheme(a.value)
                }}
                getOptionLabel={(option) => {
                  return t(`systemPreferences.energySaving.${option.value}`)
                }}
              />
            </StyledInfoSection>
          </CardContent>
        </IWGenericCard>
      </PreferencesSection>

      {/* Reactivate once notifications become available by removing conditional */}
      {isNotificationModalOpen && (
        <PreferencesSection>
          <IWTypography size="lg" weight="medium">
            {t('systemPreferences.systemNotification.header')}
          </IWTypography>
          <IWGenericCard>
            <CardContent>
              <StyledInfoSection>
                <IWProductList>
                  {notificationOptions.map(
                    ({ id, header, icon, iconColor, onClick, badges }) => (
                      <IWProductCard
                        key={id}
                        header={header}
                        icon={icon}
                        iconColor={iconColor}
                        onClick={onClick}
                        badges={badges}
                      />
                    ),
                  )}
                </IWProductList>
              </StyledInfoSection>
            </CardContent>
          </IWGenericCard>
        </PreferencesSection>
      )}
    </>
  )
}

export default GeneralSettingPage
