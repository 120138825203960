import React, { SyntheticEvent, useContext } from 'react'
import { t } from 'i18next'
import IWPaginationControls, {
  Props as IWPaginationProps,
} from 'shared/components/thunderbolt/IWPaginationControls'
import IWTable, { IWTableProps } from 'shared/components/thunderbolt/IWTable'
import { PaginatedData, paginationOptions } from 'shared/types'
import { faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import styled from 'styled-components'
import { InboundCredentials } from 'systemPreferences/integrationsServiceClient'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import LayoutContext from 'shared/contexts/LayoutContext'

const ActionButtonsWrap = styled.div`
  display: flex;
  gap: 0.25rem;
`

const PaginationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  padding: 0.5rem;
  justify-content: right;
`
interface Props {
  onSort: IWTableProps['onSort']
  sort: IWTableProps['sortedBy']
  columns: IWTableProps['columns']
  data?: PaginatedData<InboundCredentials>
  page: {
    current: number
    size: number
  }
  onChangePage: IWPaginationProps['onChangePage']
  onChangeItemsPerPage: IWPaginationProps['onChangeItemsPerPage']
  isLoading: boolean
  onEdit: (e: SyntheticEvent, credential: InboundCredentials) => void
  onDelete: (e: SyntheticEvent, credential: InboundCredentials) => void
}

const DataAccessTable = ({
  onSort,
  sort,
  columns,
  data,
  page,
  onChangePage,
  onChangeItemsPerPage,
  isLoading,
  onEdit,
  onDelete,
}: Props) => {
  const { timezone } = useContext(LayoutContext)
  const buildTableNameData = (name: string, clientId: string) => {
    return (
      <div>
        <IWTypography
          size="sm"
          weight="medium"
          fontHue={{ color: 'grey', value: 800 }}
        >
          {name}
        </IWTypography>
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 400 }}
        >
          {clientId}
        </IWTypography>
      </div>
    )
  }

  const buildTableActionButtons = (credential: InboundCredentials) => {
    return (
      <ActionButtonsWrap>
        <IWButton
          title={t('actions.details')}
          color="primary"
          onClick={(e) => {
            onEdit(e, credential)
          }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </IWButton>
        <IWButton
          title={t('actions.explore')}
          color="primary"
          onClick={(e) => onDelete(e, credential)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IWButton>
      </ActionButtonsWrap>
    )
  }

  const formatData = (d?: InboundCredentials[]) => {
    return (
      d?.map((x) => ({
        name: buildTableNameData(x.name, x.clientId),
        createdAt: DateTime.fromISO(x.createdAt, {
          zone: timezone,
        }).toLocaleString(DateTime.DATETIME_SHORT),
        expiresAt: DateTime.fromISO(x.expiresAt, {
          zone: timezone,
        }).toLocaleString(DateTime.DATETIME_SHORT),
        status: t(
          `systemPreferences.integrations.dataAccess.table.${
            x.isActive ? 'activeCredential' : 'expiredCredential'
          }`,
        ),
        actions: buildTableActionButtons(x),
      })) || []
    )
  }
  if (data?.total === 0 && !isLoading) {
    return (
      <IWAlert
        color="grey"
        variant="alternative"
        label={t(
          'systemPreferences.integrations.dataAccess.noDataAccessCredentials',
        )}
      />
    )
  }
  return (
    <div>
      <IWTable
        border
        onSort={onSort}
        sortedBy={sort}
        columns={columns}
        data={formatData(data?.rows)}
      />
      <PaginationWrapper>
        <IWPaginationControls
          perPageLabel={t('tables.rowsPerPage')}
          itemsPerPage={page.size}
          perPageOptions={paginationOptions}
          totalItems={data?.total || 0}
          currentPage={page.current}
          onChangePage={onChangePage}
          onChangeItemsPerPage={onChangeItemsPerPage}
          isLoading={isLoading}
        />
      </PaginationWrapper>
    </div>
  )
}

export default DataAccessTable
