import styled from 'styled-components'
import IWProgressTracker from 'shared/components/thunderbolt/IWProgressTracker'
import IWFormContainer from 'shared/components/thunderbolt/IWFormContainer'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { submitIbtsToISO } from 'shared/loadSchedulingClient'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import LSSelectIBTStep, { Props as LSSelectIBTConfig } from './LSSelectIBTStep'
import LSPreviewIBTStep, { Props as LSPreviewConfig } from './LSPreviewIBTStep'
import { formatToIbtConvertPayload } from '../helpers'
import { useNavigate } from 'react-router'
import LSIBTSubmitSummary, {
  Props as LSIBTSubmitSummaryConfig,
} from './LSIBTSubmitSummary'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 8rem;
`

const FixedNavBar = styled.div`
  position: fixed;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette.primary[50]};
`

type ValueOf<T> = T[keyof T]
type WizardStep<T> = { isStepValid: boolean; value: ValueOf<T>; name: keyof T }
type WizardStepBuilder<T> = { [P in keyof T]: WizardStep<T[P]> }

enum Steps {
  Select,
  Preview,
  Summary,
}

type UnknownSteps = [
  { select: undefined },
  { preview: undefined },
  { summary: undefined },
]

type ImportSteps = [
  { select: LSSelectIBTConfig['state'] },
  { preview: LSPreviewConfig['state'] },
  { summary: LSIBTSubmitSummaryConfig['state'] },
]

type AllSteps = UnknownSteps | ImportSteps

const wizardSteps = (): WizardStepBuilder<AllSteps> => [
  {
    name: 'select',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'preview',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'summary',
    isStepValid: false,
    value: undefined,
  },
]

const LSIBTSubmitWizard = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const wizardStateRef = useRef<WizardStepBuilder<AllSteps>>(wizardSteps())
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [currentStepIsValid, setCurrentStepIsValid] = useState(false)

  const toastOptions = {
    canDismiss: true,
    duration: 10_000,
  }

  const steps = [
    t('loadScheduling.select'),
    t('loadScheduling.preview'),
    t('loadScheduling.summary'),
  ]

  const getNextButtonLabel = () => {
    if (activeStepIndex === Steps.Preview) {
      return t('button.submit')
    }

    if (activeStepIndex === Steps.Summary) {
      return t('button.close')
    }

    return t('button.next')
  }

  const onBack = () => {
    setActiveStepIndex((prevState) => prevState - 1)
  }

  const onNext = async () => {
    let toastId = ''

    try {
      setActiveStepIndex((prevState) =>
        activeStepIndex === Steps.Preview ? prevState : prevState + 1,
      )

      if (activeStepIndex === Steps.Preview) {
        setIsLoading(true)

        toastId = toast.grey(t('loadScheduling.toasts.submitting'), {
          canDismiss: false,
          duration: 60_000,
        })

        const ibts =
          wizardStateRef.current[Steps.Preview].value?.internalBilaterals || []

        const payload = formatToIbtConvertPayload(ibts)
        submitIbtsToISO(payload).then()

        wizardStateRef.current[Steps.Summary].value = {
          summary: payload,
        }

        setActiveStepIndex((prevState) => prevState + 1)
        setIsLoading(false)
      }

      if (activeStepIndex === Steps.Summary) {
        navigate('/tools/load-scheduling/submission-history')
      }

      toast.clearAll()
    } catch (e) {
      console.error(e)
      toast.removeToast(toastId)
      toast.alert(t('errors.generic.processing'), toastOptions)
      setIsLoading(false)
    }
  }

  const getActiveStep = () => {
    switch (activeStepIndex) {
      case Steps.Select:
        return (
          <LSSelectIBTStep
            state={wizardStateRef.current[Steps.Select].value}
            onChange={(value, isStepValid) => {
              wizardStateRef.current[Steps.Select] = {
                ...wizardStateRef.current[Steps.Select],
                isStepValid,
                value,
              }
              setCurrentStepIsValid(isStepValid)
            }}
            showErrors={false}
          />
        )
      case Steps.Preview:
        return (
          <LSPreviewIBTStep
            state={wizardStateRef.current[Steps.Select].value}
            onChange={(value, isStepValid) => {
              wizardStateRef.current[Steps.Preview] = {
                ...wizardStateRef.current[Steps.Preview],
                isStepValid,
                value,
              }
              setCurrentStepIsValid(isStepValid)
            }}
            showErrors={false}
          />
        )
      case Steps.Summary:
        return (
          <LSIBTSubmitSummary
            state={wizardStateRef.current[Steps.Summary].value}
            onChange={(value, isStepValid) => {
              wizardStateRef.current[Steps.Summary] = {
                ...wizardStateRef.current[Steps.Summary],
                isStepValid,
                value,
              }
              setCurrentStepIsValid(isStepValid)
            }}
            showErrors={false}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <StyledDiv id="form-ibt-submit">
        <IWProgressTracker steps={steps} currentActiveIndex={activeStepIndex} />
        <IWFormContainer
          style={{
            marginTop: '1rem',
          }}
        >
          {getActiveStep()}
        </IWFormContainer>
      </StyledDiv>
      <FixedNavBar>
        <IWButton
          variant="outline"
          onClick={onBack}
          data-testid="ibt-wizard-submit-back"
          disabled={activeStepIndex === 0 || isLoading}
        >
          {t('button.back')}
        </IWButton>
        {activeStepIndex === 3 ? (
          ''
        ) : (
          <IWButton
            data-testid="ibt-wizard-submit-next"
            disabled={!currentStepIsValid || isLoading}
            onClick={onNext}
          >
            {getNextButtonLabel()}
          </IWButton>
        )}
      </FixedNavBar>
    </>
  )
}

export default LSIBTSubmitWizard
