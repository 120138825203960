import { useState } from 'react'
import { groupBy } from 'lodash'
import { GenericDropdownValue } from 'shared/components/thunderbolt/IWDropdown'
import {
  addPriceInfo,
  deletePriceInfo,
  getPriceInfo,
  updatePriceInfo,
  type PriceInfo as PriceInfoType,
} from 'shared/loadSchedulingClient'

export type IntervalRange = PriceInfoType & {
  isUnsaved?: boolean
  selectedIntervalStart: GenericDropdownValue<number>
  selectedIntervalEnd: GenericDropdownValue<number>
}

export type PriceInfoGroup = {
  key: string
  market: string
  brand: string
  location: string
  priceInfos: IntervalRange[]
}

const usePriceInfo = () => {
  const [state, setState] = useState<PriceInfoGroup[]>([])

  const setPriceInfos = (data: PriceInfoType[] = []) => {
    const mappedPrices = data.map((price) => ({
      ...price,
      selectedIntervalStart: {
        label: price.intervalStart.toString(),
        value: price.intervalStart,
      },
      selectedIntervalEnd: {
        label: price.intervalEnd.toString(),
        value: price.intervalEnd,
      },
      isUnsaved: false,
    }))

    mappedPrices.sort((a, b) => (a.intervalStart > b.intervalStart ? 1 : -1))
    mappedPrices.sort((a, b) =>
      a.location.localeCompare(b.location, undefined, { sensitivity: 'base' }),
    )

    const grouped = groupBy(
      mappedPrices,
      (v) => `${v.market}-${v.brand}-${v.location}`,
    )

    const groups = Object.entries(grouped).map(([key, value]) => {
      const [market, brand, location] = key.split('-')
      return {
        key,
        market,
        brand,
        location,
        priceInfos: value,
      }
    })

    setState([...groups])
  }

  const onFromIntervalChange = (
    priceInfoId: string,
    groupKey: string,
    selectedOption: GenericDropdownValue<number>,
  ) => {
    const { value: fromInterval } = selectedOption

    const priceInfoGroup = state.find((element) => element.key === groupKey)
    if (!priceInfoGroup) {
      return
    }

    const priceInfo = priceInfoGroup.priceInfos.find(
      (v) => v.priceInfoId === priceInfoId,
    )
    if (!priceInfo) {
      return
    }

    priceInfo.intervalStart = fromInterval
    priceInfo.selectedIntervalStart = selectedOption
    priceInfo.isUnsaved = true

    setState([...state])
  }

  const onToIntervalChange = (
    priceInfoId: string,
    groupKey: string,
    selectedOption: GenericDropdownValue<number>,
  ) => {
    const { value: toInterval } = selectedOption

    const priceInfoGroup = state.find((element) => element.key === groupKey)
    if (!priceInfoGroup) {
      return
    }

    const priceInfo = priceInfoGroup.priceInfos.find(
      (v) => v.priceInfoId === priceInfoId,
    )
    if (!priceInfo) {
      return
    }

    priceInfo.intervalEnd = toInterval
    priceInfo.selectedIntervalEnd = selectedOption
    priceInfo.isUnsaved = true

    setState([...state])
  }

  const onBidPriceChange = (
    priceInfoId: string,
    groupKey: string,
    bidPrice: number = 0,
  ) => {
    const priceInfoGroup = state.find((element) => element.key === groupKey)
    if (!priceInfoGroup) {
      return
    }

    const priceInfo = priceInfoGroup.priceInfos.find(
      (v) => v.priceInfoId === priceInfoId,
    )
    if (!priceInfo) {
      return
    }

    priceInfo.bidPrice = bidPrice
    priceInfo.isUnsaved = true

    setState([...state])
  }

  const onOfferPriceChange = (
    priceInfoId: string,
    groupKey: string,
    offerPrice: number = 0,
  ) => {
    const priceInfoGroup = state.find((element) => element.key === groupKey)
    if (!priceInfoGroup) {
      return
    }

    const priceInfo = priceInfoGroup.priceInfos.find(
      (v) => v.priceInfoId === priceInfoId,
    )
    if (!priceInfo) {
      return
    }

    priceInfo.offerPrice = offerPrice
    priceInfo.isUnsaved = true

    setState([...state])
  }

  const onAdd = async (groupKey: string, priceInfoClicked: IntervalRange) => {
    const {
      priceInfoId,
      org,
      market,
      brand,
      location,
      bidPrice,
      offerPrice,
      intervalEnd,
    } = priceInfoClicked

    if (intervalEnd >= 24) {
      return
    }

    const priceInfoGroup = state.find((element) => element.key === groupKey)
    if (!priceInfoGroup) {
      return
    }

    const priceInfoIndex = priceInfoGroup.priceInfos.findIndex(
      (v) => v.priceInfoId === priceInfoId,
    )
    if (priceInfoIndex === -1) {
      return
    }

    const from = intervalEnd + 1
    const to =
      priceInfoGroup.priceInfos[priceInfoIndex + 1] !== undefined
        ? priceInfoGroup.priceInfos[priceInfoIndex + 1].intervalStart - 1
        : 24

    priceInfoGroup.priceInfos.push({
      priceInfoId: '',
      org,
      market,
      brand,
      location,
      intervalStart: from,
      intervalEnd: to,
      bidPrice,
      offerPrice,
      selectedIntervalStart: {
        label: from.toString(),
        value: from,
      },
      selectedIntervalEnd: {
        label: to.toString(),
        value: to,
      },
      isUnsaved: true,
    })

    priceInfoGroup.priceInfos.sort((a, b) => a.intervalStart - b.intervalStart)

    setState([...state])
  }

  const onRemove = async (
    groupKey: string,
    priceInfoClicked: IntervalRange,
  ) => {
    const { priceInfoId } = priceInfoClicked

    const priceInfoGroup = state.find((element) => element.key === groupKey)
    if (!priceInfoGroup) {
      return
    }

    const priceInfo = priceInfoGroup.priceInfos.find(
      (v) => v.priceInfoId === priceInfoId,
    )
    if (!priceInfo) {
      return
    }

    if (priceInfoGroup.priceInfos.length <= 1) {
      return
    }

    await deletePriceInfo(priceInfoId)

    const index = priceInfoGroup.priceInfos.findIndex(
      (item) => item.priceInfoId === priceInfoId,
    )

    if (index === -1) {
      return
    }

    priceInfoGroup.priceInfos.splice(index, 1)

    setState([...state])
  }

  const onSave = async (groupKey: string, priceInfoToUpdate: IntervalRange) => {
    if (priceInfoToUpdate.priceInfoId) {
      await updatePriceInfo({
        priceInfoId: priceInfoToUpdate.priceInfoId,
        intervalStart: priceInfoToUpdate.intervalStart,
        intervalEnd: priceInfoToUpdate.intervalEnd,
        bidPrice: priceInfoToUpdate.bidPrice,
        offerPrice: priceInfoToUpdate.offerPrice,
      })

      const priceInfoGroup = state.find((element) => element.key === groupKey)
      if (!priceInfoGroup) {
        return
      }

      const priceInfo = priceInfoGroup.priceInfos.find(
        (v) => v.priceInfoId === priceInfoToUpdate.priceInfoId,
      )
      if (!priceInfo) {
        return
      }

      priceInfo.isUnsaved = false

      setState([...state])
      return
    }

    await addPriceInfo({
      market: priceInfoToUpdate.market,
      brand: priceInfoToUpdate.brand,
      location: priceInfoToUpdate.location,
      intervalStart: priceInfoToUpdate.intervalStart,
      intervalEnd: priceInfoToUpdate.intervalEnd,
      bidPrice: priceInfoToUpdate.bidPrice,
      offerPrice: priceInfoToUpdate.offerPrice,
    })

    setPriceInfos(await getPriceInfo())
  }

  const getIntervalOptions = (
    from: number,
    to: number,
    length: number = 24,
  ) => {
    return Array.from({ length }, (_, i) => i + 1)
      .slice(from - 1, to)
      .map((v) => ({ label: v, value: v }))
  }

  return {
    priceInfoGroups: state,
    updatePriceInfoGroups: setPriceInfos,
    getIntervalOptions,
    onFromIntervalChange,
    onToIntervalChange,
    onBidPriceChange,
    onOfferPriceChange,
    onAdd,
    onRemove,
    onSave,
  }
}

export default usePriceInfo
