import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWFormContainer from 'shared/components/thunderbolt/IWFormContainer'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { MainOptions } from 'shared/components/thunderbolt/IWRelativeDatePicker'
import IWRelativeDatePickerWrapper from 'shared/components/thunderbolt/IWRelativeDatePickerWrapper'
import { DateTime } from 'luxon'
import useIWRelativeDatePicker, {
  defaultHasErrorsCalculation,
  defaultIndividualErrorsCalculation,
  DefaultState,
} from 'shared/hooks/useIWRelativeDatePicker'
import { MeasureRangeFilter as MeasureRangeFilterType } from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import { LimitFilter as LimitFilterType } from 'tools/insightsManager/hooks/useLimitFilters'
import { AttributeFilter, WeatherAttributes } from 'tools/insightsManager/types'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import MarketFilter from '../../components/MarketFilter'
import useMarketFilter from '../../hooks/useMarketFilter'
import { WizardStepProps } from '../../../../shared/types'

type SupplyTradesConfigurationState = {
  dateRange?: DefaultState
  attributeFilters?: AttributeFilter[]
  marketFilter: DropdownValueProps[]
  rangeFilters?: MeasureRangeFilterType[]
  limit?: LimitFilterType
  weatherAttributes?: WeatherAttributes
  useForLoadScheduling?: boolean
}

const datePickerMapper = (
  datePickerState: ReturnType<typeof useIWRelativeDatePicker>,
): DefaultState => {
  return {
    selectedSpecificDatePickerDate:
      datePickerState.selectedSpecificDatePickerDate,
    selectedDatePickerMin: datePickerState.selectedDatePickerMin,
    selectedDatePickerMax: datePickerState.selectedDatePickerMax,
    selectedRelativeOption: datePickerState.selectedRelativeOption,
    selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
    timeLengthValue: datePickerState.timeLengthValue,
    selectedMainOption: datePickerState.selectedMainOption,
    disabledMainOptions: datePickerState.disabledMainOptions,
  }
}

export type Props = WizardStepProps<SupplyTradesConfigurationState>

const IWSupplyTradesConfigurationStep = ({
  state,
  onChange,
  showErrors,
}: Props) => {
  const datePickerDisabledMainOptions: MainOptions[] = ['after', 'before']
  const { t } = useTranslation()
  const [isDatePickerOpen, setDatePickerIsOpen] = useState<boolean>(false)
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState<boolean>(false)
  const [datePickerDefaults, setDatePickerDefaults] = useState<DefaultState>(
    state?.dateRange || {
      selectedMainOption: undefined,
      selectedTimeLengthOption: { value: 'days' },
      selectedRelativeOption: { value: 'today' },
      selectedSpecificDatePickerDate: DateTime.local(),
      selectedDatePickerMin: DateTime.local(),
      selectedDatePickerMax: DateTime.local(),
      timeLengthValue: 30,
      disabledMainOptions: datePickerDisabledMainOptions,
    },
  )

  const {
    selectedMarkets,
    availableMarkets,
    updateMarkets,
    hasErrors: hasMarketFiltersErrors,
  } = useMarketFilter(state?.marketFilter || [])

  const datePickerState = useIWRelativeDatePicker({
    ...datePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const handleDatePickerConfirm = () => {
    if (datePickerState.hasErrors) {
      setShouldDisplayErrors(true)
    } else {
      setDatePickerDefaults({
        selectedMainOption: datePickerState.selectedMainOption,
        selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
        selectedRelativeOption: datePickerState.selectedRelativeOption,
        selectedSpecificDatePickerDate:
          datePickerState.selectedSpecificDatePickerDate,
        selectedDatePickerMin: datePickerState.selectedDatePickerMin,
        selectedDatePickerMax: datePickerState.selectedDatePickerMax,
        timeLengthValue: datePickerState.timeLengthValue,
        disabledMainOptions: datePickerDisabledMainOptions,
      })
      setDatePickerIsOpen(false)
      setShouldDisplayErrors(false)
    }
  }

  const handleDatePickerCancel = () => {
    datePickerState.modifyFullState(datePickerDefaults)
    setDatePickerIsOpen(false)
  }

  const stepValidation = () => {
    // valid if date picker has no errors and not open
    const isDateRangeValid = !datePickerState.hasErrors && !isDatePickerOpen
    // step is valid if all section validation === true
    return isDateRangeValid && !hasMarketFiltersErrors
  }

  useEffect(() => {
    if (isDatePickerOpen) {
      // FIXME: This is a hack to ensure the datepicker is visible
      const formDiv = document.getElementById('form-div')
      if (formDiv) {
        formDiv.style.paddingBottom = '25rem'
      }
      return
    }
    const formDiv = document.getElementById('form-div')
    if (formDiv) {
      formDiv.style.paddingBottom = '8rem'
    }
    const stepIsValid = stepValidation()
    const mappedDatePicker = datePickerMapper(datePickerState)
    onChange(
      {
        dateRange: mappedDatePicker,
        marketFilter: selectedMarkets,
      },
      stepIsValid,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDatePickerOpen,
    datePickerState,
    selectedMarkets,
    hasMarketFiltersErrors,
  ])

  return (
    <IWFormContainer>
      <IWFormSection sectionTitle={t(`insightManager.createPod.dateRange`)}>
        <IWRelativeDatePickerWrapper
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datePickerState}
          label={t(
            'insights.riskManagement.metrics.supplyTrades.relativeDatePickerLabel',
          )}
          maxTimeLengthValue={100}
          shouldDisplayErrors={shouldDisplayErrors}
          isOpen={isDatePickerOpen}
          onConfirm={handleDatePickerConfirm}
          onPlaceholderClick={() => setDatePickerIsOpen(true)}
          onCancel={handleDatePickerCancel}
          hasError={showErrors && datePickerState.hasErrors}
        />
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`attributeFilterSection.attributeFilters`)}
        sectionDescription={t(
          `insights.riskManagement.metrics.supplyTrades.attributeFiltersDescription`,
        )}
      >
        <MarketFilter
          showErrors={showErrors}
          availableMarkets={availableMarkets}
          selectedMarkets={selectedMarkets}
          onSetState={updateMarkets}
          hasErrors={hasMarketFiltersErrors}
        />
      </IWFormSection>
    </IWFormContainer>
  )
}
export default IWSupplyTradesConfigurationStep
