import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import IWTypography from './IWTypography'

export interface IWRadioButtonProps {
  /** The form unique id of the input element */
  id: string
  /** Optional label to display and describe the toggle */
  label?: string
  /** Optional description to further describe the toggle */
  description?: string | ReactNode
  /** Boolean to determine if the description is below or inline with the label */
  inline?: boolean
}

type Props = IWRadioButtonProps & React.ComponentPropsWithoutRef<'input'>

const StyledDiv = styled.span<{
  inline?: boolean
}>`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
`

// The odd styling here is intended to hide but not remove from SRs
const StyledHiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledInputLabel = styled.label`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  &:hover > div {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.grey[0]};
  font-size: 0.375rem;
  opacity: 0;
`

const StyledInputDiv = styled.div`
  display: flex;
  margin-top: 0.065rem;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-width: 0.125rem;
  border-style: solid;
  border-radius: 0.5rem;
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.grey[300]};

  ${StyledHiddenInput}[disabled], ${StyledHiddenInput}[disabled]:checked + & {
    border-color: ${(props) => props.theme.palette.grey[300]};
    background-color: ${(props) => props.theme.palette.grey[200]};
  }

  ${StyledHiddenInput}:checked + && ${StyledFontAwesomeIcon} {
    opacity: 1;
  }

  ${StyledHiddenInput}:checked + & {
    border-color: ${(props) => props.theme.palette.primary[700]};
    background-color: ${(props) => props.theme.palette.primary[700]};
  }

  ${StyledHiddenInput}:focus + & {
    outline-offset: 0.125rem;
    outline: ${(props) => `${props.theme.palette.primary[800]} solid 0.125rem`};
  }
`

const StyledDescription = styled(IWTypography)<{
  inline?: boolean
  hasLabel?: boolean
}>`
  margin-left: ${(props) => (props.inline && props.hasLabel ? '0.25rem' : '0')};
  margin-top: ${(props) => (props.inline || !props.hasLabel ? '0' : '0.25rem')};
`

/**
 * This represents a radio control
 * You can provide a 'label', 'description' and if the label and description are 'inline' or not (default not)
 * It also forwards any refs to the underlying input element
 */
const IWRadioButton = React.forwardRef(
  (
    { id, label, description, inline, ...props }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <StyledInputLabel htmlFor={id}>
        <StyledHiddenInput
          data-testid="hidden-radio-input"
          type="radio"
          id={id}
          ref={ref}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
        />
        <StyledInputDiv>
          <StyledFontAwesomeIcon icon={faCircle} />
        </StyledInputDiv>
        <StyledDiv inline={inline}>
          {label && (
            <IWTypography
              data-testid="radio-button-label"
              fontHue={{ color: 'grey', value: 700 }}
              weight="medium"
              as="span"
              size="sm"
            >
              {label}
            </IWTypography>
          )}
          {description && (
            <StyledDescription
              hasLabel={!!label}
              inline={inline}
              as="span"
              size="sm"
              fontHue={{ color: 'grey', value: 500 }}
            >
              {description}
            </StyledDescription>
          )}
        </StyledDiv>
      </StyledInputLabel>
    )
  },
)

export default IWRadioButton
