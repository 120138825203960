import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ErrorImage } from '../../../images/not-supported.svg'
import IWTypography from './IWTypography'

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

/**
 * Simple loading component, displays the loading icon in the center of a white div.
 *
 */
const IWError = () => {
  const { t } = useTranslation()
  return (
    <EmptyDiv data-testid="error-message">
      <ErrorImage />
      <IWTypography weight="bold">
        {t('errors.generalFetchError.title')}
      </IWTypography>
      <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
        {t('errors.generalFetchError.body')}
      </IWTypography>
      <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
        {t('errors.generalFetchError.refresh')}
      </IWTypography>
    </EmptyDiv>
  )
}

export default IWError
