import { DateTime } from 'luxon'
import { DefaultState } from 'shared/hooks/useIWRelativeDatePicker'

const getDatePickerDisplayString = (state: DefaultState, t) => {
  let summaryString = ''

  const {
    selectedMainOption,
    selectedTimeLengthOption,
    selectedRelativeOption,
    timeLengthValue,
    selectedSpecificDatePickerDate,
    selectedDatePickerMax,
    selectedDatePickerMin,
  } = state

  if (!selectedMainOption) {
    return ''
  }

  if (
    selectedMainOption.value === 'previous' ||
    selectedMainOption.value === 'next'
  ) {
    const timeLengthOption = selectedTimeLengthOption?.value
    const relativeOption = selectedRelativeOption?.value
    const translationObj = {
      timeLengthValue,
      timeLengthOption: t(
        `relativeDatePicker.timeLengthOptions.${timeLengthOption}`,
      ),
      relativeOption: t(`relativeDatePicker.relativeOptions.${relativeOption}`),
    }
    if (relativeOption === 'specificDate' && selectedSpecificDatePickerDate) {
      translationObj.relativeOption =
        selectedSpecificDatePickerDate?.toLocaleString(DateTime.DATE_SHORT)
    }
    const str = t(
      `relativeDatePicker.summary.${selectedMainOption.value}`,
      translationObj,
    )
    summaryString = str
  } else if (
    selectedMainOption.value === 'after' ||
    selectedMainOption.value === 'on' ||
    selectedMainOption.value === 'before'
  ) {
    const str = t(`relativeDatePicker.summary.${selectedMainOption.value}`, {
      specificDatePickerDate: selectedSpecificDatePickerDate?.toLocaleString(
        DateTime.DATE_SHORT,
      ),
    })
    summaryString = str
  } else if (selectedMainOption.value === 'between') {
    const str = t(`relativeDatePicker.summary.between`, {
      datePickerMin: selectedDatePickerMin?.toLocaleString(DateTime.DATE_SHORT),
      datePickerMax: selectedDatePickerMax?.toLocaleString(DateTime.DATE_SHORT),
    })
    summaryString = str
  }
  return summaryString
}

export default getDatePickerDisplayString
