import { Outlet } from 'react-router'
import ImportPage from './pages/ImportPage'

const ImportWrapper = () => {
  return <Outlet />
}

export const routes = [
  {
    title: 'Import Manager',
    key: 'import',
    path: '/import-manager',
    main: <ImportWrapper />,
    childRoutes: [
      {
        title: 'Import',
        key: 'main',
        main: <ImportPage />,
        entryPoint: true,
      },
    ],
  },
]
