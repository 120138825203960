import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { IWSmallLoading } from 'shared/components/thunderbolt/IWLoading'

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

interface Props {
  isModalOpen: boolean
  onOneDriveClick: () => void
  onModalCancel: () => void
  isDisabled: boolean
  isLoading: boolean
}

const StyledModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledDescription = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const AddThirdPartyIntegrationsModal = ({
  isModalOpen,
  onOneDriveClick,
  onModalCancel,
  isDisabled,
  isLoading,
}: Props) => {
  const { t } = useTranslation()

  return (
    <IWModal open={isModalOpen}>
      <IWModalHeader>
        <IWTypography as="h1" size="lg" weight="medium">
          Add new integration
        </IWTypography>
      </IWModalHeader>
      <>
        <StyledModalContent>
          <StyledDescription>
            <IWTypography
              as="p"
              size="sm"
              weight="regular"
              fontHue={{ color: 'grey', value: 500 }}
            >
              {t(
                'systemPreferences.integrations.thirdPartyIntegrations.newIntegrationDescription',
              )}
            </IWTypography>
            {isLoading && <IWSmallLoading />}
          </StyledDescription>
          <IWProductCard
            icon={faMicrosoft as IconDefinition}
            iconColor="primary"
            header={t(
              'systemPreferences.integrations.thirdPartyIntegrations.availableIntegrations.oneDrive',
            )}
            isDisabled={isDisabled}
            onClick={onOneDriveClick}
          />
        </StyledModalContent>
        <StyledModalFooter>
          <IWButton
            disabled={isDisabled}
            color="grey"
            variant="outline"
            onClick={onModalCancel}
          >
            {t('button.cancel')}
          </IWButton>
        </StyledModalFooter>
      </>
    </IWModal>
  )
}

export default AddThirdPartyIntegrationsModal
