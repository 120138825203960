import React, { ReactNode, SyntheticEvent, useEffect } from 'react'
import styled from 'styled-components'
import IWBackdrop from './IWBackdrop'

interface Props {
  children: ReactNode
  open: boolean
  onClose?: (e: SyntheticEvent) => void
  size?: 'md' | 'lg'
}

const StyledModalWrapper = styled(IWBackdrop)<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  z-index: ${(props) => props.theme.layers.modal};
  justify-content: center;
  align-items: center;
`

const StyledModal = styled.div<{ size?: 'md' | 'lg' }>`
  background-color: ${(props) => props.theme.palette.grey[0]};
  border-radius: 0.375rem;
  width: ${(props) => (props.size === 'lg' ? '80%' : '32rem')};
`

export const IWModalHeader = styled.div`
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  border-bottom: ${(props) => `1px solid ${props.theme.palette.grey[300]}`};
`

export const IWModalContent = styled.div`
  padding: 1rem 1.5rem;
  // viewport minus header height minus footer height minus the specified 48px "padding"
  max-height: calc(100vh - 3.5rem - 4rem - 6rem);
  overflow-y: auto;
`

export const IWModalFooter = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 1rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
`

export const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
`

/**
 #### Modal conatiner with backdrop.
 - On Backdrop click will close the modal
 - When Modal is open the body content will become unscrollable

 ***NOTE:** `z-index` is set to *1500*.
 Therefore, if a component need to be display above modal,
 the component must have a higher number than `1500`.
 */
const IWModal = ({ open = false, onClose, size = 'md', children }: Props) => {
  useEffect(() => {
    // This prevents scrolling when modal is open
    document.body.style.overflow = open ? 'hidden' : ''
    return () => {
      document.body.style.overflow = ''
    }
  }, [open])

  return (
    <StyledModalWrapper
      isOpen={open}
      onClick={(e) => {
        if (onClose) {
          onClose(e)
        }
      }}
    >
      <StyledModal
        size={size}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </StyledModal>
    </StyledModalWrapper>
  )
}

export default IWModal
