import {
  faFloppyDisk,
  faMinus,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWAccordion from 'shared/components/thunderbolt/IWAccordion'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWDropdown, {
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import {
  getDefaultPriceInfo,
  getPriceInfo,
  updateDefaultPriceInfo,
} from 'shared/loadSchedulingClient'
import styled from 'styled-components'
import usePriceInfo from 'systemPreferences/hooks/usePriceInfo'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
`

const StyledRowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 1rem;

  > * {
    flex: 1;
  }
`

const StyledPlusMinus = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.primary[700]};
`

const StyledQuantityRulesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

const GroupListCard = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.375rem;
`

const GroupListCardHeader = styled.div`
  background-color: ${(props) => props.theme.palette.primary[700]};
  color: ${(props) => props.theme.palette.grey[50]};
  padding: 0.5rem 1rem;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
`

const GroupListCardBody = styled.div`
  padding: 1rem;
`

const PriceInfo = () => {
  const { t } = useTranslation()
  const [defaultPriceId, setDefaultPriceId] = useState<string>('')
  const [defaultBidPrice, setDefaultBidPrice] = useState(10)
  const [defaultOfferPrice, setDefaultOfferPrice] = useState(10)

  const {
    priceInfoGroups,
    updatePriceInfoGroups,
    getIntervalOptions,
    onFromIntervalChange,
    onToIntervalChange,
    onBidPriceChange,
    onOfferPriceChange,
    onAdd,
    onRemove,
    onSave,
  } = usePriceInfo()

  const { data } = useQuery('price-infos', () => {
    return getPriceInfo()
  })

  const { data: defaultPrices } = useQuery('default-price-infos', () => {
    return getDefaultPriceInfo()
  })

  const updateDefaultPrices = async (type: 'bid' | 'offer', price: number) => {
    if (type === 'bid') {
      setDefaultBidPrice(price)
      await updateDefaultPriceInfo(defaultPriceId, {
        bidPrice: price,
        offerPrice: defaultOfferPrice,
      })
    } else {
      setDefaultOfferPrice(price)
      await updateDefaultPriceInfo(defaultPriceId, {
        bidPrice: defaultBidPrice,
        offerPrice: price,
      })
    }
  }

  useEffect(() => {
    if (priceInfoGroups.length === 0) {
      updatePriceInfoGroups(data)
    }
  }, [data])

  useEffect(() => {
    if (defaultPrices && defaultPrices.length > 0) {
      const currentDefaultPrice = defaultPrices.find(
        ({ market }) => market === 'ERCOT',
      )
      if (currentDefaultPrice === undefined) {
        return
      }

      setDefaultPriceId(currentDefaultPrice.priceInfoId)
      setDefaultBidPrice(Number(currentDefaultPrice.bidPrice))
      setDefaultOfferPrice(Number(currentDefaultPrice.offerPrice))
    }
  }, [defaultPrices])

  if (!data) {
    return <></>
  }

  return (
    <IWAccordion
      title={t('loadScheduling.settings.priceInfoTitle')}
      desc={t('loadScheduling.settings.priceInfoDesc')}
      shouldStartClose
    >
      <StyledCardWrapper style={{ display: 'flex', flexDirection: 'column' }}>
        <GroupListCard>
          <GroupListCardHeader>
            <IWTypography
              fontHue={{
                color: 'primary',
                value: 50,
              }}
            >
              {t('loadScheduling.settings.defaultPriceInfoCommonLabel')}
            </IWTypography>
          </GroupListCardHeader>
          <GroupListCardBody>
            <StyledQuantityRulesWrapper>
              <IWTextInput
                name="bidRoundUp"
                label={t('loadScheduling.settings.bidPrice')}
                placeholder={t('placeholders.enterValue')}
                type="number"
                min="1"
                value={defaultBidPrice}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const targetValue = Number(e.target.value || '0')
                  const value = targetValue < 0 ? 0 : targetValue

                  updateDefaultPrices('bid', value)
                }}
              />

              <IWTextInput
                name="bidRoundUp"
                label={t('loadScheduling.settings.offerPrice')}
                placeholder={t('placeholders.enterValue')}
                type="number"
                min="1"
                value={defaultOfferPrice}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const targetValue = Number(e.target.value || '0')
                  const value = targetValue < 0 ? 0 : targetValue

                  updateDefaultPrices('offer', value)
                }}
              />
            </StyledQuantityRulesWrapper>
          </GroupListCardBody>
        </GroupListCard>

        {Boolean(priceInfoGroups.length) &&
          priceInfoGroups.map((priceInfoGroup) => (
            <GroupListCard key={priceInfoGroup.key}>
              <GroupListCardHeader>
                <IWTypography
                  fontHue={{
                    color: 'primary',
                    value: 50,
                  }}
                >
                  {`${priceInfoGroup.market} - ${priceInfoGroup.brand} - ${priceInfoGroup.location}`}
                </IWTypography>
              </GroupListCardHeader>

              <GroupListCardBody>
                {priceInfoGroup.priceInfos.map((priceInfo, index) => (
                  <StyledRowWrapper key={priceInfo.priceInfoId}>
                    <IWDropdown
                      name={`from-interval-${priceInfo.priceInfoId}`}
                      label={t('loadScheduling.settings.fromInterval')}
                      placeholder={t(`placeholders.select`)}
                      value={priceInfo.selectedIntervalStart}
                      options={getIntervalOptions(
                        index === 0
                          ? 1
                          : priceInfoGroup.priceInfos[index - 1].intervalEnd +
                              1,
                        priceInfo.intervalEnd,
                      )}
                      onChange={(value: GenericDropdownValue<number>) =>
                        onFromIntervalChange(
                          priceInfo.priceInfoId,
                          priceInfoGroup.key,
                          value,
                        )
                      }
                    />

                    <IWDropdown
                      name={`to-interval-${priceInfo.priceInfoId}`}
                      label={t('loadScheduling.settings.toInterval')}
                      value={priceInfo.selectedIntervalEnd}
                      placeholder={t(`placeholders.select`)}
                      options={getIntervalOptions(
                        priceInfo.intervalStart,
                        priceInfoGroup.priceInfos[index + 1] !== undefined
                          ? priceInfoGroup.priceInfos[index + 1].intervalStart -
                              1
                          : 24,
                      )}
                      onChange={(value: GenericDropdownValue<number>) =>
                        onToIntervalChange(
                          priceInfo.priceInfoId,
                          priceInfoGroup.key,
                          value,
                        )
                      }
                    />

                    <IWTextInput
                      name={`bid-price-${priceInfo.priceInfoId}`}
                      label={t('loadScheduling.settings.bidPrice')}
                      placeholder={t('placeholders.select')}
                      type="number"
                      min="1"
                      value={priceInfo.bidPrice}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBidPriceChange(
                          priceInfo.priceInfoId,
                          priceInfoGroup.key,
                          Number(e.target.value ?? 0),
                        )
                      }}
                    />

                    <IWTextInput
                      name={`offer-price-${priceInfo.priceInfoId}`}
                      label={t('loadScheduling.settings.offerPrice')}
                      placeholder={t('placeholders.select')}
                      type="number"
                      min="1"
                      value={priceInfo.offerPrice}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onOfferPriceChange(
                          priceInfo.priceInfoId,
                          priceInfoGroup.key,
                          Number(e.target.value ?? 0),
                        )
                      }}
                    />

                    <StyledPlusMinus>
                      <IWButtonGroup>
                        <IWButton
                          disabled={
                            priceInfoGroup.priceInfos.length <= 1 ||
                            Boolean(priceInfo?.isUnsaved)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faMinus}
                            onClick={() =>
                              onRemove(priceInfoGroup.key, priceInfo)
                            }
                          />
                        </IWButton>

                        {!Boolean(priceInfo?.isUnsaved) ? (
                          <IWButton
                            disabled={
                              priceInfoGroup.priceInfos.length >= 24 ||
                              priceInfo.intervalEnd === 24 ||
                              (priceInfoGroup.priceInfos[index + 1] !==
                                undefined &&
                                priceInfoGroup.priceInfos[index + 1]
                                  .intervalStart ===
                                  priceInfo.intervalEnd + 1)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              onClick={() =>
                                onAdd(priceInfoGroup.key, priceInfo)
                              }
                            />
                          </IWButton>
                        ) : (
                          <IWButton variant="alternative" color="primary">
                            <StyledFontAwesomeIcon
                              icon={faFloppyDisk}
                              onClick={() =>
                                onSave(priceInfoGroup.key, priceInfo)
                              }
                            />
                          </IWButton>
                        )}
                      </IWButtonGroup>
                    </StyledPlusMinus>
                  </StyledRowWrapper>
                ))}
              </GroupListCardBody>
            </GroupListCard>
          ))}
      </StyledCardWrapper>
    </IWAccordion>
  )
}

export default PriceInfo
