/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import styled from 'styled-components'
import useThirdPartySignUpPopup from 'systemPreferences/hooks/useThirdPartySignInPopup'
import {
  deleteThirdPartyIntegration,
  getThirdPartyIntegrations,
  oneDriveRedirect,
  oneDriveToken,
  ThirdPartyIntegration,
} from 'systemPreferences/integrationsServiceClient'
import IntegrationsSection from 'systemPreferences/pages/IntegrationsSection'
import ThidPartyIntegrationsDeleteModal from './ThirdPartyIntegrationsDeleteModal'
import AddThirdPartyIntegrationsModal from './ThirdPartyIntegrationsModal'
import ThirdPartyIntegrationsTable from './ThirdPartyIntegrationsTable'

const TableWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.375rem;
  padding: 1rem;
`

const ThirdPartyIntegrationsSection = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedThirdPartyIntegration, setSelectedThirdPartyIntegration] =
    useState<ThirdPartyIntegration | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()
  const { storedIntegration, setUrl, cleanUp } = useThirdPartySignUpPopup([
    'oneDrive',
  ])

  const handleAddThirdPartyIntegrations = () => {
    setIsModalOpen(true)
  }

  const { data: thirdPartyIntegrations, refetch } = useQuery(
    ['thirdPartyIntegrations'],
    () => {
      return getThirdPartyIntegrations()
    },
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (
      storedIntegration?.integration === 'oneDrive' &&
      storedIntegration.code !== undefined
    ) {
      setIsLoading(true)
      oneDriveToken(storedIntegration.code)
        .then(() => {
          refetch()
          setIsModalOpen(false)
        })
        .catch(() => {
          toast.alert(
            t(
              'systemPreferences.integrations.thirdPartyIntegrations.newIntegrationFailure',
            ),
            { canDismiss: true },
          )
        })
        .finally(() => {
          setIsLoading(false)
          cleanUp()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedIntegration])

  const handleOneDriveClick = () => {
    oneDriveRedirect().then((r) => {
      setUrl(r.url)
    })
  }

  const handelCancelDelete = () => {
    setIsDeleteModalOpen(false)
    setSelectedThirdPartyIntegration(undefined)
  }

  const handleIntegrationDeleteTableButton = (
    e: SyntheticEvent,
    integration: ThirdPartyIntegration,
  ) => {
    setIsDeleteModalOpen(true)
    setSelectedThirdPartyIntegration(JSON.parse(JSON.stringify(integration)))
  }

  const handleIntegrationDelete = async (
    e: SyntheticEvent,
    integrationId: string,
  ) => {
    if (selectedThirdPartyIntegration === undefined) {
      return
    }
    try {
      setIsLoading(true)
      await deleteThirdPartyIntegration(integrationId)
      toast.secondary(
        t(
          'systemPreferences.integrations.thirdPartyIntegrations.deleteSuccess',
        ),
        { canDismiss: true },
      )
      refetch()
      setIsDeleteModalOpen(false)
      setSelectedThirdPartyIntegration(undefined)
    } catch (error) {
      toast.alert(
        t('systemPreferences.integrations.dataAccess.deleteCredentialsFailure'),
        { canDismiss: true },
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <IntegrationsSection
        header={t(
          'systemPreferences.integrations.thirdPartyIntegrations.header',
        )}
        buttonLabel={t(
          'systemPreferences.integrations.thirdPartyIntegrations.addNewIntegrationButton',
        )}
        onButtonClick={handleAddThirdPartyIntegrations}
      >
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t(
            'systemPreferences.integrations.thirdPartyIntegrations.description',
          )}
        </IWTypography>
        <TableWrapper>
          <ThirdPartyIntegrationsTable
            data={thirdPartyIntegrations || []}
            onDelete={handleIntegrationDeleteTableButton}
          />
        </TableWrapper>
      </IntegrationsSection>
      <AddThirdPartyIntegrationsModal
        isModalOpen={isModalOpen}
        onModalCancel={handleModalCancel}
        onOneDriveClick={handleOneDriveClick}
        isDisabled={isLoading}
        isLoading={isLoading}
      />
      {selectedThirdPartyIntegration && (
        <ThidPartyIntegrationsDeleteModal
          isModalOpen={isDeleteModalOpen}
          credentialId={selectedThirdPartyIntegration.credentialId}
          thirdPartyDriveId={selectedThirdPartyIntegration.thirdPartyDriveId}
          isCancelButtonDisabled={isLoading}
          isDeleteButtonDisabled={isLoading}
          onCancel={handelCancelDelete}
          onDelete={handleIntegrationDelete}
        />
      )}
    </>
  )
}

export default ThirdPartyIntegrationsSection
