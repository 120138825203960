import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { MainOptions } from 'shared/components/thunderbolt/IWRelativeDatePicker'
import IWRelativeDatePickerWrapper from 'shared/components/thunderbolt/IWRelativeDatePickerWrapper'
import { DateTime } from 'luxon'
import useIWRelativeDatePicker, {
  defaultHasErrorsCalculation,
  defaultIndividualErrorsCalculation,
  DefaultState,
} from 'shared/hooks/useIWRelativeDatePicker'
import { MeasureRangeFilter as MeasureRangeFilterType } from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import { LimitFilter as LimitFilterType } from 'tools/insightsManager/hooks/useLimitFilters'
import { AttributeFilter, WeatherAttributes } from 'tools/insightsManager/types'
import {
  DropdownValueProps,
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import { WizardStepProps } from 'shared/types'

type MeterConfigurationState = {
  dateRange?: DefaultState
  marketFilter: DropdownValueProps[]
  attributeFilters?: AttributeFilter[]
  rangeFilters?: MeasureRangeFilterType[]
  limit?: LimitFilterType
  weatherAttributes?: WeatherAttributes
  useForLoadScheduling?: boolean
}

export const metricMarketMap = Object.freeze({
  systemERCOT: 'ercot',
  systemPJM: 'pjm',
})

const datePickerMapper = (
  datePickerState: ReturnType<typeof useIWRelativeDatePicker>,
): DefaultState => {
  return {
    selectedSpecificDatePickerDate:
      datePickerState.selectedSpecificDatePickerDate,
    selectedDatePickerMin: datePickerState.selectedDatePickerMin,
    selectedDatePickerMax: datePickerState.selectedDatePickerMax,
    selectedRelativeOption: datePickerState.selectedRelativeOption,
    selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
    timeLengthValue: datePickerState.timeLengthValue,
    selectedMainOption: datePickerState.selectedMainOption,
    disabledMainOptions: datePickerState.disabledMainOptions,
  }
}

export type Props = WizardStepProps<MeterConfigurationState>

const IWCreateConfigurationStep = ({
  market: selectedMarket,
  state,
  onChange,
  showErrors,
}: { market: 'ercot' | 'pjm' } & Props) => {
  const datePickerDisabledMainOptions: MainOptions[] = ['after', 'before']
  const { t } = useTranslation()
  const [isDatePickerOpen, setDatePickerIsOpen] = useState<boolean>(false)
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState<boolean>(false)
  const [datePickerDefaults, setDatePickerDefaults] = useState<DefaultState>(
    state?.dateRange || {
      selectedMainOption: undefined,
      selectedTimeLengthOption: { value: 'days' },
      selectedRelativeOption: { value: 'today' },
      selectedSpecificDatePickerDate: DateTime.local(),
      selectedDatePickerMin: DateTime.local(),
      selectedDatePickerMax: DateTime.local(),
      timeLengthValue: 30,
      disabledMainOptions: datePickerDisabledMainOptions,
    },
  )

  const datePickerState = useIWRelativeDatePicker({
    ...datePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const handleDatePickerConfirm = () => {
    if (datePickerState.hasErrors) {
      setShouldDisplayErrors(true)
    } else {
      setDatePickerDefaults({
        selectedMainOption: datePickerState.selectedMainOption,
        selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
        selectedRelativeOption: datePickerState.selectedRelativeOption,
        selectedSpecificDatePickerDate:
          datePickerState.selectedSpecificDatePickerDate,
        selectedDatePickerMin: datePickerState.selectedDatePickerMin,
        selectedDatePickerMax: datePickerState.selectedDatePickerMax,
        timeLengthValue: datePickerState.timeLengthValue,
        disabledMainOptions: datePickerDisabledMainOptions,
      })
      setDatePickerIsOpen(false)
      setShouldDisplayErrors(false)
    }
  }

  const handleDatePickerCancel = () => {
    datePickerState.modifyFullState(datePickerDefaults)
    setDatePickerIsOpen(false)
  }

  const stepValidation = (): boolean => {
    return !datePickerState.hasErrors && !isDatePickerOpen
  }

  useEffect(() => {
    if (isDatePickerOpen) {
      return
    }
    const stepIsValid = stepValidation()
    const mappedDatePicker = datePickerMapper(datePickerState)
    onChange(
      {
        dateRange: mappedDatePicker,
        marketFilter: [
          { value: selectedMarket, label: selectedMarket },
        ] as GenericDropdownValue<'ercot' | 'pjm'>[],
      },
      stepIsValid,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatePickerOpen, datePickerState])

  return (
    <IWFormSection sectionTitle={t(`insightManager.createPod.dateRange`)}>
      <IWRelativeDatePickerWrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...datePickerState}
        label={t('insights.shortTermForecastSystem.relativeDatePickerLabel')}
        maxTimeLengthValue={100}
        shouldDisplayErrors={shouldDisplayErrors}
        isOpen={isDatePickerOpen}
        onConfirm={handleDatePickerConfirm}
        onPlaceholderClick={() => setDatePickerIsOpen(true)}
        onCancel={handleDatePickerCancel}
        hasError={showErrors && datePickerState.hasErrors}
      />
    </IWFormSection>
  )
}
export default IWCreateConfigurationStep
