import React, { ReactNode, useContext } from 'react'
import styled from 'styled-components'
import IWInputLabel from 'shared/components/thunderbolt/IWCommonLabels'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { useTranslation } from 'react-i18next'

import getInsights from 'tools/insightsManager/utils/getInsights'
import {
  getActualUsageMetrics,
  getLongTermForecastMetrics,
  getRiskManagementMetrics,
  getShortTermForecastMetrics,
  getShortTermForecastPerformanceMetrics,
  getShortTermForecastPerformanceSystemMetrics,
  getShortTermForecastPerformanceTopDownMetrics,
  getShortTermForecastSystemMetrics,
} from 'tools/insightsManager/utils/getMetrics'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import { DateTime } from 'luxon'
import parseCronString from 'shared/utils/parseCronString'
import { Pod } from '../types'
import UserContext from 'shared/contexts/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import useCopyText from 'shared/hooks/useCopyText'
import LayoutContext from 'shared/contexts/LayoutContext'

const MultipleAttributeSection = styled.div`
  width: 100%;
  border-radius: 0.375rem;
  padding: 1rem;
  background: ${(props) => props.theme.palette.grey[50]};
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
`

const SingleRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const SingleRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SingleValueDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  gap: 0.5rem;
  text-transform: capitalize;
`

const StyledGeneralSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`

const StyledSmallFormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledPodIdTitle = styled.div`
  display: flex;
  gap: 0.5rem;
`

const StyledFontAwesomeCopy = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${(props) => `${props.theme.palette.primary[700]}`};
`

const GeneralTypography = ({ children }: { children: ReactNode }) => {
  return (
    <IWTypography
      size="sm"
      weight="regular"
      fontHue={{ color: 'grey', value: 600 }}
    >
      {children}
    </IWTypography>
  )
}

const getDatePickerDisplayString = (
  relativeDateFilter,
  t,
  timezone = 'UTC',
) => {
  let summaryString = ''

  const {
    main,
    timeLength,
    relative,
    timeLengthValue,
    specificDate,
    datePickerMax,
    datePickerMin,
  } = relativeDateFilter

  if (!main) {
    return ''
  }

  if (main === 'previous' || main === 'next') {
    const timeLengthOption = timeLength
    const relativeOption = relative
    const translationObj = {
      timeLengthValue,
      timeLengthOption: t(
        `relativeDatePicker.timeLengthOptions.${timeLengthOption}`,
      ),
      relativeOption: t(`relativeDatePicker.relativeOptions.${relativeOption}`),
    }
    if (relativeOption === 'specificDate' && specificDate) {
      translationObj.relativeOption = DateTime.fromISO(specificDate, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT)
    }
    summaryString = t(`relativeDatePicker.summary.${main}`, translationObj)
  } else if (main === 'after' || main === 'on' || main === 'before') {
    summaryString = t(`relativeDatePicker.summary.${main}`, {
      specificDatePickerDate: DateTime.fromISO(specificDate, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT),
    })
  } else if (main === 'between') {
    summaryString = t(`relativeDatePicker.summary.between`, {
      datePickerMin: DateTime.fromISO(datePickerMin, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT),
      datePickerMax: DateTime.fromISO(datePickerMax, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT),
    })
  }
  return summaryString
}

/**
 *
 * This is a summary component to show a summary of the pod details
 *
 */
const PodDetailsSummary = ({ pod }: { pod: Pod }) => {
  const { availablePodTypesMap } = useContext(UserContext)
  const { t, i18n } = useTranslation()
  const { copyText } = useCopyText()
  const { timezone } = useContext(LayoutContext)

  const conditionOperators = {
    '>': t(`rangeFilterSection.options.greaterThan`),
    '>=': t(`rangeFilterSection.options.greaterThanOrEqualTo`),
    '<': t(`rangeFilterSection.options.lessThan`),
    '<=': t(`rangeFilterSection.options.lessThanOrEqualTo`),
  }

  // TODO: This is too convoluted... we need a better and smoother way of finding these labels...
  const metricString = [
    ...getShortTermForecastMetrics(t, availablePodTypesMap.shortTermForecast),
    ...getLongTermForecastMetrics(t, availablePodTypesMap.longTermForecast),
    ...getShortTermForecastPerformanceMetrics(
      t,
      availablePodTypesMap.shortTermForecastPerformance,
    ),
    ...getShortTermForecastPerformanceTopDownMetrics(
      t,
      availablePodTypesMap.shortTermForecastPerformanceTopDown,
    ),
    ...getShortTermForecastSystemMetrics(
      t,
      availablePodTypesMap.shortTermForecastSystem,
    ),
    ...getRiskManagementMetrics(t, availablePodTypesMap.riskManagement),
    ...getActualUsageMetrics(t, availablePodTypesMap.actualUsage),
    ...getShortTermForecastPerformanceSystemMetrics(
      t,
      availablePodTypesMap.shortTermForecastPerformanceSystem,
    ),
  ].find((m) => m.value === pod.podType.metricId)?.label

  const dateConfigString = getDatePickerDisplayString(
    pod.relativeDateFilter,
    t,
    timezone,
  )

  const cronFinalGeneratedString = pod.cronExpression
    ? parseCronString(pod.cronExpression, i18n.language).interval
    : t('insightManager.pods.interval.default')

  const defaultInsights = getInsights(t)

  const insightString = defaultInsights.find(
    (insight) => insight.type === pod.podType.insightId,
  )?.label

  const retentionPeriodString = t(
    `insightManager.podDetails.summary.retentionDuration.${pod.dataRetentionDuration}`,
  )

  const eventBasedString = pod?.eventBased
    ? t(`insightManager.podDetails.summary.eventBased.yesEventBased`)
    : t(`insightManager.podDetails.summary.eventBased.notEventBased`)

  const useForLoadSchedulingString = pod?.useForLoadScheduling
    ? t(
        `insightManager.podDetails.summary.useForLoadScheduling.yesUseForLoadScheduling`,
      )
    : t(
        `insightManager.podDetails.summary.useForLoadScheduling.notUseForLoadScheduling`,
      )

  return (
    <StyledDiv>
      <StyledSmallFormSection>
        <StyledPodIdTitle>
          <IWTypography
            size="sm"
            weight="medium"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {t(`insightManager.podDetails.summary.innoPodId`)}
          </IWTypography>
          <StyledFontAwesomeCopy
            onClick={() => copyText(pod.userPodId)}
            icon={faCopy}
          />
        </StyledPodIdTitle>
        <GeneralTypography>{pod.userPodId}</GeneralTypography>
      </StyledSmallFormSection>
      <IWFormSection
        sectionTitle={t(`insightManager.podDetails.summary.insight`)}
      >
        <GeneralTypography>{insightString}</GeneralTypography>
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(`insightManager.podDetails.summary.metric`)}
      >
        <GeneralTypography>{metricString}</GeneralTypography>
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(`insightManager.podDetails.summary.dateRange`)}
      >
        <GeneralTypography>{dateConfigString}</GeneralTypography>
      </IWFormSection>
      <>
        {Boolean(pod.weatherAttributes) && (
          <IWFormSection sectionTitle={t('weatherAttribute.formHeader')}>
            <SingleValueDisplay>
              <IWInputLabel
                label={t('weatherAttribute.weatherStationCount.label')}
              />
              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 600 }}
              >
                {pod.weatherAttributes?.weatherStationCount}
              </IWTypography>
            </SingleValueDisplay>
            <SingleValueDisplay>
              <IWInputLabel label={t('weatherAttribute.aggregationFn.label')} />
              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 600 }}
              >
                {pod.weatherAttributes?.aggregationFnId}
              </IWTypography>
            </SingleValueDisplay>
          </IWFormSection>
        )}
        {Boolean(pod.markets?.length) && (
          <IWFormSection
            sectionTitle={t('insightManager.podDetails.summary.markets')}
          >
            <GeneralTypography>{pod.markets.join(', ')}</GeneralTypography>
          </IWFormSection>
        )}
        {Boolean(pod.attributeFilters?.length) && (
          <IWFormSection
            sectionTitle={t(
              `insightManager.podDetails.summary.attributeFilters`,
            )}
          >
            {pod.attributeFilters &&
              pod.attributeFilters.map(({ attribute, includes, values }) => (
                <MultipleAttributeSection key={`${attribute}`}>
                  <SingleRow>
                    <SingleValueDisplay>
                      <IWInputLabel
                        label={t(`attributeFilterSection.attribute`)}
                      />
                      <GeneralTypography>{attribute}</GeneralTypography>
                    </SingleValueDisplay>
                    <SingleValueDisplay>
                      <IWInputLabel
                        label={t(`insightManager.podDetails.summary.condition`)}
                      />
                      <GeneralTypography>
                        {includes
                          ? t('attributeFilterSection.conditionsEqual')
                          : t('attributeFilterSection.conditionsNotEqual')}
                      </GeneralTypography>
                    </SingleValueDisplay>

                    <SingleValueDisplay>
                      <IWInputLabel label={t(`common.value`)} />
                      <IWBadgeGroup>
                        {values.map((val) => (
                          <IWGeneralBadge key={val} label={val} color="grey" />
                        ))}
                      </IWBadgeGroup>
                    </SingleValueDisplay>
                  </SingleRow>
                </MultipleAttributeSection>
              ))}
          </IWFormSection>
        )}
      </>
      <>
        {Boolean(pod.rangeFilters?.length) && (
          <>
            <IWFormSection sectionTitle={t(`rangeFilterSection.rangeFilters`)}>
              {pod.rangeFilters &&
                pod.rangeFilters.map((filter) => (
                  <MultipleAttributeSection key={filter.attribute}>
                    <SingleRowContainer>
                      <SingleRow>
                        <SingleValueDisplay>
                          <IWInputLabel
                            label={t(`rangeFilterSection.measureToFilter`)}
                          />
                          <GeneralTypography>
                            {filter.attribute}
                          </GeneralTypography>
                        </SingleValueDisplay>
                      </SingleRow>
                      {filter.conditions.map((condition) => {
                        return (
                          <SingleRow>
                            <SingleValueDisplay>
                              <IWInputLabel
                                label={t(`rangeFilterSection.condition`)}
                              />
                              <GeneralTypography>
                                {conditionOperators[condition.operator]}
                              </GeneralTypography>
                            </SingleValueDisplay>

                            <SingleValueDisplay>
                              <IWInputLabel
                                label={t(`rangeFilterSection.minValue`)}
                              />
                              <GeneralTypography>
                                {condition.value}
                              </GeneralTypography>
                            </SingleValueDisplay>
                          </SingleRow>
                        )
                      })}
                    </SingleRowContainer>
                  </MultipleAttributeSection>
                ))}
            </IWFormSection>
          </>
        )}
      </>
      <>
        {pod?.podLimit && (
          <IWFormSection sectionTitle={t('limitFilterSection.limitFilter')}>
            <MultipleAttributeSection>
              <SingleRow>
                <SingleValueDisplay>
                  <IWInputLabel
                    label={t('limitFilterSection.measureToLimit')}
                  />
                  <GeneralTypography>{pod.podLimit.measure}</GeneralTypography>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel label={t('limitFilterSection.edge')} />
                  <GeneralTypography>{pod.podLimit.edge}</GeneralTypography>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel label={t('limitFilterSection.type')} />
                  <GeneralTypography>{pod.podLimit.type}</GeneralTypography>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel label={t('limitFilterSection.value')} />

                  <GeneralTypography>{pod.podLimit.value}</GeneralTypography>
                </SingleValueDisplay>
              </SingleRow>
            </MultipleAttributeSection>
          </IWFormSection>
        )}
      </>
      <IWFormSection
        sectionTitle={t('insightManager.podDetails.summary.general')}
      >
        <StyledGeneralSection>
          <SingleValueDisplay>
            <IWInputLabel
              label={t('insightManager.podDetails.summary.podNameLabel')}
            />
            <GeneralTypography>{pod.userPodName}</GeneralTypography>
          </SingleValueDisplay>

          <SingleValueDisplay>
            <IWInputLabel
              label={t('insightManager.podDetails.summary.eventBased.label')}
            />
            <GeneralTypography>{eventBasedString}</GeneralTypography>
          </SingleValueDisplay>

          <SingleValueDisplay>
            <IWInputLabel
              label={t(
                'insightManager.podDetails.summary.useForLoadScheduling.label',
              )}
            />
            <GeneralTypography>{useForLoadSchedulingString}</GeneralTypography>
          </SingleValueDisplay>

          <SingleValueDisplay>
            <IWInputLabel label={t('cronJobScheduleInput.label')} />
            <GeneralTypography>{cronFinalGeneratedString}</GeneralTypography>
          </SingleValueDisplay>
          <SingleValueDisplay>
            <IWInputLabel
              label={t(
                'insightManager.podDetails.summary.retentionPeriodLabel',
              )}
            />
            <GeneralTypography>
              {pod.dataRetentionDuration
                ? retentionPeriodString
                : t(
                    'insightManager.podDetails.summary.retentionDuration.indefinitely',
                  )}
            </GeneralTypography>
          </SingleValueDisplay>
        </StyledGeneralSection>
      </IWFormSection>
    </StyledDiv>
  )
}

export default PodDetailsSummary
