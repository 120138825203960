import { useEffect, useState } from 'react'

type UseLSTableWithPaginationHook<T> = {
  pageNumber: number
  itemsPerPage: number
  itemsInView: T[]
  setTablePageNumber: (pageNumber: number) => void
  setTableItemsPerPage: (itemsPerPage: number) => void
  setItemsInView: (items: T[]) => void
  setItems: (items: T[]) => void
}

export function UseLSTableWithPagination<T>(
  data: T[] = [],
): UseLSTableWithPaginationHook<T> {
  const [currentItems, setCurrentItems] = useState<T[]>(data)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(10)
  const [currentItemsInView, setCurrentItemsInView] = useState<T[]>([])

  function getCurrentSlice(arr) {
    return arr.slice(
      (currentPage - 1) * currentItemsPerPage,
      currentPage * currentItemsPerPage,
    )
  }

  function setItemsInView(items: T[]) {
    setCurrentItemsInView(getCurrentSlice(items))
  }

  function setTablePageNumber(pageNumber: number) {
    setCurrentPage(pageNumber)
  }

  function setTableItemsPerPage(itemsPerPage: number) {
    setCurrentItemsPerPage(itemsPerPage)
  }

  function setItems(items: T[]) {
    setCurrentItems(items)
    setCurrentPage(1)
    setCurrentItemsPerPage(10)
    setItemsInView(items)
  }

  useEffect(() => {
    setItemsInView(currentItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentItemsPerPage])

  return {
    pageNumber: currentPage,
    itemsPerPage: currentItemsPerPage,
    itemsInView: currentItemsInView,
    setTableItemsPerPage,
    setTablePageNumber,
    setItemsInView,
    setItems,
  }
}
