import React from 'react'
import styled from 'styled-components'
import IWAlert, { IWAlertProps } from './IWAlert'

type Props = {
  /**
   * Array of Toast objects to render
   */
  toasts: IWToastProps[]
}

export interface IWToastProps extends IWAlertProps {
  /**
   * Id of the toast, used as a key and for removal purposes
   */
  id: string
}

const StyledContainer = styled.div<{}>`
  width: 25rem;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: ${(props) => props.theme.layers.popover};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
`

/**
 * Displays a list of toasts in the top right of the screen, fixed.
 * this is controller by the ToastContext
 */
const IWToastList = ({ toasts }: Props) => {
  return (
    <StyledContainer>
      {toasts &&
        toasts.map((toast: IWToastProps) => {
          const { id, ...rest } = toast
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <IWAlert key={id} {...rest} />
        })}
    </StyledContainer>
  )
}

export default IWToastList
