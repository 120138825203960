import {
  AlertCompatiblePod,
  CreatePodSchema,
  Pod,
  PodListSummary,
  PodStatus,
  PodType,
  SharingRole,
  UserSharingRole,
} from 'tools/insightsManager/types'
import { DateTime } from 'luxon'
import parseCronString from './utils/parseCronString'
import axiosApiClient from './apiClient'
import { SortDirection } from './components/thunderbolt/IWTable'
import type {
  AlertConfig,
  AlertCreateSchema,
  AlertSummary,
  AlertTrigger,
  AlertTriggerSummary,
} from '../tools/alertsManager/types'
import { PaginatedData } from './types'
import i18n from '../i18n'
import { ImportManagerSchema } from 'tools/importManager/types'

const getStatus = (rawPod: PodListSummary): PodStatus => {
  /**
   * Regardless of the current status if the current success is false we know all will fail.
   */
  if (rawPod?.currentSuccess === false) {
    return 'failed'
  }
  if (!rawPod.hasInitialized) {
    return 'initializing'
  }
  if (
    rawPod.currentStatus === 'runningGeneration' ||
    rawPod.currentStatus === 'completedGeneration'
  ) {
    return 'running'
  }
  if (rawPod.currentStatus === 'completedIngestion') {
    return rawPod.summary?.wasSuccessful ? 'complete' : 'failed'
  }
  return 'ready'
}

const processPod = (pod: PodListSummary): Pod => {
  const { language } = i18n
  const extras = {
    lastRun: pod?.summary?.logTimestamp || undefined,
    currentStatus: getStatus(pod),
  }
  if (!pod.cronExpression) {
    return { ...pod, ...extras }
  }
  const { nextRun, interval } = parseCronString(pod.cronExpression, language)
  return {
    ...pod,
    interval,
    nextRun,
    ...extras,
  }
}

/**
 * Gets a list of pod metadata object from the server and processed them
 */
export const getPodList = async (
  page: number = 0,
  pageSize: number = 5,
  searchValue: string = '',
  selectedInsight: PodType['insightId'][] = [],
): Promise<{ total: number; rows: Pod[] }> => {
  const filterString = new URLSearchParams({
    skip: page.toString(),
    limit: pageSize.toString(),
    sortDirection: 'desc',
    sortColumn: 'created_at',
    searchValue,
    selectedInsight: selectedInsight.join('-').toString(),
  }).toString()

  /**
   * Incoming pod items contain a CRON expression string which we use to infer the next run date
   */
  const processItems = (rawItems: PodListSummary[]): Pod[] => {
    return rawItems.map(processPod)
  }

  const url = `/pods/v1/pods?${filterString}`

  const { data } = await axiosApiClient.get(url)

  return {
    total: data.total,
    rows: processItems(data.rows as any),
  }
}

export const getDashboardList = async ({
  dashboardCategory = [],
  currentPage = 0,
  itemsPerPage = 5,
}: {
  dashboardCategory: Array<string>
  currentPage: number
  itemsPerPage: number
}): Promise<{
  total: number
  rows: Array<any>
}> => {
  const filterString = new URLSearchParams({
    dashboardCategory: encodeURIComponent(JSON.stringify(dashboardCategory)),
    skip: currentPage.toString(),
    limit: itemsPerPage.toString(),
  }).toString()

  const url = `/pods/v1/dashboards?${filterString}`

  const { data } = await axiosApiClient.get(url)

  return {
    total: data.total,
    rows: data.rows,
  }
}

export const getAlertCompatiblePods = async (
  page: number = 0,
  pageSize: number = 5,
  searchValue: string = '',
): Promise<{ total: any; rows: AlertCompatiblePod[] }> => {
  const filterString = new URLSearchParams({
    skip: page.toString(),
    limit: pageSize.toString(),
    sortDirection: 'desc',
    sortColumn: 'created_at',
    searchValue,
  }).toString()

  const processItems = (rawItems): AlertCompatiblePod[] => {
    return rawItems.map(processPod)
  }

  const url = `/pods/v1/alerts/pods?${filterString}`

  const { data } = await axiosApiClient.get(url)

  return {
    total: data.total,
    rows: processItems(data.rows),
  }
}

/**
 * Gets pod metadata object from the server and processed it
 */
export const getPod = async (userPodId: string): Promise<Pod> => {
  const url = `/pods/v1/pods/${userPodId}`

  const { data } = await axiosApiClient.get(url)
  return processPod(data)
}

export async function createPod(pod: CreatePodSchema): Promise<string> {
  const { data } = await axiosApiClient.post('/pods/v1/pods', pod)
  return data.userPodId
}

export async function getPodDataSignedUrl(userPodId) {
  return axiosApiClient
    .get(`/pods/v1/pods/${userPodId}/download`)
    .then((r) => r.data as string)
}

export async function getPodDatacube(userPodId) {
  return axiosApiClient
    .get(`/pods/v1/pods/${userPodId}/data-cube`)
    .then((r) => r.data.url as string)
}

export async function updatePod(podId) {
  const { data } = await axiosApiClient.post('/pods/v1/pods/reset-pod', {
    podId,
  })
  return data
}

export async function deleteInnoPod(userPodId) {
  return axiosApiClient.delete(`/pods/v1/pods/${userPodId}`)
}

// FIXME: MOVE OUTSIDE OF HERE SO ALL CAN SHARE
type DruidColumnType = 'STRING' | 'FLOAT' | 'DOUBLE' | 'LONG'

interface ColumnMetadata {
  type: DruidColumnType
  size?: number
  cardinality?: number
  minValue?: number | string
  maxValue?: number | string
  hasMultipleValues?: boolean
  errorMessage?: string
}

interface SegmentMetadataDatum {
  id: string
  intervals: string | string[]
  size: number
  numRows?: number
  columns: { [columnName: string]: ColumnMetadata }
}

type SegmentMetadataResults = SegmentMetadataDatum[]

export const domainDataTypes = ['string', 'number', 'datetime'] as const

export type ValidDomainDataTypes = (typeof domainDataTypes)[number]

interface Response {
  numRows: number
  columns: { [columnName: string]: { type: ValidDomainDataTypes } }
}

export type DruidBoundFilter = {
  type: 'bound'
  dimension: string
  lower?: number
  lowerStrict?: boolean
  upper?: number
  upperStrict?: boolean
  ordering?: { type: 'numeric' }
}

export type DruidSelectorFilter = {
  type: 'selector'
  dimension: string
  value: string | number
}

export type DruidInFilter = {
  type: 'in'
  dimension: string
  values: string[]
}

export type DruidNotInFilter = {
  type: 'not'
  field: DruidInFilter
}

export type DruidVirtualColumn = {
  type: 'expression'
  name: string
  expression: string
  outputType: ColumnMetadata['type']
}

export type DruidOptionFilter =
  | DruidSelectorFilter
  | DruidInFilter
  | DruidNotInFilter

export type DruidFilter = DruidBoundFilter | DruidOptionFilter

const druidToDomainDataTypes = Object.freeze({
  STRING: domainDataTypes[0],
  FLOAT: domainDataTypes[1],
  DOUBLE: domainDataTypes[1],
  LONG: domainDataTypes[1],
})

export function getValidDomainDataType(
  columnName: string,
  type: ColumnMetadata['type'],
): ValidDomainDataTypes {
  /**
   * Match anything that contains date or time... may backfire... but a good start lol
   * In druid's documentation it states that dates in __time are typically stored as type LONG so we check for that and strings in case there are multiple time columns
   */
  const regex = /(time|date)/g
  if ((type === 'STRING' || type === 'LONG') && regex.test(columnName)) {
    return 'datetime'
  }
  return druidToDomainDataTypes[type]
}

export function getDatasourceMetadata(datasource: string) {
  return Promise.all([
    axiosApiClient
      .get(`/druid/v3/datasources/${datasource}/time-boundary`)
      .then((r) => {
        return r.data as { minTime: string; maxTime: string }
      }),
    axiosApiClient
      .get(`/druid/v3/datasources/${datasource}/metadata`)
      .then((r) => {
        const result = r.data as SegmentMetadataResults
        const obj: Response = {
          numRows: 0,
          columns: {},
        }
        result.forEach((segment) => {
          if (segment.numRows) {
            obj.numRows += segment.numRows
          }
          Object.entries(segment.columns).forEach((c) => {
            // TODO: We need a good way to handle date datatypes
            const [columnName, columnMetadata] = c
            obj.columns[columnName] = {
              type: getValidDomainDataType(columnName, columnMetadata.type),
            }
          })
        })
        return obj
      }),
  ]).then((promiseArr) => {
    const [time, metadata] = promiseArr
    return {
      minTime: DateTime.fromISO(time.minTime),
      maxTime: DateTime.fromISO(time.maxTime),
      ...metadata,
    }
  })
}

export function getTopColumnValues(datasource: string, columnName: string) {
  return axiosApiClient
    .get(`/druid/v3/datasources/${datasource}/topn/${columnName}`)
    .then((r) => {
      return r.data[0].result.map((col) => {
        if (typeof col[columnName] === 'number') {
          return col[columnName].toString()
        }
        return col[columnName]
      }) as string[]
    })
}

export function searchColumnValues(
  datasource: string,
  columnName: string,
  searchValue: string,
) {
  return axiosApiClient
    .get(
      `/druid/v3/datasources/${datasource}/search/${columnName}/${searchValue}`,
    )
    .then((res) => {
      return res.data[0].result.map((v) => v.value) as string[]
    })
}

export interface RawDataArgs {
  datasource: string
  intervals: string[]
  pageSize: number
  pageNumber: number
  columns: string[]
  virtualColumns?: DruidVirtualColumn[]
  filter?: { type: 'and'; fields: DruidFilter[] }
  sort?: SortDirection
}

export function getRawData({
  datasource,
  intervals,
  pageSize,
  pageNumber,
  columns,
  virtualColumns,
  filter,
  sort = 'descending',
}: RawDataArgs) {
  /**
   * Scan queries will fail if a sort is in the query and time is not in the
   * columns, so we always add it.
   */
  const updatedColumns = columns.includes('__time')
    ? [...columns]
    : [...columns, '__time']
  return axiosApiClient
    .post(`/druid/v3/datasources/${datasource}/scan`, {
      columns: updatedColumns,
      intervals,
      limit: pageSize,
      offset: pageSize * pageNumber,
      filter,
      virtualColumns,
      order: sort,
    })
    .then((r) => {
      const events = r.data.events.map((c) => {
        const { __time: timestamp, ...rest } = c
        if (timestamp) {
          return {
            ...rest,
            __time: DateTime.fromMillis(timestamp),
          }
        }
        return c
      })
      return { events, count: r.data.count }
    })
}

export function getAlertPreview(
  alertPreviewBody: Omit<
    AlertCreateSchema,
    'userAlertName' | 'recipients' | 'isPaused'
  >,
) {
  return axiosApiClient
    .post(`/pods/v1/alerts/preview`, alertPreviewBody)
    .then((res) => {
      return res.data as {
        columns: {
          [key: string]: { type: DruidColumnType }
        }
        data: { [key: string]: number | string }[]
      }
    })
}

export function createAlert(alert: AlertCreateSchema) {
  return axiosApiClient.post(`/pods/v1/alerts`, alert).then((res) => {
    return res.data as { userAlertId: string }
  })
}

/**
 * Pod Sharing Routes
 */

export function sharePodWithUsers(
  podId: string,
  usersToAdd: UserSharingRole[] = [],
  userIdsToRemove: string[] = [],
  sharingRole?: SharingRole,
) {
  return axiosApiClient.put(`/pods/v1/sharing/${podId}/users`, {
    usersToAdd,
    userIdsToRemove,
    sharingRole,
  })
}

export function sharePodWithOrg(
  podId: string,
  shareWithOrg: boolean,
  sharingRole: Array<{
    orgId: string
    role: SharingRole
  }>, // keeping the parameter name same and using it to pass in org and role
) {
  return axiosApiClient.put(`/pods/v1/sharing/${podId}/org`, {
    shareWithOrg,
    sharingRole,
  })
}

// Alert Routes

/**
 * Gets an alert config from the pods service
 */
export const getAlertConfig = async (alertId: string): Promise<AlertConfig> => {
  const { data } = await axiosApiClient.get(`/pods/v1/alerts/${alertId}/config`)
  return data
}

/**
 * Gets an alert summary object from the pods service
 */
export const getUserAlert = async (alertId: string): Promise<AlertSummary> => {
  const url = `/pods/v1/alerts/${alertId}`
  const { data } = await axiosApiClient.get(url)
  return data
}

/**
 * Gets a list of alert summary objects from the pods service
 */
export const getUserAlerts = async (
  page: number = 0,
  pageSize: number = 5,
  searchValue: string = '',
): Promise<{ total: number; rows: AlertSummary[] }> => {
  const filterString = new URLSearchParams({
    skip: page.toString(),
    limit: pageSize.toString(),
    sortDirection: 'desc',
    sortColumn: 'created_at',
    searchValue,
  }).toString()
  const url = `/pods/v1/alerts?${filterString}`
  const { data } = await axiosApiClient.get(url)
  return data as { total: number; rows: AlertSummary[] }
}

/**
 * Gets a list of alert summary objects from the pods service
 */
export const toggleAlertIsPaused = async (alertId) => {
  const { data } = await axiosApiClient.get(`/pods/v1/alerts/${alertId}/pause`)
  return data.isPaused
}

/**
 * Gets a list of trigger summary objects for a given alert from the pods service
 */
export const getAlertTriggersSummary = async (
  alertId: string,
  filters: {
    size: number
    page: number
    sort: { column: string; direction: SortDirection }
  },
): Promise<PaginatedData<AlertTriggerSummary>> => {
  // our tables use ascending/descending but PG needs asc/desc
  const pgSort = filters.sort.direction === 'ascending' ? 'asc' : 'desc'

  const filterString = new URLSearchParams({
    skip: filters.page.toString(),
    limit: filters.size.toString(),
    sortDirection: pgSort,
    sortColumn: filters.sort.column,
  }).toString()

  const { data } = await axiosApiClient.get(
    `/pods/v1/alerts/${alertId}/triggers?${filterString}`,
  )
  return data
}
/**
 * Gets the details of a trigger for a given alert from the pods service
 */
export const getAlertTrigger = async (
  alertId: string,
  triggerId: string,
  filters: {
    size: number
    page: number
    sort: { column: string; direction: SortDirection }
  },
): Promise<AlertTrigger> => {
  // our tables use ascending/descending but PG needs asc/desc
  const pgSort = filters.sort.direction === 'ascending' ? 'asc' : 'desc'
  const filterString = new URLSearchParams({
    skip: filters.page.toString(),
    limit: filters.size.toString(),
    sortDirection: pgSort,
    sortColumn: filters.sort.column,
  }).toString()
  const { data } = await axiosApiClient.get(
    `/pods/v1/alerts/${alertId}/triggers/${triggerId}?${filterString}`,
  )
  const updatedColumns: {
    [columnName: string]: { type: ValidDomainDataTypes }
  } = data.columns
  Object.entries(data.columns).forEach((c: [string, any]) => {
    const [columnName, columnType] = c
    updatedColumns[columnName] = {
      type: getValidDomainDataType(
        columnName,
        columnType.type as DruidColumnType,
      ),
    }
  })
  return {
    ...data,
    columns: updatedColumns,
  }
}

/**
 * Deletes an alert from the pods service
 */
export const deleteAlert = (alertId) => {
  return axiosApiClient.delete(`/pods/v1/alerts/${alertId}`)
}

export const getAllFileSchemas = async () => {
  const url = `/pods/v1/import-schemas`
  const {
    data: { data },
  } = await axiosApiClient.get(url)
  return data.map((a) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { schema, ...rest } = a
    const temp = {
      ...rest,
      mapping: a.schema,
    }
    return temp
  }) as unknown as ImportManagerSchema[]
}
