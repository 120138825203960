import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { SectionProps as IWSectionProps } from './IWFormSectionContainer'

type Section = ReactElement<IWSectionProps>

interface ExtraProps {
  children: Section | Section[]
}

type Props = ExtraProps & React.ComponentPropsWithoutRef<'form'>

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

/**
 * A container to encompass all the FormSection elements that are passed as children to create a Form in the UI.
 * It takes in multiple FormSection components as the props and creates a form with them.
 */
const FormContainer = React.forwardRef(
  ({ children, ...props }: Props, ref: React.Ref<HTMLFormElement>) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <StyledForm ref={ref} {...props}>
        {children}
      </StyledForm>
    )
  },
)

export default FormContainer
