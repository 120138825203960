import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PodType } from '../types'
import {
  getActualUsageMetrics,
  getAttributeInfoMetrics,
  getLongTermForecastMetrics,
  getRiskManagementMetrics,
  getShortTermForecastMetrics,
  getShortTermForecastPerformanceMetrics,
  getShortTermForecastPerformanceSystemMetrics,
  getShortTermForecastSystemMetrics,
  getWholesaleSettlementMetrics,
  MetricObject,
} from '../utils/getMetrics'
import UserContext from 'shared/contexts/UserContext'
import SelectPodMetricStep, {
  ValidMetricIds,
  Props as MetricState,
} from './SelectPodMetricStep'

interface Props {
  insight?: PodType['insightId']
  state?: MetricState['state']
  showErrors: boolean
  onChange: (value: any, isStepValid: boolean) => void
}

const getInsightMetrics = (
  insightId: PodType['insightId'] | undefined,
  metricIds,
  t,
): MetricObject<ValidMetricIds>[] => {
  if (insightId === 'actualUsage') {
    return getActualUsageMetrics(t, metricIds)
  }

  if (insightId === 'shortTermForecastSystem') {
    return getShortTermForecastSystemMetrics(t, metricIds)
  }

  if (insightId === 'riskManagement') {
    return getRiskManagementMetrics(t, metricIds)
  }

  if (insightId === 'wholesaleSettlement') {
    return getWholesaleSettlementMetrics(t, metricIds)
  }

  if (insightId === 'longTermForecast') {
    return getLongTermForecastMetrics(t, metricIds)
  }

  if (insightId === 'shortTermForecast') {
    return getShortTermForecastMetrics(t, metricIds)
  }

  if (insightId === 'shortTermForecastPerformanceTopDown') {
    return getShortTermForecastPerformanceMetrics(t, metricIds)
  }

  if (insightId === 'shortTermForecastPerformance') {
    return getShortTermForecastPerformanceMetrics(t, metricIds)
  }

  if (insightId === 'shortTermForecastPerformanceSystem') {
    return getShortTermForecastPerformanceSystemMetrics(t, metricIds)
  }

  if (insightId === 'attributeInfo') {
    return getAttributeInfoMetrics(t, metricIds)
  }

  return []
}

const MetricWrapper = ({ insight, state, showErrors, onChange }: Props) => {
  const { t } = useTranslation()
  const { availablePodTypesMap } = useContext(UserContext)

  if (insight === undefined) {
    return <h1>{t('insightManager.createPod.doesNotExist')}</h1>
  }

  const metrics = getInsightMetrics(insight, availablePodTypesMap[insight], t)
  return (
    <SelectPodMetricStep
      state={state}
      onChange={onChange}
      showErrors={showErrors}
      metrics={metrics}
    />
  )
}

export default MetricWrapper
