import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { MeasureConditionOperators } from 'tools/alertsManager/types'
import {
  DropdownValueProps,
  GenericDropdownValue,
} from '../components/thunderbolt/IWDropdown'

export type MeasureCondition = {
  id: string
  selectedMeasureOption?: DropdownValueProps
  selectedValueTypeOption?: DropdownValueProps
  selectedFirstConditionOption?: GenericDropdownValue<MeasureConditionOperators>
  selectedSecondConditionOption?: GenericDropdownValue<MeasureConditionOperators>
  firstConditionValue?: number
  secondConditionValue?: number
  hasFirstConditionValueError: boolean
  hasSecondConditionValueError: boolean
}

type MeasureConditionHook = {
  measureFilters: DropdownValueProps[]
  conditions: MeasureCondition[]
  hasErrors: boolean
  addMeasureCondition: () => void
  deleteMeasureCondition: (id: string) => void
  clearMeasureConditions: () => void
  selectedMeasureOption: DropdownValueProps
  selectedValueTypeOption: DropdownValueProps
  onMeasureConditionOptionChange: (
    selection: DropdownValueProps | undefined,
  ) => void
  onValueTypeOptionChange: (selection: DropdownValueProps | undefined) => void
  onFirstConditionOptionChange: (selection: DropdownValueProps) => void
  onSecondConditionOptionChange: (selection: DropdownValueProps) => void
  onFirstConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onSecondConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
}

const useMeasureRangeConditions = (
  measureOptions: DropdownValueProps[],
  initialConditions?: MeasureCondition[],
): MeasureConditionHook | any => {
  const [measureFilters] = useState<DropdownValueProps[]>(measureOptions)
  const [conditions, setConditions] = useState<MeasureCondition[]>(
    initialConditions || [],
  )
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [selectedMeasureOption, setSelectedMeasureOption] = useState<
    DropdownValueProps | undefined
  >()
  const [selectedValueTypeOption, setSelectedValueTypeOption] = useState<
    DropdownValueProps | undefined
  >()

  function validateErrors() {
    const containErrors = conditions.some(
      (condition) =>
        condition.hasFirstConditionValueError ||
        condition.hasSecondConditionValueError ||
        !condition.selectedMeasureOption ||
        !condition.selectedValueTypeOption ||
        !condition.selectedFirstConditionOption ||
        !condition.selectedSecondConditionOption,
    )
    setHasErrors(containErrors)
  }

  function onMeasureConditionOptionChange(option: DropdownValueProps) {
    setSelectedMeasureOption(option)
  }

  function onValueTypeOptionChange(option: DropdownValueProps) {
    setSelectedValueTypeOption(option)
  }

  function onFirstConditionChange(
    value: GenericDropdownValue<MeasureConditionOperators>,
    id: string,
  ) {
    setConditions((prev) =>
      prev.map((r) =>
        r.id === id ? { ...r, selectedFirstConditionOption: value } : r,
      ),
    )
  }

  function onSecondConditionChange(
    value: GenericDropdownValue<MeasureConditionOperators>,
    id: string,
  ) {
    setConditions((prev) =>
      prev.map((r) =>
        r.id === id ? { ...r, selectedSecondConditionOption: value } : r,
      ),
    )
  }

  function onFirstConditionValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) {
    setConditions((prev) =>
      prev.map((r) => {
        if (r.id !== id) {
          return r
        }
        const containErrors = e.target.value === ''
        return {
          ...r,
          firstConditionValue: !containErrors
            ? parseFloat(e.target.value)
            : undefined,
          firstConditionValueError: containErrors
            ? 'Min Value is Required'
            : undefined,
          hasFirstConditionValueError: containErrors,
        }
      }),
    )
  }

  function onSecondConditionValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) {
    setConditions((prev) =>
      prev.map((r) => {
        if (r.id !== id) {
          return r
        }
        const containErrors = e.target.value === ''
        return {
          ...r,
          secondConditionValue: !containErrors
            ? parseFloat(e.target.value)
            : undefined,
          secondConditionValueError: containErrors
            ? 'Max Value is Required'
            : undefined,
          hasSecondConditionValueError: containErrors,
        }
      }),
    )
  }

  function addMeasureCondition() {
    setConditions([
      ...conditions,
      {
        id: uuidv4(),
        selectedMeasureOption,
        selectedValueTypeOption,
        hasFirstConditionValueError: true,
        hasSecondConditionValueError: true,
      },
    ])

    setSelectedMeasureOption(undefined)
    setSelectedValueTypeOption(undefined)
  }

  function deleteMeasureCondition(id: string) {
    setConditions(
      conditions.filter((condition: MeasureCondition) => condition.id !== id),
    )
  }

  function clearMeasureConditions() {
    setConditions([])
  }

  useEffect(() => {
    validateErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions])

  return {
    measureFilters,
    conditions,
    hasErrors,
    selectedMeasureOption,
    selectedValueTypeOption,
    addMeasureCondition,
    deleteMeasureCondition,
    clearMeasureConditions,
    onMeasureConditionOptionChange,
    onValueTypeOptionChange,
    onFirstConditionChange,
    onSecondConditionChange,
    onFirstConditionValueChange,
    onSecondConditionValueChange,
  }
}

export default useMeasureRangeConditions
