import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from 'shared/contexts/UserContext'

/**
 * Application Entry Page That decide where to send user.
 */
const EntryPoint = () => {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user.availableTools.includes('dashboardsManager')) {
      navigate('/tools/dashboards-manager')
    } else {
      navigate('/overview')
    }
  }, [user])

  return null
}

export default EntryPoint
