import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import { FullPageMainContent, OverlayPage } from 'root/components/IWLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import IWSpacer from 'shared/components/thunderbolt/IWSpacer'
import ImportWizard from '../components/ImportManagerWizard'
import IWButton from '../../../shared/components/thunderbolt/IWButton'
import { useTranslation } from 'react-i18next'

const StyledCaretIcon = styled(FontAwesomeIcon)`
  align-self: center;
  font-size: 1rem;
  color: ${(props) => props.theme.palette.grey[400]};
`

const StyledWrapper = styled.div`
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  justify-content: space-between;
`

const ImportPage = () => {
  const { t } = useTranslation()
  return (
    <OverlayPage>
      <IWPageHeader data-testid="page-header" isFullScreen>
        <StyledWrapper>
          <StyledTitle>{t('importManager.header')}</StyledTitle>
          <StyledCaretIcon icon={faChevronRight} />
          <StyledTitle>
            {t('importManager.importFile.labels.stepTitle')}
          </StyledTitle>
          <IWSpacer />
          <IWButton
            as="routerNavLink"
            to="/"
            variant="alternative"
            color="primary"
            data-testid="page-header-close-button"
          >
            {t('button.close')}
          </IWButton>
        </StyledWrapper>
      </IWPageHeader>
      <FullPageMainContent>
        <ImportWizard />
      </FullPageMainContent>
    </OverlayPage>
  )
}

export default ImportPage
