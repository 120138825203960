import { WizardStepProps } from 'shared/types'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { convertToISOFormat } from 'shared/loadSchedulingClient'
import { downloadZip } from 'shared/helpers'
import { LsCsvRowItem, LsErcotCsvRowItem } from '../types'
import {
  formatToConvertPayload,
  getErcotDayAheadBidsConvertSchema,
} from '../helpers'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import LoadSchedulingTable from './LSTable'
import { getDayAheadBidsConvertSchema } from '../schemas'
import useFilterBadges, {
  UseFilterBadgesHookProps,
} from 'shared/hooks/useFilterBadges'
import { faFilter } from '@fortawesome/pro-regular-svg-icons'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWFilterBadgesOptions from 'shared/components/thunderbolt/IWFilterBadgeOptions'
import IWFilterBadgesRange from 'shared/components/thunderbolt/IWFilterBadgeRange'
import IWFilterBadgesDatetime from 'shared/components/thunderbolt/IWFilterBadgesDatetime'
import IWFilterBadgesGroup from 'shared/components/thunderbolt/IWFilterBadgesGroup'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import { ReturnProps as DateFilterState } from 'shared/hooks/useIWRelativeDatePicker'
import { formatRelativeDate } from 'tools/insightsManager/helpers'
import { DateTime } from 'luxon'
import {
  buildRangeFilter,
  getSpecialDate,
} from 'tools/insightsManager/utils/filtersToDruid'
import { TIMEZONES } from 'shared/constants'
import { getUniqueValuesByKey } from 'shared/utils/filterBadgesUtils'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'

const DEBOUNCE_TIME = 500 // ms

const DEFAULT_FILTERS: {
  title: string
  key: string
  type: string
}[] = [
  {
    title: 'Market',
    key: 'market',
    type: 'string',
  },
  {
    title: 'Brand',
    key: 'brand',
    type: 'string',
  },
  {
    title: 'Zone',
    key: 'zone',
    type: 'string',
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
  },
  {
    title: 'Location Name',
    key: 'location_name',
    type: 'string',
  },
  {
    title: 'Date',
    key: 'date',
    type: 'datetime',
  },
]

export function buildDateFilter(
  dimension: string,
  state: DateFilterState,
  { minTime, maxTime }: { minTime: DateTime; maxTime: DateTime },
  timezone: string = 'UTC',
) {
  const relativeDate = formatRelativeDate(state)
  const timezoneOption = { zone: timezone }

  if (relativeDate.main === 'on') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
      selectedSecondConditionOption: { value: '<', label: '' },
      secondConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      )
        .plus({ day: 1 })
        .toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'after') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'before') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '<=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'between') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.datePickerMin,
        timezoneOption,
      ).toMillis(),
      selectedSecondConditionOption: { value: '<=', label: '' },
      secondConditionValue: DateTime.fromISO(
        relativeDate.datePickerMax,
        timezoneOption,
      ).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'previous') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })

    if (!specialDate) {
      return undefined
    }

    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: specialDate
        .setZone(timezone)
        .minus({ [relativeDate.timeLength]: relativeDate.timeLengthValue })
        .toMillis(),
      selectedSecondConditionOption: { value: '<=', label: '' },
      secondConditionValue: specialDate.setZone(timezone).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'next') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })

    if (!specialDate) {
      return undefined
    }

    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '<=', label: '' },
      firstConditionValue: specialDate
        .setZone(timezone)
        .plus({ [relativeDate.timeLength]: relativeDate.timeLengthValue })
        .toMillis(),
      selectedSecondConditionOption: { value: '>=', label: '' },
      secondConditionValue: specialDate.setZone(timezone).toMillis(),
    })
    return { filter }
  }

  return undefined
}

function applyFilters(
  dataArray: any,
  filters: UseFilterBadgesHookProps,
  market: string,
) {
  const filteredData: any = []
  dataArray.forEach((dataObj) => {
    let include = true

    Object.entries(filters).forEach(([key, filter]) => {
      if (filter && filter.value && filter.isInView) {
        if (filter.type === 'datetime') {
          const dateColumnData = dataObj[key]
          const dateColumnDataISO = DateTime.fromISO(dateColumnData).toMillis()

          const filterOptions = buildDateFilter(
            key,
            filter.value,
            {
              minTime: DateTime.now()
                .setZone(TIMEZONES[market])
                .minus({ weeks: 5000 }),
              maxTime: DateTime.now()
                .setZone(TIMEZONES[market])
                .plus({ weeks: 5000 }),
            },
            TIMEZONES[market],
          )

          if (filterOptions) {
            const { filter: filterOption } = filterOptions
            const { lower, lowerStrict, upper, upperStrict } = filterOption

            if (lower && upper && lowerStrict) {
              if (dateColumnDataISO <= lower || dateColumnDataISO > upper) {
                include = false
                return
              }
            } else if (lower && upper && !lowerStrict) {
              if (dateColumnDataISO < lower || dateColumnDataISO > upper) {
                include = false
                return
              }
            } else if (lower && upper && upperStrict) {
              if (dateColumnDataISO < lower || dateColumnDataISO > upper) {
                include = false
                return
              }
            } else if (lower && upper && !upperStrict) {
              if (dateColumnDataISO < lower || dateColumnDataISO > upper) {
                include = false
                return
              }
            }
          }
          return
        } else if (filter.type === 'number') {
          // TODO : Add number filter
          // Example: Where load > 100
        } else if (filter.type === 'string') {
          const filterValue = filter.value.values
          const optionType = filter.value.optionType
          const dataValue = dataObj[key]

          if (optionType === 'include') {
            // Inclusion filter
            if (!filterValue.includes(dataValue)) {
              include = false
              return
            }
          } else if (optionType === 'exclude') {
            // Exclusion filter
            if (filterValue.includes(dataValue)) {
              include = false
              return
            }
          }
        }
      }
    })

    if (include) {
      filteredData.push(dataObj)
    }
  })

  return filteredData
}

const StyledActionableSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & > :first-child {
    margin-bottom: 1rem;
  }
`

const StyledFilterButton = styled(IWButton)`
  width: fit-content;
`

const StyledIWFilterBadgesGroupWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 0;
`

type StepState = {
  dayAheadDemandBids: LsCsvRowItem[] | LsErcotCsvRowItem[]
  selectedMarket?: string
}

export type Props = WizardStepProps<StepState>

const LSPreviewLoadStep = ({ state, onChange }: Props) => {
  const { t } = useTranslation()
  const toast = useToast()

  const tableColumns =
    state?.selectedMarket === 'ercot'
      ? getErcotDayAheadBidsConvertSchema()
      : getDayAheadBidsConvertSchema()
  const [isConverting, setIsConverting] = useState(false)

  const [demandBids] = useState(state?.dayAheadDemandBids || [])
  const [dataToShow, setDataToShow] = useState(demandBids)

  const [debouncer] = useState<{ timeout: NodeJS.Timeout | null }>({
    timeout: null,
  })

  const [showConvertButton] = useState(() => {
    const market = state?.selectedMarket

    if (!market) {
      return false
    }

    const markets = Object.keys(TIMEZONES).filter(
      (k) => k !== 'ERCOT' && k !== 'MISO',
    )

    return markets.includes(market.toUpperCase())
  })

  const {
    filtersState,
    filtersInView,
    handleFilterAdd,
    handleFilterRemove: onFilterRemove,
    setDefaultFilters,
    handleFilterClose,
    handleFilterOpen,
    setDefaultOptions,
    handleFilterConfirm: onFilterConfirm,
  } = useFilterBadges()

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)

  const handleFilterConfirm = (id, s) => {
    onFilterConfirm(id, s)
  }

  const handleFilterRemove = (id) => {
    onFilterRemove(id)
  }

  const handleConvert = async () => {
    setIsConverting(true)
    const id = toast.grey(t('loadScheduling.toasts.converting'), {
      duration: 30_000,
    })

    const payload = formatToConvertPayload(dataToShow as LsCsvRowItem[])
    const response = await convertToISOFormat(payload)
    const filename = 'load-scheduling-files'

    await downloadZip(response, filename, false)

    toast.removeToast(id)
    setIsConverting(false)
  }

  function handleSearchTextOptions(columnName: string, searchValue: string) {
    if (debouncer.timeout) {
      clearTimeout(debouncer.timeout)
    }
    const debouncedFn = new Promise<DropdownValueProps[]>((resolve) => {
      debouncer.timeout = setTimeout(async () => {
        const searchOptions = getUniqueValuesByKey(
          demandBids,
          columnName,
          searchValue,
        )
        const dropdownOptions = searchOptions.map((option) => ({
          value: option,
          label: option,
        }))
        resolve(dropdownOptions as any)
      }, DEBOUNCE_TIME)
    })
    return debouncedFn
  }

  function handleGetOptions(columnName) {
    const top = getUniqueValuesByKey(demandBids, columnName)
    setDefaultOptions(columnName, top as string[])
  }

  const filterMenu = Object.entries(
    DEFAULT_FILTERS.filter((el) => isNaN(parseInt(el.key))),
  ).map((elem) => {
    return (
      <IWContextMenuItem
        as="button"
        key={elem[1].key}
        label={elem[1].title}
        onClick={() => {
          if (elem[1].type === 'string') {
            handleGetOptions(elem[1].key)
          }
          handleFilterAdd(elem[1].key)
          setIsFilterMenuOpen(false)
        }}
      />
    )
  })

  useEffect(() => {
    onChange(
      {
        dayAheadDemandBids: dataToShow,
      },
      true,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToShow])

  useEffect(() => {
    const defaultFilters: UseFilterBadgesHookProps = {}
    Object.entries(
      DEFAULT_FILTERS.filter((el) => isNaN(parseInt(el.key))),
    ).forEach((el) => {
      defaultFilters[el[1].key] = {
        type: el[1].type as any,
        isInView: false,
        isOpen: false,
      }
    })
    setDefaultFilters(defaultFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DEFAULT_FILTERS])

  useEffect(() => {
    const filterDataInView = applyFilters(
      demandBids,
      filtersState,
      state?.selectedMarket || '',
    )

    setDataToShow(filterDataInView)
  }, [filtersState, demandBids])

  return (
    <>
      <StyledActionableSection>
        <IWTypography
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {t('loadScheduling.previewStepTitle')}
        </IWTypography>

        <IWTypography fontHue={{ color: 'grey', value: 500 }} size="sm">
          {t('loadScheduling.previewStepDescription')}
        </IWTypography>
      </StyledActionableSection>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <IWContextMenu
            positions={['bottom']}
            align="start"
            menu={filterMenu}
            isOpen={isFilterMenuOpen}
            onClickOutside={() => setIsFilterMenuOpen(false)}
          >
            <StyledFilterButton
              variant="outline"
              color="grey"
              icon={faFilter}
              iconPosition="leading"
              onClick={() => setIsFilterMenuOpen(true)}
            >
              {t('button.filters')}
            </StyledFilterButton>
          </IWContextMenu>

          {showConvertButton && (
            <StyledFilterButton
              disabled={isConverting}
              onClick={() => handleConvert()}
            >
              {t('button.convert')}
            </StyledFilterButton>
          )}
        </div>

        <StyledIWFilterBadgesGroupWrapper>
          {Boolean(filtersInView.length) && (
            <IWFilterBadgesGroup label={t(`filterBadgesGroup.appliedFilters`)}>
              {filtersInView.map((col) => {
                const { name, type, isOpen } = col
                if (type === 'datetime') {
                  return (
                    <IWFilterBadgesDatetime
                      id={name}
                      key={name}
                      onConfirm={handleFilterConfirm}
                      onClick={() => handleFilterOpen(name)}
                      onCancel={handleFilterClose}
                      isOpen={isOpen}
                      disabledRelativeOptions={[
                        'firstDateAvailable',
                        'lastDateAvailable',
                      ]}
                      disabledMainOptions={[
                        'next',
                        'after',
                        'before',
                        'previous',
                      ]}
                      onRemove={handleFilterRemove}
                    />
                  )
                }
                if (type === 'number') {
                  return (
                    <IWFilterBadgesRange
                      id={name}
                      key={name}
                      onConfirm={handleFilterConfirm}
                      onCancel={handleFilterClose}
                      onClick={() => handleFilterOpen(name)}
                      isOpen={isOpen}
                      onRemove={handleFilterRemove}
                    />
                  )
                }
                if (type === 'string') {
                  return (
                    <IWFilterBadgesOptions
                      id={name}
                      key={name}
                      onClick={() => handleFilterOpen(name)}
                      isOpen={isOpen}
                      onConfirm={handleFilterConfirm}
                      onRemove={handleFilterRemove}
                      onCancel={handleFilterClose}
                      defaultOptions={
                        col.defaultOptions?.map((e) => ({
                          label: e,
                          value: e,
                        })) || []
                      }
                      onGetOptions={async (inputValue) => {
                        return handleSearchTextOptions(name, inputValue)
                      }}
                    />
                  )
                }
                return <></>
              })}
            </IWFilterBadgesGroup>
          )}
        </StyledIWFilterBadgesGroupWrapper>
      </div>

      <LoadSchedulingTable
        columns={tableColumns}
        items={dataToShow as any}
        pinnedColumns={{ brand: 'left' }}
      />
    </>
  )
}

export default LSPreviewLoadStep
