import React from 'react'
import DataAccessSection from '../components/DataAccessCredentials/DataAccessSection'
import SFTPSection from '../components/SFTP/SFTPSection'
import styled from 'styled-components'
import ThirdPartyIntegrationsSection from 'systemPreferences/components/ThirdPartyIntegrations/ThirdPartyIntegrationsSection'

const IntegrationSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const IntegrationsPage = () => {
  return (
    <IntegrationSections>
      <DataAccessSection />
      <SFTPSection />
      <ThirdPartyIntegrationsSection />
    </IntegrationSections>
  )
}

export default IntegrationsPage
