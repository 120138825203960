import { ScreenSizes } from '../../../../@types/styled'

const defaultScreenSizes: ScreenSizes = {
  xl: 1920,
  lg: 1440, // Sidebar nav open
  md: 1024,
  sm: 769, // Inner nav dropdown
  xs: 568, // Not Supported
}

export default defaultScreenSizes
