import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-regular-svg-icons'

import styled from 'styled-components'
import { darken } from 'polished'
import IWTypography from './IWTypography'
import IWTextInput from './IWTextInput'
import IWDropdown from './IWDropdown'
import { IWSmallLoading } from './IWLoading'

export interface Props {
  perPageLabel: string // translated label for the select box to change number of items
  perPageOptions: {
    label: string
    value: number
  }[]
  itemsPerPage: number
  totalItems: number
  currentPage: number
  isLoading?: boolean
  onChangePage: (pageNumber: number) => void
  onChangeItemsPerPage: (perPageCount: number) => void
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`

const ItemCountWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const PageControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PageInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 0.5rem;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{
  disabled?: boolean
}>`
  color: ${(props) => props.theme.palette.grey[500]};
  font-size: 1rem;
`

const StyledPerPageLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-right: 1rem;
  align-items: center;
`

const StyledButton = styled.button<{}>`
  background: transparent;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  margin: 0;
  border: none;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 0.5rem;

  &:focus {
    outline-offset: 2px;
    outline: ${(props) => props.theme.palette.primary[800]} solid 2px;
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.palette.grey[0])};
  }

  &:disabled {
    ${StyledFontAwesomeIcon} {
      color: ${(props) => props.theme.palette.grey[300]};
    }

    cursor: not-allowed;

    &:hover {
      background-color: ${(props) => props.theme.palette.grey[0]};
    }
  }
`

// As the inputSize prop changes, we scale the text input so it flexes to the number of chars
const StyledTextInput = styled(IWTextInput)`
  width: 3rem;
  text-align: right;
`

const StyledDropdownWrapper = styled.div`
  margin-right: 2rem;
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 6rem;
`

const IWPaginationControls = ({
  perPageLabel,
  itemsPerPage,
  perPageOptions,
  totalItems,
  currentPage,
  isLoading = false,
  onChangePage,
  onChangeItemsPerPage,
}: Props) => {
  const { t } = useTranslation()
  // Calculate the total pages based on the page size and total
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItems / itemsPerPage),
  )
  // This controls the text input prior to calling the onChangePage for validation
  const [currentPageInputValue, setCurrentPageInputValue] = useState<string>(
    currentPage.toString(),
  )
  // Sets the value for the first item position to be displayed in the ui
  const [currentFirstItemPosition, setCurrentFirstItemPosition] =
    useState<number>(currentPage === 1 ? 0 : itemsPerPage * currentPage)

  /*
   * When the user changes the page number text input, we validate it
   * If valid we trigger the on change, if not, we revert to the previous valid value
   */
  const submitPageInputChange = () => {
    const currentPageInputValueAsNumber = Number.parseInt(
      currentPageInputValue,
      10,
    )
    // Test for only numbers
    const re = /^[0-9\b]+$/
    if (
      currentPageInputValue &&
      re.test(currentPageInputValue) &&
      currentPageInputValueAsNumber <= totalPages &&
      currentPageInputValueAsNumber > 0
    ) {
      onChangePage(currentPageInputValueAsNumber)
    } else {
      // Reset the input value to the currentPage value if its not valid
      setCurrentPageInputValue(currentPage.toString())
    }
  }

  /*
   * When the total items or items per page changes
   * We need to recalculate the various values which are based on them
   */
  useEffect(() => {
    const newTotalPages = Math.ceil(totalItems / itemsPerPage)
    setTotalPages(newTotalPages)
    const newCurrentPage = 1 // Set this to the start as we are changing the paging
    setCurrentPageInputValue(newCurrentPage.toString())
    setCurrentFirstItemPosition(0)
  }, [totalItems, itemsPerPage])

  useEffect(() => {
    setCurrentFirstItemPosition(
      currentPage === 1 ? 0 : itemsPerPage * (currentPage - 1),
    )
    setCurrentPageInputValue(currentPage.toString())
  }, [currentPage, itemsPerPage])

  return (
    <StyledDiv data-testid="pagination-controls">
      <StyledPerPageLabel>
        {isLoading && <IWSmallLoading />}
        <IWTypography size="sm">{perPageLabel}</IWTypography>
      </StyledPerPageLabel>
      <StyledDropdownWrapper>
        <StyledIWDropdown
          data-testid="pagination-per-page-select"
          defaultValue={perPageOptions[0]}
          value={
            perPageOptions.find((o) => o.value === itemsPerPage) ||
            perPageOptions[0]
          }
          options={perPageOptions}
          onChange={(e) => onChangeItemsPerPage(e.value)}
          isDisabled={isLoading}
        />
      </StyledDropdownWrapper>
      <ItemCountWrapper>
        <IWTypography as="span" size="sm">
          {currentFirstItemPosition + 1}-
          {totalItems > itemsPerPage * currentPage
            ? itemsPerPage * currentPage
            : totalItems}{' '}
          {t('prepositions.of')} {totalItems}
        </IWTypography>
      </ItemCountWrapper>
      <PageControlsWrapper>
        <StyledButton
          data-testid="pagination-first-page"
          type="button"
          disabled={isLoading || currentPage <= 1}
          onClick={() => onChangePage(1)}
        >
          <StyledFontAwesomeIcon icon={faAngleDoubleLeft} />
        </StyledButton>
        <StyledButton
          type="button"
          data-testid="pagination-prev-page"
          disabled={isLoading || currentPage <= 1}
          onClick={() => onChangePage(currentPage - 1)}
        >
          <StyledFontAwesomeIcon icon={faAngleLeft} />
        </StyledButton>
        <PageInputWrapper>
          <StyledTextInput
            type="text"
            inputMode="numeric"
            disabled={isLoading || totalPages === 1}
            value={currentPageInputValue}
            onChange={(e) => {
              const { value } = e.target
              setCurrentPageInputValue(value)
            }}
            onBlur={submitPageInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                submitPageInputChange()
              }
            }}
          />
          <IWTypography size="sm" as="span">
            / {totalPages}
          </IWTypography>
        </PageInputWrapper>
        <StyledButton
          type="button"
          data-testid="pagination-next-page"
          disabled={isLoading || totalPages <= currentPage}
          onClick={() => onChangePage(currentPage + 1)}
        >
          <StyledFontAwesomeIcon icon={faAngleRight} />
        </StyledButton>
        <StyledButton
          type="button"
          data-testid="pagination-last-page"
          disabled={isLoading || totalPages <= currentPage}
          onClick={() => onChangePage(totalPages)}
        >
          <StyledFontAwesomeIcon icon={faAngleDoubleRight} />
        </StyledButton>
      </PageControlsWrapper>
    </StyledDiv>
  )
}

export default IWPaginationControls
