import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import { WizardStepProps } from '../../../shared/types'

type GeneralAlertStepState = {
  alertName?: string
  pauseAlert?: boolean
}

export type Props = WizardStepProps<GeneralAlertStepState>

export default function GeneralAlertStep({
  state,
  onChange,
  showErrors,
}: Props) {
  const { t } = useTranslation()
  const MAX_LENGTH = 240
  const MIN_LENGTH = 3
  const [alertName, setAlertName] = useState(state?.alertName || '')
  const [pauseAlert, setPauseAlert] = useState(state?.pauseAlert || false)

  const hasError = () => {
    return (
      alertName === '' ||
      typeof alertName === 'undefined' ||
      alertName.length < MIN_LENGTH
    )
  }

  useEffect(() => {
    onChange({ alertName, pauseAlert }, !hasError())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseAlert, alertName])

  return (
    <>
      <IWFormSection
        sectionTitle={t(`alertsManager.createAlert.generalStep.generalTitle`)}
      >
        <IWTextInput
          required
          data-testid="create-alert-general-setting-name"
          label={t(`alertsManager.createAlert.generalStep.alertNameLabel`)}
          value={alertName}
          maxLength={MAX_LENGTH}
          onChange={(e) => setAlertName(e.target.value)}
          hasError={showErrors && hasError()}
          errorText={
            showErrors && hasError()
              ? t(`insightManager.createPod.errors.characterLengthRequired`, {
                  minLength: MIN_LENGTH,
                  maxLength: MAX_LENGTH,
                })
              : undefined
          }
        />
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(
          `alertsManager.createAlert.generalStep.schedulingTitle`,
        )}
        sectionDescription={t(
          `alertsManager.createAlert.generalStep.schedulingDesc`,
        )}
      >
        <IWToggle
          label={t('alertsManager.createAlert.generalStep.pauseAlert')}
          id="pauseAlert"
          checked={pauseAlert}
          onChange={() => {
            setPauseAlert((prevState) => !prevState)
          }}
        />
      </IWFormSection>
    </>
  )
}
