import React from 'react'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWDropdown, {
  DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { IWError } from 'shared/components/thunderbolt/IWCommonLabels'
import IWFilterSection from 'shared/components/thunderbolt/IWFilterSection'
import { LimitFilter as LimitFilterType } from 'tools/insightsManager/hooks/useLimitFilters'

export interface Props {
  limit: LimitFilterType | undefined
  filters: DropdownValueProps[]
  maxLimitValue?: number
  showErrors?: boolean
  hasErrors?: boolean
  onMeasureFilterChange: (value: DropdownValueProps) => void
  onEdgeOptionChange: (value: DropdownValueProps) => void
  onTypeOptionChange: (value: DropdownValueProps) => void
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
}

const StyledFilterSection = styled(IWFilterSection)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`

const StyledIWButton = styled(IWButton)`
  padding: 0.375rem 1rem;
  align-self: flex-end;
`

const StyledIWDropdown = styled(IWDropdown)`
  margin-right: 1rem;
`

const StyledFlexRow = styled.div`
  display: flex;
`

const StyleInputWrapper = styled.div`
  margin-right: 1rem;
  width: 100%;
`

const MAX_PERCENT = 100

const LimitFilter = ({
  limit,
  filters,
  showErrors,
  hasErrors,
  maxLimitValue,
  onMeasureFilterChange,
  onEdgeOptionChange,
  onTypeOptionChange,
  onValueChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()

  const edgeOptions = [
    {
      value: 'top',
      label: t('limitFilterSection.options.top'),
    },
    {
      value: 'bottom',
      label: t('limitFilterSection.options.bottom'),
    },
  ]

  const typeOptions = [
    {
      value: 'percentile',
      label: t('limitFilterSection.options.percentage'),
    },
    {
      value: 'absolute',
      label: t('limitFilterSection.options.absoluteValue'),
    },
  ]

  // Auto select first item if Measure filter dropdown contains one item ONLY.
  if (filters.length === 1 && limit) {
    const [firstFilter] = filters
    // eslint-disable-next-line no-param-reassign
    limit.selectedMeasureOption = firstFilter
  }

  return (
    <>
      {limit && (
        <StyledFilterSection data-testid="limit-filter-component">
          <StyledFlexRow>
            <StyledIWDropdown
              name="measure"
              required
              fullWidth
              label={t('limitFilterSection.measureToLimit')}
              value={limit.selectedMeasureOption}
              onChange={(e) => onMeasureFilterChange(e)}
              options={[...filters]}
              placeholder={t('placeholders.select')}
              isDisabled={limit.isMeasureFilterDisabled}
              hasError={showErrors && !limit.selectedMeasureOption}
            />

            <StyledIWDropdown
              name="edge"
              required
              fullWidth
              label={t('limitFilterSection.edge')}
              value={limit.selectedEdgeOption}
              onChange={(e) => onEdgeOptionChange(e)}
              options={[...edgeOptions]}
              placeholder={t('placeholders.select')}
              hasError={showErrors && !limit.selectedEdgeOption}
            />

            <StyledIWDropdown
              name="type"
              required
              fullWidth
              label={t('limitFilterSection.type')}
              value={limit.selectedTypeOption}
              onChange={(e) => onTypeOptionChange(e)}
              options={[...typeOptions]}
              placeholder={t('placeholders.select')}
              hasError={showErrors && !limit.selectedTypeOption}
            />

            <StyleInputWrapper>
              <IWTextInput
                data-testid="limit-value-input"
                name="value"
                min="1"
                max={
                  limit.selectedTypeOption?.value === 'percentile'
                    ? MAX_PERCENT
                    : maxLimitValue
                }
                type="number"
                onKeyDown={(evt) =>
                  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                }
                required
                label={t('limitFilterSection.value')}
                value={limit.limitValue ?? ''}
                onChange={(e) => {
                  // check for a valid number while typing (no leading zeroes or zero)
                  if (/^0/.test(e.target.value)) {
                    // eslint-disable-next-line no-param-reassign
                    e.target.value = e.target.value.replace(/^0/, '')
                  }
                  const val = parseInt(e.target.value, 10)
                  // We limit the value of percentile values to 100
                  if (limit.selectedTypeOption?.value === 'percentile') {
                    e.target.value = String(
                      val > MAX_PERCENT ? MAX_PERCENT : val,
                    )
                  } else {
                    // if we have a maxLimitValue we limit abs values also
                    e.target.value = String(
                      maxLimitValue && val > maxLimitValue
                        ? maxLimitValue
                        : val,
                    )
                  }
                  onValueChange(e)
                }}
                placeholder={t('placeholders.enterValue')}
                hasError={showErrors && limit.hasValueError}
              />
            </StyleInputWrapper>

            <StyledIWButton
              data-testid="limit-delete-button"
              color="grey"
              variant="outline"
              type="button"
              onClick={() => onDelete()}
            >
              <FontAwesomeIcon icon={faTrash} />
            </StyledIWButton>
          </StyledFlexRow>
          {showErrors && hasErrors && (
            <IWError
              label={t('insightManager.createPod.errors.enterAllRequired')}
            />
          )}
        </StyledFilterSection>
      )}
    </>
  )
}

export default LimitFilter
