import {
  faDownload,
  faFilter,
  faSlidersSimple,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { round } from 'mathjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import DataDownloadModal from 'shared/components/DataDownloadModal'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import IWError from 'shared/components/thunderbolt/IWError'
import IWFilterBadgesOptions from 'shared/components/thunderbolt/IWFilterBadgeOptions'
import IWFilterBadgesRange from 'shared/components/thunderbolt/IWFilterBadgeRange'
import IWFilterBadgesDatetime from 'shared/components/thunderbolt/IWFilterBadgesDatetime'
import IWFilterBadgesGroup from 'shared/components/thunderbolt/IWFilterBadgesGroup'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
  StyledActions,
} from 'shared/components/thunderbolt/IWModal'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import IWTable, {
  SortDirection,
  usePinnedColumns,
} from 'shared/components/thunderbolt/IWTable'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import IWToggleGroup from 'shared/components/thunderbolt/IWToggleGroup'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import useFilterBadges, {
  UseFilterBadgesHookProps,
} from 'shared/hooks/useFilterBadges'
import {
  getDatasourceMetadata,
  getRawData,
  getTopColumnValues,
  RawDataArgs,
  searchColumnValues,
} from 'shared/podServiceClient'
import { paginationOptions } from 'shared/types'
import styled from 'styled-components'
import { filtersToDruid } from '../utils/filtersToDruid'

const MAX_TABLE_COLUMN_COUNT = 50
const ROUND_PRECISION = 2
const DEBOUNCE_TIME = 500 // ms
const DOWNLOAD_ROW_LIMIT = 10000

/**
 * FIXME:
 * - All date types must have its min and max values stored so we can query from last / first date available
 */

const Wrapper = styled.div`
  width: 100%;
`

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledFilterButton = styled(IWButton)`
  width: fit-content;
`

const PaginationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
`

const StyledIWModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledModalText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledModalToggleTextButtons = styled.span`
  display: flex;
  gap: 0.5rem;
`

type ColumnIsInView = { [columnName: string]: { isInView: boolean } }

interface ColumnSelectorProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (columns: ColumnIsInView) => void
  initialColumns: ColumnIsInView
}

const ColumnSelector = ({
  isOpen,
  onClose,
  initialColumns,
  onConfirm,
}: ColumnSelectorProps) => {
  const { t } = useTranslation()
  const [columns, setColumns] = useState(initialColumns)

  const hasReachedLimit = useMemo(() => {
    const inViewCount = Object.values(columns).reduce((prev, curr) => {
      if (curr.isInView) {
        return prev + 1
      }
      return prev
    }, 0)
    return inViewCount >= MAX_TABLE_COLUMN_COUNT
  }, [columns])

  useEffect(() => {
    setColumns(initialColumns)
  }, [initialColumns])
  const handleColumnToggle = (columnName) => {
    if (hasReachedLimit && !columns[columnName].isInView) {
      return
    }
    setColumns((prev) => {
      return {
        ...prev,
        [columnName]: { isInView: !columns[columnName].isInView },
      }
    })
  }

  const handleToggleAll = (allIsInView: boolean) => {
    const newColumns: ColumnIsInView = {}
    Object.entries(initialColumns).forEach((col, index) => {
      let isInView = false
      if (allIsInView && index + 1 <= MAX_TABLE_COLUMN_COUNT) {
        isInView = true
      }
      const [key, value] = col
      newColumns[key] = { ...value, isInView }
    })
    setColumns(newColumns)
  }

  const handleClose = () => {
    setColumns(initialColumns)
    onClose()
  }
  return (
    <IWModal open={isOpen} onClose={handleClose}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('insightManager.rawDataTable.modal.manageColumns')}
        </IWTypography>
      </IWModalHeader>
      <StyledIWModalContent>
        <StyledModalText>
          <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
            {t('insightManager.rawDataTable.modal.maximumColumns', {
              maxTableColumnCount: MAX_TABLE_COLUMN_COUNT,
            })}
          </IWTypography>
          <StyledModalToggleTextButtons>
            <IWButton
              variant="anchorMain"
              color="primary"
              onClick={() => handleToggleAll(false)}
            >
              {t('insightManager.rawDataTable.modal.toggleOff')}
            </IWButton>
            <span>/</span>
            <IWButton
              variant="anchorMain"
              color="primary"
              onClick={() => handleToggleAll(true)}
            >
              {t('insightManager.rawDataTable.modal.toggleOn')}
            </IWButton>
          </StyledModalToggleTextButtons>
        </StyledModalText>
        <IWToggleGroup>
          {Object.entries(columns).map((e) => {
            const [columnName, columnMetadata] = e
            return (
              <IWToggle
                key={columnName}
                id={columnName}
                label={columnName}
                name={columnName}
                checked={columnMetadata.isInView}
                disabled={!columnMetadata.isInView && hasReachedLimit}
                onChange={() => handleColumnToggle(columnName)}
              />
            )
          })}
        </IWToggleGroup>
      </StyledIWModalContent>
      <IWModalFooter>
        <StyledActions>
          <IWButton onClick={handleClose} variant="outline" color="grey">
            {t('button.cancel')}
          </IWButton>
          <IWButton
            onClick={() => onConfirm(columns)}
            variant="main"
            color="primary"
          >
            {t('button.confirm')}
          </IWButton>
        </StyledActions>
      </IWModalFooter>
    </IWModal>
  )
}

interface Props {
  datasource?: string
  onDownload: (items) => void
  timezone?: string
}

interface Metadata {
  numRows: number
  columns: {
    [columnName: string]: {
      type: 'string' | 'number' | 'datetime'
      isInView: boolean
    }
  }
  minTime: string
  maxTime: string
}

const RawDataTable = ({ datasource, onDownload, timezone = 'UTC' }: Props) => {
  const { t } = useTranslation()
  const [metadata, setMetadata] = useState<Metadata>({
    numRows: 0,
    columns: {},
    minTime: '',
    maxTime: '',
  })

  const {
    filtersState,
    filtersInView,
    handleFilterAdd,
    handleFilterRemove: onFilterRemove,
    setDefaultFilters,
    handleFilterClose,
    handleFilterOpen,
    setDefaultOptions,
    handleFilterConfirm: onFilterConfirm,
  } = useFilterBadges()

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)

  const [page, setPage] = useState({
    current: 1,
    size: paginationOptions[0].value,
  })

  const [requestBody, setRequestBody] = useState<RawDataArgs>()
  const { pinnedColumns, handlePinColumns } = usePinnedColumns()
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [isFetchingDataForDownload, setIsFetchingDataForDownload] =
    useState(false)

  const [sort, setSort] = useState<
    | {
        dimension: string
        direction: SortDirection
      }
    | undefined
  >()

  const [debouncer] = useState<{ timeout: NodeJS.Timeout | null }>({
    timeout: null,
  })

  const handleFilterConfirm = (id, state) => {
    setPage((prevState) => ({ current: 1, size: prevState.size }))
    onFilterConfirm(id, state)
  }

  const handleFilterRemove = (id) => {
    setPage((prevState) => ({ current: 1, size: prevState.size }))
    onFilterRemove(id)
  }

  useEffect(() => {
    if (debouncer.timeout) {
      clearTimeout(debouncer.timeout)
    }
    if (!datasource) {
      return
    }
    debouncer.timeout = setTimeout(() => {
      const { virtualColumns, filters, intervals } = filtersToDruid(
        filtersState,
        {
          minTime: DateTime.local().minus({ year: 1000 }),
          maxTime: DateTime.local().plus({ year: 1000 }),
        },
        timezone,
      )
      setRequestBody({
        datasource,
        pageSize: page.size,
        pageNumber: page.current - 1,
        columns: Object.entries(metadata.columns)
          .filter((e) => e[1].isInView)
          .map((e) => e[0]),
        intervals,
        filter:
          filters.length > 0
            ? {
                type: 'and',
                fields: filters,
              }
            : undefined,
        virtualColumns,
        sort: sort?.direction,
      })
    }, DEBOUNCE_TIME)
  }, [page, sort, datasource, metadata, debouncer, filtersState, timezone])

  const {
    data: initMeta,
    isError: isMetaError,
    isLoading: isInitLoading,
  } = useQuery(['metadata', datasource], ({ queryKey }) => {
    const [, body] = queryKey
    if (!body || datasource === undefined) {
      throw new Error('Missing datasource')
    }
    return getDatasourceMetadata(body)
  })

  useEffect(() => {
    if (!initMeta) {
      return
    }
    // this assumes only min time and max time would ever change since columns in are fixed
    if (
      metadata.minTime === initMeta.minTime.toISO() &&
      metadata.maxTime === initMeta.maxTime.plus({ day: 1 }).toISO()
    ) {
      return
    }
    const newColumns: Metadata['columns'] = {}
    const defaultFilters: UseFilterBadgesHookProps = {}
    Object.entries(initMeta.columns).forEach((column, index) => {
      const [columnName, columnMetadata] = column
      const isInView = index + 1 <= MAX_TABLE_COLUMN_COUNT
      newColumns[columnName] = { type: columnMetadata.type, isInView }
      defaultFilters[columnName] = {
        type: columnMetadata.type,
        isInView: false,
        isOpen: false,
      }
    })
    setMetadata({
      ...initMeta,
      minTime: initMeta.minTime.toISO(),
      // requesting data to druid with max time is never inclusive, so we add one
      maxTime: initMeta.maxTime.plus({ day: 1 }).toISO(),
      columns: newColumns,
    })
    setDefaultFilters(defaultFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initMeta])

  const {
    data,
    isLoading,
    isPreviousData,
    isError: isDataError,
  } = useQuery(
    ['rawData', requestBody],
    ({ queryKey }) => {
      const [, body] = queryKey
      if (!body || typeof body === 'string' || datasource === undefined) {
        throw new Error('Missing datasource')
      }
      return getRawData(body)
    },
    {
      staleTime: 30 * 1000 * 60,
      enabled: Object.keys(metadata.columns).length > 0,
      keepPreviousData: true,
    },
  )

  const handleConfirm = (newColumns: ColumnIsInView) => {
    setMetadata((prev) => {
      const newColumnsMetadata: Metadata['columns'] = {}
      Object.entries(prev.columns).forEach((column) => {
        const [columnName, meta] = column
        newColumnsMetadata[columnName] = {
          ...meta,
          isInView: newColumns[columnName].isInView,
        }
      })
      return {
        ...prev,
        columns: newColumnsMetadata,
      }
    })
    setIsColumnMenuOpen(false)
  }

  const tableColumns = Object.entries(metadata.columns)
    .filter((e) => e[1].isInView)
    .map((e) => ({
      title: e[0],
      accessor: e[0],
      align: e[1].type === 'number' ? 'right' : 'left',
      sortable: e[0] === '__time',
      pinnable: true,
    }))

  const formattedData = useMemo(() => {
    if (!data) {
      return []
    }
    const numberColumns = Object.entries(metadata.columns)
      .map((col) => {
        return { dimension: col[0], type: col[1].type }
      })
      .filter((col) => col.type === 'number')

    const timeColumns = Object.entries(metadata.columns)
      .map((col) => {
        return { dimension: col[0], type: col[1].type }
      })
      .filter((col) => col.type === 'datetime')

    return data.events.map((elem) => {
      const shallowCopy = { ...elem }
      numberColumns.forEach((col) => {
        if (typeof elem[col.dimension] === 'number') {
          shallowCopy[col.dimension] = round(
            elem[col.dimension],
            ROUND_PRECISION,
          )
        }
      })
      timeColumns.forEach((col) => {
        shallowCopy[col.dimension] = DateTime.fromISO(
          shallowCopy[col.dimension],
          { zone: timezone },
        ).toLocaleString(DateTime.DATETIME_SHORT)
      })
      return shallowCopy
    })
  }, [data, metadata.columns, timezone])

  const downloadRawData = async () => {
    setIsFetchingDataForDownload(true)
    const { intervals, filters, virtualColumns } = filtersToDruid(
      filtersState,
      {
        minTime: DateTime.local().minus({ year: 1000 }),
        maxTime: DateTime.local().plus({ year: 1000 }),
      },
      timezone,
    )

    const body = {
      datasource,
      intervals,
      pageSize: DOWNLOAD_ROW_LIMIT,
      pageNumber: 0,
      columns: Object.entries(metadata.columns).map((e) => e[0]),
      sort,
      filter:
        filters.length > 0
          ? {
              type: 'and',
              fields: filters,
            }
          : undefined,
      virtualColumns,
    }

    const rawData = await getRawData(body as RawDataArgs)

    const numberColumns = Object.entries(metadata.columns)
      .map((col) => {
        return { dimension: col[0], type: col[1].type }
      })
      .filter((col) => col.type === 'number')

    const timeColumns = Object.entries(metadata.columns)
      .map((col) => {
        return { dimension: col[0], type: col[1].type }
      })
      .filter((col) => col.type === 'datetime')

    const formattedRawData = rawData.events.map((elem) => {
      const shallowCopy = { ...elem }
      numberColumns.forEach((col) => {
        if (typeof elem[col.dimension] === 'number') {
          shallowCopy[col.dimension] = round(
            elem[col.dimension],
            ROUND_PRECISION,
          )
        }
      })
      timeColumns.forEach((col) => {
        shallowCopy[col.dimension] = DateTime.fromISO(
          shallowCopy[col.dimension],
          { zone: timezone },
        ).toISO({
          includeOffset: false,
        })
      })
      return shallowCopy
    })

    onDownload(formattedRawData)
    setIsFetchingDataForDownload(false)
  }

  if (isInitLoading) {
    return <IWLoading />
  }

  if (isMetaError || isDataError) {
    return <IWError />
  }

  async function handleGetOptions(columnName) {
    if (datasource) {
      const top = await getTopColumnValues(datasource, columnName)
      setDefaultOptions(columnName, top)
    }
  }

  async function handleSearchTextOptions(
    columnName: string,
    searchValue: string,
  ) {
    if (!datasource) {
      return []
    }
    if (debouncer.timeout) {
      clearTimeout(debouncer.timeout)
    }
    const debouncedFn = new Promise<DropdownValueProps[]>((resolve) => {
      debouncer.timeout = setTimeout(async () => {
        const searchOptions = await searchColumnValues(
          datasource,
          columnName,
          searchValue,
        )
        const dropdownOptions = searchOptions.map((option) => ({
          value: option,
          label: option,
        }))
        resolve(dropdownOptions)
      }, DEBOUNCE_TIME)
    })
    return debouncedFn
  }

  const handleDownload = async () => {
    if (data && data.count > DOWNLOAD_ROW_LIMIT) {
      setIsDownloadModalOpen(true)
    } else {
      await downloadRawData()
    }
  }

  const filterMenu = Object.entries(metadata.columns).map((elem) => {
    return (
      <IWContextMenuItem
        as="button"
        key={elem[0]}
        label={elem[0]}
        onClick={() => {
          if (
            elem[1].type === 'string' &&
            !filtersState[elem[0]].defaultOptions
          ) {
            handleGetOptions(elem[0])
          }
          handleFilterAdd(elem[0])
          setIsFilterMenuOpen(false)
        }}
      />
    )
  })
  return (
    <StyledMainDiv>
      <IWContextMenu
        positions={['bottom']}
        align="start"
        menu={filterMenu}
        isOpen={isFilterMenuOpen}
        onClickOutside={() => setIsFilterMenuOpen(false)}
      >
        <StyledFilterButton
          variant="outline"
          color="grey"
          icon={faFilter}
          iconPosition="leading"
          onClick={() => setIsFilterMenuOpen(true)}
        >
          Filters
        </StyledFilterButton>
      </IWContextMenu>
      {filtersInView.length > 0 && (
        <IWFilterBadgesGroup label={t(`filterBadgesGroup.appliedFilters`)}>
          {filtersInView.map((col) => {
            const { name, type, isOpen } = col
            if (type === 'datetime') {
              return (
                <IWFilterBadgesDatetime
                  id={name}
                  key={name}
                  onConfirm={handleFilterConfirm}
                  onClick={() => handleFilterOpen(name)}
                  onCancel={handleFilterClose}
                  isOpen={isOpen}
                  disabledRelativeOptions={[
                    'firstDateAvailable',
                    'lastDateAvailable',
                  ]}
                  onRemove={handleFilterRemove}
                />
              )
            }
            if (type === 'number') {
              return (
                <IWFilterBadgesRange
                  id={name}
                  key={name}
                  onConfirm={handleFilterConfirm}
                  onCancel={handleFilterClose}
                  onClick={() => handleFilterOpen(name)}
                  isOpen={isOpen}
                  onRemove={handleFilterRemove}
                />
              )
            }
            if (type === 'string') {
              return (
                <IWFilterBadgesOptions
                  id={name}
                  key={name}
                  onClick={() => handleFilterOpen(name)}
                  isOpen={isOpen}
                  onConfirm={handleFilterConfirm}
                  onRemove={handleFilterRemove}
                  onCancel={handleFilterClose}
                  defaultOptions={
                    col.defaultOptions?.map((e) => ({ label: e, value: e })) ||
                    []
                  }
                  onGetOptions={async (inputValue) => {
                    return handleSearchTextOptions(name, inputValue)
                  }}
                />
              )
            }
            return <></>
          })}
        </IWFilterBadgesGroup>
      )}
      <Wrapper>
        {(!isLoading || isPreviousData) && formattedData.length === 0 ? (
          <IWNoResultsMessage />
        ) : (
          <IWTable
            border
            columns={tableColumns}
            data={formattedData}
            isLoading={isLoading || isPreviousData}
            sortedBy={sort ? { [sort.dimension]: sort.direction } : undefined}
            onSort={(dimension, direction) => {
              if (direction === null) {
                setSort(undefined)
              } else {
                setSort({
                  dimension,
                  direction,
                })
              }
            }}
            pinnedColumns={pinnedColumns}
            onPin={handlePinColumns}
          />
        )}
        <PaginationWrapper>
          <IWButtonGroup color="primary" variant="outline" hasSolidBackground>
            <IWButton
              disabled={isLoading || isPreviousData}
              onClick={() => {
                setIsColumnMenuOpen(true)
              }}
            >
              <FontAwesomeIcon icon={faSlidersSimple} />
            </IWButton>
            <IWButton
              iconPosition="leading"
              disabled={
                isLoading || isPreviousData || isFetchingDataForDownload
              }
              icon={faDownload}
              onClick={handleDownload}
            >
              {t('insightManager.pods.actions.download')}
            </IWButton>
          </IWButtonGroup>
          <IWPaginationControls
            perPageLabel={t('tables.rowsPerPage')}
            itemsPerPage={page.size}
            perPageOptions={paginationOptions}
            totalItems={data?.count || 0}
            currentPage={page.current}
            onChangePage={(newPage) =>
              setPage((prev) => ({ ...prev, current: newPage }))
            }
            onChangeItemsPerPage={(newSize) =>
              setPage({ current: 1, size: newSize })
            }
            isLoading={isLoading || isPreviousData}
          />
        </PaginationWrapper>
      </Wrapper>
      <ColumnSelector
        isOpen={isColumnMenuOpen}
        onClose={() => {
          setIsColumnMenuOpen(false)
        }}
        onConfirm={handleConfirm}
        initialColumns={metadata.columns}
      />
      <DataDownloadModal
        isOpen={isDownloadModalOpen}
        isDownloading={isFetchingDataForDownload}
        dataCount={data?.count}
        onClose={() => {
          setIsDownloadModalOpen(false)
        }}
        onProcess={downloadRawData}
      />
    </StyledMainDiv>
  )
}

export default RawDataTable
