import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
  StyledActions,
} from 'shared/components/thunderbolt/IWModal'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { DOWNLOAD_ROW_LIMIT } from '../constants'

export interface DownloadModalProps {
  isOpen: boolean
  isDownloading: boolean
  dataCount: number
  onClose: () => void
  onProcess: () => void
}

const StyledIWModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const DataDownloadModal = ({
  isOpen,
  isDownloading,
  onClose,
  onProcess,
  dataCount,
}: DownloadModalProps) => {
  const { t } = useTranslation()
  return (
    <IWModal open={isOpen} onClose={onClose}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('tables.downloadModal.downloadHeader')}
        </IWTypography>
      </IWModalHeader>
      <StyledIWModalContent>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t('insightManager.rawDataTable.modal.downloadDesc', {
            totalItems: dataCount,
            downloadRowLimit: DOWNLOAD_ROW_LIMIT,
          })}
        </IWTypography>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t('tables.downloadModal.downloadDescQuestion')}
        </IWTypography>
      </StyledIWModalContent>
      <IWModalFooter>
        <StyledActions>
          <IWButton onClick={onClose} variant="outline" color="grey">
            {t('button.cancel')}
          </IWButton>
          <IWButton
            onClick={async () => {
              await onProcess()
              onClose()
            }}
            variant="main"
            color="primary"
            disabled={isDownloading}
          >
            {t('button.proceed')}
          </IWButton>
        </StyledActions>
      </IWModalFooter>
    </IWModal>
  )
}

export default DataDownloadModal
