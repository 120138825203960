import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWDropdown, {
  DropdownValueProps,
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { MeasureCondition } from 'shared/hooks/useMeasureRangeConditions'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWMeasureRangeFilter from 'shared/components/thunderbolt/IWMeasureRangeFilter'
import { Popover } from 'react-tiny-popover'
import {
  Comparison,
  MeasureConditionOperators,
  MeasureConditionValueTypes,
} from '../types'
import { mapToDropdownOptions } from '../utils/utils'

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledPopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
  min-width: 400px;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

interface Props {
  measureConditions: MeasureCondition[]
  measureOptions: DropdownValueProps[]
  addMeasureCondition: () => void
  deleteMeasureCondition: (id: string) => void
  selectedMeasureOption?: DropdownValueProps
  selectedValueTypeOption?: DropdownValueProps
  selectedComparison?: Comparison
  onMeasureChange: (value: DropdownValueProps | undefined) => void
  onValueTypeChange: (value: DropdownValueProps | undefined) => void
  onFirstConditionChange: (
    value: GenericDropdownValue<MeasureConditionOperators>,
    id: string,
  ) => void
  onSecondConditionChange: (
    value: GenericDropdownValue<MeasureConditionOperators>,
    id: string,
  ) => void
  onFirstConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onSecondConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  showErrors?: boolean
}

export default function MeasureRangeFilterWrapper({
  measureConditions,
  measureOptions,
  addMeasureCondition,
  deleteMeasureCondition,
  onMeasureChange,
  onValueTypeChange,
  selectedMeasureOption,
  selectedValueTypeOption,
  selectedComparison,
  onFirstConditionChange,
  onSecondConditionChange,
  onFirstConditionValueChange,
  onSecondConditionValueChange,
  showErrors,
}: Props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [metricOptions] = useState<DropdownValueProps[]>(measureOptions)
  const [valueTypeOptions, setValueTypeOptions] = useState<
    DropdownValueProps[]
  >([])

  const handleMeasureDropdownUpdate = (
    selectedMetricOption?: DropdownValueProps,
  ) => {
    setValueTypeOptions((prevState) => {
      return prevState.map((v) => ({
        ...v,
        isDisabled: false,
      }))
    })

    if (selectedComparison === 'noComparison') {
      const measureConditionValueTypes: MeasureConditionValueTypes[] = [
        'absoluteValue',
        'delta',
        'percentDelta',
      ]
      setValueTypeOptions(
        mapToDropdownOptions<MeasureConditionValueTypes>(
          measureConditionValueTypes,
          (key) => t(`rangeFilterSection.${key}`),
        ).map((v) => ({
          ...v,
          isDisabled: v.value !== 'absoluteValue',
        })),
      )
    }

    if (selectedComparison !== 'noComparison' && selectedMetricOption) {
      const allMeasureWithSelectedMetrics = measureConditions.filter(
        (m) => m.selectedMeasureOption?.value === selectedMetricOption.value,
      )

      allMeasureWithSelectedMetrics.forEach((m) => {
        setValueTypeOptions((prevState) => {
          const options = [...prevState]
          const index = options.findIndex(
            (v) => v.value === m.selectedValueTypeOption?.value,
          )
          options[index].isDisabled = true
          return options
        })
      })
    }
  }

  const handleMetricOnChangeOptions = (option: DropdownValueProps) => {
    handleMeasureDropdownUpdate(option)
    onMeasureChange(option)
  }

  const handleValueTypeOnChangeOptions = (option: DropdownValueProps) => {
    handleMeasureDropdownUpdate()
    onValueTypeChange(option)
  }

  const onConfirmHandler = () => {
    addMeasureCondition()
    setIsPopupOpen(false)
  }

  const onCancelHandler = () => {
    setIsPopupOpen(false)
    onMeasureChange(undefined)
    onValueTypeChange(undefined)
  }

  useEffect(() => {
    handleMeasureDropdownUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComparison])

  useEffect(() => {
    if (isPopupOpen) {
      // FIXME: This is a hack to ensure the datepicker is visible
      const formDiv = document.getElementById('form-div')
      if (formDiv) {
        formDiv.style.paddingBottom = '25rem'
      }
      return
    }
    const formDiv = document.getElementById('form-div')
    if (formDiv) {
      formDiv.style.paddingBottom = '8rem'
    }
  }, [isPopupOpen])

  return (
    <>
      {measureConditions.map((measure) => (
        <IWMeasureRangeFilter
          key={measure.id}
          measure={measure}
          showErrors={showErrors}
          onFirstConditionChange={onFirstConditionChange}
          onFirstConditionValueChange={onFirstConditionValueChange}
          onSecondConditionChange={onSecondConditionChange}
          onSecondConditionValueChange={onSecondConditionValueChange}
          onDelete={deleteMeasureCondition}
        />
      ))}
      <Popover
        padding={4}
        reposition
        containerStyle={{ zIndex: theme.layers.popover }}
        positions={['bottom']}
        isOpen={isPopupOpen}
        onClickOutside={() => setIsPopupOpen(false)}
        content={() => (
          <StyledPopupDiv onClick={(e) => e.stopPropagation()}>
            <StyledSection>
              <IWDropdown
                options={metricOptions}
                required
                value={selectedMeasureOption}
                label={t(
                  `alertsManager.createAlert.triggerStep.measureCondition.measure`,
                )}
                onChange={handleMetricOnChangeOptions}
                hasError={showErrors && !selectedMeasureOption}
                errorText={
                  showErrors && !selectedMeasureOption
                    ? t(`alertsManager.errors.enterAllRequired`)
                    : undefined
                }
              />
              <IWDropdown
                options={valueTypeOptions}
                required
                value={selectedValueTypeOption}
                label={t(
                  `alertsManager.createAlert.triggerStep.measureCondition.valueType`,
                )}
                onChange={handleValueTypeOnChangeOptions}
                disabled={!selectedMeasureOption}
                hasError={showErrors && !selectedValueTypeOption}
                errorText={
                  showErrors && !selectedValueTypeOption
                    ? t(`alertsManager.errors.enterAllRequired`)
                    : undefined
                }
              />
            </StyledSection>
            <StyledButtonDiv>
              <IWButton
                data-testid="create-alert-measure-cancel"
                variant="alternative"
                onClick={onCancelHandler}
              >
                {t(`button.cancel`)}
              </IWButton>
              <IWButton
                data-testid="create-alert-measure-confirm"
                onClick={onConfirmHandler}
                disabled={!selectedMeasureOption || !selectedValueTypeOption}
              >
                {t(`button.confirm`)}
              </IWButton>
            </StyledButtonDiv>
          </StyledPopupDiv>
        )}
      >
        <IWButton
          data-testid="measure-range-filter-add"
          type="button"
          color="primary"
          icon={faPlusCircle}
          iconPosition="leading"
          onClick={() => setIsPopupOpen(true)}
          disabled={
            (selectedComparison === 'noComparison' &&
              metricOptions.length === measureConditions.length) ||
            (selectedComparison === 'compareGenerationTimes' &&
              measureConditions.length ===
                metricOptions.length * valueTypeOptions.length)
          }
        >
          {t('rangeFilterSection.rangeConditions')}
        </IWButton>
      </Popover>
    </>
  )
}
