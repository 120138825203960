import { useEffect, useState } from 'react'
import { GenericDropdownValue } from 'shared/components/thunderbolt/IWDropdown'
import {
  ImportManagerSchema,
  ImportManagerSourceSchema,
  Mapping,
} from '../types'
import { validateArrayWithZod } from 'shared/helpers'
import { z, ZodError } from 'zod'
import { createZodObject } from '../helpers'

type UseDataMappingProps = {
  targetSchema?: ImportManagerSchema
  sourceSchema?: ImportManagerSourceSchema[]
  importedData?: Record<string, string>[] | undefined
}

type UseDataMappingHook = {
  options: GenericDropdownValue<number>[]
  dataMappings: Mapping[]
  handleOnMappingChange: (
    id: string,
    targetColumnName: string,
    sourceColumnValue: number,
  ) => void
  hasError: boolean
}

const useDataMapping = ({
  targetSchema,
  sourceSchema,
  importedData,
}: UseDataMappingProps): UseDataMappingHook => {
  const [dataMappings, setDataMappings] = useState(targetSchema?.mapping || [])
  const [hasError, setHasError] = useState<boolean>(false)
  const options =
    sourceSchema?.map<GenericDropdownValue<number>>(
      ({ columnName, columnIndex }) => ({
        label: columnName,
        value: columnIndex,
      }),
    ) || []

  useEffect(() => {
    try {
      const zodValidationObject = createZodObject(targetSchema?.mapping || [])
      let dataToValidate: Array<any> = [...(importedData || [])]

      ;(dataMappings || []).forEach((mapping) => {
        const inputFileColumnIndexToUse = mapping.sourceColumn

        const inputFileColumnNameToUse =
          sourceSchema?.find(
            (schemaWithSampleDataRow) =>
              schemaWithSampleDataRow.columnIndex === inputFileColumnIndexToUse,
          )?.columnName || ''

        const dropDownSchemaColumnName = mapping.targetColumnName

        if (inputFileColumnIndexToUse > -1) {
          dataToValidate = dataToValidate.map((data) => {
            const { [inputFileColumnNameToUse]: valueToRename, ...rest } = data
            const updatedRow = {
              ...rest,
              [dropDownSchemaColumnName]: valueToRename,
            }
            return updatedRow
          })
        }
      })

      validateArrayWithZod<z.infer<typeof zodValidationObject>>(
        dataToValidate,
        zodValidationObject,
      )

      dataMappings.forEach((m, i) => {
        delete dataMappings[i].errors
      })

      setHasError(false)
    } catch (e) {
      if (e instanceof ZodError) {
        dataMappings.forEach((m, i) => {
          const columnHaveErr = e.issues.find((err) =>
            err.path.includes(m.targetColumnName),
          )
          if (columnHaveErr) {
            dataMappings[i].errors = columnHaveErr
          } else {
            delete dataMappings[i].errors
          }
          return m
        })
        setHasError(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMappings])

  const handleOnMappingChange = (
    id: string,
    targetColumnName: string,
    sourceColumnValue: number,
  ) => {
    setDataMappings((prev) => {
      const indexOfMapping = prev.findIndex(
        (item) => item.targetColumnName === targetColumnName,
      )
      prev[indexOfMapping].sourceColumn = sourceColumnValue
      return [...prev]
    })
  }

  return { options, dataMappings, handleOnMappingChange, hasError }
}

export default useDataMapping
