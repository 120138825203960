import React from 'react'
import styled from 'styled-components'
import AlertCard from './AlertCard'
import { AlertSummary } from '../types'
import { OrgUser } from '../../../shared/userServiceClient'

type Props = {
  /** Array of Alert Summary objects */
  alerts: AlertSummary[]
  orgUsersMap: { [userId: string]: OrgUser }
  /** Handler to call when delete alert is selected */
  onDelete: (id, name) => void
  /** Toggles paused states of the alert */
  onToggleAlertIsPaused: (id, name) => void
  isLoading: boolean
}

const StyledCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`

/**
 * A list to display an array of alert cards plus in memory pagination
 * All items are held in memory and the items array is sliced based on current page and items per page
 * to determine which items to display at any given time
 */
const AlertCardList = ({
  onToggleAlertIsPaused,
  alerts,
  onDelete,
  orgUsersMap,
  isLoading,
}: Props) => {
  return (
    <StyledCardList data-testid="alert-card-list">
      {alerts.map((alert) => {
        return (
          <AlertCard
            orgUsersMap={orgUsersMap}
            onToggleAlertIsPaused={onToggleAlertIsPaused}
            key={alert.userAlertId}
            alert={alert}
            onDelete={() => onDelete(alert.userAlertId, alert.userAlertName)}
            isLoading={isLoading}
          />
        )
      })}
    </StyledCardList>
  )
}

export default AlertCardList
