import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWDatePicker from './IWDatePicker'
import IWDropdown from './IWDropdown'
import IWTextInput from './IWTextInput'
import { IWInputInfo } from './IWCommonLabels'

interface DropdownOption<T> {
  value: T
  isDisabled?: boolean
}

export type MainOptions =
  | 'previous'
  | 'next'
  | 'on'
  | 'before'
  | 'after'
  | 'between'

type TimeLengthOptions = 'days' | 'weeks' | 'months' | 'years'

type RelativeOptions =
  | 'today'
  | 'specificDate'
  | 'lastDateAvailable'
  | 'firstDateAvailable'

type RelativeDropdownOption = DropdownOption<RelativeOptions>

type TimeLengthDropdownOption = DropdownOption<TimeLengthOptions>

const StyledVerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledVerticalFlexWidth = styled(StyledVerticalFlex)<{
  type?: MainOptions
}>`
  max-width: ${(props) => (props?.type === 'between' ? '650px' : '400px')};
`

const StyledHorizontalFlex = styled.div`
  display: flex;
  gap: 1rem;
`

const StyledDualDatePicker = styled(StyledHorizontalFlex)`
  flex-wrap: wrap;
`

const StyledTimeLengthInput = styled.span`
  width: 30%;
`

const StyledTimeLengthDropdown = styled.span`
  flex-grow: 1;
`

type SharedRelativeProps = TimeLengthProps &
  SpecificDatePickerProps &
  TimeLengthValueProps &
  RelativeOptionProps

interface RelativeProps extends SharedRelativeProps {
  type: Extract<MainOptions, 'previous' | 'next'>
}

const Relative = ({
  type,
  selectedRelativeOption,
  onRelativeOptionChange,
  selectedSpecificDatePickerDate,
  onSpecificDatePickerChange,
  selectedTimeLengthOption,
  onTimeLengthOptionChange,
  timeLengthValue,
  maxTimeLengthValue = 100,
  onTimeLengthValueChange,
  isSpecificDatePickerError,
  isRelativeOptionError,
  isTimeLengthOptionError,
  isTimeLengthValueError,
  disabledRelativeOptions,
}: RelativeProps) => {
  const { t } = useTranslation()
  const timeLengthOptions: TimeLengthDropdownOption[] = useMemo(() => {
    return [
      { value: 'days' },
      { value: 'weeks' },
      { value: 'months' },
      { value: 'years' },
    ]
  }, [])

  const relativeOptions: RelativeDropdownOption[] = useMemo(() => {
    const values: RelativeDropdownOption[] = [
      { value: 'today' },
      {
        value: 'lastDateAvailable',
        isDisabled: type === 'next',
      },
      {
        value: 'firstDateAvailable',
        isDisabled: type === 'previous',
      },
    ]
    return values.filter(
      ({ value }) => !disabledRelativeOptions?.includes(value),
    )
  }, [type, disabledRelativeOptions])
  return (
    <StyledVerticalFlex>
      <StyledHorizontalFlex>
        <StyledTimeLengthInput>
          <IWTextInput
            type="number"
            min="1"
            max={maxTimeLengthValue}
            onKeyDown={(evt) =>
              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
            }
            hasError={isTimeLengthValueError}
            value={timeLengthValue || ''}
            onChange={(e) => {
              let val = parseInt(e.target.value, 10)
              val = val > maxTimeLengthValue ? maxTimeLengthValue : val
              onTimeLengthValueChange(Number.isNaN(val) ? undefined : val)
            }}
          />
        </StyledTimeLengthInput>
        <StyledTimeLengthDropdown>
          <IWDropdown
            hasError={isTimeLengthOptionError}
            options={timeLengthOptions}
            getOptionLabel={(option) => {
              const o = option as TimeLengthDropdownOption
              return t(`relativeDatePicker.timeLengthOptions.${o.value}`)
            }}
            value={selectedTimeLengthOption || ''}
            onChange={(val) =>
              onTimeLengthOptionChange(val as TimeLengthDropdownOption)
            }
          />
        </StyledTimeLengthDropdown>
      </StyledHorizontalFlex>

      <IWDropdown
        label={t('relativeDatePicker.labels.relativeTo')}
        hasError={isRelativeOptionError}
        options={relativeOptions}
        getOptionLabel={(option) => {
          const o = option as RelativeDropdownOption
          return t(`relativeDatePicker.relativeOptions.${o.value}`)
        }}
        value={selectedRelativeOption || ''}
        onChange={(val) =>
          onRelativeOptionChange(val as RelativeDropdownOption)
        }
      />
      {selectedRelativeOption?.value === 'specificDate' && (
        <IWDatePicker
          label={t('relativeDatePicker.labels.date')}
          hasError={isSpecificDatePickerError}
          selectedDate={selectedSpecificDatePickerDate}
          onChange={onSpecificDatePickerChange}
        />
      )}
    </StyledVerticalFlex>
  )
}

const CenteredHorizontalFlex = styled(StyledHorizontalFlex)`
  justify-content: center;
`

type MainDropdownOption = DropdownOption<MainOptions>

interface MainOptionProps {
  selectedMainOption?: MainDropdownOption
  isMainOptionError?: boolean
  onMainOptionChange: (value?: MainDropdownOption) => void
  disabledMainOptions?: MainOptions[]
}

interface DualDatePickerProps {
  selectedDatePickerMin?: DateTime
  isDatePickerMinError?: boolean
  onDatePickerMinChange: (date: DateTime) => void
  selectedDatePickerMax?: DateTime
  isDatePickerMaxError?: boolean
  onDatePickerMaxChange: (date: DateTime) => void
  hasDualDatePickerError: boolean
}

interface TimeLengthProps {
  selectedTimeLengthOption?: TimeLengthDropdownOption
  isTimeLengthOptionError?: boolean
  onTimeLengthOptionChange: (value?: TimeLengthDropdownOption) => void
  maxTimeLengthValue?: number
}

interface SpecificDatePickerProps {
  selectedSpecificDatePickerDate?: DateTime
  isSpecificDatePickerError?: boolean
  onSpecificDatePickerChange: (date: DateTime) => void
}

interface TimeLengthValueProps {
  timeLengthValue?: number
  isTimeLengthValueError?: boolean
  onTimeLengthValueChange: (value?: number) => void
}

interface RelativeOptionProps {
  selectedRelativeOption?: RelativeDropdownOption
  isRelativeOptionError?: boolean
  disabledRelativeOptions?: RelativeOptions[]
  onRelativeOptionChange: (value?: RelativeDropdownOption) => void
}

export type Props = MainOptionProps &
  DualDatePickerProps &
  SharedRelativeProps & { shouldDisplayErrors?: boolean }

/**
 * Allows users to select dates within some relative timeframe. For example, the next 20 days or any days between to specific dates. It is built on top of the `<IWDatePicker />` component. It comes with a useful hook (`useIWRelativeDatePicker`) so users can get immediate default functionality without having to define all the props. The hook is used in the stories presented here to provide a detailed example.
 *
 * A wrapper component `<IWRelativeDatePickerWrapper />` exists that provides a popover and human-readable text based on the selected options.
 */
const IWRelativeDatePicker = ({
  selectedMainOption,
  onMainOptionChange,
  isMainOptionError,
  disabledMainOptions,
  disabledRelativeOptions,
  selectedSpecificDatePickerDate,
  onSpecificDatePickerChange,
  isSpecificDatePickerError,
  selectedDatePickerMin,
  isDatePickerMinError,
  onDatePickerMinChange,
  selectedDatePickerMax,
  isDatePickerMaxError,
  onDatePickerMaxChange,
  hasDualDatePickerError,
  selectedRelativeOption,
  isRelativeOptionError,
  onRelativeOptionChange,
  selectedTimeLengthOption,
  isTimeLengthOptionError,
  onTimeLengthOptionChange,
  timeLengthValue,
  maxTimeLengthValue,
  isTimeLengthValueError,
  onTimeLengthValueChange,
  shouldDisplayErrors,
}: Props) => {
  const { t } = useTranslation()
  const mainDropdownOptions: MainDropdownOption[] = useMemo(() => {
    const values: MainDropdownOption[] = [
      { value: 'previous' },
      { value: 'next' },
      { value: 'on' },
      { value: 'after' },
      { value: 'before' },
      { value: 'between' },
    ]
    return values.filter(({ value }) => !disabledMainOptions?.includes(value))
  }, [disabledMainOptions])

  return (
    <StyledVerticalFlexWidth
      data-testid="relative-date-picker"
      type={selectedMainOption?.value}
    >
      <IWDropdown
        hasError={shouldDisplayErrors && isMainOptionError}
        isClearable
        placeholder={t('placeholders.select')}
        options={mainDropdownOptions || ''}
        getOptionLabel={(option) => {
          const o = option as MainDropdownOption
          return t(`relativeDatePicker.mainOptions.${o.value}`)
        }}
        onChange={(val) => {
          if (val === null) {
            onMainOptionChange(undefined)
          } else {
            onMainOptionChange(val as MainDropdownOption)
          }
        }}
        value={selectedMainOption}
      />

      {(selectedMainOption?.value === 'previous' ||
        selectedMainOption?.value === 'next') && (
        <Relative
          isTimeLengthOptionError={
            shouldDisplayErrors && isTimeLengthOptionError
          }
          isTimeLengthValueError={shouldDisplayErrors && isTimeLengthValueError}
          isRelativeOptionError={shouldDisplayErrors && isRelativeOptionError}
          isSpecificDatePickerError={
            shouldDisplayErrors && isSpecificDatePickerError
          }
          disabledRelativeOptions={disabledRelativeOptions}
          type={selectedMainOption.value}
          selectedTimeLengthOption={selectedTimeLengthOption}
          onTimeLengthOptionChange={onTimeLengthOptionChange}
          timeLengthValue={timeLengthValue}
          maxTimeLengthValue={maxTimeLengthValue}
          onTimeLengthValueChange={onTimeLengthValueChange}
          selectedRelativeOption={selectedRelativeOption}
          onRelativeOptionChange={onRelativeOptionChange}
          selectedSpecificDatePickerDate={selectedSpecificDatePickerDate}
          onSpecificDatePickerChange={onSpecificDatePickerChange}
        />
      )}
      {(selectedMainOption?.value === 'on' ||
        selectedMainOption?.value === 'before' ||
        selectedMainOption?.value === 'after') && (
        <IWDatePicker
          label={t('relativeDatePicker.labels.date')}
          hasError={shouldDisplayErrors && isSpecificDatePickerError}
          onChange={onSpecificDatePickerChange}
          selectedDate={selectedSpecificDatePickerDate}
        />
      )}
      {selectedMainOption?.value === 'between' && (
        <>
          <CenteredHorizontalFlex>
            <StyledDualDatePicker>
              <IWDatePicker
                id="min"
                hasError={shouldDisplayErrors && isDatePickerMinError}
                label={t('relativeDatePicker.labels.from')}
                selectedDate={selectedDatePickerMin}
                onChange={onDatePickerMinChange}
              />
              <IWDatePicker
                id="max"
                hasError={shouldDisplayErrors && isDatePickerMaxError}
                label={t('relativeDatePicker.labels.until')}
                selectedDate={selectedDatePickerMax}
                onChange={onDatePickerMaxChange}
              />
            </StyledDualDatePicker>
          </CenteredHorizontalFlex>
          {shouldDisplayErrors && hasDualDatePickerError && (
            <IWInputInfo
              errorText={t('relativeDatePicker.errors.dualDatePicker')}
            />
          )}
        </>
      )}
    </StyledVerticalFlexWidth>
  )
}

export default IWRelativeDatePicker
