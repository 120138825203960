import cronParser from 'cron-parser'
import cronstrue from 'cronstrue/i18n'

/**
 * Take a cron string as input and returns the previous and next iteration dates as ISO string
 * and the interval as a human readable string
 */
const parseCronString = (
  cronString: string,
  locale: string,
): {
  prevRun: string
  nextRun: string
  interval: string
} => {
  const intervalString = cronstrue.toString(cronString, {
    locale,
    use24HourTimeFormat: true,
    verbose: true, // this forced it to say "every day" in daily cases
  })
  const intervalObject = cronParser.parseExpression(cronString)
  return {
    prevRun: intervalObject.prev().toISOString(),
    nextRun: intervalObject.next().toISOString(),
    interval: intervalString,
  }
}

export default parseCronString
