import {
  faCircleExclamation,
  faCirclePlus,
  faSearch,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { ReactComponent as NoPodsImg } from 'images/no-pods.svg'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import IWError from 'shared/components/thunderbolt/IWError'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import InsightCategoryContext from 'shared/contexts/InsightManagerCategoryContext'
import UserContext from 'shared/contexts/UserContext'
import { goToURL, isPermissionAvailable } from 'shared/helpers'
import useBoundQueryParams from 'shared/hooks/useBoundQueryParams'
import { useDebounceInput } from 'shared/hooks/useDebouncer'
import useGetOrgUsers from 'shared/hooks/useGetOrgUsers'
import {
  deleteInnoPod,
  getPodDatacube,
  getPodList,
  updatePod,
} from 'shared/podServiceClient'
import styled from 'styled-components'
import IWNoResultsMessage from '../../../shared/components/thunderbolt/IWNoResultsMessage'
import ConfirmChangesModal from '../components/ConfirmChangesModal'
import PodCardList from '../components/PodCardList'
import ShareWithModal from '../components/ShareWithModal'
import { Pod } from '../types'
import getInsights from '../utils/getInsights'

const ListControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const Spacer = styled.div`
  flex-grow: 1;
`

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 300px;
`

const StyledPaginationControls = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const MAX_PODS = 25
/**
 * Component to display a list of insights along with search and pagination.
 * It also provides a loading and empty list state
 */
const InsightManager = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const { availableToolPolicies, availablePodTypesMap } =
    useContext(UserContext)
  const modalPromiseRef = useRef<{ resolve: () => void; reject: () => void }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sharingTarget, setSharingTarget] = useState<Pod | null>()
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false)
  const [showShareModal, setShowShareModal] = useState<boolean>(false)

  const { data: orgUsersMap, isLoading: usersInitializing } = useGetOrgUsers()

  const insights = getInsights(t, Object.keys(availablePodTypesMap))
  const dropDownInsights = insights.map(({ type, label }) => {
    return { value: type, label: label }
  })
  const { selectedInsightCategory, setSelectedInsightCategory } = useContext(
    InsightCategoryContext,
  )

  // Permissions
  const canCreatePod = isPermissionAvailable(
    availableToolPolicies,
    'insightsManager:editor',
  )

  // Pagination
  const { setParam, state: queryState } = useBoundQueryParams({
    activeTab: 0,
    page: 1,
    perPage: 10,
  })

  const { perPage, page } = queryState

  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage)
  const [currentPage, setCurrentPage] = useState<number>(page)

  // Searching
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearch = useDebounceInput(searchValue, 500)

  const handleClearInput = () => {
    setSearchValue('')
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const {
    data: pods,
    error,
    refetch,
    isLoading: isLoadingPods,
  } = useQuery(
    [
      'pods',
      'pageNumber',
      currentPage,
      'pageSize',
      itemsPerPage,
      'podName',
      debouncedSearch,
      selectedInsightCategory,
    ],
    () => {
      return getPodList(
        currentPage - 1,
        itemsPerPage,
        debouncedSearch,
        selectedInsightCategory,
      )
    },
  )

  // Watch any values we need to sync with the query params
  useEffect(() => {
    if (itemsPerPage) {
      setParam('perPage', itemsPerPage)
      refetch()
    }
    if (currentPage) {
      setParam('page', currentPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage])

  // Watch for changes to the query to update the local component state on "back"
  useEffect(() => {
    setItemsPerPage(perPage)
    setCurrentPage(page)
  }, [perPage, page])

  const handleDeletePod = async (userPodId, userPodName) => {
    try {
      setIsLoading(true)
      setShowDeleteConfirmModal(false)
      await deleteInnoPod(userPodId)
      toast.secondary(
        t('insightManager.pods.deleteSuccess', { name: userPodName }),
        { canDismiss: true },
      )
    } catch (e) {
      toast.alert(t('insightManager.pods.deleteError', { name: userPodName }))
    } finally {
      setIsLoading(false)
      await refetch()
    }
  }

  const handleConfirmDeletePod = (userPodId, userPodName) => {
    setShowDeleteConfirmModal(true)
    modalPromiseRef.current = {
      resolve: async () => {
        await handleDeletePod(userPodId, userPodName)
        setShowDeleteConfirmModal(false)
      },
      reject: () => {
        setShowDeleteConfirmModal(false)
      },
    }
  }

  const handleSharePod = (userPodId: string) => {
    setSharingTarget(pods?.rows?.find((p) => p.userPodId === userPodId))
    setShowShareModal(true)
  }

  const handleGetPodDataCube = async (userPodId, userPodName) => {
    try {
      setIsLoading(true)
      const url = await getPodDatacube(userPodId)
      goToURL(url)
    } catch (e) {
      toast.alert(t('insightManager.pods.dataCubeError', { name: userPodName }))
    } finally {
      setIsLoading(false)
    }
  }

  const handlePodUpdate = async (podId: string) => {
    try {
      setIsLoading(true)
      await updatePod(podId)
      toast.primary(t('pods.actions.successfulUpdate'), { canDismiss: true })
    } catch (e) {
      toast.alert(t('pods.actions.unsuccessfulUpdate'), { canDismiss: true })
    } finally {
      setIsLoading(false)
      await refetch()
    }
  }

  const EmptyMessage = () => {
    return (
      <EmptyDiv data-testid="empty-message">
        <NoPodsImg />
        <IWTypography weight="bold">
          {t('insightManager.noInnoPods')}
        </IWTypography>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t('insightManager.noInnoPodsCopy')}
        </IWTypography>
      </EmptyDiv>
    )
  }

  if (error) {
    return <IWError />
  }

  return (
    <>
      <main>
        {pods && pods.total >= MAX_PODS && (
          <IWAlert
            icon={faCircleExclamation}
            label={t('insightManager.createPod.alertCreatePodLimit')}
            color="primary"
            variant="alternative"
          />
        )}
        <ListControls>
          <Spacer />
          <StyledIWDropdown
            name="insightManagerDropdown"
            isMulti
            multiline
            value={selectedInsightCategory.map((value) => {
              return {
                value: value,
                label: insights.find((i) => i.type === value)?.label,
              }
            })}
            isSearchable
            placeholder={t(`placeholders.search`)}
            options={dropDownInsights}
            onChange={(selectedValues) => {
              const dropValues = selectedValues.map((v) => v.value)
              setSelectedInsightCategory(dropValues)
            }}
          />
          <IWTextInput
            data-testid="search-input"
            disabled={isLoadingPods || usersInitializing || !pods}
            leadingIcon={{
              icon: faSearch,
            }}
            trailingIcon={{
              icon: faTimes,
              onIconClick: handleClearInput,
            }}
            placeholder={t('placeholders.search')}
            onChange={handleInputChange}
            value={searchValue}
          />
          {/* Its easier to disable a button hence we should not use a routerNavLink or routerLink */}
          {canCreatePod && (
            <IWButton
              data-testid="add-pod-button"
              disabled={
                isLoadingPods ||
                usersInitializing ||
                (pods && pods.total >= MAX_PODS)
              }
              icon={faCirclePlus}
              iconPosition="leading"
              onClick={() => navigate('/tools/insights-manager/create-pod')}
            >
              {t('insightManager.pods.innoPod')}
            </IWButton>
          )}
        </ListControls>
        {(isLoadingPods || usersInitializing) && <IWLoading />}
        {!(isLoadingPods || usersInitializing) && pods?.total === 0 && (
          <EmptyMessage />
        )}
        {!(isLoadingPods || usersInitializing) && pods && pods.total > 0 && (
          <>
            {pods.rows.length > 0 && orgUsersMap?.obj ? (
              <>
                <PodCardList
                  orgUsersMap={orgUsersMap.obj}
                  pods={pods.rows}
                  onGetPodDataCube={handleGetPodDataCube}
                  onDeletePod={handleConfirmDeletePod}
                  onSharePod={handleSharePod}
                  onUpdatePod={handlePodUpdate}
                  isLoading={isLoading}
                />
                <StyledPaginationControls>
                  <IWPaginationControls
                    perPageLabel={t('tables.rowsPerPage')}
                    itemsPerPage={itemsPerPage}
                    perPageOptions={[
                      {
                        value: 10,
                        label: t('numbers.number', { val: 10 }),
                      },
                      {
                        value: 20,
                        label: t('numbers.number', { val: 20 }),
                      },
                      {
                        value: 40,
                        label: t('numbers.number', { val: 40 }),
                      },
                      {
                        value: 50,
                        label: t('numbers.number', { val: 50 }),
                      },
                    ]}
                    totalItems={pods.total}
                    currentPage={currentPage}
                    onChangePage={(newPage) => {
                      setCurrentPage(newPage)
                    }}
                    onChangeItemsPerPage={(newPerPage) => {
                      setItemsPerPage(newPerPage)
                      setCurrentPage(1)
                    }}
                  />
                </StyledPaginationControls>
                <ConfirmChangesModal
                  title={t(`insightManager.pods.confirmDeleteLabel`)}
                  body={t(`insightManager.pods.confirmDeleteDescription`)}
                  isOpen={showDeleteConfirmModal}
                  handleClose={modalPromiseRef.current?.reject}
                  handleConfirm={modalPromiseRef.current?.resolve}
                />
                {sharingTarget && (
                  <ShareWithModal
                    isOpen={showShareModal}
                    handleClose={async () => {
                      await refetch()
                      setShowShareModal(false)
                      setSharingTarget(null)
                    }}
                    pod={sharingTarget}
                  />
                )}
              </>
            ) : (
              <IWNoResultsMessage />
            )}
          </>
        )}
      </main>
    </>
  )
}

export default InsightManager
