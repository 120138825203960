import React, { ReactNode, useState } from 'react'

type DashboardCategoryContextType = {
  selectedDashboardCategory: Array<string>
  setSelectedDashboardCategory: (val: Array<string>) => void
}

const DashboardCategoryContext =
  React.createContext<DashboardCategoryContextType>({
    selectedDashboardCategory: [],
    setSelectedDashboardCategory: () => null,
  })

type ContextProviderProps = {
  children: ReactNode
}

const DashboardCategoryProvider = ({ children }: ContextProviderProps) => {
  const [selectedDashboardCategory, setSelectedDashboardCategory] = useState<
    Array<string>
  >([])

  return (
    <DashboardCategoryContext.Provider
      value={{
        selectedDashboardCategory,
        setSelectedDashboardCategory: (val: Array<string>) =>
          setSelectedDashboardCategory(val),
      }}
    >
      {children}
    </DashboardCategoryContext.Provider>
  )
}

export { DashboardCategoryProvider }

export default DashboardCategoryContext
