import {
  faArrowsRotate,
  faCalendar,
  faCheckDouble,
  faDownload,
  faGaugeMax,
  faLightbulbOn,
  faRightLeft,
  faShop,
} from '@fortawesome/pro-regular-svg-icons'
import { DateTime, Interval } from 'luxon'
import { ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWGenericCard, {
  IWGenericCardBody,
  IWGenericCardFooter,
  IWGenericCardHeader,
} from 'shared/components/thunderbolt/IWGenericCard'
import LayoutContext from 'shared/contexts/LayoutContext'
import useDurationHumanizer from 'shared/hooks/useDurationHumanizer'
import styled from 'styled-components'
import { Hue } from '../../../@types/styled'
import { Pod } from '../../insightsManager/types'

const StyledIWGenericCardHeader = styled(IWGenericCardHeader)`
  display: flex;
  align-items: center;
`

const StyledIWGenericCardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
  flex: 1;
`

const StyledGenericCardBody = styled(IWGenericCardBody)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.base};
  font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  text-decoration: none;

  align-self: center;

  &:hover {
    text-decoration: underline;
  }
`

const StyledIWGenericCardFooter = styled(IWGenericCardFooter)`
  padding: 1rem 1.5rem;
  background: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StatusBadgeWrapper = styled.div`
  align-self: center;
  margin-left: 0.5rem;
`

type Props = {
  pod: Pod & { currentStatusMessage?: string }
  isLoading?: boolean
  isSelected?: boolean
  onDownload: (pod: Pod) => void
  onSelect: (pod: Pod) => void
  onConvert?: (pod: Pod) => void
  onNetOpenPositionSelected?: (pod: Pod | undefined) => void
  netOpenPositionList?: Pod[]
  selectedNetOpenPosition?: Pod
  disabled: boolean
}

const LSPodCard = ({
  pod,
  isLoading = false,
  onDownload,
  onSelect,
  onConvert,
  onNetOpenPositionSelected,
  netOpenPositionList,
  selectedNetOpenPosition,
  isSelected = false,
  disabled,
}: Props) => {
  const { t, i18n } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const { humanizer } = useDurationHumanizer({ language: i18n.language })
  const {
    userPodName,
    userPodId,
    podType: { insightId, metricId },
    interval,
    nextRun,
    lastRun,
    createdAt,
    currentStatus,
    currentStatusMessage,
  } = pod

  const duration = Interval.fromDateTimes(
    DateTime.fromISO(lastRun ? lastRun : '', {
      zone: timezone,
    }),
    DateTime.now(),
  )
    .toDuration()
    .toMillis()

  const sinceLastRun = humanizer(duration)
  function buildIntervalLabel() {
    if (!interval) {
      return t('insightManager.pods.interval.never')
    }
    if (!nextRun) {
      return t('insightManager.pods.interval.nextRun')
    }
    return t('insightManager.pods.interval.nextRun', {
      value: DateTime.fromISO(nextRun, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT),
    })
  }

  const renderFooterButtons = () => {
    return (
      <div style={{ display: 'flex' }}>
        <IWButton
          style={{ marginRight: '0.5rem' }}
          variant="outline"
          color="primary"
          data-testid="ls-pod-actions-download"
          icon={faDownload}
          disabled={isLoading || disabled}
          iconPosition="leading"
          onClick={() => onDownload(pod)}
          hasSolidBackground
        >
          {t('button.download')}
        </IWButton>

        {isSelected && (
          <IWButton
            style={{ marginRight: '0.5rem' }}
            variant="alternative"
            color="primary"
            data-testid="ls-pod-actions-select"
            icon={faCheckDouble}
            disabled={isLoading || disabled}
            iconPosition="leading"
            onClick={() => onSelect(pod)}
          >
            {t('button.select')}
          </IWButton>
        )}

        {!isSelected && (
          <IWButton
            style={{ marginRight: '0.5rem' }}
            variant="outline"
            color="primary"
            data-testid="ls-pod-actions-select"
            icon={faCheckDouble}
            disabled={isLoading || disabled}
            iconPosition="leading"
            onClick={() => onSelect(pod)}
            hasSolidBackground
          >
            {t('button.select')}
          </IWButton>
        )}

        {onConvert !== undefined &&
          (!pod.markets.includes('ercot') || !pod.markets.includes('miso')) && (
            <IWButton
              variant="outline"
              color="primary"
              data-testid="ls-pod-actions-download"
              icon={faRightLeft}
              disabled={isLoading || disabled}
              iconPosition="leading"
              onClick={() => {
                if (onConvert !== undefined) {
                  onConvert(pod)
                }
              }}
              hasSolidBackground
            >
              {t('button.convert')}
            </IWButton>
          )}
      </div>
    )
  }

  const renderStatusBadge = (message: string): ReactElement | undefined => {
    let hue: Hue = { color: 'primary', value: 700 }
    switch (currentStatus) {
      case 'initializing':
        hue = { color: 'grey', value: 500 }
        break
      case 'ready':
        hue = { color: 'secondary', value: 900 }
        break
      case 'running':
        hue = { color: 'warning', value: 900 }
        break
      case 'failed':
        hue = { color: 'alert', value: 800 }
        break
      default:
        return undefined
    }
    return (
      <IWGeneralBadge
        data-testid="pod-status-badge"
        label={message}
        fontHue={{ color: 'grey', value: 0 }}
        hue={hue}
      />
    )
  }

  return (
    <IWGenericCard data-testid="ls-pod-card">
      <StyledIWGenericCardHeader>
        <StyledIWGenericCardInfo>
          <div style={{ display: 'flex' }}>
            <StyledLink
              to={`/tools/insights-manager/pod-details/${userPodId}`}
              data-testid="pod-name"
            >
              {userPodName}
            </StyledLink>
            <StatusBadgeWrapper>
              {currentStatus &&
                currentStatusMessage &&
                renderStatusBadge(currentStatusMessage || '')}
            </StatusBadgeWrapper>
          </div>
          {pod.markets.includes('ercot') &&
            onNetOpenPositionSelected !== undefined && (
              <IWDropdown
                isClearable
                placeholder={t('placeholders.selectNetOpenPosition')}
                options={netOpenPositionList || []}
                getOptionLabel={(option: Pod) => option.userPodName}
                onChange={(val: Pod) => {
                  if (onNetOpenPositionSelected !== undefined) {
                    onNetOpenPositionSelected(val === null ? undefined : val)
                  }
                }}
                value={selectedNetOpenPosition}
              />
            )}
        </StyledIWGenericCardInfo>
      </StyledIWGenericCardHeader>
      <StyledGenericCardBody>
        <IWBadgeGroup>
          {Boolean(pod.markets.length > 0) ? (
            <IWCardBadge
              label={pod.markets.join(', ').toLocaleUpperCase()}
              icon={faShop}
            />
          ) : (
            <></>
          )}
          <IWCardBadge
            label={t(`insights.${insightId}.label`)}
            icon={faLightbulbOn}
          />
          <IWCardBadge
            label={t(`insights.${insightId}.metrics.${metricId}.label`)}
            icon={faGaugeMax}
          />
          <IWCardBadge label={buildIntervalLabel()} icon={faArrowsRotate} />
        </IWBadgeGroup>

        <IWBadgeGroup testId="pod-date-strings">
          <IWCardBadge
            label={t('insightManager.pods.createdOn', {
              value: DateTime.fromISO(createdAt, {
                zone: timezone,
              }).toLocaleString(DateTime.DATE_SHORT),
            })}
            icon={faCalendar}
          />
          <IWCardBadge
            label={
              lastRun && sinceLastRun
                ? t('insightManager.pods.lastRanRelative', {
                    value: sinceLastRun?.split(' ')[0],
                  })
                : t('insightManager.pods.hasNeverRun')
            }
            icon={faCalendar}
          />
        </IWBadgeGroup>
      </StyledGenericCardBody>
      <StyledIWGenericCardFooter>
        {renderFooterButtons()}
      </StyledIWGenericCardFooter>
    </IWGenericCard>
  )
}

export default LSPodCard
