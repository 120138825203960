import React, { ReactEventHandler } from 'react'
import styled from 'styled-components'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import IWTypography from './IWTypography'
import {
  AvailableColors,
  DefaultVariantHues,
  Hue,
} from '../../../@types/styled'
import { getBackgroundColor, getFontColor } from './utils/utils'

const defaultVariantHues: DefaultVariantHues = {
  primary: {
    background: {
      color: 'primary',
      value: 50,
    },
    font: {
      color: 'primary',
      value: 800,
    },
  },
  secondary: {
    background: {
      color: 'secondary',
      value: 50,
    },
    font: {
      color: 'secondary',
      value: 900,
    },
  },
  alert: {
    background: {
      color: 'alert',
      value: 50,
    },
    font: {
      color: 'alert',
      value: 800,
    },
  },
  warning: {
    background: {
      color: 'warning',
      value: 50,
    },
    font: {
      color: 'warning',
      value: 900,
    },
  },
  grey: {
    background: {
      color: 'grey',
      value: 200,
    },
    font: {
      color: 'grey',
      value: 600,
    },
  },
}

type IconProps =
  | {
      /** Font awesome icon */
      icon: IconDefinition
      /** Position to display the icon in within the badge */
      iconPosition: 'trailing' | 'leading'
      /** OnClick handler for the icon */
      onIconClick?: ReactEventHandler
    }
  | { icon?: never; iconPosition?: never; onIconClick?: never }

type BaseType = {
  /** Label to display within the badge */
  label?: string
  /** OnClick handler for the badge itself */
  onClick?: ReactEventHandler
}

type DefaultType = {
  color: AvailableColors
}

type CustomType = {
  hue: Hue
  fontHue: Hue
}

type DefaultTypeProps = BaseType & DefaultType & IconProps
type CustomTypeProps = BaseType & CustomType & IconProps

export type Props = DefaultTypeProps | CustomTypeProps

const LeadingIconWrapper = styled.span`
  margin: 0 0.25rem 0 0.25rem;
  color: inherit;
  display: flex;
  align-items: center;
`

const TrailingIconWrapper = styled.span<{ onIconClick }>`
  margin-left: 0.25rem;
  color: inherit;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.onIconClick ? 'pointer' : 'inherit')};
  &:hover {
    color: ${(props) => {
      const fontColor = getFontColor(props, {
        ...props.theme,
        defaultVariantHues,
      })
      return props.onIconClick ? darken(0.1, fontColor) : 'inherit'
    }};
  }
`

const StyledIWTypography = styled(IWTypography)`
  margin: 0 0.25rem 0 0.25rem;
  display: flex;
`

const StyledBadge = styled.span`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    getBackgroundColor(props, { ...props.theme, defaultVariantHues })};
  color: ${(props) =>
    getFontColor(props, { ...props.theme, defaultVariantHues })};
  &:hover {
    background-color: ${(props) =>
      props.onClick
        ? darken(
            0.1,
            getBackgroundColor(props, { ...props.theme, defaultVariantHues }),
          )
        : getBackgroundColor(props, { ...props.theme, defaultVariantHues })};
  }
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
  text-transform: capitalize;
  padding: 0.125rem 0.5rem;
  border-radius: 0.6rem;
`

/**
 * Badge to display within the app, can have an icon in either the leading or trailing position
 * The badge becomes role button if it is clickable as does the icon
 */
const IWGeneralBadge = React.forwardRef(
  ({ label, ...props }: Props, ref: React.Ref<HTMLSpanElement>) => {
    return (
      <StyledBadge
        ref={ref}
        role={props.onClick ? 'button' : undefined}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      >
        {props.icon && props.iconPosition === 'leading' && (
          <LeadingIconWrapper>
            <FontAwesomeIcon icon={props.icon} />
          </LeadingIconWrapper>
        )}

        <StyledIWTypography
          inheritFontColor
          as="span"
          size="xs"
          weight="medium"
        >
          {label}
        </StyledIWTypography>
        {props.icon && props.iconPosition === 'trailing' && (
          <TrailingIconWrapper
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            onIconClick={props.onIconClick}
          >
            <FontAwesomeIcon
              role={props.onIconClick ? 'button' : undefined}
              onClick={(e) => {
                if (props.onIconClick) {
                  e.stopPropagation()
                  props.onIconClick(e)
                }
              }}
              icon={props.icon}
            />
          </TrailingIconWrapper>
        )}
      </StyledBadge>
    )
  },
)

export default IWGeneralBadge
