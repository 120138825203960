import React from 'react'
import styled from 'styled-components'
import { OrgUser } from 'shared/userServiceClient'
import { Pod } from '../types'
import PodCard from './PodCard'

type Props = {
  /** Array of objects matching the props of a Pod */
  pods: Pod[]
  orgUsersMap: { [userId: string]: OrgUser }
  /** Handler to call when delete pod is selected */
  onDeletePod: (userPodId, userPodName) => void
  /** Handler to call when share pod is selected */
  onSharePod: (userPodId) => void
  /** Array of objects matching the props of a Pod */
  onGetPodDataCube: (userPodId, userPodName) => void
  onUpdatePod: (userPodId) => void
  isLoading: boolean
}

const StyledCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`

/**
 * A list to display an array of pod cards plus in memory pagination
 * All items are held in memory and the items array is sliced based on current page and items per page
 * to determine which items to display at any given time
 */
const PodCardList = ({
  pods,
  orgUsersMap,
  onGetPodDataCube,
  onDeletePod,
  onSharePod,
  onUpdatePod,
  isLoading,
}: Props) => {
  const renderCard = (pod: Pod) => {
    const { userPodId, userPodName } = pod
    return (
      <PodCard
        orgUsersMap={orgUsersMap}
        key={userPodId}
        pod={pod}
        onGetPodDataCube={() => onGetPodDataCube(userPodId, userPodName)}
        onDeletePod={() => onDeletePod(userPodId, userPodName)}
        onSharePod={() => onSharePod(userPodId)}
        onUpdate={() => onUpdatePod(userPodId)}
        isLoading={isLoading}
      />
    )
  }

  return (
    <StyledCardList data-testid="pod-card-list">
      {pods.map(renderCard)}
    </StyledCardList>
  )
}

export default PodCardList
