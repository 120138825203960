import React from 'react'
import styled from 'styled-components'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import { AttributeFilter as AttributeFilterType } from 'tools/insightsManager/types'
import AttributeFilter from 'tools/insightsManager/components/AttributeFilter'

type Props = {
  showErrors: boolean
  filters: AttributeFilterType[]
  deleteFilter: (index) => void
  addFilter: () => void
  updateFilter: (index, filterState) => void
  attributeTypes: DropdownValueProps[]
  initialized: boolean
  conditions: DropdownValueProps[]
  searchAttributeValues: (
    attributeType,
    inputValue,
  ) => Promise<DropdownValueProps[]>
  getInitialAttributeValues: (attributeType) => Promise<DropdownValueProps[]>
  hasErrors: {
    attribute: boolean
    condition: boolean
    values: boolean
  }[] // one error object per
  isAddDisabled?: boolean
}

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

/**
 *
 */
const AttributeFilterGroup = ({
  filters,
  updateFilter,
  deleteFilter,
  addFilter,
  attributeTypes,
  initialized,
  conditions,
  searchAttributeValues,
  getInitialAttributeValues,
  hasErrors,
  showErrors,
  isAddDisabled,
}: Props) => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      {initialized &&
        filters.map((filter: AttributeFilterType, idx) => (
          <AttributeFilter
            /* eslint-disable-next-line react/no-array-index-key */
            key={idx}
            hasErrors={showErrors ? hasErrors[idx] : undefined}
            filter={filter}
            onGetOptions={searchAttributeValues}
            attributes={attributeTypes.filter((a) => {
              // we filter out any attributes "used" by another filter
              return (
                filter.attribute?.value === a.value ||
                !filters.find((f) => f?.attribute?.value === a.value)
              )
            })}
            onDelete={() => deleteFilter(idx)}
            onSetState={(filterState) => updateFilter(idx, filterState)}
            conditions={conditions}
            getInitialAttributeValues={getInitialAttributeValues}
          />
        ))}
      <IWButton
        data-testid="attribute-filter-add"
        type="button"
        color="primary"
        icon={faPlusCircle}
        iconPosition="leading"
        onClick={addFilter}
        disabled={isAddDisabled}
      >
        {t('attributeFilterSection.attributeFilters')}
      </IWButton>
    </StyledSection>
  )
}

export default AttributeFilterGroup
