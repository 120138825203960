import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconDefinition,
  faCircle,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import IWTypography from './IWTypography'
import useDurationHumanizer from '../../hooks/useDurationHumanizer'
import LayoutContext from 'shared/contexts/LayoutContext'

type ElementProps =
  | { as?: 'div' }
  | ({ as: 'button' } & React.ComponentPropsWithoutRef<'button'>)
  | ({ as: 'a' } & React.ComponentPropsWithoutRef<'a'>)
  | ({ as: 'routerLink' } & React.ComponentPropsWithoutRef<typeof Link>)
  | ({ as: 'routerNavLink' } & React.ComponentPropsWithoutRef<typeof NavLink>)

interface VerticalNavProps {
  /** The main content or title of the notification item */
  title: string
  /** The sub title to display, commonly the notification author */
  subtitle?: string
  /** ISO format datetime string to represent the time since this was created */
  age: string
  /** Denotes wheter a user has marked a notification as read */
  isRead: boolean
  /** leading icon */
  leadingIcon?: IconDefinition
  /** Function to run when checkmark toggle button is clicked */
  onToggle: (e: SyntheticEvent) => void
}

export type Props = VerticalNavProps & ElementProps

const clickableTags = ['button', 'a', 'routerLink', 'routerLinkNav']

const StyledNotification = styled.div<{ $isRead: boolean; $tagType: string }>`
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem;
  background-color: ${(props) =>
    !props.$isRead ? props.theme.palette.primary[50] : undefined};

  &:hover {
    cursor: ${(props) =>
      clickableTags.includes(props.$tagType) ? 'pointer' : 'default'};
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.grey[500]};
`

const StyledLeadingIconWrapper = styled.span`
  margin-right: 0.25rem;
`

const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledEnd = styled.div`
  display: flex;
  gap: 0.5rem;
`

/**
 * This represents a notification item intended to be displayed in a list of similar elements
 * It accepts 'title' which is the main content of the notification, optional 'icon' to be displayed in a leading position
 * and 'subtitle' to indicate potentially who has produced this notification or some other info about it.
 *
 * If can be used as a button, a or nav link in the case where we need to redirect to a page upon clicking
 *
 * The age needs to be an ISO datetime string and will be formatted based on the active language
 */
const IWNotificationListItem = React.forwardRef(
  (
    {
      title,
      subtitle,
      age,
      as,
      onToggle,
      isRead,
      leadingIcon,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement> | React.Ref<HTMLAnchorElement>,
  ) => {
    // We need to know what language is set to get the correct humanizer for the "age" string display
    const { i18n } = useTranslation()
    const { timezone } = useContext(LayoutContext)
    const activeLanguage = i18n.language
    const { humanizer, baseOptions } = useDurationHumanizer({
      language: activeLanguage,
    })
    const [sinceAge, setSinceAge] = useState('')
    let tag
    if (as === 'routerLink') {
      tag = Link
    } else if (as === 'routerNavLink') {
      tag = NavLink
    } else {
      tag = as
    }

    useEffect(() => {
      const dt = DateTime.fromISO(age)
      const duration = dt.diff(DateTime.now(), 'days')
      /* Calculate the localised age based on the timestamp incoming
               The days are relative so will be negative as they are in the past
            */
      if (duration.years <= -1) {
        setSinceAge(
          DateTime.fromISO(age, { zone: timezone })
            .setLocale(activeLanguage)
            .toLocaleString(DateTime.DATE_SHORT),
        )
      } else {
        // We show the latest without worrying about anything more granular
        const [humanReadableString] = humanizer(duration.toMillis()).split(
          baseOptions.delimiter,
        )
        setSinceAge(humanReadableString)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLanguage, age])

    return (
      <StyledNotification
        as={tag}
        $tagType={as}
        role={as === 'button' && 'button'}
        ref={ref}
        $isRead={isRead}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        data-testid="notification-item"
      >
        <StyledTop>
          <IWTypography
            fontHue={{ color: 'grey', value: 700 }}
            weight="medium"
            as="div"
            size="sm"
          >
            {leadingIcon && (
              <StyledLeadingIconWrapper>
                <FontAwesomeIcon icon={leadingIcon} />
              </StyledLeadingIconWrapper>
            )}
            {title}
          </IWTypography>
          <StyledEnd>
            <IWTypography
              size="xs"
              weight="bold"
              fontHue={{ color: 'grey', value: 500 }}
            >
              {sinceAge}
            </IWTypography>
            <StyledIcon
              role="button"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onToggle(e)
              }}
              icon={isRead ? faCircleCheck : faCircle}
            />
          </StyledEnd>
        </StyledTop>
        {subtitle && (
          <IWTypography
            as="div"
            size="sm"
            weight="regular"
            fontHue={{ color: 'grey', value: 500 }}
          >
            {subtitle}
          </IWTypography>
        )}
      </StyledNotification>
    )
  },
)

export default IWNotificationListItem
