import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWAccordion from 'shared/components/thunderbolt/IWAccordion'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import {
  getRoundingRules,
  updateRoundingRules,
} from 'shared/loadSchedulingClient'
import styled from 'styled-components'

const StyledQuantityRulesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

const QuantityRules = () => {
  const { t } = useTranslation()
  const [roundingRuleId, setRoundingRuleId] = useState('')
  const [bidRoundUp, setBidRoundUp] = useState(0.5)
  const [offerRoundUp, setOfferRoundUp] = useState(0.5)

  const { data: quantityRules } = useQuery('rounding-rules', () => {
    return getRoundingRules()
  })

  useEffect(() => {
    const [roundingRule] = quantityRules || []

    if (roundingRule) {
      setRoundingRuleId(roundingRule.id)
      setBidRoundUp(roundingRule.bid)
      setOfferRoundUp(roundingRule.offer)
    }
  }, [quantityRules])

  const handleOnBidChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = Number(e.target.value || '0')
    const value = targetValue < 0 ? 0 : targetValue > 1 ? 1 : targetValue

    const { bid, id } = await updateRoundingRules(roundingRuleId, {
      bid: value,
      offer: offerRoundUp,
    })

    setRoundingRuleId(id)
    setBidRoundUp(bid)
  }

  const handleOnOfferChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const targetValue = Number(e.target.value || '0')
    const value = targetValue < 0 ? 0 : targetValue > 1 ? 1 : targetValue

    const { offer, id } = await updateRoundingRules(roundingRuleId, {
      bid: bidRoundUp,
      offer: value,
    })

    setRoundingRuleId(id)
    setOfferRoundUp(offer)
  }

  return (
    <IWAccordion
      title={t('loadScheduling.settings.roundingRuleSectionTitle')}
      desc={t('loadScheduling.settings.roundingRuleSectionDesc')}
      shouldStartClose
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <StyledQuantityRulesWrapper>
          <IWTypography>
            {t('loadScheduling.settings.roundingRuleDescPrefix', {
              type: 'bid',
            })}
          </IWTypography>

          <IWTextInput
            name="bidRoundUp"
            type="number"
            step="0.1"
            min="0"
            max="1"
            value={bidRoundUp}
            onChange={handleOnBidChange}
            placeholder={t('placeholders.enterValue')}
          />

          <IWTypography>
            {t('loadScheduling.settings.roundingRuleDescSuffix')}
          </IWTypography>
        </StyledQuantityRulesWrapper>

        <StyledQuantityRulesWrapper>
          <IWTypography>
            {t('loadScheduling.settings.roundingRuleDescPrefix', {
              type: 'offer',
            })}
          </IWTypography>

          <IWTextInput
            name="offerRoundUp"
            type="number"
            step="0.1"
            min="0"
            max="1"
            value={offerRoundUp}
            onChange={handleOnOfferChange}
            placeholder={t('placeholders.enterValue')}
          />

          <IWTypography>
            {t('loadScheduling.settings.roundingRuleDescSuffix')}
          </IWTypography>
        </StyledQuantityRulesWrapper>
      </div>
    </IWAccordion>
  )
}

export default QuantityRules
