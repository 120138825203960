import styled from 'styled-components'

/**
 * This represents a list of notification items to display time sorted content to the user
 */
const IWNotificationList = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export default IWNotificationList
