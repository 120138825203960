import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { ReactComponent as NoPodsImg } from 'images/no-pods.svg'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { getDashboardList } from 'shared/podServiceClient'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import styled from 'styled-components'
import UserContext from 'shared/contexts/UserContext'
import DashboardCategoryContext from 'shared/contexts/DashboardCategoryContext'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import useBoundQueryParams from 'shared/hooks/useBoundQueryParams'

const FullPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 100%;
`

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

const StyledPaginationControls = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const ListControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-flow: column;
`

const Spacer = styled.div`
  flex-grow: 1;
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 400px;
`

const StyledDashboardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 860px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 0.25rem;
`

const StyledDashboardManagerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

const DashboardManager = () => {
  const { t } = useTranslation()
  const { availableDashboardType } = useContext(UserContext)

  const dashboardCategoryDropDown = availableDashboardType.map(
    (dashboardCategory) => {
      return {
        value: dashboardCategory,
        label: dashboardCategory,
      }
    },
  )

  const { selectedDashboardCategory, setSelectedDashboardCategory } =
    useContext(DashboardCategoryContext)

  // Pagination
  const { setParam, state: queryState } = useBoundQueryParams({
    activeTab: 0,
    page: 1,
    perPage: 1,
  })

  const { perPage, page } = queryState

  const [itemsPerPage, setItemsPerPage] = useState<number>(perPage)
  const [currentPage, setCurrentPage] = useState<number>(page)

  const {
    data: userDashboards,
    isLoading: isLoadingUserDasboards,
    refetch,
  } = useQuery(
    [
      'dashboards',
      selectedDashboardCategory,
      'pageNumber',
      currentPage,
      'pageSize',
      itemsPerPage,
    ],
    () => {
      return getDashboardList({
        dashboardCategory: selectedDashboardCategory,
        currentPage: currentPage - 1,
        itemsPerPage,
      })
    },
  )

  const EmptyMessage = () => {
    return (
      <EmptyDiv data-testid="empty-message">
        <NoPodsImg />
        <IWTypography weight="bold">
          {t('dashboardManager.noDashboards')}
        </IWTypography>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t('dashboardManager.noDashboardsDesc')}
        </IWTypography>
      </EmptyDiv>
    )
  }

  useEffect(() => {
    if (itemsPerPage) {
      setParam('perPage', itemsPerPage)
      refetch()
    }
    if (currentPage) {
      setParam('page', currentPage)
    }
  }, [itemsPerPage, currentPage])

  return (
    <>
      <FullPageContent>
        <StyledDashboardManagerHeader>
          <ListControls>
            <Spacer />
            <StyledIWDropdown
              name="insightManagerDropdown"
              label={t('dashboardManager.dashboardManagerDropDownPlaceholder')}
              isMulti
              multiline
              value={selectedDashboardCategory.map((selectedValues) => {
                return {
                  value: selectedValues,
                  label: selectedValues,
                }
              })}
              isSearchable
              placeholder={t(`placeholders.select`)}
              options={dashboardCategoryDropDown}
              onChange={(sel) => {
                const dropValues = sel.map((v) => v.value)
                setSelectedDashboardCategory(dropValues)
              }}
            />
          </ListControls>
        </StyledDashboardManagerHeader>
        {isLoadingUserDasboards && <IWLoading />}
        {!isLoadingUserDasboards && userDashboards?.total === 0 && (
          <EmptyMessage />
        )}
        {!isLoadingUserDasboards &&
          userDashboards &&
          userDashboards.total > 0 && (
            <StyledDashboardList>
              {userDashboards.rows.length > 0 ? (
                <>
                  {userDashboards.rows.map((dashboardUrl, i) => {
                    return (
                      <StyledIframe
                        title={`dashboard-${i}`}
                        id="pivot"
                        src={dashboardUrl}
                      />
                    )
                  })}
                  <StyledPaginationControls>
                    <IWPaginationControls
                      perPageLabel={t('tables.rowsPerPage')}
                      itemsPerPage={itemsPerPage}
                      perPageOptions={[
                        {
                          value: 1,
                          label: t('numbers.number', { val: 1 }),
                        },
                        {
                          value: 10,
                          label: t('numbers.number', { val: 10 }),
                        },
                        {
                          value: 15,
                          label: t('numbers.number', { val: 15 }),
                        },
                        {
                          value: 20,
                          label: t('numbers.number', { val: 20 }),
                        },
                      ]}
                      totalItems={userDashboards.total}
                      currentPage={currentPage}
                      onChangePage={(newPage) => {
                        setCurrentPage(newPage)
                      }}
                      onChangeItemsPerPage={(newPerPage) => {
                        setItemsPerPage(newPerPage)
                        setCurrentPage(1)
                      }}
                    />
                  </StyledPaginationControls>
                </>
              ) : (
                <IWNoResultsMessage />
              )}
            </StyledDashboardList>
          )}
      </FullPageContent>
    </>
  )
}

export default DashboardManager
