import { useEffect, useState } from 'react'
import IWTable, { Column } from 'shared/components/thunderbolt/IWTable'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import { paginationOptions } from 'shared/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const PaginationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
`

type Props<T> = {
  items: T[]
  columns: Column[]
  isLoading?: boolean
}

type UseTableWithPaginationHook<T> = {
  pageNumber: number
  itemsPerPage: number
  itemsInView: T[]
  setTablePageNumber: (pageNumber: number) => void
  setTableItemsPerPage: (itemsPerPage: number) => void
  setItemsInView: (items: T[]) => void
  setItems: (items: T[]) => void
}

function UseTableWithPagination<T>(
  data: T[] = [],
): UseTableWithPaginationHook<T> {
  const [currentItems, setCurrentItems] = useState<T[]>(data)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(10)
  const [currentItemsInView, setCurrentItemsInView] = useState<T[]>([])

  function getCurrentSlice(arr) {
    return arr.slice(
      (currentPage - 1) * currentItemsPerPage,
      currentPage * currentItemsPerPage,
    )
  }

  function setItemsInView(items: T[]) {
    setCurrentItemsInView(getCurrentSlice(items))
  }

  function setTablePageNumber(pageNumber: number) {
    setCurrentPage(pageNumber)
  }

  function setTableItemsPerPage(itemsPerPage: number) {
    setCurrentItemsPerPage(itemsPerPage)
  }

  function setItems(items: T[]) {
    setCurrentItems(items)
    setCurrentPage(1)
    setCurrentItemsPerPage(10)
    setItemsInView(items)
  }

  useEffect(() => {
    setItemsInView(currentItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentItemsPerPage])

  return {
    pageNumber: currentPage,
    itemsPerPage: currentItemsPerPage,
    itemsInView: currentItemsInView,
    setTableItemsPerPage,
    setTablePageNumber,
    setItemsInView,
    setItems,
  }
}

function IWTableWithPagination<T>({
  items,
  columns,
  isLoading = false,
}: Props<T>) {
  const { t } = useTranslation()
  const {
    pageNumber,
    itemsPerPage,
    itemsInView,
    setTablePageNumber,
    setTableItemsPerPage,
    setItems,
  } = UseTableWithPagination(items)

  useEffect(() => {
    setItems(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return (
    <>
      <IWTable
        border
        columns={columns}
        data={itemsInView}
        isLoading={isLoading}
      />
      <PaginationWrapper>
        <IWPaginationControls
          perPageLabel={t('tables.rowsPerPage')}
          itemsPerPage={itemsPerPage}
          perPageOptions={paginationOptions}
          totalItems={items.length}
          currentPage={pageNumber}
          onChangePage={(num) => setTablePageNumber(num)}
          onChangeItemsPerPage={(num) => {
            setTableItemsPerPage(num)
            setTablePageNumber(1)
          }}
        />
      </PaginationWrapper>
    </>
  )
}

export default IWTableWithPagination
