import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import {
  getAvailableAttributeTypes,
  getInitialValues,
  searchMeterAttributeValues as searchMeterAttributeValuesServer,
} from 'shared/searchServiceClient'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { AttributeFilter } from '../types'

/**
 * A hook to contain interactions with an array of Attribute filters
 * for use in the pod creation form. This can be optionally passed fixed attribute types to select from
 * if not passed, it will get them from the search service
 */
const useAttributeFilters = (
  initialFilters: AttributeFilter[],
  fixedAttributeTypes?: DropdownValueProps[],
) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [filters, setFilters] = useState<AttributeFilter[]>(
    initialFilters || [],
  )

  const [attributeTypes, setAttributeTypes] = useState<DropdownValueProps[]>(
    fixedAttributeTypes || [],
  )

  const [hasErrors, setHasErrors] = useState<
    {
      attribute: boolean
      condition: boolean
      values: boolean
    }[]
  >([])

  const conditions = [
    { label: t('attributeFilterSection.conditionsEqual'), value: 'equal' },
    {
      label: t('attributeFilterSection.conditionsNotEqual'),
      value: 'notEqual',
    },
  ]

  const addFilter = () => {
    setFilters([
      ...filters,
      {
        values: [],
      },
    ])
  }

  const deleteFilter = (index) => {
    const updatedFilters = [...filters]
    updatedFilters.splice(index, 1)
    setFilters(updatedFilters)
  }

  const deleteAllFilters = () => {
    setFilters([])
  }

  const updateFilter = (index, updatedFilter) => {
    const updatedFilters = [...filters]
    updatedFilters.splice(index, 1, updatedFilter)
    setFilters(updatedFilters)
  }

  /**
   * Sets the attribute types based on the response from the search service
   */
  const getAvailableAttributes = async (
    market?: string,
  ): Promise<DropdownValueProps[]> => {
    try {
      const res = await getAvailableAttributeTypes(market)
      const mapped = res.map((attr) => ({
        value: attr,
        label: attr,
      }))
      setAttributeTypes(mapped)
      return mapped
    } catch (e: any) {
      toast.alert(t('useAttributeFilters.errors.getAttributes'), {
        status: e.response.status,
        canDismiss: true,
      })
      return []
    }
  }

  /**
   * Searches for attributes values of the provided type using
   * full text type ahead recommendations from the search service
   */
  const searchAttributeValues = async (
    attributeType: string,
    inputValue: string,
  ): Promise<DropdownValueProps[]> => {
    try {
      const searchValues = await searchMeterAttributeValuesServer({
        attributeType,
        inputValue,
      })
      return searchValues.map((att) => ({ label: att, value: att }))
    } catch (e: any) {
      toast.alert(t('useAttributeFilters.errors.searchAttributes'), {
        status: e.response.status,
        canDismiss: true,
      })
      return []
    }
  }

  /**
   * Populates the initial values when an attribute is first selected
   * @param attributeType
   */
  const getInitialAttributeValues = async (
    attributeType: string,
  ): Promise<DropdownValueProps[]> => {
    try {
      const searchValues = await getInitialValues({
        attributeType,
      })
      return searchValues.map((att) => ({ label: att, value: att }))
    } catch (e: any) {
      toast.alert(t('useAttributeFilters.errors.searchAttributes'), {
        status: e.response.status,
        canDismiss: true,
      })
      return []
    }
  }

  useEffect(() => {
    // Populate the attributes types if not passed in
    if (!attributeTypes.length) {
      getAvailableAttributes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Validate for errors when filters changes
    setHasErrors(
      filters.map((f) => {
        return {
          attribute: !f.attribute,
          condition: !f.condition,
          values: Boolean(!f.values || f.values.length === 0),
        }
      }),
    )
  }, [filters])

  return {
    filters,
    conditions,
    attributeTypes,
    initialized: true,
    searchAttributeValues,
    getAvailableAttributes,
    getInitialAttributeValues,
    deleteFilter,
    updateFilter,
    addFilter,
    deleteAllFilters,
    hasErrors,
  }
}

export default useAttributeFilters
