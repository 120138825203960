import { useEffect, useState } from 'react'
import { getAvailableMarkets } from 'shared/searchServiceClient'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { useTranslation } from 'react-i18next'

const useMarketFilter = (initialMarkets: DropdownValueProps[]) => {
  const [availableMarkets, setAvailableMarkets] = useState<
    DropdownValueProps[]
  >([])
  const [selectedMarkets, setSelectedMarkets] =
    useState<DropdownValueProps[]>(initialMarkets)
  const { t } = useTranslation()
  const toast = useToast()

  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const updateMarkets = (updatedMarkets: DropdownValueProps[]) => {
    setSelectedMarkets(updatedMarkets)
  }

  useEffect(() => {
    getAvailableMarkets()
      .then((res: string[]) => {
        const mappedMarkets = res.map((market: string) => ({
          value: market,
          label: market,
        }))
        // If we get none then we shouldn't populate so the ui shows an error
        if (mappedMarkets.length > 0) {
          setAvailableMarkets(mappedMarkets)
          // Select the first one if none are set
          if (initialMarkets.length === 0) {
            setSelectedMarkets([mappedMarkets[0]])
          }
        } else {
          toast.alert(t('useMarketFilter.errors.noMarkets'), {
            canDismiss: true,
          })
          setHasErrors(true)
        }
      })
      .catch((e) => {
        toast.alert(t('useMarketFilter.errors.loadMarkets'), {
          status: e.response.status,
          canDismiss: true,
        })
      })
    // We don't want to trigger again is initialmarkets changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Validate for errors when markets changes
    setHasErrors(!selectedMarkets || selectedMarkets.length === 0)
  }, [selectedMarkets])

  return {
    selectedMarkets,
    updateMarkets,
    availableMarkets,
    hasErrors,
  }
}

export default useMarketFilter
