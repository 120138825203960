import React, { ReactNode } from 'react'
import { Outlet, Route } from 'react-router'

type RouteProps = {
  key: string
  path: string
  main?: ReactNode
  childRoutes?: any
  entryPage?: boolean
}

export type Props = Array<RouteProps>

const LayoutRoute = (routes: Props) => {
  const processRoute = ({
    key,
    path,
    main,
    childRoutes,
    // FIXME: Make this work correctly
    entryPage,
  }: RouteProps) => {
    if (childRoutes) {
      return childRoutes.map((route) => {
        // If we have a path in the child route then append it to parent,
        // if not, it's the default (home)
        const childRoutePath = route.path ? `${path}${route.path}` : path
        const childRouteKey = `${key}-${route.key}`
        return (
          <Route key={key} path={path} element={main}>
            {processRoute({
              ...route,
              path: childRoutePath,
              key: childRouteKey,
            })}
          </Route>
        )
      })
    }

    return (
      <Route
        index={entryPage}
        key={key}
        path={path}
        element={main || <Outlet />}
      />
    )
  }

  return routes.map(processRoute)
}

export default LayoutRoute
