import React, { useMemo } from 'react'
import { AxisOptions, Chart } from 'react-charts'

export default function Line({
  data = [],
  height = 0,
  width = 0,
  darkMode = false,
}: {
  data: {
    label: string
    data: { dateTime: string; load: number }[]
  }[]
  height?: number
  width?: number
  darkMode?: boolean
}) {
  const series = useMemo(
    () =>
      data.map((v) => ({
        label: v.label,
        data: v.data.map((d) => ({
          dateTime: new Date(d.dateTime as string),
          load: d.load,
        })),
      })),
    [data],
  )

  const primaryAxis = useMemo<
    AxisOptions<(typeof series)[number]['data'][number]>
  >(
    () => ({
      getValue: (datum) => datum.dateTime,
      show: false,
    }),
    [],
  )

  const secondaryAxes = useMemo<
    AxisOptions<(typeof series)[number]['data'][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.load,
        show: false,
        min: 5,
      },
    ],
    [],
  )

  if (data.length === 0) {
    return <></>
  }

  return (
    <div style={{ height, width }}>
      <Chart
        options={{
          data: series,
          primaryAxis,
          secondaryAxes,
          tooltip: { show: false },
          dark: darkMode,
        }}
      />
    </div>
  )
}
