import { useTranslation } from 'react-i18next'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'

const useCopyText = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const copyText = (str: string) => {
    navigator.clipboard
      .writeText(str)
      .then(() => {
        toast.secondary(t('messages.copySuccess'), { canDismiss: true })
      })
      .catch(() => {
        toast.alert(t('messages.copyFailure'), { canDismiss: true })
      })
  }
  return { copyText }
}

export default useCopyText
