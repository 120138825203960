import axios from 'axios'
import { QueryClient } from 'react-query'
import {
  AvailablePodTypesMap,
  isUserType,
  ServerUserType,
  UserTools,
} from 'shared/types'

const { NODE_ENV } = process.env

export const queryClient = new QueryClient()

/**
 * Module with the communication layer functionalities.
 */

/* Axios client instance for API interactions, this set up ensures the
base url for our api gateway will be used and also that the session cookie
will be sent on these requests */
const axiosApiClient = axios.create({
  baseURL: NODE_ENV === 'development' ? '/' : '/api',
  withCredentials: true,
})

export interface RedirectType {
  user?: ServerUserType
  url?: string
}

/**
 * Ensures a correct UserType object
 */
function normalizeUserObject(
  receivedUserObject: ServerUserType,
): ServerUserType {
  if (isUserType(receivedUserObject)) {
    return receivedUserObject
  }
  const {
    userId,
    email,
    name,
    organization,
    toolPolicies,
    podTypeSubscriptions,
    dashboardType,
  } = receivedUserObject

  return {
    userId: (userId || 'someuserid') as string,
    email: (email || 'unset@email.com') as string,
    name: (name || 'Unset') as string,
    organization: (organization || 'Unset') as string,
    toolPolicies: toolPolicies || [],
    podTypeSubscriptions: podTypeSubscriptions || [],
    dashboardType: dashboardType || [],
  }
}

export const redirect = (state: string): Promise<RedirectType> => {
  return axiosApiClient
    .get('/auth/redirect', {
      params: { state },
      validateStatus: (status) => status === 200 || status === 302,
    })
    .then((d) => {
      const returnData: RedirectType = {}
      if (d.data.url) {
        returnData.url = d.data.url
      }
      if (d.data.user) {
        returnData.user = normalizeUserObject(d.data.user)
      }
      return returnData
    })
}

export const login = (code: string | null): Promise<ServerUserType> => {
  return axiosApiClient
    .get('/auth/user', {
      params: { code },
    })
    .then((d) => normalizeUserObject(d.data))
}

export async function logout(): Promise<string> {
  return axiosApiClient({
    method: 'POST',
    url: '/auth/logout',
  }).then((d) => d.data)
}

export async function allAllToolAndInsights(): Promise<{
  insights: Array<{
    id: keyof AvailablePodTypesMap
    metrics: AvailablePodTypesMap[keyof AvailablePodTypesMap]
  }>
  tools: Array<UserTools>
}> {
  return axiosApiClient
    .get('/pods/v1/webhooks/tools-and-insights')
    .then((r) => r.data)
}

/* The client instance is returned so interceptors,
mocks or other custom configs can be made over it */
export default axiosApiClient
