import { useEffect, useRef, useState } from 'react'

const useDebouncer = () => {
  const debouncer = useRef<NodeJS.Timeout | undefined>()

  return {
    debounce: (cb: () => void, ms: number) => {
      if (debouncer.current) {
        clearTimeout(debouncer.current)
      }
      debouncer.current = setTimeout(() => {
        cb()
      }, ms)
    },
  }
}

export const useDebounceInput = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebouncer
