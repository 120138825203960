import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IWError } from 'shared/components/thunderbolt/IWCommonLabels'
import IWDropdown, {
  DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

export type Props = {
  /** Flag to show the errors  */
  showErrors?: boolean
  /** Flag to indicate that the control has errors  */
  hasErrors?: boolean
  /** Flag toggle between multi and single select  */
  isMultiSelect?: boolean
  /** Array of available markets  */
  availableMarkets: DropdownValueProps[]
  /** Array of selected markets  */
  selectedMarkets: DropdownValueProps[]
  /** State setter for the parent components  */
  onSetState: (AttributeFilter) => void
}

const StyledAttributeFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 100%;
`

/**
 * A row of controls allowing the user to set conditions and values for a specific
 * attribute of a pod
 */
const MarketFilter = ({
  showErrors,
  hasErrors,
  selectedMarkets,
  availableMarkets,
  isMultiSelect,
  onSetState,
}: Props) => {
  const { t } = useTranslation()

  const handleValueChange = (option) => {
    // Emit the new state to the parent
    onSetState(option && !Array.isArray(option) ? [option] : option)
  }

  return (
    <>
      <StyledAttributeFilter data-testid="market-filter">
        <StyledIWDropdown
          name="market"
          required
          fullWidth
          isMulti={isMultiSelect}
          value={selectedMarkets}
          label={t('marketFilterSection.market')}
          options={availableMarkets}
          onChange={handleValueChange}
          hasError={showErrors && hasErrors}
        />
      </StyledAttributeFilter>
      {showErrors && hasErrors ? (
        <IWError label={t('marketFilterSection.helper')} />
      ) : (
        <IWTypography fontHue={{ color: 'grey', value: 400 }} size="sm">
          {t('marketFilterSection.helper')}
        </IWTypography>
      )}
    </>
  )
}

export default MarketFilter
