import { ChangeEvent, Ref, useEffect, useRef, useState } from 'react'
import { csvToJson } from 'shared/helpers'

interface FileSelector {
  selectedFile: File | null | undefined
  onFileSelect: (event: ChangeEvent<HTMLInputElement>) => void
  clearSelectedFile: () => void
  hasError: boolean
  importedData: Record<string, string>[] | null
  fileInputRef: Ref<HTMLInputElement>
}

const useUserFileSelector = (): FileSelector => {
  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [importedData, setImportedData] = useState<
    Record<string, string>[] | null
  >(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onFileSelect = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = event.target.files?.[0] || null
    setSelectedFile(file)
    setHasError(!file)

    if (file) {
      try {
        const { data } = await csvToJson(file, 20, true)
        setImportedData(data as any)
      } catch (error) {
        console.error('Error processing file:', error)
      }
    }
  }

  const clearSelectedFile = (): void => {
    setSelectedFile(null)
    setImportedData(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  useEffect(() => {
    const dataPresent = selectedFile && importedData
    setHasError(!dataPresent)
  }, [selectedFile, importedData])

  return {
    selectedFile,
    onFileSelect,
    clearSelectedFile,
    fileInputRef,
    hasError,
    importedData,
  }
}

export default useUserFileSelector
