import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAlarmExclamation,
  faCube,
  faEllipsisH,
  faEnvelope,
  faGaugeMax,
  faLightbulbOn,
  faPause,
  faPlay,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons'
import {
  IWGenericCard,
  IWGenericCardBody,
  IWGenericCardHeader,
} from 'shared/components/thunderbolt/IWGenericCard'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import { Link } from 'react-router-dom'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import { OrgUser } from 'shared/userServiceClient'
import IWContextDivider from 'shared/components/thunderbolt/IWContextMenuItemDivider'
import { isPermissionAvailable } from 'shared/helpers'
import userContext from 'shared/contexts/UserContext'
import { AlertSummary } from '../types'

type Props = {
  /** Alert Summary object  */
  alert: AlertSummary
  /** Handler to call when deleting an alert  */
  onDelete: (userAlertId) => void
  /** Toggles paused states of the alert */
  onToggleAlertIsPaused: (id, name) => void
  /** Boolean loading flag * */
  isLoading?: boolean
  /** Map of userIds to user object  */
  orgUsersMap: { [key: string]: OrgUser }
}

const StyledIWGenericCardHeader = styled(IWGenericCardHeader)`
  display: flex;
  align-items: center;
`

const StyledIWGenericCardInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1;
`

const StyledGenericCardBody = styled(IWGenericCardBody)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.base};
  font-weight: ${(props) => props.theme.typography.fontWeights.medium};

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

/**
 * A card to display the details of a pod in the app. Contained in an IWPodCardList
 */
const AlertCard = ({
  alert,
  onDelete,
  onToggleAlertIsPaused,
  isLoading,
  orgUsersMap,
}: Props) => {
  const { t } = useTranslation()

  const { availableToolPolicies, userId } = useContext(userContext)
  const ownerId = alert.userId
  const isOwner = userId === ownerId

  const canEditAlert = isPermissionAvailable(
    availableToolPolicies,
    'alertsManager:editor',
  )

  /**
   * Renders the content menu, if the pod has data it renders an explore and divider
   */
  const renderContextMenu = () => {
    const contextMenuHeader: ReactElement[] = []

    if (canEditAlert) {
      contextMenuHeader.push(
        <IWContextMenuItem
          key="details"
          as="button"
          disabled={isLoading}
          data-testid="alerts-actions-pause"
          icon={alert.isPaused ? faPlay : faPause}
          label={alert.isPaused ? t('actions.resume') : t('actions.pause')}
          onClick={() =>
            onToggleAlertIsPaused(alert.userAlertId, alert.userAlertName)
          }
        />,
      )
      contextMenuHeader.push(<IWContextDivider />)
    }

    contextMenuHeader.push(
      <IWContextMenuItem
        as="routerLink"
        key="details"
        data-testid="alerts-actions-details"
        label={t('actions.details')}
        to={`/tools/alerts-manager/alert-details/${alert.userAlertId}`}
      />,
    )

    if (isOwner) {
      contextMenuHeader.push(
        <IWContextMenuItem
          key="delete"
          disabled={isLoading}
          data-testid="pod-actions-delete"
          label={t('actions.delete')}
          onClick={onDelete}
        />,
      )
    }

    return [...contextMenuHeader]
  }

  return (
    <IWGenericCard data-testid="alert-card">
      <StyledIWGenericCardHeader>
        <StyledIWGenericCardInfo>
          <StyledLink
            to={`/tools/alerts-manager/alert-details/${alert.userAlertId}`}
            data-testid="pod-name"
          >
            {alert.userAlertName}
          </StyledLink>
          {alert.isPaused && (
            <IWGeneralBadge
              data-testid="alert-paused-badge"
              label={t(`alertsManager.paused`)}
              fontHue={{ color: 'grey', value: 0 }}
              hue={{ color: 'grey', value: 500 }}
            />
          )}
          <IWTypography size="xs" fontHue={{ color: 'grey', value: 0 }}>
            {alert.userAlertId}
          </IWTypography>
        </StyledIWGenericCardInfo>
        <IWContextMenu menu={renderContextMenu()} positions={['bottom']}>
          <IWButton
            variant="outline"
            color="grey"
            data-testid="context-menu-button"
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </IWButton>
        </IWContextMenu>
      </StyledIWGenericCardHeader>
      <StyledGenericCardBody>
        <IWBadgeGroup>
          <IWCardBadge
            label={t('alertsManager.triggerCount', {
              count: alert.triggerCount,
            })}
            icon={faAlarmExclamation}
          />
          <IWCardBadge
            label={t(`insights.${alert.podType.insightId}.label`)}
            icon={faLightbulbOn}
          />
          <IWCardBadge
            label={`${t(
              `insights.${alert.podType.insightId}.metrics.${alert.podType.metricId}.label`,
            )}`}
            icon={faGaugeMax}
          />
          <IWCardBadge label={alert.userPodName} icon={faCube} />
        </IWBadgeGroup>
        {isOwner && alert.recipients.length > 0 && (
          <IWBadgeGroup>
            <IWCardBadge
              label={t('alertsManager.recipient', {
                count: alert.recipients.length,
              })}
              icon={faUserGroup}
            />
          </IWBadgeGroup>
        )}
        {!isOwner && alert.recipients.length > 0 && orgUsersMap[userId] && (
          <IWBadgeGroup>
            <IWCardBadge
              label={t('alertsManager.addedAsRecipientBy', {
                name: orgUsersMap[ownerId].name,
              })}
              icon={faEnvelope}
            />
          </IWBadgeGroup>
        )}
      </StyledGenericCardBody>
    </IWGenericCard>
  )
}

export default AlertCard
