import React, { useContext, useEffect, useState } from 'react'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { useTranslation } from 'react-i18next'
import IWDropdown, {
  DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import styled from 'styled-components'
import IWUserListItem from 'shared/components/thunderbolt/IWUserListItem'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { OrgUser } from 'shared/userServiceClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import UserContext from 'shared/contexts/UserContext'
import useGetOrgUsers from 'shared/hooks/useGetOrgUsers'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import { SharingRole } from '../../insightsManager/types'
import { WizardStepProps } from '../../../shared/types'

const StyledSection = styled.div`
  display: flex;
  gap: 1rem;
`
const StyledUserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
const StyledButton = styled(IWButton)`
  white-space: nowrap;
  height: fit-content;
`

type AlertRecipient = DropdownValueProps[]

export type Props = WizardStepProps<AlertRecipient | undefined>

export default function ActionsAlertStep({
  userPodRole,
  state,
  onChange,
  showErrors,
}: Props & { userPodRole?: SharingRole }) {
  const { t } = useTranslation()
  const { userId: myOrgUserId, email: myOrgUserEmail } = useContext(UserContext)
  const { data: orgUsers, isLoading: initializing } = useGetOrgUsers()
  const [loading] = useState<boolean>(false)
  const [isViewOnly] = useState<boolean>(
    Boolean(Number(userPodRole) === SharingRole.VIEW),
  )
  const [mappedOrgUsers, setMappedOrgUsers] = useState<Map<string, OrgUser>>(
    new Map(),
  )
  const [availableOrgUsers, setAvailableOrgUsers] = useState<
    DropdownValueProps[]
  >([])
  const [selectedAvailableOrgUsers, setSelectedAvailableOrgUsers] = useState<
    DropdownValueProps[]
  >([])
  const [addedOrgUsers, setAddedOrgUsers] = useState<DropdownValueProps[]>(
    state || [{ value: myOrgUserId, label: myOrgUserEmail }],
  )

  const onRemoveAddedOrgUsers = (userId) => {
    const filteredAddedOrgUsers = addedOrgUsers?.filter(
      ({ value: id }) => id !== userId,
    )
    setAddedOrgUsers(filteredAddedOrgUsers)
    onChange(filteredAddedOrgUsers, true)
  }

  const onAddAddedOrgUsers = () => {
    const currentAddedOrgUsers: DropdownValueProps[] = [
      ...addedOrgUsers,
      ...selectedAvailableOrgUsers,
    ]
    const filteredAvailableOrgUsers = availableOrgUsers.filter(
      ({ value: availableOrgUserId }) =>
        !currentAddedOrgUsers.find(
          ({ value: currentUserId }) => currentUserId === availableOrgUserId,
        ),
    )
    setAddedOrgUsers(currentAddedOrgUsers)
    setAvailableOrgUsers(filteredAvailableOrgUsers)
    setSelectedAvailableOrgUsers([])
    onChange(currentAddedOrgUsers, true)
  }

  useEffect(() => {
    if (initializing) {
      return
    }

    if (orgUsers?.list && orgUsers.list.length) {
      const mappedUsers = new Map(
        orgUsers.list.map((orgUser) => [orgUser.id, orgUser]),
      )

      const currentAvailableUsers = orgUsers.list.map(
        ({ name, id, email }) => ({
          label: `${name} (${email})`,
          value: id,
        }),
      )

      const filteredAvailableOrgUsers = currentAvailableUsers.filter(
        ({ value: availableOrgUserId }) =>
          !addedOrgUsers.find(
            ({ value: currentUserId }) => currentUserId === availableOrgUserId,
          ),
      )

      setMappedOrgUsers(mappedUsers)
      setAvailableOrgUsers(filteredAvailableOrgUsers)
    }
  }, [addedOrgUsers, initializing, orgUsers])

  const renderUsers = () => {
    return addedOrgUsers.map((user) => (
      <StyledUserSection>
        <IWUserListItem
          key={user.value}
          name={mappedOrgUsers.get(user.value)?.name || ''}
          email={mappedOrgUsers.get(user.value)?.email || ''}
          truncateAtWidth={300}
        />
        <StyledButton
          variant="outline"
          color="grey"
          data-testid="create-alert-remove-user-btn"
          disabled={loading || user.value === myOrgUserId}
          onClick={(e) => {
            e.preventDefault()
            onRemoveAddedOrgUsers(user.value)
          }}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </StyledButton>
      </StyledUserSection>
    ))
  }
  return (
    <IWFormSection
      sectionTitle={t(`alertsManager.createAlert.actionsStep.sectionTitle`)}
      sectionDescription={t(
        `alertsManager.createAlert.actionsStep.sectionDesc`,
      )}
    >
      {initializing ? (
        <IWLoading />
      ) : (
        <>
          {isViewOnly && (
            <IWAlert
              icon={faCircleExclamation}
              label={t('alertsManager.createAlert.actionsStep.viewOnlyAlert')}
              color="primary"
              variant="alternative"
            />
          )}
          <StyledSection>
            <IWDropdown
              isMulti
              fullWidth
              multiline
              disabled={isViewOnly || loading}
              onChange={(options) => setSelectedAvailableOrgUsers(options)}
              value={selectedAvailableOrgUsers}
              options={availableOrgUsers}
              placeholder={t(
                `alertsManager.createAlert.actionsStep.placeholder`,
              )}
              hasError={showErrors}
            />
            <StyledButton
              data-testid="create-alert-add-recipient"
              variant="main"
              color="primary"
              type="button"
              onClick={onAddAddedOrgUsers}
              disabled={isViewOnly || !selectedAvailableOrgUsers?.length}
            >
              {t('button.addRecipient')}
            </StyledButton>
          </StyledSection>
          {mappedOrgUsers.size > 0 && renderUsers()}
        </>
      )}
    </IWFormSection>
  )
}
