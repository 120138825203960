import { faFilter } from '@fortawesome/pro-regular-svg-icons'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import IWFilterBadgesOptions from 'shared/components/thunderbolt/IWFilterBadgeOptions'
import IWFilterBadgesRange from 'shared/components/thunderbolt/IWFilterBadgeRange'
import IWFilterBadgesDatetime from 'shared/components/thunderbolt/IWFilterBadgesDatetime'
import IWFilterBadgesGroup from 'shared/components/thunderbolt/IWFilterBadgesGroup'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { TIMEZONES } from 'shared/constants'
import useFilterBadges, {
  UseFilterBadgesHookProps,
} from 'shared/hooks/useFilterBadges'
import { ReturnProps as DateFilterState } from 'shared/hooks/useIWRelativeDatePicker'
import { WizardStepProps } from 'shared/types'
import { getUniqueValuesByKey } from 'shared/utils/filterBadgesUtils'
import styled from 'styled-components'
import { formatRelativeDate } from 'tools/insightsManager/helpers'
import {
  buildRangeFilter,
  getSpecialDate,
} from 'tools/insightsManager/utils/filtersToDruid'
import { getInternalBilateralTableSchema } from '../schemas'
import { IbtCsvRowItem } from '../types'
import LoadSchedulingTable from './LSTable'

const StyledActionableSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  & > :first-child {
    margin-bottom: 1rem;
  }
`

const StyledFilterButton = styled(IWButton)`
  width: fit-content;
`

const StyledIWFilterBadgesGroupWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 0;
`

const DEFAULT_FILTERS: {
  title: string
  key: string
  type: string
}[] = [
  {
    title: 'Market',
    key: 'market',
    type: 'string',
  },
  {
    title: 'Brand',
    key: 'brand',
    type: 'string',
  },
  {
    title: 'Contract Id',
    key: 'contract_id',
    type: 'string',
  },
  {
    title: 'Contract Name',
    key: 'contract_name',
    type: 'string',
  },
  {
    title: 'Zone',
    key: 'zone',
    type: 'string',
  },
  {
    title: 'Buyer',
    key: 'buyer',
    type: 'string',
  },
  {
    title: 'Buyer QSE Code',
    key: 'buyer_qse_code',
    type: 'string',
  },
  {
    title: 'Seller',
    key: 'seller',
    type: 'string',
  },
  {
    title: 'Seller QSE Code',
    key: 'seller_qse_code',
    type: 'string',
  },
  {
    title: 'Date',
    key: 'date',
    type: 'datetime',
  },
]

type Market = 'ERCOT' | 'PJM' | 'NEISO' | 'ISONE' | 'MISO' | 'NYISO'

function buildDateFilter(
  dimension: string,
  state: DateFilterState,
  { minTime, maxTime }: { minTime: DateTime; maxTime: DateTime },
  timezone: string = 'UTC',
) {
  const relativeDate = formatRelativeDate(state)
  const timezoneOption = { zone: timezone }

  if (relativeDate.main === 'on') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
      selectedSecondConditionOption: { value: '<', label: '' },
      secondConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      )
        .plus({ day: 1 })
        .toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'after') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'before') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '<=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.specificDate,
        timezoneOption,
      ).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'between') {
    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: DateTime.fromISO(
        relativeDate.datePickerMin,
        timezoneOption,
      ).toMillis(),
      selectedSecondConditionOption: { value: '<=', label: '' },
      secondConditionValue: DateTime.fromISO(
        relativeDate.datePickerMax,
        timezoneOption,
      ).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'previous') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })

    if (!specialDate) {
      return undefined
    }

    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '>=', label: '' },
      firstConditionValue: specialDate
        .setZone(timezone)
        .minus({ [relativeDate.timeLength]: relativeDate.timeLengthValue })
        .toMillis(),
      selectedSecondConditionOption: { value: '<=', label: '' },
      secondConditionValue: specialDate.setZone(timezone).toMillis(),
    })
    return { filter }
  }

  if (relativeDate.main === 'next') {
    const specialDate = getSpecialDate({ relativeDate, minTime, maxTime })

    if (!specialDate) {
      return undefined
    }

    const filter = buildRangeFilter(dimension, {
      selectedFirstConditionOption: { value: '<=', label: '' },
      firstConditionValue: specialDate
        .setZone(timezone)
        .plus({ [relativeDate.timeLength]: relativeDate.timeLengthValue })
        .toMillis(),
      selectedSecondConditionOption: { value: '>=', label: '' },
      secondConditionValue: specialDate.setZone(timezone).toMillis(),
    })
    return { filter }
  }

  return undefined
}

function applyFilters<T>(
  data: T[],
  filters: UseFilterBadgesHookProps,
  market: Market,
): T[] {
  const filteredData: T[] = []

  data.forEach((item) => {
    let include = true

    Object.entries(filters).forEach(([key, filter]) => {
      const { type, value, isInView } = filter

      if (filter && value && isInView) {
        if (type === 'string') {
          const filterValues = value.values
          const optionType = value.optionType
          const dataValue = item[key]

          if (optionType === 'include' && !filterValues.includes(dataValue)) {
            include = false
            return
          }

          if (optionType === 'exclude' && filterValues.includes(dataValue)) {
            include = false
            return
          }
        }

        if (type === 'datetime') {
          const dataValue = item[key]
          const milliseconds = DateTime.fromISO(dataValue).toMillis()
          const options = {
            minTime: DateTime.now()
              .setZone(TIMEZONES[market])
              .minus({ weeks: 5000 }),
            maxTime: DateTime.now()
              .setZone(TIMEZONES[market])
              .minus({ weeks: 5000 }),
          }

          const filterOptions = buildDateFilter(
            key,
            value,
            options,
            TIMEZONES[market],
          )

          if (filterOptions) {
            const {
              filter: { lower, lowerStrict, upper, upperStrict },
            } = filterOptions

            if (lower && upper) {
              if (
                (lowerStrict && milliseconds <= lower) ||
                (!lowerStrict && milliseconds < lower) ||
                (upperStrict && milliseconds >= upper) ||
                (!upperStrict && milliseconds > upper)
              ) {
                include = false
                return
              }
            }
          }
        }
      }
    })

    if (include) {
      filteredData.push(item)
    }
  })

  return filteredData
}

type StepState = {
  internalBilaterals: IbtCsvRowItem[]
}

export type Props = WizardStepProps<StepState>

const LSPreviewLoadStep = ({ state, onChange }: Props) => {
  const { t } = useTranslation()
  const [internalBilaterals] = useState(state?.internalBilaterals || [])
  const tableColumns = getInternalBilateralTableSchema()

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [dataToShow, setDataToShow] = useState(internalBilaterals)
  const [debouncer] = useState<{ timeout: NodeJS.Timeout | null }>({
    timeout: null,
  })

  const {
    filtersState,
    filtersInView,
    handleFilterAdd,
    handleFilterRemove: onFilterRemove,
    setDefaultFilters,
    handleFilterClose,
    handleFilterOpen,
    setDefaultOptions,
    handleFilterConfirm: onFilterConfirm,
  } = useFilterBadges()

  const handleFilterConfirm = (id, s) => {
    onFilterConfirm(id, s)
  }

  const handleFilterRemove = (id) => {
    onFilterRemove(id)
  }

  const handleSearchTextOptions = (columnName: string, searchValue: string) => {
    if (debouncer.timeout) {
      clearTimeout(debouncer.timeout)
    }

    const debouncedFn = new Promise<DropdownValueProps[]>((resolve) => {
      debouncer.timeout = setTimeout(async () => {
        const searchOptions = getUniqueValuesByKey(
          internalBilaterals,
          columnName,
          searchValue,
        )
        const dropdownOptions = searchOptions.map((option) => ({
          value: option,
          label: option,
        }))
        resolve(dropdownOptions as any)
      }, 500)
    })

    return debouncedFn
  }

  const handleGetOptions = (columnName: string) => {
    const top = getUniqueValuesByKey(internalBilaterals, columnName)
    setDefaultOptions(columnName, top as string[])
  }

  const filterMenu = Object.entries(
    DEFAULT_FILTERS.filter(({ key }) => isNaN(parseInt(key))),
  ).map((elem) => {
    const { key, title, type } = elem[1]
    return (
      <IWContextMenuItem
        as="button"
        key={key}
        label={title}
        onClick={() => {
          if (type === 'string') {
            handleGetOptions(key)
          }
          handleFilterAdd(key)
          setIsFilterMenuOpen(false)
        }}
      />
    )
  })

  useEffect(() => {
    onChange({ internalBilaterals: dataToShow }, true)
  }, [dataToShow])

  useEffect(() => {
    const defaultFilters = Object.entries(
      DEFAULT_FILTERS.filter(({ key }) => isNaN(parseInt(key))),
    ).reduce((acc, [, value]) => {
      acc[value.key] = {
        type: value.type as any,
        isInView: false,
        isOpen: false,
      }

      return acc
    }, {} as UseFilterBadgesHookProps)

    setDefaultFilters(defaultFilters)
  }, [DEFAULT_FILTERS])

  useEffect(() => {
    const selectedMarket =
      internalBilaterals?.length > 0 && internalBilaterals[0].market

    if (selectedMarket) {
      const filterDataInView = applyFilters(
        internalBilaterals,
        filtersState,
        selectedMarket as Market,
      )

      setDataToShow(filterDataInView)
    }
  }, [filtersState, internalBilaterals])

  return (
    <>
      <StyledActionableSection>
        <IWTypography
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {t('loadScheduling.previewStepTitle')}
        </IWTypography>

        <IWTypography fontHue={{ color: 'grey', value: 500 }} size="sm">
          {t('loadScheduling.previewStepDescription')}
        </IWTypography>
      </StyledActionableSection>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <IWContextMenu
            positions={['bottom']}
            align="start"
            menu={filterMenu}
            isOpen={isFilterMenuOpen}
            onClickOutside={() => setIsFilterMenuOpen(false)}
          >
            <StyledFilterButton
              variant="outline"
              color="grey"
              icon={faFilter}
              iconPosition="leading"
              onClick={() => setIsFilterMenuOpen(true)}
            >
              {t('button.filters')}
            </StyledFilterButton>
          </IWContextMenu>
        </div>

        <StyledIWFilterBadgesGroupWrapper>
          {Boolean(filtersInView.length) && (
            <IWFilterBadgesGroup label={t(`filterBadgesGroup.appliedFilters`)}>
              {filtersInView.map((col) => {
                const { name, type, isOpen } = col
                if (type === 'datetime') {
                  return (
                    <IWFilterBadgesDatetime
                      id={name}
                      key={name}
                      onConfirm={handleFilterConfirm}
                      onClick={() => handleFilterOpen(name)}
                      onCancel={handleFilterClose}
                      isOpen={isOpen}
                      disabledRelativeOptions={[
                        'firstDateAvailable',
                        'lastDateAvailable',
                      ]}
                      disabledMainOptions={[
                        'next',
                        'after',
                        'before',
                        'previous',
                      ]}
                      onRemove={handleFilterRemove}
                    />
                  )
                }
                if (type === 'number') {
                  return (
                    <IWFilterBadgesRange
                      id={name}
                      key={name}
                      onConfirm={handleFilterConfirm}
                      onCancel={handleFilterClose}
                      onClick={() => handleFilterOpen(name)}
                      isOpen={isOpen}
                      onRemove={handleFilterRemove}
                    />
                  )
                }
                if (type === 'string') {
                  return (
                    <IWFilterBadgesOptions
                      id={name}
                      key={name}
                      onClick={() => handleFilterOpen(name)}
                      isOpen={isOpen}
                      onConfirm={handleFilterConfirm}
                      onRemove={handleFilterRemove}
                      onCancel={handleFilterClose}
                      defaultOptions={
                        col.defaultOptions?.map((e) => ({
                          label: e,
                          value: e,
                        })) || []
                      }
                      onGetOptions={async (inputValue) => {
                        return handleSearchTextOptions(name, inputValue)
                      }}
                    />
                  )
                }
                return <></>
              })}
            </IWFilterBadgesGroup>
          )}
        </StyledIWFilterBadgesGroupWrapper>
      </div>

      <LoadSchedulingTable
        columns={tableColumns}
        items={dataToShow}
        pinnedColumns={{ brand: 'left' }}
      />
    </>
  )
}

export default LSPreviewLoadStep
