import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LayoutContext from 'shared/contexts/LayoutContext'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { Outlet } from 'react-router'
import { ReactComponent as NotSupportedImg } from 'images/not-supported.svg'
import VerticalNavigation from './VerticalNavigation'
import Notifications from './Notifications'

const StyledNotSupportedWrapper = styled.div`
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: ${(props) => props.theme.layers.sticky};
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
`
/**
 * Component which displays on < sm screens indicating that they screen is not supported
 */
const NotSupported = () => {
  const { t } = useTranslation()
  return (
    <StyledNotSupportedWrapper>
      <NotSupportedImg />
      <IWTypography
        size="lg"
        weight="bold"
        fontHue={{ color: 'grey', value: 900 }}
      >
        {t('messages.deviceNotSupported')}
      </IWTypography>
      <IWTypography
        size="sm"
        weight="regular"
        fontHue={{ color: 'grey', value: 700 }}
      >
        {t('messages.deviceNotSupportedCopy')}
      </IWTypography>
    </StyledNotSupportedWrapper>
  )
}

/** Standard page content for use, has padding */
export const Page = styled.div<{
  isMediumScreen?: boolean
  menuOpen?: boolean
}>`
  padding: 1.5rem;
  width: 100vw;
  height: 100vh;
`

/** Page content for use in "overlay" pages, is fixed and full screen to obscure sidebars */
export const OverlayPage = styled.div`
  position: absolute;
  flex-grow: 1;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.palette.grey[0]};
  z-index: ${(props) => props.theme.layers.overlayout}; // to get over sidebar
  width: 100vw;
  height: 100%;
  overflow: auto;
`

export const FullPageSidebar = styled.div<{ isSmallScreen?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isSmallScreen ? 'column' : 'row')};
  width: 100%;
  height: 100%;

  & > main {
    height: 100%;
    width: ${(props) => (props.isSmallScreen ? '100%' : 'auto')};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
    margin: ${(props) => (props.isSmallScreen ? '0' : '0 auto')};
  }
`

export const FullPageMainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  transition: margin-left 250ms ease-in-out;
  max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
  margin: 0 auto;
`

const MainContent = styled.div<{
  isMediumScreen?: boolean
  menuOpen?: boolean
}>`
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 1.5rem;
  margin-right: 1rem;
  margin-left: ${(props) => {
    if (props.isMediumScreen) {
      return '4rem'
    }
    return props.menuOpen ? '17rem' : '5rem'
  }};
  transition: margin-left 250ms ease-in-out;

  & main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: ${(props) => props.theme.dimensions.maxScreenWidth};
    margin: 0 auto;
    padding-bottom: 2rem;
    width: 100%;
  }
`

const SideBar = styled.aside<{ menuOpen?: boolean }>`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.layers.overlayout};
  width: ${(props) => {
    return props.menuOpen ? '16rem' : '4rem'
  }};
  transition: width 250ms ease-in-out;
  height: 100%;
`

const BackDrop = styled.div`
  z-index: ${(props) => props.theme.layers.overlayout};
  content: '';
  top: 0;
  left: 0;
  position: fixed;
  cursor: pointer;
  transition: opacity 250ms ease-in;
  height: 100vh;
  width: 100vw;
  opacity: 0.6;
  background: ${(props) => props.theme.palette.grey[900]};
`

/**
 * This wrapper abstracts the not supported functionality used on all pages
 */
export const LayoutWrapper = ({ children }: { children: ReactElement }) => {
  const { isExtraSmallScreen } = useContext(LayoutContext)
  return <>{isExtraSmallScreen ? <NotSupported /> : children}</>
}

/**
 * Layout component to display content with a left-hand sidebar menu which displays to the left on large screens
 * and as an overlay on < lg screens
 */
export const IWMainLayout = () => {
  const {
    isMediumScreen,
    menuOpen,
    toggleMenu,
    isNotificationListMenuOpen,
    setIsNotificationListMenuOpen,
  } = useContext(LayoutContext)

  return (
    <LayoutWrapper>
      <Page isMediumScreen={isMediumScreen} menuOpen={menuOpen}>
        {menuOpen && isMediumScreen && <BackDrop onClick={toggleMenu} />}
        <SideBar menuOpen={menuOpen}>
          <VerticalNavigation />
        </SideBar>
        <MainContent isMediumScreen={isMediumScreen} menuOpen={menuOpen}>
          <Outlet />
        </MainContent>
        {isNotificationListMenuOpen && (
          <Notifications onClose={() => setIsNotificationListMenuOpen(false)} />
        )}
      </Page>
    </LayoutWrapper>
  )
}
