import styled from 'styled-components'
import IWTypography from './IWTypography'

export const StyledTitle = styled(IWTypography)`
  line-height: 2rem;
  display: flex;
  gap: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
  font-weight: ${(props) => props.theme.typography.fontWeights.medium};
`

const IWPageHeader = styled.div<{
  isFullScreen?: boolean
}>`
  position: sticky;
  z-index: ${(props) => props.theme.layers.sticky};
  color: ${(props) => props.theme.palette.grey[800]};
  background: ${(props) => props.theme.palette.grey[0]};
  display: flex;
  align-items: center;
  top: 0;
  gap: 0.5rem;
  justify-content: center;

  border-bottom: ${(props) => {
    return props.isFullScreen
      ? `1px solid ${props.theme.palette.grey[200]}`
      : 'none'
  }};

  padding: ${(props) => {
    return props.isFullScreen ? '1.5rem' : 0
  }};
`

export default IWPageHeader
