import React from 'react'
import styled from 'styled-components'
import IWInputLabel, { IWInputInfo } from './IWCommonLabels'
import { IWToggleProps } from './IWToggle'

export interface Props {
  /** Optional Label to display for this input  */
  label?: string
  children: React.ReactElement<IWToggleProps>[]
  /** helper text for additional infomation */
  helperText?: string
  /** error message on input which will display error message */
  errorText?: string
}

const StyledChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

/**
 * This represents a list of toggle input items
 */
const IWToggleGroup = ({ label, children, helperText, errorText }: Props) => {
  return (
    <StyledDiv>
      <IWInputLabel label={label} />
      <StyledChildContainer>{children}</StyledChildContainer>
      <IWInputInfo helperText={helperText} errorText={errorText} />
    </StyledDiv>
  )
}

export default IWToggleGroup
