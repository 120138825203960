import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faGaugeMax,
  faLightbulbOn,
  faRetweet,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWProgressTracker from 'shared/components/thunderbolt/IWProgressTracker'
import IWFormContainer from 'shared/components/thunderbolt/IWFormContainer'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import UserContext from 'shared/contexts/UserContext'
import { createAlert } from 'shared/podServiceClient'
import { useNavigate } from 'react-router'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import IWError from 'shared/components/thunderbolt/IWError'
import {
  IWGenericCard,
  IWGenericCardBody,
  IWGenericCardHeader,
} from 'shared/components/thunderbolt/IWGenericCard'
import ConfirmChangesModal from '../../insightsManager/components/ConfirmChangesModal'
import InnoPodAlertStep, { Props as InnoPodAlertProp } from './InnoPodAlertStep'
import TriggersAlertStep, {
  Props as TriggerAlertProps,
} from './TriggersAlertStep'
import ActionsAlertStep, { Props as AlertRecipient } from './ActionsAlertStep'
import GeneralAlertStep, {
  Props as GeneralAlertStepProps,
} from './GeneralAlertStep'
import SummaryAlertStep from './SummaryAlertStep'
import { Pod } from '../../insightsManager/types'
import {
  formatAttributeFilters,
  formatMeasureConditionsFilters,
} from '../../insightsManager/helpers'
import { AlertCreateSchema } from '../types'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import { buildIntervalLabel } from '../utils/utils'
import LayoutContext from 'shared/contexts/LayoutContext'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 8rem;
`

const FixedNavBar = styled.div`
  position: fixed;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette.primary[50]};
`

type ValueOf<T> = T[keyof T]

type WizardStep<T> = { isStepValid: boolean; value: ValueOf<T>; name: keyof T }
export type WizardStepBuilder<T> = { [P in keyof T]: WizardStep<T[P]> }

type UnknownSteps = [
  { innoPod: undefined },
  { triggers: undefined },
  { actions: undefined },
  { general: undefined },
  { summary: undefined },
]

type CreateAlertSteps = [
  { innoPod: InnoPodAlertProp['state'] },
  { triggers: TriggerAlertProps['state'] },
  { actions: AlertRecipient['state'] },
  { general: GeneralAlertStepProps['state'] },
  { summary: undefined },
]

type AllSteps = UnknownSteps | CreateAlertSteps

const wizardSteps = (): WizardStepBuilder<AllSteps> => [
  {
    name: 'innoPod',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'triggers',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'actions',
    isStepValid: true,
    value: undefined,
  },
  {
    name: 'general',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'summary',
    isStepValid: false,
    value: undefined,
  },
]

enum Steps {
  innoPod,
  triggers,
  actions,
  general,
  summary,
}

const CreateAlertWizard = () => {
  const { t } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const { userId } = useContext(UserContext)
  const toast = useToast()
  const navigate = useNavigate()
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
  const [isChangePopupOpen, setIsChangePopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const wizardStateRef = useRef<WizardStepBuilder<AllSteps>>(wizardSteps())
  const tempInnoPodSelectionRef = useRef<AllSteps[Steps.innoPod]['innoPod']>()
  const tempTriggersSelectionRef =
    useRef<AllSteps[Steps.triggers]['triggers']>()
  const tempActionsSelectionRef = useRef<AllSteps[Steps.actions]['actions']>()
  const tempGeneralSelectionRef = useRef<AllSteps[Steps.general]['general']>()

  const setNewTempInnoPodSelections = () => {
    const [innoPod, triggers, actions, general] = wizardStateRef.current
    if (activeStepIndex === Steps.innoPod) {
      tempInnoPodSelectionRef.current = innoPod.value
    }
    if (activeStepIndex === Steps.triggers) {
      tempTriggersSelectionRef.current = triggers.value
    }
    if (activeStepIndex === Steps.actions) {
      tempActionsSelectionRef.current = actions.value
    }
    if (activeStepIndex === Steps.general) {
      tempGeneralSelectionRef.current = general.value
    }
  }

  const onBack = () => {
    if (activeStepIndex !== 0) {
      setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex - 1)
    }
  }

  const handleStepChange = (stepId: number) => {
    if (
      !wizardStateRef.current[activeStepIndex].isStepValid &&
      activeStepIndex < stepId
    ) {
      setShowErrors(true)
      return
    }
    const [innoPod, triggers, actions, general] = wizardStateRef.current
    const hasInnoPodSelectionChanged =
      activeStepIndex === Steps.innoPod &&
      tempInnoPodSelectionRef.current &&
      tempInnoPodSelectionRef.current?.userPodId !== innoPod.value?.userPodId

    const hasTriggersSelectionChanged =
      activeStepIndex === Steps.triggers &&
      tempTriggersSelectionRef.current &&
      JSON.stringify(tempTriggersSelectionRef.current) !==
        JSON.stringify(triggers.value)

    const hasActionsSelectionChanged =
      activeStepIndex === Steps.actions &&
      tempActionsSelectionRef.current &&
      tempActionsSelectionRef.current?.length !== actions.value?.length

    const hasGeneralSelectionChanged =
      activeStepIndex === Steps.general &&
      tempGeneralSelectionRef.current &&
      tempGeneralSelectionRef.current?.alertName !== general.value?.alertName &&
      tempGeneralSelectionRef.current?.pauseAlert !== general.value?.pauseAlert

    if (
      hasInnoPodSelectionChanged ||
      hasTriggersSelectionChanged ||
      hasActionsSelectionChanged ||
      hasGeneralSelectionChanged
    ) {
      setIsChangePopupOpen(true)
      return
    }

    setNewTempInnoPodSelections()
    setShowErrors(false)
    setActiveStepIndex(stepId)
  }

  const createAlertSchema = (innoPod, triggers, action, general) => {
    const attributeFilters = formatAttributeFilters(
      triggers.value?.attributeFilters,
    )

    const alertSchema: AlertCreateSchema = {
      timezone,
      attributeFilters,
      userAlertName: general.value.alertName,
      userPodId: innoPod.value.userPodId,
      aggregationFnId: triggers.value.selectedTimeSeriesAggregation.value,
      timeGroupId: triggers.value.selectedTimeSeriesGroupBy.value,
      alertConditions: formatMeasureConditionsFilters(
        triggers.value.measures || [],
      ),
      splits: triggers.value.selectedSplitAnalysis
        ? triggers.value.selectedSplitAnalysis.map((e) => e.value)
        : undefined,
      timeOffset: triggers.value.selectedComparisonPeriod?.value,
      shouldMatchAllConditions: Boolean(
        triggers.value.selectedConditionMatch === 'matchAll',
      ),
      recipients: (action.value || [])
        .map((user) => user.value)
        .filter((uid) => uid !== userId),
      isPaused: general.value.pauseAlert,
    }
    return alertSchema
  }

  const handlePodBuilderFormSubmit = () => {
    const [innoPod, triggers, action, general] = wizardStateRef.current
    if (
      !innoPod.value ||
      !triggers.value ||
      !triggers.value.selectedTimeSeriesGroupBy ||
      !triggers.value.selectedTimeSeriesAggregation ||
      triggers.value.measures?.length === 0 ||
      !general.value?.alertName
    ) {
      return
    }

    setIsLoading(true)

    const alertSchema = createAlertSchema(innoPod, triggers, action, general)
    createAlert(alertSchema)
      .then(() => {
        toast.secondary(
          t(`alertsManager.createAlert.alertCreationSuccess`, {
            userPodName: innoPod.value?.userPodName,
          }),
        )
        navigate('/tools/alerts-manager')
      })
      .catch((e) => {
        const message = t('insightManager.createPod.errors.podCreationError')
        toast.alert(message, {
          status: e.response.status,
          canDismiss: true,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleClose = () => {
    setIsChangePopupOpen(false)
  }

  const handleChangeWizardConfirm = () => {
    const stepDefaultValues = wizardSteps()
    if (activeStepIndex < Steps.triggers) {
      wizardStateRef.current[Steps.triggers] = stepDefaultValues[Steps.triggers]
    }
    if (activeStepIndex < Steps.actions) {
      wizardStateRef.current[Steps.actions] = stepDefaultValues[Steps.actions]
    }
    if (activeStepIndex < Steps.general) {
      wizardStateRef.current[Steps.general] = stepDefaultValues[Steps.general]
    }
    if (activeStepIndex < Steps.summary) {
      wizardStateRef.current[Steps.summary] = stepDefaultValues[Steps.summary]
    }
    setNewTempInnoPodSelections()
    setIsChangePopupOpen(false)
    setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex + 1)
  }

  const renderPodSummary = (innoPod: InnoPodAlertProp['state']) => {
    return (
      <IWGenericCard>
        <IWGenericCardHeader>{innoPod?.userPodName}</IWGenericCardHeader>
        <IWGenericCardBody>
          <IWBadgeGroup>
            <IWCardBadge
              label={t(`insights.${innoPod?.podType.insightId}.label`)}
              icon={faLightbulbOn}
            />
            <IWCardBadge
              label={t(
                `insights.${innoPod?.podType.insightId}.metrics.${innoPod?.podType.metricId}.label`,
              )}
              icon={faGaugeMax}
            />
            <IWCardBadge
              label={buildIntervalLabel(innoPod as Pod, t)}
              icon={faRetweet}
            />
          </IWBadgeGroup>
        </IWGenericCardBody>
      </IWGenericCard>
    )
  }

  const getActiveStep = () => {
    const datasource = wizardStateRef.current[Steps.innoPod].value?.datasource
    const userPodId = wizardStateRef.current[Steps.innoPod].value?.userPodId
    const podType = wizardStateRef.current[Steps.innoPod].value?.podType
    const [innoPod, triggers, action, general] = wizardStateRef.current

    switch (activeStepIndex) {
      case Steps.innoPod:
        return (
          <InnoPodAlertStep
            state={wizardStateRef.current[Steps.innoPod].value}
            onChange={(value, isStepValid) => {
              wizardStateRef.current[Steps.innoPod] = {
                ...wizardStateRef.current[Steps.innoPod],
                isStepValid,
                value,
              }
            }}
            showErrors={showErrors}
          />
        )
      case Steps.triggers:
        return !(datasource && userPodId && podType) ? (
          <IWError />
        ) : (
          <>
            <IWFormSection
              sectionTitle={t(`alertsManager.createAlert.selectedInnoPod`)}
            >
              {renderPodSummary(innoPod.value)}
            </IWFormSection>
            <TriggersAlertStep
              datasource={datasource}
              userPodId={userPodId}
              podType={podType}
              state={wizardStateRef.current[Steps.triggers].value}
              onChange={(value, isStepValid) => {
                wizardStateRef.current[Steps.triggers] = {
                  ...wizardStateRef.current[Steps.triggers],
                  isStepValid,
                  value,
                }
              }}
              showErrors={showErrors}
            />
          </>
        )
      case Steps.actions:
        return (
          <>
            <IWFormSection
              sectionTitle={t(`alertsManager.createAlert.selectedInnoPod`)}
            >
              {renderPodSummary(innoPod.value)}
            </IWFormSection>
            <ActionsAlertStep
              userPodRole={innoPod?.value?.userPodRole}
              state={wizardStateRef.current[Steps.actions].value}
              onChange={(value, isStepValid) => {
                wizardStateRef.current[Steps.actions] = {
                  ...wizardStateRef.current[Steps.actions],
                  isStepValid,
                  value,
                }
              }}
              showErrors={showErrors}
            />
          </>
        )
      case Steps.general:
        return (
          <>
            <IWFormSection
              sectionTitle={t(`alertsManager.createAlert.selectedInnoPod`)}
            >
              {renderPodSummary(innoPod.value)}
            </IWFormSection>
            <GeneralAlertStep
              state={wizardStateRef.current[Steps.general].value}
              onChange={(value, isStepValid) => {
                wizardStateRef.current[Steps.general] = {
                  ...wizardStateRef.current[Steps.general],
                  isStepValid,
                  value,
                }
              }}
              showErrors={showErrors}
            />
          </>
        )
      case Steps.summary:
        return (
          <>
            <IWFormSection
              sectionTitle={t(`alertsManager.createAlert.selectedInnoPod`)}
            >
              {renderPodSummary(innoPod.value)}
            </IWFormSection>
            <SummaryAlertStep
              state={createAlertSchema(innoPod, triggers, action, general)}
              onChange={() => {}}
              showErrors={showErrors}
            />
          </>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    return () => {
      wizardStateRef.current = wizardSteps()
      tempInnoPodSelectionRef.current = undefined
      tempTriggersSelectionRef.current = undefined
      tempActionsSelectionRef.current = undefined
      tempGeneralSelectionRef.current = undefined
    }
  }, [])

  return (
    <>
      <StyledDiv id="form-div">
        <IWProgressTracker
          steps={wizardStateRef.current.map((s) =>
            t(`alertsManager.createAlert.${s.name}`),
          )}
          currentActiveIndex={activeStepIndex}
          onStepClick={(e, id) => handleStepChange(id)}
        />
        <IWFormContainer onSubmit={(e) => e.preventDefault()}>
          {getActiveStep()}
        </IWFormContainer>
        <ConfirmChangesModal
          icon={faExclamationTriangle}
          title={t(`alertsManager.createAlert.confirmChangeLabel`)}
          body={t(`alertsManager.createAlert.confirmChangeDesc`)}
          isOpen={isChangePopupOpen}
          handleClose={handleClose}
          handleConfirm={handleChangeWizardConfirm}
        />
      </StyledDiv>
      <FixedNavBar>
        <IWButton
          variant="outline"
          onClick={onBack}
          data-testid="create-alert-back"
          disabled={activeStepIndex === Steps.innoPod || isLoading}
        >
          {t('button.back')}
        </IWButton>
        {activeStepIndex === Steps.summary ? (
          <IWButton
            data-testid="create-alert-create"
            disabled={isLoading}
            onClick={() => handlePodBuilderFormSubmit()}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSync} />
            ) : (
              t('alertsManager.createAlert.createAlert')
            )}
          </IWButton>
        ) : (
          <IWButton
            data-testid="create-alert-next"
            onClick={() => handleStepChange(activeStepIndex + 1)}
          >
            {t('button.next')}
          </IWButton>
        )}
      </FixedNavBar>
    </>
  )
}

export default CreateAlertWizard
