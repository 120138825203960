import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import { SFTPCredential } from '../../integrationsServiceClient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { number, object, string } from 'yup'
import { formatFormErrors } from 'shared/helpers'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

const StyledModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

const StyledInputRow = styled.div`
  display: flex;
  gap: 1rem;
  > * {
    flex: 1;
  }
`

const InputWithAction = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  > div {
    flex: 1;
  }
  > button {
    margin-top: 1.25rem;
  }
`

interface Props {
  submitStatus: 'waiting' | 'submitting' | 'complete'
  credential: SFTPCredential
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onCancel: (e: SyntheticEvent) => void
  onSave: (e: SyntheticEvent, credential: SFTPCredential) => void
  onTest: (e: SyntheticEvent) => void
}

const SFTPEditModal = ({
  onChange,
  submitStatus,
  credential,
  onCancel,
  onSave,
  onTest,
}: Props) => {
  const [changePassword, setChangePassword] = useState<boolean>(false)
  const { t } = useTranslation()
  const [errors, setErrors] = useState<{
    name?: string
    description?: string
    host?: string
    port?: string
    username?: string
    password?: string
  }>({})
  const checkCredentialIsValid = async (): Promise<boolean> => {
    const updateSchema = object({
      name: string().required().min(3).max(50),
      description: string()
        .min(3)
        .max(100)
        .nullable(true)
        .transform((value) => (!!value ? value : null)),
      host: string().required().min(3).max(100),
      port: number().required(),
      path: string().nullable().max(100),
      username: string().required().min(3).max(50),
      ...(changePassword
        ? { password: string().required().min(3).max(50) }
        : {}),
    })
    try {
      await updateSchema.validate(credential, {
        abortEarly: false,
      })
      return true
    } catch (err: any) {
      setErrors(
        formatFormErrors(
          err.errors,
          'systemPreferences.integrations.sftp.fields',
        ),
      )
      return false
    }
  }
  return (
    <IWModal open={true} onClose={onCancel}>
      <IWModalHeader>
        <IWTypography as="h1" size="lg" weight="medium">
          {t('systemPreferences.integrations.sftp.editCredentialHeader')}
        </IWTypography>
      </IWModalHeader>
      <StyledModalContent>
        <IWTextInput
          required
          errorText={errors.name}
          hasError={Boolean(errors.name)}
          value={credential.name}
          name="name"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.name')}
        />
        <IWTextInput
          value={credential.description}
          errorText={errors.description}
          hasError={Boolean(errors.description)}
          name="description"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.description')}
        />
        <StyledInputRow>
          <IWTextInput
            required
            errorText={errors.host}
            hasError={Boolean(errors.host)}
            value={credential.host}
            name="host"
            onChange={onChange}
            label={t('systemPreferences.integrations.sftp.fields.host')}
          />
          <IWTextInput
            required
            errorText={errors.port}
            hasError={Boolean(errors.port)}
            value={credential.port}
            name="post"
            type="number"
            onChange={onChange}
            label={t('systemPreferences.integrations.sftp.fields.port')}
          />
        </StyledInputRow>
        <IWTextInput
          required
          errorText={errors.username}
          hasError={Boolean(errors.username)}
          value={credential.username}
          name="username"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.username')}
        />
        <InputWithAction>
          <IWTextInput
            required
            errorText={errors.password}
            hasError={Boolean(errors.password)}
            value={credential.password}
            onChange={onChange}
            disabled={!changePassword}
            name="password"
            type="password"
            label={t('systemPreferences.integrations.sftp.fields.password')}
          />
          <IWButton onClick={() => setChangePassword(!changePassword)}>
            <FontAwesomeIcon icon={faEdit} />
          </IWButton>
        </InputWithAction>

        <IWTextInput
          value={credential.path}
          name="path"
          onChange={onChange}
          label={t('systemPreferences.integrations.sftp.fields.path')}
        />
        <div>
          <IWButton
            variant="outline"
            disabled={submitStatus === 'submitting'}
            onClick={async (e) => {
              // we need the user to re-enter the password to test
              if (!changePassword) {
                setErrors({
                  ...errors,
                  password: t('forms.errors.requiredField', {
                    path: t(
                      'systemPreferences.integrations.sftp.fields.password',
                    ),
                  }),
                })
              } else {
                if (await checkCredentialIsValid()) {
                  onTest(e)
                }
              }
            }}
          >
            {t('systemPreferences.integrations.sftp.button.test')}
          </IWButton>
        </div>
      </StyledModalContent>
      <StyledModalFooter>
        <IWButton
          disabled={submitStatus === 'submitting'}
          color="grey"
          variant="outline"
          onClick={onCancel}
        >
          {t('button.cancel')}
        </IWButton>
        <IWButton
          disabled={submitStatus === 'submitting'}
          onClick={async (e) => {
            if (await checkCredentialIsValid()) {
              onSave(e, credential)
            }
          }}
        >
          {t('button.save')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default SFTPEditModal
