import { WizardStepProps } from 'shared/types'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import LoadSchedulingTable from './LSTable'
import { getLSSubmitSummaryTableSchema } from '../schemas'
import { groupBy } from 'lodash'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'

type SummaryData = {
  market: string
  brand: string
  location: string
  date: string
}

type StepState = {
  summary: SummaryData[]
}

export type Props = WizardStepProps<StepState>

const SubmitSummaryStep = ({ state }: Props) => {
  const { t } = useTranslation()
  const successTableColumns = getLSSubmitSummaryTableSchema()

  const groupedByLocation = groupBy(
    state?.summary,
    ({ market, brand, location }) => `${market}-|-${brand}-|-${location}`,
  )

  const tableData = Object.entries(groupedByLocation).map(([key, value]) => {
    const [market, brand, location] = key.split('-|-')

    const dates = value.map(({ date }) => DateTime.fromISO(date))
    const minDate = DateTime.min(...dates).toISODate()
    const maxDate = DateTime.max(...dates).toISODate()

    return {
      market,
      brand,
      location,
      dateRange: `${minDate} - ${maxDate}`,
      status: (
        <div style={{ display: 'inline-block' }}>
          <IWGeneralBadge
            label={t('loadScheduling.processing')}
            color="warning"
          />
        </div>
      ),
    }
  })

  return (
    <>
      <IWTypography size="lg">
        {t('loadScheduling.summaryResultLabel')}
      </IWTypography>
      <LoadSchedulingTable
        columns={successTableColumns}
        items={tableData || []}
      />
    </>
  )
}

export default SubmitSummaryStep
