import styled from 'styled-components'

const IWContextDivider = styled('hr')`
  background-color: ${(props) => props.theme.palette.grey[300]};
  margin: 0 0 0 0;
  height: 1px;
  border: none;
`

export default IWContextDivider
