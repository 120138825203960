/**
 * This util contains localized datetime duration humanizers
 * These will convert durations into human like "since" or "ago" strings
 * For example, Xms might be best displayed as Xminutes or Xhours
 * We also shorten minutes to (in en) "m" and hours to "h"
 */
import humanizeDuration from 'humanize-duration'

const base = {
  units: ['w', 'd', 'h', 'm', 's'],
  round: true,
  spacer: '',
  delimiter: ' ',
}

const en = humanizeDuration.humanizer({
  ...base,
  language: 'shortEn',
  languages: {
    shortEn: {
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
    },
  },
})

const languageMap = {
  en,
}

const useDurationHumanizer = ({ language = 'en' }) => {
  return {
    humanizer:
      languageMap[language] ||
      humanizeDuration.humanizer({
        ...base,
        language,
      }),
    baseOptions: base,
  }
}

export default useDurationHumanizer
