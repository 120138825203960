import {
  ValidActualUsageMetricIds,
  ValidLongTermForecastMetricIds,
  ValidRiskMetricIds,
  ValidShortTermForecastMetricIds,
  ValidShortTermForecastPerformanceMetricIds,
  ValidShortTermForecastPerformanceSystemMetricIds,
  ValidShortTermForecastPerformanceTopDownMetricIds,
  ValidShortTermForecastSystemMetricIds,
  ValidWholesaleSettlementMetricIds,
} from '../tools/insightsManager/types'

interface BaseUserType {
  userId: string
  email: string
  name: string
  organization: string
}

export interface ServerUserType extends BaseUserType {
  toolPolicies: string[]
  podTypeSubscriptions: string[]
  dashboardType: string[]
}

export interface UserType extends BaseUserType {
  availablePodTypesMap: AvailablePodTypesMap
  availableTools: UserTools[]
  availableToolPolicies: ValidPolicies[]
  availableDashboardType: string[]
}

/**
 * User-defined type guard for "UserType", in case it is needed.
 */
export function isUserType(obj: { [field: string]: any }): obj is UserType {
  const {
    email,
    name,
    organization,
    toolPolicies,
    podTypeSubscriptions,
    dashboardType,
  } = obj
  return (
    typeof email === 'string' &&
    typeof name === 'string' &&
    typeof organization === 'string' &&
    typeof podTypeSubscriptions === 'object' &&
    typeof toolPolicies === 'object' &&
    typeof dashboardType === 'object'
  )
}

export type UserTools =
  | 'insightsManager'
  | 'dataExploration'
  | 'alertsManager'
  | 'loadScheduling'
  | 'dashboardsManager'
  | 'importManager'

export type AvailablePodTypesMap = {
  shortTermForecast?: ValidShortTermForecastMetricIds[]
  shortTermForecastPerformance?: ValidShortTermForecastPerformanceMetricIds[]
  shortTermForecastPerformanceTopDown?: ValidShortTermForecastPerformanceTopDownMetricIds[]
  riskManagement?: ValidRiskMetricIds[]
  longTermForecast?: ValidLongTermForecastMetricIds[]
  shortTermForecastSystem?: ValidShortTermForecastSystemMetricIds[]
  actualUsage?: ValidActualUsageMetricIds[]
  wholesaleSettlement?: ValidWholesaleSettlementMetricIds[]
  shortTermForecastPerformanceSystem?: ValidShortTermForecastPerformanceSystemMetricIds[]
}

export type ValidPolicies =
  | 'insightsManager:reader'
  | 'insightsManager:editor'
  | 'alertsManager:reader'
  | 'alertsManager:editor'
  | 'dataExploration:reader'
  | 'loadScheduling:editor'
  | 'loadScheduling:reader'
  | 'loadScheduling:ibt'
  | 'dashboardsManager:reader'
  | 'importManager:editor'
  | 'system:admin'

export const paginationOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
]

export type PaginatedData<T> = {
  total: number
  rows: T[]
}

export type PapaparseReturn<T> = {
  data: T[]
  headers: string[]
}

export type WizardStepProps<T> = {
  state?: T
  onChange: (value: T, stepIsValid: boolean) => void
  showErrors: boolean
}

type SuccessResponseBody<T> = {
  status: 'success'
  data: T
}

type FailResponseBody<T> = {
  status: 'fail'
  data: T
}

type ErrorResponseBody<T> = {
  status: 'error'
  message: string
  data?: T
  code?: number | string
}

/**
 * @desc JSend Structure
 * @link https://github.com/omniti-labs/jsend
 */
export type JSendResponse<T> =
  | SuccessResponseBody<T>
  | FailResponseBody<T>
  | ErrorResponseBody<T>
