import React from 'react'
import { PodType } from 'tools/insightsManager/types'
import { useTranslation } from 'react-i18next'
import SettlementConfiguration, {
  Props as SettlementProps,
} from '../insights/shortTermForecast/settlement/SettlementConfiguration'
import TopDownConfiguration, {
  Props as TopDownProps,
} from '../insights/shortTermForecast/topDown/TopDownConfiguration'
import MeterConfiguration, {
  Props as MeterProps,
} from '../insights/shortTermForecast/meter/MeterConfiguration'
import ProfileConfiguration, {
  Props as ProfileProps,
} from '../insights/shortTermForecast/profile/ProfileConfiguration'
import CustomerTypeConfiguration, {
  Props as CustomerTypeProps,
} from '../insights/shortTermForecast/customerType/CustomerTypeConfiguration'
import IWNetOpenPositionConfigurationStep, {
  Props as NetOpenPositionProps,
} from '../insights/riskManagement/NetOpenPositionConfiguration'
import IWSupplyTradesConfigurationStep, {
  Props as SupplyTradesProps,
} from '../insights/riskManagement/SupplyTradesConfiguration'
import GeneralShortTermPerformanceConfiguration, {
  Props as GeneralShortTermPerformanceProps,
} from '../insights/shortTermForecastPerformance/GeneralConfiguration'
import ShortTermForecastSystemConfiguration, {
  metricMarketMap,
  Props as STFSystemProps,
} from '../insights/shortTermForecastSystem/STFSystemConfiguration'
import LongTermForecastMeterConfiguration from '../insights/longTermForecast/meter/LongTermForecastMeterConfiguration'
import LTFCustomerTypeConfiguration from '../insights/longTermForecast/customerType/LTFCustomerTypeConfiguration'
import LTFProductTypeConfiguration from '../insights/longTermForecast/productType/LTFProductTypeConfiguration'
import LTFProfileConfiguration from '../insights/longTermForecast/profile/LTFProfileConfiguration'
import IWExplodedTradesConfigurationStep, {
  Props as ExplodedTradesProps,
} from '../insights/riskManagement/ExplodedTradesConfiguration'
import LTFSettlementConfiguration, {
  Props as LTFSettlementProps,
} from '../insights/longTermForecast/settlement/LTFSettlementConfiguration'
import SettlementWeatherConfiguration, {
  Props as SettlementWeatherProps,
} from '../insights/shortTermForecast/settlementWeather/SettlementWeatherConfiguration'
import CustomerTypeWeatherConfiguration, {
  Props as CustomerTypeWeatherProps,
} from '../insights/shortTermForecast/customerTypeWeather/CustomerTypeWeatherConfiguration'
import ProfileWeatherConfiguration, {
  Props as ProfileWeatherProps,
} from '../insights/shortTermForecast/profileWeather/ProfileWeatherConfiguration'
import TopDownWeatherConfiguration, {
  Props as TopDownWeatherProps,
} from '../insights/shortTermForecast/topDownWeather/TopDownWeatherConfiguration'
import ActualUsageSettlementConfiguration, {
  Props as ActualUsageSettlementProps,
} from '../insights/actualUsage/settlement/SettlementConfiguration'
import ActualUsageMeterConfiguration, {
  Props as ActualUsageMeterProps,
} from '../insights/actualUsage/meter/MeterConfiguration'
import WholesaleSettlementSummaryConfiguration, {
  wholesaleSettlementMetricMarketMap,
  Props as WholesaleSettlementSummaryProps,
} from '../insights/wholesaleSettlement/summary/SummaryConfiguration'
import WholesaleSettlementAllocationConfiguration, {
  wholesaleSettlementAllocationMetricMarketMap,
  Props as WholesaleSettlementAllocationProps,
} from '../insights/wholesaleSettlement/allocation/AllotmentConfiguration'
import LTFBlockTypeConfiguration, {
  Props as BlockTypeProps,
} from '../insights/longTermForecast/blockType/LTFBlockTypeConfiguration'
import ProductTypeConfiguration, {
  Props as ProductTypeProps,
} from '../insights/shortTermForecast/productType/ProductTypeConfiguration'
import PerformanceSystemConfiguration, {
  metricMarketMap as perfSystemMetricMarketMap,
  Props as PerformanceSystemProps,
} from '../insights/shortTermPerformanceSystem/PerformaceSystemConfiguration'
import AttributeInfoConfiguration, {
  Props as AttributeInfoProps,
} from '../insights/attributeInfo/meter/MeterConfiguration'

type State = SettlementProps['state'] | MeterProps['state']

interface Props {
  podType?: PodType
  state?: State
  showErrors: boolean
  onChange: (value: State, isStepValid: boolean) => void
}

const ConfigurationWrapper = ({
  podType,
  state,
  showErrors,
  onChange,
}: Props) => {
  const { t } = useTranslation()
  if (podType?.insightId === 'shortTermForecast') {
    if (podType.metricId === 'settlement') {
      return (
        <SettlementConfiguration
          state={state as SettlementProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'meter') {
      return (
        <MeterConfiguration
          state={state as MeterProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'customerType') {
      return (
        <CustomerTypeConfiguration
          state={state as CustomerTypeProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'productType') {
      return (
        <ProductTypeConfiguration
          state={state as ProductTypeProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'profile') {
      return (
        <ProfileConfiguration
          state={state as ProfileProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'topDown') {
      return (
        <TopDownConfiguration
          state={state as TopDownProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'settlementWeather') {
      return (
        <SettlementWeatherConfiguration
          state={state as SettlementWeatherProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'customerTypeWeather') {
      return (
        <CustomerTypeWeatherConfiguration
          state={state as CustomerTypeWeatherProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'profileWeather') {
      return (
        <ProfileWeatherConfiguration
          state={state as ProfileWeatherProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'topDownWeather') {
      return (
        <TopDownWeatherConfiguration
          state={state as TopDownWeatherProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
  }

  if (podType?.insightId === 'riskManagement') {
    if (podType.metricId === 'netOpenPosition') {
      return (
        <IWNetOpenPositionConfigurationStep
          state={state as NetOpenPositionProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'netOpenPositionLtf') {
      return (
        <IWNetOpenPositionConfigurationStep
          state={state as NetOpenPositionProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'supplyTrades') {
      return (
        <IWSupplyTradesConfigurationStep
          state={state as SupplyTradesProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'explodedTrades') {
      return (
        <IWExplodedTradesConfigurationStep
          state={state as ExplodedTradesProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
  }

  if (podType?.insightId === 'shortTermForecastPerformance') {
    return (
      <GeneralShortTermPerformanceConfiguration
        state={state as GeneralShortTermPerformanceProps['state']}
        onChange={onChange}
        showErrors={showErrors}
      />
    )
  }

  /**
   * For now we're simply using the short term forecast performance configuration step, since configurations
   * for both short term forecast performance and short term forecast performance top down are the same.
   * However, if we ever need a special configuration for one but not the other it is HIGHLY recommended to add
   * short term forecast performance top down as own insight in insights directory and follow exisisting patterns.
   */
  if (podType?.insightId === 'shortTermForecastPerformanceTopDown') {
    return (
      <GeneralShortTermPerformanceConfiguration
        state={state as GeneralShortTermPerformanceProps['state']}
        onChange={onChange}
        showErrors={showErrors}
      />
    )
  }

  if (podType?.insightId === 'shortTermForecastSystem') {
    const market = metricMarketMap[podType.metricId]
    return (
      <ShortTermForecastSystemConfiguration
        market={market}
        state={state as STFSystemProps['state']}
        onChange={onChange}
        showErrors={showErrors}
      />
    )
  }

  if (podType?.insightId === 'longTermForecast') {
    if (podType.metricId === 'meter') {
      return (
        <LongTermForecastMeterConfiguration
          state={state as MeterProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'customerType') {
      return (
        <LTFCustomerTypeConfiguration
          state={state as CustomerTypeProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'blockType') {
      return (
        <LTFBlockTypeConfiguration
          state={state as BlockTypeProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'productType') {
      return (
        <LTFProductTypeConfiguration
          state={state as CustomerTypeProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'profile') {
      return (
        <LTFProfileConfiguration
          state={state as ProfileProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'settlement') {
      return (
        <LTFSettlementConfiguration
          state={state as LTFSettlementProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
  }

  if (podType?.insightId === 'actualUsage') {
    if (podType.metricId === 'settlement') {
      return (
        <ActualUsageSettlementConfiguration
          state={state as ActualUsageSettlementProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'meterHourly') {
      return (
        <ActualUsageMeterConfiguration
          state={state as ActualUsageMeterProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
    if (podType.metricId === 'meterDaily') {
      return (
        <ActualUsageMeterConfiguration
          state={state as ActualUsageMeterProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
  }

  if (podType?.insightId === 'wholesaleSettlement') {
    if (podType.metricId === 'summaryERCOT') {
      const market = wholesaleSettlementMetricMarketMap[podType.metricId]
      return (
        <WholesaleSettlementSummaryConfiguration
          market={market}
          state={state as WholesaleSettlementSummaryProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }

    if (podType.metricId === 'allocationERCOT') {
      const market =
        wholesaleSettlementAllocationMetricMarketMap[podType.metricId]
      return (
        <WholesaleSettlementAllocationConfiguration
          market={market}
          state={state as WholesaleSettlementAllocationProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
  }

  if (podType?.insightId === 'shortTermForecastPerformanceSystem') {
    const market = perfSystemMetricMarketMap[podType.metricId]
    return (
      <PerformanceSystemConfiguration
        market={market}
        state={state as PerformanceSystemProps['state']}
        onChange={onChange}
        showErrors={showErrors}
      />
    )
  }

  if (podType?.insightId === 'attributeInfo') {
    if (podType.metricId === 'meter') {
      return (
        <AttributeInfoConfiguration
          state={state as AttributeInfoProps['state']}
          onChange={onChange}
          showErrors={showErrors}
        />
      )
    }
  }
  return <h1>{t('insightManager.createPod.doesNotExist')}</h1>
}

export default ConfigurationWrapper
