import React, { useEffect, useState } from 'react'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'

export interface LimitFilter {
  isMeasureFilterDisabled: boolean
  selectedMeasureOption?: DropdownValueProps
  selectedEdgeOption?: DropdownValueProps
  selectedTypeOption?: DropdownValueProps
  limitValue?: number
  hasValueError: boolean
  limitValueError?: string
}

/**
 * @description A Custom Hook to pair with the `LimitFilter`.
 * The hook can manage the `LimitFilter` states.
 * @param limitFilters Different Measure Options to create limits on
 * @param selectedLimit
 * @returns object of LimitFilterHook
 */
const useLimitFilters = (
  limitFilters: DropdownValueProps[],
  selectedLimit?: LimitFilter,
) => {
  const [filters, setFilters] = useState<DropdownValueProps[]>(limitFilters)
  const [limit, setLimit] = useState<LimitFilter | undefined>(
    selectedLimit || undefined,
  )
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const item = {
    isMeasureFilterDisabled: filters.length === 1,
    hasValueError: true,
  }

  function validateErrors() {
    const containErrors =
      !limit?.selectedMeasureOption ||
      !limit?.selectedEdgeOption ||
      !limit?.selectedTypeOption ||
      limit?.limitValue === undefined ||
      limit?.limitValue === null ||
      limit?.hasValueError
    setHasErrors(containErrors)
  }

  const onMeasureOptionChange = (value: DropdownValueProps) => {
    if (limit) {
      setLimit({ ...limit, selectedMeasureOption: value })
    }
  }

  const onEdgeOptionChange = (value: DropdownValueProps) => {
    if (limit) {
      setLimit({ ...limit, selectedEdgeOption: value })
    }
  }

  const onTypeOptionChange = (value: DropdownValueProps) => {
    if (limit) {
      setLimit({ ...limit, selectedTypeOption: value, limitValue: undefined })
    }
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (limit) {
      const val = parseInt(e.target.value, 10)
      const containErrors = Number.isNaN(val)
      setLimit({
        ...limit,
        limitValue: !containErrors ? val : undefined,
        limitValueError: containErrors ? 'Value is Required' : undefined,
        hasValueError: containErrors,
      })
    }
  }

  const addLimit = () => {
    setLimit({ ...item })
  }

  const deleteLimit = () => {
    setLimit(undefined)
  }

  useEffect(() => {
    validateErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  useEffect(() => {
    if (limitFilters) {
      setFilters(limitFilters)
    }
  }, [limitFilters])

  return {
    limit,
    limitFilters: filters,
    hasErrors,
    addLimit,
    deleteLimit,
    onMeasureOptionChange,
    onEdgeOptionChange,
    onTypeOptionChange,
    onValueChange,
  }
}

export default useLimitFilters
