import React, { useContext, useEffect } from 'react'
import { PodType } from 'tools/insightsManager/types'
import useInsights from 'tools/insightsManager/hooks/useInsights'
import { useTranslation } from 'react-i18next'
import getInsights from 'tools/insightsManager/utils/getInsights'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWProductList from 'shared/components/thunderbolt/IWProductList'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import { IWError } from 'shared/components/thunderbolt/IWCommonLabels'
import UserContext from 'shared/contexts/UserContext'
import { WizardStepProps } from '../../../shared/types'

export type Props = WizardStepProps<PodType['insightId']>

const SelectPodInsightStep = ({ state, onChange, showErrors }: Props) => {
  const { t } = useTranslation()
  const { availablePodTypesMap } = useContext(UserContext)
  const insights = getInsights(t, Object.keys(availablePodTypesMap))
  const { selectedInsight, onInsightChange, hasError } = useInsights({
    selectedInsight: state,
  })

  useEffect(() => {
    if (selectedInsight) {
      onChange(selectedInsight, !hasError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsight, hasError])

  return (
    <IWFormSection
      sectionTitle={t(`insightManager.createPod.insightSelector.sectionHeader`)}
    >
      <IWProductList>
        {insights.map(({ label, type, icon, description }) => (
          <IWProductCard
            key={type}
            isSelected={selectedInsight === type}
            header={label}
            icon={icon}
            iconColor="secondary"
            description={description}
            onClick={() => onInsightChange(type)}
          />
        ))}
      </IWProductList>
      {showErrors && hasError && (
        <IWError
          label={t(`insightManager.createPod.errors.enterAllRequired`)}
        />
      )}
    </IWFormSection>
  )
}

export default SelectPodInsightStep
