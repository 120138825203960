import axiosApiClient from './apiClient'

/**
 * Gets the top X = 5 values for the selected attribute type
 */
export const getInitialValues = async ({
  attributeType,
  limit = 5,
}: {
  attributeType: string
  limit?: number
}): Promise<string[]> => {
  const { data } = await axiosApiClient.get(
    `/search/v1/meter-attributes/values?attributeType=${attributeType}&limit=${limit}`,
  )
  return data
}

/**
 * Performs a full text search on the unique attribute values of a specific type in elastic search
 */
export const searchMeterAttributeValues = async ({
  attributeType,
  inputValue,
}: {
  attributeType: string
  inputValue: string
}): Promise<string[]> => {
  const { data } = await axiosApiClient.get(
    `/search/v1/meter-attributes/suggest?attributeType=${attributeType}&query=${inputValue}`,
  )
  return data
}

/**
 * Queries the ES index to get a list of available attribute types
 */
export const getAvailableAttributeTypes = async (
  market?: string,
): Promise<string[]> => {
  const uri = market
    ? `/search/v1/meter-attributes?market=${market}`
    : `/search/v1/meter-attributes`
  const { data } = await axiosApiClient.get(uri)
  return data
}

/**
 * Queries the ES index to get a list of available markets
 */
export const getAvailableMarkets = async (): Promise<string[]> => {
  const { data } = await axiosApiClient.get(
    '/search/v1/meter-attributes/markets',
  )
  return data
}

export default {
  getInitialValues,
  searchMeterAttributeValues,
  getAvailableAttributeTypes,
  getAvailableMarkets,
}
