import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import {
  createNewInboundCredential,
  deleteInboundCredential,
  editInboundCredential,
  getInboundCredentials,
  InboundCredentials,
} from 'systemPreferences/integrationsServiceClient'
import IntegrationsSection from '../../pages/IntegrationsSection'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { Column, SortDirection } from 'shared/components/thunderbolt/IWTable'
import { paginationOptions } from 'shared/types'
import DataAccessCreateModal from './DataAccessCreateModal'
import DataAccessTable from './DataAccessTable'
import DataAccessEditModal from './DataAccessEditModal'
import DataAccessDeleteModal from './DataAccessDeleteModal'
import styled from 'styled-components'

const TableWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.375rem;
  padding: 1rem;
`

const DataAccessSection = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newDataAccessName, setNewDataAccessName] = useState('')
  const [newDataAccessDescription, setNewDataAccessDescription] = useState('')
  const [hasErrorDataAccessName, setHasErrorDataAccessName] = useState(false)
  const [submitStatus, setSubmitStatus] = useState<
    'waiting' | 'submitting' | 'complete'
  >('waiting')
  const [newClientId, setNewClientId] = useState<undefined | string>()
  const [newClientSecret, setNewClientSecret] = useState<undefined | string>()
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirection
  }>({
    column: 'created_at',
    direction: 'descending',
  })
  const [page, setPage] = useState({
    current: 1,
    size: paginationOptions[0].value,
  })
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedInboundCredential, setSelectedInboundCredential] = useState<
    InboundCredentials | undefined
  >()

  const handleModalCloseWithCleanUp = () => {
    setNewDataAccessName('')
    setNewDataAccessDescription('')
    setNewClientId(undefined)
    setNewClientSecret(undefined)
    setIsModalOpen(false)
    setSubmitStatus('waiting')
    setHasErrorDataAccessName(false)
  }

  const handleAddDataAccessClick = () => {
    setIsModalOpen(true)
  }

  const handleNewDataAccessNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (hasErrorDataAccessName && newValue.length > 0) {
      setHasErrorDataAccessName(false)
    }
    setNewDataAccessName(e.target.value)
  }

  const handleNewDataAccessDescriptionChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setNewDataAccessDescription(e.target.value)
  }

  const handleDataAccessEdit = (
    e: SyntheticEvent,
    inboundCredential: InboundCredentials,
  ) => {
    setIsEditModalOpen(true)
    setSelectedInboundCredential(JSON.parse(JSON.stringify(inboundCredential)))
  }

  const handleSelectedCredentialNameChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = e.target.value
    if (hasErrorDataAccessName && newValue.length > 0) {
      setHasErrorDataAccessName(false)
    }
    setSelectedInboundCredential((prev) => {
      if (prev) {
        return {
          ...prev,
          name: newValue,
        }
      }
      return undefined
    })
  }
  const handleSelectedCredentialDescriptionChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedInboundCredential((prev) => {
      if (prev) {
        return {
          ...prev,
          description: e.target.value,
        }
      }
      return undefined
    })
  }

  const handleCancelEdit = () => {
    setIsEditModalOpen(false)
    setSelectedInboundCredential(undefined)
    setHasErrorDataAccessName(false)
  }

  const handelCancelDelete = () => {
    setIsDeleteModalOpen(false)
    setSelectedInboundCredential(undefined)
  }

  const handleDataAccessDeleteTableButton = (
    e: SyntheticEvent,
    inboundCredential: InboundCredentials,
  ) => {
    setIsDeleteModalOpen(true)
    setSelectedInboundCredential(JSON.parse(JSON.stringify(inboundCredential)))
  }

  const columns: Column[] = [
    {
      title: t('systemPreferences.integrations.dataAccess.table.actions'),
      accessor: 'actions',
      targetPercentageWidth: 10,
    },
    {
      title: t(
        'systemPreferences.integrations.dataAccess.table.credentialName',
      ),
      accessor: 'name',
      dbColumn: 'name',
      sortable: true,
    },
    {
      title: t('systemPreferences.integrations.dataAccess.table.expiresAt'),
      accessor: 'expiresAt',
      dbColumn: 'expires_at',
      sortable: true,
    },
    {
      title: t('systemPreferences.integrations.dataAccess.table.createdAt'),
      accessor: 'createdAt',
      dbColumn: 'created_at',
      sortable: true,
    },
    {
      title: t('systemPreferences.integrations.dataAccess.table.status'),
      accessor: 'status',
    },
  ]

  const {
    data: dataAccessCredentials,
    refetch,
    isLoading: isLoadingDataAccessCredentials,
  } = useQuery(
    [
      'dataAccessCredentials',
      'pageNumber',
      page,
      'pageSize',
      page.size,
      'sort',
      sort,
    ],
    () => {
      return getInboundCredentials(page.current - 1, page.size, {
        direction: sort.direction,
        column:
          columns.find((c) => c.accessor === sort.column)?.dbColumn ||
          'created_at',
      })
    },
    {
      keepPreviousData: true,
    },
  )

  const handleCreateNewDataAccess = async () => {
    if (newDataAccessName.length === 0) {
      setHasErrorDataAccessName(true)
    } else {
      setSubmitStatus('submitting')
      try {
        const r = await createNewInboundCredential({
          name: newDataAccessName,
          description: newDataAccessDescription,
        })
        setSubmitStatus('complete')
        setNewClientId(r.clientId)
        setNewClientSecret(r.clientSecret)
        refetch()
      } catch (error) {
        setSubmitStatus('waiting')
        toast.alert(
          t('systemPreferences.integrations.dataAccess.newCredentialsFailure'),
          { canDismiss: true },
        )
      }
    }
  }

  const handleEditDataAccessCredential = async (
    e: SyntheticEvent,
    credentialId: string,
    name: string,
    description: string,
  ) => {
    if (selectedInboundCredential === undefined) {
      return
    }
    if (selectedInboundCredential?.name.length === 0) {
      setHasErrorDataAccessName(true)
      return
    }
    try {
      setSubmitStatus('submitting')
      await editInboundCredential({
        credentialId,
        name,
        description,
      })
      refetch()
      toast.secondary(
        t('systemPreferences.integrations.dataAccess.credentialHasBeenSaved'),
        { canDismiss: true },
      )
      setIsEditModalOpen(false)
      setSelectedInboundCredential(undefined)
    } catch (error) {
      toast.alert(
        t('systemPreferences.integrations.dataAccess.editCredentialsFailure'),
        { canDismiss: true },
      )
    } finally {
      setSubmitStatus('waiting')
    }
  }

  const handleDataAccessCredentialDelete = async (
    e: SyntheticEvent,
    credentialId: string,
  ) => {
    if (selectedInboundCredential === undefined) {
      return
    }
    try {
      await deleteInboundCredential({ credentialId })
      toast.secondary(
        t(
          'systemPreferences.integrations.dataAccess.credentialHasBeenDeleted',
          { name: selectedInboundCredential.name },
        ),
        { canDismiss: true },
      )
      refetch()
      setIsDeleteModalOpen(false)
      setSelectedInboundCredential(undefined)
    } catch (error) {
      toast.alert(
        t('systemPreferences.integrations.dataAccess.deleteCredentialsFailure'),
        { canDismiss: true },
      )
    } finally {
      setSubmitStatus('waiting')
    }
  }

  const handleSort = (column: string, direction: SortDirection) => {
    setSort({
      column: column,
      direction: direction || 'descending',
    })
  }

  return (
    <>
      <IntegrationsSection
        header={t('systemPreferences.integrations.dataAccess.header')}
        buttonLabel={t(
          'systemPreferences.integrations.dataAccess.addNewCredentialButton',
        )}
        onButtonClick={handleAddDataAccessClick}
      >
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t('systemPreferences.integrations.dataAccess.description')}
        </IWTypography>
        <TableWrapper>
          <DataAccessTable
            onSort={handleSort}
            sort={sort ? { [sort.column]: sort.direction } : undefined}
            columns={columns}
            data={dataAccessCredentials}
            page={page}
            onChangePage={(newPage) =>
              setPage((prev) => ({ ...prev, current: newPage }))
            }
            onChangeItemsPerPage={(newSize) =>
              setPage({ current: 1, size: newSize })
            }
            isLoading={isLoadingDataAccessCredentials}
            onEdit={handleDataAccessEdit}
            onDelete={handleDataAccessDeleteTableButton}
          />
        </TableWrapper>
      </IntegrationsSection>
      <DataAccessCreateModal
        submitStatus={submitStatus}
        newDataAccessName={newDataAccessName}
        hasErrorNewDataAccessName={hasErrorDataAccessName}
        onNewDataAccessNameChange={handleNewDataAccessNameChange}
        newDataAccessDescription={newDataAccessDescription}
        onNewDataAccesDescriptionChange={handleNewDataAccessDescriptionChange}
        onModalCancel={handleModalCloseWithCleanUp}
        onModalCreate={handleCreateNewDataAccess}
        newClientId={newClientId}
        newClientSecret={newClientSecret}
        onModalConfirmCopy={handleModalCloseWithCleanUp}
        isModalOpen={isModalOpen}
      />
      {selectedInboundCredential && (
        <>
          <DataAccessEditModal
            isModalOpen={isEditModalOpen}
            clientId={selectedInboundCredential.clientId}
            credentialId={selectedInboundCredential.credentialId}
            name={selectedInboundCredential.name}
            onNameChange={handleSelectedCredentialNameChange}
            description={selectedInboundCredential.description}
            onDescriptionChange={handleSelectedCredentialDescriptionChange}
            hasErrorName={hasErrorDataAccessName}
            onCancel={handleCancelEdit}
            isCancelButtonDisabled={submitStatus === 'submitting'}
            isSaveButtonDisabled={
              submitStatus === 'submitting' || hasErrorDataAccessName
            }
            onSave={handleEditDataAccessCredential}
          />
          <DataAccessDeleteModal
            isModalOpen={isDeleteModalOpen}
            credentialId={selectedInboundCredential.credentialId}
            name={selectedInboundCredential.name}
            isCancelButtonDisabled={submitStatus === 'submitting'}
            isDeleteButtonDisabled={submitStatus === 'submitting'}
            onCancel={handelCancelDelete}
            onDelete={handleDataAccessCredentialDelete}
          />
        </>
      )}
    </>
  )
}

export default DataAccessSection
