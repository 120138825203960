import React from 'react'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import styled from 'styled-components'
import { ReactComponent as NotAuthorisedImage } from 'images/401.svg'
import { useTranslation } from 'react-i18next'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { useNavigate } from 'react-router'

const CenterAllWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledHeader = styled(IWTypography)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const StyledDesc = styled(IWTypography)`
  width: 400px;
  text-align: center;
`

const StyledDiv = styled('div')`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`

const NotAuthorised = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <CenterAllWrapper>
      <NotAuthorisedImage />
      <StyledHeader size="lg" weight="bold">
        {t('errors.pageError.unauthorisedAccessText')}
      </StyledHeader>
      <StyledDesc size="sm" weight="regular">
        {t('errors.pageError.unauthorisedAccessDesc')}
      </StyledDesc>
      <StyledDiv>
        <IWButton
          data-testid="error-page-back-button"
          variant="outline"
          hasSolidBackground={false}
          onClick={() => navigate(-2)}
        >
          {t('button.back')}
        </IWButton>
        <IWButton
          data-testid="error-page-home-button"
          onClick={() => navigate('/')}
        >
          {t('button.home')}
        </IWButton>
      </StyledDiv>
    </CenterAllWrapper>
  )
}

export default NotAuthorised
