import React from 'react'
import { Outlet } from 'react-router'
import { routes as InsightsManagerRoutes } from './insightsManager'
import { routes as AlertsManagerRoutes } from './alertsManager'
import { routes as LoadSchedulingRoutes } from './loadScheduling'
import { routes as DashboardsRoutes } from './dashboardsManager'
import { routes as ImportManagerRoutes } from './importManager'

export const routes = [
  {
    key: 'tools',
    path: '/tools',
    main: <Outlet />,
    childRoutes: [
      ...InsightsManagerRoutes,
      ...DashboardsRoutes,
      ...AlertsManagerRoutes,
      ...LoadSchedulingRoutes,
      ...ImportManagerRoutes,
    ],
  },
]
