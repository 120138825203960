import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWAccordion from 'shared/components/thunderbolt/IWAccordion'
import IWTable from 'shared/components/thunderbolt/IWTable'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import {
  getTargetNetOpenPositions,
  updateTargetNetOpenPositions,
} from 'shared/loadSchedulingClient'

const TargetNetOpenPosition = () => {
  const { t } = useTranslation()
  const [dataTable, setDataTable] = useState<any[]>([])
  const [targetNetOpenPositions, setTargetNetOpenPositions] = useState<any[]>(
    [],
  )

  const schema = [
    {
      title: 'Brand',
      accessor: 'brand',
    },
    {
      title: 'location',
      accessor: 'location',
    },
    {
      title: 'bid',
      accessor: 'bid',
    },
    {
      title: 'offer',
      accessor: 'offer',
    },
  ]

  const { data: targetNopRules } = useQuery('nop-rules', () => {
    return getTargetNetOpenPositions()
  })

  const onChange = async ({ id, bid, offer, location }) => {
    const response = await updateTargetNetOpenPositions(id, {
      location,
      bid: bid.toFixed(),
      offer: offer.toFixed(),
    })

    const indexToUpdate = targetNetOpenPositions.findIndex(
      (nop) => nop.nopId === response.nopId,
    )

    if (indexToUpdate >= 0) {
      targetNetOpenPositions[indexToUpdate] = {
        ...targetNetOpenPositions[indexToUpdate],
        bid: response.bid,
        offer: response.offer,
      }

      setTargetNetOpenPositions([...targetNetOpenPositions])
    }
  }

  useEffect(() => {
    if (!targetNopRules || targetNopRules.length === 0) {
      return
    }

    targetNopRules.sort((a, b) => a.location.localeCompare(b.location))

    setTargetNetOpenPositions(targetNopRules)
  }, [targetNopRules])

  useEffect(() => {
    setDataTable(
      targetNetOpenPositions.map((target) => ({
        ...target,
        bid: (
          <IWTextInput
            id={`bid-${target.nopId}`}
            key={`bid-${target.nopId}`}
            name={`bid-${target.brand}-${target.location}`}
            type="number"
            step="1"
            min="0"
            value={target.bid}
            placeholder={t('placeholders.enterValue')}
            onChange={(e) =>
              onChange({
                id: target.nopId,
                location: target.location,
                offer: target.offer,
                bid: Number(e.target.value),
              })
            }
          />
        ),
        offer: (
          <IWTextInput
            id={`offer-${target.nopId}`}
            key={`offer-${target.brand}-${target.location}`}
            name={`offer-${target.brand}-${target.location}`}
            type="number"
            step="1"
            min="0"
            value={target.offer}
            placeholder={t('placeholders.enterValue')}
            onChange={(e) =>
              onChange({
                id: target.nopId,
                location: target.location,
                bid: target.bid,
                offer: Number(e.target.value),
              })
            }
          />
        ),
      })),
    )
  }, [targetNetOpenPositions])

  return (
    <IWAccordion
      title={t('loadScheduling.settings.targetNetOpenPositionTitle')}
      desc={t('loadScheduling.settings.targetNetOpenPositionDesc')}
      shouldStartClose
    >
      <IWTable border columns={schema} data={dataTable} />
    </IWAccordion>
  )
}

export default TargetNetOpenPosition
