import React, { useContext } from 'react'
import { Outlet } from 'react-router'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { useTranslation } from 'react-i18next'
import { FullPageSidebar, LayoutWrapper } from 'root/components/IWLayout'
import LayoutContext from 'shared/contexts/LayoutContext'
import SystemPreferencesSidebar from './components/SystemPreferencesSidebar'
import UserProfilePage from './pages/UserProfilePage'
import GeneralSettingPage from './pages/GeneralSettingPage'
import IWSpacer from '../shared/components/thunderbolt/IWSpacer'
import IntegrationsPage from './pages/IntegrationsPage'
import LoadSchedulingSettingPage from './pages/LoadSchedulingSettingPage'

const SystemPreferences = () => {
  const { t } = useTranslation()
  const { isSmallScreen } = useContext(LayoutContext)
  return (
    <LayoutWrapper>
      <>
        <IWPageHeader isFullScreen data-testid="page-header">
          <StyledTitle>{t('systemPreferences.systemPreferences')}</StyledTitle>
          <IWSpacer />
          <IWButton
            as="routerNavLink"
            to="/tools/insights-manager"
            variant="main"
            color="primary"
            data-testid="page-header-close-button"
          >
            {t('button.close')}
          </IWButton>
        </IWPageHeader>
        <FullPageSidebar isSmallScreen={isSmallScreen}>
          <SystemPreferencesSidebar />
          <main>
            <Outlet />
          </main>
        </FullPageSidebar>
      </>
    </LayoutWrapper>
  )
}

export const routes = [
  {
    key: 'system-preferences',
    path: '/system-preferences',
    main: <SystemPreferences />,
    childRoutes: [
      {
        key: 'system-preferences-user',
        path: '/user',
        main: <UserProfilePage />,
        entryPage: true,
      },
      {
        key: 'system-preferences-general',
        path: '/general',
        main: <GeneralSettingPage />,
      },
      {
        key: 'system-preferences-integrations',
        path: '/integrations',
        main: <IntegrationsPage />,
      },
      {
        key: 'system-preferences-loas-scheduling',
        path: '/load-scheduling',
        main: <LoadSchedulingSettingPage />,
      },
    ],
  },
]

export default SystemPreferences
