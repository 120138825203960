import React, { useContext } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faCaretRight,
  faShareNodes,
  faSignOutAlt,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import IWTypography from './IWTypography'
import IWContextMenuItem from './IWContextMenuItem'
import IWContextMenu from './IWContextMenu'
import IWDivider from './IWDivider'
import IWUserListItem from './IWUserListItem'
import UserContext from 'shared/contexts/UserContext'
import { isPermissionAvailable } from 'shared/helpers'

interface ExtraProps {
  header: string
  subHeader?: string
  imageUri?: string
  truncateAtWidth?: number
  imageSize?: number
  onLogout?: () => void
  isAdmin?: boolean
}

type Props = ExtraProps & React.ComponentPropsWithoutRef<'button'>

const StyledComponentWrapper = styled.button`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: ${(props) => props.theme.palette.grey[0]};
  width: 100%;
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
  border-radius: 0.75rem;
  padding: 0.75rem;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[200]};
  }
`

const StyledIconWrapper = styled.div`
  margin-left: auto;
`

const IWSectionDivider = styled(IWDivider)`
  margin: 0;
`

const IWUserTile = ({
  header,
  subHeader,
  imageUri,
  onLogout,
  isAdmin,
  ...props
}: Props) => {
  const { t } = useTranslation()
  const { availableToolPolicies } = useContext(UserContext)

  const canViewLoadScheduling = isPermissionAvailable(
    availableToolPolicies,
    'loadScheduling:editor',
  )

  return (
    <IWContextMenu
      positions={['right', 'bottom']}
      menu={[
        <IWContextMenuItem
          as="routerNavLink"
          key="user-profile"
          data-testid="user-tile-profile"
          label={t('systemPreferences.title.userProfile')}
          icon={faUser}
          to="/system-preferences/user"
        />,
        <IWContextMenuItem
          as="routerNavLink"
          key="integrations-settings"
          data-testid="user-tile-integrations-settings"
          label={t('systemPreferences.title.integrations')}
          icon={faShareNodes}
          to="/system-preferences/integrations"
        />,
        canViewLoadScheduling ? (
          <IWContextMenuItem
            as="routerNavLink"
            key="load-scheduling-settings"
            data-testid="user-tile-load-scehduling-settings"
            label={t('systemPreferences.title.loadScheduling')}
            icon={faCalendarAlt}
            to="/system-preferences/load-scheduling"
          />
        ) : (
          <></>
        ),
        <IWSectionDivider key="divider-2" />,
        <IWContextMenuItem
          key="logout"
          data-testid="user-tile-logout"
          label={t('userTile.labels.signout')}
          icon={faSignOutAlt}
          onClick={onLogout}
        />,
      ]}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StyledComponentWrapper {...props} data-testid="user-tile">
        <IWUserListItem
          imageUri={imageUri}
          name={header}
          email={subHeader}
          isAdmin={isAdmin}
        />

        <StyledIconWrapper>
          <IWTypography size="lg" fontHue={{ color: 'grey', value: 400 }}>
            <FontAwesomeIcon icon={faCaretRight} />
          </IWTypography>
        </StyledIconWrapper>
      </StyledComponentWrapper>
    </IWContextMenu>
  )
}

export default IWUserTile
