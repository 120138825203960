import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Props as ProductItemProps } from './IWProductItem'

type ProductItem = ReactElement<ProductItemProps>

interface ProductCardLists {
  children: ProductItem | Array<ProductItem>
}

const StyledUnorderedList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 0.25rem;
  }
`

/**
 * This is a wrapper component for Product Card component. It takes in Product Cards as children and displays it in a unordered list form.
 */
const ProductList = ({ children }: ProductCardLists) => (
  <StyledUnorderedList>{children}</StyledUnorderedList>
)

export default ProductList
