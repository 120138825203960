import React, { ReactNode, useState } from 'react'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { animation, rem, transitions } from 'polished'

interface AccordionProps {
  /**
   * title given to a single accordian bar
   */
  title: string
  /**
   * description given to a single accordian bar
   */
  desc?: string
  /**
   * if the accordian should start closed
   */
  shouldStartClose?: boolean
  /**
   * children that can be passed as accordian body
   */
  children: ReactNode
}

const StyledAccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem('16px')};
`

const StyledAccordionButton = styled.button`
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
`

const StyledAccordionTitle = styled(IWTypography)<{ hasDescription?: boolean }>`
  text-align: left;
  margin-bottom: ${(props) => (props.hasDescription ? '-0.75rem' : undefined)};
`
const StyledAccordionDesc = styled(IWTypography)`
  text-align: left;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{
  active: boolean
}>`
  color: ${(props) => props.theme.palette.grey[400]};
  font-size: 0.8rem;
  margin-left: auto;
  transform: ${(props) => (props.active ? 'rotate(90deg)' : 'rotate(0deg)')};
  ${animation(['rotate', '0.2s', 'ease'])}
`

const StyledAccordionContent = styled.div<{
  active: boolean
}>`
  display: flex;
  max-height: ${(props) => (props.active ? rem('1000px') : '0rem')};
  overflow: auto;
  ${transitions('max-height 0.2s ease-in-out')};
  margin: 0rem;
`

/**
 * IWAccordian is a general iw component to be used as a accordian
 * you can pass in any children to be rendered inside accordian body
 * and give a title to accordian as well
 */
const IWAccordion = ({
  title,
  desc,
  shouldStartClose = false,
  children,
}: AccordionProps) => {
  const [active, setActive] = useState<boolean>(!shouldStartClose)

  const toggleAccordion = () => {
    setActive(!active)
  }

  return (
    <StyledAccordionSection>
      <StyledAccordionButton onClick={toggleAccordion}>
        <StyledAccordionTitle size="lg" weight="medium" hasDescription={!!desc}>
          {title}
        </StyledAccordionTitle>
        <StyledFontAwesomeIcon active={active} icon={faChevronRight} />
      </StyledAccordionButton>
      {Boolean(desc) && (
        <StyledAccordionDesc
          size="sm"
          fontHue={{
            color: 'grey',
            value: 400,
          }}
        >
          {desc}
        </StyledAccordionDesc>
      )}
      <StyledAccordionContent active={active}>
        {children}
      </StyledAccordionContent>
    </StyledAccordionSection>
  )
}

export default IWAccordion
