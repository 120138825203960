import React, { useEffect } from 'react'
import styled from 'styled-components'

const { PUBLIC_URL } = process.env

const loadingUrl = `${PUBLIC_URL}/images/loading.gif`

const CenterAllWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const StyledLoadingIconWrapper = styled.div`
  margin: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.125rem;
  height: 8.125rem;
  border-radius: 100%;
  border: 3px solid #f3f4f6;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  & > img {
    border-radius: 8.125rem;
  }
`

export const LoadingContent = () => (
  <CenterAllWrapper>
    <StyledLoadingIconWrapper>
      <img src={loadingUrl} alt="Loading" />
    </StyledLoadingIconWrapper>
  </CenterAllWrapper>
)

interface Props {
  onLogin: () => void
}

const Login = ({ onLogin }: Props) => {
  useEffect(() => {
    onLogin()
  })

  return <LoadingContent />
}

export default Login
