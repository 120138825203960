import React, { ReactNode, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SectionHeader = styled(IWTypography)`
  display: inline-block;
`

const HeaderWithButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
interface Props {
  header: string
  buttonLabel: string
  onButtonClick: (e: SyntheticEvent) => void
  children: ReactNode
}

const IntegrationsSection = ({
  header,
  buttonLabel,
  onButtonClick,
  children,
}: Props) => {
  return (
    <Section>
      <HeaderWithButton>
        <SectionHeader
          as="h1"
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {header}
        </SectionHeader>
        <IWButton
          icon={faCirclePlus}
          iconPosition="leading"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </IWButton>
      </HeaderWithButton>
      {children}
    </Section>
  )
}

export default IntegrationsSection
