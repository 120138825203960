import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import React, { SyntheticEvent, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTable, { Column } from 'shared/components/thunderbolt/IWTable'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import LayoutContext from 'shared/contexts/LayoutContext'
import styled from 'styled-components'
import { ThirdPartyIntegration } from 'systemPreferences/integrationsServiceClient'

const ActionButtonsWrap = styled.div`
  display: flex;
  gap: 0.25rem;
`

interface Props {
  data: ThirdPartyIntegration[]
  onDelete: (e: SyntheticEvent, integration: ThirdPartyIntegration) => void
}

const ThirdPartyIntegrationsTable = ({ data, onDelete }: Props) => {
  const { t } = useTranslation()
  const { timezone } = useContext(LayoutContext)

  const buildTableNameData = (integrationName: string, email: string) => {
    return (
      <div>
        <IWTypography
          size="sm"
          weight="medium"
          fontHue={{ color: 'grey', value: 800 }}
        >
          {integrationName}
        </IWTypography>
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 400 }}
        >
          {email}
        </IWTypography>
      </div>
    )
  }

  const buildTableActionButtons = (integration: ThirdPartyIntegration) => {
    return (
      <ActionButtonsWrap>
        <IWButton
          title={t('actions.explore')}
          color="primary"
          onClick={(e) => onDelete(e, integration)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IWButton>
      </ActionButtonsWrap>
    )
  }

  const columns: Column[] = [
    {
      title: t(
        'systemPreferences.integrations.thirdPartyIntegrations.table.actions',
      ),
      accessor: 'actions',
      targetPercentageWidth: 10,
    },
    {
      title: t(
        'systemPreferences.integrations.thirdPartyIntegrations.table.integrationName',
      ),
      accessor: 'integration',
      sortable: true,
    },
    {
      title: t(
        'systemPreferences.integrations.thirdPartyIntegrations.table.createdAt',
      ),
      accessor: 'createdAt',
      sortable: true,
    },
  ]

  const formatData = (d: ThirdPartyIntegration[]) => {
    return (
      d.map((x) => ({
        integration: buildTableNameData(
          t(
            `systemPreferences.integrations.thirdPartyIntegrations.availableIntegrations.${x.thirdPartyDriveId}`,
          ),
          x.email,
        ),
        createdAt: DateTime.fromISO(x.createdAt, {
          zone: timezone,
        }).toLocaleString(DateTime.DATETIME_SHORT),
        actions: buildTableActionButtons(x),
      })) || []
    )
  }
  if (data.length === 0) {
    return (
      <IWAlert
        color="grey"
        variant="alternative"
        label={t(
          'systemPreferences.integrations.thirdPartyIntegrations.noIntegrations',
        )}
      />
    )
  }
  return <IWTable border columns={columns} data={formatData(data)} />
}

export default ThirdPartyIntegrationsTable
