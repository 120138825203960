import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PodIntegrationsSection from './PodIntegrationsSection'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import {
  editSFTPSubscription,
  editThirdPartyDriveSubscription,
  getSFTPCredentials,
  getSFTPSubscriptionsForPod,
  getThirdPartyDriveSubscriptionsForPod,
  getThirdPartyIntegrations,
} from '../../../systemPreferences/integrationsServiceClient'
import usePodIntegrations from '../hooks/usePodIntegrations'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWRadioButtonGroup from 'shared/components/thunderbolt/IWRadioButtonGroup'
import IWRadioButton from 'shared/components/thunderbolt/IWRadioButton'

const StyledTabSection = styled.div`
  padding: 1rem;
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`
const StyledButtonDiv = styled.div`
  display: flex;
  justify-content: right;
  gap: 0.5rem;
`

const StyledDiv = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export type Props = {
  userPodId: string
  userPodName: string
}

// TODO: The logic here seems quite convoluted... would like to revist and improve... but for now it should be fine

const PodDetailsIntegrationsSection = ({ userPodId, userPodName }: Props) => {
  const { t } = useTranslation()
  const [hasIntegrations, setHasIntegrations] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const {
    localCredentials: localSftpCredentials,
    handleLocalCredentials: handleLocalSftpCredentials,
    toggleCredentials: toggleSftpCredentials,
    hasIntegrations: hasLocalSftpIntegrations,
  } = usePodIntegrations({
    getName: (r) => r.name,
    getDescription: (r) => `${r.host}:${r.port}/${r.path ?? ''}`,
  })
  const {
    localCredentials: localThirdPartyCredentials,
    handleLocalCredentials: handleLocalThirdPartyCredentials,
    toggleCredentials: toggleLocalThirdPartyCredentials,
    hasIntegrations: hasLocalThirdPartyIntegrations,
  } = usePodIntegrations({
    getName: (r) =>
      t(
        `systemPreferences.integrations.thirdPartyIntegrations.availableIntegrations.${r.thirdPartyDriveId}`,
      ),
    getDescription: (r) => r.email,
  })

  const {
    data: podSFTPSubscriptions,
    error,
    isLoading: isLoadingSFTPSubscriptions,
    refetch: refetchSFTPSubscriptions,
  } = useQuery(`podSFTPSubscriptions:${userPodId}`, () =>
    getSFTPSubscriptionsForPod({ userPodId }),
  )

  const {
    data: podThirdPartyDriveSubscriptions,
    refetch: refetchThirdPartySubscriptions,
  } = useQuery(`podThirdPartyDriveSubscriptions:${userPodId}`, () =>
    getThirdPartyDriveSubscriptionsForPod({ userPodId }),
  )

  const { data: availableSftpCredentials } = useQuery(
    ['sftpCredentials'],
    () => {
      return getSFTPCredentials(0, 100, {
        direction: 'descending',
        column: 'created_at',
      }).then((r) => r.rows)
    },
    {
      keepPreviousData: true,
    },
  )

  const { data: availableThirdPartyCredentials } = useQuery(
    ['thirdPartyCredentials'],
    () => {
      return getThirdPartyIntegrations()
    },
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    handleLocalSftpCredentials(availableSftpCredentials, podSFTPSubscriptions)
    handleLocalThirdPartyCredentials(
      availableThirdPartyCredentials,
      podThirdPartyDriveSubscriptions,
    )

    setHasIntegrations(
      Boolean(
        (podSFTPSubscriptions && podSFTPSubscriptions.length > 0) ||
          (podThirdPartyDriveSubscriptions &&
            podThirdPartyDriveSubscriptions.length > 0),
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableSftpCredentials,
    availableThirdPartyCredentials,
    podThirdPartyDriveSubscriptions,
    podSFTPSubscriptions,
  ])

  useEffect(() => {
    if (!isEditing) {
      const internalHasIntegrations =
        hasLocalSftpIntegrations() || hasLocalThirdPartyIntegrations()
      setHasIntegrations(internalHasIntegrations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSftpCredentials, localThirdPartyCredentials, isEditing])

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleCancelClick = async () => {
    setIsEditing(false)
    handleLocalSftpCredentials(availableSftpCredentials, podSFTPSubscriptions)
    handleLocalThirdPartyCredentials(
      availableThirdPartyCredentials,
      podThirdPartyDriveSubscriptions,
    )
  }

  const handleSaveClick = async () => {
    await editSFTPSubscription({
      userPodId,
      credentialIds: hasIntegrations
        ? localSftpCredentials.filter((r) => r.isSelected).map((r) => r.id)
        : [],
      outputFileName: userPodName,
    })
    await editThirdPartyDriveSubscription({
      userPodId,
      credentialIds: hasIntegrations
        ? localThirdPartyCredentials
            ?.filter((r) => r.isSelected)
            .map((r) => r.id)
        : [],
      outputFileName: userPodName,
    })
    await Promise.all([
      refetchSFTPSubscriptions,
      refetchThirdPartySubscriptions,
    ])

    setIsEditing(false)
  }

  // TODO: IF we pull an integration/subscription from the api and populate the above, we need to set the radio button to show the integrations

  if (isLoadingSFTPSubscriptions) {
    return <IWLoading />
  }

  if (!isLoadingSFTPSubscriptions && error) {
    return <IWError />
  }

  return (
    <StyledTabSection>
      <IWFormSection
        sectionTitle={t(
          `insightManager.createPod.generalSetting.additionalOutput`,
        )}
      >
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 400 }}>
          {t(`insightManager.createPod.generalSetting.additionalOutputDesc`)}
        </IWTypography>
        <IWRadioButtonGroup direction="row">
          <IWRadioButton
            id="hide"
            name="hide"
            disabled={!isEditing}
            checked={!hasIntegrations}
            label={t(
              `insightManager.createPod.generalSetting.notNotStoreExternal`,
            )}
            onChange={(e) => setHasIntegrations(!e.target.checked)}
          />
          <IWRadioButton
            id="show"
            name="show"
            disabled={!isEditing}
            checked={hasIntegrations}
            label={t(`insightManager.createPod.generalSetting.storeExternal`)}
            onChange={(e) => setHasIntegrations(e.target.checked)}
          />
        </IWRadioButtonGroup>
      </IWFormSection>
      {hasIntegrations && (
        <StyledDiv>
          <PodIntegrationsSection
            availableCredentials={localSftpCredentials || []}
            isEditable={isEditing}
            onCredentialIdToggle={toggleSftpCredentials}
            sectionTitle={t(
              `insightManager.createPod.generalSetting.sftpOptions`,
            )}
          />
          <PodIntegrationsSection
            availableCredentials={localThirdPartyCredentials || []}
            isEditable={isEditing}
            onCredentialIdToggle={toggleLocalThirdPartyCredentials}
            sectionTitle={t(
              `insightManager.createPod.generalSetting.thirdPartyIntegrations`,
            )}
          />
        </StyledDiv>
      )}
      <StyledButtonDiv>
        {!isEditing ? (
          <IWButton onClick={handleEditClick}>{t('button.edit')}</IWButton>
        ) : (
          <>
            <IWButton
              color="primary"
              variant="alternative"
              onClick={handleCancelClick}
            >
              {t('button.cancel')}
            </IWButton>
            <IWButton onClick={handleSaveClick}>{t('button.save')}</IWButton>
          </>
        )}
      </StyledButtonDiv>
    </StyledTabSection>
  )
}

export default PodDetailsIntegrationsSection
