import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  faExclamationTriangle,
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { MainOptions } from 'shared/components/thunderbolt/IWRelativeDatePicker'
import IWRelativeDatePickerWrapper from 'shared/components/thunderbolt/IWRelativeDatePickerWrapper'
import { DateTime } from 'luxon'
import useIWRelativeDatePicker, {
  defaultHasErrorsCalculation,
  defaultIndividualErrorsCalculation,
  DefaultState,
} from 'shared/hooks/useIWRelativeDatePicker'
import MeasureRangeFilter from 'tools/insightsManager/components/MeasureRangeFilter'
import useMeasureRangeFilters, {
  MeasureRangeFilter as MeasureRangeFilterType,
} from 'tools/insightsManager/hooks/useMeasureRangeFilters'
import LimitFilter from 'tools/insightsManager/components/LimitFilter'
import useLimitFilters, {
  LimitFilter as LimitFilterType,
} from 'tools/insightsManager/hooks/useLimitFilters'
import AttributeFilterGroup from 'tools/insightsManager/components/AttributeFilterGroup'
import useAttributeFilters from 'tools/insightsManager/hooks/useAttributeFilters'
import { AttributeFilter, WeatherAttributes } from 'tools/insightsManager/types'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import useMarketFilter from '../../../hooks/useMarketFilter'
import MarketFilter from '../../../components/MarketFilter'
import ConfirmChangesModal from '../../../components/ConfirmChangesModal'
import { WizardStepProps } from '../../../../../shared/types'

type MeterConfigurationState = {
  dateRange?: DefaultState
  marketFilter: DropdownValueProps[]
  attributeFilters?: AttributeFilter[]
  rangeFilters?: MeasureRangeFilterType[]
  limit?: LimitFilterType
  weatherAttributes?: WeatherAttributes
  useForLoadScheduling?: boolean
}

const StyledRangeFilterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const datePickerMapper = (
  datePickerState: ReturnType<typeof useIWRelativeDatePicker>,
): DefaultState => {
  return {
    selectedSpecificDatePickerDate:
      datePickerState.selectedSpecificDatePickerDate,
    selectedDatePickerMin: datePickerState.selectedDatePickerMin,
    selectedDatePickerMax: datePickerState.selectedDatePickerMax,
    selectedRelativeOption: datePickerState.selectedRelativeOption,
    selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
    timeLengthValue: datePickerState.timeLengthValue,
    selectedMainOption: datePickerState.selectedMainOption,
    disabledMainOptions: datePickerState.disabledMainOptions,
  }
}

export type Props = WizardStepProps<MeterConfigurationState>

const IWCreateConfigurationStep = ({ state, onChange, showErrors }: Props) => {
  const datePickerDisabledMainOptions: MainOptions[] = ['after', 'before']
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const modalPromiseRef = useRef<{ resolve: () => void; reject: () => void }>()
  const [isDatePickerOpen, setDatePickerIsOpen] = useState<boolean>(false)
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState<boolean>(false)
  const [datePickerDefaults, setDatePickerDefaults] = useState<DefaultState>(
    state?.dateRange || {
      selectedMainOption: undefined,
      selectedTimeLengthOption: { value: 'days' },
      selectedRelativeOption: { value: 'today' },
      selectedSpecificDatePickerDate: DateTime.local(),
      selectedDatePickerMin: DateTime.local(),
      selectedDatePickerMax: DateTime.local(),
      timeLengthValue: 30,
      disabledMainOptions: datePickerDisabledMainOptions,
    },
  )

  const [RANGE_FILTERS, setRangeFilters] = useState<
    { value: string; label: string }[]
  >([])

  const [LIMIT_FILTERS, setLimitFilters] = useState<
    { value: string; label: string }[]
  >([])

  const {
    selectedMarkets,
    availableMarkets,
    updateMarkets,
    hasErrors: hasMarketFiltersErrors,
  } = useMarketFilter(state?.marketFilter || [])

  const {
    filters: attributeSectionFilters,
    deleteFilter,
    deleteAllFilters,
    addFilter,
    updateFilter,
    attributeTypes,
    initialized,
    conditions,
    searchAttributeValues,
    getAvailableAttributes,
    hasErrors: hasAttributeFiltersErrors,
    getInitialAttributeValues,
  } = useAttributeFilters(state?.attributeFilters || [])

  const {
    filters: rangeFilters,
    ranges,
    hasErrors: hasRangeFiltersErrors,
    addRange,
    deleteRange,
    onMeasureFilterChange,
    onFirstConditionChange,
    onFirstConditionValueChange,
    onFirstConditionToggleChange,
    onSecondConditionChange,
    onSecondConditionValueChange,
    onSecondConditionToggleChange,
  } = useMeasureRangeFilters(RANGE_FILTERS, state?.rangeFilters)

  const {
    limit,
    limitFilters,
    hasErrors: hasLimitErrors,
    onMeasureOptionChange,
    onEdgeOptionChange,
    onTypeOptionChange,
    onValueChange,
    addLimit,
    deleteLimit,
  } = useLimitFilters(LIMIT_FILTERS, state?.limit)

  const updateRangeFilters = (range) => {
    return RANGE_FILTERS.filter((rf) => {
      return (
        range.selectedMeasureRangeFilterOption?.value === rf.value ||
        !ranges.find(
          (r) => r.selectedMeasureRangeFilterOption?.value === rf.value,
        )
      )
    })
  }

  const datePickerState = useIWRelativeDatePicker({
    ...datePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const handleDatePickerConfirm = () => {
    if (datePickerState.hasErrors) {
      setShouldDisplayErrors(true)
    } else {
      setDatePickerDefaults({
        selectedMainOption: datePickerState.selectedMainOption,
        selectedTimeLengthOption: datePickerState.selectedTimeLengthOption,
        selectedRelativeOption: datePickerState.selectedRelativeOption,
        selectedSpecificDatePickerDate:
          datePickerState.selectedSpecificDatePickerDate,
        selectedDatePickerMin: datePickerState.selectedDatePickerMin,
        selectedDatePickerMax: datePickerState.selectedDatePickerMax,
        timeLengthValue: datePickerState.timeLengthValue,
        disabledMainOptions: datePickerDisabledMainOptions,
      })
      setDatePickerIsOpen(false)
      setShouldDisplayErrors(false)
    }
  }

  const handleDatePickerCancel = () => {
    datePickerState.modifyFullState(datePickerDefaults)
    setDatePickerIsOpen(false)
  }

  /**
   * When the markets change, we need to reset all filters if there are attributes
   */
  const handleConfirmMarketChange = (markets) => {
    if (attributeSectionFilters?.length > 0) {
      setModalIsOpen(true)
      modalPromiseRef.current = {
        resolve: () => {
          deleteAllFilters()
          updateMarkets(markets)
          setModalIsOpen(false)
        },
        reject: () => {
          setModalIsOpen(false)
        },
      }
    } else {
      updateMarkets(markets)
    }
  }

  const stepValidation = (): boolean => {
    // valid if date picker has no errors and not open
    const isDateRangeValid = !datePickerState.hasErrors && !isDatePickerOpen
    // valid if attribute section is (empty or not empty and no key was flag set to true)
    // attribute hasErrors type is {attribute: boolean, condition: boolean, value: boolean}[]
    const isAttributeFilterSectionValid =
      attributeSectionFilters?.length === 0 ||
      (attributeSectionFilters?.length > 0 &&
        !hasAttributeFiltersErrors.some((element) =>
          Object.keys(element).some((k) => element[k]),
        ))
    // valid if range section is (empty or not empty and has no errors)
    const isRangeFilterSectionValid =
      ranges.length === 0 || (ranges.length > 0 && !hasRangeFiltersErrors)
    // valid if limit section is (empty or not empty and has no errors)
    const isLimitSectionValid = !limit || (limit && !hasLimitErrors)
    // step is valid if all section validation === true
    return (
      isDateRangeValid &&
      !hasMarketFiltersErrors &&
      isAttributeFilterSectionValid &&
      isRangeFilterSectionValid &&
      isLimitSectionValid
    )
  }

  useEffect(() => {
    if (isDatePickerOpen) {
      return
    }
    const stepIsValid = stepValidation()
    const mappedDatePicker = datePickerMapper(datePickerState)
    onChange(
      {
        dateRange: mappedDatePicker,
        marketFilter: selectedMarkets,
        attributeFilters: attributeSectionFilters,
        rangeFilters: ranges,
        limit,
      },
      stepIsValid,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDatePickerOpen,
    datePickerState,
    selectedMarkets,
    attributeSectionFilters,
    hasAttributeFiltersErrors,
    ranges,
    hasRangeFiltersErrors,
    limit,
    hasLimitErrors,
  ])

  useEffect(() => {
    // When markets updates, we want to get the new available attributes and remove any invalid filters
    if (selectedMarkets && selectedMarkets[0]) {
      getAvailableAttributes(selectedMarkets[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarkets])

  useEffect(() => {
    if (attributeTypes.length > 0) {
      setRangeFilters(attributeTypes)
      setLimitFilters(attributeTypes)
    }
  }, [attributeTypes])

  return (
    <>
      <IWFormSection sectionTitle={t(`insightManager.createPod.dateRange`)}>
        <IWRelativeDatePickerWrapper
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datePickerState}
          label={t(
            'insights.shortTermForecast.metrics.meter.relativeDatePickerLabel',
          )}
          maxTimeLengthValue={100}
          shouldDisplayErrors={shouldDisplayErrors}
          isOpen={isDatePickerOpen}
          onConfirm={handleDatePickerConfirm}
          onPlaceholderClick={() => setDatePickerIsOpen(true)}
          onCancel={handleDatePickerCancel}
          hasError={showErrors && datePickerState.hasErrors}
        />
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`attributeFilterSection.attributeFilters`)}
        sectionDescription={t(
          `attributeFilterSection.attributeFiltersDescription`,
        )}
      >
        <MarketFilter
          showErrors={showErrors}
          availableMarkets={availableMarkets}
          selectedMarkets={selectedMarkets}
          onSetState={handleConfirmMarketChange}
          hasErrors={hasMarketFiltersErrors}
        />

        <ConfirmChangesModal
          icon={faExclamationTriangle}
          title={t(`insightManager.createPod.confirmChangeLabel`)}
          body={t(`insightManager.createPod.confirmMarketChangeDesc`)}
          isOpen={modalIsOpen}
          handleClose={modalPromiseRef.current?.reject}
          handleConfirm={modalPromiseRef.current?.resolve}
        />

        <AttributeFilterGroup
          updateFilter={updateFilter}
          showErrors={showErrors}
          filters={attributeSectionFilters}
          deleteFilter={deleteFilter}
          addFilter={addFilter}
          attributeTypes={attributeTypes}
          initialized={initialized}
          conditions={conditions}
          getInitialAttributeValues={getInitialAttributeValues}
          searchAttributeValues={searchAttributeValues}
          hasErrors={hasAttributeFiltersErrors}
        />
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`rangeFilterSection.rangeFilters`)}
        sectionDescription={t(`rangeFilterSection.rangeFiltersDescription`)}
      >
        <StyledRangeFilterSection>
          {ranges.map((range) => (
            <MeasureRangeFilter
              key={range.id}
              filters={updateRangeFilters(range)}
              range={range}
              showErrors={showErrors}
              hasErrors={hasRangeFiltersErrors}
              onMeasureFilterChange={onMeasureFilterChange}
              onFirstConditionChange={onFirstConditionChange}
              onFirstConditionValueChange={onFirstConditionValueChange}
              onFirstConditionToggleChange={onFirstConditionToggleChange}
              onSecondConditionChange={onSecondConditionChange}
              onSecondConditionValueChange={onSecondConditionValueChange}
              onSecondConditionToggleChange={onSecondConditionToggleChange}
              onDelete={deleteRange}
            />
          ))}

          <IWButton
            data-testid="measure-range-filter-add"
            type="button"
            color="primary"
            icon={faPlusCircle}
            iconPosition="leading"
            onClick={addRange}
            disabled={
              hasRangeFiltersErrors || rangeFilters.length === ranges.length
            }
          >
            {t('rangeFilterSection.rangeFilters')}
          </IWButton>
        </StyledRangeFilterSection>
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(`limitFilterSection.limitFilters`)}
        sectionDescription={t(`limitFilterSection.limitFiltersDescription`)}
      >
        <StyledRangeFilterSection>
          <LimitFilter
            filters={limitFilters}
            limit={limit}
            onMeasureFilterChange={onMeasureOptionChange}
            onEdgeOptionChange={onEdgeOptionChange}
            onTypeOptionChange={onTypeOptionChange}
            onValueChange={onValueChange}
            onDelete={deleteLimit}
            showErrors={showErrors}
            hasErrors={hasLimitErrors}
          />

          <IWButton
            data-testid="measure-range-filter-add"
            type="button"
            color="primary"
            icon={faPlusCircle}
            iconPosition="leading"
            onClick={addLimit}
            disabled={Boolean(limit)}
          >
            {t('limitFilterSection.limitFilter')}
          </IWButton>
        </StyledRangeFilterSection>
      </IWFormSection>
    </>
  )
}
export default IWCreateConfigurationStep
