import React from 'react'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { v4 as uuidv4 } from 'uuid'
import IWTextInput from 'shared/components/thunderbolt/IWTextInput'
import { IWError } from 'shared/components/thunderbolt/IWCommonLabels'
import IWDropdown, {
  DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFilterSection from 'shared/components/thunderbolt/IWFilterSection'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import { MeasureRangeFilter as MeasureRangeFilterType } from 'tools/insightsManager/hooks/useMeasureRangeFilters'

export interface Props {
  range: MeasureRangeFilterType
  filters: DropdownValueProps[]
  showErrors?: boolean
  hasErrors?: boolean
  onMeasureFilterChange: (value: DropdownValueProps, id: string) => void
  onFirstConditionChange: (value: DropdownValueProps, id: string) => void
  onSecondConditionChange: (value: DropdownValueProps, id: string) => void
  onFirstConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onSecondConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onFirstConditionToggleChange: (id: string) => void
  onSecondConditionToggleChange: (id: string) => void
  onDelete: (id: string) => void
}

const StyledFilterSection = styled(IWFilterSection)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`

const StyledIWButton = styled(IWButton)`
  padding: 0.375rem 1rem;
  align-self: flex-end;
`

const StyledIWDropdown = styled(IWDropdown)`
  margin-right: 1rem;
`

const StyledFlexRow = styled.div`
  display: flex;
`

const StyleInputWrapper = styled.div`
  margin-right: 1rem;
  width: 100%;
`

const StyleToggleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

/**
 * Component to filter the underlying data in my POD by values in 1 of the measure columns.
 *
 * NOTE: This component provides a Custom Hook (useMeasureRangeFilter) that can be used with the component.
 * The `useMeasureRangeFilter` hook will manage the state of the component.
 */
const MeasureRangeFilter = ({
  range,
  filters,
  showErrors,
  hasErrors,
  onMeasureFilterChange,
  onFirstConditionChange,
  onSecondConditionChange,
  onFirstConditionValueChange,
  onSecondConditionValueChange,
  onFirstConditionToggleChange,
  onSecondConditionToggleChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()

  const firstConditions = [
    {
      value: '>',
      label: t(`rangeFilterSection.options.greaterThan`),
    },
    {
      value: '>=',
      label: t(`rangeFilterSection.options.greaterThanOrEqualTo`),
    },
  ]

  const secondConditions = [
    {
      value: '<',
      label: t(`rangeFilterSection.options.lessThan`),
    },
    {
      value: '<=',
      label: t(`rangeFilterSection.options.lessThanOrEqualTo`),
    },
  ]

  // Auto select first item if Measure filter dropdown contains one item ONLY.
  if (filters.length === 1) {
    const [firstFilter] = filters
    // eslint-disable-next-line no-param-reassign
    range.selectedMeasureRangeFilterOption = firstFilter
  }

  return (
    <StyledFilterSection data-testid="measure-range-component">
      <StyledFlexRow>
        <StyledIWDropdown
          name="measure"
          required
          fullWidth
          label={t('rangeFilterSection.measureToFilter')}
          value={range.selectedMeasureRangeFilterOption}
          onChange={(e) => onMeasureFilterChange(e, range.id)}
          options={[...filters]}
          placeholder={t('placeholders.select')}
          isDisabled={range.isMeasureRangeFilterDisabled}
          hasError={showErrors && !range.selectedMeasureRangeFilterOption}
        />

        <StyledIWButton
          data-testid="measure-range-delete-button"
          color="grey"
          variant="outline"
          type="button"
          onClick={() => onDelete(range.id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </StyledIWButton>
      </StyledFlexRow>

      <StyledFlexRow>
        <StyledIWDropdown
          name="first-condition"
          required
          fullWidth
          label={t('rangeFilterSection.condition')}
          value={range.selectedFirstConditionOption}
          onChange={(e) => onFirstConditionChange(e, range.id)}
          options={firstConditions}
          placeholder={t('placeholders.select')}
          isDisabled={range.isFirstConditionDisabled}
          hasError={showErrors && !range.selectedFirstConditionOption}
        />

        <StyleInputWrapper>
          <IWTextInput
            data-testid="measure-range-first-condition-input"
            name="minvalue"
            type="number"
            required
            label={t('rangeFilterSection.minValue')}
            value={range.firstConditionValue ?? ''}
            onChange={(e) => onFirstConditionValueChange(e, range.id)}
            placeholder={t('placeholders.enterValue')}
            disabled={range.isFirstConditionDisabled}
            hasError={showErrors && range.hasFirstConditionValueError}
          />
        </StyleInputWrapper>

        <StyleToggleWrapper>
          <IWToggle
            data-testid="measure-range-first-condition-toggle"
            label={t('rangeFilterSection.disabled')}
            id={uuidv4()}
            checked={range.isFirstConditionDisabled}
            onChange={() => onFirstConditionToggleChange(range.id)}
          />
        </StyleToggleWrapper>
      </StyledFlexRow>

      <StyledFlexRow>
        <StyledIWDropdown
          name="second-condition"
          required
          fullWidth
          label={t('rangeFilterSection.condition')}
          value={range.selectedSecondConditionOption}
          onChange={(e) => onSecondConditionChange(e, range.id)}
          options={secondConditions}
          placeholder={t('placeholders.select')}
          isDisabled={range.isSecondConditionDisabled}
          hasError={showErrors && !range?.selectedSecondConditionOption}
        />

        <StyleInputWrapper>
          <IWTextInput
            data-testid="measure-range-second-condition-input"
            name="maxvalue"
            type="number"
            required
            label={t('rangeFilterSection.maxValue')}
            value={range.secondConditionValue ?? ''}
            onChange={(e) => onSecondConditionValueChange(e, range.id)}
            placeholder={t('placeholders.enterValue')}
            disabled={range.isSecondConditionDisabled}
            hasError={showErrors && range.hasSecondConditionValueError}
          />
        </StyleInputWrapper>

        <StyleToggleWrapper>
          <IWToggle
            data-testid="measure-range-second-condition-toggle"
            label={t('rangeFilterSection.disabled')}
            id={uuidv4()}
            checked={range.isSecondConditionDisabled}
            onChange={() => onSecondConditionToggleChange(range.id)}
          />
        </StyleToggleWrapper>
      </StyledFlexRow>
      {showErrors && hasErrors && (
        <IWError
          label={t('insightManager.createPod.errors.enterAllRequired')}
        />
      )}
    </StyledFilterSection>
  )
}

export default MeasureRangeFilter
