import NotAuthorised from 'root/pages/NotAuthorised'
import { Navigate } from 'react-router-dom'
import { IWMainLayout } from './components/IWLayout'
import OverviewPage from './pages/OverviewPage'
import { routes as ToolsRoutes } from '../tools'
import { routes as SystemPreferencesRoutes } from '../systemPreferences'
import LayoutRoute from './components/LayoutRoute'
import NotFound from './pages/NotFound'
import EntryPoint from './pages/EntryPoint'
import InnowattsProducts from './pages/InnowattsProducts'

const Main = () => {
  return <IWMainLayout />
}

const ErrorAndWildcardRoutes = [
  {
    key: '401',
    path: '/not-authorised',
    main: <NotAuthorised />,
  },
  {
    key: '404',
    path: '/not-found',
    main: <NotFound />,
  },
  {
    key: 'wildcard',
    path: '*',
    main: <Navigate to="/" />,
  },
]

const mainRoutes = [
  {
    key: 'home',
    path: '',
    main: <Main />,
    childRoutes: [
      {
        key: 'entrypoint',
        path: '/',
        main: <EntryPoint />,
        entryPoint: true,
      },
      {
        key: 'overview',
        path: '/overview',
        main: <OverviewPage />,
      },
      {
        key: 'innowatts-products',
        path: '/innowatts-products',
        main: <InnowattsProducts />,
      },
      ...ErrorAndWildcardRoutes,
      ...ToolsRoutes,
    ],
  },
]

const index = LayoutRoute([...mainRoutes, ...SystemPreferencesRoutes])

export default index
