import React, { MouseEventHandler, useEffect, useMemo } from 'react'
import { DateTime, WeekdayNumbers } from 'luxon'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import useCalendar from 'shared/hooks/useCalendar'
import IWTypography from './IWTypography'
import IWButton from './IWButton'
import IWDivider from './IWDivider'
import IWRoundInput from './IWRoundInput'

const StyledDatePickerWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-radius: 0.375rem;
  padding: 0.5rem;
`

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6);
`

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`

const StyledMonthSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0.5rem;
  text-transform: capitalize;
`

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`

interface ChevronButtonProps {
  onClick: MouseEventHandler
  direction: 'right' | 'left'
}

const StyledChevronButton = styled(FontAwesomeIcon)`
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
  color: ${(props) => props.theme.palette.grey[400]};
  &:hover {
    cursor: pointer;
    color: ${(props) => darken(0.1, props.theme.palette.grey[400])};
  }
  &:focus {
    color: ${(props) => darken(0.1, props.theme.palette.grey[400])};
  }
`

const ChevronButton = ({ onClick, direction }: ChevronButtonProps) => {
  const { t } = useTranslation()
  return (
    <StyledChevronButton
      role="button"
      data-testid={direction === 'left' ? 'prevMonth' : 'nextMonth'}
      aria-label={
        direction === 'left'
          ? t('staticDatePicker.nextMonth')
          : t('staticDatePicker.previousMonth')
      }
      tabIndex={0}
      onClick={onClick}
      icon={direction === 'left' ? faChevronLeft : faChevronRight}
    />
  )
}

const StyledDatePickerDateButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 36px;
`

export interface Props {
  /** This component uses checkboxes as the date selectors... If you have more than one date picker in view you should provide an id to distinguish between date pickers */
  id?: string
  /** This prop will set when to start the days of the week in the date picker (mon-sun / sun-mon / etc) */
  weekStartsOn?: number
  /** On what month / year should the date picker initialize */
  initialView?: DateTime
  selectedDate?: DateTime
  onChange: (DateTime) => void
}

/**
 * A simple date picker that allows a user to only select a single date. A second component, `<IWDatePicker />` is also available to combine the date picker with a text input.
 */
const IWStaticDatePicker = ({
  id,
  initialView = DateTime.local(),
  weekStartsOn = 7,
  selectedDate,
  onChange,
}: Props) => {
  const {
    calendar,
    nextMonth,
    prevMonth,
    year,
    month,
    daysOfWeek,
    setCurrentView,
  } = useCalendar({ weekStartsOn: weekStartsOn as WeekdayNumbers, initialView })

  useEffect(() => {
    if (selectedDate) {
      setCurrentView(selectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const today = useMemo(() => {
    return DateTime.fromISO(DateTime.local().toISODate())
  }, [])

  const { t } = useTranslation()
  return (
    <StyledDatePickerWrapper id={id} data-testid="staticDatePicker">
      <StyledHeader>
        <StyledMonthSelector>
          <ChevronButton direction="left" onClick={prevMonth} />
          <IWTypography
            data-testid="monthYear"
            size="sm"
            weight="medium"
            as="span"
            fontHue={{ color: 'grey', value: 700 }}
          >
            {month} {year}
          </IWTypography>
          <ChevronButton direction="right" onClick={nextMonth} />
        </StyledMonthSelector>
        <IWButton
          variant="outline"
          onClick={() => {
            // Set current view in case today has already been selected
            setCurrentView(today)
            onChange(today)
          }}
        >
          {t('staticDatePicker.today')}
        </IWButton>
      </StyledHeader>
      <IWDivider />
      <StyledDatePicker>
        <StyledGrid>
          {daysOfWeek.map((day) => {
            return (
              <StyledDatePickerDateButtonWrapper
                key={`dayOfWeek_${day.weekdayShort}`}
              >
                <IWTypography
                  size="sm"
                  weight="medium"
                  as="span"
                  data-testid="dayOfWeek"
                  fontHue={{ color: 'grey', value: 400 }}
                >
                  {day.weekdayShort}
                </IWTypography>
              </StyledDatePickerDateButtonWrapper>
            )
          })}
        </StyledGrid>
        <StyledGrid>
          {calendar.map((week, i) => {
            return week.map((d, j) => {
              if (d) {
                return (
                  <IWRoundInput
                    // in case you need to display multiple calendars we append the 'calendar's input to the id
                    id={`${id}_${d.toISODate()}`}
                    name={`${id}_${d.toISODate()}`}
                    key={d.toISODate()}
                    type="radio"
                    label={d.day.toString()}
                    checked={Boolean(
                      selectedDate &&
                        d.toISODate() === selectedDate.toISODate(),
                    )}
                    onChange={() => {
                      onChange(d)
                    }}
                  />
                )
              }
              return (
                <StyledDatePickerDateButtonWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={`undefinedDay_${i}_${j}`}
                />
              )
            })
          })}
        </StyledGrid>
      </StyledDatePicker>
    </StyledDatePickerWrapper>
  )
}

export default IWStaticDatePicker
