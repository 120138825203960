import { useEffect, useState } from 'react'
import { GenericDropdownValue } from 'shared/components/thunderbolt/IWDropdown'
import { AggregationFn } from 'tools/alertsManager/types'
import { mapToDropdownOptions } from 'tools/alertsManager/utils/utils'

/**
 * @description A Custom Hook to pair with the `WeatherAttributes`.
 * The hook can manage the `WeatherAttributes` states.
 * @param selectedAggregation aggregation to see with the grouped weather station . avg / min / max
 * @param selectedWeatherStationCount number of weather station to group by
 * @returns object of WeatherAttributeHook
 */
const useWeatherAttributes = (
  selectedAggregation?: AggregationFn,
  selectedWeatherStationCount?: number,
) => {
  const weatherStationCountOptions = mapToDropdownOptions<number>([
    1, 2, 3, 4, 5,
  ])

  const aggregationOptions = mapToDropdownOptions<AggregationFn>([
    'avg',
    'min',
    'max',
  ])

  const [weatherStationCount, setWeatherStationCount] = useState<
    GenericDropdownValue<number> | undefined
  >(
    selectedWeatherStationCount
      ? {
          label: selectedWeatherStationCount.toString(),
          value: selectedWeatherStationCount,
        }
      : undefined,
  )

  const [aggregation, setAggregation] = useState<
    GenericDropdownValue<AggregationFn> | undefined
  >(
    selectedAggregation
      ? {
          label: selectedAggregation,
          value: selectedAggregation,
        }
      : undefined,
  )

  const [hasErrors, setHasErrors] = useState<boolean>(false)

  function validateErrors() {
    const containErrors = !weatherStationCount || !aggregation
    setHasErrors(containErrors)
  }

  const onWeatherStationCountChange = (value) => {
    setWeatherStationCount(value)
  }

  const onAggregationChange = (value) => {
    setAggregation(value)
  }

  useEffect(() => {
    validateErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherStationCount, aggregation])

  return {
    weatherStationCountOptions,
    aggregationOptions,
    weatherStationCount,
    aggregation,
    onWeatherStationCountChange,
    onAggregationChange,
    hasErrors,
  }
}

export default useWeatherAttributes
