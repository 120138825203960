import styled from 'styled-components'
import { Pod } from '../../insightsManager/types'
import LSPodCard from './LSPodCard'

const StyledCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`

type Props = {
  pods: Array<Pod & { currentStatusMessage?: string }>
  isLoading?: boolean
  selectedPodIds: string[]
  onDownload: (pod: Pod) => void
  onSelect: (pod: Pod) => void
  onConvert?: (pod: Pod) => void
  onNetOpenPositionSelected?: (pod: Pod | undefined) => void
  selectedNetOpenPosition?: Pod
  netOpenPositionList?: Pod[]
}

const LSPodList = ({
  pods,
  isLoading = false,
  selectedPodIds,
  onDownload,
  onSelect,
  onConvert,
  onNetOpenPositionSelected,
  netOpenPositionList,
  selectedNetOpenPosition,
}: Props) => {
  return (
    <StyledCardList>
      {pods
        .filter(
          (p) =>
            (p.useForLoadScheduling &&
              p.podType.insightId === 'shortTermForecast' &&
              (p.podType.metricId === 'settlement' ||
                p.podType.metricId === 'productType')) ||
            (p.podType.insightId === 'riskManagement' &&
              p.podType.metricId === 'explodedTrades'),
        )
        .map((pod) => (
          <LSPodCard
            key={pod.userPodId}
            pod={pod}
            netOpenPositionList={netOpenPositionList}
            disabled={
              pod.markets[0] === 'ercot' &&
              !Boolean(selectedNetOpenPosition) &&
              pod.podType.insightId === 'shortTermForecast'
            }
            isSelected={selectedPodIds.includes(pod.userPodId)}
            isLoading={isLoading}
            onDownload={onDownload}
            onSelect={onSelect}
            onConvert={onConvert}
            onNetOpenPositionSelected={onNetOpenPositionSelected}
            selectedNetOpenPosition={selectedNetOpenPosition}
          />
        ))}
    </StyledCardList>
  )
}

export default LSPodList
