import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { faBell, faUser } from '@fortawesome/pro-regular-svg-icons'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { IWGenericCard } from 'shared/components/thunderbolt/IWGenericCard'
import UserContext from 'shared/contexts/UserContext'
import IWInputLabel from 'shared/components/thunderbolt/IWCommonLabels'
import IWAvatar from 'shared/components/thunderbolt/IWAvatar'
import LayoutContext, {
  availableThemes,
  availableTimezones,
} from 'shared/contexts/LayoutContext'
import { availableLanguages } from 'i18n'
import useNotificationSelector, {
  Notifications,
} from 'systemPreferences/hooks/useNotificationSelector'
import NotificationSelectorModal from 'systemPreferences/components/NotificationSelectorModal'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import IWProductList from 'shared/components/thunderbolt/IWProductList'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'

const PreferencesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CardContent = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
`

const StyledInfoBlock = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.5rem;
`
const StyledInfoSection = styled.div`
  display: flex;
`

/**
 * Page to display and edit user profile settings
 */
const UserProfilePage = () => {
  const { t } = useTranslation()
  const { name, email } = useContext(UserContext)
  const { theme, setTheme, language, setLanguage, timezone, setTimezone } =
    useContext(LayoutContext)

  const themeOptions = availableThemes.map((thm) => ({ value: thm }))
  const languageOptions = availableLanguages.map((l) => ({ value: l }))
  const timezoneOptions = availableTimezones.map((tz) => ({
    value: tz,
    label: tz,
  }))

  const {
    isNotificationModalOpen,
    setIsNotificationSelectorModalOpen,
    notificationOptions,
    selectedNotificationType,
    notifications,
    updateNotifications,
  } = useNotificationSelector()

  return (
    <>
      <PreferencesSection>
        <IWTypography
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {t('systemPreferences.userProfile.profile')}
        </IWTypography>
        <IWGenericCard>
          <CardContent>
            <StyledInfoSection>
              <StyledInfoBlock>
                <IWAvatar size={64} src="" icon={faUser} alt="User" />
              </StyledInfoBlock>
            </StyledInfoSection>
            <StyledInfoSection>
              <StyledInfoBlock>
                <IWTypography size="sm" fontHue={{ color: 'grey', value: 600 }}>
                  <IWInputLabel
                    label={t('systemPreferences.userProfile.name', {
                      suffix: ': ',
                    })}
                  />
                  {name}
                </IWTypography>
                <IWTypography size="sm" fontHue={{ color: 'grey', value: 600 }}>
                  <IWInputLabel
                    label={t('systemPreferences.userProfile.email', {
                      suffix: ': ',
                    })}
                  />
                  {email}
                </IWTypography>
              </StyledInfoBlock>
            </StyledInfoSection>
          </CardContent>
        </IWGenericCard>
      </PreferencesSection>

      <PreferencesSection>
        <IWTypography size="lg" weight="medium">
          {t('systemPreferences.generalSetting.header')}
        </IWTypography>
        <IWGenericCard>
          <CardContent>
            <StyledInfoSection>
              <StyledInfoBlock>
                <IWDropdown
                  label={t('systemPreferences.energySaving.header')}
                  options={themeOptions}
                  value={{ value: theme }}
                  onChange={(a) => {
                    setTheme(a.value)
                  }}
                  getOptionLabel={(option) => {
                    return t(`systemPreferences.energySaving.${option.value}`)
                  }}
                />
              </StyledInfoBlock>
            </StyledInfoSection>
            <StyledInfoSection>
              <StyledInfoBlock>
                <IWDropdown
                  label={t('systemPreferences.generalSetting.language')}
                  value={{ value: language }}
                  onChange={(a) => {
                    setLanguage(a.value)
                  }}
                  getOptionLabel={(option) => {
                    return t(`language.${option.value}`)
                  }}
                  options={languageOptions}
                />
              </StyledInfoBlock>
            </StyledInfoSection>
            <StyledInfoSection>
              <StyledInfoBlock>
                <IWDropdown
                  label={t('systemPreferences.generalSetting.timezone')}
                  options={timezoneOptions}
                  onChange={(a) => {
                    setTimezone(a.value)
                  }}
                  value={{ value: timezone, label: timezone }}
                />
              </StyledInfoBlock>
            </StyledInfoSection>
          </CardContent>
        </IWGenericCard>
      </PreferencesSection>

      {isNotificationModalOpen && (
        <NotificationSelectorModal
          notificationType={selectedNotificationType}
          icon={
            notificationOptions.find((n) => n.id === selectedNotificationType)
              ?.icon || faBell
          }
          notifications={notifications() || ([] as Notifications)}
          isOpen={isNotificationModalOpen}
          handleCancel={() => setIsNotificationSelectorModalOpen(false)}
          handleConfirm={(changed) => {
            updateNotifications(changed)
            setIsNotificationSelectorModalOpen(false)
          }}
        />
      )}

      {/* Reactivate once notifications become available by removing conditional */}
      {isNotificationModalOpen && (
        <PreferencesSection>
          <IWTypography size="lg" weight="medium">
            {t('systemPreferences.systemNotification.header')}
          </IWTypography>
          <IWGenericCard>
            <CardContent>
              <StyledInfoSection>
                <IWProductList>
                  {notificationOptions.map(
                    ({ id, header, icon, iconColor, onClick, badges }) => (
                      <IWProductCard
                        key={id}
                        header={header}
                        icon={icon}
                        iconColor={iconColor}
                        onClick={onClick}
                        badges={badges}
                      />
                    ),
                  )}
                </IWProductList>
              </StyledInfoSection>
            </CardContent>
          </IWGenericCard>
        </PreferencesSection>
      )}
    </>
  )
}

export default UserProfilePage
