import {
  faAlarmExclamation,
  faBadgePercent,
  faBox,
  faBoxArchive,
  faBriefcaseMedical,
  faCalendarAlt,
  faCube,
  faDashboard,
  faFlask,
  faSolarSystem,
  faList,
} from '@fortawesome/pro-regular-svg-icons'
import { UserTools } from 'shared/types'
import { PodType } from 'tools/insightsManager/types'

const getInsights = (t, allowedInsights?: Array<string>) => {
  const insights = [
    {
      icon: faBox,
      type: 'shortTermForecast' as PodType['insightId'],
      label: t(`insights.shortTermForecast.label`),
      description: t(`insights.shortTermForecast.description`),
    },
    {
      icon: faBadgePercent,
      type: 'shortTermForecastPerformance' as PodType['insightId'],
      label: t(`insights.shortTermForecastPerformance.label`),
      description: t(`insights.shortTermForecastPerformance.description`),
    },
    {
      icon: faBadgePercent,
      type: 'shortTermForecastPerformanceTopDown' as PodType['insightId'],
      label: t(`insights.shortTermForecastPerformanceTopDown.label`),
      description: t(
        `insights.shortTermForecastPerformanceTopDown.description`,
      ),
    },
    {
      icon: faSolarSystem,
      type: 'shortTermForecastSystem' as PodType['insightId'],
      label: t(`insights.shortTermForecastSystem.label`),
      description: t(`insights.shortTermForecastSystem.description`),
    },
    {
      icon: faBoxArchive,
      type: 'longTermForecast' as PodType['insightId'],
      label: t(`insights.longTermForecast.label`),
      description: t(`insights.longTermForecast.description`),
    },
    {
      icon: faBriefcaseMedical,
      type: 'riskManagement' as PodType['insightId'],
      label: t(`insights.riskManagement.label`),
      description: t(`insights.riskManagement.description`),
    },
    {
      icon: faBox,
      type: 'actualUsage' as PodType['insightId'],
      label: t(`insights.actualUsage.label`),
      description: t(`insights.actualUsage.description`),
    },
    {
      icon: faSolarSystem,
      type: 'wholesaleSettlement' as PodType['insightId'],
      label: t(`insights.wholesaleSettlement.label`),
      description: t(`insights.wholesaleSettlement.description`),
    },
    {
      icon: faSolarSystem,
      type: 'shortTermForecastPerformanceSystem' as PodType['insightId'],
      label: t(`insights.shortTermForecastPerformanceSystem.label`),
      description: t(`insights.shortTermForecastPerformanceSystem.description`),
    },
    {
      icon: faList,
      type: 'attributeInfo' as PodType['insightId'],
      label: t(`insights.attributeInfo.label`),
      description: t(`insights.attributeInfo.description`),
    },
  ]

  if (allowedInsights) {
    return insights.filter((insight) => allowedInsights.includes(insight.type))
  }
  return insights
}

export const getTools = (t, allowedTools?: Array<string>) => {
  const tools = [
    {
      icon: faCube,
      type: 'insightsManager' as UserTools,
      label: t(`insightManager.insightManager`),
      description: t(`insightManager.insightManagerDesc`),
    },
    {
      icon: faFlask,
      type: 'dataExploration' as UserTools,
      label: t(`verticalNavigation.labels.dataExploration`),
      description: t(`verticalNavigation.labels.dataExplorationDesc`),
    },
    {
      icon: faAlarmExclamation,
      type: 'alertsManager' as UserTools,
      label: t(`alertsManager.alertsManager`),
      description: t(`alertsManager.alertsManagerDesc`),
    },
    {
      icon: faCalendarAlt,
      type: 'loadScheduling' as UserTools,
      label: t(`loadScheduling.loadScheduling`),
      description: t(`loadScheduling.loadSchedulingDesc`),
    },
    {
      icon: faDashboard,
      type: 'dashboardsManager' as UserTools,
      label: t(`verticalNavigation.labels.dashboardsManager`),
      description: t(`verticalNavigation.labels.dashboardsManagerDesc`),
    },
  ]

  if (allowedTools) {
    return tools.filter((tl) => allowedTools.includes(tl.type))
  }
  return tools
}

export default getInsights
