import axiosApiClient from 'shared/apiClient'
import { SortDirection } from 'shared/components/thunderbolt/IWTable'
import { PaginatedData } from 'shared/types'

export interface InboundCredentials {
  credentialId: string
  clientId: string
  name: string
  description: string
  expiresAt: string
  createdAt: string
  updatedAt: string
  isActive: boolean
}

export interface SFTPCredential {
  credentialId: string
  name: string
  description?: string
  host: string
  port: string
  path?: string
  username: string
  password: string
  createdAt: string
}

export interface ThirdPartyIntegration {
  credentialId: string
  thirdPartyDriveId: 'oneDrive'
  email: string
  createdAt: string
  updatedAt: string
}

export interface IntegrationSubscription {
  subscriptionId: string
  credentialId: string
  userPodId: string
  createdAt: string
  updatedAt: string
}

export type CreateSFTPCredential = Omit<
  SFTPCredential,
  'credentialId' | 'createdAt'
>

export const getInboundCredentials = (
  page,
  pageSize: number,
  sort: { column: string; direction: SortDirection },
) => {
  const pgSort = sort.direction === 'ascending' ? 'asc' : 'desc'
  const filterString = new URLSearchParams({
    skip: page.toString(),
    limit: pageSize.toString(),
    sortDirection: pgSort,
    sortColumn: sort.column,
  }).toString()
  const url = `/credentials/v1/inbound-credentials/?${filterString}`
  return axiosApiClient
    .get(url)
    .then((r) => r.data as PaginatedData<InboundCredentials>)
}

export const createNewInboundCredential = ({
  name,
  description,
}: {
  name: string
  description: string
}) => {
  return axiosApiClient
    .post('/credentials/v1/inbound-credentials/', {
      name,
      description,
    })
    .then((r) => {
      return r.data as { clientId: string; clientSecret: string }
    })
}

export const editInboundCredential = ({
  name,
  description,
  credentialId,
}: {
  name: string
  description: string
  credentialId: string
}) => {
  return axiosApiClient.patch(
    `/credentials/v1/inbound-credentials/${credentialId}`,
    {
      name,
      description,
    },
  )
}

export const deleteInboundCredential = ({
  credentialId,
}: {
  credentialId: string
}) => {
  return axiosApiClient.delete(
    `/credentials/v1/inbound-credentials/${credentialId}`,
  )
}

export const getSFTPCredentials = (
  page,
  pageSize: number,
  sort: { column: string; direction: SortDirection },
) => {
  const pgSort = sort.direction === 'ascending' ? 'asc' : 'desc'
  const filterString = new URLSearchParams({
    skip: page.toString(),
    limit: pageSize.toString(),
    sortDirection: pgSort,
    sortColumn: sort.column,
  }).toString()
  const url = `/credentials/v1/sftp-credentials/?${filterString}`
  return axiosApiClient
    .get(url)
    .then((r) => r.data as PaginatedData<SFTPCredential>)
}

export const testStoredSFTPCredential = async (
  credentialId: string,
): Promise<SFTPCredentialTestResult> => {
  const { data } = await axiosApiClient.get(
    `/credentials/v1/sftp-credentials/test/${credentialId}`,
  )
  return data
}

export type SFTPCredentialTestResult = {
  isAuthenticated: boolean
  pathExists: boolean
}

export const testSFTPCredential = async ({
  host,
  port,
  path,
  username,
  password,
}: Pick<
  SFTPCredential,
  'host' | 'port' | 'path' | 'username' | 'password'
>): Promise<SFTPCredentialTestResult> => {
  const { data } = await axiosApiClient.post(
    '/credentials/v1/sftp-credentials/test',
    {
      host,
      port,
      path,
      username,
      password,
    },
  )
  return data
}

export const createSFTPCredential = ({
  name,
  description,
  host,
  port,
  path,
  username,
  password,
}: CreateSFTPCredential) => {
  return axiosApiClient.post('/credentials/v1/sftp-credentials', {
    name,
    description,
    host,
    port,
    path,
    username,
    password,
  })
}

export const editSFTPCredential = ({
  name,
  description,
  host,
  port,
  path,
  username,
  password,
  credentialId,
}: SFTPCredential) => {
  return axiosApiClient.patch(
    `/credentials/v1/sftp-credentials/${credentialId}`,
    {
      name,
      description,
      host,
      port,
      path,
      username,
      password,
    },
  )
}

export const deleteSFTPCredential = ({
  credentialId,
}: {
  credentialId: string
}) => {
  return axiosApiClient.delete(
    `/credentials/v1/sftp-credentials/${credentialId}`,
  )
}

export const createSFTPSubscription = ({
  credentialIds,
  userPodId,
  outputFileName,
}: {
  credentialIds: string[]
  userPodId: string
  outputFileName: string
}) => {
  return axiosApiClient.post(
    `/credentials/v1/sftp-subscriptions/${userPodId}`,
    {
      credentialIds,
      outputFileName,
    },
  )
}

export const editSFTPSubscription = ({
  credentialIds,
  userPodId,
  outputFileName,
}: {
  credentialIds: string[]
  userPodId: string
  outputFileName: string
}) => {
  return axiosApiClient.patch(
    `/credentials/v1/sftp-subscriptions/${userPodId}`,
    {
      credentialIds,
      outputFileName,
    },
  )
}

export const getSFTPSubscriptionsForPod = ({
  userPodId,
}: {
  userPodId: string
}) => {
  // TODO: IMPLEMENT THIS -> What happens here if some subscriptions are no longer valid?

  return axiosApiClient
    .get(`/credentials/v1/sftp-subscriptions/${userPodId}`)
    .then((r) => r.data as IntegrationSubscription[])
}

export const getThirdPartyDriveSubscriptionsForPod = ({
  userPodId,
}: {
  userPodId: string
}) => {
  // TODO: IMPLEMENT THIS -> What happens here if some subscriptions are no longer valid?

  return axiosApiClient
    .get(`/credentials/v1/third-party-drive-subscriptions/${userPodId}`)
    .then((r) => r.data as IntegrationSubscription[])
}

export const oneDriveRedirect = () => {
  return axiosApiClient
    .get('/credentials/v1/third-party-integrations/onedrive/redirect-url', {
      params: { state: 'oneDrive' },
      validateStatus: (status) => status === 200 || status === 302,
    })
    .then((r) => r.data as { url: string })
}

export const oneDriveToken = (code: string) => {
  return axiosApiClient
    .post('/credentials/v1/third-party-integrations/onedrive/token', { code })
    .then((r) => r.data)
}

export const getThirdPartyIntegrations = () => {
  return axiosApiClient
    .get('/credentials/v1/third-party-integrations')
    .then((r) => {
      return r.data as ThirdPartyIntegration[]
    })
}

export const deleteThirdPartyIntegration = (integrationId: string) => {
  return axiosApiClient.delete(
    `/credentials/v1/third-party-integrations/${integrationId}`,
  )
}

export const editThirdPartyDriveSubscription = ({
  credentialIds,
  userPodId,
  outputFileName,
}: {
  credentialIds: string[]
  userPodId: string
  outputFileName: string
}) => {
  return axiosApiClient.patch(
    `/credentials/v1/third-party-drive-subscriptions/${userPodId}`,
    {
      credentialIds,
      outputFileName,
    },
  )
}

export const createThirdPartyDriveSubscription = ({
  credentialIds,
  userPodId,
  outputFileName,
}: {
  credentialIds: string[]
  userPodId: string
  outputFileName: string
}) => {
  return axiosApiClient.post(
    `/credentials/v1/third-party-drive-subscriptions/${userPodId}`,
    {
      credentialIds,
      outputFileName,
    },
  )
}
