import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'

// FIXME: REMOVE NULL AND REPLACE WITH ?: (OPTIONAL)
export interface MeasureRangeFilter {
  id: string
  selectedMeasureRangeFilterOption?: DropdownValueProps
  selectedFirstConditionOption?: DropdownValueProps
  selectedSecondConditionOption?: DropdownValueProps
  firstConditionValue?: number
  secondConditionValue?: number
  isMeasureRangeFilterDisabled: boolean
  isFirstConditionDisabled: boolean
  isSecondConditionDisabled: boolean
  hasFirstConditionValueError: boolean
  hasSecondConditionValueError: boolean
}

export interface MeasureRangeFilterHook {
  ranges: MeasureRangeFilter[]
  rangeFilters: DropdownValueProps[] | undefined
  hasErrors: boolean
  addRange: () => void
  deleteRange: () => void
  onMeasureFilterChange: (value: DropdownValueProps, id: string) => void
  onFirstConditionChange: (selection: DropdownValueProps) => void
  onSecondConditionChange: (selection: DropdownValueProps) => void
  onFirstConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onSecondConditionValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void
  onFirstConditionToggleChange: (id: string) => void
  onSecondConditionToggleChange: (id: string) => void
}

/**
 * @description A Custom Hook to pair with the `MeasureRangeFilter`.
 * The hook can manage the `MeasureRangeFilter` states.
 * @param rangeFilters Measure Range Filters
 * @returns object of MeasureRangeFilterHook
 */
const useMeasureRangeFilters = (
  rangeFilters: DropdownValueProps[],
  selectedRange?: MeasureRangeFilter[],
) => {
  const [filters, setFilters] = useState<DropdownValueProps[]>(rangeFilters)
  const [ranges, setRanges] = useState<MeasureRangeFilter[]>(
    selectedRange || [],
  )
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const item = {
    isMeasureRangeFilterDisabled: filters.length === 1,
    isFirstConditionDisabled: false,
    isSecondConditionDisabled: false,
    hasFirstConditionValueError: true,
    hasSecondConditionValueError: true,
  }

  function validateErrors() {
    const containErrors = ranges.some(
      (range) =>
        (range.hasFirstConditionValueError &&
          !range.isFirstConditionDisabled) ||
        (range.hasSecondConditionValueError &&
          !range.isSecondConditionDisabled) ||
        !range.selectedMeasureRangeFilterOption ||
        (!range.selectedFirstConditionOption &&
          !range.isFirstConditionDisabled) ||
        (!range.selectedSecondConditionOption &&
          !range.isSecondConditionDisabled),
    )
    setHasErrors(containErrors)
  }

  function onMeasureFilterChange(value: DropdownValueProps, id: string) {
    setRanges((prev) =>
      prev.map((r) =>
        r.id === id ? { ...r, selectedMeasureRangeFilterOption: value } : r,
      ),
    )
  }

  function onFirstConditionChange(value: DropdownValueProps, id: string) {
    setRanges((prev) =>
      prev.map((r) =>
        r.id === id ? { ...r, selectedFirstConditionOption: value } : r,
      ),
    )
  }

  function onSecondConditionChange(value: DropdownValueProps, id: string) {
    setRanges((prev) =>
      prev.map((r) =>
        r.id === id ? { ...r, selectedSecondConditionOption: value } : r,
      ),
    )
  }

  function onFirstConditionValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) {
    setRanges((prev) =>
      prev.map((r) => {
        if (r.id !== id) {
          return r
        }
        const containErrors = e.target.value === ''
        return {
          ...r,
          firstConditionValue: !containErrors
            ? parseFloat(e.target.value)
            : undefined,
          firstConditionValueError: containErrors
            ? 'Min Value is Required'
            : undefined,
          hasFirstConditionValueError: containErrors,
        }
      }),
    )
  }

  function onSecondConditionValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) {
    setRanges((prev) =>
      prev.map((r) => {
        if (r.id !== id) {
          return r
        }
        const containErrors = e.target.value === ''
        return {
          ...r,
          secondConditionValue: !containErrors
            ? parseFloat(e.target.value)
            : undefined,
          secondConditionValueError: containErrors
            ? 'Max Value is Required'
            : undefined,
          hasSecondConditionValueError: containErrors,
        }
      }),
    )
  }

  function onFirstConditionToggleChange(id: string) {
    setRanges((prev) =>
      prev.map((r) =>
        r.id === id
          ? {
              ...r,
              isFirstConditionDisabled: !r.isFirstConditionDisabled,
              isSecondConditionDisabled: false,
            }
          : r,
      ),
    )
  }

  function onSecondConditionToggleChange(id: string) {
    setRanges((prev) =>
      prev.map((r) =>
        r.id === id
          ? {
              ...r,
              isSecondConditionDisabled: !r.isSecondConditionDisabled,
              isFirstConditionDisabled: false,
            }
          : r,
      ),
    )
  }

  function addRange() {
    setRanges([...ranges, { id: uuidv4(), ...item }])
  }

  function deleteRange(id: string) {
    setRanges(ranges.filter((p: MeasureRangeFilter) => p.id !== id))
  }

  useEffect(() => {
    validateErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges])

  useEffect(() => {
    if (rangeFilters) {
      setFilters(rangeFilters)
    }
  }, [rangeFilters])

  return {
    filters,
    ranges,
    hasErrors,
    addRange,
    deleteRange,
    onMeasureFilterChange,
    onFirstConditionChange,
    onSecondConditionChange,
    onFirstConditionValueChange,
    onSecondConditionValueChange,
    onFirstConditionToggleChange,
    onSecondConditionToggleChange,
  }
}

export default useMeasureRangeFilters
