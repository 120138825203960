import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { round } from 'mathjs'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWDropdown, {
  DropdownValueProps,
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import IWRadioButton from 'shared/components/thunderbolt/IWRadioButton'
import useMeasureRangeConditions, {
  MeasureCondition,
} from 'shared/hooks/useMeasureRangeConditions'
import { useQuery } from 'react-query'
import useDebouncer from 'shared/hooks/useDebouncer'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import IWTable, { Column } from 'shared/components/thunderbolt/IWTable'
import {
  formatAttributeFilters,
  formatMeasureConditionsFilters,
} from 'tools/insightsManager/helpers'
import {
  getAlertPreview,
  getDatasourceMetadata,
  getTopColumnValues,
  getValidDomainDataType,
  searchColumnValues,
} from 'shared/podServiceClient'
import { IWSmallLoading } from 'shared/components/thunderbolt/IWLoading'
import { AttributeFilter, PodType } from '../../insightsManager/types'
import {
  AggregationFn,
  AlertCreateSchema,
  Comparison,
  TimeGroup,
} from '../types'
import AttributeFilterGroup from '../../insightsManager/components/AttributeFilterGroup'
import useAttributeFilters from '../../insightsManager/hooks/useAttributeFilters'
import MeasureRangeFilterWrapper from './MeasureRangeFilterWrapper'
import TimeSeriesSummary from './TimeSeriesSummary'
import { mapToDropdownOptions } from '../utils/utils'
import LayoutContext from 'shared/contexts/LayoutContext'
import { WizardStepProps } from '../../../shared/types'

const StyledRowSection = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    flex: 1;
  }
`

const StyledMeasureConditionsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

type TriggerAlert = {
  selectedTimeSeriesGroupBy?: GenericDropdownValue<TimeGroup>
  selectedTimeSeriesAggregation?: GenericDropdownValue<AggregationFn>
  selectedSplitAnalysis?: DropdownValueProps[]
  selectedComparison?: Comparison
  selectedComparisonPeriod?: GenericDropdownValue<number>
  selectedConditionMatch?: 'matchAll' | 'matchAny'
  attributeFilters?: AttributeFilter[]
  measures?: MeasureCondition[]
}

export type Props = WizardStepProps<TriggerAlert | undefined>

export default function TriggersAlertStep({
  datasource,
  userPodId,
  podType,
  state,
  onChange,
  showErrors,
}: Props & { datasource: string; userPodId: string; podType: PodType }) {
  const { t } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const [isAlertPreviewFetching, setIsAlertPreviewFetching] = useState(false)
  const [isAlertValid, setIsAlertValid] = useState(false)
  const [metricOptions, setMetricOptions] = useState<DropdownValueProps[]>([])
  const [defaultTimeSeriesGroupByOption] = mapToDropdownOptions<TimeGroup>([
    'hourAll',
    'dayAll',
  ])
  const [defaultTimeSeriesAggregationOption] =
    mapToDropdownOptions<AggregationFn>(['sum', 'min', 'max'])
  const [splitAnalysisOptions, setSplitAnalysisOptions] = useState<
    DropdownValueProps[]
  >([])
  const [currentState, setCurrentState] = useState<TriggerAlert | undefined>({
    ...state,
    selectedTimeSeriesGroupBy:
      state?.selectedTimeSeriesGroupBy || defaultTimeSeriesGroupByOption,
    selectedTimeSeriesAggregation:
      state?.selectedTimeSeriesAggregation ||
      defaultTimeSeriesAggregationOption,
    selectedSplitAnalysis: state?.selectedSplitAnalysis,
    selectedComparison: state?.selectedComparison || 'noComparison',
    selectedComparisonPeriod: state?.selectedComparisonPeriod,
    selectedConditionMatch: state?.selectedConditionMatch || 'matchAll',
    measures: state?.measures || [],
  })
  const [previewTableData, setPreviewTableData] = useState<{
    columns: Column[]
    data: object[]
  }>({ columns: [], data: [] })

  const { debounce } = useDebouncer()

  const {
    filters: attributeSectionFilters,
    deleteFilter,
    addFilter,
    updateFilter,
    initialized,
    conditions,
    hasErrors: hasAttributeFiltersErrors,
  } = useAttributeFilters(currentState?.attributeFilters || [])

  const {
    conditions: measureConditions,
    selectedMeasureOption,
    selectedValueTypeOption,
    addMeasureCondition,
    deleteMeasureCondition,
    clearMeasureConditions,
    onMeasureConditionOptionChange,
    onValueTypeOptionChange,
    onFirstConditionChange,
    onSecondConditionChange,
    onFirstConditionValueChange,
    onSecondConditionValueChange,
  } = useMeasureRangeConditions(metricOptions, currentState?.measures)

  const handleTimeSeriesConfirm = (
    selectedTimeSeriesGroupBy: GenericDropdownValue<TimeGroup>,
    selectedTimeSeriesAggregation: GenericDropdownValue<AggregationFn>,
  ) => {
    setCurrentState((prevState) => ({
      ...prevState,
      selectedTimeSeriesGroupBy,
      selectedTimeSeriesAggregation,
    }))
  }

  const handleSplitAnalysisChange = (selectedOption: DropdownValueProps[]) => {
    setCurrentState((prevState) => ({
      ...prevState,
      selectedSplitAnalysis: selectedOption,
    }))
  }

  const handleConditionMatchChange = (e) => {
    setCurrentState((prevState) => ({
      ...prevState,
      selectedConditionMatch: e.target.value,
    }))
  }

  const getInitialAttributeValues = async (attributeType) => {
    if (!datasource) {
      return []
    }
    return (await getTopColumnValues(datasource, attributeType))
      .filter((f) => f)
      .map((att) => ({
        label: att,
        value: att,
      }))
  }

  const searchAttributeValues = async (
    columnName: string,
    searchValue: string,
  ): Promise<DropdownValueProps[]> => {
    if (!datasource) {
      return []
    }
    return (await searchColumnValues(datasource, columnName, searchValue))
      .filter((f) => f)
      .map((att) => ({
        label: att,
        value: att,
      }))
  }

  const isStepValid = () => {
    if (currentState === undefined) {
      return false
    }

    const {
      selectedTimeSeriesGroupBy,
      selectedTimeSeriesAggregation,
      selectedComparison,
      selectedComparisonPeriod,
    } = currentState

    const isTimeSeriesValid = !!(
      selectedTimeSeriesGroupBy && selectedTimeSeriesAggregation
    )

    const isComparisonValid =
      !!(
        selectedComparison === 'compareGenerationTimes' &&
        selectedComparisonPeriod
      ) || selectedComparison === 'noComparison'

    const isAttributesValid =
      attributeSectionFilters?.length === 0 ||
      (attributeSectionFilters?.length > 0 &&
        !hasAttributeFiltersErrors.some((element) =>
          Object.keys(element).some((k) => element[k]),
        ))

    const isMeasureConditionsValid =
      measureConditions.length > 0 ||
      measureConditions.some(
        (m) =>
          !m.hasFirstConditionValueError && !m.hasSecondConditionValueError,
      )
    return (
      isTimeSeriesValid &&
      isComparisonValid &&
      isAttributesValid &&
      isMeasureConditionsValid
    )
  }

  const { data: datasourceMetadata } = useQuery(
    `trigger-step-meta-${datasource}`,
    () =>
      getDatasourceMetadata(datasource).then((res) => {
        const { columns } = res
        return Object.entries(columns).reduce(
          (memo, current) => {
            const [key, value] = current
            const item = { label: key, value: key, isDisabled: false }
            if (value.type === 'string') {
              memo.attributes.push(item)
            }
            if (value.type === 'number') {
              memo.metrics.push(item)
            }
            return memo
          },
          {
            attributes: [] as DropdownValueProps[],
            metrics: [] as DropdownValueProps[],
          },
        )
      }),
  )

  const { data: processTimes } = useQuery(
    `trigger-step-process-times-${datasource}`,
    () =>
      getTopColumnValues(datasource, '__time').then((res) => {
        return Array.from({ length: res.length - 1 }, (_, i) => {
          const value = i + 1
          return {
            label: t(
              'alertsManager.createAlert.triggerStep.processTimes.option',
              { count: value },
            ),
            value,
          }
        })
      }),
  )

  const handleComparisonChange = (e) => {
    const newComparisonPeriod =
      e.target.value === 'compareGenerationTimes' && processTimes !== undefined
        ? processTimes[0]
        : undefined

    setCurrentState((prevState) => ({
      ...prevState,
      selectedComparison: e.target.value as Comparison,
      selectedComparisonPeriod: newComparisonPeriod,
    }))

    clearMeasureConditions()
  }

  const getPreviewAlerts = () => {
    // set alert flags to display the alert
    // preview is being process
    setIsAlertPreviewFetching(true)
    setIsAlertValid(false)

    debounce(() => {
      if (!currentState || currentState.measures === undefined) {
        onChange(currentState, false)
        return
      }
      const {
        measures,
        selectedTimeSeriesAggregation,
        selectedTimeSeriesGroupBy,
        selectedSplitAnalysis,
        attributeFilters,
        selectedComparisonPeriod,
        selectedConditionMatch,
      } = currentState
      const hasAggregationFn = selectedTimeSeriesAggregation !== undefined
      const hasTimeGroup = selectedTimeSeriesGroupBy !== undefined
      const hasMeasures = measures.length

      if (!(hasMeasures && hasAggregationFn && hasTimeGroup)) {
        setIsAlertPreviewFetching(false)
        setIsAlertValid(false)
        return
      }

      const alertPreviewBody: Omit<
        AlertCreateSchema,
        'userAlertName' | 'recipients' | 'isPaused'
      > = {
        userPodId,
        timezone,
        aggregationFnId: selectedTimeSeriesAggregation.value,
        timeGroupId: selectedTimeSeriesGroupBy.value,
        alertConditions: formatMeasureConditionsFilters(measures),
        attributeFilters: formatAttributeFilters(attributeFilters),
        timeOffset: selectedComparisonPeriod?.value,
        splits: selectedSplitAnalysis
          ? selectedSplitAnalysis.map(({ value }) => value)
          : undefined,
        shouldMatchAllConditions: Boolean(
          selectedConditionMatch === 'matchAll',
        ),
      }
      getAlertPreview(alertPreviewBody)
        .then((res) => {
          const columns: Column[] = []
          const dateColumns: string[] = []
          const numberColumns: string[] = []
          Object.entries(res.columns).forEach((col) => {
            const domainDataType = getValidDomainDataType(col[0], col[1].type)
            columns.push({
              title: col[0],
              accessor: col[0],
              align: domainDataType === 'number' ? 'right' : 'left',
            })
            if (
              selectedTimeSeriesGroupBy.value === 'hour24' &&
              domainDataType === 'datetime'
            ) {
              numberColumns.push(col[0])
            } else if (domainDataType === 'number') {
              numberColumns.push(col[0])
            } else if (domainDataType === 'datetime') {
              dateColumns.push(col[0])
            }
          })

          const data = res.data.map((elem) => {
            const shallowCopy = { ...elem }
            dateColumns.forEach((col) => {
              shallowCopy[col] = DateTime.fromISO(shallowCopy[col] as string, {
                zone: timezone,
              }).toLocaleString(DateTime.DATETIME_SHORT)
            })
            numberColumns.forEach((col) => {
              shallowCopy[col] = Number(round(shallowCopy[col] as number, 2))
            })
            return shallowCopy
          })
          setPreviewTableData({ columns, data })
          setIsAlertValid(true)
          onChange(currentState, isStepValid())
        })
        .catch(() => {
          setIsAlertValid(false)
          onChange(currentState, false)
        })
        .finally(() => {
          setIsAlertPreviewFetching(false)
        })
    }, 2000)
  }

  useEffect(() => {
    onChange(currentState, false)
    getPreviewAlerts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState])

  useEffect(() => {
    setCurrentState((prevState) => ({
      ...prevState,
      attributeFilters: attributeSectionFilters,
    }))
  }, [attributeSectionFilters])

  useEffect(() => {
    setCurrentState((prevState) => ({
      ...prevState,
      measures: measureConditions,
    }))
  }, [measureConditions])

  useEffect(() => {
    if (!datasourceMetadata) {
      return
    }
    setSplitAnalysisOptions(datasourceMetadata.attributes)
    setMetricOptions([...datasourceMetadata.metrics])
  }, [datasourceMetadata])

  return (
    <>
      <IWFormSection
        sectionTitle={t(
          `alertsManager.createAlert.triggerStep.dataAnalysisTittle`,
        )}
      >
        <StyledRowSection>
          <TimeSeriesSummary
            selectedTimeSeriesGroupBy={currentState?.selectedTimeSeriesGroupBy}
            selectedTimeSeriesAggregation={
              currentState?.selectedTimeSeriesAggregation
            }
            onConfirm={handleTimeSeriesConfirm}
            showErrors={showErrors}
          />
          <IWDropdown
            isMulti
            options={splitAnalysisOptions}
            value={currentState?.selectedSplitAnalysis}
            label={t(
              `alertsManager.createAlert.triggerStep.splitAnalysisTitle`,
            )}
            onChange={handleSplitAnalysisChange}
            isOptionDisabled={() =>
              currentState &&
              currentState.selectedSplitAnalysis &&
              currentState?.selectedSplitAnalysis?.length >= 3
            }
            helperText={t(
              `alertsManager.createAlert.triggerStep.splitAnalysisHelpDesc`,
            )}
          />
        </StyledRowSection>

        <IWFormSection
          sectionTitle={t(`attributeFilterSection.attributeFilters`)}
          sectionDescription={t(
            `attributeFilterSection.attributeFiltersDescription`,
          )}
        >
          <AttributeFilterGroup
            updateFilter={updateFilter}
            showErrors={showErrors}
            filters={attributeSectionFilters}
            deleteFilter={deleteFilter}
            addFilter={addFilter}
            attributeTypes={splitAnalysisOptions}
            initialized={initialized}
            conditions={conditions}
            getInitialAttributeValues={getInitialAttributeValues}
            searchAttributeValues={searchAttributeValues}
            hasErrors={hasAttributeFiltersErrors}
          />
        </IWFormSection>
        {podType.insightId !== 'shortTermForecastPerformance' && (
          <IWFormSection
            sectionTitle={t(
              'alertsManager.createAlert.triggerStep.comparisonDefinitionTitle',
            )}
            sectionDescription={t(
              'alertsManager.createAlert.triggerStep.comparisonDefinitionDesc',
            )}
          >
            <StyledRowSection>
              <IWRadioButton
                id="comparison-definition-opt1"
                value="noComparison"
                label={t('alertsManager.createAlert.triggerStep.noComparison')}
                name="comparison-definition"
                onChange={handleComparisonChange}
                checked={currentState?.selectedComparison === 'noComparison'}
              />
              <IWRadioButton
                id="comparison-definition-opt2"
                value="compareGenerationTimes"
                label={t(
                  'alertsManager.createAlert.triggerStep.compareGenerationTimes',
                )}
                name="comparison-definition"
                disabled={
                  processTimes === undefined || processTimes.length === 0
                }
                onChange={handleComparisonChange}
                checked={
                  currentState?.selectedComparison === 'compareGenerationTimes'
                }
              />
            </StyledRowSection>
            {currentState?.selectedComparison === 'compareGenerationTimes' && (
              <IWDropdown
                fullWidth
                options={processTimes}
                value={currentState?.selectedComparisonPeriod}
                label={t(
                  `alertsManager.createAlert.triggerStep.comparisonDropdownLabel`,
                )}
                onChange={(option) =>
                  setCurrentState(() => ({
                    ...currentState,
                    selectedComparisonPeriod: option,
                  }))
                }
                hasError={
                  showErrors &&
                  !currentState?.selectedComparisonPeriod &&
                  currentState?.selectedComparison === 'compareGenerationTimes'
                }
                errorText={
                  showErrors &&
                  !currentState?.selectedComparisonPeriod &&
                  currentState?.selectedComparison === 'compareGenerationTimes'
                    ? t(`alertsManager.errors.enterAllRequired`)
                    : undefined
                }
              />
            )}
          </IWFormSection>
        )}
      </IWFormSection>
      <IWFormSection
        sectionTitle={t(
          'alertsManager.createAlert.triggerStep.conditionsTitle',
        )}
        sectionDescription={t(
          'alertsManager.createAlert.triggerStep.conditionsDesc',
        )}
      >
        <StyledRowSection>
          <IWRadioButton
            id="condition-match-all-radio"
            value="matchAll"
            label={t('alertsManager.createAlert.triggerStep.matchAll')}
            name="match-conditions"
            onChange={handleConditionMatchChange}
            checked={currentState?.selectedConditionMatch === 'matchAll'}
          />
          <IWRadioButton
            id="condition-match-any-radio"
            value="matchAny"
            label={t('alertsManager.createAlert.triggerStep.matchAny')}
            name="match-conditions"
            onChange={handleConditionMatchChange}
            checked={currentState?.selectedConditionMatch === 'matchAny'}
          />
        </StyledRowSection>

        {metricOptions.length > 0 && (
          <StyledMeasureConditionsSection>
            <MeasureRangeFilterWrapper
              measureConditions={measureConditions}
              measureOptions={metricOptions}
              addMeasureCondition={addMeasureCondition}
              onMeasureChange={onMeasureConditionOptionChange}
              deleteMeasureCondition={deleteMeasureCondition}
              selectedMeasureOption={selectedMeasureOption}
              selectedValueTypeOption={selectedValueTypeOption}
              selectedComparison={currentState?.selectedComparison}
              onValueTypeChange={onValueTypeOptionChange}
              onFirstConditionChange={onFirstConditionChange}
              onSecondConditionChange={onSecondConditionChange}
              onFirstConditionValueChange={onFirstConditionValueChange}
              onSecondConditionValueChange={onSecondConditionValueChange}
              showErrors={showErrors}
            />
          </StyledMeasureConditionsSection>
        )}
      </IWFormSection>
      <IWFormSection
        sectionTitle={
          <>
            {t(`alertsManager.createAlert.triggerStep.preview`)}{' '}
            {isAlertPreviewFetching && <IWSmallLoading />}
          </>
        }
      >
        {isAlertValid ? (
          <>
            <IWAlert
              label={t('alertsManager.createAlert.triggerStep.previewDesc', {
                count: previewTableData.data.length,
              })}
              color="grey"
              variant="alternative"
            />
            <IWTable
              columns={previewTableData.columns}
              data={previewTableData.data}
            />
          </>
        ) : (
          <IWAlert
            label={`${
              isAlertPreviewFetching
                ? t('alertsManager.createAlert.triggerStep.fetching')
                : t('alertsManager.createAlert.triggerStep.invalidAlert')
            }`}
            color="alert"
            variant="alternative"
          />
        )}
      </IWFormSection>
    </>
  )
}
