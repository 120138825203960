import React from 'react'
import styled from 'styled-components'
import IWInputLabel from 'shared/components/thunderbolt/IWCommonLabels'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { useTranslation } from 'react-i18next'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import IWRadioButton from 'shared/components/thunderbolt/IWRadioButton'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import {
  AggregationFn,
  AlertCondition,
  AttributeFilter,
  TimeGroup,
} from '../types'
import { getAlertTimeSeriesLabel } from '../utils/utils'

const StyledRowSection = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    flex: 1;
  }
`

const SingleRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const StyledIWProductCard = styled(IWProductCard)`
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 6px;
  padding: 1rem;
`

const MultipleAttributeSection = styled.div`
  width: 100%;
  border-radius: 0.375rem;
  padding: 1rem;
  background: ${(props) => props.theme.palette.grey[50]};
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
`

const SingleRow = styled.div`
  display: flex;
  flex-direction: row;
`

const SingleValueDisplay = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  gap: 0.5rem;
  text-transform: capitalize;
`

const StyledText = styled(IWTypography)`
  font-size: ${(props) => props.theme.typography.fontSizes.sm};
  color: ${(props) => props.theme.palette.grey[600]};
`

type Props = {
  aggregationFnId: AggregationFn
  timeGroupId: TimeGroup
  splits?: string[]
  attributeFilters?: AttributeFilter[]
  timeOffset?: number
  alertConditions: AlertCondition[]
  shouldMatchAllConditions: boolean
  recipients?: string[]
  userAlertName: string
  isPaused: boolean
}

export default function AlertDetailsSummary({
  aggregationFnId,
  timeGroupId,
  splits,
  attributeFilters,
  timeOffset,
  alertConditions,
  shouldMatchAllConditions,
  recipients,
  userAlertName,
  isPaused,
}: Props) {
  const { t } = useTranslation()
  return (
    <>
      <IWFormSection
        sectionTitle={t(
          `alertsManager.createAlert.triggerStep.dataAnalysisTittle`,
        )}
      >
        <StyledRowSection>
          {timeGroupId && aggregationFnId && (
            <div>
              <IWInputLabel
                label={t(
                  'alertsManager.createAlert.triggerStep.timeSeriesTitle',
                )}
              />
              <StyledText>
                {getAlertTimeSeriesLabel(timeGroupId, aggregationFnId, t)}
              </StyledText>
            </div>
          )}

          <div>
            <IWInputLabel
              label={t(
                'alertsManager.createAlert.triggerStep.splitAnalysisTitle',
              )}
            />
            <StyledText>
              {splits && Boolean(splits.length)
                ? splits.join(', ')
                : t('alertsManager.createAlert.triggerStep.noOptionSelected')}
            </StyledText>
          </div>
        </StyledRowSection>
      </IWFormSection>

      {Boolean(attributeFilters?.length) && (
        <IWFormSection
          sectionTitle={t(`attributeFilterSection.attributeFilters`)}
        >
          {attributeFilters?.map(({ attribute, includes, values }) => (
            <MultipleAttributeSection key={attribute}>
              <SingleRow>
                <SingleValueDisplay>
                  <IWInputLabel
                    required
                    label={t(`attributeFilterSection.attribute`)}
                  />
                  <StyledText>{attribute}</StyledText>
                </SingleValueDisplay>
                <SingleValueDisplay>
                  <IWInputLabel
                    required
                    label={t(`attributeFilterSection.condition`)}
                  />
                  <StyledText>
                    {includes
                      ? t(`attributeFilterSection.conditionsEqual`)
                      : t(`attributeFilterSection.conditionsNotEqual`)}
                  </StyledText>
                </SingleValueDisplay>

                <SingleValueDisplay>
                  <IWInputLabel required label={t(`common.value`)} />
                  <IWBadgeGroup>
                    {values.map((val) => (
                      <IWGeneralBadge key={val} label={val} color="grey" />
                    ))}
                  </IWBadgeGroup>
                </SingleValueDisplay>
              </SingleRow>
            </MultipleAttributeSection>
          ))}
        </IWFormSection>
      )}

      <IWFormSection
        sectionTitle={t(
          'alertsManager.createAlert.triggerStep.comparisonDefinitionTitle',
        )}
        sectionDescription={t(
          'alertsManager.createAlert.triggerStep.comparisonDefinitionDesc',
        )}
      >
        <StyledRowSection>
          <IWRadioButton
            id="comparison-definition-opt1"
            value="noComparison"
            label={t('alertsManager.createAlert.triggerStep.noComparison')}
            name="comparison-definition"
            disabled
            checked={timeOffset === undefined}
          />
          <IWRadioButton
            id="comparison-definition-opt2"
            value="compareGenerationTimes"
            label={t(
              'alertsManager.createAlert.triggerStep.compareGenerationTimes',
            )}
            name="comparison-definition"
            disabled
            checked={timeOffset !== undefined}
          />
        </StyledRowSection>
        {timeOffset !== undefined && (
          <SingleValueDisplay>
            <IWInputLabel
              required
              label={t(
                `alertsManager.createAlert.triggerStep.comparisonDropdownLabel`,
              )}
            />
            <StyledText>
              {t('alertsManager.createAlert.triggerStep.processTimes.option', {
                count: timeOffset,
              })}
            </StyledText>
          </SingleValueDisplay>
        )}
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(
          'alertsManager.createAlert.triggerStep.conditionsTitle',
        )}
        sectionDescription={t(
          'alertsManager.createAlert.triggerStep.conditionsDesc',
        )}
      >
        <StyledRowSection>
          <IWRadioButton
            id="condition-match-all-radio"
            value="matchAll"
            label={t('alertsManager.createAlert.triggerStep.matchAll')}
            name="match-conditions"
            disabled
            checked={shouldMatchAllConditions}
          />
          <IWRadioButton
            id="condition-match-any-radio"
            value="matchAny"
            label={t('alertsManager.createAlert.triggerStep.matchAny')}
            name="match-conditions"
            disabled
            checked={!shouldMatchAllConditions}
          />
        </StyledRowSection>
      </IWFormSection>

      {Boolean(alertConditions.length) && (
        <>
          <IWFormSection sectionTitle={t(`rangeFilterSection.rangeFilters`)}>
            <StyledText fontHue={{ color: 'grey', value: 500 }}>
              {t(`rangeFilterSection.rangeFiltersDescription`)}
            </StyledText>
            {alertConditions.map((alertCondition) => (
              <MultipleAttributeSection
                key={`${alertCondition.attribute}-${alertCondition.valueType}`}
              >
                <SingleRowContainer>
                  <SingleRow>
                    <SingleValueDisplay>
                      <IWInputLabel
                        required
                        label={t(
                          `alertsManager.createAlert.triggerStep.measureCondition.measure`,
                        )}
                      />
                      <StyledText>{alertCondition.attribute}</StyledText>
                    </SingleValueDisplay>
                  </SingleRow>
                  <SingleRow>
                    <SingleValueDisplay>
                      <IWInputLabel
                        required
                        label={t(
                          `alertsManager.createAlert.triggerStep.measureCondition.valueType`,
                        )}
                      />
                      <StyledText>{alertCondition.valueType}</StyledText>
                    </SingleValueDisplay>
                  </SingleRow>
                  {alertCondition.conditions.map((condition) => (
                    <SingleRow>
                      <SingleValueDisplay>
                        <IWInputLabel
                          required
                          label={t(`rangeFilterSection.condition`)}
                        />
                        <StyledText>
                          {t(
                            `rangeFilterSection.options.${condition.operator}`,
                          )}
                        </StyledText>
                      </SingleValueDisplay>

                      <SingleValueDisplay>
                        <IWInputLabel
                          required
                          label={t(`rangeFilterSection.minValue`)}
                        />
                        <StyledText>{condition.value}</StyledText>
                      </SingleValueDisplay>
                    </SingleRow>
                  ))}
                </SingleRowContainer>
              </MultipleAttributeSection>
            ))}
          </IWFormSection>
        </>
      )}

      {Boolean(recipients?.length) && (
        <IWFormSection
          sectionTitle={t(
            `alertsManager.createAlert.actionsStep.summaryDetailsHeader`,
          )}
          sectionDescription={t(
            `alertsManager.createAlert.actionsStep.summaryDetailsDesc`,
          )}
        >
          <StyledIWProductCard
            header={t(`alertsManager.createAlert.actionsStep.sectionTitle`)}
            description={t(
              'alertsManager.createAlert.actionsStep.summaryProductDesc',
              {
                userList: recipients?.join(', '),
              },
            )}
            icon={faBell}
            iconColor="grey"
          />
        </IWFormSection>
      )}

      <IWFormSection
        sectionTitle={t(`alertsManager.createAlert.generalStep.generalTitle`)}
      >
        <IWInputLabel
          label={t('alertsManager.createAlert.generalStep.alertNameLabel')}
        />
        <StyledText>{userAlertName}</StyledText>
      </IWFormSection>

      <IWFormSection
        sectionTitle={t(
          `alertsManager.createAlert.generalStep.schedulingTitle`,
        )}
        sectionDescription={t(
          `alertsManager.createAlert.generalStep.schedulingDesc`,
        )}
      >
        <IWToggle
          label={t('alertsManager.createAlert.generalStep.pauseAlert')}
          id="pauseAlert"
          checked={isPaused}
          disabled
        />
      </IWFormSection>
    </>
  )
}
