import React, { SyntheticEvent, ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { rgba } from 'polished'

const fade = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`

const StyledBackdrop = styled.div`
  background-color: ${(props) => rgba(props.theme.palette.grey[900], 0.6)};
  z-index: ${(props) => props.theme.layers.backdrop};
  position: fixed;
  inset: 0;
  animation: ${fade} 100ms ease-in-out;
`

interface Props {
  children: ReactNode | ReactNode[]
  onClick?: (e: SyntheticEvent) => void
}

const IWBackdrop = React.forwardRef(
  ({ children, onClick, ...props }: Props, ref: React.Ref<HTMLDivElement>) => {
    return (
      <StyledBackdrop
        ref={ref}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick) {
            onClick(e)
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </StyledBackdrop>
    )
  },
)

export default IWBackdrop
