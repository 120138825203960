import { string, number, object, ZodString, ZodNumber } from 'zod'
import {
  ImportManagerSchema,
  ImportManagerSourceSchema,
  Mapping,
} from './types'

export const convertImportedDataForComparison = (
  importedData: Record<string, string>[] = [],
): ImportManagerSourceSchema[] => {
  const rowsArray: string[][] = []
  const valuesArray: string[][] = []

  ;(importedData as object[]).forEach((item: object) => {
    rowsArray.push(Object.keys(item))
    valuesArray.push(Object.values(item))
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstRow, ...d] = rowsArray

  return firstRow
    ? firstRow.map((columnName, i) => ({
        columnName,
        columnIndex: i,
        sampleData: valuesArray.map((v) => v[i]),
      }))
    : []
}

export const processUserInputFileDataToMapWithSelectedFileSchema = (
  importedData: Record<string, string>[],
  innowattsSchema: ImportManagerSchema,
) => {
  const selectedFileDataWithSampleDataAndComparisonOptions =
    convertImportedDataForComparison(importedData || [])
  const innowattsSchemaTransformedToSelectedFile = { ...innowattsSchema }

  selectedFileDataWithSampleDataAndComparisonOptions.forEach((item) => {
    const fromColumnName = item.columnName
    const toSourceColumnIndex = item.columnIndex

    if (
      innowattsSchemaTransformedToSelectedFile &&
      innowattsSchemaTransformedToSelectedFile?.mapping
    ) {
      const indexOfMapping =
        innowattsSchemaTransformedToSelectedFile?.mapping.findIndex(
          ({ targetColumnName }) => targetColumnName === fromColumnName,
        )
      if (indexOfMapping >= 0) {
        innowattsSchemaTransformedToSelectedFile.mapping[
          indexOfMapping
        ].sourceColumn = toSourceColumnIndex
      }
    }
  })

  return {
    selectedFileSchema: selectedFileDataWithSampleDataAndComparisonOptions,
    selectedSchema: innowattsSchemaTransformedToSelectedFile,
  }
}

export function createZodObject(mapping: Mapping[]) {
  const zodSchema: Record<string, ZodString | ZodNumber> = {}

  for (const map of mapping) {
    let zodType: ZodString | ZodNumber | undefined

    switch (map.options.type) {
      case 'string':
        zodType = string()
        break
      case 'number':
        zodType = number()
        if (map.options.min !== undefined) {
          zodType = zodType.min(map.options.min)
        }
        if (map.options.max !== undefined) {
          zodType = zodType.max(map.options.max)
        }
        break
      case 'datetime':
        zodType = string() // Assuming datetime is a string representation
        break
      case 'date':
        zodType = string() // Assuming date is a string representation
        break
      default:
        throw new Error(`Invalid type provided for ${map.targetColumnName}`)
    }

    if (zodType) {
      zodSchema[map.targetColumnName] = zodType
    }
  }

  return object(zodSchema)
}
