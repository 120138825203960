import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Popover } from 'react-tiny-popover'
import getDatePickerDisplayString from 'shared/utils/getDatePickerDisplayString'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import IWRelativeDatePicker, {
  Props as IWRelativeDatePickerProps,
} from './IWRelativeDatePicker'
import { IWInputPlaceholder } from './IWTextInput'
import IWButton from './IWButton'

const StyledPopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
  min-width: 400px;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

interface Props extends IWRelativeDatePickerProps {
  label?: string
  onConfirm: () => void
  onCancel: () => void
  onPlaceholderClick: () => void
  isOpen: boolean
  hasError?: boolean
}

const IWRelativeDatePickerWrapper = ({
  label,
  onConfirm,
  onCancel,
  onPlaceholderClick,
  isOpen,
  hasError,
  ...state
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { selectedMainOption } = state
  const [summary, setSummary] = useState<string | undefined>()

  useEffect(() => {
    if (isOpen || !selectedMainOption) {
      return
    }
    const summaryString = getDatePickerDisplayString(state, t)
    setSummary(summaryString)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Popover
      padding={10}
      reposition={false}
      positions={['bottom']}
      containerStyle={{ zIndex: theme.layers.popover }}
      align="start"
      isOpen={isOpen}
      onClickOutside={onCancel}
      content={() => {
        return (
          <StyledPopupDiv onClick={(e) => e.stopPropagation()}>
            <IWRelativeDatePicker
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...state}
            />
            <StyledButtonDiv>
              <IWButton variant="alternative" onClick={onCancel}>
                {t(`button.cancel`)}
              </IWButton>
              <IWButton
                data-testid="relative-date-picker-confirm"
                onClick={onConfirm}
              >
                {t(`button.confirm`)}
              </IWButton>
            </StyledButtonDiv>
          </StyledPopupDiv>
        )
      }}
    >
      <IWInputPlaceholder
        data-testid="relative-date-picker-placeholder"
        placeholder={t('relativeDatePicker.placeholders.main')}
        leadingIcon={{ icon: faCalendar }}
        label={label || t('relativeDatePicker.labels.wrapper')}
        onClick={onPlaceholderClick}
        hasError={hasError}
      >
        {summary}
      </IWInputPlaceholder>
    </Popover>
  )
}

export default IWRelativeDatePickerWrapper
