import React, { SyntheticEvent, useContext } from 'react'
import { t } from 'i18next'
import IWPaginationControls, {
  Props as IWPaginationProps,
} from 'shared/components/thunderbolt/IWPaginationControls'
import IWTable, { IWTableProps } from 'shared/components/thunderbolt/IWTable'
import { PaginatedData, paginationOptions } from 'shared/types'
import {
  faPenToSquare,
  faSync,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import IWButton from 'shared/components/thunderbolt/IWButton'
import styled from 'styled-components'
import { SFTPCredential } from 'systemPreferences/integrationsServiceClient'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import LayoutContext from 'shared/contexts/LayoutContext'

const ActionButtonsWrap = styled.div`
  display: flex;
  gap: 0.25rem;
`

const PaginationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  padding: 0.5rem;
  justify-content: right;
`

interface Props {
  onSort: IWTableProps['onSort']
  sort: IWTableProps['sortedBy']
  columns: IWTableProps['columns']
  data?: PaginatedData<SFTPCredential>
  page: {
    current: number
    size: number
  }
  onChangePage: IWPaginationProps['onChangePage']
  onChangeItemsPerPage: IWPaginationProps['onChangeItemsPerPage']
  isLoading: boolean
  onEdit: (e: SyntheticEvent, credential: SFTPCredential) => void
  onDelete: (e: SyntheticEvent, credential: SFTPCredential) => void
  onTest: (e: SyntheticEvent, credentialId: string) => void
}

const SFTPTable = ({
  onSort,
  sort,
  columns,
  data,
  page,
  onChangePage,
  onChangeItemsPerPage,
  isLoading,
  onEdit,
  onDelete,
  onTest,
}: Props) => {
  const { timezone } = useContext(LayoutContext)
  const buildTableNameData = (name: string, description?: string) => {
    return (
      <div>
        <IWTypography
          size="sm"
          weight="medium"
          fontHue={{ color: 'grey', value: 800 }}
        >
          {name}
        </IWTypography>
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 400 }}
        >
          {description}
        </IWTypography>
      </div>
    )
  }
  const buildTableHostData = (host: string, port: string) => {
    return (
      <div>
        <IWTypography
          size="sm"
          weight="medium"
          fontHue={{ color: 'grey', value: 800 }}
        >
          {host}
        </IWTypography>
        <IWTypography
          size="sm"
          weight="regular"
          fontHue={{ color: 'grey', value: 400 }}
        >
          {`${t('systemPreferences.integrations.sftp.fields.port')}: ${port}`}
        </IWTypography>
      </div>
    )
  }
  const buildTableActionButtons = (credential: SFTPCredential) => {
    return (
      <ActionButtonsWrap>
        <IWButton
          title={t('actions.test')}
          color="primary"
          onClick={(e) => {
            onTest(e, credential.credentialId)
          }}
        >
          <FontAwesomeIcon icon={faSync} />
        </IWButton>{' '}
        <IWButton
          title={t('actions.edit')}
          color="primary"
          onClick={(e) => {
            onEdit(e, credential)
          }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </IWButton>
        <IWButton
          title={t('actions.delete')}
          color="primary"
          onClick={(e) => onDelete(e, credential)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </IWButton>
      </ActionButtonsWrap>
    )
  }

  const formatData = (d?: SFTPCredential[]) => {
    return (
      d?.map((credential) => ({
        ...credential,
        name: buildTableNameData(credential.name, credential.description),
        host: buildTableHostData(credential.host, credential.port),
        createdAt: DateTime.fromISO(credential.createdAt, {
          zone: timezone,
        }).toLocaleString(DateTime.DATETIME_SHORT),
        actions: buildTableActionButtons(credential),
      })) || []
    )
  }
  if (data?.total === 0 && !isLoading) {
    return (
      <IWAlert
        color="grey"
        variant="alternative"
        label={t('systemPreferences.integrations.sftp.noSFTPCredentials')}
      />
    )
  }
  return (
    <div>
      <IWTable
        border
        onSort={onSort}
        sortedBy={sort}
        columns={columns}
        data={formatData(data?.rows)}
      />
      <PaginationWrapper>
        <IWPaginationControls
          perPageLabel={t('tables.rowsPerPage')}
          itemsPerPage={page.size}
          perPageOptions={paginationOptions}
          totalItems={data?.total || 0}
          currentPage={page.current}
          onChangePage={onChangePage}
          onChangeItemsPerPage={onChangeItemsPerPage}
          isLoading={isLoading}
        />
      </PaginationWrapper>
    </div>
  )
}

export default SFTPTable
