import { Reducer, useReducer } from 'react'
import {
  CronScheduleSelectorErrorState,
  Props,
  WeekdayDropdownOption,
} from 'shared/components/thunderbolt/IWCronJobSelector'

export const defaultCronJobScheduleSelectorCalculateError = (
  state: DefaultCronSelectorState,
) => {
  const errorState = {
    isScheduleTypeError: false,
    isHourError: false,
    isWeekdayError: false,
    isTimeOfMonthError: false,
    isMonthError: false,
  }

  let hasErrors = false
  const inputMap = new Map(Object.entries(state))
  switch (state.selectedScheduleTypeOption?.value) {
    case 'once':
      break
    case 'daily':
      hasErrors = typeof inputMap.get('selectedHourOption') === 'undefined'

      if (typeof inputMap.get('selectedHourOption') === 'undefined') {
        errorState.isHourError = true
      }

      break
    case 'hourly':
      break
    case 'weekly':
      hasErrors =
        typeof inputMap.get('selectedHourOption') === 'undefined' ||
        typeof inputMap.get('selectedWeekdayOption') === 'undefined'

      if (typeof inputMap.get('selectedHourOption') === 'undefined') {
        errorState.isHourError = true
      }

      if (typeof inputMap.get('selectedWeekdayOption') === 'undefined') {
        errorState.isWeekdayError = true
      }

      break
    case 'monthly':
      hasErrors =
        typeof inputMap.get('selectedHourOption') === 'undefined' ||
        typeof inputMap.get('selectedTimeOfMonthOption') === 'undefined'

      if (typeof inputMap.get('selectedHourOption') === 'undefined') {
        errorState.isHourError = true
      }

      if (typeof inputMap.get('selectedTimeOfMonthOption') === 'undefined') {
        errorState.isTimeOfMonthError = true
      }
      break
    case 'yearly':
      hasErrors =
        typeof inputMap.get('selectedHourOption') === 'undefined' ||
        typeof inputMap.get('selectedTimeOfMonthOption') === 'undefined' ||
        typeof inputMap.get('selectedMonthOption') === 'undefined'

      if (typeof inputMap.get('selectedHourOption') === 'undefined') {
        errorState.isHourError = true
      }

      if (typeof inputMap.get('selectedTimeOfMonthOption') === 'undefined') {
        errorState.isTimeOfMonthError = true
      }

      if (typeof inputMap.get('selectedMonthOption') === 'undefined') {
        errorState.isMonthError = true
      }
      break
    default:
      hasErrors = true
      errorState.isScheduleTypeError = true
      break
  }
  return {
    ...errorState,
    hasErrors,
  }
}

export type DefaultCronSelectorState = Pick<
  Props,
  | 'selectedScheduleTypeOption'
  | 'selectedHourOption'
  | 'selectedWeekdayOption'
  | 'selectedTimeOfMonthOption'
  | 'selectedMonthOption'
>

type ReducerState = DefaultCronSelectorState

interface ModifyState extends ReducerState {
  type: 'MODIFY_STATE'
}

const modifyState = ({ ...val }: ReducerState): ModifyState => ({
  type: 'MODIFY_STATE',
  ...val,
})

type ReducerAction = ReturnType<typeof modifyState>

const reducer: Reducer<ReducerState, ReducerAction> = (state, action) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...rest } = action
  switch (action.type) {
    case 'MODIFY_STATE':
      return {
        ...state,
        ...rest,
      }

    default:
      return state
  }
}

interface UseIwCronJobScheduleSelectorProps extends DefaultCronSelectorState {
  errorCalculation?: (
    state: DefaultCronSelectorState,
  ) => CronScheduleSelectorErrorState
}

const useIwCronJobScheduleSelector = ({
  selectedScheduleTypeOption,
  selectedHourOption,
  selectedWeekdayOption,
  selectedTimeOfMonthOption,
  selectedMonthOption,
  errorCalculation,
}: UseIwCronJobScheduleSelectorProps) => {
  const [state, dispatch] = useReducer(reducer, {
    selectedScheduleTypeOption,
    selectedHourOption,
    selectedWeekdayOption,
    selectedTimeOfMonthOption,
    selectedMonthOption,
  })

  const {
    isScheduleTypeError,
    isHourError,
    isWeekdayError,
    isTimeOfMonthError,
    isMonthError,
    hasErrors,
  } = errorCalculation
    ? errorCalculation(state)
    : {
        isScheduleTypeError: false,
        isHourError: false,
        isWeekdayError: false,
        isTimeOfMonthError: false,
        isMonthError: false,
        hasErrors: false,
      }

  return {
    selectedScheduleTypeOption: state.selectedScheduleTypeOption,
    isScheduleTypeError,
    onScheduleTypeChange: (val: Props['selectedScheduleTypeOption']) =>
      dispatch(
        modifyState({
          selectedHourOption: undefined,
          selectedMonthOption: undefined,
          selectedTimeOfMonthOption: undefined,
          selectedWeekdayOption: undefined,
          selectedScheduleTypeOption: val,
        }),
      ),
    selectedHourOption: state.selectedHourOption,
    isHourError,
    onHourChange: (val: Props['selectedHourOption']) =>
      dispatch(modifyState({ selectedHourOption: val })),
    selectedWeekdayOption: state.selectedWeekdayOption,
    isWeekdayError,
    onWeekdayChange: (val: WeekdayDropdownOption) => {
      const weekMap = new Map(
        state.selectedWeekdayOption?.map((w) => [w.value, true]),
      )
      if (weekMap.get(val.value)) {
        weekMap.set(val.value, false)
      } else {
        weekMap.set(val.value, true)
      }
      dispatch(
        modifyState({
          selectedWeekdayOption: Array.from(weekMap)
            .filter(([, value]) => value)
            .map(([key]) => ({ value: key })),
        }),
      )
    },
    selectedTimeOfMonthOption: state.selectedTimeOfMonthOption,
    isTimeOfMonthError,
    onTimeOfMonthChange: (val: Props['selectedTimeOfMonthOption']) =>
      dispatch(modifyState({ selectedTimeOfMonthOption: val })),
    selectedMonthOption: state.selectedMonthOption,
    isMonthError,
    onMonthChange: (val: Props['selectedMonthOption']) =>
      dispatch(modifyState({ selectedMonthOption: val })),
    hasErrors,
    modifyFullState: (val: DefaultCronSelectorState) =>
      dispatch(modifyState(val)),
  }
}

export default useIwCronJobScheduleSelector
