import React from 'react'
import { Hue } from '../../../@types/styled'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { rem } from 'polished'
import IWTypography from './IWTypography'

interface Props {
  src?: string
  size?: number
  alt: string
  icon?: IconDefinition
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  fontHue?: Hue
}

const StyledAvatar = styled.div<{ src?: string; size: string }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.grey[100]};
  min-height: ${(props) => props.size};
  min-width: ${(props) => props.size};
  max-height: ${(props) => props.size};
  max-width: ${(props) => props.size};
  border-radius: 50%;
  background-image: ${(props) => (props.src ? `url(${props.src})` : '')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`

const StyledAvatarIcon = styled(IWTypography)<{
  iconSize: string
  alt: string
}>`
  font-size: ${(props) => props.iconSize};
  display: flex;
  align-items: center;
`

const StyledAvatarText = styled(IWTypography)<{
  iconSize: string
  alt: string
}>`
  font-size: ${(props) => props.iconSize};
  display: flex;
  align-items: center;
`

const IWAvatar = ({ icon, src, size = 40, alt, onClick, fontHue }: Props) => {
  const imageSizeInRem = rem(`${size}px`)
  const placeholderSizeInRem = rem(`${size / 2}px`)
  return (
    <StyledAvatar src={src} size={imageSizeInRem} onClick={onClick}>
      {!src && icon && (
        <StyledAvatarIcon
          iconSize={placeholderSizeInRem}
          fontHue={fontHue || { color: 'grey', value: 400 }}
          alt={alt}
        >
          <FontAwesomeIcon icon={icon} />
        </StyledAvatarIcon>
      )}
      {!src && !icon && alt && (
        <StyledAvatarText
          iconSize={placeholderSizeInRem}
          fontHue={{ color: 'grey', value: 400 }}
          alt={alt}
        >
          {alt.substr(0, 2).toLocaleUpperCase()}
        </StyledAvatarText>
      )}
    </StyledAvatar>
  )
}

export default IWAvatar
