import React, { ReactNode } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { transitions } from 'polished'
import { AvailableColors } from '../../../@types/styled'
import IWTypography from './IWTypography'

export type ButtonVariants =
  | 'main'
  | 'alternative'
  | 'anchorMain'
  | 'anchorAlternative'
  | 'outline'

type ButtonVariantsWOOutline = Exclude<ButtonVariants, 'outline'>

const mainBackgroundColorMap = {
  main: {
    primary: { bg: 800, font: 50, hover: 900, fontHover: 100 },
    secondary: { bg: 900, font: 50, hover: 800, fontHover: 100 },
    alert: { bg: 800, font: 50, hover: 900, fontHover: 100 },
    warning: { bg: 900, font: 50, hover: 800, fontHover: 100 },
    grey: { bg: 500, font: 50, hover: 800, fontHover: 100 },
  },
  alternative: {
    primary: { bg: 50, font: 800, hover: 100, fontHover: 900 },
    secondary: { bg: 50, font: 900, hover: 100, fontHover: 900 },
    alert: { bg: 50, font: 800, hover: 100, fontHover: 900 },
    warning: { bg: 50, font: 900, hover: 100, fontHover: 900 },
    grey: { bg: 200, font: 500, hover: 300, fontHover: 800 },
  },
  outline: {
    primary: { bg: undefined, font: 800, hover: 50, fontHover: 900 },
    secondary: { bg: undefined, font: 900, hover: 50, fontHover: 900 },
    alert: { bg: undefined, font: 800, hover: 50, fontHover: 900 },
    warning: { bg: undefined, font: 900, hover: 50, fontHover: 900 },
    grey: { bg: undefined, font: 500, hover: 50, fontHover: 800 },
  },
  anchorMain: {
    primary: { bg: undefined, font: 800, hover: undefined, fontHover: 900 },
    secondary: { bg: undefined, font: 900, hover: undefined, fontHover: 900 },
    alert: { bg: undefined, font: 800, hover: undefined, fontHover: 900 },
    warning: { bg: undefined, font: 900, hover: undefined, fontHover: 900 },
    grey: { bg: undefined, font: 500, hover: undefined, fontHover: 800 },
  },
  anchorAlternative: {
    primary: { bg: undefined, font: 50, hover: undefined, fontHover: 200 },
    secondary: { bg: undefined, font: 50, hover: undefined, fontHover: 200 },
    alert: { bg: undefined, font: 50, hover: undefined, fontHover: 200 },
    warning: { bg: undefined, font: 50, hover: undefined, fontHover: 200 },
    grey: { bg: undefined, font: 50, hover: undefined, fontHover: 200 },
  },
}

const getMainBackgroundColor = (
  buttonVariant: ButtonVariants,
  buttonColor: AvailableColors,
  theme: DefaultTheme,
  hasSolidBackground?: Boolean,
) => {
  if (
    (!hasSolidBackground && buttonVariant === 'outline') ||
    buttonVariant === 'anchorMain' ||
    buttonVariant === 'anchorAlternative'
  ) {
    return 'transparent'
  }
  if (buttonVariant === 'outline') {
    return theme.palette.grey[0]
  }
  const shade = mainBackgroundColorMap[buttonVariant][buttonColor].bg
  return theme.palette[buttonColor][shade]
}

const getMainTextColor = (
  buttonVariant: ButtonVariants,
  buttonColor: AvailableColors,
  theme: DefaultTheme,
) => {
  const shade = mainBackgroundColorMap[buttonVariant][buttonColor].font
  return theme.palette[buttonColor][shade]
}

const getHoverTextColor = (
  buttonVariant: ButtonVariants,
  buttonColor: AvailableColors,
  theme: DefaultTheme,
) => {
  const shade = mainBackgroundColorMap[buttonVariant][buttonColor].fontHover
  return theme.palette[buttonColor][shade]
}

const getHoverColor = (
  buttonVariant: ButtonVariants,
  buttonColor: AvailableColors,
  theme: DefaultTheme,
) => {
  if (buttonVariant === 'anchorMain' || buttonVariant === 'anchorAlternative') {
    return 'transparent'
  }
  const shade = mainBackgroundColorMap[buttonVariant][buttonColor].hover
  return theme.palette[buttonColor][shade]
}

const getHoverTextDecorationLine = (buttonVariant: ButtonVariants) => {
  if (buttonVariant === 'anchorMain' || buttonVariant === 'anchorAlternative') {
    return 'underline'
  }
  return 'none'
}

const getBorder = (
  buttonVariant: ButtonVariants,
  buttonColor: AvailableColors,
  theme: DefaultTheme,
) => {
  if (buttonVariant === 'outline') {
    return `1px solid ${theme.palette[buttonColor][500]}`
  }
  return 'none'
}

const getBorderRadius = (buttonVariant: ButtonVariants) => {
  if (buttonVariant === 'anchorMain' || buttonVariant === 'anchorAlternative') {
    return `0px`
  }
  return '0.375rem'
}

const getPadding = (buttonVariant: ButtonVariants) => {
  if (buttonVariant === 'anchorMain' || buttonVariant === 'anchorAlternative') {
    return `0px`
  }
  return '0.5rem 1rem'
}

const getDisabledBackgroundColor = (
  buttonVariant: ButtonVariants,
  theme: DefaultTheme,
) => {
  if (buttonVariant === 'anchorMain' || buttonVariant === 'anchorAlternative') {
    return 'transparent'
  }
  return theme.palette.grey[300]
}

type ExtraPropsWithoutOuline = {
  children: ReactNode
  variant?: ButtonVariantsWOOutline
  color?: AvailableColors
  hasSolidBackground?: never
}

type ExtraPropsWithOutline = {
  children: ReactNode
  variant: 'outline'
  hasSolidBackground?: Boolean
  color?: AvailableColors
}

type ExtraProps = ExtraPropsWithoutOuline | ExtraPropsWithOutline

type IconProps =
  | { icon: IconDefinition; iconPosition: 'trailing' | 'leading' }
  | { icon?: never; iconPosition?: never }

type ElementProps =
  | ({ as?: 'button' } & React.ComponentPropsWithoutRef<'button'>)
  | ({ as: 'a' } & React.ComponentPropsWithoutRef<'a'>)
  | ({ as: 'routerLink' } & React.ComponentPropsWithoutRef<typeof Link>)
  | ({ as: 'routerNavLink' } & React.ComponentPropsWithoutRef<typeof NavLink>)

export type Props = ExtraProps & IconProps & ElementProps

const IconWrapper = styled.span<{ iconPosition: Props['iconPosition'] }>`
  margin-right: ${(props) =>
    props.iconPosition === 'leading' ? '0.25rem' : undefined};
  margin-left: ${(props) =>
    props.iconPosition === 'trailing' ? '0.25rem' : undefined};
`

const StyledButton = styled('button')<{
  variant: ButtonVariants
  color: AvailableColors
  hasSolidBackground?: Boolean
}>`
  background-color: ${(props) =>
    getMainBackgroundColor(
      props.variant,
      props.color,
      props.theme,
      props.hasSolidBackground,
    )};

  &:hover {
    background-color: ${(props) =>
      getHoverColor(props.variant, props.color, props.theme)};
    cursor: pointer;
    color: ${(props) =>
      getHoverTextColor(props.variant, props.color, props.theme)};
    text-decoration-line: ${(props) =>
      getHoverTextDecorationLine(props.variant)};
  }

  &:active {
    background-color: ${(props) =>
      getMainBackgroundColor(
        props.variant,
        props.color,
        props.theme,
        props.hasSolidBackground,
      )};
  }

  &:focus {
    outline-offset: 2px;
    outline: ${(props) => props.theme.palette.primary[800]} solid 2px;
  }

  &:disabled {
    background-color: ${(props) =>
      getDisabledBackgroundColor(props.variant, props.theme)};
    color: ${(props) => props.theme.palette.grey[400]};
    cursor: not-allowed;
    text-decoration: none;
  }

  color: ${(props) =>
    getMainTextColor(props.variant, props.color, props.theme)};
  border: ${(props) => getBorder(props.variant, props.color, props.theme)};
  padding: ${(props) => getPadding(props.variant)};
  border-radius: ${(props) => getBorderRadius(props.variant)};
  text-decoration: none;
  ${transitions(['color', 'background-color'], '0.3s ease 0s')};
`

const InnerButton = ({
  icon,
  iconPosition,
  children,
}: Pick<Props, 'children' | 'icon' | 'iconPosition'>) => {
  return (
    <IWTypography inheritFontColor as="span" size="sm" weight="medium">
      {icon && iconPosition === 'leading' && (
        <IconWrapper iconPosition={iconPosition}>
          <FontAwesomeIcon icon={icon} />
        </IconWrapper>
      )}
      {children}
      {icon && iconPosition === 'trailing' && (
        <IconWrapper iconPosition={iconPosition}>
          <FontAwesomeIcon icon={icon} />
        </IconWrapper>
      )}
    </IWTypography>
  )
}
/**
 * This is Innowatts' main button component. Not much more to say here, except it can take icons. Technically, you could pass an icon and text as children. If that were the case you would have to create the appropriate spacing. This button allows you to pass `icon` and `iconPosition` props that will appropriately space text and icon. Make sure you use the correct variant.
 */
const IWButton = React.forwardRef(
  (
    {
      children,
      icon,
      variant = 'main',
      color = 'primary',
      iconPosition,
      as,
      hasSolidBackground = false,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement> | React.Ref<HTMLAnchorElement>,
  ) => {
    let tag
    if (as === 'routerLink') {
      tag = Link
    } else if (as === 'routerNavLink') {
      tag = NavLink
    } else {
      tag = as
    }
    return (
      <StyledButton
        as={tag}
        variant={variant}
        color={color}
        ref={ref}
        type="button"
        hasSolidBackground={hasSolidBackground}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <InnerButton icon={icon} iconPosition={iconPosition}>
          {children}
        </InnerButton>
      </StyledButton>
    )
  },
)

export default IWButton
