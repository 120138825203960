import { faBell, faBolt } from '@fortawesome/pro-regular-svg-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WithClick as ProductItemProps } from 'shared/components/thunderbolt/IWProductItem'

export type NotificationOptionType = 'inApp' | 'email' | 'slack'
export type NotificationType = 'system' | 'iwToolkit'

const SystemNotification = [
  'innoPodSharedWithYou',
  'innoUnshared',
  'innoRunFailed',
  'newDefaultInnoPodAvailable',
  'visualisationShared',
  'visualisationUnshared',
  'dashboardShared',
  'dashboardUnshared',
]
const IWToolkitNotification = ['alertTriggered']

export type Notifications = {
  [key in NotificationType]?: Array<{ id: string; isEnabled: boolean }>
}

interface NotificationOptions extends ProductItemProps {
  id: NotificationOptionType
}

function getTotalEnabled(o) {
  let total = 0
  Object.keys(o).forEach((key) => {
    o[key].forEach((v) => {
      if (v.isEnabled) total += 1
    })
  })
  return total
}

/**
 * This hook helps in maintaining notification states
 * it provides few things which notification section of general page needs
 * 1. it provides notification options which are either inapp , slack or email
 * this can be expanded by just adding new option to notificationOptions array, current
 * only inApp is a option
 * 2. it provides where the modal is open or not and a onchange for modal open and close
 * 3. it provides notitification for a selected notificationOption , example
 *    a notificationOption like 'inApp' can have multiple notification type like 'system', 'iwtoolkit'
 *    and further system can have 'type1', 'type2' etc real notifications , so hook gives notification
 *    for a specific notificationOption and it also provide onchage for the specific notificationOption
 */
const useNotificationSelector = () => {
  const { t } = useTranslation()

  const [selectedNotificationType, setSelectedNotificationType] =
    useState<NotificationOptionType>('inApp')

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [inAppNotifications, setInAppNotification] = useState<Notifications>({
    system: SystemNotification.map((notification) => ({
      id: notification,
      isEnabled: true,
    })),
    iwToolkit: IWToolkitNotification.map((notification) => ({
      id: notification,
      isEnabled: true,
    })),
  })

  const [notificationOptions, setNotificationOptions] = useState<
    Array<NotificationOptions>
  >([
    {
      id: 'inApp',
      icon: faBell,
      iconColor: 'grey',
      badges: [
        {
          icon: faBolt,
          label: `${getTotalEnabled(inAppNotifications)} ${t(
            'systemPreferences.systemNotification.triggersEnabled',
          )}`,
        },
      ],
      header: t('systemPreferences.notification.inApp.header'),
      onClick: () => {
        setSelectedNotificationType('inApp')
        setIsOpen(true)
      },
    },
  ])

  useEffect(() => {
    setNotificationOptions((prev) => {
      return prev.map((el) => {
        if (el.id === 'inApp') {
          return {
            ...el,
            badges: [
              {
                icon: faBolt,
                label: `${getTotalEnabled(inAppNotifications)} ${t(
                  'systemPreferences.systemNotification.triggersEnabled',
                )}`,
              },
            ],
          }
        }
        return el
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inAppNotifications])

  return {
    isNotificationModalOpen: isOpen,
    setIsNotificationSelectorModalOpen: setIsOpen,
    notificationOptions,
    selectedNotificationType,
    notifications: () => {
      if (selectedNotificationType === 'inApp') {
        return inAppNotifications
      }
      return null
    },
    updateNotifications: (updatedNotifications: Notifications) => {
      if (selectedNotificationType === 'inApp') {
        return setInAppNotification(updatedNotifications)
      }
      return null
    },
  }
}

export default useNotificationSelector
