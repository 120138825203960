import { useEffect, useState } from 'react'
import useIwCronJobScheduleSelector, {
  defaultCronJobScheduleSelectorCalculateError,
  DefaultCronSelectorState,
} from 'shared/hooks/useIWCronJobScheduleSelector'
import { RetentionPeriodOptions } from 'tools/insightsManager/types'

const usePodBuilderGeneralSetting = ({
  selectedPodName,
  cronJobSchedule,
  selectedRetentionPeriod,
  selectedEventBased,
  minLength,
}: {
  selectedPodName?: string
  cronJobSchedule?: DefaultCronSelectorState
  selectedRetentionPeriod?: RetentionPeriodOptions
  selectedEventBased?: boolean
  minLength: number
}) => {
  const cronSelectorInputState = useIwCronJobScheduleSelector({
    ...cronJobSchedule,
    errorCalculation: defaultCronJobScheduleSelectorCalculateError,
  })

  const [userPodName, setPodName] = useState(selectedPodName)
  const [isPodNameError, setIsPodNameError] = useState(false)

  const [eventBased, setEventBased] = useState(selectedEventBased || false)
  const [isEventBasedError, setIsEventBasedError] = useState(false)

  const [retentionPeriod, setRetentionPeriod] = useState(
    selectedRetentionPeriod,
  )
  const [isRetentionError, setIsRetentionError] = useState(false)

  const [hasError, setHasError] = useState(
    isPodNameError ||
      isRetentionError ||
      cronSelectorInputState.hasErrors ||
      isEventBasedError,
  )

  useEffect(() => {
    if (
      userPodName === '' ||
      typeof userPodName === 'undefined' ||
      userPodName.length < minLength
    ) {
      setIsPodNameError(true)
    } else {
      setIsPodNameError(false)
    }
  }, [userPodName, minLength])

  useEffect(() => {
    if (typeof retentionPeriod === 'undefined') {
      setIsRetentionError(true)
    } else {
      setIsRetentionError(false)
    }
  }, [retentionPeriod])

  useEffect(() => {
    if (typeof eventBased === 'undefined') {
      setIsEventBasedError(true)
    } else {
      setIsEventBasedError(false)
    }

    if (eventBased) {
      cronSelectorInputState.onScheduleTypeChange({
        value: 'once',
      })
      setRetentionPeriod({
        value: 'indefinitely',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventBased])

  useEffect(() => {
    setHasError(
      isPodNameError || isRetentionError || cronSelectorInputState.hasErrors,
    )
  }, [
    isPodNameError,
    isRetentionError,
    cronSelectorInputState.hasErrors,
    isEventBasedError,
  ])

  return {
    userPodName,
    isPodNameError,
    onPodNameChange: (val: string) => setPodName(val),
    retentionPeriod,
    isRetentionError,
    onRetentionPeriodChange: (val: any) => setRetentionPeriod(val),
    cronSelectorInputState,
    eventBased,
    isEventBasedError,
    onEventBasedChange: (val: boolean) => setEventBased(val),
    hasError,
  }
}

export default usePodBuilderGeneralSetting
