import { useEffect, useState } from 'react'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWTableWithPagination from 'shared/components/thunderbolt/IWTableWithPagination'

import { useTranslation } from 'react-i18next'
import { ImportManagerSourceSchema, Mapping } from '../types'

export type Props = {
  inputFileSchema: ImportManagerSourceSchema[]
  mapping: Mapping[] | []
}

const SummaryStep = ({ inputFileSchema, mapping }: Props) => {
  const { t } = useTranslation()
  const [data, setData] = useState<Record<string, string>[]>([])
  const [columns, setColumns] = useState<
    Array<{
      title: string
      accessor: string
    }>
  >([] as any)

  const getTableRows = (sourceColumnIndex: number): string[] => {
    if (inputFileSchema) {
      return inputFileSchema
        .filter((column) => sourceColumnIndex === column.columnIndex)
        .map((column) => column.sampleData)
        .flat()
    }
    return []
  }

  useEffect(() => {
    if (!data || data.length === 0) {
      if (mapping) {
        const mapper = mapping?.map((item) => ({
          columnName: item.targetColumnName,
          values: getTableRows(item.sourceColumn),
        }))

        const tColumns = mapping.map((item) => ({
          title: item.targetColumnName,
          accessor: item.targetColumnName,
        }))
        setColumns(tColumns)
        // FIXME: This is super slow, but its fine since we are only
        // processing 20 rows. If the preview amount increases,
        // feel free to improve the code
        const result = mapper.reduce((memo, current) => {
          if (memo.length === 0) {
            current.values.forEach((v) => {
              memo.push({ [current.columnName]: v })
            })
          } else {
            memo.forEach((item) => {
              current.values.forEach((v) => {
                item[current.columnName] = v
              })
            })
          }

          return memo
        }, [] as Record<string, string>[])
        setData(result)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IWFormSection
      sectionTitle={t('importManager.summary.labels.sectionTitle')}
    >
      <IWTableWithPagination items={data} columns={columns} />
    </IWFormSection>
  )
}

export default SummaryStep
