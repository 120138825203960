import { WizardStepProps } from 'shared/types'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import LoadSchedulingTable from './LSTable'
import { getLSIbtSubmitSummaryTableSchema } from '../schemas'
import { groupBy } from 'lodash'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import { InternalBilateral } from '../types'

type StepState = {
  summary: InternalBilateral[]
}

export type Props = WizardStepProps<StepState>

const SubmitSummaryStep = ({ state }: Props) => {
  const { t } = useTranslation()
  const successTableColumns = getLSIbtSubmitSummaryTableSchema()

  const groupedByLocation = groupBy(
    state?.summary,
    ({ market, brand, zone, buyer, seller }) =>
      `${market}-|-${brand}-|-${zone}-|-${buyer}-|-${seller}`,
  )

  const tableData = Object.entries(groupedByLocation).map(([key, value]) => {
    const [market, brand, zone, buyer, seller] = key.split('-|-')

    const dates = value.map(({ date }) => DateTime.fromISO(date))
    const minDate = DateTime.min(...dates).toISODate()
    const maxDate = DateTime.max(...dates).toISODate()

    return {
      market,
      brand,
      zone,
      buyer,
      seller,
      dateRange: `${minDate} - ${maxDate}`,
      status: (
        <div style={{ display: 'inline-block' }}>
          <IWGeneralBadge
            label={t('loadScheduling.processing')}
            color="warning"
          />
        </div>
      ),
    }
  })

  return (
    <>
      <IWTypography size="lg">
        {t('loadScheduling.summaryResultLabel')}
      </IWTypography>
      <LoadSchedulingTable
        columns={successTableColumns}
        items={tableData || []}
      />
    </>
  )
}

export default SubmitSummaryStep
