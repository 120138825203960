import i18n, { AvailableLanguages, availableLanguages } from 'i18n'
import { DateTime } from 'luxon'
import React, { ReactNode, useEffect, useState } from 'react'
import defaultScreenSizes from 'shared/components/thunderbolt/utils/breakpoints'

export const availableThemes = ['light', 'dark', 'machine'] as const

export type Theme = (typeof availableThemes)[number]

// @ts-ignore
export const availableTimezones = Intl.supportedValuesOf('timeZone') as string[]

function isAvailableLanguage(lng: string): lng is AvailableLanguages {
  return availableLanguages.some((l) => l === lng)
}
type LayoutContextType = {
  /**
   * Stores the selected theme
   */
  theme: Theme
  /**
   * Sets the selected theme
   */
  setTheme: (theme: Theme) => void
  /**
   * If the sidebar menu is open or not
   */
  menuOpen: boolean
  /**
   * If the notification menu is open or not
   */
  isNotificationListMenuOpen: boolean
  /**
   * If screen width is between 0 and defaultScreenSizes.xs
   */
  isExtraSmallScreen: boolean
  /**
   * If screen width is between defaultScreenSizes.xs and defaultScreenSizes.sm
   */
  isSmallScreen: boolean
  /**
   * If screen width is between defaultScreenSizes.sm and defaultScreenSizes.md
   */
  isMediumScreen: boolean
  /**
   * Function to toggle the menu on and off
   */
  toggleMenu: () => void
  /**
   * Function to set the menu open state
   */
  setMenuOpen: (val: boolean) => void
  /**
   * Function to set the notification menu open state
   */
  setIsNotificationListMenuOpen: (val: boolean) => void
  /**
   * Function to set the application's language
   */
  setLanguage: (lng: AvailableLanguages) => void
  language: AvailableLanguages
  setTimezone: (zone: string) => void
  timezone: string
}

const LayoutContext = React.createContext<LayoutContextType>({
  theme: 'light',
  setTheme: () => {},
  menuOpen: false,
  isNotificationListMenuOpen: false,
  isExtraSmallScreen: false,
  isSmallScreen: false,
  isMediumScreen: false,
  toggleMenu: () => {},
  setMenuOpen: () => {},
  setIsNotificationListMenuOpen: () => {},
  setLanguage: () => {},
  language: 'en',
  setTimezone: () => {},
  timezone: 'America/Chicago',
})

type ContextProviderProps = {
  children: ReactNode
}

const LayoutProvider = ({ children }: ContextProviderProps) => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

  const isMediumScreen = screenWidth < defaultScreenSizes.md // Sidebar Collapses
  const isSmallScreen = screenWidth < defaultScreenSizes.sm // Inner nav (full page nav) dropdown collapses
  const isExtraSmallScreen = screenWidth < defaultScreenSizes.xs // Not Supported

  const [theme, setTheme] = useState<Theme>(
    (localStorage.getItem('theme') as Theme) || 'light',
  )

  const [language, setLanguage] = useState<AvailableLanguages>(
    (i18n.language as AvailableLanguages) || 'en',
  )

  const [timezone, setTimezone] = useState<string>(
    localStorage.getItem('timezone') || DateTime.local().toFormat('z'),
  )

  const [menuOpen, setMenuOpen] = useState(!isMediumScreen)
  const [isNotificationListMenuOpen, setIsNotificationListMenuOpen] =
    useState(false)
  const [debouncer] = useState<{ timeout: NodeJS.Timeout | null }>({
    timeout: null,
  })

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
      localStorage.setItem('i18nextLng', language)
    }
  }, [language])

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  useEffect(() => {
    localStorage.setItem('timezone', timezone)
  }, [timezone])

  i18n.on('languageChanged', (lng) => {
    if (isAvailableLanguage(lng)) {
      setLanguage(lng)
    }
  })

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    if (isMediumScreen && menuOpen) {
      setMenuOpen(false) // only close the menu on resize for medium screens
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediumScreen, screenWidth])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (debouncer.timeout) {
        clearTimeout(debouncer.timeout)
      }
      debouncer.timeout = setTimeout(() => {
        handleResize()
      }, 250)
    })
    return () => {
      if (debouncer.timeout) {
        clearTimeout(debouncer.timeout)
      }
      window.removeEventListener('resize', handleResize)
    }
  }, [debouncer])

  return (
    <LayoutContext.Provider
      value={{
        theme,
        setTheme,
        menuOpen,
        isNotificationListMenuOpen,
        isExtraSmallScreen,
        isSmallScreen,
        isMediumScreen,
        toggleMenu,
        setMenuOpen,
        setIsNotificationListMenuOpen,
        setLanguage,
        language,
        timezone,
        setTimezone,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export { LayoutProvider }

export default LayoutContext
