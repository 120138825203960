import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import { IWFileInput } from 'shared/components/thunderbolt/IWTextInput'
import { WizardStepProps } from 'shared/types'
import { ImportManagerSchema, ImportManagerSourceSchema } from '../types'
import useInnowattsSchemaSelector from '../hooks/useInnowattsSchemaSelector'
import useUserFileSelector from '../hooks/useUserFileSelector'
import IWDropdown from 'shared/components/thunderbolt/IWDropdown'
import { processUserInputFileDataToMapWithSelectedFileSchema } from '../helpers'

type StepState = {
  selectedFile: File | null
  selectedFileData: Record<string, string>[]
  selectedFileSchemaForComparison: ImportManagerSourceSchema[]
  selectedInnowattsSchema: ImportManagerSchema
  selectedInnowattsSchemaForComparison: ImportManagerSchema
}

export type Props = WizardStepProps<StepState>

const ImportFileStep = ({ state, onChange, showErrors }: Props) => {
  const { t } = useTranslation()

  const {
    selectedSchema: selectedSchemaOption,
    onSchemaSelectionChange,
    availableSchemas,
    hasErrors: hasFileSchemaSelectorErrors,
  } = useInnowattsSchemaSelector({})

  const {
    selectedFile,
    onFileSelect,
    clearSelectedFile,
    importedData,
    fileInputRef,
    hasError: hasUserFileSelectorError,
  } = useUserFileSelector()

  const isStepValid = () => {
    return hasFileSchemaSelectorErrors && hasUserFileSelectorError
  }

  useEffect(() => {
    try {
      if (
        selectedFile &&
        availableSchemas &&
        importedData &&
        selectedSchemaOption
      ) {
        const innowattsSchema = availableSchemas.find(
          (item) => item.id === selectedSchemaOption?.value,
        )
        const { selectedFileSchema, selectedSchema } =
          processUserInputFileDataToMapWithSelectedFileSchema(
            importedData || [],
            innowattsSchema || ([] as any),
          )

        onChange(
          {
            ...state,
            selectedFile,
            selectedFileData: importedData || [],
            // selected file data with comparison options
            selectedFileSchemaForComparison: selectedFileSchema,
            selectedInnowattsSchema: innowattsSchema as any,
            // selected schema data with comparison options
            selectedInnowattsSchemaForComparison: selectedSchema,
          },
          !isStepValid(),
        )
      }
    } catch (e) {
      // console.log(e)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    importedData,
    selectedFile,
    selectedSchemaOption,
    hasFileSchemaSelectorErrors,
    hasUserFileSelectorError,
  ])

  return (
    <>
      <IWFormSection
        sectionTitle={t('importManager.importFile.labels.stepTitle')}
      >
        <IWFileInput
          label={t('importManager.importFile.labels.selectFile')}
          ref={fileInputRef}
          type="file"
          name="file"
          accept=".csv"
          trailingIcon={{
            icon: faTimes,
            onIconClick: clearSelectedFile,
          }}
          onChange={onFileSelect}
          hasError={hasUserFileSelectorError && showErrors}
        />

        <IWDropdown
          name="import-file-schema-types"
          label={t('importManager.importFile.labels.selectSchema')}
          value={selectedSchemaOption}
          options={availableSchemas?.map((v) => ({
            label: v.name,
            value: v.id,
          }))}
          onChange={onSchemaSelectionChange}
          hasError={hasFileSchemaSelectorErrors && showErrors}
        />
      </IWFormSection>
    </>
  )
}

export default ImportFileStep
