import { DropdownValueProps } from 'shared/components/thunderbolt/IWDropdown'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { AggregationFn } from 'tools/alertsManager/types'
import { Props as ShortTermSettlementConfig } from './insights/shortTermForecast/settlement/SettlementConfiguration'
import { Props as GeneralSettingsProps } from './components/PodGeneralSettingStep'
import { Props as ShortTermMeterConfig } from './insights/shortTermForecast/meter/MeterConfiguration'
import { Props as ShortTermCustomerTypeConfig } from './insights/shortTermForecast/customerType/CustomerTypeConfiguration'
import { Props as ShortTermProfileConfig } from './insights/shortTermForecast/profile/ProfileConfiguration'
import { Props as ShortTermSettlementWeatherConfig } from './insights/shortTermForecast/settlementWeather/SettlementWeatherConfiguration'
import { Props as ShortTermCustomerTypeWeatherConfig } from './insights/shortTermForecast/customerTypeWeather/CustomerTypeWeatherConfiguration'
import { Props as ShortTermProfileWeatherConfig } from './insights/shortTermForecast/profileWeather/ProfileWeatherConfiguration'
import { Props as RiskManagementNOPConfig } from './insights/riskManagement/NetOpenPositionConfiguration'
import { Props as RiskManagementExplodedTradesConfig } from './insights/riskManagement/ExplodedTradesConfiguration'
import { Props as ShortTermForecastPerformanceConfig } from './insights/shortTermForecastPerformance/GeneralConfiguration'
import { Props as ActualUsageSettlementConfig } from './insights/actualUsage/settlement/SettlementConfiguration'
import {
  Props as ActualUsageMeterDailyConfig,
  Props as ActualUsageMeterHourlyConfig,
} from './insights/actualUsage/meter/MeterConfiguration'
import { Props as WholesaleSettlementSummaryConfig } from './insights/wholesaleSettlement/summary/SummaryConfiguration'
import { Props as LongtermBlockTypeConfig } from './insights/longTermForecast/blockType/LTFBlockTypeConfiguration'
import { Props as ShortTermForecastPerformanceSystemConfig } from './insights/shortTermForecast/settlement/SettlementConfiguration'
import { Props as AttributeInfoConfig } from './insights/attributeInfo/meter/MeterConfiguration'

export type AttributeFilter = {
  attribute?: DropdownValueProps
  condition?: DropdownValueProps
  values: DropdownValueProps[]
  defaultSelectedValue?: string
  isValuesSingleSelect?: boolean
  isDisabled?: boolean
}

type GenericPodType<I, M> = {
  insightId: I
  metricId: M
}

const validShortTermForecastMetricIds = [
  'settlement',
  'meter',
  'customerType',
  'productType',
  'profile',
  'topDown',
  'settlementWeather',
  'customerTypeWeather',
  'profileWeather',
  'topDownWeather',
] as const
const validRiskMetricIds = [
  'netOpenPosition',
  'netOpenPositionLtf',
  'supplyTrades',
  'explodedTrades',
] as const
const validLongTermForecast = [
  'meter',
  'customerType',
  'profile',
  'productType',
  'settlement',
  'blockType',
] as const
const validShortTermForecastPerformanceMetricIds = [
  'allPerformance',
  'dailyMape',
  'monthlyMape',
  'allBrandPerformance',
  'dailyBrandMape',
  'monthlyBrandMape',
  'allPortfolioPerformance',
  'dailyPortfolioMape',
  'monthlyPortfolioMape',
] as const

const validShortTermForecastPerformanceTopDownMetricIds = [
  'allPerformance',
  'dailyMape',
  'monthlyMape',
  'allBrandPerformance',
  'dailyBrandMape',
  'monthlyBrandMape',
  'allPortfolioPerformance',
  'dailyPortfolioMape',
  'monthlyPortfolioMape',
] as const

const validShortTermForecastSystemMetricIds = [
  'systemERCOT',
  'systemPJM',
] as const

const validActualUsageMetricIds = [
  'settlement',
  'meterHourly',
  'meterDaily',
] as const

const validWholesaleSettlementMetricIds = [
  'summaryERCOT',
  'allocationERCOT',
] as const

const validShortTermForecastPerformanceSystemMetricIds = [
  'systemERCOTAllPerformance',
  'systemERCOTDailyPerformance',
  'systemERCOTMonthlyPerformance',
  'systemPJMAllPerformance',
  'systemPJMDailyPerformance',
  'systemPJMMonthlyPerformance',
] as const

const validAttributeInfoConfigMetricIds = ['meter'] as const

export type ValidShortTermForecastMetricIds =
  (typeof validShortTermForecastMetricIds)[number]
export type ValidRiskMetricIds = (typeof validRiskMetricIds)[number]
export type ValidLongTermForecastMetricIds =
  (typeof validLongTermForecast)[number]
export type ValidShortTermForecastPerformanceMetricIds =
  (typeof validShortTermForecastPerformanceMetricIds)[number]
export type ValidShortTermForecastPerformanceTopDownMetricIds =
  (typeof validShortTermForecastPerformanceTopDownMetricIds)[number]
export type ValidShortTermForecastSystemMetricIds =
  (typeof validShortTermForecastSystemMetricIds)[number]
export type ValidActualUsageMetricIds =
  (typeof validActualUsageMetricIds)[number]
export type ValidWholesaleSettlementMetricIds =
  (typeof validWholesaleSettlementMetricIds)[number]
export type ValidShortTermForecastPerformanceSystemMetricIds =
  (typeof validShortTermForecastPerformanceSystemMetricIds)[number]

export type ValidAttributeInfoMetricIds =
  (typeof validAttributeInfoConfigMetricIds)[number]

type ShortTermForecastPods = GenericPodType<
  'shortTermForecast',
  ValidShortTermForecastMetricIds
>

type ShortTermForecastPerformancePods = GenericPodType<
  'shortTermForecastPerformance',
  ValidShortTermForecastPerformanceMetricIds
>
type ShortTermForecastPerformanceTopDownPods = GenericPodType<
  'shortTermForecastPerformanceTopDown',
  ValidShortTermForecastPerformanceTopDownMetricIds
>

type ShortTermForecastSystemPods = GenericPodType<
  'shortTermForecastSystem',
  ValidShortTermForecastSystemMetricIds
>

type RiskPods = GenericPodType<'riskManagement', ValidRiskMetricIds>

type LongTermForecastPods = GenericPodType<
  'longTermForecast',
  ValidLongTermForecastMetricIds
>

type ActualUsagePods = GenericPodType<'actualUsage', ValidActualUsageMetricIds>

type WholesaleSettlementPods = GenericPodType<
  'wholesaleSettlement',
  ValidWholesaleSettlementMetricIds
>

type ShortTermForecastPerformanceSystemPods = GenericPodType<
  'shortTermForecastPerformanceSystem',
  ValidShortTermForecastPerformanceSystemMetricIds
>

type AttributeInfoPods = GenericPodType<
  'attributeInfo',
  ValidAttributeInfoMetricIds
>

export type PodType =
  | ShortTermForecastPods
  | ShortTermForecastPerformancePods
  | ShortTermForecastPerformanceTopDownPods
  | RiskPods
  | LongTermForecastPods
  | ShortTermForecastSystemPods
  | ActualUsagePods
  | WholesaleSettlementPods
  | ShortTermForecastPerformanceSystemPods
  | AttributeInfoPods

export interface WeatherAttributes {
  weatherStationCount?: number
  aggregationFnId?: AggregationFn
}

interface RelativeDateBetween {
  main: 'between'
  datePickerMax: string
  datePickerMin: string
  timezone?: string
}

interface RelativeDateAfter {
  main: 'after'
  specificDate: string
  timezone?: string
}

interface RelativeDateBefore {
  main: 'before'
  specificDate: string
  timezone?: string
}

interface RelativeDateOn {
  main: 'on'
  specificDate: string
  timezone?: string
}

export interface RelativeDateNext {
  main: 'next'
  relative: 'today' | 'specificDate' | 'firstDateAvailable'
  timeLength: 'days' | 'weeks' | 'months' | 'years'
  timeLengthValue: number
  specificDate?: string
  timezone?: string
}

export interface RelativeDatePrevious {
  main: 'previous'
  relative: 'today' | 'specificDate' | 'lastDateAvailable'
  timeLength: 'days' | 'weeks' | 'months' | 'years'
  timeLengthValue: number
  specificDate?: string
  timezone?: string
}

export type RelativeDateFilter =
  | RelativeDateBetween
  | RelativeDateAfter
  | RelativeDateBefore
  | RelativeDateOn
  | RelativeDateNext
  | RelativeDatePrevious

interface AttributeFilterSchema {
  attribute: string
  values: string[]
  includes: boolean
}

export interface RangeCondition {
  operator: string
  value: number
}

interface RangeFilterSchema {
  attribute: string
  conditions: RangeCondition[]
}

interface PodLimit {
  type: string
  value: number
  edge: string
  measure: string
}

// TODO: Create a general pod type that we can expect all over the app
export interface CreatePodSchema {
  podType: PodType
  userPodName: string
  relativeDateFilter: RelativeDateFilter
  cronExpression?: string
  dataRetentionDuration?: string
  attributeFilters?: AttributeFilterSchema[]
  rangeFilters?: RangeFilterSchema[]
  timezone?: string
  podLimit?: PodLimit
  markets?: string[]
  weatherAttributes?: WeatherAttributes
  eventBased?: boolean
  useForLoadScheduling?: boolean
}

type PodRunStatus =
  | 'runningGeneration'
  | 'completedGeneration'
  | 'completedIngestion'

type LsSubmissionStatus =
  | 'processingSubmission'
  | 'completedSubmission'
  | 'failedSubmisssion'

export interface PodListSummary {
  userPodId: string
  userId: string
  userPodName: string
  /** Unique combination of insight and metric */
  podType: PodType
  cronExpression?: string
  eventBased?: boolean
  useForLoadScheduling?: boolean
  dataRetentionDuration?: string
  weatherAttributes?: WeatherAttributes
  markets: string[]
  relativeDateFilter: RelativeDateFilter
  attributeFilters?: AttributeFilterSchema[]
  rangeFilters?: RangeFilterSchema[]
  podLimit?: PodLimit
  hasInitialized: boolean
  datasource?: string
  currentStatus?: PodRunStatus
  currentSuccess?: boolean
  /** Last run summary */
  summary?: {
    wasSuccessful: boolean
    logTimestamp: string
  }
  /** When the pod was created in ISO format */
  createdAt: string
  /** When the pod was updated in ISO format */
  updatedAt: string
  /** Role if the pod is shared with an org  */
  sharedWithOrgRole?: Array<{ orgId: string; role: SharingRole }>
  /** List of users that this pod is shared with */
  sharedWithUsers?: UserSharingRole[]
  /** user Id that shared this pod with me */
  sharedByUserId?: string
  /** Role that the pod is shared with them */
  myRole?: SharingRole
}

export type PodStatus =
  | 'initializing'
  | 'ready'
  | 'running'
  | 'complete'
  | 'failed'

export interface Pod extends Omit<PodListSummary, 'currentStatus'> {
  /** How frequently the pod will run */
  interval?: string
  /** When the pods last run was in ISO format */
  lastRun?: string
  /** If the pod has ever been successful (has data) */
  hasBeenSuccessful?: boolean
  /** Status of the last/current pod run */
  currentStatus?: PodStatus
  /** When the pods next run will be, this is derived from the cron string
   * and must be in ISO format  */
  nextRun?: string
  /** Boolean to indicate that this is an IW default pod (shows a green badge next to the name) */
  isDefault?: boolean
}

export interface AlertCompatiblePod
  extends Omit<PodListSummary, 'currentStatus'> {
  /** Raw cron string indicating how frequently the pod will run */
  cronExpression: string
  /** Data source is required when creating an alert */
  datasource: string
  /** Humanised How frequently the pod will run */
  interval: string
  /** When the pods last run was in ISO format */
  lastRun?: string
  /** If the pod has ever been successful (has data) */
  hasBeenSuccessful?: boolean
  /** Status of the last/current pod run */
  currentStatus?: PodStatus
  /** When the pods next run will be, this is derived from the cron string
   * and must be in ISO format  */
  nextRun: string
  /** Boolean to indicate that this is an IW default pod (shows a green badge next to the name) */
  isDefault?: boolean
}

export enum SharingRole {
  VIEW = 1,
  EDIT = 2,
}

export type UserSharingRole = {
  userId: string
  sharingRole: SharingRole
}

interface Dropdown<T> {
  value: T
  isDisabled?: boolean
}

export type RetentionPeriod =
  | '1day'
  | '1week'
  | '2week'
  | '3week'
  | '1month'
  | 'indefinitely'
export type RetentionPeriodOptions = Dropdown<RetentionPeriod>

type GenericNotification<S, T, B> = {
  title: string
  subtitle?: string
  isRead: boolean
  notificationId: string
  timestamp: number
  link: string
  icon?: IconDefinition
  source: S
  notificationType: T
  body: B
}

export type PodStatusBody = {
  userPodId: string
  podType: PodType
  userPodName: string
  status: PodRunStatus
  wasSuccessful: boolean
}

export type PodSharingBody = {
  userPodId: string
  podType: PodType
  userPodName: string
  sharingStatus: boolean
  userIdThatShared: string
}

export type LoadSchedulingSubmissionBody = {
  submissionId: string
  market: PodType
  submissionRunStatus: LsSubmissionStatus
  wasSuccessful: boolean
}

type PodStatusNotification = GenericNotification<
  'ease-pods-service',
  'podStatus',
  PodStatusBody
>

type PodSharingNotification = GenericNotification<
  'ease-pods-service',
  'podSharing',
  PodSharingBody
>

type LoadSchedulingNotification = GenericNotification<
  'ease-load-scheduling-service',
  'loadSchedulingSubmission',
  LoadSchedulingSubmissionBody
>

export type Notification =
  | PodStatusNotification
  | PodSharingNotification
  | LoadSchedulingNotification

type UnknownSteps = [
  { insight: undefined },
  { metric: undefined },
  { configuration: undefined },
  { general: undefined },
  { summary: undefined },
]

type ShortTermForecastSettlementSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'settlement' },
  { configuration: ShortTermSettlementConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastMeterSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'meter' },
  { configuration: ShortTermMeterConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastCustomerSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'customerType' },
  { configuration: ShortTermCustomerTypeConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastProfileSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'profile' },
  { configuration: ShortTermProfileConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastTopDownSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'topDown' },
  { configuration: ShortTermSettlementConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastSettlementWeatherSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'settlementWeather' },
  { configuration: ShortTermSettlementWeatherConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastCustomerWeatherSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'customerTypeWeather' },
  { configuration: ShortTermCustomerTypeWeatherConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastProfileWeatherSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'profile' },
  { configuration: ShortTermProfileWeatherConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastTopDownWeatherSteps = [
  { insight: 'shortTermForecast' },
  { metric: 'topDown' },
  { configuration: ShortTermSettlementWeatherConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type RiskManagementNOPSteps = [
  { insight: 'riskManagement' },
  { metric: 'netOpenPosition' },
  { configuration: RiskManagementNOPConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type RiskManagementSupplyTradesSteps = [
  { insight: 'riskManagement' },
  { metric: 'supplyTrades' },
  { configuration: RiskManagementNOPConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type RiskManagementExplodedTradesSteps = [
  { insight: 'riskManagement' },
  { metric: 'explodedTrades' },
  { configuration: RiskManagementExplodedTradesConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceAllPerformance = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'allPerformance' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceDailyMape = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'dailyMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceMonthlyMape = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'monthlyMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceAllBrandPerformance = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'allBrandPerformance' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceDailyBrandMape = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'dailyBrandMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceMonthlyBrandMape = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'monthlyBrandMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceAllPortfolioPerformance = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'allPortfolioPerformance' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceDailyPortfolioMape = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'dailyPortfolioMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceMonthlyPortfolioMape = [
  { insight: 'shortTermForecastPerformance' },
  { metric: 'monthlyPortfolioMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownAllPerformance = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'allPerformance' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownDailyMape = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'dailyMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownMonthlyMape = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'monthlyMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownAllBrandPerformance = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'allBrandPerformance' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownDailyBrandMape = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'dailyBrandMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownMonthlyBrandMape = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'monthlyBrandMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownAllPortfolioPerformance = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'allPortfolioPerformance' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownDailyPortfolioMape = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'dailyPortfolioMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceTopDownMonthlyPortfolioMape = [
  { insight: 'shortTermForecastPerformanceTopDown' },
  { metric: 'monthlyPortfolioMape' },
  { configuration: ShortTermForecastPerformanceConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type LongTermForecastMeterSteps = [
  { insight: 'longTermForecast' },
  { metric: 'meter' },
  { configuration: ShortTermMeterConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type LongTermForecastCustomerSteps = [
  { insight: 'longTermForecast' },
  { metric: 'customerType' },
  { configuration: ShortTermCustomerTypeConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type LongTermForecastBlockTypeSteps = [
  { insight: 'longTermForecast' },
  { metric: 'blockType' },
  { configuration: LongtermBlockTypeConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type LongTermForecastProfileSteps = [
  { insight: 'longTermForecast' },
  { metric: 'profile' },
  { configuration: ShortTermProfileConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastSystemERCOTSteps = [
  { insight: 'shortTermForecastSystem' },
  { metric: 'systemERCOT' },
  { configuration: ShortTermSettlementConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastSystemPJMSteps = [
  { insight: 'shortTermForecastSystem' },
  { metric: 'systemPJM' },
  { configuration: ShortTermSettlementConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ActualUsageSettlementSteps = [
  { insight: 'actualUsage' },
  { metric: 'settlement' },
  { configuration: ActualUsageSettlementConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ActualUsageMeterHourlySteps = [
  { insight: 'actualUsage' },
  { metric: 'meterHourly' },
  { configuration: ActualUsageMeterHourlyConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ActualUsageMeterDailySteps = [
  { insight: 'actualUsage' },
  { metric: 'meterDaily' },
  { configuration: ActualUsageMeterDailyConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type WholesaleSettlementSummaryERCOTSteps = [
  { insight: 'wholesaleSettlement' },
  { metric: 'summaryERCOT' },
  { configuration: WholesaleSettlementSummaryConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceSystemERCOTAllPerformanceSteps = [
  { insight: 'shortTermForecastPerformanceSystem' },
  { metric: 'systemERCOTAllPerformance' },
  { configuration: ShortTermForecastPerformanceSystemConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceSystemERCOTDailyPerformanceSteps = [
  { insight: 'shortTermForecastPerformanceSystem' },
  { metric: 'systemERCOTDailyPerformance' },
  { configuration: ShortTermForecastPerformanceSystemConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceSystemERCOTMonthlyPerformanceSteps = [
  { insight: 'shortTermForecastPerformanceSystem' },
  { metric: 'systemERCOTMonthlyPerformance' },
  { configuration: ShortTermForecastPerformanceSystemConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceSystemPJMAllPerformanceSteps = [
  { insight: 'shortTermForecastPerformanceSystem' },
  { metric: 'systemPJMAllPerformance' },
  { configuration: ShortTermForecastPerformanceSystemConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceSystemPJMDailyPerformanceSteps = [
  { insight: 'shortTermForecastPerformanceSystem' },
  { metric: 'systemPJMDailyPerformance' },
  { configuration: ShortTermForecastPerformanceSystemConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type ShortTermForecastPerformanceSystemPJMMonthlyPerformanceSteps = [
  { insight: 'shortTermForecastPerformanceSystem' },
  { metric: 'systemPJMMonthlyPerformance' },
  { configuration: ShortTermForecastPerformanceSystemConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

type AttributeInfoSteps = [
  { insight: 'attributeInfo' },
  { metric: 'meter' },
  { configuration: AttributeInfoConfig['state'] },
  { general: GeneralSettingsProps['state'] },
  { summary: undefined },
]

export type AllCreatePodSteps =
  | UnknownSteps
  | ShortTermForecastSettlementSteps
  | ShortTermForecastTopDownSteps
  | ShortTermForecastMeterSteps
  | ShortTermForecastCustomerSteps
  | ShortTermForecastProfileSteps
  | RiskManagementNOPSteps
  | RiskManagementSupplyTradesSteps
  | ShortTermForecastPerformanceAllPerformance
  | ShortTermForecastPerformanceDailyMape
  | ShortTermForecastPerformanceMonthlyMape
  | ShortTermForecastPerformanceAllBrandPerformance
  | ShortTermForecastPerformanceDailyBrandMape
  | ShortTermForecastPerformanceMonthlyBrandMape
  | ShortTermForecastPerformanceAllPortfolioPerformance
  | ShortTermForecastPerformanceDailyPortfolioMape
  | ShortTermForecastPerformanceMonthlyPortfolioMape
  | ShortTermForecastPerformanceTopDownAllPerformance
  | ShortTermForecastPerformanceTopDownDailyMape
  | ShortTermForecastPerformanceTopDownMonthlyMape
  | ShortTermForecastPerformanceTopDownAllBrandPerformance
  | ShortTermForecastPerformanceTopDownDailyBrandMape
  | ShortTermForecastPerformanceTopDownMonthlyBrandMape
  | ShortTermForecastPerformanceTopDownAllPortfolioPerformance
  | ShortTermForecastPerformanceTopDownDailyPortfolioMape
  | ShortTermForecastPerformanceTopDownMonthlyPortfolioMape
  | LongTermForecastMeterSteps
  | LongTermForecastCustomerSteps
  | LongTermForecastProfileSteps
  | LongTermForecastBlockTypeSteps
  | ShortTermForecastSystemERCOTSteps
  | ShortTermForecastSystemPJMSteps
  | RiskManagementExplodedTradesSteps
  | ShortTermForecastSettlementWeatherSteps
  | ShortTermForecastCustomerWeatherSteps
  | ShortTermForecastProfileWeatherSteps
  | ShortTermForecastTopDownWeatherSteps
  | ActualUsageSettlementSteps
  | ActualUsageMeterHourlySteps
  | ActualUsageMeterDailySteps
  | WholesaleSettlementSummaryERCOTSteps
  | ShortTermForecastPerformanceSystemERCOTAllPerformanceSteps
  | ShortTermForecastPerformanceSystemERCOTDailyPerformanceSteps
  | ShortTermForecastPerformanceSystemERCOTMonthlyPerformanceSteps
  | ShortTermForecastPerformanceSystemPJMAllPerformanceSteps
  | ShortTermForecastPerformanceSystemPJMDailyPerformanceSteps
  | ShortTermForecastPerformanceSystemPJMMonthlyPerformanceSteps
  | AttributeInfoSteps
