import React, { ReactNode } from 'react'
import styled from 'styled-components'
import IWTypography from './IWTypography'

export interface IWToggleProps {
  /** The form unique id of the input element */
  id: string
  /** Optional label to display and describe the toggle */
  label?: string
  /** Optional description to further describe the toggle */
  description?: string | ReactNode
  /** Boolean to determine if the description is below or inline with the label */
  inline?: boolean
}

type Props = IWToggleProps & React.ComponentPropsWithoutRef<'input'>

const StyledDiv = styled.span<{
  inline?: boolean
}>`
  display: flex;
  flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
`

// The odd styling here is intended to hide but not remove from SRs
const StyledHiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledToggleButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 100ms ease;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 1.25rem;
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
  background-color: ${(props) => props.theme.palette.grey[0]};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
`

const StyledToggleTrack = styled.div`
  margin: 0.125rem 0 0.125rem 0;
  width: 2.25rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.grey[200]};
`

const StyledToggleGlow = styled.div`
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  transform: translate3d(0, 0, 0);
  transition: opacity 100ms ease;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1.75rem;
  background-color: transparent;
  border: ${(props) => `0.5rem solid ${props.theme.palette.grey[300]}`};
  opacity: 0;
`

const StyledInputLabel = styled.label`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  &:hover ${StyledToggleGlow} {
    opacity: 0.3;
  }
`

const StyledInputDiv = styled.div`
  position: relative;
  display: flex;
  width: 2.25rem;
  margin-right: 0.75rem;
  height: 1.25rem;
  background-color: transparent;
  border-color: ${(props) => props.theme.palette.grey[300]};
  ${StyledHiddenInput}:checked + & ${StyledToggleButton} {
    transform: translate3d(1.25rem, 0, 0);
  }
  ${StyledHiddenInput}:checked + & ${StyledToggleTrack} {
    background-color: ${(props) => props.theme.palette.primary[700]};
  }
  ${StyledHiddenInput}:checked + & ${StyledToggleGlow} {
    transform: translate3d(1.25rem, 0, 0);
    border-color: ${(props) => props.theme.palette.primary[700]};
  }
  ${StyledHiddenInput}:focus + && {
    outline-offset: 0.125rem;
    outline: ${(props) => `${props.theme.palette.primary[800]} solid 0.125rem`};
  }
  ${StyledHiddenInput}:disabled + & ${StyledToggleButton} {
    background-color: ${(props) => props.theme.palette.primary[50]};
    border-color: ${(props) => props.theme.palette.primary[100]};
  }
  ${StyledHiddenInput}:disabled:not(:checked) + & ${StyledToggleButton} {
    background-color: ${(props) => props.theme.palette.grey[100]};
    border-color: ${(props) => props.theme.palette.grey[200]};
  }
  ${StyledHiddenInput}:disabled + & ${StyledToggleGlow} {
    opacity: 0 !important;
  }
  ${StyledHiddenInput}:disabled + & ${StyledToggleTrack} {
    background-color: ${(props) => props.theme.palette.primary[50]};
  }
  ${StyledHiddenInput}:disabled:not(:checked) + & ${StyledToggleTrack} {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`

const StyledDescription = styled(IWTypography)<{
  inline?: boolean
  hasLabel?: boolean
}>`
  margin-left: ${(props) => (props.inline && props.hasLabel ? '0.25rem' : '0')};
  margin-top: ${(props) => (props.inline || !props.hasLabel ? '0' : '0.25rem')};
`

/**
 * This represents a toggle control which is ultimately a styled checkbox such that it can be on or off, checked or not
 * You can provide a 'label', 'description' and if the label and description are 'inline' or not (default not)
 * It also forwards any refs to the underlying input element
 */
const IWToggle = React.forwardRef(
  (
    { id, label, description, inline, ...props }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <StyledInputLabel htmlFor={id}>
        <StyledHiddenInput
          id={id}
          ref={ref}
          type="checkbox"
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
        />
        <StyledInputDiv>
          <StyledToggleTrack />
          <StyledToggleGlow className="toggle-button-glow" />
          <StyledToggleButton />
        </StyledInputDiv>
        <StyledDiv inline={inline}>
          {label && (
            <IWTypography
              fontHue={{ color: 'grey', value: 700 }}
              weight="medium"
              as="span"
              size="sm"
            >
              {label}
            </IWTypography>
          )}
          {description && (
            <StyledDescription
              hasLabel={!!label}
              inline={inline}
              as="span"
              size="sm"
              fontHue={{ color: 'grey', value: 500 }}
            >
              {description}
            </StyledDescription>
          )}
        </StyledDiv>
      </StyledInputLabel>
    )
  },
)

export default IWToggle
