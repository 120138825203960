import React from 'react'
import { AvailableColors, GreyColors } from '../../../@types/styled'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import IWTypography from './IWTypography'

export type Props = {
  label: string
  icon: IconDefinition
  // For accessibility what do you want the hover to say on the icon...
  iconTitle?: string
  iconColor?: AvailableColors
  colorValue?: keyof GreyColors
}

const IconWrapper = styled.span`
  margin-right: 0.25rem;
`

const StyledIWTypography = styled(IWTypography)`
  margin-right: 0.5rem;
`

/**
 * This is a grey badge that does not allow any actions, but let's you provide a leading icon. It doesn't give you a background color like `<IWGeneralBadge />`
 */
const IWCardBadge = ({
  icon,
  label,
  iconTitle,
  iconColor,
  colorValue,
}: Props) => {
  return (
    <StyledIWTypography
      data-testid="card-badge"
      as="span"
      size="sm"
      fontHue={{ color: iconColor || 'grey', value: colorValue || 500 }}
      weight="medium"
    >
      <IconWrapper title={iconTitle}>
        <FontAwesomeIcon icon={icon} />
      </IconWrapper>
      {label}
    </StyledIWTypography>
  )
}

export default IWCardBadge
