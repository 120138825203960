import React, { ReactNode, useState } from 'react'
import { PodType } from 'tools/insightsManager/types'

type InsightManagerCategoryContextType = {
  selectedInsightCategory: Array<PodType['insightId']>
  setSelectedInsightCategory: (val: Array<PodType['insightId']>) => void
}

const InsightCategoryContext =
  React.createContext<InsightManagerCategoryContextType>({
    selectedInsightCategory: [],
    setSelectedInsightCategory: () => null,
  })

type ContextProviderProps = {
  children: ReactNode
}

const InsightCategoryProvider = ({ children }: ContextProviderProps) => {
  const [selectedInsightCategory, setSelectedInsightCategory] = useState<
    Array<PodType['insightId']>
  >([])

  return (
    <InsightCategoryContext.Provider
      value={{
        selectedInsightCategory,
        setSelectedInsightCategory: (val: Array<PodType['insightId']>) => {
          setSelectedInsightCategory(val)
        },
      }}
    >
      {children}
    </InsightCategoryContext.Provider>
  )
}

export { InsightCategoryProvider }

export default InsightCategoryContext
