import React, { ReactNode } from 'react'
import { Theme } from 'shared/contexts/LayoutContext'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { generalTheme } from './appThemes'

export const GlobalStyles = createGlobalStyle`
* {
    outline: none;
    outline-offset: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Open Sans', sans-serif;
}

*:focus {
  outline: none !important;
}

body {
  background: ${(props) => props.theme.palette.grey[0]};
}
`

interface Props {
  children: ReactNode
  theme: Theme
}

function handleSelectedTheme(theme: Theme) {
  if (theme === 'machine') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }
  return theme
}

const ThemeContextProvider = ({ children, theme }: Props) => {
  const selectedTheme = handleSelectedTheme(theme)

  return (
    <ThemeProvider theme={generalTheme(selectedTheme)}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default ThemeContextProvider
