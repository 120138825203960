import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { setLocale } from 'yup'

const { REACT_APP_TRANSLATION_ENV } = process.env

export const availableLanguages = ['en', 'ja', 'es'] as const

export type AvailableLanguages = (typeof availableLanguages)[number]

const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=W4k4qeOm1G3-Iz0k54n40w`

let config

const defaultConfig = {
  detection: { order: ['localStorage', 'navigator'] },
  fallbackLng: 'en',
  supportedLngs: availableLanguages,
  debug: process.env.NODE_ENV !== 'production',
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged',
  },
  interpolation: {
    escapeValue: false,
    format(value, format) {
      if (value === undefined) {
        return value
      }
      switch (format) {
        case 'uppercase':
          return value.toUpperCase()
        case 'lowercase':
          return value.toLowerCase()
        case 'capitalize':
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`
        default:
          return value
      }
    },
  },
}

if (!REACT_APP_TRANSLATION_ENV) {
  config = {
    ...defaultConfig,
    ns: ['default'],
    defaultNS: 'default',
    backend: {
      loadPath,
    },
  }
} else {
  config = defaultConfig
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(config)

setLocale({
  mixed: {
    default: 'forms.errors.invalidField',
    required: ({ path }) => ({
      key: 'forms.errors.requiredField',
      values: { path },
    }),
  },
  number: {
    min: ({ min, path }) => ({
      key: 'forms.errors.tooSmall',
      values: { path, min },
    }),
    max: ({ max, path }) => ({
      key: 'forms.errors.tooLarge',
      values: { path, max },
    }),
  },
  string: {
    min: ({ min, path }) => ({
      key: 'forms.errors.tooShort',
      values: { path, min },
    }),
    max: ({ max, path }) => ({
      key: 'forms.errors.tooLong',
      values: { path, max },
    }),
  },
})

export default i18n
