import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Props as IWTabProps } from './IWTab'

type IWTabType = ReactElement<IWTabProps>
type Props = {
  children: IWTabType[]
  /** 'onTabChange' function to call when the tabs are clicked, passes the tab index */
  onTabChange?: (tabIndex: number) => void
  /** The integer value for the active tab to be displayed as active (blue border) */
  activeTabIndex?: number
}

const StyledTabs = styled.div<{}>`
  display: flex;
  flex-flow: wrap;
  border-bottom: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
`

/**
 * The tabs component for the app, displays a list of 'IWTabs' in a row and manages their state
 * 'activeTabIndex' the integer value for the active tab to be displayed as active (blue border)
 * 'onTabChange' function to call when the tabs are clicked, passes the tab index
 */
const IWTabs = ({ activeTabIndex, onTabChange, children }: Props) => {
  return (
    <StyledTabs data-testid="tabs">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          active: index === activeTabIndex,
          onClick: () => onTabChange && onTabChange(index),
        })
      })}
    </StyledTabs>
  )
}

export default IWTabs
