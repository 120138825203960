import styled from 'styled-components'

const IWDivider = styled('hr')`
  background-color: ${(props) => props.theme.palette.grey[300]};
  margin: 0.5rem 0;
  height: 1px;
  border: none;
`

export default IWDivider
