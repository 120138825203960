import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import { useTranslation } from 'react-i18next'
import IWProgressTracker from 'shared/components/thunderbolt/IWProgressTracker'
import IWFormContainer from 'shared/components/thunderbolt/IWFormContainer'

import PodGeneralSettingStep from 'tools/insightsManager/components/PodGeneralSettingStep'
import SelectPodInsightStep from 'tools/insightsManager/components/SelectPodInsightStep'
import PodBuilderSummary from 'tools/insightsManager/components/PodBuilderSummary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faExclamationTriangle,
  faSync,
} from '@fortawesome/pro-regular-svg-icons'
import {
  AllCreatePodSteps,
  CreatePodSchema,
  PodType,
} from 'tools/insightsManager/types'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import {
  formatAttributeFilters,
  formatLimitFilter,
  formatRangeFilters,
  formatRelativeDate,
} from 'tools/insightsManager/helpers'
import { createPod } from 'shared/podServiceClient'
import { useNavigate } from 'react-router'
import generateCronExpression from 'shared/utils/generateCronExpression'
import generateRetentionDuration from 'shared/utils/generateRententionDuration'
import ConfigurationWrapper from 'tools/insightsManager/components/ConfigurationWrapper'
import ConfirmChangesModal from 'tools/insightsManager/components/ConfirmChangesModal'
import MetricWrapper from './MetricWrapper'
import IWAlert from 'shared/components/thunderbolt/IWAlert'
import {
  createSFTPSubscription,
  createThirdPartyDriveSubscription,
} from '../../../systemPreferences/integrationsServiceClient'
import LayoutContext from 'shared/contexts/LayoutContext'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 8rem;
`

const FixedNavBar = styled.div`
  position: fixed;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette.primary[50]};
`

type ValueOf<T> = T[keyof T]

type WizardStep<T> = { isStepValid: boolean; value: ValueOf<T>; name: keyof T }
export type WizardStepBuilder<T> = { [P in keyof T]: WizardStep<T[P]> }

const wizardSteps = (): WizardStepBuilder<AllCreatePodSteps> => [
  {
    name: 'insight',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'metric',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'configuration',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'general',
    isStepValid: false,
    value: undefined,
  },
  {
    name: 'summary',
    isStepValid: false,
    value: undefined,
  },
]

enum Steps {
  insight,
  metric,
  configuration,
  general,
  summary,
}

const InsightBadge = ({
  insight,
  metric,
}: {
  insight?: PodType['insightId']
  metric?: PodType['metricId']
}) => {
  const { t } = useTranslation()
  return (
    <IWBadgeGroup>
      {insight && (
        <IWGeneralBadge
          data-testid="create-pod-insight-badge"
          label={`${t(`insightManager.createPod.insight`)}: ${t(
            `insights.${insight}.label`,
          )}`}
          color="primary"
        />
      )}
      {metric && (
        <IWGeneralBadge
          data-testid="create-pod-metric-badge"
          label={`${t(`insightManager.createPod.metric`)}: ${t(
            `insights.${insight}.metrics.${metric}.label`,
          )}`}
          color="primary"
        />
      )}
    </IWBadgeGroup>
  )
}

/**
 * TODO: Perhaps the form tracker and action buttons (bottom)
 * should be extracted to their own more abstracted component.
 * That way this component only focuses on the form sections.
 */
/**
 * This component controls the pod creation flow resulting in a
 * form payload to be sent to the server
 */
const CreatePodWizard = () => {
  const { t } = useTranslation()
  const { timezone } = useContext(LayoutContext)
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [isChangePopupOpen, setIsChangePopupOpen] = useState(false)
  const wizardStateRef = useRef<WizardStepBuilder<AllCreatePodSteps>>(
    wizardSteps(),
  )
  const tempInsightSelectionRef =
    useRef<AllCreatePodSteps[Steps.insight]['insight']>()
  const tempPodCalculationSelectionRef =
    useRef<AllCreatePodSteps[Steps.metric]['metric']>()
  const tempConfigurationRef = useRef<
    AllCreatePodSteps[Steps.configuration]['configuration']
  >(wizardStateRef[Steps.configuration])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const navigate = useNavigate()

  const getDateRange = () => {
    const [, , configuration] = wizardStateRef.current
    return configuration.value?.dateRange
  }

  const handlePodBuilderFormSubmit = async () => {
    const [insight, metric, configuration, general] = wizardStateRef.current
    if (
      !insight.value ||
      !metric.value ||
      !configuration.value?.marketFilter ||
      !configuration.value?.dateRange ||
      !general.value?.userPodName
    ) {
      return
    }

    setIsLoading(true)
    const attributeFilters = formatAttributeFilters(
      configuration.value?.attributeFilters,
    )

    const pod: CreatePodSchema = {
      podType: {
        insightId: insight.value,
        metricId: metric.value,
      } as PodType,
      userPodName: general.value.userPodName.trim(),
      relativeDateFilter: {
        ...formatRelativeDate(configuration.value.dateRange),
        timezone,
      },
      markets: configuration.value.marketFilter.map((m) => m.value),
      attributeFilters: attributeFilters?.filter(
        (element) => element.attribute !== 'market',
      ),
      rangeFilters: !configuration.value.rangeFilters
        ? undefined
        : formatRangeFilters(configuration.value.rangeFilters),
      podLimit: !configuration.value.limit
        ? undefined
        : formatLimitFilter(configuration.value.limit),
      eventBased: general.value.eventBased,
      useForLoadScheduling: configuration.value.useForLoadScheduling || false,
      cronExpression: !general.value.cronSelectorInputState
        ? undefined
        : generateCronExpression(general.value.cronSelectorInputState),
      dataRetentionDuration: !general.value.retentionPeriod
        ? undefined
        : generateRetentionDuration(general.value.retentionPeriod.value),
      weatherAttributes: configuration.value.weatherAttributes,
    }

    try {
      const newPodId = await createPod(pod)
      toast.secondary(
        t(`insightManager.createPod.podCreationSuccess`, {
          userPodName: general.value?.userPodName,
          canDismiss: true,
        }),
      )
      if (
        general.value.hasIntegrations &&
        general.value.sftpCredentialIds.length > 0
      ) {
        await createSFTPSubscription({
          userPodId: newPodId,
          credentialIds: general.value.sftpCredentialIds,
          outputFileName: general.value.userPodName,
        })
      }
      if (
        general.value.hasIntegrations &&
        general.value.thirdPartyDriveCredentialIds.length > 0
      ) {
        await createThirdPartyDriveSubscription({
          userPodId: newPodId,
          credentialIds: general.value.thirdPartyDriveCredentialIds,
          outputFileName: general.value.userPodName,
        })
      }
      navigate('/tools/insights-manager')
    } catch (e: any) {
      let message = t('insightManager.createPod.errors.podCreationError')
      if (e.response.status === 403) {
        message = t('insightManager.createPod.alertCreatePodLimit')
      }
      toast.alert(message, {
        status: e.response.status,
        canDismiss: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getPodType = () => {
    const insightId = wizardStateRef.current[Steps.insight].value
    const metricId = wizardStateRef.current[Steps.metric].value
    if (insightId !== undefined && metricId !== undefined) {
      return {
        insightId,
        metricId,
      } as PodType
    }
    return undefined
  }

  const getActiveStep = () => {
    switch (activeStepIndex) {
      case Steps.insight:
        return (
          <SelectPodInsightStep
            state={wizardStateRef.current[Steps.insight].value}
            onChange={(value, isStepValid) => {
              wizardStateRef.current[Steps.insight] = {
                ...wizardStateRef.current[Steps.insight],
                isStepValid,
                value,
              }
            }}
            showErrors={showErrors}
          />
        )
      case Steps.metric:
        return (
          <>
            <IWFormSection
              sectionTitle={t(`insightManager.createPod.innoPodDetails`)}
            >
              <InsightBadge
                insight={wizardStateRef.current[Steps.insight].value}
              />
            </IWFormSection>
            <MetricWrapper
              insight={wizardStateRef.current[Steps.insight].value}
              state={wizardStateRef.current[Steps.metric].value}
              showErrors={showErrors}
              onChange={(value, isStepValid) => {
                wizardStateRef.current[Steps.metric] = {
                  ...wizardStateRef.current[Steps.metric],
                  isStepValid,
                  value,
                }
              }}
            />
          </>
        )
      case Steps.configuration:
        return (
          <>
            <IWFormSection
              sectionTitle={t(`insightManager.createPod.innoPodDetails`)}
            >
              <InsightBadge
                insight={wizardStateRef.current[Steps.insight].value}
                metric={wizardStateRef.current[Steps.metric].value}
              />
            </IWFormSection>
            <IWAlert
              icon={faCircleExclamation}
              label={t('insightManager.createPod.warnings.dateRangeWarning')}
              color="primary"
              variant="alternative"
            />
            <ConfigurationWrapper
              podType={getPodType()}
              state={wizardStateRef.current[Steps.configuration].value}
              onChange={(value, isStepValid) => {
                wizardStateRef.current[Steps.configuration] = {
                  ...wizardStateRef.current[Steps.configuration],
                  isStepValid,
                  value,
                }
              }}
              showErrors={showErrors}
            />
          </>
        )
      case Steps.general:
        return (
          <>
            <IWFormSection
              sectionTitle={t(`insightManager.createPod.innoPodDetails`)}
            >
              <InsightBadge
                insight={wizardStateRef.current[Steps.insight].value}
                metric={wizardStateRef.current[Steps.metric].value}
              />
            </IWFormSection>
            <PodGeneralSettingStep
              insightId={wizardStateRef.current[Steps.insight].value}
              metricId={wizardStateRef.current[Steps.metric].value}
              state={wizardStateRef.current[Steps.general].value}
              onChange={(value, isStepValid) => {
                wizardStateRef.current[Steps.general] = {
                  ...wizardStateRef.current[Steps.general],
                  isStepValid,
                  value,
                }
              }}
              dateRange={getDateRange()}
              showErrors={showErrors}
            />
          </>
        )
      case Steps.summary: {
        // We aren't using the insight nor metric value... be aware of the commas here...
        const [, , configuration, general] = wizardStateRef.current
        const podType = getPodType()
        if (
          !podType ||
          !configuration.value?.marketFilter ||
          !configuration.value?.dateRange ||
          !general.value?.userPodName ||
          !general.value?.retentionPeriod ||
          !general.value.cronSelectorInputState
        ) {
          return <></>
        }
        return (
          <PodBuilderSummary
            podType={podType}
            markets={configuration.value.marketFilter}
            dateRangeConfig={configuration.value.dateRange}
            attributes={configuration.value.attributeFilters || []}
            rangeFilters={configuration.value.rangeFilters}
            limits={configuration.value.limit}
            general={{
              userPodName: general.value.userPodName,
              retentionPeriod: general.value.retentionPeriod,
              cronSelectorInputState: general.value.cronSelectorInputState,
              eventBased: general.value.eventBased,
              useForLoadScheduling:
                configuration.value.useForLoadScheduling || false,
              hasIntegrations: general.value.hasIntegrations,
              sftpCredentialIds: general.value.sftpCredentialIds,
              thirdPartyDriveCredentialIds:
                general.value.thirdPartyDriveCredentialIds,
            }}
            weatherAttributes={configuration.value.weatherAttributes}
          />
        )
      }
      default:
        return <></>
    }
  }

  const onBack = () => {
    if (activeStepIndex !== 0) {
      setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex - 1)
    }
  }

  function setNewTempInsightAndPodSelections() {
    const [insight, metric, configuration] = wizardStateRef.current
    if (activeStepIndex === Steps.insight) {
      tempInsightSelectionRef.current = insight.value
    }
    if (activeStepIndex === Steps.metric) {
      tempPodCalculationSelectionRef.current = metric.value
    }
    if (activeStepIndex === Steps.configuration) {
      tempConfigurationRef.current = configuration.value
    }
  }

  const handleStepChange = (stepId: number) => {
    if (
      !wizardStateRef.current[activeStepIndex].isStepValid &&
      activeStepIndex < stepId
    ) {
      setShowErrors(true)
      return
    }
    const [insight, metric, configuration] = wizardStateRef.current

    const hasInsightSelectionChanged =
      tempInsightSelectionRef.current !== undefined &&
      tempInsightSelectionRef.current !== insight.value &&
      activeStepIndex === Steps.insight
    const hasPodCalculationSelectionChanged =
      tempPodCalculationSelectionRef.current !== undefined &&
      tempPodCalculationSelectionRef.current !== metric.value &&
      activeStepIndex === Steps.metric
    // Since pod cron options depends on the pod date range we check if date range has changed
    const hasPodDateRangeChanged =
      tempConfigurationRef.current !== undefined &&
      tempConfigurationRef.current.dateRange?.selectedMainOption?.value !==
        configuration.value?.dateRange?.selectedMainOption?.value &&
      activeStepIndex === Steps.configuration

    if (
      hasInsightSelectionChanged ||
      hasPodCalculationSelectionChanged ||
      hasPodDateRangeChanged
    ) {
      setIsChangePopupOpen(true)
      return
    }

    setNewTempInsightAndPodSelections()
    setShowErrors(false)
    setActiveStepIndex(stepId)
  }

  const handleClose = () => {
    setIsChangePopupOpen(false)
  }

  const handleChangeWizardConfirm = () => {
    if (activeStepIndex < Steps.configuration) {
      wizardStateRef.current[Steps.configuration] = {
        name: 'configuration',
        isStepValid: false,
        value: undefined,
      }
    }
    if (activeStepIndex < Steps.general) {
      wizardStateRef.current[Steps.general] = {
        name: 'general',
        isStepValid: false,
        value: undefined,
      }
    }
    if (activeStepIndex < Steps.summary) {
      wizardStateRef.current[Steps.summary] = {
        name: 'summary',
        isStepValid: false,
        value: undefined,
      }
    }
    setNewTempInsightAndPodSelections()
    setIsChangePopupOpen(false)
    setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex + 1)
  }

  useEffect(() => {
    return () => {
      wizardStateRef.current = wizardSteps()
      tempInsightSelectionRef.current = undefined
      tempPodCalculationSelectionRef.current = undefined
      tempConfigurationRef.current = undefined
    }
  }, [])

  return (
    <>
      <StyledDiv id="form-div">
        <IWProgressTracker
          steps={wizardStateRef.current.map((s) =>
            t(`insightManager.createPod.${s.name}`),
          )}
          currentActiveIndex={activeStepIndex}
          onStepClick={(e, id) => handleStepChange(id)}
        />
        <IWFormContainer>{getActiveStep()}</IWFormContainer>
        <ConfirmChangesModal
          icon={faExclamationTriangle}
          title={t(`insightManager.createPod.confirmChangeLabel`)}
          body={t(`insightManager.createPod.confirmChangeDesc`)}
          isOpen={isChangePopupOpen}
          handleClose={handleClose}
          handleConfirm={handleChangeWizardConfirm}
        />
      </StyledDiv>
      <FixedNavBar>
        <IWButton
          variant="outline"
          onClick={onBack}
          data-testid="create-pod-back"
          disabled={activeStepIndex === Steps.insight || isLoading}
        >
          {t('button.back')}
        </IWButton>
        {activeStepIndex === Steps.summary ? (
          <IWButton
            data-testid="create-pod-create"
            disabled={isLoading}
            onClick={() => handlePodBuilderFormSubmit()}
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSync} />
            ) : (
              t('insightManager.createPod.createPod')
            )}
          </IWButton>
        ) : (
          <IWButton
            data-testid="create-pod-next"
            onClick={() => handleStepChange(activeStepIndex + 1)}
          >
            {t('button.next')}
          </IWButton>
        )}
      </FixedNavBar>
    </>
  )
}

export default CreatePodWizard
