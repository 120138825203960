import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { useTranslation } from 'react-i18next'
import IWModal from 'shared/components/thunderbolt/IWModal'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWToggle from 'shared/components/thunderbolt/IWToggle'
import IWAccordion from 'shared/components/thunderbolt/IWAccordion'
import IWToggleGroup from 'shared/components/thunderbolt/IWToggleGroup'
import {
  NotificationOptionType,
  Notifications,
} from 'systemPreferences/hooks/useNotificationSelector'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type Props = {
  /**
   * notitification type example - 'inApp' | 'email' | 'slack'
   */
  notificationType: NotificationOptionType
  /**
   * icon that could be put in front of title
   */
  icon: IconProp
  /**
   * notification options to show in modal
   */
  notifications: Notifications
  /**
   * if modal is open or not
   */
  isOpen: boolean
  /** Function to call when the close is pressed */
  handleCancel: () => void
  /** Function to call when the confirm is pressed */
  handleConfirm: (changed) => void
}

const StyledModalContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-width: 512px;
  padding: 1.5rem;
`

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
const StyledModalButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1.5rem;
  background: ${(props) => props.theme.palette.grey[100]};
`

const StyledToggleAllButton = styled(IWButton)`
  text-align: left;
`

/**
 * Notification Modal is a general pupose modal to support
 * several notification type , the modal itself does not hold any
 * state related to notification , it only hold local changes to prompt
 * user for changed and unchanged toggles in terms of confirm button disablity
 */
const NotificationSelectorModal = ({
  notificationType,
  icon,
  notifications,
  isOpen,
  handleCancel,
  handleConfirm,
}: Props) => {
  const { t } = useTranslation()
  const [alertOptions, setAlertOptions] = useState<Notifications>(notifications)
  const [allNotificationState, setAllNotification] = useState<boolean>(true)
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)

  const updateNotifications = (type, alertId, isEnabled) => {
    setAlertOptions((prev) => {
      return {
        ...prev,
        [type]: prev[type].map((alert) => {
          if (alert.id === alertId) {
            return {
              id: alert.id,
              isEnabled,
            }
          }
          return alert
        }),
      }
    })
  }

  const updateAllNotification = () => {
    setAllNotification(!allNotificationState)
    Object.keys(alertOptions).map((key) =>
      alertOptions[key].map(({ id }) =>
        updateNotifications(key, id, allNotificationState),
      ),
    )
  }

  useEffect(() => {
    let isDisabled = true
    Object.keys(alertOptions).map((key) =>
      alertOptions[key].map(({ isEnabled }, index) => {
        if (notifications[key][index].isEnabled !== isEnabled) {
          isDisabled = false
        }
        return true
      }),
    )
    setSubmitDisabled(isDisabled)
  }, [alertOptions, notifications])

  return (
    <IWModal open={isOpen} onClose={handleCancel}>
      <StyledModalContainer>
        <IWProductCard
          header={t(`notification.${notificationType}.header`)}
          icon={icon}
          iconColor="grey"
        />
        <IWTypography
          size="xs"
          weight="regular"
          fontHue={{ color: 'grey', value: 500 }}
        >
          {t('systemPreferences.systemNotification.popupDesc')}
        </IWTypography>
        <StyledToggleAllButton
          variant="anchorMain"
          onClick={() => updateAllNotification()}
        >
          {t('systemPreferences.systemNotification.toggleAllNotifications')}
        </StyledToggleAllButton>
        <StyledModalContent>
          {Object.keys(alertOptions).map((key) => (
            <IWAccordion
              title={t(`notification.${notificationType}.${key}.header`)}
              key={key}
            >
              <IWToggleGroup>
                {alertOptions[key].map(({ id, isEnabled }) => (
                  <IWToggle
                    key={id}
                    id={id}
                    label={t(
                      `notification.${notificationType}.${key}.notifications.${id}.header`,
                    )}
                    description={t(
                      `notification.${notificationType}.${key}.notifications.${id}.desc`,
                    )}
                    name={id}
                    checked={isEnabled}
                    onClick={() => updateNotifications(key, id, !isEnabled)}
                  />
                ))}
              </IWToggleGroup>
            </IWAccordion>
          ))}
        </StyledModalContent>
      </StyledModalContainer>
      <StyledModalButtonSection>
        <IWButton
          as="button"
          type="button"
          variant="outline"
          color="primary"
          onClick={handleCancel}
        >
          {t(`button.cancel`)}
        </IWButton>
        <IWButton
          disabled={submitDisabled}
          as="button"
          type="button"
          variant="main"
          color="primary"
          onClick={() => handleConfirm(alertOptions)}
        >
          {t(`button.confirm`)}
        </IWButton>
      </StyledModalButtonSection>
    </IWModal>
  )
}

export default NotificationSelectorModal
