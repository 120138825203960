import React, { ReactElement, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Popover, PopoverAlign, PopoverPosition } from 'react-tiny-popover'

export interface Props {
  /**
   * Children here will be wrapped in an onClick to toggle the menu,
   * it is intended to pass the toggle button for the menu
   */
  children: JSX.Element
  /**
   * The array of elements to display in the pop over menu
   * This is not restricted to IWContextMeuItm since we may need titles/dividers etc too
   */
  menu: ReactElement[]
  /** Boolean to determine if the menu is open or closed */
  isOpen?: boolean
  onClickOutside?: () => void
  /** Sets the position of the popover to the child element 'right' | 'left' | 'top' | 'bottom'
   This is an array which operates by finding the most appropriate position based on your
   selected priority, ['right' 'top'] would try to put it right but if there is no room, top
   */
  positions: PopoverPosition[]
  /** Sets the alignment of the popover to the child element 'start' | 'center' | 'end' */
  align?: PopoverAlign
}

const StyledDiv = styled.div`
  min-width: 16rem;
  max-height: 300px;
  overflow-y: auto;
  background: ${(props) => props.theme.palette.grey[0]};
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
`

const StyledButton = styled.div`
  cursor: pointer;
`

/**
 * The Contextual Menu to be used throughout the app to display a list of IWContextMenuItems
 * This wraps the PopOver component and merely handles the open state
 */
const IWContextMenu = ({
  children,
  isOpen,
  onClickOutside,
  menu,
  align = 'end',
  positions = ['bottom'],
}: Props) => {
  const theme = useTheme()
  const [localIsOpen, setLocalIsOpen] = useState<boolean>(isOpen ?? false)

  const handleSetIsOpen = (value: boolean) => {
    setLocalIsOpen(value)
  }

  return (
    <Popover
      align={align}
      containerStyle={{ zIndex: theme.layers.offcanvas }} // 1 below modal
      padding={8} // 0.5rem
      positions={positions}
      reposition // Prevents moving offscreen after scrolling with fixed parent
      content={<StyledDiv>{menu}</StyledDiv>}
      isOpen={isOpen ?? localIsOpen}
      onClickOutside={(e) => {
        e.preventDefault()
        if (isOpen !== undefined && onClickOutside !== undefined) {
          onClickOutside()
        } else {
          handleSetIsOpen(false)
        }
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      {isOpen === undefined ? (
        <StyledButton
          role="button"
          onClick={() => {
            handleSetIsOpen(!localIsOpen)
          }}
        >
          {children}
        </StyledButton>
      ) : (
        <span>{children}</span>
      )}
    </Popover>
  )
}

export default IWContextMenu
