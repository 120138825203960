import React, { ReactEventHandler, useEffect, useState } from 'react'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IWError } from 'shared/components/thunderbolt/IWCommonLabels'
import IWDropdown, {
  DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { AttributeFilter as AttributeFilterType } from 'tools/insightsManager/types'
import FilterSection from 'tools/insightsManager/components/FilterSection'

export type Props = {
  /** Object containing any errors by key */
  hasErrors?: {
    attribute?: boolean
    condition?: boolean
    values?: boolean
  }
  /** The filter object from the parent */
  filter: AttributeFilterType
  /** The dropdown options for the attribute */
  attributes: DropdownValueProps[]
  /** The dropdown attributes for the conditions */
  conditions: DropdownValueProps[]
  /** Handler to delete this component from the parent list  */
  onDelete: ReactEventHandler
  /** "live" search function to populate results for values based on user input */
  onGetOptions: (attributeType, inputValue) => Promise<DropdownValueProps[]>
  /** populates an initial list of 10 values when an attribute is selected */
  getInitialAttributeValues: (attribute) => Promise<DropdownValueProps[]>
  /** Handler to emit the local changes in this component to the parent.  */
  onSetState: (AttributeFilter) => void
}

const StyledAttributeFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
`

const StyledIWButton = styled.div`
  align-self: flex-end;
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 100%;
`

/**
 * A row of controls allowing the user to set conditions and values for a specific
 * attribute of a pod
 */
const AttributeFilter = ({
  hasErrors,
  filter,
  attributes,
  conditions,
  onDelete,
  onGetOptions,
  getInitialAttributeValues,
  onSetState,
}: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [defaultOptions, setDefaultOptions] = useState<DropdownValueProps[]>([])

  // When we change the attribute, we need to reset values and load more
  const handleAttributeChange = async (option: DropdownValueProps) => {
    // Trigger loading here to disable the values input while we reset
    setLoading(true)
    const newState = {
      ...filter,
      attribute: option,
      values: [],
    }
    // Emit the new state to the parent
    onSetState(newState)
    // get the default attributes for this one
    setDefaultOptions(await getInitialAttributeValues(option.value))
    setLoading(false)
  }

  const handleConditionChange = (option) => {
    // Emit the new state to the parent
    onSetState({
      ...filter,
      condition: option,
    })
  }

  const handleValueChange = (option) => {
    // Emit the new state to the parent
    onSetState({
      ...filter,
      values: option && !Array.isArray(option) ? [option] : option,
    })
  }

  useEffect(() => {
    const { defaultSelectedValue, values } = filter
    const attribute = attributes.find(
      ({ value }) => value === defaultSelectedValue,
    )
    if (attribute && defaultSelectedValue && !values) {
      handleAttributeChange(attribute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (defaultOptions.length && filter.defaultSelectedValue) {
      const [firstOption] = defaultOptions
      handleValueChange(firstOption)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions])

  return (
    <FilterSection>
      <StyledAttributeFilter>
        <StyledIWDropdown
          name="attribute"
          required
          fullWidth
          label={t('attributeFilterSection.attribute')}
          value={filter.attribute}
          onChange={handleAttributeChange}
          options={attributes}
          hasError={hasErrors?.attribute}
          isDisabled={Boolean(filter.isDisabled)}
        />

        <StyledIWDropdown
          data-testid="select-condition"
          name="condition"
          required
          fullWidth
          value={filter.condition}
          label={t('attributeFilterSection.condition')}
          onChange={handleConditionChange}
          options={conditions}
          hasError={hasErrors?.condition}
          isDisabled={Boolean(filter.isDisabled)}
        />

        <StyledIWDropdown
          isAsync
          name="values"
          isDisabled={!filter.attribute || loading}
          isMulti={!filter.isValuesSingleSelect}
          required
          fullWidth
          value={filter.values}
          label={t('attributeFilterSection.values')}
          loadOptions={(inputValue) =>
            filter.attribute && onGetOptions(filter.attribute.value, inputValue)
          }
          defaultOptions={filter.attribute && defaultOptions}
          onChange={handleValueChange}
          hasError={hasErrors?.values}
          placeholder={t('attributeFilterSection.startTypingToSeeOptions')}
        />

        <StyledIWButton>
          <IWButton
            data-testid="delete-filter"
            variant="outline"
            color="grey"
            type="button"
            onClick={onDelete}
            disabled={Boolean(filter.isDisabled)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IWButton>
        </StyledIWButton>
      </StyledAttributeFilter>
      {hasErrors &&
        Object.keys(hasErrors).some((key) => {
          return hasErrors[key]
        }) && (
          <IWError
            label={t('insightManager.createPod.errors.enterAllRequired')}
          />
        )}
    </FilterSection>
  )
}

export default AttributeFilter
