import { DateTime, Duration, Interval } from 'luxon'

export const getDaysOfWeek = (weekStartsOn = 7) => {
  let weekStart = weekStartsOn
  if (weekStart > 7 || weekStart < 1) {
    weekStart = 7
  }
  const today = DateTime.local()
  const intervals = Interval.fromDateTimes(
    today.startOf('week'),
    today.endOf('week'),
  ).splitBy(Duration.fromObject({ day: 1 }))

  const index = intervals.findIndex(
    (interval) => interval.start.weekday === weekStart,
  )
  const shiftedArr = intervals.concat(intervals.splice(0, index))
  return shiftedArr.map((d) => ({
    weekday: d.start.weekday,
    weekdayLong: d.start.weekdayLong,
    weekdayShort: d.start.weekdayShort,
  }))
}

export const getMonthsOfYear = () => {
  const today = DateTime.local()
  const intervals = Interval.fromDateTimes(
    today.startOf('year'),
    today.endOf('year'),
  ).splitBy(Duration.fromObject({ month: 1 }))

  return intervals.map((d) => ({
    month: d.start.month,
    monthLong: d.start.monthLong,
    monthShort: d.start.monthShort,
  }))
}

export const getHoursOfDay = () => {
  const today = DateTime.local()
  const intervals = Interval.fromDateTimes(
    today.startOf('day'),
    today.endOf('day'),
  ).splitBy(Duration.fromObject({ hour: 1 }))

  return intervals.map((d) => ({
    hour: d.start.hour,
  }))
}

export const getWeekdayOptions = () => {
  return getDaysOfWeek(1).map(({ weekday, weekdayLong }) => ({
    value: weekday,
    label: weekdayLong.charAt(0).toUpperCase(),
    labelLong: weekdayLong,
  }))
}

export const getHourOptions = () => {
  const hours = getHoursOfDay()

  return hours.map(({ hour }) => ({
    value: hour,
    label: `${String(hour).padStart(2, '0')}:00`,
  }))
}

export const getMonthOptions = () => {
  const monthOptions = getMonthsOfYear()

  return monthOptions.map(({ month, monthLong }) => ({
    value: month,
    label: monthLong,
  }))
}

export const shiftCronHourFromLocalToUTC = (
  selectedHour: number = 0,
  isoDate: string = DateTime.local().toISO(),
) => {
  const localTime = DateTime.fromISO(isoDate).set({ hour: selectedHour })
  const utcTime = DateTime.fromISO(localTime.toISO()).toUTC()
  return {
    day: utcTime.get('day'),
    hour: utcTime.get('hour'),
    year: utcTime.get('year'),
    month: utcTime.get('month'),
    minute: utcTime.get('minute'),
    second: utcTime.get('second'),
    millisecond: utcTime.get('millisecond'),
    isoDate: utcTime.toISO(),
  }
}
