import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { round } from 'mathjs'
import styled from 'styled-components'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import {
  faAlarmExclamation,
  faArrowLeft,
  faCube,
  faDownload,
  faFlask,
  faGaugeMax,
  faLightbulbOn,
} from '@fortawesome/pro-regular-svg-icons'
import { useQuery } from 'react-query'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotFound from 'root/pages/NotFound'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import IWTable, { SortDirection } from 'shared/components/thunderbolt/IWTable'
import { paginationOptions } from 'shared/types'
import {
  getAlertTrigger,
  getPodDatacube,
  getUserAlert,
} from 'shared/podServiceClient'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import DataDownloadModal from 'shared/components/DataDownloadModal'
import { convertToCVS, downloadContent, goToURL } from 'shared/helpers'
import { DOWNLOAD_ROW_LIMIT } from 'shared/constants'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import { AlertTriggerColumns, AlertTriggerData } from '../types'
import LayoutContext from 'shared/contexts/LayoutContext'

const LeftHand = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1;
`

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.lg};
`

const Wrapper = styled.div`
  width: 100%;
  padding-top: 1.5rem;
  border-top: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
`

const PaginationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
`

/**
 * Component to display the details of an Alert Trigger
 */
const AlertTriggerDetailsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { timezone } = useContext(LayoutContext)
  const toast = useToast()
  const { alertId = '', triggerId = '' } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [isFetchingDataForDownload, setIsFetchingDataForDownload] =
    useState(false)

  const [page, setPage] = useState({
    current: 1,
    size: paginationOptions[0].value,
  })

  const [sort, setSort] = useState<{
    column: string
    direction: SortDirection
  }>({
    column: 'created_at',
    direction: 'descending',
  })

  const {
    data: trigger,
    isError,
    refetch,
    isLoading,
  } = useQuery(
    [
      'alert',
      alertId,
      'triggerId',
      triggerId,
      'pageNumber',
      page,
      'sort',
      sort,
    ],
    () => {
      return getAlertTrigger(alertId, triggerId, {
        page: page.current - 1, // postgres starts at 0 but IWTable at 1
        size: page.size,
        sort: {
          direction: sort.direction,
          column: sort.column,
        },
      })
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )

  const formatData = (
    data: AlertTriggerData[],
    triggerColumns: AlertTriggerColumns,
  ) => {
    const ROUND_PRECISION = 2
    const numberColumns = Object.entries(triggerColumns)
      .map((col) => {
        return { dimension: col[0], type: col[1].type }
      })
      .filter((col) => col.type === 'number')

    const timeColumns = Object.entries(triggerColumns)
      .map((col) => {
        return { dimension: col[0], type: col[1].type }
      })
      .filter((col) => col.type === 'datetime')

    return data.map((elem) => {
      const shallowCopy = { ...elem }
      numberColumns.forEach((col) => {
        shallowCopy[col.dimension] = Number(
          round(elem[col.dimension] as number, ROUND_PRECISION),
        )
      })
      timeColumns.forEach((col) => {
        shallowCopy[col.dimension] = DateTime.fromISO(
          shallowCopy[col.dimension] as string,
          { zone: timezone },
        ).toLocaleString(DateTime.DATETIME_SHORT)
      })
      return shallowCopy
    })
  }

  const formatColumns = (triggerColumns: AlertTriggerColumns) => {
    return Object.entries(triggerColumns).map((e) => ({
      title: e[0],
      accessor: e[0],
      align: e[1].type === 'number' ? 'right' : 'left',
      sortable: true,
    }))
  }

  const {
    data: alert,
    error: alertError,
    isLoading: alertIsInitializing,
  } = useQuery(
    `alert:${alertId}`,
    () => {
      return getUserAlert(alertId)
    },
    { refetchOnWindowFocus: false },
  )

  const handleSort = (column: string, direction: SortDirection) => {
    if (direction === null) {
      setSort({
        column,
        direction: 'descending',
      })
    } else {
      setSort({
        column,
        direction,
      })
    }
  }

  useEffect(() => {
    refetch().then(() => {
      setPage((prev) => ({ current: 1, size: prev.size })) // go back to the first page on sort
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort])

  const downloadData = async () => {
    setIsFetchingDataForDownload(true)
    // Unfiltered download
    const { results } = await getAlertTrigger(alertId, triggerId, {
      page: 0,
      size: 1000,
      sort,
    })
    const csv = convertToCVS(results.rows)
    downloadContent({
      filename: `${alertId}`,
      ext: 'csv',
      content: csv,
      mediaType: 'text/csv',
    })
    setIsFetchingDataForDownload(false)
  }

  const handleGetPodDataCube = async () => {
    try {
      setLoading(true)
      const url = await getPodDatacube(trigger?.userPodName || '')
      goToURL(url)
    } catch (e) {
      toast.alert(
        t('insightManager.pods.dataCubeError', { name: trigger?.userPodId }),
      )
    } finally {
      setLoading(false)
    }
  }

  const renderButtons = () => {
    const buttons: ReactElement[] = []
    buttons.push(
      <IWButton
        key="explore"
        icon={faFlask}
        iconPosition="leading"
        variant="outline"
        data-testid="trigger-actions-explore"
        onClick={handleGetPodDataCube}
      >
        {t('actions.explore')}
      </IWButton>,
    )

    return buttons
  }

  // Only show the modal if the number of rows is greater than the limit
  const handleDownload = async () => {
    if (trigger && trigger.results.total > DOWNLOAD_ROW_LIMIT) {
      setIsDownloadModalOpen(true)
    } else {
      await downloadData()
    }
  }

  if (alertIsInitializing || isLoading) {
    return <IWLoading />
  }

  if (alertError || isError) {
    return <IWError />
  }
  if (!isLoading && !trigger) {
    return <NotFound />
  }

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <LeftHand>
          <StyledButton
            onClick={() =>
              navigate(`/tools/alerts-manager/alert-details/${alertId}`)
            }
            data-testid="page-header-back-button"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </StyledButton>
          <StyledTitle>
            {trigger?.userAlertName} -{' '}
            {DateTime.fromISO(trigger?.createdAt, {
              zone: timezone,
            }).toLocaleString(DateTime.DATETIME_SHORT)}
          </StyledTitle>
        </LeftHand>
        <IWButtonGroup color="primary">{renderButtons()}</IWButtonGroup>
      </IWPageHeader>
      <main>
        <IWBadgeGroup>
          <IWCardBadge
            label={t('alertsManager.triggerCount', {
              count: alert?.triggerCount,
            })}
            icon={faAlarmExclamation}
          />
          <IWCardBadge
            label={t(`insights.${alert?.podType.insightId}.label`)}
            icon={faLightbulbOn}
          />
          <IWCardBadge
            label={`${t(
              `insights.${alert?.podType.insightId}.metrics.${alert?.podType.metricId}.label`,
            )}`}
            icon={faGaugeMax}
          />
          <IWCardBadge label={alert?.userPodName || ''} icon={faCube} />
          {alert?.isPaused ? (
            <IWGeneralBadge
              data-testid="alert-paused-badge"
              label={t(`alertsManager.paused`)}
              fontHue={{ color: 'grey', value: 0 }}
              hue={{ color: 'grey', value: 500 }}
            />
          ) : undefined}
        </IWBadgeGroup>
        <Wrapper>
          {!isLoading && trigger?.results.total === 0 ? (
            <IWNoResultsMessage />
          ) : (
            <IWTable
              border
              columns={formatColumns(trigger.columns)}
              data={formatData(trigger.results.rows, trigger.columns)}
              isLoading={loading || isLoading}
              sortedBy={sort ? { [sort.column]: sort.direction } : undefined}
              onSort={handleSort}
            />
          )}
          <PaginationWrapper>
            <IWButtonGroup color="primary" variant="outline" hasSolidBackground>
              <IWButton
                iconPosition="leading"
                disabled={isLoading || isFetchingDataForDownload}
                icon={faDownload}
                onClick={handleDownload}
              >
                {t('actions.download')}
              </IWButton>
            </IWButtonGroup>
            <IWPaginationControls
              perPageLabel={t('tables.rowsPerPage')}
              itemsPerPage={page.size}
              perPageOptions={paginationOptions}
              totalItems={trigger?.results.total || 0}
              currentPage={page.current}
              onChangePage={(newPage) =>
                setPage((prev) => ({ ...prev, current: newPage }))
              }
              onChangeItemsPerPage={(newSize) =>
                setPage({ current: 1, size: newSize })
              }
              isLoading={isLoading}
            />
          </PaginationWrapper>
        </Wrapper>
      </main>
      {trigger?.results.total && (
        <DataDownloadModal
          isOpen={isDownloadModalOpen}
          isDownloading={isFetchingDataForDownload}
          dataCount={trigger?.results.total}
          onClose={() => {
            setIsDownloadModalOpen(false)
          }}
          onProcess={downloadData}
        />
      )}
    </>
  )
}

export default AlertTriggerDetailsPage
