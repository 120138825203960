import {
  IconDefinition,
  faArrowDown,
  faCancel,
  faCircleCheck,
  faCube,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import IWGenericCard, {
  IWGenericCardBody,
  IWGenericCardHeader,
} from 'shared/components/thunderbolt/IWGenericCard'
import IWProductCard from 'shared/components/thunderbolt/IWProductItem'
import IWProductList from 'shared/components/thunderbolt/IWProductList'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import UserContext from 'shared/contexts/UserContext'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import getInsights, { getTools } from 'tools/insightsManager/utils/getInsights'
import { UserTools } from 'shared/types'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import { allAllToolAndInsights } from 'shared/apiClient'

const CardHeader = styled(IWGenericCardHeader)`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  cursor: 'pointer';
`

const CardBody = styled(IWGenericCardBody)`
  padding: 0;
`
const StyledSubscribedIcon = styled(IWCardBadge)`
  margin-top: 10px;
  display: block;
`
interface InnowattsProductsList {
  insights: Array<{
    header: string
    icon: IconDefinition
    totalAvailable: number
    isAvailable: boolean
  }>
  tools: Array<{
    header: string
    type: UserTools
    description: string
    icon: IconDefinition
    isAvailable: boolean
  }>
}
/**
 * Shows all innowatts tools and what is available to clients
 * and what they can opt for.
 */
const InnowattsProducts = () => {
  const { t } = useTranslation()
  const user = useContext(UserContext)
  const { availablePodTypesMap, availableTools } = user

  const [pageData, setPageData] = useState<InnowattsProductsList>()

  const [showExapandedInsightsSection, setShowExapandedInsightsSection] =
    useState(true)

  const { data: allToolsAndInsights } = useQuery(['all-tools-insights'], () => {
    return allAllToolAndInsights()
  })

  const formatter = () => {
    const allInsights = getInsights(
      t,
      allToolsAndInsights?.insights.map((i) => i.id),
    )
    const mappedInsights = allInsights.map((item) => ({
      header: item.label,
      icon: item.icon,
      totalAvailable: availablePodTypesMap[item.type]?.length ?? 0,
      isAvailable: Boolean(availablePodTypesMap[item.type]),
    }))

    const allTools = getTools(t, allToolsAndInsights?.tools)
    const mappedTools = allTools.map((item) => ({
      header: item.label,
      type: item.type,
      icon: item.icon,
      description: item.description,
      isAvailable: availableTools.includes(item.type),
    }))

    setPageData((prev) => ({
      ...prev,
      insights: mappedInsights,
      tools: mappedTools,
    }))
  }

  useEffect(() => {
    if (user) {
      formatter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allToolsAndInsights])

  return (
    <>
      {pageData?.tools?.map((tool, i) => {
        return (
          <IWGenericCard>
            <CardHeader
              onClick={() =>
                i === 0
                  ? setShowExapandedInsightsSection((prev) => !prev)
                  : null
              }
            >
              <IWTypography
                size="lg"
                weight="bold"
                fontHue={{ color: 'grey', value: 700 }}
              >
                {t(`verticalNavigation.labels.${tool.type}`)}
                {tool.type === 'insightsManager' ? (
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    style={{
                      marginLeft: '20px',
                    }}
                  />
                ) : null}
              </IWTypography>

              <IWTypography
                size="sm"
                weight="regular"
                fontHue={{ color: 'grey', value: 500 }}
                style={{ marginTop: '0.5rem' }}
              >
                {tool.description}
              </IWTypography>
              <StyledSubscribedIcon
                icon={tool.isAvailable ? faCircleCheck : faCancel}
                label={tool.isAvailable ? 'SUBSCRIBED' : 'NOT SUBSCRIBED'}
                colorValue={400}
                iconColor={tool.isAvailable ? 'secondary' : 'alert'}
              />
            </CardHeader>
            {tool.type === 'insightsManager' && showExapandedInsightsSection ? (
              <CardBody>
                <IWProductList>
                  {pageData?.insights?.map((item) => (
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <IWProductCard
                        header={item.header}
                        icon={item.icon}
                        iconColor="secondary"
                        badges={[
                          {
                            icon: faCube,
                            label:
                              item &&
                              item.totalAvailable &&
                              item.totalAvailable > 0
                                ? t('application.innoPods.podsAvailable', {
                                    podsAvailable: item.totalAvailable,
                                  })
                                : t('application.innoPods.createFirstPod', {
                                    insight: item.header,
                                  }),
                          },
                          {
                            icon: item.isAvailable ? faCircleCheck : faCancel,
                            iconColor: item.isAvailable ? 'secondary' : 'alert',
                            label: item.isAvailable
                              ? t('common.subscribe')
                              : t('common.notSubscribe'),
                            colorValue: 400,
                          },
                        ]}
                      />
                    </div>
                  ))}
                </IWProductList>
              </CardBody>
            ) : null}
          </IWGenericCard>
        )
      })}
    </>
  )
}

export default InnowattsProducts
