import React, { ReactEventHandler } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import styled, { DefaultTheme } from 'styled-components'
import IWTypography from './IWTypography'

type StepStatus = 'complete' | 'active' | 'inactive'

interface IWProgressTrackerStep {
  index: number
  label: string
  status: StepStatus
  handleClick: ReactEventHandler | undefined
}

interface IWProgressTrackerProps {
  /**
   * these are progress steps and comes in form of Array<{id: string , label: string}>
   */
  steps: Array<string>
  /**
   * this can be a string corresponding to one of the ids of steps in steps array, also undefind and null
   * which will make all steps as complete
   */
  currentActiveIndex?: number | null
  /**
   * this is optional making steps clickable or not depending on steps availablity
   */
  onStepClick?: (e, id: number) => void
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 0.4rem;
`

const getProgressStepColor = (stepStatus: StepStatus, theme: DefaultTheme) => {
  let color = ''
  let backgroundColor = ''
  let border = ''
  const defaultColor = theme.palette.grey[500]
  const activeColor = theme.palette.primary[800]
  const altGrey = theme.palette.grey[300]
  switch (stepStatus) {
    case 'complete':
      color = defaultColor
      backgroundColor = activeColor
      border = 'none'
      break
    case 'active':
      color = activeColor
      backgroundColor = 'none'
      border = `0.13rem solid ${activeColor}`
      break
    case 'inactive':
      color = defaultColor
      backgroundColor = 'none'
      border = `0.13rem solid ${altGrey}`
      break
    default:
      color = 'none'
      backgroundColor = 'none'
      border = 'none'
      break
  }
  return {
    color,
    backgroundColor,
    border,
  }
}

const ProgressStepCircularElement = styled.div<{
  status: StepStatus
}>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: ${(props) => getProgressStepColor(props.status, props.theme).border};
  background: ${(props) =>
    getProgressStepColor(props.status, props.theme).backgroundColor};
  color: ${(props) => getProgressStepColor(props.status, props.theme).color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`
const StyledProgressStepLabel = styled(IWTypography)``

const ProgressStepContainer = styled.div<{
  status: StepStatus
}>`
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${ProgressStepCircularElement} {
    background-color: ${(props) =>
      props.status === 'complete' ? props.theme.palette.primary[900] : 'none'};
  }

  &:hover ${StyledProgressStepLabel} {
    color: ${(props) => props.theme.palette.primary[800]};
  }

  &:focus {
    outline-offset: 0.125rem;
    outline: ${(props) => `${props.theme.palette.primary[800]} solid 0.125rem`};
  }

  &:hover {
    cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  }
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.grey[0]};
  font-size: 1.25rem;
`

const IWProgressTrackerSteps = ({
  index,
  label,
  status,
  handleClick,
}: IWProgressTrackerStep) => {
  return (
    <ProgressStepContainer
      status={status}
      tabIndex={handleClick ? index : undefined}
      role={handleClick ? 'button' : undefined}
      onClick={handleClick}
    >
      <ProgressStepCircularElement status={status}>
        {status === 'complete' ? (
          <StyledFontAwesomeIcon
            icon={faCheck}
            data-testid="step-complete-icon"
          />
        ) : (
          <IWTypography weight="medium" size="sm" inheritFontColor>
            {`${index + 1}`.padStart(2, '0')}
          </IWTypography>
        )}
      </ProgressStepCircularElement>

      <StyledProgressStepLabel
        data-testid="progress-step-label"
        weight="regular"
        size="sm"
        fontHue={{
          color: status === 'active' ? 'primary' : 'grey',
          value: status === 'active' ? 700 : 500,
        }}
      >
        {label}
      </StyledProgressStepLabel>
    </ProgressStepContainer>
  )
}

/**
 *
 * IWProgressTracker shows the progress of either a process or form
 * steps are array of object that hold progress steps of a process or a form
 * currentActiveIndex is id of the current active step which corresponds to step id in
 * steps array <{id: string , label: string}> where currentActiveIndex can either be id from
 * steps array or can be undefined or null and in that case all the steps will be marked as
 * Complete
 * onStepClick is a mouse event handler which gets the id of step on which mouse is clicked
 * (e, id) => void where id is id of step currently clicked
 * @returns
 */
const IWProgressTracker = ({
  steps,
  currentActiveIndex,
  onStepClick,
}: IWProgressTrackerProps) => {
  return (
    <StyledDiv>
      {steps.map((step, index) => {
        let status: StepStatus = 'inactive'
        if (currentActiveIndex == null) {
          status = 'complete'
        } else if (index === currentActiveIndex) {
          status = 'active'
        } else if (index < currentActiveIndex) {
          status = 'complete'
        } else if (index > currentActiveIndex) {
          status = 'inactive'
        }
        return (
          <IWProgressTrackerSteps
            key={step}
            index={index}
            label={step}
            status={status}
            handleClick={onStepClick ? (e) => onStepClick(e, index) : undefined}
          />
        )
      })}
    </StyledDiv>
  )
}

export default IWProgressTracker
