import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  faDownload,
  faFlask,
  faTableList,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import IWTable, {
  Column,
  SortDirection,
} from 'shared/components/thunderbolt/IWTable'
import { getAlertTriggersSummary } from 'shared/podServiceClient'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWError from 'shared/components/thunderbolt/IWError'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import { useQuery } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import { convertToCVS, downloadContent } from 'shared/helpers'
import { PaginatedData, paginationOptions } from 'shared/types'
import DataDownloadModal from 'shared/components/DataDownloadModal'
import { DOWNLOAD_ROW_LIMIT } from 'shared/constants'
import { AlertTriggerSummary as AlertTriggerSummaryType } from '../types'
import LayoutContext from 'shared/contexts/LayoutContext'

const Wrapper = styled.div`
  width: 100%;
`

const StyledMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ActionButtonsWrap = styled.div`
  display: flex;
  gap: 0.25rem;
`

const PaginationWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
`

interface Props {
  alertId: string
  onGetPodDataCube: () => void
}

const AlertTriggerSummary = ({ alertId, onGetPodDataCube }: Props) => {
  const { t } = useTranslation()

  const { timezone } = useContext(LayoutContext)

  const columns: Column[] = [
    {
      title: t('alertsManager.triggers.actions'),
      accessor: 'actions',
      targetPercentageWidth: 10,
    },
    {
      title: t('alertsManager.triggers.triggerDate'),
      accessor: 'createdAt',
      dbColumn: 'created_at',
      sortable: true,
    },
    {
      title: t('alertsManager.triggers.matches'),
      accessor: 'numMatches',
      align: 'right',
      dbColumn: 'num_matches',
      sortable: true,
    },
  ]

  const [page, setPage] = useState({
    current: 1,
    size: paginationOptions[0].value,
  })

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)

  const [isFetchingDataForDownload, setIsFetchingDataForDownload] =
    useState(false)

  const [sort, setSort] = useState<{
    column: string
    direction: SortDirection
  }>({
    column: 'created_at',
    direction: 'descending',
  })

  const { data, isError, isLoading } = useQuery(
    ['alert', alertId, 'pageNumber', page, 'sort', sort],
    () => {
      return getAlertTriggersSummary(alertId, {
        page: page.current - 1, // postgres starts at 0 but IWTable at 1
        size: page.size,
        sort: {
          direction: sort.direction,
          column:
            columns.find((c) => c.accessor === sort.column)?.dbColumn ||
            'created_at',
        },
      })
    },
    {
      keepPreviousData: true,
    },
  )

  const actionButtons = (triggerId: string) => {
    return (
      <ActionButtonsWrap>
        <IWButton
          title={t('actions.details')}
          color="primary"
          as="routerLink"
          to={`/tools/alerts-manager/alert-details/${alertId}/triggers/${triggerId}`}
        >
          <FontAwesomeIcon icon={faTableList} />
        </IWButton>
        <IWButton
          title={t('actions.explore')}
          color="primary"
          onClick={onGetPodDataCube}
        >
          <FontAwesomeIcon icon={faFlask} />
        </IWButton>
      </ActionButtonsWrap>
    )
  }

  const handleSort = (column: string, direction: SortDirection) => {
    setSort({
      column: column,
      direction: direction || 'descending',
    })
  }

  const formatData = (d?: PaginatedData<AlertTriggerSummaryType>) => {
    return (
      d?.rows.map((x) => ({
        ...x,
        createdAt: DateTime.fromISO(x.createdAt, {
          zone: timezone,
        }).toLocaleString(DateTime.DATETIME_SHORT),
        actions: actionButtons(x.triggerId),
      })) || []
    )
  }

  if (isLoading) {
    return <IWLoading />
  }

  if (isError) {
    return <IWError />
  }

  const downloadData = async () => {
    setIsFetchingDataForDownload(true)
    // Unfiltered download
    const { rows } = await getAlertTriggersSummary(alertId, {
      page: 0,
      size: 1000,
      sort,
    })
    const csv = convertToCVS(rows)
    downloadContent({
      filename: `${alertId}`,
      ext: 'csv',
      content: csv,
      mediaType: 'text/csv',
    })
    setIsFetchingDataForDownload(false)
  }

  // Only show the modal if the number of rows is greater than the limit
  const handleDownload = async () => {
    if (data && data.total > DOWNLOAD_ROW_LIMIT) {
      setIsDownloadModalOpen(true)
    } else {
      await downloadData()
    }
  }

  return (
    <StyledMainDiv>
      <Wrapper>
        {!isLoading && data?.total === 0 ? (
          <IWNoResultsMessage />
        ) : (
          <IWTable
            border
            columns={columns}
            data={formatData(data)}
            isLoading={isLoading}
            sortedBy={sort ? { [sort.column]: sort.direction } : undefined}
            onSort={handleSort}
          />
        )}
        <PaginationWrapper>
          <IWButtonGroup color="primary" variant="outline" hasSolidBackground>
            <IWButton
              iconPosition="leading"
              disabled={isLoading || isFetchingDataForDownload}
              icon={faDownload}
              onClick={handleDownload}
            >
              {t('actions.download')}
            </IWButton>
          </IWButtonGroup>
          <IWPaginationControls
            perPageLabel={t('tables.rowsPerPage')}
            itemsPerPage={page.size}
            perPageOptions={paginationOptions}
            totalItems={data?.total || 0}
            currentPage={page.current}
            onChangePage={(newPage) =>
              setPage((prev) => ({ ...prev, current: newPage }))
            }
            onChangeItemsPerPage={(newSize) =>
              setPage({ current: 1, size: newSize })
            }
            isLoading={isLoading}
          />
        </PaginationWrapper>
      </Wrapper>
      {data && data.total > 0 && (
        <DataDownloadModal
          isOpen={isDownloadModalOpen}
          dataCount={data.total}
          isDownloading={isFetchingDataForDownload}
          onClose={() => {
            setIsDownloadModalOpen(false)
          }}
          onProcess={downloadData}
        />
      )}
    </StyledMainDiv>
  )
}

export default AlertTriggerSummary
