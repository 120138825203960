import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import { getPodDatacube } from 'shared/podServiceClient'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledIframe = styled.iframe`
  border: 0px;
  height: 650px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 0.25rem;
`

export type Props = {
  userPodId: string
  userPodName: string
}

const PodVisualisations = ({ userPodId, userPodName }: Props) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [podUrl, setPodUrl] = useState<string>('')

  const handleGetPodDataCube = async () => {
    try {
      setIsLoading(true)
      const url = await getPodDatacube(userPodId)
      setPodUrl(url)
    } catch (e) {
      toast.alert(t('insightManager.pods.dataCubeError', { name: userPodName }))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetPodDataCube()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledSection>
      <>
        {isLoading ? (
          <IWLoading />
        ) : (
          <IWFormSection sectionTitle={''}>
            <StyledIframe
              title={'pod-datacube-visual'}
              id="pivot"
              src={podUrl}
            />
          </IWFormSection>
        )}
      </>
    </StyledSection>
  )
}

export default PodVisualisations
