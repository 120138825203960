import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWTypography from './IWTypography'
import { AvailableColors, DefaultVariantHues } from '../../../@types/styled'
import { getBackgroundColor, getFontColor } from './utils/utils'
import IWButton from './IWButton'

type AlertVariants = 'default' | 'alternative'

export type IWAlertProps = {
  /**
   * Alert text that user should see
   */
  label: string
  /**
   * Alert text that user should see
   */
  description?: string
  /**
   * Error status code to display
   */
  status?: number
  /**
   * Available color values that we have in our theme
   */
  color: AvailableColors
  /**
   * Tells the component to change their basic color to either default or alternative
   */
  variant: AlertVariants
  /**
   * Array of buttons represented by a title and an action
   * that we need the button to perform. The `<IWButton />` anchor variants will be used
   */
  actionButtons?: Array<{
    text: string
    action: (e: React.SyntheticEvent<EventTarget>) => void
  }>
  /**
   * FontAwesome svg icon used before the label
   */
  icon?: IconDefinition
  /**
   * Will place buttons in a new line
   */
  shouldButtonsBeInNewLine?: boolean
}

const getButtonVariant = (
  variant: AlertVariants,
): 'anchorMain' | 'anchorAlternative' => {
  return variant === 'alternative' ? 'anchorMain' : 'anchorAlternative'
}

const defaultVariantHues: DefaultVariantHues = {
  primary: {
    background: {
      color: 'primary',
      value: 800,
    },
    font: {
      color: 'primary',
      value: 50,
    },
  },
  secondary: {
    background: {
      color: 'secondary',
      value: 900,
    },
    font: {
      color: 'secondary',
      value: 50,
    },
  },
  alert: {
    background: {
      color: 'alert',
      value: 800,
    },
    font: {
      color: 'alert',
      value: 50,
    },
  },
  warning: {
    background: {
      color: 'warning',
      value: 900,
    },
    font: {
      color: 'warning',
      value: 50,
    },
  },
  grey: {
    background: {
      color: 'grey',
      value: 500,
    },
    font: {
      color: 'grey',
      value: 50,
    },
  },
}

const alternateVariantHues: DefaultVariantHues = {
  primary: {
    background: {
      color: 'primary',
      value: 50,
    },
    font: {
      color: 'primary',
      value: 800,
    },
  },
  secondary: {
    background: {
      color: 'secondary',
      value: 50,
    },
    font: {
      color: 'secondary',
      value: 900,
    },
  },
  alert: {
    background: {
      color: 'alert',
      value: 50,
    },
    font: {
      color: 'alert',
      value: 900,
    },
  },
  warning: {
    background: {
      color: 'warning',
      value: 50,
    },
    font: {
      color: 'warning',
      value: 900,
    },
  },
  grey: {
    background: {
      color: 'grey',
      value: 100,
    },
    font: {
      color: 'grey',
      value: 700,
    },
  },
}

const StyledContainer = styled.div<{
  variant: AlertVariants
  color: AvailableColors
}>`
  display: flex;
  background-color: ${(props) =>
    getBackgroundColor(
      props,
      props.variant === 'default'
        ? { ...props.theme, defaultVariantHues }
        : { ...props.theme, defaultVariantHues: alternateVariantHues },
    )};
  color: ${(props) =>
    getFontColor(
      props,
      props.variant === 'default'
        ? { ...props.theme, defaultVariantHues }
        : { ...props.theme, defaultVariantHues: alternateVariantHues },
    )};
  fill: ${(props) =>
    getFontColor(
      props,
      props.variant === 'default'
        ? { ...props.theme, defaultVariantHues }
        : { ...props.theme, defaultVariantHues: alternateVariantHues },
    )};
  padding: 1rem;
  border-radius: 0.4rem;
`

const IconWrapper = styled.div`
  margin-right: 1rem;
`

const DescriptionWrapper = styled.div`
  margin-right: 1rem;
  flex: 1;
`

const ActionWrapper = styled.div<{ shouldButtonsBeInNewLine: boolean }>`
  margin-left: ${(props) => (props.shouldButtonsBeInNewLine ? '0' : 'auto')};
  margin-top: ${(props) => (props.shouldButtonsBeInNewLine ? '1rem' : '0')};
  display: flex;
  gap: 1rem;
`
const ContentWrapper = styled.div<{ shouldButtonsBeInNewLine: boolean }>`
  display: flex;
  flex: 1;
  align-items: ${(props) =>
    props.shouldButtonsBeInNewLine ? 'none' : 'center'};
  flex-direction: ${(props) =>
    props.shouldButtonsBeInNewLine ? 'column' : 'row'};
`

/**
 * This component gives us a way get an alert in all available colors. There are two variants
 * `default | alternate`. Alerts can be used to show important info to users. They are `divs`, thus, can
 * be used with any width.
 */
const IWAlert = ({
  label,
  description,
  status,
  actionButtons,
  shouldButtonsBeInNewLine,
  icon,
  variant,
  color,
}: IWAlertProps) => {
  const buttonCount = actionButtons ? actionButtons.length : 0
  const { t } = useTranslation()
  return (
    <StyledContainer variant={variant} color={color}>
      {icon && (
        <IconWrapper>
          <IWTypography size="sm" weight="regular" inheritFontColor>
            <FontAwesomeIcon icon={icon} />
          </IWTypography>
        </IconWrapper>
      )}

      <ContentWrapper
        shouldButtonsBeInNewLine={shouldButtonsBeInNewLine || buttonCount > 1}
      >
        <DescriptionWrapper>
          <IWTypography size="sm" weight="regular" inheritFontColor>
            {label} {status && ` (${t('numbers.number', { val: status })})`}
          </IWTypography>
          {description && (
            <IWTypography size="sm" weight="regular" inheritFontColor>
              {description}
            </IWTypography>
          )}
        </DescriptionWrapper>
        {actionButtons && (
          <ActionWrapper
            shouldButtonsBeInNewLine={
              shouldButtonsBeInNewLine || buttonCount > 1
            }
          >
            {actionButtons.map((btn) => (
              <IWButton
                key={btn.text}
                variant={getButtonVariant(variant)}
                color={color}
                onClick={btn.action}
              >
                {btn.text}
              </IWButton>
            ))}
          </ActionWrapper>
        )}
      </ContentWrapper>
    </StyledContainer>
  )
}

export default IWAlert
