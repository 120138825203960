import React, { useRef, useState, SyntheticEvent, useEffect } from 'react'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { Popover } from 'react-tiny-popover'
import {
  getFilterBadgeOptionsDisplayString,
  deepCopy,
} from 'shared/utils/filterBadgesUtils'
import styled from 'styled-components'
import IWButton from './IWButton'
import IWDropdown, { DropdownValueProps } from './IWDropdown'
import IWGeneralBadge from './IWGeneralBadge'
import IWRadioButton from './IWRadioButton'

export type RadioOptionType = 'include' | 'exclude'

interface IWFilterBadgeOptionsWrapperProps {
  children: any
  isOpen: boolean
  label: string
  optionType: RadioOptionType
  defaultOptions: DropdownValueProps[]
  values: DropdownValueProps[]
  onGetOptions: (inputValue: string) => Promise<DropdownValueProps[]>
  onConfirm: () => void
  onCancel: () => void
  onValueSelected: (value: DropdownValueProps[]) => void
  onRadioButtonChange: (selection: RadioOptionType) => void
}

export type FilterBadgesOptionsState = {
  values: string[]
  optionType: RadioOptionType
}

interface IWFilterBadgesOptionsProps {
  id: string
  defaultOptions: DropdownValueProps[]
  onConfirm: (id: string, state: FilterBadgesOptionsState) => void
  onRemove: (id: string) => void
  onCancel: (id: string) => void
  onGetOptions: (inputValue) => Promise<DropdownValueProps[]>
  isOpen: boolean
  onClick: (e: SyntheticEvent) => void
}

const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${(props) => props.theme.palette.grey[0]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
  min-width: 400px;
`

const StyledButtonDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

const StyledAttributeFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
`

const StyledIWDropdown = styled(IWDropdown)`
  width: 100%;
  max-width: 400px;
`

const FlexDiv = styled.div`
  display: flex;
  gap: 1rem;
`

const IWFilterBadgeOptionsWrapper = ({
  children,
  isOpen,
  label,
  optionType,
  defaultOptions,
  values,
  onCancel,
  onConfirm,
  onValueSelected,
  onRadioButtonChange,
  onGetOptions,
}: IWFilterBadgeOptionsWrapperProps) => {
  const { t } = useTranslation()
  return (
    <Popover
      padding={4}
      reposition
      containerStyle={{ zIndex: '3' }}
      positions={['bottom']}
      align="start"
      isOpen={isOpen}
      onClickOutside={onCancel}
      content={() => {
        return (
          <StyledPopupContent onClick={(e) => e.stopPropagation()}>
            <FlexDiv>
              <IWRadioButton
                id="include"
                name="optionType"
                label={t(`filterBadgeOptions.labels.include`)}
                value="include"
                checked={optionType === 'include'}
                onChange={(e) => {
                  onRadioButtonChange(e.target.value as RadioOptionType)
                }}
              />
              <IWRadioButton
                id="exclude"
                name="optionType"
                label={t(`filterBadgeOptions.labels.exclude`)}
                value="exclude"
                checked={optionType === 'exclude'}
                onChange={(e) => {
                  onRadioButtonChange(e.target.value as RadioOptionType)
                }}
              />
            </FlexDiv>
            <StyledAttributeFilter data-testid="string-selector-attrs-filter">
              <StyledIWDropdown
                isAsync
                name={label}
                fullWidth
                isMulti
                multiline
                value={values}
                label={label}
                defaultOptions={defaultOptions}
                onGetOptions={onGetOptions}
                loadOptions={(inputValue) => onGetOptions(inputValue)}
                onChange={onValueSelected}
              />
            </StyledAttributeFilter>
            <StyledButtonDiv>
              <IWButton
                data-testid="string-selector-cancel"
                variant="alternative"
                onClick={onCancel}
              >
                {t(`button.cancel`)}
              </IWButton>
              <IWButton
                data-testid="string-selector-confirm"
                disabled={!values.length}
                onClick={onConfirm}
              >
                {t(`button.confirm`)}
              </IWButton>
            </StyledButtonDiv>
          </StyledPopupContent>
        )
      }}
    >
      {children}
    </Popover>
  )
}

const IWFilterBadgesOptions = ({
  id,
  defaultOptions,
  onConfirm,
  onRemove,
  onGetOptions,
  isOpen,
  onCancel,
  onClick,
}: IWFilterBadgesOptionsProps) => {
  const defaults = {
    values: [] as DropdownValueProps[],
    optionType: 'include' as RadioOptionType,
    options: defaultOptions,
  }
  const [summary, setSummary] = useState<string>(id)
  const [state, setState] = useState(deepCopy(defaults))
  const ref = useRef(deepCopy(state))

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        options: defaultOptions,
      }
    })
  }, [defaultOptions])

  const onValueSelected = (selections: DropdownValueProps[]) => {
    state.values = selections
    setState({ ...state })
  }

  const onRadioButtonChange = (selection) => {
    state.optionType = selection
    setState({ ...state })
  }

  const handleConfirm = () => {
    ref.current = deepCopy(state)
    const labelText = getFilterBadgeOptionsDisplayString(
      id,
      state.optionType,
      state.values,
    )
    onConfirm(id, {
      values: state.values.map((v) => v.value),
      optionType: state.optionType,
    })
    setSummary(labelText)
  }

  const handleCancel = () => {
    const { optionType, values } = ref.current
    const labelText = getFilterBadgeOptionsDisplayString(id, optionType, values)
    setState(() => ({ ...ref.current }))
    onCancel(id)
    setSummary(labelText)
  }

  return (
    <IWFilterBadgeOptionsWrapper
      values={state.values}
      defaultOptions={state.options}
      optionType={state.optionType}
      label={id}
      isOpen={isOpen}
      onGetOptions={onGetOptions}
      onValueSelected={onValueSelected}
      onRadioButtonChange={onRadioButtonChange}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      <IWGeneralBadge
        label={summary}
        color="grey"
        icon={faTimesCircle}
        iconPosition="trailing"
        onClick={onClick}
        onIconClick={() => onRemove(id)}
      />
    </IWFilterBadgeOptionsWrapper>
  )
}

export default IWFilterBadgesOptions
