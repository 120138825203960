import React, { SyntheticEvent } from 'react'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

const StyledModalFooter = styled(IWModalFooter)`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`

interface Props {
  credentialId: string
  name: string
  isCancelButtonDisabled: boolean
  onCancel: (e: SyntheticEvent) => void
  isDeleteButtonDisabled: boolean
  onDelete: (e: SyntheticEvent, credentialId: string) => void
}

const SFTPDeleteModal = ({
  credentialId,
  name,
  isCancelButtonDisabled,
  onCancel,
  isDeleteButtonDisabled,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  return (
    <IWModal open={true} onClose={onCancel}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('systemPreferences.integrations.sftp.deleteCredentialHeader')}
        </IWTypography>
      </IWModalHeader>
      <IWModalContent>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t(
            'systemPreferences.integrations.sftp.deleteCredentialDescription',
            {
              name,
            },
          )}
        </IWTypography>
      </IWModalContent>
      <StyledModalFooter>
        <IWButton
          disabled={isCancelButtonDisabled}
          color="grey"
          variant="outline"
          onClick={onCancel}
        >
          {t('button.cancel')}
        </IWButton>
        <IWButton
          color="alert"
          disabled={isDeleteButtonDisabled}
          onClick={(e) => {
            onDelete(e, credentialId)
          }}
        >
          {t('button.delete')}
        </IWButton>
      </StyledModalFooter>
    </IWModal>
  )
}

export default SFTPDeleteModal
