import React, { forwardRef, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Select, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  Props as SelectProps,
  SelectInstance,
} from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { darken, rgba, transitions } from 'polished'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import IWInputLabel, { IWInputInfo } from './IWCommonLabels'

export interface GenericDropdownValue<T> {
  label: string
  value: T
  isDisabled?: boolean
}

export type DropdownValueProps = GenericDropdownValue<string>

const ClearIndicatorSpan = styled.span`
  margin-right: 0.5rem;
`

const DropdownIndicatorSpan = styled.span<{ menuIsOpen: boolean }>`
  margin-left: 0.5rem;
  ${transitions(['rotate', '0.3s ease 0s'])};
  transform: ${(props) => (props.menuIsOpen ? 'rotate(-180deg)' : '')};
`

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <ClearIndicatorSpan>
        <FontAwesomeIcon icon={faTimesCircle} />
      </ClearIndicatorSpan>
    </components.ClearIndicator>
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorSpan menuIsOpen={selectProps.menuIsOpen}>
        <FontAwesomeIcon icon={faChevronDown} />
      </DropdownIndicatorSpan>
    </components.DropdownIndicator>
  )
}

const DisabledMultiValueRemove = styled.span`
  padding: 0;
  display: flex;
  align-items: center;
`

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  const { selectProps } = props
  if (selectProps.isDisabled) {
    return (
      <DisabledMultiValueRemove>
        <FontAwesomeIcon icon={faTimesCircle} />
      </DisabledMultiValueRemove>
    )
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValueRemove {...props}>
      <FontAwesomeIcon icon={faTimesCircle} />
    </components.MultiValueRemove>
  )
}

interface DefaultProps {
  /** Simple string to place as a label on top of the dropdown */
  label?: string
  /** In case Dropdown `isMulti` it will allow the dropdown container to grow vertically when more options are selected than space is available. */
  multiline?: boolean
  /** Pass this to "color" the dropdown border with red to denote an error (kind of weird in a dropdown, and probably would rarely be used...) */
  hasError?: boolean
  /** If the Dropdown should be fullWidth (100%) or auto */
  fullWidth?: boolean
  /** If the Dropdown flex grow */
  flexGrow?: boolean
  /** If the field is required or not, adds * to the label */
  required?: boolean
  /** helper text for additional information */
  helperText?: string
  /** error message on input which will display error message */
  errorText?: string
  /** If you wish to use <AsyncSelect> */
  isAsync?: boolean
  /** Custom css width for the drop-down menu */
  menuWidth?: string
}

const StyledDropdown = styled.div<{
  disabled?: boolean
  fullWidth?: boolean
  flexGrow?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  flex-grow: ${(props) => (props.flexGrow ? 1 : 0)};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`

type Props =
  | (DefaultProps & Omit<SelectProps, 'styles' | 'components' | 'theme'>)
  // @ts-ignore <- it doesn't work without this
  | (DefaultProps & AsyncProps)

/**
 * This is Innowatts' Dropdown component. You should use this component for most Dropdown components that act as an HTML Select or MultiSelect. This component is built using [React Select](https://react-select.com/), which is a VERY comprehensive Select Input controller. All `props` available to the React Select `<Select />` component are available, except for `styles`, `components`, and `theme`. Additionally, this component offers three extra `props` that are not part of React Select: `label`, `multiline`, and `hasError`. This component is simply an Innowatts style wrapper of React Select, thus the omission of the styling and component props. If you ever need to pass extra styles for a very specific reason, we suggest you first question it and try to work with what is provided here.
 */
const IWDropdown = forwardRef(
  (
    {
      isAsync,
      fullWidth,
      required,
      flexGrow,
      disabled,
      menuWidth,
      ...props
    }: Props,
    ref: React.Ref<SelectInstance>,
  ) => {
    const themeContext = useContext(ThemeContext)
    const { label, id, helperText, errorText } = props

    const selectComponents = {
      MultiValueRemove,
      ClearIndicator,
      DropdownIndicator,
    }

    const styles = {
      control: (provided, state) => {
        const boxShadowColor = rgba(themeContext.palette.primary[800], 0.2)
        return {
          ...provided,
          backgroundColor: themeContext.palette.grey[0],
          gap: '0.5rem',
          /**
           * .4rem is not to spec...However, it is the only way I could get it the height of the input close to a normal text input
           */
          padding: '0.4rem 1rem',
          borderRadius: '0.375rem',
          borderColor: themeContext.palette.grey[300],
          color: themeContext.palette.grey[700],
          minHeight: '0',
          ':hover': {
            borderColor: themeContext.palette.grey[400],
          },
          ...(state.menuIsOpen && {
            boxShadow: `0 0 0.5rem ${boxShadowColor}`,
            borderColor: themeContext.palette.primary[700],
            ':hover': {
              borderColor: themeContext.palette.primary[700],
            },
          }),
          ...(props.hasError &&
            !state.isDisabled && {
              borderColor: themeContext.palette.alert[800],
              ':hover': {
                borderColor: themeContext.palette.alert[800],
              },
            }),
          ...(state.isDisabled && {
            backgroundColor: themeContext.palette.grey[100],
            color: themeContext.palette.grey[400],
            pointerEvents: 'auto',
            cursor: 'not-allowed',
          }),
        }
      },
      valueContainer: (provided) => ({
        ...provided,
        fontSize: themeContext.typography.fontSizes.sm,
        lineHeight: themeContext.typography.fontSizes.sm,
        gap: '0.25rem',
        flexWrap: props.multiline ? 'wrap' : 'nowrap',
        padding: 0,
      }),
      placeholder: (provided) => ({
        ...provided,
        color: themeContext.palette.grey[400],
        margin: 0,
        padding: 0,
        /**
         * without a maxWidth the placeholder will go all the way
         * next to the divider. When a value is selected the separation
         * between the divider and the value is the calc seen below.
         * Thus, I copied the methodology.
         */
        maxWidth: 'calc(100% - 0.5rem)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
      input: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        fontSize: themeContext.typography.fontSizes.sm,
        lineHeight: themeContext.typography.fontSizes.sm,
      }),
      singleValue: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        fontSize: themeContext.typography.fontSizes.sm,
        lineHeight: themeContext.typography.fontSizes.sm,
        color: 'inherit',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        backgroundColor: themeContext.palette.grey[200],
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        color: themeContext.palette.grey[500],
      }),
      clearIndicator: (provided) => {
        const color = themeContext.palette.grey[500]
        return {
          ...provided,
          margin: 0,
          padding: 0,
          color: themeContext.palette.grey[500],
          ':hover': {
            color: darken(0.1, color),
          },
        }
      },
      multiValue: (provided) => ({
        ...provided,
        margin: 0,
        borderRadius: '0.6rem',
        padding: '0.1rem 0.25rem 0.1rem 0.25rem',
        backgroundColor: themeContext.palette.grey[200],
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: themeContext.palette.grey[600],
        fontSize: themeContext.typography.fontSizes.xs,
        fontWeight: themeContext.typography.fontWeights.medium,
        lineHeight: themeContext.typography.lineHeights.xs,
        marginRight: '0.5rem',
        padding: 0,
        ...(state.isDisabled && {
          color: themeContext.palette.grey[400],
        }),
      }),
      multiValueRemove: (provided, state) => {
        const color = themeContext.palette.grey[600]
        return {
          ...provided,
          padding: 0,
          color: !state.isFocused ? color : darken(0.1, color),
          backgroundColor: 'none',
          ':hover': {
            color: darken(0.1, color),
          },
        }
      },
      menu: (provided) => {
        const boxShadowColor = rgba(themeContext.palette.grey[800], 0.2)
        return {
          ...provided,
          zIndex: themeContext.layers.dropdown,
          width: menuWidth || '100%',
          borderRadius: '0.375rem',
          backgroundColor: themeContext.palette.grey[0],
          borderColor: themeContext.palette.grey[300],
          boxShadow: `0 0 08px ${boxShadowColor}`,
          '& :last-child': {
            borderRadius: '0 0 0.375rem 0.375rem',
          },
          '& :first-child': {
            borderRadius: '0.375rem 0.375rem 0 0',
          },
          '& :only-child': {
            borderRadius: '0.375rem 0.375rem 0.375rem 0.375rem',
          },
        }
      },
      menuList: (provided) => ({
        ...provided,
        padding: 0,
      }),
      option: (provided, state) => {
        const hoverBackgroundColor = themeContext.palette.grey[50]
        const selectedBackgroundColor = themeContext.palette.primary[100]
        return {
          ...provided,
          color: themeContext.palette.grey[700],
          ...(state.isDisabled && {
            backgroundColor: themeContext.palette.grey[50],
            color: themeContext.palette.grey[400],
          }),
          ...(!state.isDisabled && {
            ':hover': {
              backgroundColor: hoverBackgroundColor,
            },
          }),
          ...(state.isFocused && {
            backgroundColor: hoverBackgroundColor,
          }),
          ...(state.isSelected &&
            !state.isDisabled && {
              backgroundColor: selectedBackgroundColor,
              ':hover': {
                backgroundColor: selectedBackgroundColor,
              },
            }),
        }
      },
    }

    return (
      <StyledDropdown
        disabled={disabled}
        fullWidth={fullWidth}
        flexGrow={flexGrow}
        data-testid="dropdown-select"
      >
        <IWInputLabel label={label} id={id} required={required} />
        {isAsync ? (
          <AsyncSelect
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            ref={ref}
            components={selectComponents}
            styles={styles}
          />
        ) : (
          <Select
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            ref={ref}
            components={selectComponents}
            styles={styles}
          />
        )}
        <IWInputInfo helperText={helperText} errorText={errorText} />
      </StyledDropdown>
    )
  },
)

export default IWDropdown
